.checkout-google-pay-button {
  display: block;
  width: 100%;
  height: var(--rc-min-height-button-medium);

  button {
    border-radius: var(--rc-border-radius-button);
    padding: 0;
  }

  &.checkout-google-pay-button--small {
    height: var(--rc-min-height-button-small);
  }
}
