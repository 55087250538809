.paypal-buy-now-button {
  iframe {
    position: relative;
    // stylelint-disable-next-line declaration-no-important
    z-index: 0 !important;
  }
}

.paypal-buy-now-button--pay-later {
  margin-top: var(--rc-space-2);

  iframe {
    position: relative;
    // stylelint-disable-next-line declaration-no-important
    z-index: 0 !important;
  }

  div {
    margin: 0;
  }
}
