@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.modal-sidebar-listing {
  width: 100%;

  &.modal-sidebar-listing--loading {
    @include loading-pulse;
  }
}

.modal-sidebar-listing__primary {
  @include tablet {
    display: flex;
    align-items: center;
  }
}

.modal-sidebar-listing__secondary {
  @include tablet {
    display: none;

    .modal-sidebar-listing--expanded & {
      display: block;
    }
  }
}

.modal-sidebar-listing__thumbnail {
  margin: 0 0 2rem;
  background-color: var(--rc-color-highlight-200);

  @include tablet {
    margin-bottom: 0;
    margin-right: 1rem;
    flex: 0 0 5rem
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.modal-sidebar-listing__title {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0 0 2rem;

  @include tablet {
    margin-bottom: 0;
    flex: 1;
  }

  .modal-sidebar-listing--loading & {
    background-color: var(--rc-color-highlight-200);
    border-radius: $input-border-radius;
    height: 1.6rem;
    width: 60%;
  }
}

.modal-sidebar-listing__details-toggle {
  @include button-reset;
  display: none;

  @include tablet {
    display: block;
    padding: 2rem;
  }

  &::after {
    @include fa-icon(chevron-down);
    font-size: 1.8rem;
    color: var(--rc-color-palette-blue-600);
  }

  .modal-sidebar-listing--expanded & {
    &::after {
      @include fa-icon(chevron-up);
    }
  }
}

.modal-sidebar-listing__details {
  width: 100%;

  @include tablet {
    margin-top: 1rem;
  }

  .modal-sidebar-listing--loading & {
    background-color: var(--rc-color-shadow-400);
    border-radius: $input-border-radius;
    height: 2.2rem;
  }
}

.modal-sidebar-listing__detail {
  font-size: 1.4rem;
  margin-bottom: 1rem;

  @include tablet {
    margin-bottom: 0.5rem;
  }

  dt {
    display: inline-block;
    font-weight: 700;
    margin-right: 0.5em;

    &::after {
      content: ':';
    }
  }

  dd {
    display: inline-block;
  }
}

.modal-sidebar-listing__link {
  @include rc-button.filled;
  @include fa-icon-after(external-link);
  display: block;
  width: 100%;
  font-size: 1.4rem;
  margin: 2rem 0 0;

  @include tablet {
    @include rc-button.muted($extend: false);
    margin-top: 1rem;
  }

  .modal-sidebar-listing--loading & {
    border: none;
    cursor: default;
    pointer-events: none;
    height: 3.2rem;
    background-color: var(--rc-color-shadow-400);
    border-radius: $input-border-radius;
  }
}
