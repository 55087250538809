@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.csp-review-button {
  @include rc-button.filled;

  .csp-reviews__header & {
    @include rc-button.size('small');
    width: 100%;
  }

  .item2 & {
    @include rc-button.colors($theme: 'main', $variant: 'muted', $inverted: false);
    width: auto;

    @include mobile {
      width: 100%;
    }
  }
}
