@use 'sass:math';

.image-carousel {
  --image-carousel-control-size: 3.2rem;
  --image-carousel-icon-size: 2.6rem;
  --image-carousel-control-offset: 0.5rem;
  --image-carousel-control-border: solid 0.1rem var(--rc-color-border-primary);

  position: relative;
  overflow: hidden;
  height: 0;
  width: 100%;
  padding-bottom: 100%;

  .rc-listing-card & {
    --image-carousel-control-size: 3.6rem;
    --image-carousel-icon-size: 2rem;
    --image-carousel-control-offset: var(--card-image-edge-spacing, var(--rc-space-4));
    --image-carousel-control-border: none;
  }

  .rc-listing-row-card & {
    --image-carousel-control-offset: var(--rc-space-2);
    --image-carousel-control-border: none;
  }
}

.image-carousel__prefetch {
  display: none;
}

.image-carousel__slides {
  position: absolute;
  width: 300%;
  left: -100%;
  height: 100%;
  transition: none;

  .image-carousel__slide {
    position: absolute;
    width: math.percentage(math.div(1, 3));
    left: 0;
    height: 100%;

    + .image-carousel__slide {
      left: math.percentage(math.div(1, 3));
    }

    + .image-carousel__slide + .image-carousel__slide {
      left: math.percentage(math.div(2, 3));
    }
  }

  .image-carousel--click-left & {
    left: 0;
    transition: left .2s ease-in-out;
  }

  .image-carousel--click-right & {
    left: -200%;
    transition: left .2s ease-in-out;
  }
}

.image-carousel__control-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: var(--image-carousel-control-size);
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.image-carousel__control-wrapper--left {
    left: 0;
    padding-left: var(--image-carousel-control-offset);
  }

  &.image-carousel__control-wrapper--right {
    right: 0;
    padding-right: var(--image-carousel-control-offset);
  }

  // overrides to make this work in rc-listing-cards
  .rc-listing-row-card &,
  .rc-listing-card & {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);

    &.image-carousel__control-wrapper--left {
      z-index: 2;
    }

    &.image-carousel__control-wrapper--right {
      z-index: 2;
    }
  }
}

.image-carousel__control {
  @include button-reset;
  box-sizing: border-box;
  width: var(--image-carousel-control-size);
  height: var(--image-carousel-control-size);
  border-radius: 50%;
  border: var(--image-carousel-control-border);
  background-color: var(--rc-color-background-page);
  color: var(--rc-color-text-secondary);
  transition:
    opacity 0.2s ease,
    box-shadow 0.2s ease,
    color 0.2s ease,
    transform 0.2s ease;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    @include box-shadow;
    color: var(--rc-color-text-primary);
    transform: scale(1.1);
  }

  .rc-listing-row-card:hover &,
  .rc-listing-card:hover &,
  .image-carousel:hover & {
    opacity: 1;
  }

  .rc-icon {
    display: block;
    width: var(--image-carousel-icon-size);
    height: var(--image-carousel-icon-size);

    // the visual weight of these icons are slightly off center so we're compensating for that here
    .image-carousel__control-wrapper--left & {
      transform: translateX(-0.1rem);
    }

    .image-carousel__control-wrapper--right & {
      transform: translateX(0.1rem);
    }
  }

  @include mobile {
    display: none;
  }
}
