@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/config/configuration' as config;
@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow' as shadows;

@keyframes watching-heart-button {
  0%,
  18% {
    transform: scale(0);
  }
}

.watch-badge__button {
  @include button-reset;
  cursor: pointer;
  display: block;
  padding: 1.1rem;
  border: 0.1rem solid var(--rc-color-border-primary);

  @include mobile {
    padding: 0.8rem;
  }

  .watch-badge--button & {
    @include rc-button.muted($fullwidth: true);

    .list-view-row-card & {
      @include tablet {
        @include rc-button.size('small');
      }
    }
  }

  .watch-badge--animation-button & {
    @include rc-button.muted($fullwidth: true);
  }

  .watch-badge--text & {
    @include rc-button.muted;

    .item2 &,
    .listing-row-card & {
      width: 100%;
    }
  }

  .watch-badge--animation-icon & {
    color: var(--rc-color-text-primary);
    background-color: var(--rc-color-background-page);
    border: none;
    border-radius: 50%;
    transform: scale(1);
    transition: color 0.2s, transform 0.2s;

    &:hover,
    &:focus-visible {
      color: var(--rc-color-text-accent);
      transform: scale(1.1);
    }
  }

  .watch-badge--disabled & {
    opacity: config.$button-disabled-opacity;
    cursor: not-allowed;

    &:hover {
      color: var(--rc-color-text-primary);
    }
  }

  .item2__watch-badge & {
    @include shadows.box-shadow;
  }

  .item2__watch-badge .watch-badge--icon-only & {
    width: 4.8rem;
  }
}

.watch-badge__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.watch-badge__icon {
  transition: transform .2s;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  will-change: transform;
  width: 1.2em;
  height: 1.2em;
  color: inherit;

  .watch-badge--watching & {
    transform: scale(1);
    color: var(--rc-color-text-accent);
  }

  .watch-badge--button & {
    transform: translateY(0.1rem); // helps with visual alignment of heart icon
  }

  .watch-badge--icon-only & {
    width: 2.2rem;
    height: auto;
  }

  .watch-badge--watching.watch-badge--icon-only &,
  .watch-badge--watching.watch-badge--button &,
  .watch-badge--watching.watch-badge--text & {
    animation: watching-heart-button .5s cubic-bezier(.17, .89, .32, 1.49);
  }

  .watch-badge--animation-icon & {
    width: 3.2rem;
    height: 3.2rem;

    @include mobile {
      width: 2.8rem;
      height: 2.8rem;
    }
  }

  .item2__watch-badge .watch-badge--animation-icon & {
    width: 3.2rem;
    height: 3.2rem;

    @include mobile {
      width: 2.8rem;
      height: 2.8rem;
    }
  }

  .item2__watch-badge .watch-badge--icon-only & {
    width: 2.8rem;
    height: auto;
  }
}

.watch-badge__animation {
  position: absolute;
  width: 7rem;
  height: 7rem;
  top: -2.5rem;
  left: -2.5rem;
  pointer-events: none;

  .watch-badge--animation-icon & {
    width: 12rem;
    height: 12rem;
    top: -4.2rem;
    left: -4.4rem;

    @include mobile {
      width: 10rem;
      height: 10rem;
      top: -3.5rem;
      left: -3.65rem;
    }
  }
}

.watch-badge__text {
  margin-left: 0.5rem;

  .item2-pricing__watch & {
    margin-left: 0.6rem;
  }
}
