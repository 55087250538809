@use '../tools/mixins/rc-button';
@use '../tools/mixins/box-shadow' as shadow;

@keyframes favorite-pulse {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

@keyframes favorite-icon-pulse {
  0% {
    transform: scale(1);
  }

  12% {
    transform: scale(0.89);
  }

  24% {
    transform: scale(0.77);
  }

  56% {
    transform: scale(1.08);
  }

  74% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.rc-favorite-button {
  @include shadow.box-shadow($blur-radius: 0.6rem, $color: var(--rc-color-shadow-400));

  color: var(--rc-color-text-primary);
  background: var(--rc-color-background-module);
  border-radius: var(--rc-border-radius-full);
  border: none;
  display: block;

  width: var(--rc-space-9);
  aspect-ratio: 1 / 1;

  padding: 0;
  transition: color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;

  &:not([disabled]):hover,
  &:focus-visible {
    @include shadow.box-shadow($blur-radius: 1.4rem, $color: var(--rc-color-shadow-400));

    color: var(--rc-color-accent-loud);
    transform: scale(1.1);
  }

  &.rc-favorite-button--large {
    width: var(--rc-min-height-button-medium);
  }

  &.rc-favorite-button--small {
    width: var(--rc-space-7);
  }

  &.rc-favorite-button--favorited {
    color: var(--rc-color-accent-loud);
    &::after {
      animation: favorite-pulse .4s forwards;
    }
  }

  &::after {
    content: '';
    transform: translate(-50%, -50%) scale(0);
    position: absolute;
    left: 50%;
    top: 50%;
    background: var(--rc-color-accent-loud);
    border-radius: var(--rc-border-radius-full);
    width: 8rem;
    height: 8rem;
  }
}

.rc-favorite-button__icon {
  position: absolute;
  height: auto;
  width: 65%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  svg {
    .rc-favorite-button--favorited & {
      animation: favorite-icon-pulse .4s forwards;
    }
  }
}
