.callout-embed {
  @extend %blog-wrapper;
  position: relative;
  background: var(--rc-color-palette-gray-900) center no-repeat;
  background-size: cover;
  text-align: center;
  box-shadow: inset 0 0 0 100rem var(--rc-color-shadow-300);

  a {
    @include scaling((padding-top padding-bottom), 4rem);
    display: block;
    color: var(--rc-color-palette-bw-white);
  }

  &.callout-embed--narrow {
    .blog-post__frame & {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.callout-embed__title {
  font-weight: bold;
  font-size: 1.8em;
  line-height: 1.2;
  padding: 0 1em;
  margin-bottom: 0.5em;
}
