.favorite-listings-empty-state {
  background-color: var(--rc-color-background-page-secondary);
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  gap: 0.625rem;
  border-radius: 0.3125rem;
  padding: 1.75rem;
}

.favorite-listings-empty-state__title {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--rc-color-text-primary);
}

.favorite-listings-empty-state__subtitle {
  color: var(--rc-color-text-secondary);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
