.cart-footer-express-pay-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: white;
  margin: 1rem -1.75rem -1.75rem -1.75rem;
  border-radius: 0.4rem;

  @include tablet {
    margin: 1rem -1.25rem -1.25rem -1.25rem;
  }

  @include mobile {
    margin: 1rem -0.75rem -0.75rem -0.75rem;
  }

  &-buttons {
    align-items: baseline;
  }

  .checkout-cart-footer__button--apple-pay {
    margin-top: 0.35rem;

    .apple-pay-button--white-outlined, .apple-pay-button--white-outlined-express {
      margin-top: 0.2rem;

      @include mobile {
        margin: 0.9rem 0 0 1rem;
      }
    }
  }
}
