@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.offer-action {
  @include rc-button.muted;

  .grid-card & {
    width: 100%;
    white-space: normal;
    font-size: 0.8em;
    margin-top: 0.5rem;
  }

  .featured-listing-card & {
    font-size: 1.1em;
    width: 100%;
    white-space: normal;
  }

  .item2 & {
    width: 100%;
    white-space: normal;
  }

  .rc-listing-row-card &,
  .listing-row-card & {
    width: 100%;
    white-space: normal;
  }

  .rc-listing-card & {
    @include rc-button.size('small');
  }

  .list-view-row-card & {
    width: 100%;
    white-space: normal;

    @include tablet {
      @include rc-button.size('small');
    }
  }

  .featured-listing-header,
  .featured-listing-module & {
    white-space: normal;
    width: 100%;
  }
}

.offer-action--accept {
  @include rc-button.filled($extend: false);
  @include fa-icon-before(check-circle);
}

.offer-action--counter {
  @include fa-icon-before(comments);
}

.offer-action--reject {
  @include fa-icon-before(times-circle);
}

.offers-form {
  width: 48rem;
  max-width: 100%;

  @include mobile {
    width: 100%;
  }
}

.offers-form--loading {
  .offers-form-placeholder {
    @include loading-pulse;
    width: 100%;
    height: 6rem;
    margin: 2rem 0;
    background: var(--rc-color-background-loading-placeholder);
  }
}

.offers-form__header-content-container {
  border-radius: 0.5rem;
  background: var(--rc-color-background-alert-plain);
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    align-items: flex-start;
  }
}

.offers-form__header-content-container__condensed {
  justify-content: start;

  @include mobile {
    align-items: center;
  }
}

.offers-form__header-image {
  display: flex;
  width: 25%;

  img {
    border: solid 0.1rem var(--rc-color-border-primary);
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include mobile {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }

  @include mobile {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    margin-top: 0.8rem;
  }
}

.offers-form__header-image__condensed {
  width: 15%;

  @include mobile {
    margin: 0.4rem;
  }
}

.offers-form__header-content {
  margin: 0.8rem;
  width: 75%;
}

.offers-form__header-content__title {
  color: var(--rc-color-palette-gray-900);
  font-size: var(--rc-font-size-400);
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.offers-form__header-content__title__one-line {
  color: var(--rc-color-palette-gray-900);
  font-size: 1.6rem;
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.offers-form__header-content__title__post-submit {
  color: var(--rc-color-palette-gray-900);
  font-size: var(--rc-font-size-400);
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.offers-form__header-content__cart-nudge {
  color: var(--rc-color-text-primary);
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.offers-form__header-content__body {
  font-size: 1.4rem;
  margin-top: 0.8rem;
}

.offers-form__header-content__price {
  font-size: 1.6rem;
  font-weight: bold;

  @include mobile {
    display: block;
  }
}

.offers-form__header-content__listing-link {
  margin-left: 0.8rem;

  @include mobile {
    display: block;
    margin-left: 0;
  }
}

.offers-form__header-content__price__post-submit {
  font-size: var(--rc-font-size-450);
  font-weight: bold;
}

.offers-form__header-content__shop-location {
  color: var(--rc-color-text-secondary);
  margin-top: 0.8rem;
}

.offers-form__header-content__condition {
  color: var(--rc-color-text-secondary);
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.offers-form__header-content__total__post-submit {
  font-size: 1.4rem;
  margin-top: 0.5rem;

  span {
    margin-left: 1rem;
  }
}

.offers-form__fields {
  .rc-alert-box {
    margin-bottom: 2rem;
  }

  .rc-alert-box__content {
    font-size: 1.4rem;
    padding: 1rem;
  }
}

.offers-form__discount-percentage-buttons {
  button {
    margin-right: var(--rc-space-2);

    @include mobile {
      margin-bottom: var(--rc-space-2);
    }
  }
}

.offers-form__review {
  display: flex;
  gap: var(--rc-space-4);
  align-items: flex-start;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
  padding: 1rem 0 1rem 0.5rem;

  @include mobile {
    display: block;
    padding: 1rem 0;
  }
}

.offers-form__review__header {
  flex: 0 0 7rem;
  font-weight: bold;
  padding-top: var(--rc-space-y-accordion);
  line-height: var(--rc-font-line-height-title);

  @include mobile {
    padding-left: var(--rc-space-x-accordion);
  }
}

.offers-form__review__content {
  flex: 1 1 auto;
}

.offers-form__payment-review {

  .rc-form-group__label {
    display: none;
  }

  .offers-form__payment-review__additional-content {
    text-align: right;
    font-size: 1.4rem;
    color: var(--rc-color-text-secondary);
    margin-bottom: 0;
  }

  @include mobile {
    margin-left: 0;
  }
}

.offers-form__payment-review__totals {
  border-bottom: solid 0.1rem var(--rc-color-border-primary);
}

.offers-form__payment-review__totals__estimated-total {
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: var(--rc-space-2);
  display: flex;
  justify-content: space-between;
  color: var(--rc-color-sentiment-positive-dark);
  align-items: center;

  div {
    color: var(--rc-color-text-secondary);
    font-weight: normal;
    font-size: 1.4rem;

    h4, .rc-accordion__heading__title {
      color: var(--rc-color-palette-gray-900);
      font-weight: bold;
      font-size: 1.8rem;
    }
  }
}

.offers-form__payment-review__terms {
  margin-top: 1rem;
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
}

.offers-form__payment-review__reverify {
  margin-bottom: 2rem;
}

.offers-form__payment-review__credit-card {
  display: flex;
  align-items: center;

  span.rc-icon {
    width: var(--rc-space-12);
    padding-right: var(--rc-space-2);
  }

  div {
    font-size: var(--rc-font-size-350);
  }
}

.offers-form__confirmation {
  h4 {
    font-weight: bold;
    margin-bottom: 0.8rem;
  }

  li {
    list-style-type: disc;
    margin-left: 2rem;
    margin-bottom: 0.8rem;
  }
}

.offers-form__actions {
  @include scaling(margin-top, 4rem);
}

.offers-form__location-text {
  margin-bottom: 0;

  @include mobile {
    font-size: 90%;
  }
}

.offers-form__location__edit {
  @include button-as-link;
  @include fa-icon-before(pencil, $margin-right: 0.25em);
  margin-left: 1em;
  white-space: nowrap;
}

.offers-form__location__change {
  @include button-as-link;
  white-space: nowrap;
  font-weight: bold;
  margin-left: 0.5rem;
}

.offers-form__location__apply {
  @include button-as-link;
  white-space: nowrap;
  font-weight: bold;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 2rem;
}

.offers-form__location__form {
  @include grid-container;
  display: flex;
  flex-wrap: wrap;
}

.offers-form__location__input {
  @include grid-columns(6, 6, 12);

  @include mobile {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.offers-form__negotiation-summary {
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  color: var(--rc-color-text-secondary);

  span.offers-form__negotiation-summary__price-label {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--rc-color-palette-gray-900);
  }
}

.offers-form__negotiation-summary__quantity {
  @include scaling(margin-bottom, 0.5rem);
}

.offers-form__negotiation-summary__price {
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: 700;
  margin: 0;
}

.offers-form__negotiation-summary__sub-price {
  font-size: 1.4rem;
  font-weight: normal;
  font-style: italic;
  margin: 0;
}

.offers-form__negotiation-summary__tax-hint {
  font-size: 60%;
  font-weight: normal;
  margin-left: 0.5em;
}

.offers-form__negotiation-summary__details {
  margin: 0;
}

.offers-form__offer-input {
  @include scaling(margin-bottom, 2rem);
}

.offers-form__price-row {
  margin-bottom: 0.8rem;
  display: flex;

  @include mobile {
    display: block;
  }

  input[type],
  select {
    font-size: 2rem;

    @include mobile {
      font-size: 1.6rem;
    }
  }

  .input-group__text {
    font-size: 1.6rem;
    padding: 1rem 1.2rem;

    @include mobile {
      padding: 0.4rem 0.8rem;
      font-size: 1.4rem;
    }
  }
}

.offers-form__counter-price-row {
  margin-bottom: 1.6rem;
  display: flex;

  @include mobile {
    display: block;
  }

  .input-group__text {
    padding: 1rem 1.2rem;

    @include mobile {
      padding: 0.4rem 0.8rem;
    }
  }
}

.offers-form__shipping-price-row {
  margin-bottom: 1.6rem;
}

.offers-form__price {
  flex: 1;

  @include mobile {
    margin-bottom: 1.1rem;
  }
}

.offers-form__quantity {
  font-size: 2rem;
  margin-left: 1rem;

  @include mobile {
    margin-left: 0;
  }

  .form-group {
    min-width: 8rem;
  }
}

.offers-form__error {
  @include scaling(margin-bottom, 2rem);
  border-radius: 0.4rem;
  padding: 0.5em;
  line-height: 1.5;
  color: var(--rc-color-text-danger);
  background-color: var(--rc-color-sentiment-negative-lighter);
}

.offers-form__separator {
  border-top: solid 0.1rem var(--rc-color-border-primary);
  margin: 2rem -2.8rem;
  height: 0;
}

.offers-form__footnote {
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
  margin: 1rem 0 0;
}

.offers-form__currency-difference-alert {
  border-radius: 0.5rem;
  background: var(--rc-color-background-alert-plain);
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.8rem;

  p {
    margin: 0;
    font-size: 1.4rem;
  }
}

.offers-form__message-row {
  margin-top: 2rem;
}

.offers-form__credit-card-icon {
  width: 20%;
}
