@use '@reverbdotcom/cadence/styles/config/configuration' as config;

// These are legacy alert styles and should be kept in sync with
// packages/cadence/styles/classes-ui/alert-box.scss styles
.alert {
  @include clearfix;
  border-radius: config.$input-border-radius;
  border-width: 0.1rem;
  border-style: solid;
  background-color: var(--rc-color-background-alert-warning);
  border-color: var(--rc-color-border-alert-warning);
  margin: 1rem auto 2rem auto;
  padding: 2rem;

  &.mt-0 { margin-top: 0; }
  &.mb-0 { margin-bottom: 0; }

  p {
    margin-bottom: 0;
  }

  .btn {
    font-size: 1.4rem;
  }

  .subtext {
    font-weight: normal;
    font-size: 1.4rem;
    margin-top: 0.5em;
  }

  &, h3 {
    font-size: 1.6rem;
    font-weight: normal;
  }
}

.alert-warn {
  background: var(--rc-color-sentiment-warning-lighter);
}

.alert-error {
  background-color: var(--rc-color-background-alert-error);
  border-color: var(--rc-color-border-alert-error);
}

.alert-notice, .alert-info {
  background-color: var(--rc-color-background-alert-info);
  border-color: var(--rc-color-border-alert-info);
}

.alert-success {
  background-color: var(--rc-color-background-alert-success);
  border-color: var(--rc-color-border-alert-success);

  .alert-link {
    float: right;
    @include mobile { float: left; }
  }
}

.alert-small {
  font-size: 1.4rem;
  padding: 1.5rem;
}

.alert-tiny {
  padding: 1rem;
}

.flash-messages {
  @include box-shadow;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  text-align: center;
  overflow: hidden;
  font-size: 1.6rem;
  font-weight: bold;
  border-bottom: solid 0.1rem transparent;

  &.flash-messages--active {
    display: block;
  }

  &[data-flash-message-type='success'] {
    background-color: var(--rc-color-background-alert-success);
    border-color: var(--rc-color-border-alert-success);
  }

  &[data-flash-message-type='notice'] {
    background-color: var(--rc-color-background-alert-info);
    border-color: var(--rc-color-border-alert-info);
  }

  &[data-flash-message-type='error'] {
    background-color: var(--rc-color-background-alert-error);
    border-color: var(--rc-color-border-alert-error);
  }

  @include mobile {
    padding: 1rem 3rem;
  }
}

.flash-messages__close {
  float: right;

  &:after {
    @include fa-icon(remove);
    font-size: 120%;
  }

  @include mobile {
    position: absolute;
    right: 1rem;
    top: calc(50% - 0.5em);
  }
}

.error-highlight {
  background-color: var(--rc-color-background-alert-error) !important;
  transition: background-color 0.5s linear;
}

.update-highlight {
  background-color: var(--rc-color-background-alert-warning) !important;
  transition: background-color 1s linear;
}

.user-activate-ask {
  padding: 2rem 0;
  background-color: var(--rc-color-background-alert-info);
  box-sizing: border-box;
  @include mobile { padding: 1rem; }

  &.activation-success {
    background-color: var(--rc-color-background-alert-success);
  }

  & + .flash-messages + .collection-banner { margin-top: 0; }
}

.ui-state-error input, select.ui-state-error {
  border: 0.1rem solid var(--rc-color-border-alert-error) !important;
  background: var(--rc-color-background-alert-error) !important;
  border-radius: config.$input-border-radius;
}

.ui-state-error[type='checkbox'] + label {
  color: var(--rc-color-text-danger);

  a {
    color: var(--rc-color-text-danger);
    text-decoration: underline;
  }
}

iframe,
input,
textarea {
  &.ui-state-error {
    border: 0.1rem solid var(--rc-color-border-alert-error) !important;
    background: var(--rc-color-background-alert-error) !important;
    border-radius: config.$input-border-radius;
  }
}

.errorExplanation {
  @extend .alert;
  @extend .alert-error;
}
