@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/aspect-ratio';
@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow';
@use '@reverbdotcom/cadence/styles/tools/mixins/card-styles';

.product-family-cta {
  @include card-styles.card-base;
  @include responsive((padding gap), var(--rc-space-5), var(--rc-space-4), var(--rc-space-3));
  display: flex;
  align-items: center;
  color: var(--rc-color-text-primary);
  padding: var(--rc-space-5);
  background-size: cover;
  background-position: right center;
  background-blend-mode: saturation;
  border-radius: var(--rc-border-radius-primary);

  &:hover {
    color: var(--rc-color-text-primary);
  }
}

.product-family-cta__image {
  @include responsive(flex-basis, 20rem, 12rem, 10rem);
  @include aspect-ratio.aspect-ratio(1, 1);
  mix-blend-mode: multiply;
}

.product-family-cta__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  gap: var(--rc-space-3);

  h4 {
    font-weight: bold;

    @include mobile {
      font-size: 1.8rem;
    }
  }
}

.product-family-cta__action {
  @include rc-button.filled($size: 'small');
}
