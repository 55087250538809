@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';
@use 'sass:math';
// Some pertinent notes:

// there are max-widths in this file that seem redundant but ARE NOT.
// IE11 needs them because it doesn't calculate box-sizing correctly for flex-basis

// Firefox and IE don't support percentage top/bottom margins
// on flex items, so instead we're type-coercing the $grid-gutter value
// into vws, and writing those instead.
// max-vw is documented in sass-core so look it up there if you like.

.tiles {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: calc(-0.5 * var(--rc-space-gap-grid));
  margin-right: calc(-0.5 * var(--rc-space-gap-grid));
  margin-bottom: calc(-1 * var(--rc-space-gap-grid));
  opacity: 1;
  transition: opacity .3s;

  &.tiles--one-wide-mobile,
  &.tiles--one-wide {
    margin-bottom: -1rem;
  }

  &.tiles--single-row {
    flex-wrap: nowrap;
    margin-bottom: 0;
    justify-content: flex-start;
  }

  &.tiles--centered {
    @include min-width($site-width-mid) {
      justify-content: center;
    }
  }

  &.tiles--reloading {
    opacity: 0.6;
  }

  .overflowing-row--landing & {
    @include mobile {
      flex-wrap: wrap;
    }
  }
}

.tiles__tile {
  // legacy iOS needs this up here
  flex: 1;

  @include responsive(min-width, 18rem, 17rem, 15rem); // stylelint-disable-line order/order
  @include responsive(flex-basis, 18rem, 17rem, 15rem); // stylelint-disable-line order/order
  max-width: 25rem;
  box-sizing: border-box;
  display: flex;
  padding-left: calc(0.5 * var(--rc-space-gap-grid));
  padding-right: calc(0.5 * var(--rc-space-gap-grid));
  margin-bottom: var(--rc-space-gap-grid);

  @include max-width($site-width-tiny) {
    min-width: 14rem;
    flex-basis: 14rem;
  }

  // prevents a 7-up grid on wide monitors
  @include min-width($site-width-max - 130) {
    min-width: 20rem;
    flex-basis: 20rem;
  }

  .tiles--large & {
    @include responsive(min-width, 24rem, 19rem, 17rem);
    @include responsive(flex-basis, 24rem, 19rem, 17rem);
    max-width: 35rem;

    @include with-sidebar {
      @include responsive(min-width, 22rem, 18rem, 16rem);
      @include responsive(flex-basis, 22rem, 18rem, 16rem);

      max-width: 28rem;
    }
  }

  // for non-single-row large tiles on tiny phones, we don't want 2-wide
  .tiles--large:not(.single-row) & {
    @include max-width($site-width-tiny) {
      max-width: none;
    }
  }

  .tiles--one-wide & {
    flex: 0 0 100%;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: var(--rc-space-gap-grid);
  }

  .tiles--two-wide & {
    @include responsive(min-width, 40%, 45%, 100%);
    @include responsive(flex-basis, 40%, 45%, 100%);
    max-width: 50%;

    @include mobile {
      max-width: 100%;
    }

    @include with-sidebar {
      @include tablet {
        min-width: 100%;
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }

  .tiles--three-wide & {
    @include responsive((min-width flex-basis), 33%, $mobile: 100%);
    flex-grow: 0;
  }

  .tiles--three-wide.tiles--single-row & {
    @include responsive(min-width, 33rem, 31rem, 75%);
    @include responsive(flex-basis, 33rem, 31rem, 75%);
  }

  @include with-sidebar {
    .tiles--three-wide & {
      @include min-width($site-width-mid) {
        min-width: 31rem;
        flex-basis: 31rem;
      }
    }
  }

  .tiles--grow & {
    flex-grow: 1;
    max-width: 100%;

    // necessary to override sidebar overrides :\
    @include with-sidebar {
      flex-grow: 1;
      max-width: none;
    }
  }

  // special case for the Reverb core marketplace
  // so it renders at most 4 wide
  .tiles--four-wide-max & {
    @include min-width(1150px) {
      flex: 0 0 25%;
      min-width: 0;
      max-width: 25%;

      // necessary to override sidebar overrides :\
      @include with-sidebar {
        flex: 0 0 25%;
        min-width: 0;
        max-width: 25%;
      }
    }

    // Adding these overrides to match sortable-tiles on listing grids
    .faceted-grid--wide & {
      $wide-grid-5up: 1081px; // only 1px higher since it flips to be min-width
      $wide-grid-4up: 1080px;
      $wide-grid-3up: 890px;
      $wide-grid-2up: 640px;

      @media screen and (min-width: $wide-grid-5up) {
        flex-basis: math.percentage(math.div(1, 5));
        max-width: math.percentage(math.div(1, 5));
      }

      @media screen and (max-width: $wide-grid-4up) {
        flex-basis: math.percentage(math.div(1, 4));
        max-width: math.percentage(math.div(1, 4));
      }

      @media screen and (max-width: $wide-grid-3up) {
        flex-basis: math.percentage(math.div(1, 3));
        max-width: math.percentage(math.div(1, 3));
      }

      @media screen and (max-width: $wide-grid-2up) {
        flex-basis: math.percentage(math.div(1, 2));
        max-width: math.percentage(math.div(1, 2));
      }
    }
  }

  .tiles--one-wide-mobile & {
    @include mobile {
      flex: 0 0 100%;
      min-width: 100%;
      max-width: 100%;
      margin-bottom: 1rem;
    }
  }

  .tiles--single-row & {
    margin-bottom: 0;

    &:empty {
      display: none;
    }
  }

  &:empty {
    margin-bottom: 0;
  }

  .overflowing-row--landing &:nth-child(n+5) {
    @include mobile {
      display: none;
    }
  }

  .overflowing-row--landing & {
    @include mobile {
      margin-bottom: 1rem;
      flex-basis: 50%;
      max-width: 100%;
    }
  }
}

.tiles__view-more {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 0.9em;
  font-weight: bold;
}

.tiles__view-more__link {
  @include shadow-on-hover;
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg.icon-bg(angle-right, scss-tokens.$rc-color-palette-gray-900);

  width: 50%;
  height: 0;
  padding-bottom: 50%;
  border-radius: 50%;
  background-color: var(--rc-color-palette-bw-white);
  background-size: auto 60%;
}
