.domestic-shipping-card__radio__content__wrapper {
  margin-left: var(--rc-space-7);

  @include mobile {
    margin-left: 0;
    padding-top: var(--rc-space-3);
  }
}

.rc-radio-card:has(.domestic-shipping-card__radio__content__wrapper) {
  &.rc-radio-card--checked {
    .rc-radio-card__content {
      padding-top: 0;
      background-color: var(--rc-color-background-module);
      border-bottom-left-radius: var(--rc-border-radius-md);
    }
  }
}
