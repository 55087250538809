@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow';

.csp-wide-card {
  width: 100%;
  margin: 0;

  &.csp-wide-card--placeholder {
    @include loading-pulse;
  }
}

.csp-wide-card__inner {
  display: block;
  background: var(--rc-color-background-page-secondary);
  color: var(--rc-color-text-primary);
  border-radius: var(--rc-border-radius-primary);
  transition: box-shadow 0.15s ease, background-color 0.15s ease;

  &:hover {
    @include box-shadow.box-shadow;
    color: var(--rc-color-text-primary);
    background: var(--rc-color-background-page);
  }
}

.csp-wide-card__banner-img {
  @include responsive(height, 14rem, 12rem, 8rem);

  background-size: cover;
  background-position: center;
}

.csp-wide-card__content {
  // height of abs. pos. image + padding
  @include responsive(min-height, 18rem, 16rem, 10rem);

  position: relative;
  display: flex;
  align-items: center;

  .csp-wide-card__banner-img + & {
    display: block;
    min-height: 0;
  }
}

.csp-wide-card__content__img {
  @include responsive(width, 14rem, 12rem, 10rem);
  position: absolute;
  bottom: 2rem;
  left: 2rem;

  @include mobile {
    bottom: 0;
    left: 0;
    padding: 0;
    border-left: none;
    border-bottom: none;
    border-top: none;
  }
}

.csp-wide-card__content__details {
  flex: 1;
  padding: 2rem 2rem 2rem 18rem;

  @include tablet {
    padding-left: 16rem;
  }

  @include mobile {
    padding: 1rem 1rem 1rem 11rem;
  }
}

.csp-wide-card__content__title {
  @include responsive(font-size, 2.8rem, 2.2rem, 1.6rem);
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.csp-wide-card__price {
  font-size: 1.2rem;
  color: var(--rc-color-text-primary);
  display: inline-block;
  margin-right: 1em;
  line-height: 1;

  b {
    font-size: 1.3em;
  }
}
