
.checkout-order-summary__item {
  margin-bottom: var(--rc-space-4);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.checkout-order-summary__item-title {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkout-order-summary__item-shop {
  margin-bottom: 0;
  font-size: 90%;
  color: var(--rc-color-text-secondary);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.checkout-order-summary__item-discount-label {
  font-size: 90%;
  font-weight: bold;
  color: var(--rc-color-text-price-nudge);
  margin: 0;
}

.checkout-order-summary__item-discount-label--sale {
  @include fa-icon-before(tag);
}

.checkout-order-summary__item-discount-label--shop-campaign {
  @include fa-icon-before(ticket);
}

.checkout-order-summary__line-items {
  width: 100%;
  font-size: 1.4rem; // specific to override weird user agent styles on %table elements in-app web view

  td {
    border: none;
    padding: 0.5rem 0;
    padding: 0.5rem 0;
  }

  tr:first-child {
    td {
      padding-top: 0;
    }
  }

  tr:nth-last-child(2) {
    td {
      padding-bottom: 1rem;
      padding-bottom: 1rem;
    }
  }

  tr:last-child {
    td {
      padding: 1rem 0 0;
      border-top: 0.2rem solid var(--rc-color-border-primary);
    }
  }
}
