@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.csp-tiles__cta {
  @include responsive(font-size, 1em, 0.95em, 0.9em);
  @include scaling(padding, 4rem);
  background-color: var(--rc-color-palette-vu-orange-300);
  border-radius: $input-border-radius;
  align-items: center;
  width: 100%;
  display: flex;
  border-color: transparent;

  @include tablet {
    justify-content: center;
    text-align: center;
  }

  @include mobile {
    width: 100%;
    text-align: center;
    padding: 0;
    background-color: var(--rc-color-background-page);
  }
}

.csp-tiles__cta-title {
  margin: 0.9rem 0 1.5rem 0;
  color: var(--rc-color-text-primary);
  font-size: 2.5rem;

  @include mobile {
    font-size: 2.2rem;
  }
}

.csp-tiles__cta-subtitle {
  color: var(--rc-color-text-primary);
  font-size: 1.5rem;
}

.csp-tiles__cta-link {
  @include text-link;

  &:hover {
    color: var(--rc-color-text-primary);
  }

  @include mobile {
    display: none;
  }
}

.csp-tiles__mobile-cta {
  margin: 1rem 1% 0; // using 1% margin to visually match columns above
  width: 100%;
  display: none;

  @include mobile {
    display: block;
  }
}

.csp-tiles__mobile-cta-link {
  @include rc-button.muted($size: 'small', $fullwidth: true);
  margin-bottom: 1rem;
}
