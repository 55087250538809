.jumplinks {
  font-weight: bold;
  font-size: 0.9em;
  white-space: nowrap;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  margin-top: 1rem;

  .dynamic-page--csp &,
  &.jumplinks--stuck {
    border-bottom: 0.1rem solid var(--rc-color-border-primary);
    border-top: 0.1rem solid var(--rc-color-border-primary);
    margin-top: 0;
  }
}

.jumplinks__inner {
  @include site-wrapper;
  @include smooth-scroll;

  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;

  // prevent scrollbar overlap
  margin-bottom: -1rem;
  padding-bottom: 1rem;

  .jumplinks--centered & {
    text-align: center;
  }

  @include mobile {
    text-align: center;
  }
}

.jumplink {
  color: var(--rc-color-text-primary);
  padding: 0.7rem 2em 0.5rem 2em;
  display: inline-block;
  border-bottom: 0.2rem solid var(--rc-color-border-primary);
  cursor: pointer;

  + .jumplink {
    margin-left: 0.4rem;
  }

  .dynamic-page--csp &,
  .jumplinks--stuck & {
    border-bottom-color: transparent;
    margin-left: 0;
  }

  &.jumplink--active {
    color: var(--rc-color-text-accent);
    border-bottom-color: var(--rc-color-border-accent);
  }

  @include responsive-hover {
    color: var(--rc-color-text-link-hovered);
  }

  @include mobile {
    padding: 0.9rem 1rem 0.7rem 1rem;
  }

  &.jumplink--in-sidebar {
    display: none;

    @include mobile {
      display: inline-block;
    }
  }
}
