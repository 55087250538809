.fixed-sell-search {
  background-color: var(--rc-color-background-module);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transform: translateY(-100%);
  transition: transform 0.3s, box-shadow 0.3s;
  transition-timing-function: cubic-bezier(.4, 0.0, 0.2, 1); // Material's FastOutSlowIn

  @include mobile {
    top: unset;
    bottom: 0;
    transform: translateY(100%);
  }

  &.fixed-sell-search--open,
  &:focus-within { // allows content to remain in the expected tab order and appear when a child has focus
    @include box-shadow;
    transform: translateY(0);
    transition: transform 0.3s, box-shadow 0.3s;
  }
}

.fixed-sell-search-input {
  flex: 2;
  max-width: 30rem;

  @include mobile {
    max-width: 100%;
  }
}

.fixed-sell-search__left-container {
  flex: 1;
  display: flex;
  align-items: center;
  gap: var(--rc-space-4);
}

.fixed-sell-search__right-container {
  display: flex;
  align-items: center;
  gap: var(--rc-space-4);

  @include mobile {
    display: none;
  }
}
