@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

// Styles correspond to AdCard component

.amp-card {
  @include shadow-on-hover;
  position: relative;
  width: 100%;
  height: 100%;
  border: 0.1rem solid var(--rc-color-border-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  justify-content: center;
  border-radius: var(--rc-border-radius-primary);

  &:hover {
    text-decoration: none;
  }

  .rc-grid & {
    min-width: 25rem;
  }
}

.amp-card__titleblock {
  flex: 0;
}

.amp-card__title {
  font-size: 1.8rem;
  margin: 0;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  white-space: normal;

  @media screen and (max-width: 960px) {
    font-size: 1.6rem;
  }
}

.amp-card__subtitle {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 400;
  white-space: normal;

  @media screen and (max-width: 960px) {
    display: none;
  }
}

.amp-card__image-wrapper {
  display: flex;
  align-items: center;
  padding: 2rem 0;
  max-height: 23rem;
}

.amp-card__image {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.amp-card__cta-wrapper {
  flex: 0;
  padding-bottom: 0.75rem;
}

.amp-card__cta {
  @include rc-button.muted($size: 'mini');
}

.amp-card__amp-callout {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
}
