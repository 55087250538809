.label-tag {
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: var(--rc-color-palette-gray-200);
  color: var(--rc-color-text-primary);
  padding: 0.2em 0.4em;
  border-radius: var(--rc-border-radius-sm);
  min-width: 2rem;
  text-align: center;
  box-sizing: border-box;
  line-height: 1.2;

  &.label-tag--orange {
    background-color: var( --rc-color-palette-vu-orange-500);
    color: var( --rc-color-palette-bw-white);
  }

  &.label-tag--blue {
    background-color: var( --rc-color-palette-blue-600);
    color: var( --rc-color-palette-bw-white);
  }

  &.label-tag--small {
    padding: 0.05em 0.2em;
    min-width: 1.4rem;
  }
}
