@use 'sass:map';
@use '../../config/configuration';
@use '../functions/svg';
@use 'focus-outline';
@use 'responsive';
@use 'responsive-hover';
@use '../../config/scss-tokens';

@mixin checkbox($size: configuration.$checkbox-size, $mobile-size: configuration.$checkbox-size, $simulated: false) {
  $base-selector: '> label';
  $checked-selector: '> input:checked + label';
  $focus-selector: '> input:focus-visible + label';
  $disabled-selector: '> input:disabled + label';
  $disabled-checked-selector: '> input:checked:disabled + label';

  @if ($simulated == true) {
    $base-selector: '&';
    $checked-selector: '&[data-checked]';
    $focus-selector: '&:focus-visible';
    $disabled-selector: '&[data-disabled]';
    $disabled-checked-selector: '&[data-disabled][data-checked]';
  }

  position: relative;

  @if ($simulated != true) {
    input[type='checkbox'] {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }

  #{$base-selector} {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    font-weight: map.get(configuration.$font-weights, normal);
    width: 100%;
    line-height: $size;

    // simulated checkbox element
    &::before {
      background-color: var(--rc-color-background-input);
      content: '';
      display: block;
      border: 0.2rem solid var(--rc-color-border-input-primary);
      border-radius: var(--rc-border-radius-checkbox);
      box-sizing: border-box;
      width: $size;
      height: $size;
      transition: border-color .2s, background .2s, box-shadow .2s;
      flex: 0 0 $size;
      margin-right: $size * 0.5;
    }

    // simulated check icon
    &::after {
      content: '';
      position: absolute;
      box-sizing: border-box;
      width: $size;
      height: $size;
      background-color: var(--rc-color-background-input);
      mask-image: svg.svg-url(check, scss-tokens.$rc-color-palette-bw-soft-black); // stylelint-disable-line cadence/no-scss-tokens
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 70% auto;
      transition: background-color 0.2s;
    }

    @if ($mobile-size != $size) {
      @include responsive.mobile {
        line-height: $mobile-size;

        &::before {
          width: $mobile-size;
          height: $mobile-size;
          flex-basis: $mobile-size;
          margin-right: $mobile-size * 0.3;
        }
      }
    }

    @include responsive-hover.responsive-hover {
      &::after {
        background-color: var(--rc-color-text-input-secondary);
      }
    }

    .form-group--with-error & {
      &::before {
        border-color: var(--rc-color-border-input-error);
      }
    }
  }

  @if ($simulated != true) {
    #{$focus-selector} {
      &::before {
        @include focus-outline.focus-outline($offset: true);
      }
    }
  }

  #{$checked-selector} {
    &::before {
      background-color: var(--rc-color-background-input-checked);
      border-color: var(--rc-color-background-input-checked);
    }
    &::after {
      background-color: var(--rc-color-background-page);
    }
  }

  #{$disabled-selector} {
    opacity: 0.7;
    cursor: default;

    @include responsive-hover.responsive-hover {
      &::before {
        background: none;
      }
    }

    &::before {
      background-color: var(--rc-color-background-input-disabled);
    }
  }

  #{$disabled-checked-selector} {
    &::after {
      // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
      background-color: var(--rc-color-text-primary-disabled);
    }
  }
}
