@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.signup-signin {
  padding: 0;
}

.signup-signin__tabs {
  display: flex;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 0.1rem solid var(--rc-color-background-page-secondary);

  .reverb-modal--signup-signin & {
    border-bottom: none;
    padding: 0;
  }
}

.signup-signin__tab {
  @include button-reset;
  color: var(--rc-color-text-primary);
  display: block;
  padding: 0.5rem 1.6rem;
  text-align: center;
  position: relative;
  line-height: 2.5;
  flex: 1;

  @include mobile {
    line-height: 1.875;
  }

  &:hover {
    color: var(--rc-color-palette-vu-orange-500);
  }

  &.signup-signin__tab--active {
    font-weight: bold;
    color: var(--rc-color-palette-vu-orange-500);

    &::before {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      right: 0;
      left: 0;
      border-bottom: 0.4rem solid var(--rc-color-palette-vu-orange-500);
    }
  }
}

.signup-signin__form {
  @include scaling(padding, 2rem);
}

.signup-signin__header {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  padding: 1rem 0;
}

.signup-signin__form__get-newsletter {
  margin-bottom: 0.5rem;
}

.signup-signin__form__facebook {
  text-align: center;
}

.signup-signin__form__facebook__link {
  @include fa-icon-before(facebook);
  @include button-as-link;
  color: var(--rc-color-vendor-facebook);
  font-weight: bold;
}

.signup-signin__form__facebook__button {
  .rc-button.rc-button--main.rc-button--filled {
    --background-color: var(--rc-color-vendor-facebook);
    --color: var(--rc-color-palette-bw-white);
  }
}

.signup-signin__form__gdpr {
  margin-bottom: 1rem;
}

.signup-signin__form__gdpr__explanation__title {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.signup-signin__form__gdpr__explanation__subtext {
  font-size: 1.2rem;
  line-height: 1.2;
  color: var(--rc-color-text-secondary);
}
