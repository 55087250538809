.scroll-x-on-mobile {
  @include scroll-x-on-mobile;

  &.scroll-x-on-mobile--full-bleed {
    padding-left: var(--rc-space-gutter-page);
    margin-left: calc(-1 * var(--rc-space-gutter-page));
    margin-right: calc(-1 * var(--rc-space-gutter-page));
  }
}

.scroll-x-on-tablet {
  @include scroll-x-on-tablet;

  &.scroll-x-on-tablet--full-bleed {
    padding-left: var(--rc-space-gutter-page);
    margin-left: calc(-1 * var(--rc-space-gutter-page));
    margin-right: calc(-1 * var(--rc-space-gutter-page));
  }
}
