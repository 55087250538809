@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.lightbox-modal__primary {
  @include responsive(bottom, 8rem, 7rem, 6rem);
  @include responsive(top, 6rem, 5rem, 4rem);
  @include responsive((left right), 5rem, 4rem, 0);

  position: absolute;

  .lightbox-modal--image-loading & {
    @include loading-pulse;
  }
}

.lightbox-modal__close {
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg.icon-bg(close, scss-tokens.$rc-color-palette-bw-white);
  @include button-reset;

  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 3rem;
  height: 3rem;
  opacity: 0.7;
  transition: opacity .2s;

  @include tablet {
    top: 1rem;
    right: 1rem;
  }

  @include mobile {
    width: 2rem;
    height: 2rem;
  }

  @include responsive-hover {
    opacity: 1;
  }
}

.lightbox-modal__container {
  @include smooth-scroll;

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: var(--rc-color-palette-bw-soft-black);
  overflow-y: scroll;
}

.lightbox-modal__caption {
  position: absolute;
  bottom: calc(100% + 0.2em);
  left: 4rem;
  right: 4rem;
  text-align: center;
  color: var(--rc-color-palette-bw-white);
  line-height: 1.1;
  font-weight: bold;
  font-size: 0.85em;
}
