@use '@reverbdotcom/cadence/styles/tools/mixins/svg';

.category-flyout-header__underlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: var(--rc-color-shadow-600);
  opacity: 0;
  bottom: 100%;
  z-index: 10000;

  .category-flyout-header--open & {
    opacity: 1;
    bottom: 0;
    transition: opacity .2s;
  }
}

.category-flyout-header__link-bar {
  @include site-wrapper;

  position: relative;
  display: flex;
  white-space: nowrap;
  font-size: 1.3rem;
  width: 100%;
  background: var(--rc-color-background-page);

  .category-flyout-header--open & {
    z-index: 10002;
  }

  @include tablet {
    padding: 0;
  }
}

.category-flyout-header__link-bar__primary {
  overflow: hidden;
  flex-grow: 1;

  @include tablet {
    @include smooth-scroll;

    overflow-x: auto;
    padding: 0 var(--rc-space-gutter-page);
  }
}

.category-flyout-header__link-bar__secondary {
  border-left: 0.1em solid var(--rc-color-border-primary);

  @include tablet {
    display: none;
  }
}

.category-flyout-header__category {
  display: inline-block;
  margin: 0 0.2rem;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.category-flyout-header__link-bar__list-item {
  display: inline-block;
  position: relative;

  &.category-flyout-header__link-bar__list-item--tablet-only {
    display: none;

    @include tablet {
      display: inline-block;
    }
  }
}

.category-flyout-header__link-bar__list-item__outlet {
  a {
    color: var(--rc-color-text-accent);
  }
}

.category-flyout-header__link {
  @include button-reset;
  @include group-link;

  padding: 1rem 1.2rem;
  display: inline-block;
  color: var(--rc-color-text-primary);
  font-size: 1.3rem;
  position: relative;

  @include responsive-hover {
    color: var(--rc-color-text-accent);
  }

  .category-flyout-header__link-bar__secondary & {
    padding: 1rem;
  }

  .header-dropdown__contents & {
    display: block;
    font-size: 1.5rem;
  }

  &.category-flyout-header__link--active {
    color: var(--rc-color-text-accent);
  }

  &:focus-visible {
    outline-offset: -0.2rem;
    border-radius: 0.2rem;
  }
}

.category-flyout-header__view-more {
  @include button-reset;
  @include svg.icon-after(caret-down);

  flex: 0 0 auto;
  font-size: 1.3rem;
  font-weight: bold;
  padding: 1rem;
  padding-left: 2rem;
  height: 100%;

  &:hover {
    cursor: default;
  }

  &:focus-visible {
    outline-offset: -0.2rem;
    border-radius: 0.2rem;
  }

  &::before {
    display: inline-block;
    position: absolute;
    content: '';
    top: 0;
    left: -4rem;
    height: 100%;
    width: 4rem;
    background: linear-gradient(to left, var(--rc-color-background-page), var(--rc-color-transparent));
  }

  @include tablet {
    display: none;
  }
}

