// Admin navigation
.sticky-wrapper {
  @include mobile { display: none; }
}

.sticky-wrapper .stuck {
  position: fixed;
  top: 0;
  width: 100%;

  animation: slide-down 0.3s 1 ease-out forwards;
}

.header-admin {
  position: relative;
  margin: 0px auto;
  @include hide-for-print;

  &.header-admin--logged-in-as {
    min-height: 3.2rem;
  }
}

.admin-static-edit {
  position: relative;
  width: 100%;
  margin: 0 auto;

  .btn.btn-warning {
    position: absolute;
    right: 1rem;
    top: 6rem;
  }
}

.admin-panel {
  text-align: center;
  background: var(--rc-color-palette-gray-900);

  .admin-panel__admin-side-only {
    display: none;

    .navbar-fixed-top & {
      display: inline-block;
    }
  }

  a,
  .experiment label {
    display: block;
    padding: 7px;
    color: var(--rc-color-palette-bw-white);
    cursor: pointer;
    font-weight: normal;
    margin: 0;
    font-size: 13px;

    @include mobile {
      font-size: 12px;
    }

    &:hover {
      text-decoration: none;
      background: var(--rc-color-palette-gray-500);
    }
  }

  a[data-toggle="dropdown"] {
    &:after {
      @include fa-icon('caret-down');
      font-size: 90%;
      opacity: 0.5;
    }
  }

  ul.dropdown-menu {
    float: left;
    text-align: left;
    background: var(--rc-color-palette-gray-900);
    padding-top: 0;
    min-width: 0;

    @include mobile {
      height: 0;
      overflow: hidden;
    }
  }

  .dropdown {
    &:last-of-type {
      ul.dropdown-menu {
        left: auto;
        right: 0;
      }
    }
  }

  .experiment {
    input[type='checkbox'] {
      display: none;
      &:checked + label {
        background: var(--rc-color-palette-vu-orange-500);
      }
    }
  }

  &.admin-panel--responsive {
    white-space: nowrap;

    @include mobile {
      > ul {
        overflow-x: auto;
        overflow-y: hidden;
        font-size: 12px;
      }
      .mobile-admin-menu {
        ul.dropdown-menu {
          display: block;
          width: 100%;
          position: fixed;
          top: 100%;
          left: 0;
          right: 0;
          height: auto;
        }
      }
    }
  }
}

// Banners to alert users & admins of account states
.logged-in-as-alert {
  text-align: center;
  background: var(--rc-color-sentiment-negative-dark);
  padding: 9px 0;
  color: var(--rc-color-palette-bw-white);

  &.logged-in-as-alert--fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    font-size: 1.6rem;
    padding: 0.5rem 0;
    z-index: 9999;
  }
}

.header-banner {
  margin-top: 0;
  max-width: none;
  padding: 3rem 0;
  text-align: center;

  &.header-banner--late-payment,
  &.header-banner--unverified-paypal {
    background: var(--rc-color-sentiment-negative-lighter);
    color: var(--rc-color-text-danger);
  }

  &.header-banner--vacation {
    background: var(--rc-color-palette-blue-500);
    color: var(--rc-color-palette-bw-white);
  }
}

// Used on Shops, Gift Cards, Wish Lists, etc
.collection-banner {
  padding: 38px 0;
  color: var(--rc-color-palette-bw-white);
  background: var(--rc-color-palette-gray-900);

  &.header-collection-banner {
    padding: 60px 0;
  }
}

.module-overlap { margin-top: -4rem; }

.curation-content {
  @include clearfix;
  box-sizing: border-box;
  padding: 24px;
}

p.curation-content-summary {
  font-size: 16px;
  line-height: 26px;

  @include mobile {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.collection-title {
  margin-top: -10px;
  font-size: 32px;
  font-weight: 500;
}

.search-title {
  margin-bottom: 4px;
}

.collection-results-count {
  display: block;
  font-size: 1.8rem;
  color: var(--rc-color-text-secondary);
}

.spacer {
  padding: 30px 0;
  height: 0px;
  margin-top: -7px;
  background: var(--rc-color-palette-gray-900);
}
