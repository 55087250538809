.shop-onboarding-errors {
  @include scaling(padding, 3.2rem);
  color: var(--rc-color-text-danger);
  background-color: var(--rc-color-sentiment-negative-lighter);
  border: solid 0.1rem var(--rc-color-sentiment-negative-light);
  border-radius: 0.4rem;
  font-size: 1.6rem;
}

.shop-onboarding-errors__title {
  @include scaling(margin-bottom, 1rem);
  font-size: 1.8rem;
  font-weight: 700;
}

.shop-onboarding-errors__subtitle {
  @include scaling(margin-bottom, 1rem);
}

.shop-onboarding-errors__items {
  list-style-type: disc;
  padding-left: 2rem;
}

.shop-onboarding-errors__item {
  margin-bottom: 0.4rem;

  &:last-child {
    margin-bottom: 0;
  }
}
