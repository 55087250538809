.cart-header {
  padding: 3rem 0;
  margin-bottom: var(--rc-space-15);
  border-bottom: solid 0.1rem var(--rc-color-border-primary);

  .cart-header__title {
    font-size: 2.4rem;
  }

  .cart-header__content {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: var(--rc-space-5);
  }
}
