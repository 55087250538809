.listing-photos-upload {
  display: flex;
  flex-direction: column;
}

.listing-photos-upload__guide__text {
  border-bottom: solid 0.1rem var(--rc-color-border-primary);
  margin-bottom: 3rem;

  @include mobile {
    order: 1;
  }
}

.listing-photos-upload__examples {
  @include mobile {
    order: 3;
    padding-top: 2rem;
  }
}

.listing-photos-upload__form__wrapper {
  @include mobile {
    border-bottom: solid 0.1rem var(--rc-color-border-primary);
    padding-bottom: 2rem;
    order: 2;
  }
}

.listing-photos-upload__x__platform__banner__wrapper {
  margin: 2rem 0;

  @include mobile {
    display: none;
  }
}

.listing-photos-upload__x__platform__banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.listing-photos-upload__x__platform__banner__content {
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
  }
}

.listing-photos-upload__x__platform__banner__icon {
  padding: 0 1rem;
  margin-right: 1rem;
}
