@use '../../config/configuration';
@use 'responsive';

@mixin full-bleed {
  --fullbleed-offset-left: var(--rc-space-gutter-page);
  --fullbleed-offset-right: var(--rc-space-gutter-page);
  margin-left: calc(-1 * var(--fullbleed-offset-left));
  margin-right: calc(-1 * var(--fullbleed-offset-right));
  padding-left: var(--fullbleed-offset-left);
  padding-right: var(--fullbleed-offset-right);
}
