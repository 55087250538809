@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.call-to-action {
  @include gap-row;
  padding: var(--rc-space-gap-row) 0;

  text-align: center;

  .dynamic-page__one-col & {
    @include viewport-bleed;
  }

  .dynamic-page__sidebar & {
    @include scaling(padding, 2rem);
  }

  @include mobile {
    @include viewport-bleed;
  }

  &.call-to-action--white {
    border-top: 0.1rem solid var(--rc-color-border-primary);
    border-bottom: 0.1rem solid var(--rc-color-border-primary);
    background: var(--rc-color-palette-bw-white);
  }

  &.call-to-action--offwhite {
    background: var(--rc-color-palette-bw-white);
  }

  &.call-to-action--blue {
    @include commons-svg-background('instruments-darken.svg');

    background-color: var(--rc-color-palette-blue-600);
    background-position: center;
    color: var(--rc-color-palette-bw-white);
  }

  &.call-to-action--orange {
    @include commons-svg-background('instruments-darken.svg');

    background-color: var(--rc-color-palette-vu-orange-500);
    background-position: center;
    color: var(--rc-color-palette-bw-white);
  }

  &.call-to-action[style] {
    background-size: cover;
    background-position: center;
    color: var(--rc-color-palette-bw-white);
  }
}

.call-to-action__inner {
  .dynamic-page__one-col & {
    @include site-wrapper;
  }

  @include mobile {
    margin-left: var(--rc-space-gutter-page);
    margin-right: var(--rc-space-gutter-page);
  }
}

.call-to-action__heading {
  @include responsive(font-size, 1.9em, 1.7em, 1.5em);

  line-height: 1.2;
  margin-bottom: 0.5em;

  .dynamic-page__sidebar & {
    font-size: 1.5em;
  }
}

.call-to-action__subheading {
  font-size: 0.9em;
}

.call-to-action__link-container {
  padding-top: 2rem;
  text-align: center;

  &:only-child {
    padding: 4rem 0;
  }

  .rc-button {
    white-space: normal;
  }
}
