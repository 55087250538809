@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow' as shadow;
@use '@reverbdotcom/cadence/styles/tools/mixins/responsive-hover' as hover;
@use '@reverbdotcom/cadence/styles/tools/mixins/body-min-height';

.buying-trade-ins-manager {
  padding: var(--rc-space-8) 0;

  @include mobile {
    padding: var(--rc-space-2) 0;
  }
}

.buying-trade-ins-manager__content {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-6);
}

.buying-trade-ins-manager__title {
  display: flex;
  padding-top: var(--rc-space-4);
  padding-bottom: var(--rc-space-4);
  font-size: var(--rc-font-size-800);
  font-weight: 700;
}

.buying-trade-ins-manager__card {
  border: var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-lg);
  background-color: var(--rc-color-background-card);
  transition: box-shadow 0.15s ease, background-color 0.15s ease;
  gap: var(--rc-space-4);
  padding: var(--rc-space-4);
  color: var(--rc-color-text-primary);
  margin-bottom: var(--rc-space-8);

  @include hover.responsive-hover {
    @include shadow.box-shadow;
    background-color: var(--rc-color-background-card-hovered);
  }

  &:focus-within {
    @include shadow.box-shadow;

    background-color: var(--rc-color-background-card-hovered);
  }

  @include mobile {
    margin-bottom: var(--rc-space-4);
  }
}

.buying-trade-ins-manager__card-inner {
  display: flex;
  gap: var(--rc-space-8);

  @include mobile {
    align-items: center;
    flex-direction: column;
    gap: var(--rc-space-4);
  }
}

.buying-trade-ins-manager__card-image {
  display: flex;
  flex-shrink: 1;
  align-items: center;

  img {
    width: 10rem;
    height: 10rem;

    @include mobile {
      width: 7rem;
      height: 7rem;
    }
  }
}

.buying-trade-ins-manager__card-seller-name,
.buying-trade-ins-manager__show-seller-name,
.buying-trade-ins-manager__show-form-title {
  font-family: var(--rc-font-family-inter);
  font-size: var(--rc-font-size-600);
  font-weight: var(--rc-font-weight-bold);
  line-height: var(--rc-font-line-height-title);
  letter-spacing: -0.0816rem;
  align-self: center;

  &.buying-trade-ins-manager__show-form-title--start {
    align-self: flex-start;
  }
}

.buying-trade-ins-manager__card-creation-date,
.buying-trade-ins-manager__show-creation-date {
  display: flex;
  flex-shrink: 1;
  font-size: var(--rc-font-size-350);
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;

  @include mobile {
    font-size: var(--rc-font-size-300);
  }
}

.buying-trade-ins-manager__card-cp-make,
.buying-trade-ins-manager__show-cp-make {
  font-family: var(--rc-font-family-inter);
  font-size: var(--rc-font-size-350);
  font-weight: var(--rc-font-weight-bold);
  line-height: var(--rc-font-line-height-utility);
}

.buying-trade-ins-manager__card-cp-name,
.buying-trade-ins-manager__show-cp-name {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--rc-font-family-inter);
  font-size: var(--rc-font-size-450);
  line-height: var(--rc-font-line-height-utility);
  font-weight: var(--rc-font-weight-medium);
  letter-spacing: -0.024rem;

  @include mobile {
    font-size: var(--rc-font-size-400);
  }
}

.buying-trade-ins-manager__card-icon {
  display: flex;
  align-self: center;
  justify-content: center;
  height: var(--rc-space-6);
  width: var(--rc-space-6);

  @include mobile {
    height: var(--rc-space-4);
    width: var(--rc-space-4);
  }
}

.buying-trade-ins-manager__show {
  @include body-min-height.body-min-height;
  width: 100%;
  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }
}

.buying-trade-ins-manager__show-details {
  @include body-min-height.body-min-height;
  display: flex;
  min-width: 50%;
  background-color: var(--rc-color-background-card);
  padding: var(--rc-space-6);
  flex-direction: column;

  @include mobile {
    width: 100%;
    // stylelint-disable-next-line declaration-no-important
    min-height: fit-content !important;
    padding: var(--rc-space-4);
  }
}

.buying-trade-ins-manager__show-conditions {
  @include body-min-height.body-min-height;
  display: flex;
  min-width: 50%;
  padding: var(--rc-space-6);
  justify-content: center;
  flex-shrink: 1;

  @include mobile {
    width: 100%;
    // stylelint-disable-next-line declaration-no-important
    min-height: fit-content !important;
    padding: var(--rc-space-6) var(--rc-space-4);
  }
}

.buying-trade-ins-manager__show-cp-image {
  img {
    width: 25rem;
    height: 25rem;

    @include mobile {
      width: 15rem;
      height: 15rem;
    }
  }
}

.buying-trade-ins-manager__show-inner {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--rc-space-4);
}

.buying-trade-ins-manager__show-form {
  display: flex;
  justify-content: center;
  flex-shrink: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--rc-space-6);
  max-width: 37rem;
}

.buying-trade-ins-manager__show-appraisal-button {
  display: flex;
  flex-direction: column;
  border: 0.15rem solid var(--rc-color-border-primary);
  background-color: var(--rc-color-background-page);
  border-radius: var(--rc-border-radius-lg);
  padding: var(--rc-space-4);
  gap: var(--rc-space-2);
  text-align: left;
  font-family: var(--rc-font-family-inter);

  &.buying-trade-ins-manager__show-appraisal-button--selected {
    border: 0.15rem solid var(--rc-color-border-selected);
  }

  @include mobile {
    width: 100%;
  }
}

.buying-trade-ins-manager__show-appraisal-condition {
  font-size: var(--rc-font-size-500);
  font-weight: var(--rc-font-weight-bold);
}

.buying-trade-ins-manager__show-appraisal-price {
  font-size: var(--rc-font-size-500);
  font-weight: var(--rc-font-weight-bold);
  color: var(--rc-color-palette-vu-orange-700);

  &.buying-trade-ins-manager__show-appraisal-price--original {
    text-decoration: line-through;
    color: var(--rc-color-palette-gray-600);
  }
}

.buying-trade-ins-manager__show-appraisal-description {
  font-size: var(--rc-font-size-400);
  font-weight: var(--rc-font-weight-medium);
}

.buying-trade-ins-manager__alert {
  margin-bottom: var(--rc-space-4);

  @include mobile {
    margin-top: var(--rc-space-3);
  }
}
