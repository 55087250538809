@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.chat-widget {
  position: fixed;
  bottom: var(--rc-space-4);
  right: var(--rc-space-4);
  z-index: 2002;

  @include mobile {
    body:has(.fixed-sell-search--open) & {
      bottom: calc(8rem + var(--rc-space-3));
    }
  }
}

.chat-widget__button {
  @include rc-button.filled($size: 'large');
  min-width: 16.2rem;

  @include mobile {
    min-width: 4.4rem;
    min-height: 4.4rem;
    padding: 0;
  }
}

.chat-widget__icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
}

.chat-widget__label {
  font-weight: var(--rc-font-weight-bold);
  font-size: 1.6rem;
  margin-left: var(--rc-space-2);

  @include mobile {
    display: none;
  }
}

.ada-chat-frame {
  @include mobile {
    body:has(.fixed-sell-search--open) & {
      margin-bottom: 7rem;
    }
  }
}
