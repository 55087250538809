.item2-stats {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: var(--rc-color-text-secondary);
  margin-top: 1rem;

  @include mobile {
    justify-content: initial;
    margin: 1rem -0.5rem 0;
  }
}

.item2-stats__stat-block {
  margin: 0.5rem 2rem 0.5rem 0;
  font-size: 1.3rem;

  @include mobile {
    margin: 0.5rem 1rem;
  }

  &:last-of-type {
    margin-right: 0;
  }
}
