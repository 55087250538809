.image-feature {
  @include gap-row;

  border: 0.1rem solid var(--rc-color-border-primary);
  background: white;
}

.image-feature__inner {
  display: flex;
  min-height: 20rem;

  @include mobile {
    display: block;
  }

  .dynamic-page__two-col & {
    @include tablet {
      display: block;
    }
  }

  .dynamic-page__one-col & {
    @include site-wrapper;
  }
}

.image-feature__text {
  @include scaling(padding, 4rem);

  flex: 0 0 45%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-basis: 55%;
  }

  .dynamic-page__two-col & {
    flex-basis: 50%;
  }
}

.image-feature__image {
  display: block;
  flex: 0 0 55%;
  background-size: cover;
  background-position: center;

  @include tablet {
    height: 0;
    padding-bottom: 56.25%;
    flex-basis: 45%;
  }

  .dynamic-page__one-col & {
    @include tablet {
      padding: 0;
      height: auto;
    }

    @include mobile {
      height: 0;
      padding-bottom: 56.25%;
    }
  }

  .dynamic-page__two-col & {
    flex-basis: 50%;
  }
}

.image-feature__subheading {
  border-bottom: 0.2rem solid var(--rc-color-palette-vu-orange-500);
  font-style: italic;
  font-weight: bold;
  padding-bottom: 0.2em;
  display: inline-block;
}

.image-feature__heading {
  font-size: 2em;
  font-weight: 300;
  margin: 0.5em 0;

  @include mobile {
    font-size: 1.4em;
    font-weight: normal;
  }
}

.image-feature__body {
  font-size: 0.9em;
}
