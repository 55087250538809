@use '../tools/mixins/rc-button';

.rc-close-button {

  // override default X icon size
  &:not(.rc-close-button--alt) {
    .rc-icon-button {
      .rc-button {
        font-size: 2rem;
      }
    }
  }

  // override button size variant
  .rc-icon-button {
    .rc-button {
      @include rc-button.size('small');
    }
  }
}
