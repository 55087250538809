.currency-exchange-approximation {
  min-height: 1em;

  .price-currency {
    font-size: inherit;
    font-weight: inherit;
  }
}

.price-currency {
  font-size: 0.6em;
  font-weight: normal;
  padding-left: 0.3em;

  &.price-currency--bold {
    font-weight: bold;
  }
}
