.express-sale-checkout__header {
  @include site-wrapper;
  @include site-wrapper--narrow;
  padding: 3rem 2rem;
}

.express-sale-checkout__header__title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.express-sale-checkout__content__wrapper {
  background-color: var(--rc-color-background-page-secondary);
}

.express-sale-checkout__content {
  @include site-wrapper;
  @include site-wrapper--narrow;

  padding: 3rem 2rem;

  ol {
    list-style: decimal;
    margin-left: 2rem;
    margin-bottom: 2rem;
  }

  ul, li {
    list-style: inherit;
  }
}

// errors section
.express-sale-checkout__errors {
  margin-bottom: 2rem;

  ul {
    padding-left: 2rem;
  }
}

.express-sale-checkout__errors__contact {
  padding-top: 1rem;
}

// item section
.express-sale-checkout__item {
  display: flex;
}

.express-sale-checkout__item__image {
  width: 15rem;
  max-height: 15rem;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 0.1rem;
  border-radius: $input-border-radius;
  border-color: var(--rc-color-border-primary);

  .csp-square-card__finish-indicator {
    margin-top: auto;
  }
}

.express-sale-checkout__item__content {
  width: 100%;
  margin-left: 3rem;
}

.express-sale-checkout__item__brand {
  font-size: 2rem;
}

.express-sale-checkout__item__title {
  font-size: 2rem;
  font-weight: 700;
}

.express-sale-checkout__item__year {
  margin-bottom: 1rem;
}

.express-sale-checkout__item__price {
  font-size: 1.5rem;

  b {
    font-size: 2.2rem;
    color: var(--rc-color-sentiment-positive-dark);
  }
}

.express-sale-checkout__item__shipping, .express-sale-checkout__item__shipping--mobile {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 0.1rem solid var(--rc-color-border-primary);

  img {
    height: 3rem;
  }
}

.express-sale-checkout__item__shipping {
  @include mobile {
    display: none;
  }
}

.express-sale-checkout__item__shipping--mobile {
  display: none;

  @include mobile {
    display: flex;
  }
}

// condition section
.express-sale-checkout__condition__title {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.express-sale-checkout__condition, .express-sale-checkout__shipping {
  display: flex;

  .rc-form-group {
    margin-bottom: 1rem;
  }

  .help-card {
    max-width: 40%;
    padding: 3rem 2rem 2rem;

    @include mobile {
      display: none;
    }
  }
}

.express-sale-checkout__condition__modifications {
  padding-top: 2rem;
}

// shipping address section
.express-sale-checkout__shipping__title {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.express-sale-checkout__shipping__form {
  width: 60%;

  @include mobile {
    width: 100%;
  }

  .formatted-address__highlight {
    line-height: $body-line-height;
  }
}

// terms and conditions section
.express-sale-checkout__terms__title {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

// submit / footer section
.express-sale-checkout__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 3rem;

  button {
    margin-left: 3rem;
  }

  @include mobile {
    flex-direction: column;
    margin-top: 2.2rem;

    button {
      width: 100%;
      margin-left: 0;
      margin-top: 2.2rem;
    }
  }
}

// loading modal
.express-sale-checkout__loading__container {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.express-sale-checkout__loading__bars {
  padding: 4rem 0;
}

.express-sale-checkout__loading__header {
  font-size: 3rem;
  font-weight: bold;
}

.express-sale-checkout__loading__content {
  font-size: 2.5rem;
  margin-bottom: 4rem;
}
