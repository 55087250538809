@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/responsive';
@use '@reverbdotcom/cadence/styles/tools/functions/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.sell__grid {
  @include site-wrapper;
  display: flex;

  .facet-sidebar {
    @include responsive(padding-top, 0, $mobile: 6rem);
  }

  &.sell__grid--has-results {
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
}

.sell__form {
  margin: 1rem 0;
  position: relative;
}

.sell__form__input {
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: none;

  &:hover {
    @include box-shadow;
  }
}

.sell__form__input__search {
  box-sizing: border-box;
  margin: 0;
  background: var(--rc-color-palette-bw-white);
  border-radius: $input-border-radius 0 0 $input-border-radius;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  border: solid 0.1rem var(--rc-color-border-primary);

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
    -webkit-appearance: none;
  }

  input[type='search'] {
    @include responsive(font-size, 2rem, 1.6rem);
    @include responsive(line-height, 2, 1.6);
    @include responsive(padding, 1.25rem 1.5rem, 1rem 1.25rem);
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    border: none;
    margin: 0;
    resize: none;
    box-shadow: none;
    width: 100%;

    .sell__form__input--active & {
      padding-right: 4rem;
    }

    &::placeholder {
      color: var(--rc-color-text-secondary);
      opacity: 1; // Fix for browsers that don't default to 1
    }
  }
}

.sell__form__clear {
  cursor: pointer;
  height: calc(100% - 0.2rem);
  position: absolute;
  right: 0;
  top: 0.1rem;
  width: 4.1rem;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4; // 1+ of the focused state for the adjacent input

  @include mobile {
    width: 3.1rem;
  }

  &:hover::after {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    background-image: svg.svg-url(close, scss-tokens.$rc-color-palette-gray-700);
  }
}

.sell__header {
  padding: 20rem 1rem;
  display: flex;
  align-items: center;
  position: relative;
  background-image: url('#{$discovery-ui-path}/images/sell-flow/sell-search-header.jpg');
  background-size: cover;
  background-position: center;

  @include mobile {
    padding: 10rem 0;
    min-height: 30rem;
    align-items: flex-start;
  }
}

.sell__header-v3 {
  position: relative;
  background-image: url('#{$discovery-ui-path}/images/sell-flow/sell-search-header-v3.jpg');
  background-size: cover;
  background-position: center;

  @include mobile {
    background-image: url('#{$discovery-ui-path}/images/sell-flow/sell-search-header-v3.jpg');
    align-items: flex-start;
  }
}

.sell__header-v3__container {
  @include site-wrapper;
  padding-top: var(--rc-space-10);
  padding-bottom: var(--rc-space-10);

  @include mobile {
    padding-top: var(--rc-space-8);
    padding-bottom: var(--rc-space-8);
  }
}

.sell__header-v3__content {
  color: var(--rc-color-text-primary);
  text-align: left;

  @include mobile {
    text-align: center;
  }
}

.sell__header-v3__subtitle {
  @include responsive(font-size, 2rem, 1.6rem);
  margin-bottom: 1rem;
  line-height: 1.3;

  @include mobile {
    display: none;
  }
}

.sell__header-v3__title {
  @include rc-text.title('900');
}

.sell__header__container {
  @include site-wrapper;
  z-index: 2;
  width: 100%;

  @include mobile {
    max-width: 45rem;
    margin: 0 auto;
  }
}

.sell__header__content {
  @include responsive(width, 50%, 58%, 100%);
  color: var(--rc-color-text-primary);
  text-align: left;

  @include mobile {
    text-align: center;
  }
}

.sell__header__subtitle {
  @include responsive(font-size, 2rem, 1.6rem);
  margin-bottom: 1rem;
  line-height: 1.3;

  @include mobile {
    display: none;
  }
}

.sell__header__title {
  @include responsive(font-size, 4rem, 3rem, 2.5rem);
  margin-bottom: 2rem;
  font-weight: bold;
}

.sell__search-v3__container {
  padding-bottom: var(--rc-space-10);
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
}

.sell__search-v3__autocomplete-container {
  max-width: 830px;
}

.sell__search-v3__container__heading {
  @include site-wrapper;
  padding-top: var(--rc-space-gutter-page);

  .sell-search-normal-label {
    label {
      font-weight: normal;
    }
  }
}

.sell__search-v3__bail-container {
  @include site-wrapper;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--rc-space-10);
  gap: var(--rc-space-6);
  background: var(--rc-color-background-page-secondary);
  border-radius: var(--rc-border-radius-lg);

  a:hover {
    color: var(--rc-color-palette-bw-white);
  }
}

.sell__search-v3__bail-container-prompt {
  font-size: var(--rc-font-size-600);
  letter-spacing: -0.816px;
}

.sell__search-v3__no-header {
  padding-top: var(--rc-space-12);
}

.sell__empty {
  position: relative;
  text-align: center;
  margin: 5vh auto;

  &-content {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    z-index: 2;

    &-image {
      width: 100%;
      max-width: 35rem;
    }

    h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
    }

    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    p {
      font-size: 2rem;
      margin-bottom: .5rem;
    }

    a {
      @include rc-button.filled;
      font-size: 2rem;
    }
  }
}

.sell__product-grid {
  --grid-columns: 4;

  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  gap: var(--rc-space-gap-grid);
  margin: var(--rc-space-gap-grid) 0;

  @include responsive.min-width(1425px) {
    --grid-columns: 5;
  }

  @include responsive.max-width(1152px) {
    --grid-columns: 3;
  }

  @include responsive.max-width(838px) {
    --grid-columns: 2;
  }

  @include mobile {
    --grid-columns: 1;
  }
}

.sell__product-grid__item {
  display: flex;
}
