@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.csp-listings {
  @extend %component-row-with-spacer;
}

.csp-listings__heading {
  @extend %component-row-heading;
}

.csp-listings__action {
  margin-top: $grid-gutter;
  text-align: center;

  a {
    @include rc-button.filled;
    @include fa-icon-after(arrow-right);
  }

  @include mobile {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
