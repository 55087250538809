.international-shipping-price {
  background-color: var(--rc-color-background-module-secondary);
  max-width: 50rem;
  padding: 2.4rem;
  margin-bottom: 2rem;
  border-radius: var(--rc-border-radius-md);

  & hr {
    margin-top: 2rem;
  }
}

.international-shipping-price__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.international-shipping-price__remove {
  @include button-as-link;
  display: block;
  font-size: 1.4rem;
  color: var(--rc-color-text-danger);
}
