.not-found {
  @include scaling(margin-top, 1rem);

  &.not-found--ended-sale {
    @include scaling(margin-bottom, 4rem);
  }
}

.not-found__message {
  font-size: 140%;
  max-width: 60rem;
  margin: 2rem auto;
  text-align: center;

  ~ .not-found__message {
    font-size: 110%;
    max-width: 45rem;
    margin: 1em auto;

    @include mobile {
      font-size: 90%;
    }
  }

  a {
    font-weight: bold;
  }
}

.not-found__image {
  display: block;
  max-width: 40rem;
  height: auto;
  margin: 1rem auto;
  padding: 0 2rem;

  svg {
    width: 100%;
    height: auto;
  }

  .not-found--ended-sale & {
    opacity: 1;
    max-width: 22rem;
  }

  @include mobile {
    display: none;
  }
}

.not-found__remove-shipping {
  box-sizing: border-box;
  padding: 2em;
  max-width: 30rem;
  margin: 0 auto;
  background: var(--rc-color-palette-bw-white);
  border-radius: 0.4rem;
}

.not-found__app-links {
  margin: 2rem auto;
  max-width: 35rem;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.not-found__app-link {
  &.not-found__app-link--ios {
    margin-right: 1rem;
  }

  a {
    display: block;
  }

  svg {
    height: 4rem;
    width: auto;
  }
}
