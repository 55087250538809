@use 'sass:math';
@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.cms-facets {
  @include gap-row;

  .dynamic-page__sidebar__bottom &,
  .dynamic-page__sidebar__top &,
  .dynamic-page__sidebar-mobile-overflow & {
    @include responsive(margin-bottom, var(--rc-space-20), var(--rc-space-12), var(--rc-space-8));
  }

  &.cms-facets--subcategory {
    @include mobile {
      display: none;
    }
  }
}

.cms-facet__options {

  @include mobile {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    padding-left: 0;
  }

  &.cms-facet__options--placeholder {
    @include loading-pulse;
  }
}

.cms-facet__option {
  margin-top: var(--rc-space-4);
  box-sizing: border-box;

  @include mobile {
    flex: 0 0 50%;
    margin-top: 0;
    margin-bottom: 1rem;
    padding: 0 0.5rem;

    &:nth-last-child(-n+2) {
      margin-bottom: 0;
    }
  }

  .cms-facet--collapsed > .cms-facet__options > & {
    &:nth-child(n+11) {
      max-height: 0;
      overflow: hidden;
      margin: 0;
      opacity: 0;
    }
  }
}

.cms-facet__toggle {
  @include button-as-link;
  @include fa-icon-after(angle-up);
  display: block;
  margin-top: 0.5em;
  font-size: 1.2rem;
  color: var(--rc-color-text-primary);

  @include responsive-hover {
    color: var(--rc-color-palette-vu-orange-500);
  }

  @include mobile {
    font-size: 1em;
    margin-left: auto;
    margin-right: auto;
    padding: 0.8rem 1.2rem;
    border-radius: 0.4rem;
    border: 0.1rem solid var(--rc-color-border-primary);
  }

  .cms-facet--collapsed > & {
    &::after {
      content: fa-icon-content(angle-down);
    }
  }
}

.cms-facet__link {
  @include group-link;
  display: block;
  box-sizing: border-box;
  color: var(--rc-color-text-primary);
  transition: color .1s;
  cursor: pointer;

  &.cms-facet__link--parent {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    @include svg.mask-icon-before(angle-left);

    margin-top: 0.2em;
    display: flex;
    align-items: center;

    @include mobile {
      display: none;
    }
  }

  &.cms-facet__link--current {
    @include responsive(font-size, 2.8rem, 2.4rem, 2rem);
    line-height: 1.143; // 3.2rem
    font-weight: bold;
    margin-bottom: var(--rc-space-6);

    @include mobile {
      display: none;
    }

    & ~ hr {
      display: none;
    }
  }

  @include responsive-hover {
    color: var(--rc-color-text-link-hovered);
  }

  @include mobile {
    position: relative;
  }
}

.cms-facet__link__parents-wrapper + .cms-facets__wrapper {
  margin-left: 1rem;

  @include mobile {
    margin-left: 0;
  }
}

.cms-facet__link__image__wrap {
  display: none;

  @include mobile {
    display: block;
  }
}

.cms-facet__link__image {
  @include commons-svg-background('instruments-darken.svg');
  background-color: var(--rc-color-palette-gray-300);
  background-repeat: repeat;
  background-position: center;
  height: 0;
  padding-top: 50%;
  box-sizing: border-box;

  @include mobile {
    background-size: auto 200%;
  }

  // with background image
  &[style] {
    background-size: cover;
  }
}

.cms-facet__link__text {
  flex: 1 1 auto;
  box-sizing: border-box;

  .cms-facet__options--placeholder & {
    width: 60%;
    background: var(--rc-color-palette-gray-100);
    height: 1.4em;
    border-radius: math.div(1.4em, 2);
  }

  @include mobile {
    padding: 0.3em 0.5em;
    background-color: var(--rc-color-palette-bw-white);
    color: var(--rc-color-text-primary);
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 90%;
    line-height: 1.2;
  }
}
