.cms-buying-guide-page-head__image {
  @include responsive(height, 25vh, 20vh, 12rem);
  @include responsive(min-height, 15rem, $mobile: 12rem);
  @include commons-svg-background('instruments-lighten.svg');

  background-color: var(--rc-color-palette-blue-600);
  max-width: none;
  max-height: 20rem;
  background-size: cover;
  background-position: center;
}

.cms-buying-guide-page-head__wrapper {
  @include site-wrapper;

  @include responsive(margin-top, -8rem, -6rem, -5rem);
}

.cms-buying-guide-page-head__content {
  position: relative;
  text-align: center;
}

.cms-buying-guide-page-head__text {
  @include scaling(padding, 2rem);

  border: 0.1rem solid var(--rc-color-border-primary);
  display: inline-block;
  background: var(--rc-color-background-page);
  position: relative;
}

.cms-buying-guide-page-head__label {
  @include responsive(font-size, 1.4rem, $mobile: 1.2rem);

  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: var(--rc-color-palette-vu-orange-500);
  color: var(--rc-color-palette-bw-white);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.3rem 0.7rem;

  @include mobile {
    padding: 0.1rem 0.5rem;
  }
}

.cms-buying-guide-page-head__title {
  @include responsive(font-size, 2.6em, 2.2em, 1.4em);

  font-weight: 400;
  line-height: 1.1;

  @include mobile {
    padding-top: 0.5rem;
  }
}

.cms-buying-guide-page-head__subtitle {
  @include responsive(font-size, 1.2em, 1em, 0.9em);

  font-weight: 400;
  line-height: 1.3;
  margin: 0.4em 0 0 0;
}
