.cms-heading {
  @include gap-row;

  text-align: center;
  background-size: cover;
  background-position: center;

  &.cms-heading--left {
    text-align: left;
  }
}

.cms-heading__inner {
  @include site-wrapper;

  .dynamic-page__one-col & {
    max-width: $site-width-cms;
  }

  .cms-heading--with-image & {
    @include scaling((padding-top padding-bottom), 8rem);
  }

  .cms-heading--left & {
    @include with-sidebar {
      padding-left: 0;
    }
  }
}

.cms-heading__text {
  &::after {
    @include scaling(margin-top, 2rem);

    content: '';
    display: block;
    width: 10%;
    border-bottom: 0.2rem solid var(--rc-color-palette-vu-orange-500);
    margin-left: auto;
    margin-right: auto;
  }

  .cms-heading--with-image & {
    text-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.3);
    color: var(--rc-color-palette-bw-white);

    &::after {
      display: none;
    }
  }

  .cms-heading--left & {
    &::after {
      margin-left: 0;
    }
  }
}

.cms-heading__title {
  @include responsive(font-size, 2em, 1.6em, 1.3em);

  font-weight: bold;
  line-height: 1.2;
}

.cms-heading__subtitle {
  @include responsive(font-size, 1.3em, 1.1em, 1em);

  margin-top: 0.5em;
  line-height: 1.2;
}

.cms-heading__breadcrumbs {
  color: var(--rc-color-text-primary);
}
