.rc-password-input {
  position: relative;

  input[type='password'],
  input[type='text'] {
    padding-right: var(--rc-space-8); // make room for the eyeball icon
  }
}

.rc-password-input__toggle {
  @include button-reset;

  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: $input-padding-vertical;
  width: 3rem;
  color: var(--rc-color-text-secondary);
  opacity: 1;
  transition: opacity 0.2s;

  @include responsive-hover {
    opacity: 0.8;
  }

  .rc-password-input--shown & {
    color: var(--rc-color-text-primary);
    opacity: 1;
  }
}
