.payment-icon {
  display: inline-block;
  width: 5.2rem;

  &.payment-icon--fullwidth {
    display: block;
    width: 100%;
  }

  &::before {
    @include svg-background-image('payment-icon-placeholder');
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 67.31%; // approx ratio of a credit card
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  // loop over blessed arrays
  @each $list in $payment-methods {
    @each $card in $list {
      &.payment-icon--#{$card}::before {
        @include svg-background-image('payment-icon-#{$card}');
      }
    }
  }

  // Temporary: shim things for core names that differ from these
  &.payment-icon--mastercard::before {
    @include svg-background-image('payment-icon-mc');
  }

  &.payment-icon--union-pay::before {
    @include svg-background-image('payment-icon-cup');
  }

  &.payment-icon--diners-club::before {
    @include svg-background-image('payment-icon-diners');
  }

  &.payment-icon--small {
    width: 4rem;
  }
}
