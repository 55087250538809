.cart-item-row {
  // Ensures that actions will appear beneath the title by spanning
  // the image and pricing by multiple rows in the desktop layout
  --multiple-rows-span: 3;

  display: grid;
  grid-template-columns: [image-column] min(max(8rem, 20%), 14rem) [title-column] auto [pricing-column] 20%;
  grid-template-rows: repeat(min-content);

  @include tablet {
    grid-template-columns: [image-column] 20% [title-column] 80%;
  }

  .cart-item-row__image {
    grid-column: image-column;
    grid-row: 1 / span var(--multiple-rows-span);

    img {
      border-radius: var(--rc-border-radius-sm);
      height: auto;
      display: block;
      width: 100%;
    }

    @include tablet {
      margin-bottom: 2rem;
    }
  }

  .cart-item-row__title {
    margin-left: var(--rc-space-gap-grid);

    grid-column: title-column;
    grid-row: 1;
  }

  .cart-item-row__pricing {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    grid-column: pricing-column;
    grid-row: 1 / span var(--multiple-rows-span);
    gap: 0.5rem;

    .cart-item-row__price-block {
      text-align: right;

      @include tablet {
        text-align: left;
      }
    }

    .cart-item-row__quantity-select {
      width: 6rem;
    }

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      gap: 0;

      margin-left: var(--rc-space-gap-grid);
      margin-bottom: 2rem;

      grid-column: title-column;
      grid-row: 2;
    }
  }

  .cart-item-row__actions {
    --actions-row: 2;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
    margin-left: var(--rc-space-gap-grid);
    grid-column: title-column;
    grid-row: var(--actions-row);

    @include tablet {
      margin-left: 0;
      grid-column: image-column / span 2;
      --actions-row: 3;

      &>div {
        flex: 1;
      }
    }

    @include mobile {
      margin-top: 0;
      --actions-row: 4;
    }
  }

  .cart-item-row__alerts {
    margin-top: var(--rc-space-2);
    grid-column: image-column / span 3;
    grid-row: calc(var(--actions-row) + 1);
  }
}

.cart-item-row__actions__bottom {
  --actions-row: 2;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  grid-column: title-column;
  grid-row: var(--actions-row);
  justify-content: space-between;

  @include tablet {
    margin-left: 0;
    grid-column: image-column / span 2;
    --actions-row: 3;

    &>div {
      flex: 1;
    }
  }
}
