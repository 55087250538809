@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.sell-yours-cta {
  display: flex;
  flex-direction: column;
}

.sell-yours-cta__image {
  width: 100%;
}

.sell-yours-cta__content {
  padding: 2rem;
  color: var(--rc-color-text-primary);
  background-color: var(--rc-color-palette-vu-orange-100);
}

.sell-yours-cta__title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.sell-yours-cta__copy {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.sell-yours-cta__button {
  @include rc-button.outlined;
  width: 100%;
}
