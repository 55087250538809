.featured-listing-header {
  @include site-wrapper;

  @include mobile {
    padding: 0;
  }
}

.featured-listing-header__inner {
  display: flex;
  width: 100%;
  padding: 3rem 0;

  @include tablet {
    padding: 2rem 0;
  }

  @include mobile {
    flex-direction: column;
    padding: 1rem 0 2rem;
  }
}

.featured-listing-header__image {
  @include scaling(margin-right, 3rem);

  width: 20%;

  @include mobile {
    width: 100%;
    padding: 0 var(--rc-space-gutter-page);
    box-sizing: border-box;
  }

  .featured-listing-header--placeholder & {
    @include loading-pulse;
    height: 18vw;
    background-color: var(--rc-color-palette-gray-200);

    @include mobile {
      width: 100%;
      height: 100vw;
    }
  }
}

.featured-listing-header__content {
  display: flex;
  width: 80%;
  align-items: center;

  @include tablet {
    flex-direction: column;
    justify-content: center;
  }

  @include mobile {
    width: 100%;
  }
}

.featured-listing-header__primary {
  flex: 1;
  margin-right: 3rem;

  @include tablet {
    flex: unset;
    width: 100%;
    margin-right: 0;
  }
}

.featured-listing-header__categories {
  @include smooth-scroll;

  font-size: 0.8em;
  opacity: 0.7;
  margin-bottom: 0.5em;

  a {
    color: var(--rc-color-text-primary);

    @include responsive-hover {
      color: var(--rc-color-palette-blue-600);
    }
  }

  li {
    display: inline-block;

    + li {
      &::before {
        @extend %fa-icon-base;

        content: fa-icon-content(angle-right);
        opacity: 0.5;
        margin: 0 0.6em;
      }
    }

    .featured-listing-header--placeholder & {
      @include loading-pulse;
      height: 0.8em;
      width: 15%;
      background-color: var(--rc-color-palette-gray-200);
      margin-right: 1rem;
      margin-bottom: 1rem;

      + li {
        &::before {
          display: none
        }
      }
    }
  }

  @include tablet {
    margin-bottom: 0.5rem;
  }

  @include mobile {
    margin: 2rem 0 1rem;
    padding: 0 var(--rc-space-gutter-page);
    font-size: 0.8em;
    white-space: nowrap;
    overflow: auto;
  }
}

.featured-listing-header__title {
  @include responsive(font-size, 3.5rem, 3rem, 2rem);
  margin-bottom: 1rem;

  a {
    color: var(--rc-color-text-primary);
  }

  @include mobile {
    margin-top: 1rem;
    padding: 0 var(--rc-space-gutter-page);
  }

  .featured-listing-header--placeholder & {
    @include loading-pulse;
    @include responsive(height, 4rem, 3.5rem, 2.5rem);
    width: 60%;
    background-color: var(--rc-color-palette-gray-200);

    @include mobile {
      margin-left: var(--rc-space-gutter-page);
    }
  }
}

.featured-listing-header__finish-filter {
  margin-bottom: 2rem;
}

.featured-listing-header__condition-indicator {
  margin-bottom: 2rem;

  @include mobile {
    padding: 0 var(--rc-space-gutter-page);
  }
}

.featured-listing-header__section-heading {
  text-transform: uppercase;
  font-weight: bold;
  opacity: 0.6;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;

  .featured-listing-header__finish-filter & {
    @include mobile {
      padding: 0 var(--rc-space-gutter-page);
    }
  }
}

.featured-listing-header__secondary {
  min-width: 25rem;
  box-sizing: border-box;
  text-align: center;

  @include tablet {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: left;
  }

  @include mobile {
    width: 100%;
    padding: 0 var(--rc-space-gutter-page);
  }
}

.featured-listing-header__pricing {
  @include responsive(font-size, 2.1rem, 1.8rem);
  margin-bottom: 2rem;

  @include tablet {
    margin-bottom: 0;
    order: 2;
    text-align: right;
  }

  @include mobile {
    margin-bottom: 2rem;
    text-align: left;
    width: 100%;
  }

  .featured-listing-header--placeholder & {
    @include loading-pulse;
    height: 4rem;
    width: 70%;
    margin-left: 15%;
    background-color: var(--rc-color-palette-gray-200);

    @include tablet {
      width: 25%;
      margin-left: 0;
    }

    @include mobile {
      width: 25%;
      margin-left: 0;
      margin-right: 70%;
    }
  }
}

.featured-listing-header__actions {
  width: 100%;

  @include tablet {
    order: 3;
    display: flex;
    flex-direction: row-reverse;
    flex: 1 0 100%;
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  @include mobile {
    margin-top: 0;
  }

  .featured-listing-header--placeholder & {
    @include loading-pulse;
    height: 4rem;
    background-color: var(--rc-color-palette-gray-200);
  }
}

.featured-listing-header__button-wrapper {
  margin: 0.5rem 0;
  flex: 1;

  @include tablet {
    margin: 0.25rem;
  }

  @include mobile {
    &:first-of-type {
      flex: 1 0 90%;
    }
  }
}

.featured-listing-header__shop-info {
  margin-top: 1rem;

  @include tablet {
    order: 1;
    margin-top: 0;
    margin-right: 2rem;
  }

  @include mobile {
    margin-bottom: 2rem;
  }

  .featured-listing-header--placeholder & {
    @include loading-pulse;
    height: 8rem;
    width: 30%;
    background-color: var(--rc-color-palette-gray-200);
  }

  .featured-listing-header__primary & {
    @include tablet {
      display: none;
    }
  }

  .featured-listing-header__secondary & {
    display: none;

    @include tablet {
      display: block;
    }
  }
}
