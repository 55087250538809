@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.price-guide-tool-usage {
  @include mobile {
    padding-left: 3vw;
    padding-right: 3vw;
  }
}

.price-guide-tool-usage__title {
  @include rc-text.title('700');
  margin-top: 12rem;

  @include tablet {
    margin-top: 6rem;
  }
}

.price-guide-tool-usage__row {
  display: flex;
  justify-content: space-between;
  gap: var(--rc-space-gap-grid);
  margin-top: var(--rc-space-3);

  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
}

.price-guide-tool-usage__row__item {
  flex: 1;
  background: var(--rc-color-background-module-price-guide-secondary);
  padding: 2rem;
  border-radius: var(--rc-border-radius-md);
}


.price-guide-tool-usage_row_item_image-container {
  width: 4rem;
  height: 4rem;
  margin-bottom: var(--rc-space-4);
}
