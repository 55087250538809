.site-module {
  background-color: var(--rc-color-background-module);
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-primary);

  &.site-module--loading {
    @include loading-pulse;
    padding: 1.6rem;
    margin: 0 0 2rem;

    &::before,
    &::after {
      content: '';
      display: block;
      background-color: var(--rc-color-background-loading-placeholder);
      height: 2rem;
      border-radius: var(--rc-border-radius-primary);
    }

    &::before {
      width: 100%;
      margin-bottom: 1rem;
    }

    &::after {
      width: 65%;
    }
  }

  + .site-module {
    margin-top: 2rem;
  }
}

.site-module__header,
.site-module__content,
.site-module__footer {
  @include scaling(padding, 2rem);
}

.site-module__header {
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
}

.site-module__title {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;
}

.site-module__subtitle {
  font-size: 1.4rem;
  line-height: 1.2;
  color: var(--rc-color-text-secondary);
  margin: 0.5rem 0 0;
}

.site-module__footer {
  border-top: 0.1rem solid var(--rc-color-border-primary);
  background-color: var(--rc-color-background-module-secondary);
  border-radius: 0 0 var(--rc-border-radius-primary) var(--rc-border-radius-primary);
}
