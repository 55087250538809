.cms-search {
  @include gap-row;

  &[style] {
    background-position: center;
    background-size: cover;
  }

  .dynamic-page__one-col & {
    @include site-wrapper;
  }

  .dynamic-page__sidebar & {
    border: 0.1rem solid var(--rc-color-border-primary);

    padding: 1.5rem;
    background: white;
    margin-bottom: 2rem;
  }
}

.cms-search__inner {
  .dynamic-page__one-col &,
  .dynamic-page__two-col__main & {
    @include scaling(padding, 3rem);
    @include responsive(display, flex, block);

    border: 0.1rem solid var(--rc-color-border-primary);
    align-items: center;
    background: white;
  }

  .cms-search[style] & {
    @include scaling(padding, 8rem);

    box-shadow: none;
    background: none;
  }
}

.cms-search__label {
  @include responsive(font-size, 2.4rem, 1.6rem, 1.4rem);
  @include responsive(font-weight, normal, bold);
  @include responsive(margin-right, 1rem, 0);

  @include tablet {
    margin-bottom: 0.2rem;
  }

  .dynamic-page__sidebar & {
    font-size: 0.9em;
    font-weight: bold;
  }

  .cms-search[style] & {
    color: var(--rc-color-palette-bw-white);
    text-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.3);
  }
}

.cms-search__form {
  flex: 1;
}
