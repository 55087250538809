.rc-modal-base[data-status='unmounted'] {
  display: none;
}

.rc-modal-base__overlay {
  z-index: var(--z-index-modal);
  background-color: var(--rc-color-background-overlay-modal);
  opacity: 1;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.4, 0.0, 0.2, 1); // Material's FastOutSlowIn

  .rc-modal-base[data-status='open'] &,
  .rc-modal-base[data-status='close'] & {
    transition-duration: 0.3s;
  }

  .rc-modal-base[data-status='initial'] &,
  .rc-modal-base[data-status='close'] & {
    opacity: 0;
  }

  // Overrides for modal stacking
  .rc-modal-base:has(~ .rc-modal-base[data-status='open']) &,
  .rc-modal-base:has(~ .rc-modal-base[data-status='open'] ~ .rc-modal-base[data-status='close']) & {
    opacity: 0.05;
  }

  .rc-modal-base:has(~ .rc-modal-base[data-status='close']) & {
    opacity: 1;
  }
}

.rc-modal-base__wrapper {
  position: fixed;
  inset: 0;
  z-index: var(--z-index-modal);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  // removing pointer events to prevent this wrapper from blocking background content clicks for non-modal instances
  pointer-events: none;
}

.rc-modal-base__content {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  align-self: center;
  justify-self: center;
  box-sizing: border-box;
  opacity: 1;
  transform: translate(0, 0);
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(.4, 0.0, 0.2, 1); // Material's FastOutSlowIn

  // Pointer events are removed from the positioning elements to prevent them from interfering with close on outside press behavior.
  // Pointer events are then re-enabled on direct descendants of this
  & > div {
    pointer-events: auto;
  }

  // Animations
  .rc-modal-base[data-status='open'] & {
    transition-duration: 0.25s;
  }

  .rc-modal-base[data-status='close'] & {
    transition-duration: 0.15s;
  }

  .rc-modal-base[data-status='initial'] &,
  .rc-modal-base[data-status='close'] & {
    opacity: 0;
  }

  .rc-modal-base[data-status='initial'] & {
    transform: translate(0, var(--rc-space-6));
  }


  // Positioning Overrides
  &.rc-modal-base__content--bottom {
    align-self: flex-end;
  }

  &.rc-modal-base__content--drawer {
    padding: 0;

    &.rc-modal-base__content--left {
      align-self: stretch;
      justify-self: flex-start;

      .rc-modal-base[data-status='initial'] & {
        transform: translate(calc(-1 * var(--rc-space-20)), 0);
      }
    }
  }
}

.rc-modal-base__close-button {
  position: absolute;
  top: var(--rc-space-1);
  right: var(--rc-space-1);
}
