@use 'sass:math';
@use '../tools/mixins/loading-pulse' as loading;

$rc-grid-cols: 12;
$rc-grid-spannable-rows: 4;

@function single-row-grid-flex-basis($colsToSpan) {
  // This is a bit of a magical function. Comments and variable names here hopefully help out future-us...
  // TL;DR
  // We take the width of the whole grid, subtract all the gaps and divide by 12 to get a single column's width.
  // Then, we multiply that by $colsToSpan and add back in the gap widths

  // This function is needed to calculate the 'flex-basis' value below.
  // CSS grid uses 'grid-column' to do similar magically, but when we collapse into a single row,
  // we need to leverage Flexbox (and flex-basis specifically) instead.

  // Width of the whole grid (100%), minus the sum of all the gaps (fixed rems)
  $widthOfGridMinusGaps: '(100% - (var(--gap) * (var(--grid-cols) - 1)))';

  // Width of just a single column = $widthOfGridMinusGaps / 12 columns
  $widthOfASingleColumn: '(#{$widthOfGridMinusGaps} / var(--grid-cols))';

  // The amount of gaps this columns will span
  $widthOfGapsToSpan: '(var(--gap) * #{$colsToSpan - 1})';

  @return(calc((#{$widthOfASingleColumn} * #{$colsToSpan}) + #{$widthOfGapsToSpan}));
}

@mixin colWidth($span) {
  // Simply pass $span through calls for the CSS grid. The other logic in here is
  // just for the Flexbox calls needed for `single-row-on-x` things.
  grid-column: span $span;

  &.rc-grid-item--subgrid {
    grid-template-columns: repeat($span, 1fr);
  }

  // These are here for future grepability
  // .rc-grid--single-row-on-all
  // .rc-grid--single-row-on-tablet
  // .rc-grid--single-row-on-mobile
  [class*='rc-grid--single-row-on-'] & {
    flex-shrink: 0;
    flex-basis: single-row-grid-flex-basis($span);
  }
}

.rc-grid {
  // disabling bc we use this value defined above in order to do looping below
  // stylelint-disable-next-line cadence/no-scss-tokens
  --cols-desktop: #{$rc-grid-cols};
  --cols-tablet: var(--cols-desktop);
  --cols-mobile: var(--cols-tablet);
  --grid-cols: var(--cols-desktop);
  --rows: 'auto';
  --gap: var(--rc-space-gap-grid);
  --mobile-display: 'block';

  display: grid;
  grid-template-columns: repeat(var(--grid-cols), 1fr);
  grid-auto-rows: min-content;
  gap: var(--gap);

  &.rc-grid--single-row-on-all {
    display: flex;
  }

  &.rc-grid--disable-and-pulse {
    @include loading.loading-pulse;
    pointer-events: none;
  }

  @include tablet {
    --grid-cols: var(--cols-tablet);
    &.rc-grid--single-row-on-tablet {
      display: flex;
    }
  }

  @include mobile {
    --grid-cols: var(--cols-mobile);
    &.rc-grid--single-row-on-mobile {
      display: flex;
    }
  }
}

.rc-grid-item {
  // variables are overridden in RCGrid.tsx
  --item-order-d: 0;
  --item-order-t: var(--item-order-d);
  --item-order-m: var(--item-order-t);
  order: var(--item-order-d);

  .rc-grid--flexbox-items & {
    display: flex; // flexbox here get us symmetry with how Tiles worked
  }

  &:empty {
    display: none;
  }

  &.rc-grid-item--subgrid {
    display: grid;
    grid-auto-rows: min-content;
    gap: var(--gap);
  }

  &.rc-grid-item--hide-d,
  &.rc-grid-item--hide-t-and-d {
    display: none;
  }

  @include tablet {
    order: var(--item-order-t);

    &.rc-grid-item--hide-t,
    &.rc-grid-item--hide-t-and-d,
    &.rc-grid-item--hide-t-and-m {
      display: none;
    }

    &.rc-grid-item--hide-d {
      display: flex;

      &:empty {
        display: none;
      }
    }
  }

  @include mobile {
    order: var(--item-order-m);

    &.rc-grid-item--hide-m,
    &.rc-grid-item--hide-m-and-t {
      display: none;
    }

    &.rc-grid-item--hide-t-and-d {
      display: flex;

      &:empty {
        display: none;
      }
    }

    &.rc-grid-item--hide-d {
      display: flex;

      &:empty {
        display: none;
      }
    }
  }
}

.rc-grid-item {
  // loop over 12 columns
  @for $i from 1 through $rc-grid-cols {
    &.rc-grid-item--col-d-#{$i} {
      @include colWidth($i);
    }
  }

  // loop over 4 max rows
  @for $i from 1 through $rc-grid-spannable-rows {
    &.rc-grid-item--row-d-#{$i} {
      grid-row: span #{$i};
    }
  }

  @include tablet {
    @for $i from 1 through $rc-grid-cols {
      &.rc-grid-item--col-t-#{$i} {
        @include colWidth($i);
      }
    }

    @for $i from 1 through $rc-grid-spannable-rows {
      &.rc-grid-item--row-t-#{$i} {
        grid-row: span #{$i};
      }
    }
  }

  @include mobile {
    @for $i from 1 through $rc-grid-cols {
      &.rc-grid-item--col-m-#{$i} {
        @include colWidth($i);
      }
    }

    @for $i from 1 through $rc-grid-spannable-rows {
      &.rc-grid-item--row-m-#{$i} {
        grid-row: span #{$i};
      }
    }
  }
}
