@use '@reverbdotcom/cadence/styles/tools/mixins/gutter';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.breadcrumbs {
  @include rc-text.default;

  a {
    @include text-link;
  }

  @include mobile {
    font-size: 0.8em;
  }

  &.breadcrumbs--scroll {
    @include gutter.full-bleed;

    display: flex;
    padding-bottom: var(--rc-space-gap-grid);
    overflow: auto;
  }
}

.breadcrumbs__breadcrumb {
  display: inline-block;

  .breadcrumbs--scroll & {
    white-space: nowrap;
  }

  + .breadcrumbs__breadcrumb {
    &::before {
      @extend %fa-icon-base;

      content: fa-icon-content(angle-right);
      opacity: 0.5;
      margin: 0 0.6em;
    }
  }

  // the Breadcrumbs component iterates over children,
  // and it's simpler to just hide empty divs here
  // than for that component to know whether its children
  // will render null
  &:empty {
    display: none;
  }
}
