@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.verification-task-list__title {
  font-size: 1.6rem;
  font-weight: 700;

  &.verification-task-list__title--alert {
    @include fa-icon-before(exclamation-triangle, $margin-right: 0.5em);
    color: var(--rc-color-text-danger);
  }
}

.verification-task-list__item {
  padding: 1.8rem 0;

  &:first-child {
    padding-top: 0;

    @include mobile {
      padding-top: 1.8rem;
      border-top: solid 0.1rem var(--rc-color-border-primary);
    }
  }

  &:last-child {
    border-bottom: 0;
  }
}

.verification-task-list__item__inner {
  display: flex;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }
}

.verification-task-list__item__title {
  font-size: 1.6rem;
  font-weight: 700;
}

.verification-task-list__item__description {
  color: var(--rc-color-text-secondary);
  margin-top: 0.4rem;
  margin-bottom: 0;
}

.verification-task-list__item__actions {
  flex-shrink: 0;
  margin-left: 1.8rem;
  display: flex;
  align-items: center;

  @include mobile {
    margin-left: 0;
    margin-top: 1rem;
  }
}

.verification-task-list__item__status {
  font-size: 1.4rem;
  font-weight: 700;
  display: block;

  &.verification-task-list__item__status--not-required {
    color: var(--rc-color-text-secondary);
    font-style: italic;
  }

  &.verification-task-list__item__status--unknown {
    color: var(--rc-color-text-secondary);
    font-style: italic;
  }

  &.verification-task-list__item__status--pending {
    @include fa-icon-before(clock-o, $margin-right: 0.5em);
    color: var(--rc-color-palette-vu-orange-500);
  }

  &.verification-task-list__item__status--passed {
    @include fa-icon-before(check, $margin-right: 0.5em);
    color: var(--rc-color-sentiment-positive-dark);
  }

  &.verification-task-list__item__status-failed {
    @include fa-icon-before(close, $margin-right: 0.5em);
    color: var(--rc-color-text-danger);
  }
}

.verification-task-list__item__action {
  @include rc-button.filled;
}

.verification-task-list__alert {
  background: var(--rc-color-sentiment-warning-lighter);
  border-radius: 0.4rem;
  padding: 1.2rem;
  margin-bottom: 2rem;

  &.verification-task-list__alert--error {
    color: var(--rc-color-text-danger);
    background-color: var(--rc-color-sentiment-negative-lighter);
  }

  &.verification-task-list__alert--info {
    background-color: var(--rc-color-palette-blue-100);
    color: var(--rc-color-palette-blue-700);
  }

  &.verification-task-list__alert--success {
    color: var(--rc-color-sentiment-positive-dark);
    background-color: var(--rc-color-sentiment-positive-lighter);
  }
}

.verification-task-list__alert-title {
  margin-bottom: 0.4rem;
  font-weight: 700;

  &.verification-task-list__alert-title--calendar {
    @include fa-icon-before(calendar, $margin-right: 0.5em);
  }
}
