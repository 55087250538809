@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.download-app {
  background-color: var(--rc-color-palette-gray-100);

  .in-mobile-app & {
    display: none;
  }
}

.download-app__container {
  @include site-wrapper;
  display: flex;

  @include mobile {
    flex-direction: column-reverse;
  }
}

.download-app__image-container {
  width: 50%;
  display: flex;
  justify-content: flex-end;

  @include tablet {
    width: 25rem;
  }

  @include mobile {
    display: block;
    text-align: center;
    width: 100%;
    max-height: 15rem;
    overflow: hidden;
  }
}

.download-app__image {
  width: auto;
  height: 40rem;
  margin: 0 auto;

  @include mobile {
    height: auto;
    width: 25rem;
  }
}

.download-app__content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include tablet {
    align-items: center;
    text-align: center;
    width: 100%;
  }

  @include mobile {
    padding-top: 2rem;
  }
}

.download-app__title {
  @include rc-text.title('900');
}

.download-app__subtitle {
  @include rc-text.title('500');
}

.download-app__links {
  display: flex;
}

.download-app__link {
  height: 4rem;
  width: 14rem;
  margin-right: 1rem;
  background-size: contain;
  background-repeat: no-repeat;

  @include mobile {
    width: 10rem;
  }
}

.download-app__link-alt {
  font-size: 0;
}

