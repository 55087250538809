@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.collection-card {
  @include scaling(padding, 2rem);
  display: flex;
  border: solid 0.1rem var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-primary);

  @include mobile {
    display: block;
  }

  & + .collection-card {
    margin-top: 3rem;
  }
}

.collection-card__image-wrapper {
  flex: 0 0 20rem;
  align-self: flex-start;

  @include mobile {
    width: 100%;
  }
}

.collection-card__main {
  flex-grow: 1;
  padding-right: 1rem;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;

  @include mobile {
    padding: 2rem 0 0;
  }
}

.collection-card__header {
  margin-bottom: 2rem;
}

.collection-card__title {
  font-size: 2.4rem;
  font-weight: bold;

  @include mobile {
    font-size: 2rem;
  }
}

.collection-card__title__link {
  color: inherit;

  @include responsive-hover {
    text-decoration: underline;
    color: inherit;
  }
}

.collection-card__description {
  font-size: 1.8rem;
  color: var(--rc-color-text-secondary);
  margin-bottom: 2rem;

  @include mobile {
    font-size: 1.6rem;
  }

  > :last-child {
    margin-bottom: 0;
  }

  > :first-child {
    margin-top: 0;
  }
}

.collection-card__estimate-wrapper {
  margin-bottom: 2rem;
}

.collection-card__actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 20rem;

  @include mobile {
    width: 100%;
  }
}

.collection-card__action {
  margin-bottom: var(--rc-space-6);

  @include mobile {
    width: 100%;
  }

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  &.collection-card__action--primary {
    @include rc-button.muted;
  }

  &.collection-card__action--secondary {
    @include button-as-link;
    text-align: center;
  }
}

.collection-card__footer {
  display: flex;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }
}

.collection-card__tags {

  @include mobile {
    margin-bottom: 2rem;
  }
}
