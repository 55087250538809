.pullquote {
  .dynamic-page__one-col & {
    @include site-wrapper;
    @include site-wrapper--cms;
  }

  &.pullquote--center {
    @include gap-row;
  }
}

.pullquote__inner {
  width: 30%;
  margin: 1.5em auto;

  @include tablet {
    width: 40%;
  }

  @include mobile {
    border-top: 0.1rem solid var(--rc-color-border-primary);
    border-bottom: 0.1rem solid var(--rc-color-border-primary);
  }

  .pullquote--center & {
    @include scaling((padding-top padding-bottom), 3rem);

    border-top: 0.1rem solid var(--rc-color-border-primary);
    border-bottom: 0.1rem solid var(--rc-color-border-primary);

    .dynamic-page__one-col & {
      @include full-bleed;

      width: 100%;
    }
  }

  .pullquote--left & {
    float: left;
    margin-right: 1.5em;

    .dynamic-page__one-col & {
      @include site-wrapper-overhang-left;
    }

    @include mobile {
      @include full-bleed;
      margin-top: 1.5em;
      margin-bottom: 1.5em;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      float: none;
      width: 100%;
      border-top: 0.1rem solid var(--rc-color-border-primary);
      border-bottom: 0.1rem solid var(--rc-color-border-primary);
    }
  }

  .pullquote--right & {
    float: right;
    margin-left: 1.5em;

    .dynamic-page__one-col & {
      @include site-wrapper-overhang-right;
    }

    @include mobile {
      @include full-bleed;
      margin-top: 1.5em;
      margin-bottom: 1.5em;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      float: none;
      width: 100%;
      border-top: 0.1rem solid var(--rc-color-border-primary);
      border-bottom: 0.1rem solid var(--rc-color-border-primary);
    }
  }
}

.pullquote__attribution {
  font-style: italic;
  margin-top: 0.5em;

  a {
    color: var(--rc-color-text-primary);
    transition: color .2s;

    &:hover {
      color: var(--rc-color-palette-vu-orange-500);
      text-decoration: underline;
    }
  }

  &::before {
    content: '—';
  }
}

.pullquote__content {
  p {
    @include responsive(font-size, 1.8em, 1.6em, 1.4em);
    @include responsive(font-weight, 300, $mobile: 400);

    line-height: 1.3;
    margin: 0;

    &::before {
      content: '“';
    }

    &::after {
      content: '”';
    }
  }

  .pullquote--long & {
    p {
      @include responsive(font-size, 1.3em, 1.1em);
    }
  }
}

