@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.newsletter-signup {
  .reverb-modal & {
    text-align: center;
  }

  .homepage-footer & {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    max-width: 60%;
    color: white;

    @include mobile {
      max-width: 90%;
    }
  }

  .newsletter-signup-module & {
    max-width: $site-width-narrow;
    margin: 0 auto;
  }
}

.newsletter-signup__heading {
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 1rem;

  .reverb-modal & {
    font-size: 1.8em;
    font-weight: normal;
    margin: 0;
  }

  .homepage-marketing-callout & {
    @include rc-text.title('900');
    margin-bottom: var(--rc-space-4);
    text-align: left;
  }

  .site-footer & {
    font-size: 1.2em;
  }
}

.newsletter-signup__subheading {
  font-size: 1.2em;
  line-height: 1.2;
  margin-bottom: 1em;

  .site-footer & {
    font-size: 1em;
  }
}

.newsletter-signup__fineprint {
  @include rc-text.fine;
  opacity: 0.8;
  margin-top: var(--rc-space-3);

  .homepage-marketing-callout & {
    opacity: 1;
    color: var(--rc-color-text-secondary);
  }
}
