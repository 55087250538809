@use '@reverbdotcom/cadence/styles/tools/mixins/svg';

.csp2-filters {
  margin-top: 2rem;
}

.csp2-filters__options {
  white-space: nowrap;
  display: flex;
  align-items: center;
  width: 100%;
}

.csp2-filter-option {
  margin: 0.5rem 1rem 0.5rem 0;

  &.csp2-filter-option--no-stock {
    opacity: 0.6;
    pointer-events: none;
  }
}

.csp2-filter__toggle {
  @include button-as-link;
  @include svg.icon-after(angle-right, $as-link: true);

  font-weight: bold;
  font-size: 1.4rem;
  margin-left: 1rem;
  padding: 0.5rem var(--rc-space-gutter-page) 0.5rem 0;

  .csp2-filters__options--expanded & {
    @include svg.icon-before(angle-left, $as-link: true);

    &::after {
      display: none;
    }
  }

  @include tablet {
    padding-right: var(--rc-space-gutter-page);
  }
}

.csp2-marketplace-filters {
  position: relative;
  margin-bottom: 2rem;
  z-index: 2; // prevents listing grid shadow overlap blocking pointer events
}
