@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/functions/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

$order-card-background-color: var(--rc-color-palette-gray-100);

.order-card {
  background-color: $order-card-background-color; // #f9f9f9
  border: solid 0.1rem var(--rc-color-border-primary);
  border-radius: 0.4rem;

  // TODO - remove, temp for dev testing
  max-width: 70rem;
  margin: 4rem auto;
}

.order-card__header {
  padding: 1.4rem 2rem;
  border-bottom: solid 0.1rem var(--rc-color-border-primary);

  @include mobile {
    padding: 1.4rem;
  }
}

.order-card__header-row {
  font-size: 1.6rem;
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
    line-height: 1.1875;
  }
}

.order-card__header-title__info {
  @include mobile {
    display: none;
  }
}

.order-card__header-price {
  font-weight: 700;
}

.order-card__header-actions {
  display: flex;
  margin-top: 1rem;
  width: 100%;

  @include mobile {
    margin-top: 1.4rem;
  }
}

.order-card__header-action {
  @include button-reset;
  font-size: 1.4rem;
  color: var(--rc-color-palette-blue-600);
  margin-right: 2rem;

  @include mobile {
    display: none;
  }

  &:hover,
  &.order-card__header-action--active {
    color: var(--rc-color-palette-vu-orange-500);
  }

  &.order-card__header-action--active {
    font-weight: 700;
  }

  &::before {
    display: inline-block;
  }

  &.order-card__header-action--offer-history {
    &::before {
      content: '';
      // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
      background-image: svg.svg-url(offer-icon, $fill: scss-tokens.$rc-color-palette-blue-600);
      width: 2.1rem;
      height: 1.2rem;
      margin-right: 0.6rem;
    }

    &:hover::before {
      // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
      background-image: svg.svg-url(offer-icon, $fill: scss-tokens.$rc-color-palette-vu-orange-500);
    }
  }

  &.order-card__header-action--messages {
    @include fa-icon-before(envelope-o, $margin-right: 0.6rem);
  }

  &.order-card__header-action--notes {
    @include fa-icon-before(sticky-note-o, $margin-right: 0.6rem);
  }

  &.order-card__header-action--order {
    @include fa-icon-before(info-circle, $margin-right: 0.6rem);
    margin-left: auto; // to right-align it
    margin-right: 0;

    @include mobile {
      display: block;
      width: 100%;
      font-size: 1.28rem;
      border: 0.1rem solid var(--rc-color-border-primary);
      background: var(--rc-color-palette-bw-white);
      color: var(--rc-color-text-primary);
      padding: 0.65em 1em;
      border-radius: 0.3rem;
    }
  }
}

.order-card__notes-container {
  border-bottom: solid 0.1rem var(--rc-color-border-primary);
  padding: 1.4rem 2rem;
}

.order-card__note-header {
  color: var(--rc-color-text-secondary);
  margin-bottom: 0.3rem;
}

.order-card__note-timestamp {
  font-size: 1.2rem;
}

.order-card__note-delete {
  @include button-reset;
  @include fa-icon-before(close, $margin-right: 0);
  color: var(--rc-color-text-secondary);
  margin-left: 0.6rem;

  &:hover {
    color: var(--rc-color-palette-vu-orange-500);
  }
}

.order-card__note-body {
  font-size: 1.4rem;
  line-height: 1.35;
}

.order-card__notes-form {
  margin-top: 1rem;
}

.order-card__notes-textarea {
  display: block;
  width: 100%;
  height: 7rem;
  margin: 0 0 1.2rem;
}

.order-card__notes-submit {
  @include rc-button.filled;
  font-size: 1.4rem;
  margin: 0;

  @include mobile {
    width: 100%;
  }
}

.order-card__notes-hint {
  @include scaling(margin-top, 1rem);
  display: inline-block;
  margin-left: 1em;
  color: var(--rc-color-text-secondary);
  font-size: 1.2rem;

  @include mobile {
    display: block;
    text-align: center;
    margin-top: 1em;
    margin-left: 0;
  }
}

.order-card__main {
  padding: 2rem;
  display: flex;

  @include mobile {
    display: block;
    padding: 1.4rem;
  }
}

.order-card__status {
  flex: 1;
  margin-right: 2rem;

  @include mobile {
    margin-right: 0;
    margin-bottom: 1.4rem;
  }
}


.order-card__status-title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;

  &.order-card__status-title--shipping {
    @include fa-icon-before(truck);
  }

  &.order-card__status-title--refund {
    @include fa-icon-before(exchange);
  }
}

.order-card__status-content {
  font-size: 1.6rem;
}

.order-card__actions {
  flex: 0;
  flex-basis: 28%;
}

.order-card__action {
  @include rc-button.muted;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  width: 100%;

  &:last-child {
    margin: 0;
  }

  &.order-card__action--primary {
    @include rc-button.filled;
    font-size: 1.3rem;
  }
}

.order-card__items-wrapper {
  position: relative;

  @include mobile {
    padding-top: 2.8rem;
  }
}

.order-card__items-count {
  position: absolute;
  top: -2.4rem;
  left: 2rem;
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);

  @include mobile {
    top: 0;
  }
}

.order-card__additional-items {
  display: flex;
  align-items: center;
  background-color: var(--rc-color-palette-bw-white);
  border-top: solid 0.1rem var(--rc-color-border-primary);
  border-radius: 0 0 0.4rem 0.4rem;
  padding: 1.4rem 2rem;
}

.order-card__additional-thumbs {
  @include clearfix;
  padding-left: 2.4rem;
  margin-right: 1.6rem;
  height: 4rem;
}

.order-card__additional-thumb {
  display: block;
  float: left;
  width: 4rem;
  height: 4rem;
  border-radius: 0.8rem;
  border: solid 0.4rem var(--rc-color-palette-bw-white);
  position: relative;
  top: -0.4rem;
  margin-left: -2.8rem;

  @for $i from 1 through 4 {
    &:nth-child(#{$i}) {
      z-index: #{5 - $i};
    }
  }
}

.order-card__additional-button {
  @include rc-button.muted;
  font-size: 1.4rem;
}
