.rc-radio {
  display: flex;
  align-items: flex-start;
  position: relative;

  // disabled state for label
  &.rc-radio--disabled {
    color: var(--rc-color-text-secondary);
    cursor: not-allowed;
  }

  &.rc-radio--icon-center {
    align-items: center;
  }
}

.rc-radio__input {
  opacity: 0;
  height: var(--rc-size-checkbox);
  width: var(--rc-size-checkbox);
  position: absolute;
  z-index: 2; // on top, but invisible to accept clicks
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.rc-radio__circle {
  --border-width: 0.2rem;
  --border-gap: 0.2rem;
  box-sizing: border-box;
  display: block;
  position: relative;
  width: var(--rc-size-checkbox);
  height: var(--rc-size-checkbox);
  background-color: var(--rc-color-background-input);
  border-width: var(--border-width);
  border-style: solid;
  border-color: var(--rc-color-border-input-primary);
  border-radius: 50%;
  z-index: 1;
  flex-shrink: 0;
  flex-grow: 0;

  // the filled dot "icon"
  &::before {
    --dot-size: calc(var(--rc-size-checkbox) - (var(--border-width) * 2) - (var(--border-gap) * 2));
    content: '';
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: 50%;
    position: absolute;
    top: var(--rc-space-half);
    left: var(--rc-space-half);
    background-color: var(--rc-color-text-input-secondary);
    opacity: 0;
  }
}

// Hover state styling
.rc-radio__input:hover:not(:disabled) + .rc-radio__circle::before {
  opacity: 1;
}

// Checked state styling
.rc-radio__input:checked + .rc-radio__circle::before {
  background-color: var(--rc-color-background-input-checked);
  opacity: 1;
}

.rc-radio__input:checked + .rc-radio__circle {
  border-color: var(--rc-color-background-input-checked);
}

// Focus-visible state styling
.rc-radio__input:focus-visible + .rc-radio__circle {
  @include focus-outline;
  outline-offset: -0.1rem; // border thickness
}

// Disabled state styling
.rc-radio__input:disabled {
  cursor: not-allowed;

  + .rc-radio__circle {
    opacity: 0.5;
  }
}
