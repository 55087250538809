// basic mixin for applying our responsive spacing.
// the first-child selector here is essentially for legacy reasons
// due to the number of pages that aren't prepared for their top elements
// to have top margin.
// if you don't require the first-child behavior, just use the tokens directly.

@mixin gap-row {
  margin-top: var(--rc-space-gap-row);
  margin-bottom: var(--rc-space-gap-row);

  &:first-child {
    margin-top: 0;
  }
}
