.promotional-banner-top {
  color: var(--rc-color-text-primary);
  background-color: var(--rc-color-palette-vu-orange-300);
  position: relative;

  a {
    background-color: initial;
    color: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 5rem;

    &:hover {
      background-color: initial;
      color: initial;
      text-decoration: underline;
    }

    @include tablet {
      font-size: 90%;
      min-height: 4rem;
    }
  }

  &.promotional-banner-top--downtime {
    background-color: var(--rc-color-palette-gray-500);
  }
}

.promotional-banner-top__inner {
  @include site-wrapper;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 1rem var(--rc-space-gutter-page);
  justify-content: center;

  @include tablet {
    min-width: 0;
  }

  @include mobile {
    padding: 0.5rem 0.5rem 0.5rem var(--rc-space-gutter-page);
    justify-content: initial;
  }

  .promotional-banner-top--ad & {
    padding-right: 3.5rem;

    @include mobile {
      padding-right: var(--rc-space-gutter-page);
    }
  }
}

.promotional-banner-top__action {
  flex: 0 0 auto;
  white-space: nowrap;
  text-decoration: underline;

  @include tablet {
    display: none;
  }
}

.promotional-banner-top__content,
.promotional-banner-top__heading,
.promotional-banner-top__subheading {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.promotional-banner-top__content {
  margin-bottom: 0;
  text-align: center;

  @include mobile {
    flex: 1;
    text-align: left;
  }
}

.promotional-banner-top__heading {
  padding: 0 1rem;
  margin-left: 1rem;
  border-left: 0.1rem solid var(--rc-color-text-primary);

  @include mobile {
    display: block;
    padding: 0;
    margin: 0;
    border: none;
  }

  .promotional-banner-top--ad & {
    @include mobile {
      padding-right: 2rem;
    }
  }
}

.promotional-banner-top__subheading {
  font-weight: bold;

  .rc-icon {
    display: none;
  }

  @include mobile {
    display: block;
    line-height: 1.2;
  }

  .promotional-banner-top--ad & {
    @include mobile {
      .rc-icon {
        display: inline-block;
      }
    }
  }
}

.promotional-banner-top__ad {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  margin: 0;
  border-radius: 0.2rem;
  overflow: hidden;
}

.promotional-banner-top__icon {
  width: 2.8rem;
  height: 2.8rem;

  .promotional-banner-top--ad & {
    @include mobile {
      display: none;
    }
  }

  .promotional-banner-top--downtime & {
    display: none;
  }
}
