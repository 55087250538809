@use '@reverbdotcom/cadence/styles/tools/mixins/svg';

.featured-listing-card {
  border: 0.1rem solid var(--rc-color-border-primary);
  background: white;

  &.featured-listing-card--loading,
  &.featured-listing-card--placeholder {
    @include loading-pulse;
  }
}

.featured-listing-card__inner {
  @include tablet {
    padding: 1rem;
  }
}

.featured-listing-card__heading {
  background: var(--rc-color-palette-gray-100);
  padding: 0.5rem 1rem;
  color: var(--rc-color-text-secondary);
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;

  a {
    @include svg.icon-after(arrow-right, $as-link: true);
    text-transform: capitalize;
  }
}

.featured-listing-card__main {
  padding: 2rem 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  @include tablet {
    display: block;
    padding: 1rem 0;
  }
}

.featured-listing-card__bump-tag {
  padding: 0 0 0.5rem;
}

.featured-listing-card__title {
  @include responsive(font-size, 1.3em, 1.2em, 1.1em);

  font-weight: bold;
  max-height: 4.8em;
  overflow: hidden;
  line-height: 1.2;

  a {
    color: var(--rc-color-text-primary);
  }
}

.featured-listing-card__condition {
  margin: 0.5rem 0 1.5rem 0;

  @include tablet {
    margin-bottom: 0;
  }
}

.featured-listing-card__image-and-title {
  display: flex;
  flex: 1;
  align-items: center;
}

.featured-listing-card__image {
  flex: 0 0 35%;
  margin-right: 2rem;
  max-width: 22rem;
}

.featured-listing-card__image__inner {
  @include image-box;

  background: var(--rc-color-palette-gray-200);
}

.featured-listing-card__pricing {
  white-space: nowrap;
  text-align: center;
  flex: 0 0 27%;
  min-width: 17rem;

  @include tablet {
    text-align: right;
    margin-top: 1rem;
  }
}

.featured-listing-card__mobile-pricing {
  @include tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.featured-listing-card__actions {
  margin-top: 1rem;
}

.featured-listing-card__primary {
  @include responsive(padding-right, 1em, 0);

  flex: 1;
}

.featured-listing-card__description {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  &:empty {
    display: none;
  }
}

.featured-listing-card__description__inner {
  @include hyphenate;

  hyphens: auto;
  flex: 1;
  background: var(--rc-color-palette-gray-100);
  padding: 0.8em;
  font-size: 1.3rem;

  // stylelint-disable-next-line selector-max-universal
  * {
    display: inline;
  }

  a {
    display: inline-block;
  }

  &:empty {
    display: none;
  }

  p {
    margin: 0;
  }

  @include mobile {
    font-size: 1.2rem;
  }
}

