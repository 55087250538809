@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.checkout-paypal-button {
  @include rc-button.base;
  @include rc-button.size('medium');

  --paypal-button-bg: rgb(255, 196, 57);
  --paypal-button-border: rgb(248, 169, 5);

  width: 100%;
  background: var(--paypal-button-bg);
  border: 0.1rem solid var(--paypal-button-border);

  &:hover {
    background: var(--paypal-button-bg);

    &:not(:disabled) {
      opacity: 85%;
    }
  }
}

.checkout-paypal-button__paypal-icon {
  display: block;
  width: 10.6rem;
  height: 2.4rem;
  background: url('#{$discovery-ui-path}/images/icons/paypal-button-icon.svg') no-repeat;

  &--disabled {
    opacity: 0.5;
  }
}
