.item2-description__user {
  border: 0.1rem solid var(--rc-color-border-primary);

  margin: 2rem 0;
  padding: 2rem;
  background: white;
}

.item2-description {
  table {
    width: 100%;
  }

  .collapsing-row {
    td {
      padding: 0.4em 0;

      &:first-child {
        vertical-align: top;
        font-weight: bold;
        padding-right: 4%;
        width: 20%;

        @include tablet {
          width: auto;
        }
      }
    }

    tr:first-child td {
      padding-top: 0;
    }

    tr:last-child td {
      padding-bottom: 0;
    }
  }
}

// some users' descriptions are pasted in with extra divs,
// so we can't rely on sass-core's strict parent/child selectors (e.g. ol > li)

.item2-description__content {
  ol {
    @extend %cms-ol;

    li {
      @extend %cms-ol-li;
    }
  }

  ul {
    @extend %cms-ul;

    li {
      @extend %cms-ul-li;
    }
  }
}

.item2-description__accordion {
  @include mobile {
    margin-right: calc(var(--rc-space-x-accordion) * -1);
    margin-left: calc(var(--rc-space-x-accordion) * -1);
  }
}
