@use 'sass:map';
@use '../../config/configuration';

@use 'sass:math';

@mixin switch($size: var(--rc-size-height-switch), $color: var( --rc-color-palette-blue-600), $extend: true) {
  @if ($extend == true) {
    @extend %switch-base;
  }

  $handle-size: calc(#{$size} - (2 * var(--rc-border-width-switch)));
  $switch-width: calc($handle-size * 2 + var(--rc-border-width-switch) * 2);
  --label-padding-left: max(calc(#{$switch-width} + var(--rc-space-gap-checkbox-label)), var(--rc-space-16));

  label {
    padding-left: var(--label-padding-left);
    min-height: $size;
    font-weight: map.get(configuration.$font-weights, normal);

    // switch box
    &::before {
      background-color: var(--rc-color-background-switch-track);
      width: $switch-width;
      height: $size;
      border-radius: var(--rc-border-radius-full);
      left: 0;
      border: var(--rc-border-width-switch) solid var(--rc-color-border-input-primary);
      box-sizing: border-box;
    }

    // handle
    &::after {
      width: $handle-size;
      height: $handle-size;
      transform: scale(0.667);
      top: var(--rc-border-width-switch);
      left: var(--rc-border-width-switch);
      margin-left: 0;
      box-sizing: border-box;
    }
  }

  input:checked {
    + label {
      &::before {
        background-color:  var(--rc-color-background-switch-track-selected);
        border-color: var(--rc-color-background-switch-track-selected);
      }

      &::after {
        margin-left: calc($handle-size);
        background-color: var(--rc-color-background-switch-handle);
        transform: scale(1);
      }
    }
  }

  input:disabled {
    + label::before {
      background-color: var(--rc-color-background-switch-track);
    }
  }
}
