.shop-campaign-coupon-preview {
  border-color: var(--rc-color-palette-yellow-600);
  background: var(--rc-color-palette-yellow-100);
  border-width: 0.2rem;
  border-radius: 0.4rem;
  border-style: dashed;
  margin: 1rem 0;
}

.shop-campaign-coupon-preview__header {
  padding: 1.5rem;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-campaign-coupon-preview__body {
  background: var(--rc-color-palette-bw-white);
  padding: 2rem;
  font-size: 1.5rem;

  p {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.shop-campaign-coupon-terms__link {
  margin-top: 1rem;

  a {
    color: var(--rc-color-text-secondary);
    text-decoration: underline;

    @include responsive-hover {
      color: var(--rc-color-text-primary);
    }
  }
}
