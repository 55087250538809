.rc-text-with-icon__icon {
  &.rc-text-with-icon__icon--right {
    margin-left: 0.25em;

    &.rc-text-with-icon__icon--large {
      margin-left: 0.5em;
    }
  }

  &.rc-text-with-icon__icon--left {
    margin-right: 0.25em;

    &.rc-text-with-icon__icon--large {
      margin-right: 0.5em;
    }
  }

  .rc-button & {
    &.rc-text-with-icon__icon--right,
    &.rc-text-with-icon__icon--left {
      margin-left: 0;
      margin-right: 0;

      &.rc-text-with-icon__icon--large {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
