.listing-row-card {
  width: 100%;
  position: relative;

  &.listing-row-card--placeholder {
    @include loading-pulse;
  }
}

.listing-row-card__inner {
  position: relative;
  display: block;
  background: var(--rc-color-palette-bw-white);
  color: var(--rc-color-text-primary);
  font-size: 1em;
  border: none;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);

  @include responsive-hover {
    .listing-row-card__title {
      text-decoration: underline;
    }
  }

  .listing-row-card--placeholder & {
    height: 12rem;
  }
}


.listing-row-card__main {
  padding-bottom: 1rem;
  margin: 0.5rem 0;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;

  @include tablet-with-sidebar {
    display: flex;
    align-items: flex-start;
  }
}

.listing-row-card__info-and-image {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  a {
    color: var(--rc-color-text-primary);
  }

  @include tablet-with-sidebar {
    margin-bottom: 1rem;
  }
}

.listing-row-card__info {
  padding: 0 1rem;
  flex: 1;
  box-sizing: border-box;

  @include tablet-with-sidebar {
    padding-right: 0;
  }
}

.listing-row-card__image {
  box-sizing: border-box;
  flex: 0 0 16rem;
  border: 0.1rem solid var(--rc-color-border-primary);
  position: relative;

  @include tablet-with-sidebar {
    flex-basis: 8rem;
  }

  @include mobile {
    flex-basis: 7rem;
  }
}

.listing-row-card__image__inner {
  display: block;
  width: 100%;
  height: auto;
}

.listing-row-card__seller-and-pricing {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 35rem;
  padding-left: 2rem;

  @include tablet-with-sidebar {
    display: flex;
    flex: 1;
    padding: 0;
    min-width: 0;
  }
}

.listing-row-card__bump-tag {
  margin-top: 1rem;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
  color: var(--rc-color-text-tag-bump);

  @include tablet-with-sidebar {
    margin-top: 0.5rem;
  }
}

.listing-row-card__title {
  @include responsive(font-size, 1.2em, 1.1em, 1em);
  @include group-link;

  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  color: var(--rc-color-text-primary);
}

.listing-row-card__info-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 1rem;
  align-items: stretch;
}

.listing-row-card__title-and-price {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex: 1;
  margin-bottom: 0.5rem;

  @include tablet-with-sidebar {
    flex-direction: column;
    align-items: flex-start;
  }
}

.listing-row-card__shop-and-actions {
  justify-content: space-between;
  display: flex;
  align-items: flex-end;

  @include tablet-with-sidebar {
    display: block;
  }
}

.listing-row-card__shop-info {
  align-items: flex-start;
  flex-direction: column;

  @include tablet-with-sidebar {
    display: none;
  }
}

.listing-row-card__shop-info--mobile {
  display: none;

  @include tablet-with-sidebar {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
  }
}

.listing-row-card__image-container {
  display: flex;
  flex-direction: column;
  flex: 0 0 16rem;
}

.listing-row-card__seller {
  margin-right: 2rem;
}

.listing-row-card__pricing {
  flex: 0 0 auto;
  text-align: right;
  padding-left: 2rem;

  @include tablet-with-sidebar {
    padding-left: 0;
    margin-top: 1rem;
    text-align: left;
  }
}

.listing-row-card__pricing__actions {
  display: flex;
  align-items: center;
  margin: 0 -0.5rem -0.5rem;

  @include tablet-with-sidebar {
    flex-direction: column-reverse;
    align-items: stretch;
    text-align: center;
  }
}

.listing-row-card__pricing__action {
  margin: 0.5rem;
}

.listing-row-card__price {
  font-size: 1.4em;
  line-height: 1.2;
}

.listing-row-card__price__original {
  font-size: 1.2rem;
  opacity: 0.6;
  text-decoration: line-through;
}

.listing-row-card__price__amount {
  .listing-row-card__price__original + & {
    color: var(--rc-color-text-price-nudge);
  }
}

.listing-row-card__shipping {
  opacity: 0.7;
  font-size: 0.9em;
}
