.sell-form-additional-content-modal__section {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);

  a {
    text-decoration: underline;
    color: var(--rc-color-text-primary);
  }

  > p {
    margin-bottom: 2rem;

    &.sell-form-additional-content__list-intro {
      margin-bottom: 0.5rem;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.sell-form-additional-content-modal__section__title {
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.sell-form-additional-content-modal__section__image {
  max-height: 25rem;
  max-width: 100%;
  align-self: center;
  margin-bottom: 2rem;
}

.sell-form-additional-content-modal__retailer-conditions-title {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 3rem;
}

.sell-form-additional-content-modal__ul {
  @extend %cms-ul;

  li {
    @extend %cms-ul-li;
  }
}

.sell-form-additional-content-modal__image-caption {
  font-style: italic;
  font-size: 1.4rem;
}
