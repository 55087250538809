@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.feed-customize {
  @include site-wrapper;
  margin-bottom: 4rem;
}

.feed-customize__columns {
  @include grid-container;
}

.feed-customize__column {
  @include grid-columns(6, $mobile: 12);
}

.feed-customize__subhead {
  @include scaling(margin-bottom, 1rem);
  font-size: 1.6em;
  font-weight: bold;
  border-bottom: 0.2rem solid var(--rc-color-border-primary);
  min-height: 1em;
}

.feed-customize__callout {
  @include scaling(padding, 2rem);
  background: var(--rc-color-palette-gray-300);
  text-align: center;
  font-size: 1.2em;

  p + p {
    margin-top: 1em;
  }

  a {
    @include rc-button.filled;
    @include fa-icon-before(arrow-right);
  }

  &.feed-customize__callout--articles {
    background: none;
    padding: 0;
  }
}

.feed-customize__section {
  @include scaling(margin-bottom, 3rem);
}

.feed-customize__tiles {
  margin: $grid-gutter 0;
}

.feed-customize__item {
  @include scaling(padding, 1rem);

  border: 0.1rem solid var(--rc-color-border-primary);
  width: 100%;
  background: var(--rc-color-palette-bw-white);
  display: flex;
  flex-wrap: wrap;

  &.feed-customize__item--placeholder {
    @include loading-pulse;
  }

  &.feed-customize__item--confirm-remove {
    background: var(--rc-color-palette-gray-300);
  }
}

.feed-customize__item__details {
  flex: 0 0 100%;
  font-weight: bold;
  font-size: 1.1em;
  hyphens: auto;
  overflow: hidden;
  margin-bottom: 1rem;

  a {
    color: var(--rc-color-text-primary);
  }

  small {
    display: block;
    color: var(--rc-color-text-secondary);
    font-size: 0.8em;
    margin-top: 0.5em;
  }

  .feed-customize__item--placeholder & {
    height: 4em;
  }
}

.feed-customize__item__confirm-remove-title {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  flex: 0 0 100%;
}

.feed-customize__item__confirm-remove-button {
  @include rc-button.muted;
  font-size: 1rem;

  &:first-of-type {
    margin-right: 0.2rem;
  }
}

.feed-customize__item__confirm-remove-hint {
  font-size: 0.7em;
  font-weight: normal;
  margin-top: 0.5rem;
  line-height: 1.3;
}

.feed-customize__item__actions {
  align-self: flex-end;
  width: 100%;
  text-align: right;

  .feed-customize__item--placeholder & {
    opacity: 0.5;
  }
}

.feed-customize__item__action {
  @include button-reset;

  display: inline-block;
  height: 2.4rem;
  width: 2.4rem;
  font-size: 1.6rem;
  padding: 0;
  margin-left: 1rem;
  text-align: center;

  &::before {
    @extend %fa-icon-base;
    height: 100%;
    width: 100%;
    line-height: 1.5;
    color: var(--rc-color-text-secondary);
  }

  &.feed-customize__item__action--visit {
    &::before {
      content: fa-icon-content(search);
    }

    &:hover::before {
      color: var(--rc-color-text-primary);
    }
  }

  &.feed-customize__item__action--add-gear-alert {
    &::before {
      content: fa-icon-content(envelope);
    }

    &:hover::before {
      color: var(--rc-color-text-primary);
    }
  }

  &.feed-customize__item__action--remove-gear-alert {
    &::before {
      content: fa-icon-content(envelope);
      color: var(--rc-color-palette-blue-600);
    }

    &:hover::before {
      color: var(--rc-color-palette-blue-500);
    }
  }

  &.feed-customize__item__action--confirm {
    &::before {
      content: fa-icon-content(close);
    }

    &:hover::before {
      color: var(--rc-color-text-danger);
    }
  }
}

.feed-customize__articles {
  @include grid-container;
  margin-top: $grid-gutter;
  margin-bottom: $grid-gutter;

  li {
    @include grid-columns(3, 4, 12, $cycle: true);

    border: 0.1rem solid var(--rc-color-border-primary);
    background: var(--rc-color-palette-bw-white);
    padding: 0.5em;
    text-align: left;
  }
}

.feed-customize__article {
  @include switch(2rem);

  label {
    margin-bottom: 0;
  }
}
