@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.site-footer-v2 {
  position: relative;
  background: var(--rc-color-background-footer);
  color: var(--rc-color-text-footer);
  font-size: 1.4rem;
  padding: 3em 0;
  margin-top: 1rem;

  &::before {
    position: absolute;
    content: '';
    display: block;
    height: 1.9rem;
    bottom: 100%;
    left: 0;
    right: 0;
    background: url('#{$discovery-ui-path}/images/top-level/footer-border.png') top center;
    z-index: 2;
  }

  @include mobile {
    padding-top: 0;
  }
}

.site-footer-v2__inner {
  @include site-wrapper;
  display: flex;
  width: 100%;

  @include tablet {
    display: block;
  }
}

.site-footer-v2__link-columns {
  display: flex;
  flex: 1;

  @include mobile {
    display: block;
    font-size: 1.1em;
    text-align: center;
  }
}

.site-footer-v2__link-column {
  padding-right: $grid-gutter;
  flex: 1;
  line-height: 1.4;

  &:last-child {
    padding-right: 0;
  }

  + .site-footer-v2__link-column {
    @include mobile {
      border-top: 0.1rem solid var(--rc-color-border-primary);
    }
  }

  @include mobile {
    padding: 0;
  }
}

.site-footer-v2__link-section {
  h3 {
    margin-bottom: 0.3em;
    font-weight: bold;
    position: relative;

    @include mobile {
      margin-bottom: 0;
      font-size: 1.7rem;

      &::before {
        // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
        @include svg.icon-bg(angle-up, $fill: scss-tokens.$rc-color-text-footer);
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 2rem;
        background-size: 100% auto;
        pointer-events: none;
      }
    }

    button {
      font: inherit;
      cursor: inherit;
      background-color: transparent;
      border: none;
      color: var(--rc-color-text-footer);
      width: 100%;
      text-align: left;

      @include mobile {
        padding: 2rem 0;
        cursor: pointer;
      }
    }
  }

  &.site-footer-v2__link-section--collapsed {
    h3 {
      &::before {
        // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
        @include svg.icon-bg(angle-down, $fill: scss-tokens.$rc-color-text-footer);
      }
    }
  }

  + .site-footer-v2__link-section {
    margin-top: 1.5em;

    @include mobile {
      margin-top: 0;
      border-top: 0.1rem solid var(--rc-color-border-primary);
    }
  }
}

.site-footer-v2__collapsible-links {
  @include mobile {
    margin-bottom: 2rem;
    text-align: left;

    .site-footer-v2__link-section--collapsed & {
      display: none;
    }
  }
}

.site-footer-v2__link {
  @include button-reset;

  @include group-link;
  display: block;
  padding: 0.3em 0;

  @include responsive-hover {
    color: var(--rc-color-text-footer);
  }

  @include mobile {
    padding: 0.4em 0;
  }
}

.site-footer-v2__newsletter {
  max-width: 32rem;

  @include tablet {
    margin: 3rem auto 0 auto;
    max-width: none;
    width: 38rem;
  }

  @include mobile {
    width: 100%;
    text-align: center;
    border-top: 0.1rem solid var(--rc-color-border-primary);
    padding-top: 3rem;
    margin-top: 0;
  }

  .input-group__button {
    border-width: 0.1rem;
  }
}
