.item2-cpo-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--rc-space-3);
  flex-wrap: wrap;
  gap: var(--rc-space-half);
}

.item2-cpo-title__logo {
  height: 3.4rem;
  width: 10rem;

  img {
    height: 100%;
    width: auto;
  }
}
