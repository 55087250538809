@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.overflowing-row-action {
  @include rc-button.transparent;

  @include mobile {
    width: var(--rc-min-height-button-medium);
    padding: 0;
    margin-left: var(--rc-space-2);
  }

  .overflowing-row--landing & {
    @include mobile {
      padding: var(--rc-padding-tb-button-medium) var(--rc-padding-lr-button-medium);
      width: 100%;
      margin-left: 0;
    }
  }

  .favorites-content & {
    @include mobile {
      @include rc-button.size('small');
      width: var(--rc-min-height-button-small);
    }
  }
}

.overflowing-row-action__text-with-icon {
  @include mobile {
    display: none;
  }

  .overflowing-row--landing & {
    @include mobile {
      display: block;
    }
  }
}

.overflowing-row-action__icon {
  display: none;

  @include mobile {
    display: block;
  }

  .overflowing-row--landing & {
    @include mobile {
      display: none;
    }
  }
}
