.sell-form-preorder-info-fields__dynamic-fields-container {
  max-width: 30rem;

  // iOS safari styling fix
  input[type='date'] {
    min-width: 96%;
    appearance: textfield;
  }

  @include tablet {
    max-width: 70%;
  }

  @include mobile {
    max-width: 100%;
  }
}
