.loading-overlay {
  position: relative;
}

.loading-overlay__bars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    opacity: 0.5;
    background-color: var(--rc-color-background-page);
  }
}

.loading-overlay__content {
  .loading-overlay--active & {
    min-height: 8rem; // to make space for loading bars in case they're taller than the content
  }
}
