.rc-box {
  --box-border-radius: 0;
  --box-padding: 0;
  --box-curve-radius: var(--box-border-radius);
  --box-display: block;

  display: var(--box-display);
  border-radius: var(--box-border-radius);
  padding: var(--box-padding);
  color: var(--rc-color-text-primary);
  overflow: hidden;

  &.rc-box--curve-top-left {
    border-top-left-radius: var(--box-curve-radius);
  }

  &.rc-box--curve-bottom-left {
    border-bottom-left-radius: var(--box-curve-radius);
  }

  &.rc-box--curve-top-right {
    border-top-right-radius: var(--box-curve-radius);
  }

  &.rc-box--curve-bottom-right {
    border-bottom-right-radius: var(--box-curve-radius);
  }

  &.rc-box--outlined,
  &.rc-box--subtle-outlined {
    border-width: 0.1rem;
    border-style: solid;
    border-color: var(--rc-color-border-primary);

    &.rc-box--gray {
      border-color: var(--rc-color-border-box-gray);
    }

    &.rc-box--vu-orange {
      border-color: var(--rc-color-border-box-vu-orange);
    }

    &.rc-box--tweed-orange {
      border-color: var(--rc-color-border-box-tweed-orange);
    }

    &.rc-box--yellow {
      border-color: var(--rc-color-border-box-yellow);
    }

    &.rc-box--teal {
      border-color: var(--rc-color-border-box-teal);
    }

    &.rc-box--blue {
      border-color: var(--rc-color-border-box-blue);
    }

    &.rc-box--kick-blue {
      border-color: var(--rc-color-border-box-kick-blue);
    }

    &.rc-box--sentiment-negative {
      border-color: var(--rc-color-border-box-sentiment-negative);
    }
  }

  &.rc-box--subtle,
  &.rc-box--subtle-outlined {
    background-color: var(--rc-color-background-module);

    &.rc-box--gray {
      background-color: var(--rc-color-background-box-gray-subtle);
    }

    &.rc-box--vu-orange {
      background-color: var(--rc-color-background-box-vu-orange-subtle);
    }

    &.rc-box--tweed-orange {
      background-color: var(--rc-color-background-box-tweed-orange-subtle);
    }

    &.rc-box--yellow {
      background-color: var(--rc-color-background-box-yellow-subtle);
    }

    &.rc-box--teal {
      background-color: var(--rc-color-background-box-teal-subtle);
    }

    &.rc-box--blue {
      background-color: var(--rc-color-background-box-blue-subtle);
    }

    &.rc-box--kick-blue {
      background-color: var(--rc-color-background-box-kick-blue-subtle);
    }

    &.rc-box--sentiment-negative {
      background-color: var(--rc-color-background-box-sentiment-negative-subtle);
    }
  }

  &.rc-box--muted {
    background-color: var(--rc-color-background-module-muted);

    &.rc-box--gray {
      background-color: var(--rc-color-background-box-gray-muted);
    }

    &.rc-box--vu-orange {
      background-color: var(--rc-color-background-box-vu-orange-muted);
    }

    &.rc-box--tweed-orange {
      background-color: var(--rc-color-background-box-tweed-orange-muted);
      color: var(--rc-color-text-primary-invert-dark);
    }

    &.rc-box--yellow {
      background-color: var(--rc-color-background-box-yellow-muted);
      color: var(--rc-color-text-primary-invert-dark);
    }

    &.rc-box--teal {
      background-color: var(--rc-color-background-box-teal-muted);
      color: var(--rc-color-palette-bw-soft-black); // needs darker value for proper contrast in dark mode
    }

    &.rc-box--blue {
      background-color: var(--rc-color-background-box-blue-muted);
    }

    &.rc-box--kick-blue {
      background-color: var(--rc-color-background-box-kick-blue-muted);
    }

    &.rc-box--sentiment-negative {
      background-color: var(--rc-color-background-box-sentiment-negative-muted);
    }
  }

  &.rc-box--bold {
    background-color: var(--rc-color-background-page-inverted);
    color: var(--rc-color-text-primary-inverted);

    &.rc-box--gray {
      background-color: var(--rc-color-background-box-gray-bold);
      color: var(--rc-color-text-primary-inverted);
    }

    &.rc-box--vu-orange {
      background-color: var(--rc-color-background-box-vu-orange-bold);
      color: var(--rc-color-text-primary-invert-dark);
    }

    &.rc-box--tweed-orange {
      background-color: var(--rc-color-background-box-tweed-orange-bold);
      color: var(--rc-color-text-primary-invert-dark);
    }

    &.rc-box--yellow {
      background-color: var(--rc-color-background-box-yellow-bold);
      color: var(--rc-color-text-primary-invert-dark);
    }

    &.rc-box--teal {
      background-color: var(--rc-color-background-box-teal-bold);
      color: var(--rc-color-text-primary-invert-dark);
    }

    &.rc-box--blue {
      background-color: var(--rc-color-background-box-blue-bold);
      color: var(--rc-color-text-primary-invert-light);
    }

    &.rc-box--kick-blue {
      background-color: var(--rc-color-background-box-kick-blue-bold);
      color: var(--rc-color-text-primary-invert-light);
    }

    &.rc-box--sentiment-negative {
      background-color: var(--rc-color-background-box-sentiment-negative-bold);
    }
  }
}

.rc-box__icon {
  --box-icon-color: inherit;

  display: flex;
  color: var(--box-icon-color);
}
