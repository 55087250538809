@mixin show-for-print {
  @media print {
    @content;
  }
}

@mixin hide-for-print {
  @media print {
    display: none;
  }
}
