.tabbed-navigation {
  display: flex;
  border-radius: 0.4rem;
  border: solid 0.1rem var(--rc-color-border-primary);
  overflow: hidden;

  @include mobile {
    display: block;
  }

  &.tabbed-navigation--with-counter {
    counter-reset: tabbed_navigation_counter;
  }

  &.tabbed-navigation--shop-onboarding {
    @include scaling(margin-bottom, 3.2rem);
  }
}

.tabbed-navigation__tab {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 2rem;
  background-color: var(--rc-color-palette-bw-white);
  border: none; // to reset <button> default borders
  border-right: solid 0.1rem var(--rc-color-border-primary);
  border-radius: 0;
  color: var(--rc-color-text-secondary);
  font-size: 1.8rem;
  text-align: center;
  font-weight: normal;
  cursor: pointer;
  transition: background-color 0.15s ease, color 0.15s ease;

  @include mobile {
    border-right: none;
    border-bottom: solid 0.1rem var(--rc-color-border-primary);
    font-size: 1.4rem;
    padding: 1.2rem;
  }

  &:last-child {
    border-right: none;

    @include mobile {
      border-bottom: none;
    }
  }

  &:hover {
    background-color: var(--rc-color-palette-gray-100);
    color: var(--rc-color-text-primary);
  }

  .tabbed-navigation--with-counter & {
    counter-increment: tabbed_navigation_counter;
  }

  &.tabbed-navigation__tab--complete {
    color: var(--rc-color-text-primary);

    &::before {
      @include fa-icon(check-circle);
      color: var(--rc-color-palette-vu-orange-500);
      margin-right: 0.5em;
    }
  }

  &.tabbed-navigation__tab--active {
    color: var(--rc-color-text-primary);
    cursor: default;
    background-color: var(--rc-color-palette-gray-100);
  }

  &.tabbed-navigation__tab--disabled {
    pointer-events: none;

    &:hover {
      background-color: var(--rc-color-palette-bw-white);
      color: var(--rc-color-text-secondary);
    }
  }
}

.tabbed-navigation__label {
  &::before {
    .tabbed-navigation--with-counter & {
      content: counter(tabbed_navigation_counter) '.';
      margin-right: 0.25em;
    }
  }
}
