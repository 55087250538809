/**
 * -- README --
 * This file is automatically generated, do not edit directly.
 * Edit files in `src/tokenSets` then run `npm run build-tokens` from packages/cadence to generate updates.
 */

:root {
  --rc-space-20: 8rem;
  --rc-space-16: 6.4rem;
  --rc-space-15: 6rem;
  --rc-space-14: 5.6rem;
  --rc-space-12: 4.8rem;
  --rc-space-10: 4rem;
  --rc-space-9: 3.6rem;
  --rc-space-8: 3.2rem;
  --rc-space-7: 2.8rem;
  --rc-space-6: 2.4rem;
  --rc-space-5: 2rem;
  --rc-space-4: 1.6rem;
  --rc-space-3: 1.2rem;
  --rc-space-2: 0.8rem;
  --rc-space-1: 0.4rem;
  --rc-space-half: 0.2rem;
  --rc-space-px: 0.1rem;
  --rc-space-0: 0;
  --rc-space-sp: 0.25em;
  --rc-font-weight-bold: 700;
  --rc-font-weight-semibold: 600;
  --rc-font-weight-medium: 500;
  --rc-font-weight-normal: 400;
  --rc-font-family-monospace: monospace;
  --rc-font-family-inter: Inter;
  --rc-color-vendor-mulberry: #483b85;
  --rc-color-vendor-youtube: #d51727;
  --rc-color-vendor-twitter: #55acee;
  --rc-color-vendor-pinterest: #c92228;
  --rc-color-vendor-instagram: #305f84;
  --rc-color-vendor-facebook: #3b5998;
  --rc-color-vendor-affirm: #4a4af4;
  --rc-color-legacy-condition-very-good: #32b7ec;
  --rc-color-legacy-condition-poor: #bf4f18;
  --rc-color-legacy-condition-non-functioning: #bf4f18;
  --rc-color-legacy-condition-mint: #30c966;
  --rc-color-legacy-condition-good: #e6c62c;
  --rc-color-legacy-condition-fair: #f8ae37;
  --rc-color-legacy-condition-excellent: #2ec9bc;
  --rc-color-legacy-condition-brand-new: #30c966;
  --rc-color-legacy-condition-b-stock: #30c966;
  --rc-color-sentiment-warning-darker: #ddb227;
  --rc-color-sentiment-warning-dark: #f5c62b;
  --rc-color-sentiment-warning-light: #fae395;
  --rc-color-sentiment-warning-lighter: #fef9ea;
  --rc-color-sentiment-negative-darkest: #610b05;
  --rc-color-sentiment-negative-darker: #a01308;
  --rc-color-sentiment-negative-dark: #da190b;
  --rc-color-sentiment-negative-dim: #f5493d;
  --rc-color-sentiment-negative-light: #fbbbb6;
  --rc-color-sentiment-negative-lighter: #fee8e7;
  --rc-color-sentiment-positive-darker: #205f20;
  --rc-color-sentiment-positive-dark: #2d862d;
  --rc-color-sentiment-positive-dim: #40bf40;
  --rc-color-sentiment-positive-light: #abe3ab;
  --rc-color-sentiment-positive-lighter: #d9f2d9;
  --rc-color-palette-teal-900: #08514b;
  --rc-color-palette-teal-800: #0e8d84;
  --rc-color-palette-teal-700: #12b6a9;
  --rc-color-palette-teal-600: #14cabc;
  --rc-color-palette-teal-500: #43d5c9;
  --rc-color-palette-teal-400: #72dfd7;
  --rc-color-palette-teal-300: #a1eae4;
  --rc-color-palette-teal-200: #d0f4f2;
  --rc-color-palette-teal-100: #e7faf8;
  --rc-color-palette-tweed-orange-900: #7b4408;
  --rc-color-palette-tweed-orange-800: #bf8601;
  --rc-color-palette-tweed-orange-700: #e6a101;
  --rc-color-palette-tweed-orange-600: #ffb301;
  --rc-color-palette-tweed-orange-500: #ffc234;
  --rc-color-palette-tweed-orange-400: #ffd167;
  --rc-color-palette-tweed-orange-300: #ffe199;
  --rc-color-palette-tweed-orange-200: #fff0cc;
  --rc-color-palette-tweed-orange-100: #fff7e8;
  --rc-color-palette-kick-blue-900: #0a0033;
  --rc-color-palette-kick-blue-800: #100053;
  --rc-color-palette-kick-blue-700: #14006c;
  --rc-color-palette-kick-blue-600: #18007f;
  --rc-color-palette-kick-blue-500: #463399;
  --rc-color-palette-kick-blue-400: #7466b2;
  --rc-color-palette-kick-blue-300: #a399cc;
  --rc-color-palette-kick-blue-200: #d1cce5;
  --rc-color-palette-kick-blue-100: #e8e6f2;
  --rc-color-palette-vu-orange-900: #662700;
  --rc-color-palette-vu-orange-800: #a64000;
  --rc-color-palette-vu-orange-700: #e55800;
  --rc-color-palette-vu-orange-600: #ff6200;
  --rc-color-palette-vu-orange-500: #ff8133;
  --rc-color-palette-vu-orange-400: #ffa166;
  --rc-color-palette-vu-orange-300: #ffc099;
  --rc-color-palette-vu-orange-200: #ffe0cc;
  --rc-color-palette-vu-orange-100: #ffefe5;
  --rc-color-transparent: rgba(0, 0, 0, 0);
  --rc-color-highlight-900: rgba(255, 255, 255, 0.96);
  --rc-color-highlight-800: rgba(255, 255, 255, 0.94);
  --rc-color-highlight-700: rgba(255, 255, 255, 0.88);
  --rc-color-highlight-600: rgba(255, 255, 255, 0.74);
  --rc-color-highlight-500: rgba(255, 255, 255, 0.62);
  --rc-color-highlight-400: rgba(255, 255, 255, 0.46);
  --rc-color-highlight-300: rgba(255, 255, 255, 0.38);
  --rc-color-highlight-200: rgba(255, 255, 255, 0.26);
  --rc-color-highlight-100: rgba(255, 255, 255, 0.13);
  --rc-color-highlight-0: rgba(255, 255, 255, 0);
  --rc-color-shadow-900: rgba(0, 0, 0, 0.87);
  --rc-color-shadow-800: rgba(0, 0, 0, 0.74);
  --rc-color-shadow-700: rgba(0, 0, 0, 0.62);
  --rc-color-shadow-600: rgba(0, 0, 0, 0.54);
  --rc-color-shadow-500: rgba(0, 0, 0, 0.38);
  --rc-color-shadow-400: rgba(0, 0, 0, 0.26);
  --rc-color-shadow-300: rgba(0, 0, 0, 0.12);
  --rc-color-shadow-200: rgba(0, 0, 0, 0.07);
  --rc-color-shadow-100: rgba(0, 0, 0, 0.04);
  --rc-color-shadow-0: rgba(0, 0, 0, 0);
  --rc-color-palette-yellow-900: #665a02;
  --rc-color-palette-yellow-800: #a69204;
  --rc-color-palette-yellow-700: #e6cb05;
  --rc-color-palette-yellow-600: #ffe106;
  --rc-color-palette-yellow-500: #ffe738;
  --rc-color-palette-yellow-400: #ffed6a;
  --rc-color-palette-yellow-300: #fff39b;
  --rc-color-palette-yellow-200: #fff9cd;
  --rc-color-palette-yellow-100: #fffce6;
  --rc-color-palette-gray-900: #212121;
  --rc-color-palette-gray-800: #424242;
  --rc-color-palette-gray-700: #616161;
  --rc-color-palette-gray-600: #757575;
  --rc-color-palette-gray-500: #9e9e9e;
  --rc-color-palette-gray-400: #bdbdbd;
  --rc-color-palette-gray-300: #e0e0e0;
  --rc-color-palette-gray-200: #eeeeee;
  --rc-color-palette-gray-100: #f5f5f5;
  --rc-color-palette-bw-white: #ffffff;
  --rc-color-palette-bw-soft-black: #121212;
  --rc-color-palette-bw-black: #000000;
  --rc-color-palette-blue-900: #021f62;
  --rc-color-palette-blue-800: #04339f;
  --rc-color-palette-blue-700: #0542d0;
  --rc-color-palette-blue-600: #064ef5;
  --rc-color-palette-blue-500: #3871f7;
  --rc-color-palette-blue-400: #6a95f9;
  --rc-color-palette-blue-300: #9bb8fb;
  --rc-color-palette-blue-200: #cddcfd;
  --rc-color-palette-blue-100: #ecedfe;
  --rc-border-radius-full: 100rem;
  --rc-border-radius-xl: 2.4rem;
  --rc-border-radius-lg: 1.6rem;
  --rc-border-radius-md: 0.8rem;
  --rc-border-radius-sm: 0.4rem;
  --rc-border-radius-0: 0;
}
