.checkout-header {
  border-bottom: solid 0.1rem var(--rc-color-border-primary);
  background-color: var(--rc-color-background-module);
  padding: 3rem 0;
}

.checkout-header__inner {
  @include site-wrapper;
}

.checkout-header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.checkout-header__logo {
  display: block;
  margin-right: 2rem;
  color: var(--rc-color-text-primary);
  max-width: 98px;

  &:hover {
    color: var(--rc-color-text-secondary);
  }

  .rc-icon {
    display: block;
    height: 3rem;
    width: auto;
  }
}

.checkout-header__secure-msg {
  margin-right: auto; // auto keeps things centered without allowing the button to be super wide

  @include mobile {
    order: 3;
    flex-basis: 100%;
    margin-top: 1rem;
  }
}

.checkout-header__secure-msg__trigger {
  @include fa-icon-before(lock);
  @include button-reset;
  color: var(--rc-color-sentiment-positive-dark);
  font-weight: bold;
  margin-top: 1rem; // small offset to more visually center against logo
}

.checkout-header__cart-link {
  font-weight: bold;

  @include mobile {
    order: 2;
  }
}
