@use '../tools/mixins/rc-button';

.rc-button {
  @include rc-button.base;

  // Colors
  // Example compile: rc-button.rc-button--danger.rc-button--muted.rc-button--inverted {}
  @each $theme in rc-button.$button-themes {
    @each $variant in rc-button.$button-variants {
      &.rc-button--#{$theme}.rc-button--#{$variant} {
        @include rc-button.colors($theme: $theme, $variant: $variant, $inverted: false);
      }

      &.rc-button--#{$theme}.rc-button--#{$variant}.rc-button--inverted {
        @include rc-button.colors($theme: $theme, $variant: $variant, $inverted: true);
      }
    }
  }

  // Sizes
  @each $size in rc-button.$button-sizes {
    &.rc-button--#{$size} {
      @include rc-button.size($size: $size);
    }
  }

  // Misc Modifiers
  &.rc-button--full-width {
    width: 100%;
  }
}
