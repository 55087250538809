.price-guide-timeline {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 38rem;

  @include mobile {
    min-height: 0;
    height: 25rem;
  }
}

.price-guide-timeline__chart {
  flex: 1;
  min-height: 14rem;
  padding: 1rem 0;
  box-sizing: border-box;

  .ct-series-a {
    .ct-line {
      stroke: var(--rc-color-legacy-condition-brand-new);
      stroke-width: 0.3rem;
    }

    .ct-point {
      stroke: var(--rc-color-legacy-condition-brand-new);
      stroke-width: 0.8rem;
    }
  }
}

.price-guide-timeline__label {
  display: flex;
  width: auto;
  font-size: 1rem;
  opacity: 0.7;
  line-height: 1;
  text-align: right;
  align-items: center;
  padding: 0;
  margin: 0;
  white-space: nowrap;

  &.price-guide-timeline__vertical {
    transform: translateY(50%);
    justify-content: flex-end;
  }

  &.price-guide-timeline__horizontal {
    @include tablet {
      transform: rotate(30deg);
    }
  }
}
