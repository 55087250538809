@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.locale-switcher {
  @include rc-button.outlined($inverted: true);
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 0;

  @include mobile {
    width: 100%;
  }

  .rc-icon {
    padding: 0 1.2rem;
    display: flex;
    align-items: center;
    width: auto;
    flex-grow: 0;

    svg {
      width: 2.2rem;
      height: auto;

      @include mobile {
        width: 1.8rem;
      }
    }
  }

  .site-subfooter & {
    @include tablet {
      margin: 0 auto;
    }
  }
}

.locale-switcher__current {
  display: flex;
  align-items: stretch;
  overflow: hidden;
  width: 100%;
}

.locale-switcher__detail {
  display: flex;
  align-items: center;
  padding: var(--rc-padding-tb-button-medium) 1.2rem;
  border-left: 0.1rem solid var(--rc-color-button-main-primary-inverted);
  flex: 1;

  @include mobile {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
