@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.indented-row-content {
  @include site-wrapper;
  @include gap-row;

  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }
}

.indented-row-titles {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.indented-row__title {
  @include rc-text.title('900');
  margin-bottom: 0.8rem;
  align-self: center;
}

.indented-row__action {
  .overflowing-row-action {
    padding: 0;
  }
}

.indented-row-collection {
  @include max-vw(max-width, 65);
  max-width: 65vw;
  min-width: 100%;

  @include tablet {
    @include max-vw(max-width, 100);
    max-width: 100vw;
  }

  .overflowing-row__heading,
  .overflowing-row__action {
    display: none;
    @include tablet {
      display: block;
    }
  }

  .overflowing-row {
    padding-left: 0;
    padding-right: 0;
    @include tablet {
      padding-left: var(--rc-space-gutter-page);
      padding-right: var(--rc-space-gutter-page);
    }
  }
}
