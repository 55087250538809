.recently-viewed-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.recently-viewed-container__content {
  @include site-wrapper;
}

.recently-viewed-container__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 7rem;
}

.recently-viewed-container__empty-header {
  font-size: 2rem;
  margin-top: 4rem;
  font-weight: 600;
}

.recently-viewed-container__instructions {
  margin-top: 1rem;
}

.recently-viewed-header__right-header {
  display: flex;
  float: right;
  margin-bottom: 2rem;

  @include mobile {
    flex-direction: column;
  }
}
