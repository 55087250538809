@use '@reverbdotcom/cadence/styles/tools/mixins/aspect-ratio';

.trait-collection {
  @include site-wrapper;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.trait-collection__item {
  @include commons-svg-background('instruments-darken.svg');
  @include aspect-ratio.aspect-ratio(1, 1);

  border: 0.1rem solid var(--rc-color-border-primary);
  width: 100%;
  background-color: var(--rc-color-palette-gray-300);
  background-repeat: repeat;
  display: flex;
  position: relative;
  color: var(--rc-color-text-primary);
  border-radius: 2rem;
  flex: none;
  order: 0;
  flex-grow: 0;

  // with background image
  &[style] {
    background-size: cover;
  }
}

.trait-collection__item--outer {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.trait-collection__item-text {
  color: var(--rc-color-text-primary)
}
