.checkout-cart-header {
  border-bottom: solid 0.1rem var(--rc-color-border-primary);
  padding-bottom: 4rem;
  margin-bottom: 4rem;

  @include mobile {
    display: block;
    width: 100%;
  }

  &.cart-hoisted-express-pay-options {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &:empty {
    display: none;
  }
}

.checkout-cart-header__button {
  @include mobile {
    width: 100%;
  }

  &.checkout-cart-header__button--apple-pay {
    margin-right: 2rem;

    @include mobile {
      display: block;
      margin-right: 0;
      margin-top: 1rem;
    }

    &:empty {
      display: none;
    }
  }
}
