
.photo-tiles {
  display: flex;
  flex-wrap: nowrap;
  gap: var(--rc-space-3);
}

.photo-tile {
  --size: 10rem;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: var(--size);
  height: var(--size);

  @include mobile {
    --size: 7.5rem;
  }

  &:last-child {
    padding-right: 1rem;
  }
}

.photo-tile__image {
  width: 100%;
  transition: box-shadow 0.15s;
  overflow: hidden;
  border-radius: var(--rc-border-radius-md);
  background-color: var(--rc-color-palette-gray-100);

  @include responsive-hover {
    @include box-shadow;
  }
}

.photo-tile__placeholder {
  @include loading-pulse;

  width: 100%;
  padding-bottom: 100%;
  border: 0.1rem solid var(--rc-color-border-primary);
  background: var(--rc-color-palette-gray-100);
}
