.app-install-qr-code {
  display: flex;
  padding: 1rem;
  border: 0.1rem solid var(--rc-color-palette-gray-900);
  justify-content: space-between;
  align-items: center;
  font-size: 1.3em;
  box-sizing: border-box;

  div {
    margin-right: 1rem;
  }

  img {
    width: 8rem;
    height: 8rem;
  }

  @include mobile {
    display: none;
  }

  .in-mobile-app & {
    display: none;
  }
}
