.image-embed {
  .dynamic-page__one-col & {
    @include site-wrapper;
    @include site-wrapper--cms;
  }

  &.image-embed--center {
    @include gap-row;
  }
}

.image-embed__inner {
  width: 40%;
  margin: 1.5em auto;

  @include tablet {
    width: 50%;
  }

  .image-embed--center & {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .image-embed--left & {
    float: left;
    margin-right: 1.5em;

    @include mobile {
      float: none;
      width: 100%;
      margin: 1.5em auto;
    }

    .dynamic-page__one-col & {
      @include site-wrapper-overhang-left;

      @include mobile {
        margin-left: auto;
      }
    }
  }

  .image-embed--right & {
    float: right;
    margin-left: 1.5em;

    @include mobile {
      float: none;
      width: 100%;
      margin: 1.5em auto;
    }

    .dynamic-page__one-col & {
      @include site-wrapper-overhang-right;

      @include mobile {
        margin-right: auto;
      }
    }
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  > a {
    display: block;
    transition: opacity .2s;

    &:hover {
      opacity: 0.9;
    }
  }
}

.image-embed__caption {
  font-size: 0.85em;
  font-weight: bold;
  text-align: center;
  margin-top: 0.5em;
}
