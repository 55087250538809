@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.gear-collection-page-header {
  .dashboard-page-header {
    padding: 0;
    margin-top: var(--rc-space-6);
    margin-bottom: var(--rc-space-10);
    border: none;
  }

  .dashboard-page-header__breadcrumb {
    font-weight: var(--rc-font-weight-medium);

    a {
      @include text-link;

      color: var(--rc-color-text-textlink);
    }

    &::after {
      content: '>';
    }
  }
}

.gear-collection-csp-search__content {
  .faceted-grid__inner {
    padding: 0;
  }
}

.gear-collection-item-form {
  @include tablet {
    margin-bottom: -1rem;
  }
}

.gear-collection-item-form-page-header {
  .dashboard-page-header__main {
    display: flex;
    align-items: center;
  }

  .dashboard-page-header__actions {
    margin-top: 0;
  }

  .dashboard-page-header__action-wrapper {
    margin-top: 0;
  }
}

.gear-collection-item-form__fields {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-8);

  @include tablet {
    gap: var(--rc-space-6);
  }
}

.gear-collection-item-form-section {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-3);

  button.multiple-photo-uploader__file-uploader {
    margin-bottom: 0;
  }

  .multiple-photo-uploader__alert {
    margin-top: var(--rc-space-2);
    margin-bottom: 0;
  }

  .multiple-photo-uploader__thumbnails-section {
    margin-top: 2rem;
  }

  .rc-product-card__wrapper {
    display: flex;
    flex-direction: row;
  }

  .rc-product-card__image-wrapper {
    width: 30%;
    padding: var(--rc-space-2);
  }
}

.gear-collection-item-form-section__header {
  font-size: var(--rc-font-size-500);
  font-weight: var(--rc-font-weight-bold);
}

.gear-collection-item-form-section__subheader {
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
}

.gear-collection-item-form__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include tablet {
    @include box-shadow;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    flex-direction: column-reverse;
    background: var(--rc-color-background-module);
    justify-items: center;
    transform: translate(calc(-1 * var(--rc-space-gutter-page)));
    z-index: 10; // so it goes on top of rc-select::after

    &.gear-collection-item-form__footer--native-mobile {
      position: fixed;
      transform: none;
    }
  }
}

.gear-collection-item-form__footer__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--rc-space-gutter-page) 0 var(--rc-space-10);

  @include tablet {
    justify-content: flex-end;
    width: calc(100% - var(--rc-space-5));
    padding: var(--rc-space-5);
    padding-bottom: max(var(--rc-space-5), var(--rc-space-inset-browser-safe-area-bottom));
  }
}

.gear-collection-item-form__footer__actions--flex-end {
  justify-content: flex-end;
}

.gear-collection-item-form__footer__actions__submit-container {
  display: flex;
  gap: var(--rc-space-6);
}

.gear-collection-item-form__footer__actions__submit-container--full-width {
  width: 100%;
}

.gear-collection-item-form__pricing-section {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-5);
}

.gear-collection-item-form__price-estimate {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-5);
}

.gear-collection-item-form__price-estimate__pricing-guidance {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-3);
  background-color: var(--rc-color-background-module-price-guide-secondary);
  border-radius: var(--rc-border-radius-primary);
  padding: var(--rc-space-4);
}

.gear-collection-item-form__price-estimate__pricing-guidance__title {
  font-size: var(--rc-font-size-500);
  font-weight: var(--rc-font-weight-bold);
}

.gear-collection-item-form__price-estimate__pricing-guidance__content {
  display: flex;
  gap: var(--rc-space-4);
}

.gear-collection-item-form__price-estimate__pricing-guidance__content__estimated-range {
  color: var(--rc-color-text-primary);
  font-size: var(--rc-font-size-600);
  font-weight: var(--rc-font-weight-bold);
  align-self: center;
}
