.typical-new-price-display {
  display: inline-block;
  font-size: var(--rc-font-size-350);
  margin-top: 1rem;
  padding-left: 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 0.5rem;
  border-radius: 0.4rem;

  &.typical-new-price-display--used {
    background-color: var(--rc-color-background-success-ultralight);
    color: var(--rc-color-text-success-on-success-ultralight);
  }

  &.typical-new-price-display--brand-new {
    background-color: var(--rc-color-background-info-ultralight);
    color: var(--rc-color-text-info-on-info-ultralight);
  }
}
