@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.truncation-container {
  overflow: hidden;
  position: relative;

  &.truncation-container--open {
    height: auto;
    overflow: unset;
  }
}

.truncation-container__show-more {
  --background-color: var(--rc-color-background-page);
  background: linear-gradient(to bottom, var(--rc-color-highlight-0), var(--background-color));
  padding: 5em 0 2em 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  text-align: center;

  .truncation-container--open & {
    display: none;
  }

  .item2 & {
    padding: 3rem 0 1rem;
  }
}

.truncation-container__show-more__button {
  @include rc-button.muted;

  .item2 & {
    @include rc-button.muted;
    width: 25%;
    min-width: 20rem;
  }
}
