.suggested-package-toggle {
  background-color: var(--rc-color-background-alert-info);
  padding: var(--rc-space-3);
  border-radius: var(--rc-space-2);
  margin-bottom: var(--rc-space-5);
}

.suggested-package-toggle__description {
  font-size: var(--rc-font-size-350);
  margin-top: var(--rc-space-4);
  margin-left: var(--rc-space-15);
  margin-bottom: 0;
}
