.category-flyout {
  @include site-wrapper;

  position: absolute;
  left: 0;
  right: 0;
  font-size: 1.3rem;
  display: none;
  // over category-flyout-header__underlay,
  // under category-flyout-header__link-bar,
  z-index: 10001;
  background: var(--rc-color-background-module);
  border-top: 0.1rem solid var(--rc-color-border-primary);

  @include tablet {
    padding: 0;
    max-width: none;
  }

  &.category-flyout--visible {
    display: block;
  }
}


.category-flyout__panel {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: var(--rc-color-background-page);
  white-space: normal;
  overflow: hidden;
  opacity: 0;
  height: 0;

  .category-flyout-header--open & {
    @include box-shadow;
    opacity: 1;
    transition: opacity .2s;
    height: auto;
  }
}

.category-flyout__header {
  padding-right: var(--rc-space-gutter-page);
  position: relative;

  .category-flyout__heading {
    font-size: 2em;
    margin-right: 1em;
    display: inline-block;
    line-height: 1.2;
    font-weight: 400;

    @include tablet {
      font-size: 1.8em;
    }
  }

  a {
    color: inherit;

    @include responsive-hover {
      color: var(--rc-color-text-link-hovered);
    }
  }

  // avoid overlap with close button
  @include mobile {
    padding-right: 3rem;
    padding-right: var(--rc-space-gutter-page);
  }
}

.category-flyout__content {
  display: flex;
  width: 100%;

  @include tablet {
    display: block;
  }
}

.category-flyout__primary {
  padding: 2.5rem 1rem 2.5rem 0;
  flex: 1;

  @include tablet {
    padding: var(--rc-space-gutter-page);
    padding-right: 0;
  }

  &:only-child {
    padding-right: 0;
  }
}

.category-flyout__columns {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;

  @include tablet {
    @include smooth-scroll;

    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.category-flyout__column {
  flex: 1 0 25%;
  padding: 1em 1rem;
  box-sizing: border-box;

  .category-flyout__primary:only-child & {
    flex: 1;
  }

  @include tablet {
    flex: 0 0 40vw;
  }
}

.category-flyout__column__section {
  + .category-flyout__column__section {
    margin-top: 2rem;
  }

  @include mobile {
    box-sizing: border-box;
  }
}

.category-flyout__subhead {
  font-size: 1.2em;
  font-weight: bold;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
  padding: 0.6rem 0;
  margin-bottom: 0.6rem;

  a {
    color: inherit;
    display: block;

    @include responsive-hover {
      color: var(--rc-color-text-link-hovered);
    }
  }
}

.category-flyout__secondary {
  flex: 0 0 30%;
  max-width: 26rem;
  border-left: 0.1rem solid var(--rc-color-border-primary);
  padding: 2rem;
  padding-right: 0;

  @include tablet {
    border-left: none;
    max-width: none;
    border-top: 0.1rem solid var(--rc-color-border-primary);
    padding: 2rem;
  }

  @include mobile {
    padding: 1rem;
  }
}

.category-flyout__secondary__inner {
  @include tablet {
    display: flex;
  }
}

.category-flyout__secondary__callout {
  margin-bottom: 2rem;

  @include tablet {
    width: 30%;
    padding-right: 2rem;
  }
}

.category-flyout__link {
  @include group-link;
  color: var(--rc-color-text-primary);
  padding: 0.6rem 0;
  line-height: 1.2;
  display: block;

  @include tablet {
    font-size: 1.1em;
  }
}

.category-flyout__close {
  position: absolute;
  right: 1rem;
  top: 0;
}
