.shop-badge-statuses {
  @include grid-container;
}

.shop-badge-status {
  @include grid-columns(4, 12, 12);

  &.shop-badge-status--extra-wide {
    @include grid-columns(6, 12, 12);
  }

  @include tablet {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.shop-badge-status__inner {
  border: solid 0.1rem var(--rc-color-border-primary);
  border-radius: $input-border-radius;
  padding: 2rem;
}

.shop-badge-status__title {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  font-size: 1.6rem;
  color: var(--rc-color-text-secondary);

  .shop-badge-status--complete & {
    color: var(--rc-color-text-primary);
  }

  .rc-icon {
    display: block;
    width: 4rem;
    margin-bottom: 1rem;
    color: var(--rc-color-palette-gray-600);

    .shop-badge-status--complete & {
      color: var(--rc-color-palette-blue-600);
    }
  }
}

.shop-badge-status__percentage {
  margin-top: 2rem;

  .percentage-bar {
    width: 100%;
  }
}

.shop-badge-status__percentage-label {
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
  margin-top: 0.5rem;
  text-align: center;
}

.shop-badge-status__toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.shop-badge-status__steps {
  display: none;
  padding-left: 1.5rem;

  .shop-badge-status--step-open & {
    display: block;
  }
}

.shop-badge-status__step {
  padding: 0.5rem 0;
  list-style-type: decimal;
  font-size: 1.4rem;
}

.shop-badge-status__step-label {
  display: block;
  color: var(--rc-color-text-primary);
  overflow-wrap: break-word;

  .shop-badge-status__step--complete & {
    color: var(--rc-color-text-success);
    text-decoration: line-through;
  }
}

.shop-badge-status__step-link {
  @include text-link;
  margin-top: 0.5rem;
}

.shop-badge-status__step-note {
  display: block;
  margin-top: 0.5rem;
  padding: 1rem;
  border-radius: $input-border-radius;
  background-color: var(--rc-color-palette-yellow-100);
}
