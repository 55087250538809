@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/responsive';
@use '@reverbdotcom/cadence/styles/tools/mixins/vendor/fontawesome';
@use '@reverbdotcom/cadence/styles/tools/mixins/loading-pulse';

.filter-chip {
  --icon-size: 2.8rem;
  --padding-right: var(--rc-padding-lr-button-medium);

  @include rc-button.muted;
  position: relative;
  padding-right: var(--padding-right);
  gap: var(--rc-space-2);
  min-height: 40px;

  .rc-icon {
    width: 2.4rem;
  }

  &.filter-chip--with-dropdown,
  &.filter-chip--with-count {
    --padding-right: 4.4rem;
  }

  &.filter-chip--with-mobile-count {
    @include responsive.mobile {
      --padding-right: 4.4rem;
    }
  }

  &.filter-chip--with-dropdown {
    &:hover,
    &[data-floating-ui-is-open='true'] {
      .filter-chip__count {
        opacity: 0;
      }
    }
  }

  &.filter-chip--selected {
    @include rc-button.colors($theme: 'main', $variant: 'muted', $inverted: false);
    --border-color: var(--rc-color-button-main-primary);
  }

  &.filter-chip--loading {
    --border-color: var(--rc-color-border-primary);
    opacity: 0.6;
  }

  &.filter-chip--text-link {
    @include rc-button.colors($theme: 'main', $variant: 'transparent', $inverted: false);

    &:hover {
      text-decoration: underline;
    }

    @include mobile {
      @include rc-button.colors($theme: 'main', $variant: 'muted', $inverted: false);
    }
  }

  .filter-chip__dropdown,
  .filter-chip__count {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--icon-size);
    height: var(--icon-size);
    box-sizing: border-box;
    border-radius: 50%;
    line-height: 1;
  }
}

.filter-chip__dropdown {
  // rotate the caret when dropdown is open
  [data-floating-ui-is-open='true'] & {
    transform: translateY(calc(-50%)) rotate(180deg);
  }

  svg {
    display: block;
    width: 100%;
    height: auto;
    fill: currentColor;
  }
}

.filter-chip__count {
  visibility: hidden;
  background-color: var(--rc-color-palette-gray-900);
  color: var(--rc-color-palette-bw-white);
  font-size: 1.4rem;
  padding: 0.3rem 0;
  opacity: 1;
  transition: opacity 0.2s;

  .filter-chip--with-count & {
    visibility: visible;
  }

  .filter-chip--with-mobile-count & {
    visibility: hidden;

    @include responsive.mobile {
      visibility: visible;
    }
  }
}

.filter-chip__loading-placeholder {
  @include loading-pulse.loading-pulse;
  background-color: var(--rc-color-palette-gray-200);
  width: 8rem; // arbitrary value to estimate normal button width
  height: 1rem;
  border-radius: 1em;
  margin: 0.6rem;
}

.filter-chip__group-title {
  margin: 0 1rem 1rem;
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--rc-color-text-primary);
  white-space: nowrap;

  &:first-of-type {
    margin-left: 0;
  }
}
