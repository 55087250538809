@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.sell-marketing-info {
  h2 {
    @include responsive(padding-bottom, var(--rc-space-10), var(--rc-space-8), var(--rc-space-4));
    @include rc-text.title('900');
    text-align: center;
    padding-bottom: var(--rc-space-10);
  }
}
