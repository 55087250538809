@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.selected-carrier {
  display: flex;
  gap: var(--rc-space-3);
}

.selected-carrier__logo-container-usps {
  width: 5.6rem;
  padding: var(--rc-space-1) 0;
  gap: var(--rc-space-2);
}

.selected-carrier__logo-container-ups {
  width: 4rem;
  padding: 0 var(--rc-space-2);
  gap: var(--rc-space-2);
}

.selected-carrier__text {
  @include rc-text.default('350');
}
