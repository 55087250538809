@use '../tools/mixins/dialogs';
@use '../tools/mixins/focus-outline';
@use '../tools/mixins/links';
@use '../tools/mixins/box-shadow';

.rc-toast-wrapper {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: var(--rc-space-5);
  left: var(--rc-space-0);
  right: var(--rc-space-0);
  width: 100%;
  z-index: 99999;
  opacity: 1;
  transform: translateY(0);
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(.4, 0.0, 0.2, 1); // Material's FastOutSlowIn

  &[data-status='open'] {
    transition-duration: 0.3s
  }

  &[data-status='initial'] {
    opacity: 0;
    transform: translateY(2rem);
  }

  @include mobile {
    --mobile-offset: 1rem;
    // uses the default padding value or the browser safe area, whichever is larger
    bottom: max(var(--mobile-offset), var(--rc-space-inset-browser-safe-area-bottom));
  }
}

.rc-toast {
  @include box-shadow.box-shadow;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: var(--rc-color-palette-gray-700);
  border-radius: var(--rc-border-radius-md);
  color: var( --rc-color-palette-bw-white);
  padding: var(--rc-space-2) var(--rc-space-4);
  min-width: 35rem;
  max-width: calc(100vw - 2rem);
  min-height: 5rem;
  gap: var(--rc-space-4);
  border: solid 0.1rem var(--rc-color-palette-gray-500);
  z-index: var(--z-index-toast);

  @include mobile {
    min-width: unset;
    width: 100%;
  }

  a {
    @include links.text-link;
    font-weight: bold;

    @include responsive-hover {
      color: var(--rc-color-palette-vu-orange-300);
    }
  }
}

.rc-toast__content {
  display: flex;
  align-items: center;
  flex: 1;
}

.rc-toast__icon {
  display: flex;
  align-items: center;
  margin-right: 1rem;

  .rc-icon.rc-icon--inline {
    height: 2.2rem;
  }

  &.rc-toast__icon--watch-add {
    color: var(--rc-color-palette-vu-orange-600);
  }

  &.rc-toast__icon--success {
    color: var(--rc-color-sentiment-positive-light);
  }

  &.rc-toast__icon--error {
    color: var(--rc-color-sentiment-negative-light);
  }
}

.rc-toast__close-button {
  margin-right: calc(var(--rc-space-1) * -1);
}
