@keyframes bob {
  0% {
    transform: translateY(-0.02vw) rotate(-1deg);
  }

  15% {
    transform: translateY(0.02vw) translateX(0.05vw);
  }

  30% {
    transform: translateY(-0.02vw) rotate(1deg);
  }

  45% {
    transform: translateY(0.1vw) translateX(-0.05vw);
  }

  60% {
    transform: translateY(-0.02vw) rotate(-1deg);
  }

  70% {
    transform: translateY(0.02vw) translateX(0.05vw);
  }

  80% {
    transform: translateY(-0.02vw) rotate(1deg);
  }

  90% {
    transform: translateY(0.02vw) translateX(-0.05vw);
  }

  100% {
    transform: translateY(-0.02vw) rotate(-1deg);
  }
}

@keyframes pop {
  0% {
    transform: translateY(0%);
  }

  20% {
    transform: translateY(0%);
  }

  22% {
    transform: translateY(-30%);
  }

  24% {
    transform: translateY(-35%);
  }

  27% {
    transform: translateY(-30%);
  }

  31% {
    transform: translateY(-32%);
  }

  35% {
    transform: translateY(-30%);
  }

  43% {
    transform: translateY(-34%);
  }

  50% {
    transform: translateY(-30%);
  }

  58% {
    transform: translateY(-32%);
  }

  60% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(0%);
  }
}

.not-found-animation {
  display: block;
  max-width: 40rem;
  height: auto;
  margin: 1rem auto;
  padding: 0 2rem;

  svg {
    width: 100%;
    height: auto;
  }

  .not-found-animation--ended-sale & {
    opacity: 1;
    max-width: 22rem;
  }

  @include mobile {
    display: none;
  }
}

.not-found-animation__periscope {
  animation: pop 10s 0.5s infinite ease-in-out;
}

.not-found-animation__amp {
  transform-origin: center;
  animation: bob 30s infinite ease-in-out;
}

.not-found-animation__jack {
  transform-origin: center;
  animation: bob 25s infinite ease-in-out;
}

.not-found-animation__drum {
  transform-origin: center;
  animation: bob 20s infinite ease-in-out;
}

.not-found-animation__keyboard {
  transform-origin: center;
  animation: bob 15s infinite ease-in-out;
}

.not-found-animation__usb {
  transform-origin: center;
  animation: bob 12s infinite ease-in-out;
}

// horrific browser hacks to hide this SVG animation in FF and IE

@-moz-document url-prefix() {
  .not-found-animation {
    display: none;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .not-found-animation {
    display: none;
  }
}

