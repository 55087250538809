@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.sell-form-review-section__section-header {
  display: grid;
  padding: 1rem 1rem 1rem 2rem;
  grid-template-columns: 1fr auto;
  align-items: center;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);

  @include mobile {
    padding: 1rem;
  }
}

.sell-form-review-section__section-header__title {
  @include rc-text.title('700');
}

.sell-form-review-section__description-content {
  ol {
    @extend %cms-ol;

    li {
      @extend %cms-ol-li;
    }
  }

  ul {
    @extend %cms-ul;

    li {
      @extend %cms-ul-li;
    }
  }
}

.sell-form-review-section__description__profile__name {
  margin-bottom: 2rem;
}
