@use '../tools/mixins/dialogs';
@use '../tools/mixins/focus-outline';

.rc-non-modal-dialog-base {
  @include dialogs.panel;
  z-index: var(--z-index-popover);

  &:focus-visible {
    @include focus-outline.focus-outline($offset: true);
  }

  &.rc-non-modal-dialog-base--guide-popover {
    background-color: var( --rc-color-palette-blue-600);
    color: var( --rc-color-palette-bw-white);
    border-color: var( --rc-color-palette-blue-600);

    &:focus-visible {
      @include focus-outline($offset: true);
    }
  }
}

.rc-non-modal-dialog-base__arrow {
  @include dialogs.arrow;

  .rc-non-modal-dialog-base--guide-popover & {
    @include dialogs.arrow($border-color: var( --rc-color-palette-blue-600), $fill-color: var( --rc-color-palette-blue-600), $extend: false);
  }
}
