
.sell-autocomplete {
  @include box-shadow;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  width: calc(100% - 0.2rem);
  background-color: var(--rc-color-palette-bw-white);
  border: 0.1rem solid var(--rc-color-palette-blue-600);
  border-top: none;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.sell-autocomplete__label {
  @include responsive(font-size, 1.25rem, $mobile: 1rem);
  color: var(--rc-color-palette-vu-orange-500);
  font-weight: 700;
  text-transform: uppercase;
  padding: .5rem 1.5rem;
  padding-top: 1rem;
}

.sell-autocomplete__result {
  @include responsive(font-size, 1.6rem, $mobile: 1.3rem);
  display: block;
  color: var(--rc-color-text-primary);
  padding: .5rem 1.5rem;

  &:last-of-type {
    padding-bottom: 1rem;
  }

  &:hover,
  &--active {
    color: var(--rc-color-text-primary);
    background: var(--rc-color-palette-blue-100);
  }
}
