@use '../mixins/focus-outline';

%switch-base {
  position: relative;

  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  label {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    line-height: 1.4;
    align-content: center;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
    }

    &::before {
      top: 0;
      transition-property: background-color, border-color;
      transition-duration: 0.15s;
      transition-timing-function: cubic-bezier(.4, 0.0, 0.2, 1); // Material's FastOutSlowIn;
    }

    &::after {
      border-radius: 50%;
      background-color: var(--rc-color-border-input-primary);
      transition-property: transform, background-color, margin-left;
      transition-duration: 0.15s;
      transition-timing-function: cubic-bezier(.4, 0.0, 0.2, 1); // Material's FastOutSlowIn;
    }

    .form-group & {
      margin: 0.6rem 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  input:disabled {
    + label {
      opacity: 0.7;
      cursor: default;
      pointer-events: none;

      &::after {
        background: var(--rc-color-palette-gray-100);
      }
    }

    + label:active::after {
      transform: scale(1);
    }
  }

  input:focus-visible {
    + label::before {
      @include focus-outline.focus-outline($offset: true);
      border-radius: 0.9rem; // to match switch UI radius
    }
  }
}
