.rc-alert-box {
  border-radius: var(--rc-border-radius-sm);
  border-width: 0 0 0 1rem;
  border-style: solid;
  position: relative;

  :last-child {
    margin-bottom: var(--rc-space-0);
  }

  .rc-alert-box__dismiss {
    position: absolute;
    top: var(--rc-space-1);
    right: var(--rc-space-1);
  }

  &.rc-alert-box--with-header {
    border-width: 0.1rem 0.1rem 0.1rem 1rem;
  }

  &,
  &.rc-alert-box--warning {
    background-color: var(--rc-color-background-alert-warning);
    border-color: var(--rc-color-border-alert-warning);
  }

  &.rc-alert-box--info {
    background-color: var(--rc-color-background-alert-info);
    border-color: var(--rc-color-border-alert-info);
  }

  &.rc-alert-box--error {
    background-color: var(--rc-color-background-alert-error);
    border-color: var(--rc-color-border-alert-error);
  }

  &.rc-alert-box--success {
    background-color: var(--rc-color-background-alert-success);
    border-color: var(--rc-color-border-alert-success);
  }

  &.rc-alert-box--highlight {
    background-color: var(--rc-color-background-alert-highlight);
    border-color: var(--rc-color-border-alert-highlight);
  }

  &.rc-alert-box--plain {
    background-color: var(--rc-color-background-alert-plain);
    border-color: var(--rc-color-border-alert-plain);
  }

  &.rc-alert-box--inline {
    display: inline-block;
  }
}

.rc-alert-box__header {
  background-color: var(--rc-color-background-page);
  padding: var(--rc-space-5);
  border-top-left-radius: calc(var(--rc-border-radius-sm) - 0.1rem);
  border-top-right-radius: calc(var(--rc-border-radius-sm) - 0.1rem);

  // the order of these modifier selectors matters here, edit with caution
  .rc-alert-box--small & {
    padding: var(--rc-space-2);
    font-size: 0.875em; // sets the default rendered size from 16px to 14px
  }

  .rc-alert-box--dismissible & {
    padding-right: var(--rc-space-10);
  }

  .rc-alert-box--unpadded & {
    padding: 0;
  }
}

.rc-alert-box__header-text {
  font-size: 2.2rem;

  .rc-alert-box--small & {
    font-size: 1.8rem;
  }
}

.rc-alert-box__content {
  padding: var(--rc-space-5);
  color: var(--rc-color-text-primary);

  .rc-alert-box--dismissible:not(.rc-alert-box--with-header) & {
    padding-right: var(--rc-space-10);
  }

  .rc-alert-box--small & {
    padding: var(--rc-space-2);
    font-size: 0.875em; // sets the default rendered size from 16px to 14px
  }

  .rc-alert-box--unpadded & {
    padding: var(--rc-space-0);
  }
}
