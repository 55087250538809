
html,
body {
  background-color: var(--rc-color-background-page);
}

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  height: 100%;
  font-family: var(--rc-font-family-page), #{$body-font-family-fallback};
  color: var(--rc-color-text-primary);
  font-size: $body-font-size;
  line-height: var(--rc-font-line-height-default);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  letter-spacing: var(--rc-font-letter-spacing-default);
}

a {
  color: var(--rc-color-text-link);
  text-decoration: none;
  cursor: pointer;
  transition: color .1s;

  @include responsive-hover {
    color: var(--rc-color-text-link-hovered);
  }
}

p {
  margin-bottom: $tag-p-margin-bottom;
  line-height: $tag-p-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--rc-font-family-heading), #{$body-font-family-fallback};
  line-height: var(--rc-font-line-height-title);
  font-weight: var(--rc-font-weight-normal);
}

button {
  font-family: inherit;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

label {
  font-weight: $tag-label-font-weight;
  font-size: $tag-label-font-size;
  display: block;
}

textarea,
input,
select {
  font-family: inherit;
}

input[type='radio'] {
  margin-right: 0.4em;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

select {
  max-width: 100%;
}

hr {
  border: none;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  text-align: left;
}

abbr {
  text-decoration: none;
}

input[type='text'],
input[type='tel'],
input[type='date'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='datetime-local'],
textarea {
  -webkit-appearance: none;
  font-family: inherit;
  padding: var(--rc-padding-tb-input-medium) var(--rc-padding-lr-input-medium);
  font-size: $input-font-size;
  line-height: $input-line-height;
  border-radius: var(--rc-border-radius-input);
  background: var(--rc-color-background-input);
  border: 0.1rem solid var(--rc-color-border-input-primary);
  color: var(--rc-color-text-primary);
  resize: none;
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  transition: border-color 0.2s;
  min-height: var(--rc-size-height-input);

  .form-group--with-error & {
    border-color: var(--rc-color-border-input-error);
  }

  &::placeholder {
    color: var(--rc-color-text-secondary);
    opacity: 1; // Fix for browsers that don't default to 1
  }

  &:disabled,
  &[disabled],
  &.disabled {
    background: var(--rc-color-background-input-disabled);
    color: var(--rc-color-text-secondary);
    cursor: default;
  }

  &:focus-visible {
    outline-offset: -0.1rem; // same as the border thickness of the inputs
  }
}

textarea {
  width: 100%;
  min-height: 5em;
  resize: none;
  display: block;
}
