.price-breakdown__table {
  color: var(--rc-color-text-secondary);
  border-spacing: 0 0.3rem;
  border-collapse: separate;
  width: 100%;

  tr:last-child {
    font-weight: bold;
    color: var(--rc-color-text-primary);
  }
}

.price-breakdown__value {
  text-align: right;
}

.price-breakdown_bulleted-list {
  @extend %cms-ul;
  margin: 0;
  margin-left: 2rem;

  li {
    @extend %cms-ul-li;
  }
}

.price-breakdown__selling-fee-alert {
  margin-bottom: 1rem;
}

.price-breakdown__vat-id-alert {
  margin-top: 1rem;
}
