@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.review-item {
  border-top: 0.1rem solid var(--rc-color-border-primary);
  padding-top: $grid-gutter;
  width: 100%;
  display: flex;
  font-size: 0.9em;

  @include tablet {
    font-size: 0.85em;
  }

  .item2__content__section & {
    padding: 2rem 0 1rem;
  }
}

.review-item__user {
  flex: 0 0 12rem;
  font-weight: bold;
  text-align: center;

  @include tablet {
    flex-basis: 9rem;
  }
}

.review-item__avatar {
  @include hyphenate;

  hyphens: auto;
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  background: var(--rc-color-palette-gray-200);
  background-size: cover;
  display: block;
  margin: 0 auto 1rem auto;

  @include tablet {
    width: 5rem;
    height: 5rem;
  }
}

.review-item__main {
  flex: 1;
}

.review-item__body {
  margin-bottom: 0;
  word-break: break-word;
  overflow-wrap: break-word;
}

.review-item__mark-helpful {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.review-item__mark-helpful__button {
  @include rc-button.muted($size: 'mini');
}

.review-item__mark-helpful__count {
  font-size: 90%;
  margin-left: 1rem;
  margin-bottom: 0;
  color: var(--rc-color-text-secondary);
}

.review-item__marked-as-helpful {
  @include fa-icon-before(check, $margin-right: 0.3em);

  color: var(--rc-color-sentiment-positive-dark);
  font-size: 90%;
  margin-bottom: 0;
}
