@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.sidebar-articles {
  margin: 2rem 0;

  padding: 0;
  border: none;

  @include mobile {
    margin-left: var(--rc-space-gutter-page);
    margin-right: var(--rc-space-gutter-page);
  }

  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

.sidebar-articles__heading {
  @include rc-text.title('700');
  margin-bottom: var(--rc-space-6);

  .sidebar-articles--loading & {
    background: var(--rc-color-background-loading-placeholder);
    width: 30%;
    height: 1em;
    color: transparent;
  }
}
