.signin-separator__wrapper {
  text-align: center;
  margin-bottom: 1rem;
}

.signin-separator__line {
  display: block;
  border-top: .1rem solid var(--rc-color-border-primary);
  margin-bottom: -1.3rem;
}

.signin-separator__text {
  color: var(--rc-color-text-secondary);
  padding: 0.25rem 0.5rem;
  background-color: var(--rc-color-background-module);
  font-size: 1.4rem;
  text-transform: uppercase;
}
