.faceted-grid__inner {
  @include site-wrapper;

  // obviously the number of overrides here show that this spacing is probably
  // something that should be handled by a parent element.
  // Refactor opportunity.
  @include scaling((margin-top margin-bottom), 3rem);

  display: flex;
  justify-content: space-between;

  @include mobile {
    display: block;
  }

  // truncation-container uses overflow: hidden,
  // which keeps the margins here from collapsing with DOM siblings
  // so we'll zero them out instead
  .truncation-container & {
    margin-top: 0;
    margin-bottom: 0;
  }

  // the favorites page has its own heading
  .favorites-content & {
    margin-top: 0;
    margin-bottom: 0;
  }

  // the /holiday page mounts this inside of a dynamic-page;
  // this will be good to refactor soon
  .dynamic-page & {
    margin-top: 0;
    margin-bottom: 0;
  }

  // don't be a wrapper when mounted inside content-frame
  // (used for some dashboard views, e.g. my_auctions_page.tsx)
  .content-frame & {
    padding: 0;
    margin: 0;
  }

  // remove padding from nested site wrappers...
  // this helps with issues caused by nesting Frontend/Core layouts
  .browse-page &,
  .site-wrapper & {
    padding-left: 0;
    padding-right: 0;
  }

  .browse-page & {
    margin-top: 0;
  }
}

.faceted-grid__facets {
  flex: 0 0 20rem;

  &:empty {
    display: none;
  }
}

.faceted-grid__main {
  width: 100%;

  @include mobile {
    width: auto;
  }

  .faceted-grid__facets:empty + & {
    width: 100%;
  }
}

.faceted-grid--shorter {
  .faceted-grid__inner {
    @include scaling((margin-top margin-bottom), 2.4rem);
  }
}
