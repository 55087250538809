.togglable-dropdown__button {
  color: var(--rc-color-palette-blue-600);
  cursor: pointer;
  transition: color .1s;
  background-color: transparent;
  border: none;
  font-size: 1.6rem;

  @include responsive-hover {
    color: var(--rc-color-palette-vu-orange-500);
  }
}
