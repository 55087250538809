@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

.seller-dashboard-listing {
  display: flex;
  font-size: 1.4rem;
  color: var(--rc-color-text-primary);
}

.seller-dashboard-listing__thumbnail {
  width: 6rem;
  margin-right: 1rem;

  img {
    display: block;
    width: 100%;
  }
}

.seller-dashboard-listing__main {
  flex: 1;

  .styled-table & {
    max-width: 20rem;
  }
}

.seller-dashboard-listing__title {
  @include truncate-text.truncate-text(
    $font-size: 1.4rem,
    $line-height: 1.4,
    $lines-to-show: 2,
    $fixed-height: true
  );
  white-space: normal;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.seller-dashboard-listing__sku {
  font-weight: normal;
}

.seller-dashboard-listing__price {
  font-weight: normal;
  margin-bottom: 0.5rem;
}
