.form-group {
  margin-bottom: var(--rc-space-5);

  @include mobile {
    margin-bottom: var(--rc-space-3);
  }

  &.form-group--large {
    margin-bottom: var(--rc-space-16);

    @include mobile {
      margin-bottom: var(--rc-space-8);
    }
  }

  &:last-child {
    margin-bottom: var(--rc-space-0);
  }

  &.form-group--with-error {
    .styled-dropdown {
      border-color: var(--rc-color-border-input-error);
    }
  }
}

.form-group__header {
  display: flex;
  align-items: center;
  margin-bottom: var(--rc-space-1);
  flex-wrap: wrap;

  .form-group--large & {
    margin-bottom: var(--rc-space-2);
  }

  label {
    margin-bottom: 0; // temp to override core
  }
}

.form-group__label-text {
  margin-right: 0.5em;

  .label-with-checkbox & {
    display: inline;
  }

  .form-group--large & {
    font-size: 2.4rem;
    line-height: 1.2;
  }
}

.form-group__fields {
  input,
  select,
  textarea {
    margin-bottom: 0; // temp to override core

    .form-group--large & {
      font-size: 2rem;
      padding: var(--rc-space-4);
    }
  }

  .styled-dropdown::before {
    .form-group--large & {
      width: 3rem;
      background-size: 60% auto;
    }
  }

  // exception to our pattern rule due to the preponderance of legacy instances of this
  .label-with-radio + .label-with-radio,
  .label-with-checkbox + .label-with-checkbox {
    margin-top: var(--rc-space-2);
  }
}

.form-group__tag {
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: var(--rc-color-background-tag);
  padding: 0.2em 0.4em;
  border-radius: var(--rc-border-radius-sm);
  margin-right: 0.5em;

  .label-with-checkbox & {
    display: inline-block;
  }
}

.form-group__help-text {
  font-size: 1.4rem;
  font-weight: normal;
  margin: var(--rc-space-1) var(--rc-space-0);
  color: var(--rc-color-text-secondary);
  line-height: 1.2;

  .form-group--large & {
    font-size: 1.6rem;
    color: var(--rc-color-text-secondary);
    margin-top: var(--rc-space-2);
    margin-bottom: var(--rc-space-5);
  }
}

.form-group__error {
  color: var(--rc-color-text-danger);
  font-size: 0.9em;
  font-weight: bold;

  .label-with-checkbox & {
    display: inline-block;
  }
}

.form-group__password-container {
  position: relative;

  input[type='password'],
  input[type='text'] {
    padding-right: var(--rc-space-8); // make room for the eyeball icon
  }
}

.form-group__password-container__toggle {
  @include button-reset;

  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: $input-padding-vertical;
  width: 3rem;
  color: var(--rc-color-text-secondary);
  opacity: 1;
  transition: opacity 0.2s;

  @include responsive-hover {
    opacity: 0.8;
  }

  .form-group__password-container--shown & {
    color: var(--rc-color-text-primary);
    opacity: 1;
  }
}
