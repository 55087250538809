%component-row {
  @include site-wrapper;
  @include scaling((margin-top margin-bottom), 3rem);

  .blog-post__frame & {
    margin-left: -15%;
    margin-right: -15%;
    padding: 0;
    min-width: 0;

    @include mobile {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }

  .content-frame & {
    padding-left: 0;
    padding-right: 0;
  }
}

%component-row-with-spacer {
  @extend %component-row;
  @include scaling(margin-bottom, 6rem);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: calc(50% - 7.5rem);
    height: 0.2rem;
    width: 15rem;
    background: var(--rc-color-border-primary);
    margin-top: 3rem;
  }

  @include mobile {
    margin-bottom: 1rem;

    &::before {
      display: none;
    }
  }
}

%component-row-heading {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 2rem;

  @include mobile {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
}
