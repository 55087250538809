.mobile-app-ad {
  background-color: var(--rc-color-palette-vu-orange-400);
  color: var(--rc-color-text-primary-invert-dark);
  border-radius: $input-border-radius;
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;

  @include mobile {
    display: block;
    padding: 2rem;
  }
}

.mobile-app-ad__illustration {
  display: block;
  width: 15rem;
  height: auto;
  transform: translateY(1rem);
  flex-shrink: 0;

  @include mobile {
    display: none;
  }
}

.mobile-app-ad__content {
  display: flex;
  align-items: center;
  margin-left: 3rem;

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  @include mobile {
    margin-left: 0;
  }
}

.mobile-app-ad__titleblock {
  margin-right: 3rem;
}

.mobile-app-ad__title {
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
}

.mobile-app-ad__subtitle {
  margin: 0;
  font-size: 1.8rem;
}

.mobile-app-ad__store-links {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @include tablet {
    margin-top: 2rem;
  }
}

.mobile-app-ad__store-link {
  display: block;
  height: 4rem;
  width: auto;

  &.mobile-app-ad__store-link--apple {
    margin-right: 2rem;

    @include mobile {
      margin-right: 1rem;
    }
  }
}
