@use 'sass:math';
@use 'sass:string';
@use 'sass:map';
@use 'sass:meta';

@use '../responsive';

// new config-based build system
// format of config:
//
// if tokenBase is defined, output will be CSS vars
// tokenBase: e.g. '--rc-space'
//
// if multiplier is defined, output will be values multiplied by this.
// multiplier: e.g. 0.1rem
//
// list of values to be appended to the classnames & multiplied / tokenized using the above
// valueList: e.g. (0, 1, half)
//
// use valuePairs if a class substring represents a numeric value to be multiplied
// or if the desired class substring ≠ the value
// valuePairs: e.g. (( half: 0.5 ))
//
// output rules for the css
// attributes: (
//   [classname segment]: [property]
// )
//
// scaleBy: [number] // multiplied against every numeric output value
// classPrefix: [string] // prefix every output class
// valuePrefix: [string] // prefix every output value

// if value is a nonzero number, use the multiplier on it
@function _multiply-value($val, $multiplier: null) {
  @if (meta.type-of($val) != number) { @return $val; }
  @if ($multiplier == null) { @return $val; }
  @if ($val == 0) { @return $val; }

  @return $val * $multiplier;
}

// conditionally return a css calc() function for the value
@function _calc($val, $scaleBy: null) {
  @if ($scaleBy == null) { @return $val; }
  @return calc(#{$val} * #{$scaleBy});
}

// returns a map with
// key of 'value' and value that's either been calculated or tokenized
// e.g.
// 2: 0.8rem
// or
// 2: var(--rc-spacing-2)

@function _get-processed-values($c) {
  $valueList: map.get($c, valueList);
  $tokenBase: map.get($c, tokenBase);
  $multiplier: map.get($c, multiplier);
  $valuePairs: map.get($c, valuePairs);

  $range: ();

  // if tokenBase, construct values based on the token names only
  @if ($tokenBase) {
    @each $i in $valueList {
      $range: map.merge($range, ($i: var(#{$tokenBase}#{$i})));
    }
  } @else {
    // otherwise output values themselves

    @each $i in $valueList {
      $range: map.merge($range, ($i: _multiply-value($i, $multiplier)));
    }
  }

  // always process valuePairs directly (without using tokenBase)
  @if ($valuePairs) {
    @each $k, $v in $valuePairs {
      $range: map.merge($range, ($k: _multiply-value($v, $multiplier)));
    }
  }

  @return $range;
}

@mixin build-classes($c, $overrides: ()) {
  $c: map.deep-merge($c, $overrides);

  $full-range: _get-processed-values($c);

  // e.g. attrLabel of 'bdr', attr of 'border-radius'
  @each $attrLabel, $attr in map.get($c, attributes) {

    // e.g. valueLabel of 'half', value of '0.2rem'
    @each $valueLabel, $value in $full-range {

      .#{map.get($c, classPrefix)}#{$attrLabel}-#{$valueLabel} {
        @each $a in $attr {

          // _calc here is used for generating the `scaling-` prefixed classes by
          #{$a}: #{map.get($c, valuePrefix)}#{_calc($value, map.get($c, scaleBy))};
        }
      }
    }
  }
}

@mixin build-responsive-classes($c, $overrides: ()) {
  @include build-classes($c, $overrides);
  @include responsive.tablet {
    @include build-classes($c, map.deep-merge($overrides, (classPrefix: 'tablet-')));
  }
  @include responsive.mobile {
    @include build-classes($c, map.deep-merge($overrides, (classPrefix: 'mobile-')));
  }
}
