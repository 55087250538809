.buy-it-now-express-pay-options {
  display: flex;
  justify-content: space-between;
  gap: var(--rc-space-2);
  flex-wrap: wrap;

  // Given that payment method availability is dynamic,
  // these selectors display the buttons depending on how many are visible
  // See https://alistapart.com/article/quantity-queries-for-css/ for more details
  //
  // stylelint-disable selector-max-universal
  div, button {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;

    &:nth-last-child(even):first-child,
    &:nth-last-child(even):first-child~* {
      flex-basis: calc(50% - var(--rc-space-2));
    }

    &:nth-last-child(3):first-child,
    &:nth-last-child(3):first-child~* {
      flex-basis: calc(33% - var(--rc-space-2));
    }
  }
  // stylelint-enable selector-max-universal

  // Modifies the button to avoid PayPal's unavoidable min-width restriction
  .express-paypal-button {
    display: flex;
    width: 100%;
    overflow: hidden;
    justify-content: center;
    border-radius: var(--rc-border-radius-button);
  }
}
