@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.anyone-can-sell-on-reverb-cta {
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: start;
  }
}

.anyone-can-sell-on-reverb-cta__content {
  display: flex;
  flex: 1;
  margin: 0 3rem;
  flex-direction: column;

  @include tablet {
    margin: 0 2rem;
  }

  @include mobile {
    margin: 0;
    margin-bottom: 2rem;
  }
}

.anyone-can-sell-on-reverb-cta__image {
  max-width: 25rem;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @include mobile {
    display: none;
  }

  @include tablet {
    max-width: 15rem;
  }
}

.anyone-can-sell-on-reverb-cta__title {
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1.8rem;

  @include tablet {
    font-size: 1.6rem;
  }
}

.anyone-can-sell-on-reverb-cta__copy {
  color: var(--rc-color-text-secondary);
  font-size: 1.6rem;

  @include tablet {
    font-size: 1.4rem;
  }
}

.anyone-can-sell-on-reverb-cta__button {
  display: flex;
  max-width: 20rem;
  margin-right: 2rem;
  align-items: center;

  a {
    @include rc-button.filled;
    width: 100%;
  }

  @include tablet {
    margin: 0;
  }

  @include mobile {
    max-width: unset;
    width: 100%;
  }
}
