.reusable-line-chart__header {
  margin-bottom: 2rem;
}

.reusable-line-chart__title {
  font-weight: 700;
}

.reusable-line-chart__totals {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.reusable-line-chart__total {
  padding: 0 2rem 1rem 0;
}

.reusable-line-chart__total__title {
  transition: color 0.25s;
  font-size: 1.4rem;

  @include mobile {
    font-size: 1.2rem;
  }

  &::before {
    content: '';
    margin-right: 1rem;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: var(--rc-color-palette-vu-orange-400);
  }

  .reusable-line-chart__total--secondary &::before {
    background-color: var(--rc-color-palette-blue-600);
  }
}

.reusable-line-chart__total__amount {
  font-size: 2rem;
  font-weight: 700;
  margin-right: 1rem;
  transition: color 0.25s;

  @include mobile {
    font-size: 1.6rem;
  }
}

.reusable-line-chart__container {
  position: relative;
}

.reusable-line-chart__empty-state {
  padding: 6rem 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.reusable-line-chart__empty-state__title {
  @include responsive(font-size, 2rem, 1.75rem);
  font-weight: 700;
  margin-bottom: 1rem;
}

.reusable-line-chart__empty-state__text {
  margin-bottom: 0;
}

.reusable-line-chart__chart {
  .reusable-line-chart--empty & {
    filter: blur(1rem);
  }
}

.reusable-line-chart__chart {
  .reusable-line-chart--loading & {
    @include loading-pulse;
    filter: blur(1rem);
  }
}
