.stat-block {
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-left: 0.1rem solid var(--rc-color-border-primary);

  @include mobile {
    padding-left: 0.5rem;
  }
}

.stat-block__titleblock {
  display: flex;
  align-items: center;
}

.stat-block__title {
  font-size: 1.2rem;
  text-transform: uppercase;
}

.stat-block__percent-change {
  margin-left: 0.5rem;
  font-size: 1.2rem;

  .stat-block--change-pos & {
    @include fa-icon-before(arrow-up, $margin-right: 0.25rem);
    color: var(--rc-color-sentiment-positive-dark);
  }

  .stat-block--change-neg & {
    @include fa-icon-before(arrow-down, $margin-right: 0.25rem);
    color: var(--rc-color-text-danger);
  }
}

.stat-block__stat {
  font-size: 2.4rem;

  @include mobile {
    font-size: 1.6rem;
  }

  .stat-block--orange & {
    color: var(--rc-color-palette-vu-orange-500);
  }

  .stat-block--green & {
    color: var(--rc-color-sentiment-positive-dark);
  }

  .stat-block--large & {
    font-size: 3.4rem;
  }
}

.stat-block__sub {
  margin-top: 0.25rem;
}

.stat-block__subtitle {
  font-size: 1.2rem;
  color: var(--rc-color-text-secondary);
}

.stat-block__substat {
  font-size: 1.4rem;
}
