@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.sell-card {
  @include shadow-on-hover;
  @include responsive(font-size, 1em, 0.95em, 0.9em);
  background: var(--rc-color-palette-bw-white);
  position: relative;
  transition: opacity .25s;
  width: 100%;
  opacity: 1;
  animation: fade-in .25s;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.sell-card__price-guide-recommendation {
  display: flex;
  gap: var(--rc-space-1);
  align-items: center;
  font-size: var(--rc-font-size-350);
}

.sell-card__price-guide-icon {
  width: 1.8rem;
}

.sell-card--loading {
  opacity: .75;
}

.sell-card--active {
  &:hover {
    outline: 0.1rem solid var(--rc-color-palette-vu-orange-500);
    box-shadow: 0 0 1.5rem orange;
  }
}

.sell-card__recommended {
  @include fa-icon-before(search);

  font-size: 0.75em;
  color: var(--rc-color-palette-vu-orange-500);
  font-weight: 700;
  text-align: center;
  margin-bottom: .5rem;
}

.sell-card__image {
  @include image-box;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);

  img {
    width: 100%;
  }
}

.sell-card__main {
  flex: 0 0 100%;
}

.sell-card__title {
  @include hyphenate;

  hyphens: auto;
  padding: 1rem;
  line-height: 1.2;
  color: var(--rc-color-text-primary);
  text-align: center;

  h2 {
    @include responsive(font-size, 1.75rem, $mobile: 1.4rem);
  }
}

.sell-card__finishes {
  color: var(--rc-color-text-secondary);
  text-align: center;
  font-size: 80%;
  margin-bottom: 1rem;
}

.sell-card__button {
  @include rc-button.filled($size: 'small');
  margin-top: var(--rc-space-2);

  .sell__product-grid & {
    @include mobile {
      @include rc-button.size('mini');
    }
  }
}

.sell-card__footer {
  padding: 0 1rem 1rem 1rem;
  align-self: flex-end;
  flex: 0 0 100%;
  box-sizing: border-box;
}
