@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.email-pop-up-modal {
  @include site-wrapper;
  display: flex;
  padding-left: 0;
  padding-right: 0;

  @include tablet {
    padding: 0;
    margin: 0;
  }
}

.email-pop-up-modal__image-container {
  width: 50%;
  flex: 1;
  min-height: 35rem;

  @include tablet {
    min-height: 25rem;
    width: 40%;
  }

  @include mobile {
    display: none;
  }
}

.email-pop-up-modal__image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('#{$commons-path}/images/email_pop_up/vintage-guitars-bass-synth-amp-cropped.jpeg');
}

.email-pop-up-modal__content {
  @include responsive((padding-left, padding-right), 6rem, 3rem, 2rem);
  background-image: url('#{$commons-path}/images/email_pop_up/pop-up-background-texture.jpg');
  width: 50%;
  flex: 1;
  padding: 2rem;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 1.7rem;

  @include tablet {
    width: 60%;
  }

  @include mobile {
    margin-right: 0;
    padding: 3rem 2rem;
    text-align: center;
    font-size: 1.4rem;
    justify-content: center;
  }

  .newsletter-signup__fineprint a {
    color: black;
    text-decoration: underline;
  }
}
