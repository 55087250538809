.sell-form-traits__additional-fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3rem;

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }

  @include mobile {
    display: block;
  }
}
