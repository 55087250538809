@use '../tools/mixins/wrapper';
@use '../tools/mixins/responsive';
@use '../tools/mixins/loading-pulse';

.rc-site-wrapper {
  @include wrapper.site-wrapper;

  &.rc-site-wrapper--narrow {
    @include wrapper.site-wrapper--narrow;
  }

  // protection against nested wrappers
  .site-wrapper &,
  .rc-site-wrapper &,
  &.rc-site-wrapper--inside-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  &.rc-site-wrapper--with-sidebar {
    position: relative;
    display: flex;
    gap: var(--rc-space-gap-grid);
    align-items: flex-start;

    @include tablet {
      display: block;
    }
  }
}

.rc-site-wrapper__main-column {
  flex: 1;

  .rc-site-wrapper--loading & {
    @include loading-pulse.loading-pulse;
  }
}

.rc-site-wrapper__sidebar {
  --sidebar-inner-width: 24rem;
  --scrollbar-padding: var(--rc-space-4);
  --width: calc(var(--sidebar-inner-width) + var(--scrollbar-padding));
  --gradient-color: var(--rc-color-background-page);

  flex: 0 0 var(--width);
  position: relative;

  &.rc-site-wrapper__sidebar--sticky {
    position: sticky;
    top: 0;
    overflow: hidden;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: var(--rc-space-4);
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.2s;
      z-index: 2;
    }

    &::before {
      top: -0.1rem;
      border-top: 0.1rem solid var(--rc-color-border-primary);
      background: linear-gradient(to top, var(--rc-color-transparent), var(--rc-color-shadow-200) 150%);
    }

    &::after {
      bottom: -0.1rem;
      border-bottom: 0.1rem solid var(--rc-color-border-primary);
      background: linear-gradient(to bottom, var(--rc-color-transparent), var(--rc-color-shadow-200) 150%);
    }

    &.rc-site-wrapper__sidebar--shadow-top {
      &::before {
        opacity: 1;
      }
    }

    &.rc-site-wrapper__sidebar--shadow-bottom {
      &::after {
        opacity: 1;
      }
    }
  }
}

.rc-site-wrapper__sidebar__content {
  .rc-site-wrapper__sidebar--sticky & {
    padding-right: var(--scrollbar-padding);
    max-height: 100vh;
    overflow-y: scroll;
    overscroll-behavior: contain;

    & > [data-observer] {
      height: 0.1rem;
    }
  }
}
