
@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.site-search {
  position: relative;
  border: 0.1rem solid var(--rc-color-border-input-primary);
  margin-top: 1.2rem;
  margin-right: 2rem;
  background: var(--rc-color-background-input);
  border-radius: var(--rc-border-radius-input);
  width: 100%;
  z-index: 2;

  &.site-search--active {
    @include box-shadow;
    transition: box-shadow .2s;
  }

  &.site-search--rounded {
    border-radius: var(--rc-border-radius-full);

    &.site-search--active:has(> .site-search__dropdown) {
      border-top-left-radius: var(--rc-border-radius-lg);
      border-top-right-radius: var(--rc-border-radius-lg);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }


  @include mobile {
    box-sizing: border-box;
    width: 100%;
    margin-top: 0;
  }

  // deep selector to override `form input[type='text']
  .site-search__controls__input[type='text'] {
    flex: 1;
    box-sizing: border-box;
    height: 3.6rem;
    padding: 1rem;
    border: none;
    transition: background .2s;
    background: var(--rc-color-background-input);

    // overrides of form input styles
    margin: 0;
    box-shadow: none;
    border-radius: 0;

    .site-search--rounded & {
      padding: 2rem;
      border-radius: inherit;
    }
  }
}

.site-search__underlay {
  position: relative;
  z-index: 1001;

  &::before {
    content: '';
    height: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--rc-color-shadow-600);
    transform: translateZ(0); // transition performance hack
    transition: opacity .25s;
  }

  &.site-search__underlay--active {
    &::before {
      z-index: 1;
      display: block;
      height: auto;
      opacity: 1;
    }
  }

  .site-search--active {
    .site-search__dropdown {
      box-shadow: none;
    }
  }
}

.site-search__controls {
  position: relative;
  border-radius: var(--rc-border-radius-input);
  overflow: hidden;
  display: flex;
  width: 100%;
  z-index: 3;

  .site-search--rounded & {
    border-radius: inherit;
  }
}

.site-search__controls__clear {
  margin: auto 0.5rem;
}

.site-search__controls__submit {
  @include rc-button.transparent($size: 'small');

  flex: 0 0 3.6rem;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: var(--rc-size-height-input);
  position: relative;
  appearance: none;
  border: none;
  border-left: 0.1rem solid var(--rc-color-border-input-primary);
  height: var(--rc-size-height-input);
  border-radius: var(--rc-border-radius-0);

  &::after {
    @include svg.icon-mask-image(search, var(--rc-color-text-primary), 2.4rem auto);

    content: '';
    position: absolute;
    inset: 0;
  }
}

.site-search__dropdown {
  position: absolute;
  top: calc(100% - 0.2rem);
  left: -0.1rem;
  right: -0.1rem;
  border: 0.1rem solid var(--rc-color-border-input-primary);
  background: var(--rc-color-background-module);
  z-index: 4;
  height: 0;
  overflow: hidden;
  opacity: 0;
  border-radius: 0 0 var(--rc-border-radius-input) var(--rc-border-radius-input);

  .site-search--active & {
    @include box-shadow;
    display: block;
    opacity: 1;
    height: auto;
  }

  .site-search--rounded & {
    border-radius: 0 0 var(--rc-border-radius-lg) var(--rc-border-radius-lg);
  }
}

.site-search__dropdown__section {
  position: relative;

  + .site-search__dropdown__section {
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: - 0.1rem;
      left: 1.5rem;
      right: 1.5rem;
      height: 0.1rem;
      background: var(--rc-color-palette-gray-200);
    }
  }

  &:empty {
    display: none;
  }
}

.site-search__dropdown__section__heading {
  color: var(--rc-color-text-secondary);
  font-weight: bold;
  text-transform: capitalize;
  font-size: 1.2rem;
  margin: 0 1.5rem;
  padding-top: 1.5rem;
  font-size: 1.2rem;
  line-height: 1;
}

.site-search__dropdown__section__heading__action {
  @include rc-button.transparent($size: 'mini');

  position: absolute;
  right: 0;
}

.site-search__dropdown__section__item {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  transition: background-color 0.2s;
  padding: 0.7rem 1.5rem;
  &:hover {
    background-color: var(--rc-color-background-module-hovered);
  }

  a {
    display: block;
    line-height: 1.1;
    color: var(--rc-color-text-primary);
    font-size: 1.4rem;
    font-weight: bold;
    flex: 1;

    b {
      font-weight: normal;
    }
  }

  small {
    white-space: nowrap;
    color: var(--rc-color-palette-vu-orange-500);
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 0.25em;
  }

  &.site-search__dropdown__section__item--selected {
    background: var(--rc-color-background-module-hovered);
  }

  &.site-search__dropdown__section__item--csp:first-child {
    padding-top: 0.5rem;
  }

  &.site-search__dropdown__section__item--ex {
    small {
      color: var(--rc-color-text-secondary);
      font-weight: normal;
    }
  }
}

.site-search__dropdown__section__tap-ahead {
  @include button-reset;
  @include svg.icon-mask-image(search-arrow, var(--rc-color-text-secondary), 1.4rem);
  width: 1.6rem;
  height: 1.6rem;

  &:focus-visible, &:hover {
    background-color: var(--rc-color-text-primary);
  }
}

.csp__suggestion {
  display: flex;
  align-items: center;
  line-height: 1.2;
  width: 100%;

  @include mobile {
    align-items: start;
  }
}

.csp__suggestion__image {
  flex: 0 0 4rem;
  border: 0.1rem solid var(--rc-color-border-primary);

  @include mobile {
    flex-basis: 3rem;
  }
}

.csp__suggestion__image__inner {
  @include image-box;
  background-color: var(--rc-color-palette-gray-200);
}

.csp__suggestion__content {
  margin-left: 0.5em;
}

.csp__suggestion__title {
  font-size: 1.4rem;
}

.csp__suggestion__prices {
  font-size: 0.9em;
  font-weight: normal;
  margin-top: 0.2em;

  @include mobile {
    font-size: 0.8em;
  }
}

.csp__suggestion__prices__price {
  color: var(--rc-color-palette-vu-orange-500);
}

.recent-searches__container {
  padding: 1rem 0;
}
