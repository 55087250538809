@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.csp-vertical-feature {
  @include responsive(width, 80%, 100%, 80%);
  @include responsive(font-size, 1em, 0.9em);

  display: flex;
  max-width: 70rem;
  align-items: center;
  justify-content: space-between;

  .tiles & {
    margin: $grid-gutter * 2 auto;
  }

  .tiles__tile + .tiles__tile & {
    margin-top: 0;
  }

  .tiles__tile:nth-child(2n) & {
    flex-direction: row-reverse;
  }

  @include mobile {
    display: block;
  }

  &.csp-vertical-feature--wide {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1000rem;

    @include tablet {
      width: 100%;
    }

    @include with-sidebar {
      width: 100%;
    }
  }
}

.csp-vertical-feature__image {
  flex: 0 0 48%;

  a {
    display: block;
    width: 100%;
    padding-bottom: 100%;
    background: var(--rc-color-palette-gray-200) center;
    background-size: contain;
  }

  .csp-vertical-feature--wide & {
    max-width: 30rem;
    margin: 0 auto;
  }

  @include tablet {
    flex-basis: 40%;
  }
}

.csp-vertical-feature__text {
  flex: 0 0 48%;

  @include tablet {
    flex-basis: 55%;
  }

  .csp-vertical-feature--wide & {
    flex: 1;
    padding-left: 5%;

    @include mobile {
      padding-left: 0;
    }

    .tiles__tile:nth-child(2n) & {
      padding-right: 2%;
      padding-left: 0;

      @include mobile {
        padding-right: 0;
      }
    }
  }
}

.csp-vertical-feature__title {
  @include responsive(font-size, 1.4em, 1.2em, 1.1em);

  line-height: 1.1;
  font-weight: bold;
  margin: 1em 0;

  &::after {
    content: '';
    display: block;
    width: 25%;
    background: var(--rc-color-palette-vu-orange-500);
    height: 0.3rem;
    margin: 0.7em 0;

    .tiles__tile:nth-child(2n) & {
      margin-right: 0;
    }

    @include mobile {
      margin-bottom: 0.5em 0;
    }
  }

  a {
    color: var(--rc-color-text-primary);

    @include responsive-hover {
      color: var(--rc-color-palette-vu-orange-500);
    }
  }
}

.csp-vertical-feature__description {
  margin: 1em 0;

  p {
    margin: 0;
  }
}

.csp-vertical-feature__footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include mobile {
    justify-content: space-between;
  }
}

.csp-vertical-feature__price {
  font-weight: bold;
  font-size: 0.9em;
  margin-right: 2rem;
}

.csp-vertical-feature__split-price {
  font-size: 0.9em;

  &:only-child,
  &:last-child {
    margin-bottom: 0;
    font-size: 1em;
  }
}

.csp-vertical-feature__shop-link {
  @include rc-button.filled;
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg.icon-after(angle-right, scss-tokens.$rc-color-palette-bw-white);

  font-size: 0.9em;
}
