.price-guide-table__header {
  @include scaling(padding, 2rem);

  background: white;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
  padding-bottom: 0;
  position: relative;
}

.price-guide-table__element {
  width: 100%;
  font-size: 0.8em;

  th,
  td {
    padding: 0.4em 0.8em;
    line-height: 1.1;

    &:first-child {
      padding-left: 0;
    }
  }

  th {
    padding-top: 0;
  }

  // blurs for logged out users
  .price-guide-table--blurred & {
    filter: blur(4px);
    opacity: 0.5;

    tr:nth-child(n + 6) {
      display: none;
    }
  }
}
