.user-quote {
  display: flex;

  + .user-quote {
    margin-top: 2rem;
  }
}

.user-quote__avatar {
  display: block;
  width: 4.2rem;
  flex: 0 0 4.2rem;

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
}

.user-quote__content {
  margin-left: 2rem;
}

.user-quote__text {
  margin-bottom: 1rem;
  line-height: 1.2;
}

.user-quote__author {
  margin: 0;
  line-height: 1.2;
}
