@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.shippable-order-row {
  margin-top: 2rem;
}

.shippable-order-row__inner {
  @include checkbox;

  > label {
    align-items: center;
  }
}

.shippable-order-row__thumbnail {
  width: 4rem;
  height: 4rem;
  border-radius: $input-border-radius;
  margin-right: 1rem;
}

.shippable-order-row__title {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0.4rem;
}

.shippable-order-row__link {
  margin-right: 1rem;
}

.shippable-order-row__expedited-tag {
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg.icon-before(fast-shipping-truck, scss-tokens.$rc-color-palette-vu-orange-500);
  display: inline-block;
  color: var(--rc-color-palette-vu-orange-500);

  &::before {
    margin-right: 0.3em;
  }
}
