.shipping-logo {
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  height: 4rem;

  &.shipping-logo--usps {
    background-image: url('#{$discovery-ui-path}/images/icons/shipping-icons/usps.svg');
    min-width: 17.2rem;
  }

  &.shipping-logo--ups {
    background-image: url('#{$discovery-ui-path}/images/icons/shipping-icons/ups.svg');
    min-width: 4rem;
  }

  &.shipping-logo--dhlexpress {
    background-image: url('#{$discovery-ui-path}/images/icons/shipping-icons/DHL_Express_logo_rgb.svg');
    min-width: 18rem;
  }

  &.shipping-logo--fedex {
    background-image: url('#{$discovery-ui-path}/images/icons/shipping-icons/fedex.svg');
    min-width: 11.6rem;
  }
}
