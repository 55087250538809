@use 'sass:math';
@use '@reverbdotcom/cadence/styles/tools/mixins/links';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.shop-headerv2 {
  margin-bottom: var(--rc-space-6);
  background: var(--rc-color-background-page);
  border-bottom: 0.1rem solid var(--rc-color-border-primary);

  &.shop-headerv2--with-banner {
    &::before {
      @include tablet {
        display: none;
      }
    }
  }
}

.shop-headerv2__banner {
  position: relative;
  width: 100%;
  overflow: hidden;

  @include tablet {
    margin: 0;
  }

  .shop-headerv2--placeholder & {
    &::after {
      content: '';
      padding-bottom: math.percentage(math.div(160, 960));
      background: var(--rc-color-palette-gray-100);
    }
  }
}

.shop-headerv2__banner::after,
.shop-headerv2__banner__main {
  display: block;
  width: 100%;
  margin: var(--rc-space-6) auto;
  max-width: 96rem;
  position: relative;
  border-radius: var(--rc-border-radius-md);

  @include tablet {
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
  }
}

.shop-headerv2__banner__blur,
.shop-headerv2__banner__bg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shop-headerv2__banner__blur {
  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(2rem);
}

.shop-headerv2__banner__bg {
  object-fit: cover;
}

.shop-headerv2__main {
  @include site-wrapper;

  padding-top: var(--rc-space-10);
  padding-bottom: var(--rc-space-10);
  display: flex;
  align-items: flex-start;
  gap: var(--rc-space-10);
  width: 100%;

  @include tablet {
    padding-top: var(--rc-space-6);
    padding-bottom: var(--rc-space-6);
    gap: var(--rc-space-6);
    flex-wrap: wrap;
  }

  @include mobile {
    display: block;
    padding: var(--rc-space-4) 0 0;
  }

  .shop-headerv2--placeholder & {
    @include loading-pulse;
  }

  .rc-button {
    flex-wrap: nowrap;
  }
}

.shop-headerv2__main__avatar {
  flex: 0 0 11rem;

  @include tablet {
    flex-basis: 9rem;
  }

  @include mobile {
    width: 11rem;
    margin: 0 auto var(--rc-space-4) auto;
  }

  &::after,
  img {
    border: 0.1rem solid var(--rc-color-border-primary);

    display: block;
    width: 100%;
    height: auto;
    border-radius: 50%;
    background: var(--rc-color-palette-bw-white);
    padding: 0.2rem;
    box-sizing: border-box;
  }

  .shop-headerv2--placeholder & {
    &::after {
      content: '';
      padding-bottom: 100%;
      background: var(--rc-color-palette-gray-100);
    }
  }
}

.shop-headerv2__main__text {
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-3);

  @include mobile {
    padding: var(--rc-space-4);
  }
}

.shop-headerv2__title {
  @include rc-text.title('900');
  display: flex;
  align-items: center;
  gap: var(--rc-space-3) var(--rc-space-8);
  flex-wrap: wrap;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--rc-space-4);
  }

  .shop-headerv2--placeholder & {
    background: var(--rc-color-palette-gray-100);
    height: 1em;
    width: 40%;
  }

  .favorite-shop-cta {
    display: flex;
    align-items: center;

    @include mobile {
      width: 100%;
    }
  }
}

.shop-headerv2__admin-link {
  margin-left: var(--rc-space-2);
}

.shop-headerv2__details {
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: var(--rc-space-4);
  }

  li {
    display: inline-block;

    + li {
      margin-left: var(--rc-space-2);
    }

    @include mobile {
      + li {
        margin: 0;
        width: 100%;
      }
    }
  }

  .rc-button {
    @include mobile {
      width: 100%;
    }
  }
}

.shop-headerv2__bio {
  max-width: 100ch;

  .button-as-link.size-80 {
    @include links.text-link;
    font-size: var(--rc-font-size-400); // overriding the "read more" button
  }
}

.shop-headerv2__main__badges {
  flex: 0 1 auto;
  padding-top: var(--rc-space-3);

  @include tablet {
    flex: 1 0 100%;
  }

  .shared-shop-badges {
    gap: var(--rc-space-3);
    margin: 0;

    @include tablet {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-top: 0.1rem solid var(--rc-color-border-primary);
      padding-top: var(--rc-space-6);
    }

    @include mobile {
      padding: var(--rc-space-gutter-page);
    }
  }

  .shared-shop-badge {
    color: var(--rc-color-text-primary);
  }
}

.shop-headerv2__free-shipping-alert {
  margin: 1rem 0;
}

.shop-headerV2__buyer-coupons {
  margin: 1rem 0;
}

.shop-headerv2__buyer-coupon-modal-trigger {
  @include button-as-link;
  @include text-link;
  margin-left: 1rem;
}

.shop-headerv2__buyer-coupon-callout {
  border-color: var(--rc-color-palette-yellow-600);
  background: var(--rc-color-palette-yellow-100);
  border-width: 0.2rem;
  border-radius: var(--rc-border-radius-md);
  border-style: dashed;
  margin: 1rem 0;
  padding: var(--rc-space-2);
}

.shop-headerv2__buyer-coupon-available {
  font-weight: bold;
}

.shop-headerv2__buyer-coupon-fine-print {
  font-size: 90%;
}
