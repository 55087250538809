@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/links';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

// TODO we may end up wanting to split out all these namespaces into their own specific .scss files

.favorites-page-layout__title {
  font-size: 2.4rem;
  line-height: 1.5;
  font-weight: bold;

  @include mobile {
    display: none;
  }
}

.favorites-header {
  border-bottom: solid 0.1rem var(--rc-color-border-primary);
  padding-top: 3rem;

  @include mobile {
    padding-top: 0.5rem;
  }
}

.favorites-content {
  @include scaling(padding-bottom, 6rem); // to compensate for the extra margin on the "mobile-app-ad" banner
}

.favorites-content__heading {
  font-size: 2.4rem;
  font-weight: 700;
  display: block;
  line-height: 1.25;
  padding-top: 3rem;
  padding-bottom: 2rem;
  font-weight: bold;

  &.favorites-content__heading--feed {
    font-size: 2rem;
    line-height: 1.5;
    padding-top: 4rem;
    padding-bottom: 0;
  }
}

.favorites-tabs {
  display: block;
}

.favorites-tabs__items {
  @include scroll-x-on-mobile;
  display: flex;
  gap: 2rem;
}

.favorites-tabs__item {
  display: block;
  flex-shrink: 0;
}

.favorites-tabs__link {
  display: inline-block;
  position: relative;
  font-size: 1.6rem;
  padding: 1rem 0;
  color: var(--rc-color-text-secondary);
  border-bottom: solid 0.4rem transparent;

  @include mobile {
    font-size: 1.4rem;
  }

  &:hover {
    border-bottom-color: var(--rc-color-palette-gray-300);
    color: var(--rc-color-text-primary);
  }

  &.favorites-tabs__link--active {
    color: var(--rc-color-text-primary);
    border-bottom-color: var(--rc-color-text-primary);
    font-weight: bold;
  }
}

.watchlist-container__empty__wrapper {
  width: 100%;
}

.favorites__mobile-only {
  display: none;

  @include mobile {
    display: block;
  }

  @include tablet {
    display: block;
  }
}

.favorites__desktop-only {
  display: block;

  @include mobile {
    display: none;
  }

  @include tablet {
    display: none;
  }
}

.favorites__mobile-only__inline {
  display: none;

  @include mobile {
    display: inline;
  }
}

.favorites__desktop-only__inline {
  display: inline;

  @include mobile {
    display: none;
  }
}

.favorite-button, .favorite-button__container {
  .favorite-button__button {
    &::before {
      margin-right: 0.25em;
    }

    font-size: 1.4rem;
    font-weight: bold;
    display: inline-block;
    vertical-align: bottom;

    &.favorite-button__button--link {
      @include button-as-link;
    }

    &.favorite-button__button--secondary {
      @include rc-button.outlined;

      @include mobile {
        @include rc-button.size('small');
      }
    }

    &.favorite-button__button--favorited {
      .rc-icon {
        color: var(--rc-color-palette-vu-orange-400);
      }
    }

    &.favorite-button__button--disabled {
      color: var(--rc-color-text-primary-disabled);

      &:hover {
        color: var(--rc-color-text-primary-disabled);
      }
    }

    .rc-icon {
      margin-right: 0.25em;
    }

    @include mobile {
      @include rc-button.size('small');
    }
  }
}

.favorite-modal__header {
  @include scaling(padding, 1em);
  font-size: 1.6em;
  text-align: center;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);

  @include mobile {
    font-size: 1.4em;
  }
}

.favorite-modal__shoppability-header {
  @include scaling(padding, 1em);

  h1 {
    font-size: 1.6em;
    font-weight: 600;
  }

  @include mobile {
    font-size: 1.4em;
  }
}

.favorite-modal__shoppability-header__experimental {
  padding-bottom: 0;
}

.favorite-modal__shoppability-subsection {
  @include scaling(padding, 1em);
  font-size: 1.2em;

  h1 {
    font-weight: 600;
  }

  @include mobile {
    font-size: 1em;
  }
}

.favorite-modal__shoppability-subsection-divider {
  @include scaling(padding-top, 0.5em);
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
}

.favorite-modal__shoppability-subsection-options {
  padding-bottom: 0;
}

.favorite-modal__shoppability-subsection-callouts {
  padding-top: 0;
}

.favorite-modal__header__follow-title {
  @include responsive(max-width, 80%, 80%, 100%);
  font-size: 1.3rem;
  display: block;
  margin: 0.5rem auto 0;
}

.favorite-modal__header__shoppability-follow-title {
  font-size: 1.3rem;
  display: block;
  margin-top: 0.5rem;
}

.favorite-modal__header__shoppability-follow-title__experimental {
  font-size: 1.5rem;
}

.favorite-modal__header__shoppability-text {
  font-size: 1.6rem;
  display: block;
  margin-top: 0.5rem;
}

.favorite-modal__header__shoppability-follow-callout {
  @include scaling(padding, 0.5em);

  background: var(--rc-color-palette-tweed-orange-400);
  border-radius: var(--rc-border-radius-lg) var(--rc-border-radius-sm) var(--rc-border-radius-lg) var(--rc-border-radius-sm);
  margin-top: 0.5rem;
  color: var(--rc-color-text-primary-invert-dark);

  @include mobile {
    @include scaling(padding, 1em);
  }
}

.favorite-modal__header__shoppability-follow-callout-title {
  @include scaling(padding-bottom, 0.2em);

  font-size: 1.3rem;
  font-weight: bold;

  .rc-icon {
    color: var(--rc-color-text-accent);
  }
}

.favorite-modal__header__shoppability-follow-callout-subtitle {
  font-size: 1.3rem;
}

.favorite-modal__header__shoppability-follow-disclaimer {
  @include scaling(margin-top, 1rem);
  @include scaling(margin-bottom, 0.5rem);

  color: var(--rc-color-text-secondary);
  display: block;
  font-size: 1rem;
}

.favorite-modal__option {
  @include scaling(padding-top, 2rem);
  padding-left: 1rem;
  padding-right: 1rem;

  .rc-switch {
    margin-bottom: 2rem;
  }
}

.favorite-modal__action {
  @include scaling((padding-top padding-bottom), 2rem);
  text-align: center;

  button {
    @include rc-button.filled;
  }
}

.favorite-modal__actions {
  @include scaling((padding-top padding-bottom), 2rem);

  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }
}

.favorite-modal__actions__experimental {
  flex-direction: row-reverse;
  justify-content: flex-start;

  .favorite-modal__save-and-cancel-buttons {
    flex-direction: row-reverse;
  }

  .favorite-modal__remove-button {
    @include scaling(padding-right, 0.5em);
  }
}

.favorite-modal__save-and-cancel-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  @include mobile {
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;

    .rc-button {
      width: 100%;
    }
  }
}

.favorites-toast__undo-button {
  @include links.text-link;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: bold;

  &:hover {
    color: var( --rc-color-palette-vu-orange-300);
  }
}

.favorites-callout__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 4rem;
}

.favorites-callout__header {
  @include rc-text.title('600');
  text-align: center;
}

.favorites-callout__description {
  @include rc-text.utility('500');

  margin-top: 1rem;
  text-align: center;
}

.favorites-feed-recommended-content__tooltip {
  text-align: center;
  padding-top: 0.75rem;
}

.favorites-callout__heart-animation {
  height: 5rem;
  padding: 0 0 1rem 0;
}
