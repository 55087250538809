@use '../tools/mixins/responsive' as breakpoint;
@use '../tools/mixins/box-shadow' as shadow;

.rc-favorite-card {
  --thumbnail-width: 8.8rem;
  --card-padding: var(--rc-space-4);
  --action-width: 3.2rem;
  --action-gap: 1rem;

  background-color: var(--rc-color-background-module);
  border: solid 0.1rem var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-md);
  box-sizing: border-box;
  position: relative;
  min-height: calc(var(--thumbnail-width) + (var(--card-padding) * 2));
  padding: var(--card-padding);
  display: flex;
  flex-direction: column;
  gap: var(--card-padding);

  // extra left padding for absolute-positioned thumbnail
  padding-left: calc(var(--thumbnail-width) + (var(--card-padding) * 2));

  // extra right padding for absolute-positioned action button(s)
  &.rc-favorite-card--single-action {
    padding-right: calc(var(--action-width) + (var(--card-padding) * 2));
  }

  &.rc-favorite-card--double-action {
    padding-right: calc((var(--action-width) * 2) + (var(--card-padding) * 2) + var(--action-gap));
  }

  &.rc-favorite-card--clickable {
    cursor: pointer;
    box-shadow: none;
    transition: box-shadow 0.1s ease;

    @include responsive-hover {
      @include shadow.box-shadow;
    }
  }

  &.rc-favorite-card--stretch {
    height: 100%;
  }

  @include breakpoint.tablet {
    --action-width: 3.2rem;

    // stylelint-disable-next-line declaration-no-important
    padding: 0 !important;
    min-height: auto;
    gap: var(--rc-space-0);
  }
}

.rc-favorite-card__primary-content {
  display: flex;
  align-items: flex-start;
  gap: var(--card-padding);
  flex-grow: 1;

  @include breakpoint.tablet {
    padding: var(--card-padding);
  }
}

.rc-favorite-card__thumbnail {
  position: absolute;
  width: var(--thumbnail-width);
  top: var(--card-padding);
  left: var(--card-padding);

  @include breakpoint.tablet {
    --thumbnail-width: 5.5rem;

    flex: 0 0 var(--thumbnail-width);
    position: static;
  }
}

.rc-favorite-card__thumbnail-link {
  display: block;
  width: 100%;
}

.rc-favorite-card__thumbnail-image {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: var(--rc-border-radius-sm)
}

.rc-favorite-card__titleblock {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-half);
  min-width: 1%; // to ensure word-break in __title works properly
}

.rc-favorite-card__title {
  color: var(--rc-color-text-primary);
  font-size: 1.6rem;
  font-weight: bold;
  display: block;
  white-space: normal;
  word-wrap: break-word;

  .rc-favorite-card--clickable & {
    @include responsive-hover {
      color: var(--rc-color-text-primary);
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
}

.rc-favorite-card__subtitle {
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
  display: block;
}

.rc-favorite-card__secondary-content {
  @include breakpoint.tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid 0.1rem var(--rc-color-border-primary);
    padding: var(--rc-space-2) var(--card-padding);
  }
}

.rc-favorite-card__additional {
  position: relative;

  .rc-favorite-card--search & {
    a {
      @include group-link;
      font-weight: bold;
      font-size: 1.3rem;
    }
  }
}

.rc-favorite-card__actions {
  display: flex;
  align-items: center;
  gap: var(--action-gap);
  position: absolute;
  top: var(--card-padding);
  right: var(--card-padding);

  .rc-favorite-card--clickable & {
    z-index: 2;
  }

  @include breakpoint.tablet {
    position: static;
  }
}

.rc-favorite-card__action {
  width: var(--action-width);

  .rc-button {
    --min-height: var(--action-width);
  }

  .rc-icon-button .rc-button {
    font-size: 1.6rem;
  }
}
