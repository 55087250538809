.rc-icon {
  --icon-size: 100%;

  display: flex;
  height: auto;
  width: var(--icon-size);
  align-items: center;

  &.rc-icon--inline {
    display: inline-block;
    height: 0.875em; // 14px default size
    width: auto;
    transform: translateY(0.0625em); // 1px nudge at default size

    svg {
      height: 100%;
      width: auto;
    }
  }

  &.rc-icon--native-size {
    display: inline-flex;
    height: auto;
    width: auto;

    svg {
      display: inline-block;
      width: auto;
      height: auto;
    }
  }

  svg {
    display: block;
    fill: currentColor;
    height: auto;
    width: 100%;
  }

  .rc-text-with-icon__icon & {
    height: 1em;
    transform: translateY(0.1em); // visually center icon
  }

  .rc-text-with-icon__icon--large & {
    transform: scale(1.25) translateY(0.1em); // scale and visually center icon
  }

  .rc-icon-button & {
    display: inline-block;
    height: 1em;
    width: auto;

    svg {
      height: 100%;
      width: auto;
    }
  }

  .rc-segmented-control__button--icon-only & {
    height: 2rem;
    width: auto;

    svg {
      height: 100%;
      width: auto;
    }
  }
}
