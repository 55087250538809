@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.form-section {
  padding: var(--rc-space-10) 0;
  border-bottom: solid 0.1rem var(--rc-color-border-primary);
  background-color: var(--rc-color-background-page);

  @include tablet {
    padding: var(--rc-space-6) 0;
  }

  &.form-section--rounded-top {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
  }

  &.form-section--rounded-bottom {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }

  &.form-section--complete {
    text-align: center;
  }

  &.form-section--slim {
    @include scaling((padding-top padding-bottom), 1.6rem);
  }

  &.form-section--actions {
    text-align: center;
  }
}

.form-section__wrapper {
  display: flex;

  .form-section--stacked & {
    display: block;
  }

  @include mobile {
    display: block;
  }
}

.form-section__info {
  flex-basis: 32%;
  flex-shrink: 0;
  overflow: hidden;
  margin-right: 6%;

  @include mobile {
    margin-right: 0;
  }

  &.form-section__info--muted {
    color: var(--rc-color-text-secondary);
  }
}

.form-section__title {
  @include rc-text.title('700');
  margin-bottom: var(--rc-space-2);
}

.form-section__description {
  color: var(--rc-color-text-secondary);
  margin-bottom: var(--rc-space-1);

  &:last-child {
    margin-bottom: 0;
  }

  &.form-section__description--highlight {
    color: var(--rc-color-palette-vu-orange-500);
  }
}

.form-section__content {
  flex-grow: 1;

  @include mobile {
    margin-top: 1.6rem;
  }

  // set new defaults to override legacy styles
  input,
  select {
    max-width: 100%;
  }
}

.form-section__primary-action {
  @include rc-button.filled;
  min-width: 16%;
  margin: 0 0.8rem;

  @include mobile {
    margin: 0;
    width: 100%;
  }

  // specificity override for legacy form styles
  form & {
    margin-bottom: 0;
  }
}
