.csp2-jumplinks__dropdown {
  display:none;
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
  border-top: 0.1rem solid var(--rc-color-border-primary);

  @include mobile {
    display: block;
  }

  .styled-dropdown {
    margin-bottom: 0;
    flex: 1;
  }
}

.csp2-jumplinks__dropdown__inner {
  @include site-wrapper;

  display: flex;
  align-items: center;
  justify-content: stretch;
}

.csp2-jumplinks__dropdown__title {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
}

.csp2-jumplinks--hide-mobile {
  @include mobile {
    display: none;
  }
}
