@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.csp-reviews {
  .dynamic-page--csp & {
    @include site-wrapper;
    @include scaling((margin-top margin-bottom), 5rem);

    padding-bottom: 1rem;
  }
}

.csp-reviews__inner {

  .dynamic-page--csp & {
    max-width: 93rem; // visually matches price history table at max screen width
  }
}

.csp-reviews__header {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;

  &:empty {
    display: none;
  }

  @include mobile {
    display: block;
  }

  .dynamic-page--csp & {
    @include scaling(margin-top, 3rem);
  }
}

.csp-reviews__header__title {
  .dynamic-page--csp & {
    @include rc-text.title('900');
    margin-right: 2rem;

    @include mobile {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
}

.csp-reviews__prompt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  gap: 1rem 2rem;

  .dynamic-page--csp & {
    margin-top: var(--rc-space-4);
  }
}

.csp-reviews__empty-state {
  @include scaling(padding, 4rem);
  background-color: var(--rc-color-background-page-secondary);
  text-align: center;
}
