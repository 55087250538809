.rc-radio-group {
  .rc-form-group & {
    margin-top: var(--rc-space-4); // to visually space the label away a little further
  }

  > .rc-radio,
  > .rc-radio-with-label,
  > .rc-radio-box {
    margin-bottom: var(--rc-space-4);

    &:last-child {
      margin-bottom: var(--rc-space-0);
    }
  }
}
