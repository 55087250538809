@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.bulk-sale-row {
  .bulk-sale-form__rows & {
    border-bottom: solid 0.1rem var(--rc-color-border-primary);
    padding: 2rem 0;
  }
}

.bulk-sale-row__inner {
  display: flex;
  flex-wrap: wrap;
}

.bulk-sale-row__info {
  flex: 1;
}

.bulk-sale-row__type {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--rc-color-text-secondary);
  margin-bottom: 0.5rem;
}

.bulk-sale-row__featured-tag {
  display: inline-block;
  background-color: var(--rc-color-palette-vu-orange-500);
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.2rem 0.6rem;
  border-radius: $input-border-radius;
  color: var(--rc-color-palette-bw-white);
  margin-right: 1em;
}

.bulk-sale-row__name {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.bulk-sale-row__sale-link {
  font-size: 1.4rem;
  margin-left: 0.5em;
}

.bulk-sale-row__details {
  font-size: 1.6rem;
}

.bulk-sale-row__detail {
  td:last-child {
    padding-left: 0.5em;
  }
}

.bulk-sale-row__notice {
  margin: 0.5rem 0 0;
  font-size: 1.4rem;

  &.bulk-sale-row__notice--error {
    color: var(--rc-color-text-danger);
  }

  &.bulk-sale-row__notice--warning {
    color: var(--rc-color-palette-vu-orange-500);
  }
}

.bulk-sale-row__status {
  &.bulk-sale-row__status--active {
    font-weight: bold;
    color: var(--rc-color-sentiment-positive-dark);
  }

  &.bulk-sale-row__status--upcoming {
    font-weight: bold;
    color: var(--rc-color-palette-vu-orange-500);
  }

  &.bulk-sale-row__status--ended {
    font-weight: bold;
    color: var(--rc-color-text-primary-disabled);
  }
}

.bulk-sale-row__actions {
  text-align: right;

  @include tablet {
    width: 100%;
    margin-top: 1rem;
  }
}

.bulk-sale-row__add {
  @include rc-button.filled;
}

.bulk-sale-row__remove {
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
  margin-top: 1rem;

  @include tablet {
    text-align: center;
  }
}

.bulk-sale-row__remove-text {
  margin-right: 0.5em;
}

.bulk-sale-row__affirm-text {
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
  margin-bottom: 1rem;
}

.bulk-sale-row__submit-message {
  font-size: 1.4rem;
  font-weight: 700;
  text-align: right;
  margin: 1rem 0 0;

  @include tablet {
    text-align: center;
  }

  &.bulk-sale-row__submit-message--success {
    @include fa-icon-before(check);
    color: var(--rc-color-sentiment-positive-dark);
  }

  &.bulk-sale-row__submit-message--failure {
    @include fa-icon-before(exclamation-triangle);
    color: var(--rc-color-text-danger);
  }
}

.bulk-sale-row__submit-submessage {
  display: block;
  color: var(--rc-color-text-secondary);
  font-weight: 400;
}
