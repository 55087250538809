.rc-modal-confirmation {
  position: relative;
  box-sizing: border-box;
  width: 48rem;
  max-width: 100%;
  max-height: 100%;
  margin: min(var(--rc-space-10), var(--rc-space-gutter-page));
  border-radius: var(--rc-border-radius-xl);
  background-color: var(--rc-color-background-module);
  border: 0.1rem solid var(--rc-color-border-primary);
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-6);
  padding: var(--rc-space-6);
  overflow: auto;

  @include mobile {
    padding: var(--rc-space-4);
  }
}

.rc-modal-confirmation__main {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-4);
  align-items: center;

  @include mobile {
    gap: var(--rc-space-2);
  }
}

.rc-modal-confirmation__icon {
  width: 6rem;

  @include mobile {
    width: 4rem;
  }

  &:empty {
    display: none;
  }

  &.rc-modal-confirmation__icon--danger {
    color: var(--rc-color-text-danger);
  }

  &.rc-modal-confirmation__icon--loud {
    color: var(--rc-color-text-accent);
  }
}

.rc-modal-confirmation__text {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-2);
  text-align: center;
  text-wrap: pretty;
}

.rc-modal-confirmation__content {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-2);
}
