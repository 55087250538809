@use '../tools/functions/grid';
@use '../tools/mixins/internal/class-prefix';

.g-container {
  @include grid-container;

  &.g-container--no-gutter {
    margin-left: 0;
    margin-right: 0;
  }

  &.g-container--flex {
    display: flex;

    &::after {
      display: none;
    }
  }
}

.g-col {
  margin-left: $grid-gutter * 0.5;
  margin-right: $grid-gutter * 0.5;
  box-sizing: border-box;

  // if the grid is flexed & has a gap value, don't do the usual built-in gutter
  .gap-gutter > & {
    margin: 0;
  }
}

@include class-prefix('g-col-') {
  float: left;
  margin-left: $grid-gutter * 0.5;
  margin-right: $grid-gutter * 0.5;
  box-sizing: border-box;
}

@for $i from 1 through $grid-count {

  .g-col-#{$i} {
    width: grid.column-width($i);
    flex-basis: grid.column-width($i);

    @if (12 % $i == 0) {
      .g-container--cycle > & {
        @include grid-cycle($i);
      }
    }
  }
}

@include tablet {
  @for $i from 1 through $grid-count {
    .g-col-tablet-#{$i} {
      width: grid.column-width($i);
      flex-basis: grid.column-width($i);

      @if (12 % $i == 0) {
        .g-container--cycle > & {
          @include grid-cycle($i);
        }
      }
    }
  }
}

@include mobile {
  @for $i from 1 through $grid-count {
    .g-col-mobile-#{$i} {
      width: grid.column-width($i);
      flex-basis: grid.column-width($i);

      @if (12 % $i == 0) {
        .g-container--cycle > & {
          @include grid-cycle($i);
        }
      }
    }
  }
}

.g-offset-0 {
  margin-left: 0;
}

@for $i from 1 through $grid-count {
  .g-offset-#{$i} {
    @include grid-offset($i);
  }
}

@include tablet {
  @for $i from 1 through $grid-count {
    .g-offset-tablet-#{$i} {
      @include grid-offset($i);
    }
  }

  .g-col-tablet-12,
  .g-offset-tablet-0 {
    margin-left: $grid-gutter * 0.5;
  }
}


@include mobile {
  @for $i from 1 through $grid-count {
    .g-offset-mobile-#{$i} {
      @include grid-offset($i);
    }
  }

  .g-col-mobile-12,
  .g-offset-mobile-0 {
    margin-left: $grid-gutter * 0.5;
  }
}
