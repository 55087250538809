@use '@reverbdotcom/cadence/styles/tools/mixins/svg';

.secondary-nag-banner {
  background-color: var(--rc-color-background-box-gray-muted);

  .rc-close-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.secondary-nag-banner__inner {
  @include site-wrapper;
}

.secondary-nag-banner__text {
  text-align: center;
  position: relative;
  padding: 1rem 3rem;

  @include tablet {
    text-align: left;
    padding-left: 0;
    font-size: 85%;
  }
}
.secondary-nag-banner__link {
  @include svg.icon-after(angle-right, $as-link: true);
  display: inline-block;

  &::after {
    margin-left: 0.1em;
  }
}
