.csp-layout__resources {
  display: flex;
  gap: calc(2 * var(--rc-space-gap-grid)) var(--rc-space-gap-grid);
  margin-top: 3rem;

  .overflowing-row {
    margin-bottom: 0;
  }

  @include tablet {
    flex-direction: column;
  }

  &:empty {
    display: none;
  }
}

.csp-layout__buying-guide {
  flex: 0 1 36rem;

  @include tablet {
    flex: 1;
  }

  &:empty {
    display: none;
  }
}

.csp-layout__no-listing {
  margin-top: var(--rc-space-gutter-page);
}
