@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.browse-page {
  padding-top: var(--rc-space-3);
  padding-bottom: var(--rc-space-3);
}

.browse-page__title {
  @include rc-text.display;

  margin-bottom: 2.4rem;

  @include tablet {
    &.browse-page__title--tablet-h2 {
      @include rc-text.title('900');
    }
  }

  &.browse-page__title--h2 {
    @include rc-text.title('900');
  }
}

.browse-page__results {
  @include rc-text.title('700');

  font-weight: 600;
}

.browse-page__loading-text {
  @include loading-pulse;
  background-color: var(--rc-color-background-loading-placeholder);
  width: 8em;
  height: 1em;
  border-radius: var(--rc-border-radius-sm);
}

.browse-page__empty-state {
  border: 0.1rem solid var(--rc-color-border-primary);

  border-radius: 0.8rem;
  margin: 3rem 0;
  padding: 3rem;
  background-color: var(--rc-color-palette-bw-white);
  text-align: center;
}
