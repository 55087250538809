@use '@reverbdotcom/cadence/styles/tools/mixins/svg';

.item2-affirm__logo {
  height: 1.6rem;
  width: 4rem;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('#{$discovery-ui-path}/images/payment-icons/affirm.svg');

  &.item2-affirm__logo--modal {
    width: 5.5rem;
    height: 2.2rem;
  }
}

.item2-affirm__benefits-icon {
  display: block;
  height: 3rem;
  min-width: 3rem;
  margin-right: 2rem;

  &.item2-affirm__benefits-icon--watch {
    @include svg.icon-bg(wristwatch);
  }

  &.item2-affirm__benefits-icon--money {
    @include svg.icon-bg(bucks);
  }
}
