.percentage-bar {
  display: block;
  width: 100%;
  height: 1rem;
  background-color: var(--rc-color-background-progressbar-track);
  border-radius: 0.5rem;
  position: relative;
  overflow-x: hidden;
}

.percentage-bar__progress {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0.5rem;
  min-width: 1rem;
  max-width: 100%;
  background-color: var(--rc-color-background-progressbar-indicator-primary);

  .percentage-bar--green & {
    background-color: var(--rc-color-background-progressbar-indicator-success);
  }

  .percentage-bar--light-gray & {
    background-color: var(--rc-color-background-progressbar-indicator-muted);
  }

  .percentage-bar--dark-gray & {
    background-color: var(--rc-color-background-progressbar-indicator-primary);
  }

  .percentage-bar--animated & {
    transition: width 0.75s ease;
    animation: 1s ease 0s 1 slideInFromLeft;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
