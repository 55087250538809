.amp-tag {
  display: block;
  padding: 0 0.5rem;
  font-size: 1.4rem;
  color: var(--rc-color-text-primary);
  background-color: var(--rc-color-highlight-600);

  &.amp-tag--small {
    font-size: 1.2rem;
  }

  &.amp-tag--inline {
    display: inline;
  }
}
