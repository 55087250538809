// This is the deprecated css-only tooltip styles
// Please use the Tooltip react component instead

[data-tooltip-text] {
  position: relative;

  &::after {
    opacity: 0;
    position: absolute;
    bottom: 100%;
    margin-bottom: 0.5em;
    content: attr(data-tooltip-text);
    background-color: var(--rc-color-shadow-700);
    color: var(--rc-color-palette-bw-white);
    border-radius: 0.4rem;
    padding: 0.4em 0.6em;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.2rem;
    z-index: 1000;
    width: 15rem;
    white-space: normal;
    line-height: 1.2;
    visibility: hidden;
    transition: opacity 0.2s;
    text-align: center;
    font-weight: bold;
  }

  &:hover::after {
    visibility: visible;
    opacity: 1;
  }

  @include mobile {
    &::after {
      display: none;
    }
  }
}

[data-tooltip-align='left'] {
  &::after {
    transform: none;
    left: 0;
  }
}

[data-tooltip-align='right'] {
  &::after {
    transform: none;
    left: auto;
    right: 0;
  }
}

[data-tooltip-whitespace='pre'] {
  &::after {
    white-space: pre-wrap;
  }
}
