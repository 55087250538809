$grid-column-max: 12;
$grid-row-max: 4;

@mixin _build-grid-cols($prefix: '') {
  @for $i from 1 through $grid-column-max {
    .#{$prefix}grid-cols-#{$i} {
      grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
    }

    .#{$prefix}grid-col-span-#{$i} {
      grid-column: span #{$i} / span #{$i};
    }
  }
}

@mixin _build-grid-rows($prefix: '') {
  @for $i from 1 through $grid-row-max {
    .#{$prefix}grid-rows-#{$i} {
      grid-template-rows: repeat(#{$i}, minmax(0, 1fr));
    }

    .#{$prefix}grid-row-span-#{$i} {
      grid-row: span #{$i} / span #{$i};
    }
  }
}

@include _build-grid-cols();
@include _build-grid-rows();

@include tablet {
  @include _build-grid-cols('tablet-');
  @include _build-grid-rows('tablet-');
}

@include mobile {
  @include _build-grid-cols('mobile-');
  @include _build-grid-rows('mobile-');
}
