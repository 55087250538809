.rc-radio-card-group {
  .rc-form-group & {
    margin-top: var(--rc-space-2); // to visually space the label away a little further
  }

  .rc-radio-card + .rc-radio-card {
    margin-top: var(--rc-space-2);
  }
}

.rc-radio-card {
  --radio-card-padding: var(--rc-space-5);
  border-style: solid;
  border-width: 0.2rem;
  border-color: var(--rc-color-border-selectable);
  border-radius: var(--rc-border-radius-input);
  background-color: var(--rc-color-background-module);

  &.rc-radio-card--checked {
    border-color: var(--rc-color-border-selected);
  }

  &.rc-radio-card--disabled {
    background-color: var(--rc-color-background-input-disabled);
  }
}

.rc-radio-card__main {
  padding: var(--radio-card-padding);
}

.rc-radio-card__content {
  position: relative;
  padding: var(--radio-card-padding);
  padding-left: var(--rc-space-12);
  background-color: var(--rc-color-background-module-secondary);
  border-top: 0.1rem solid var(--rc-color-border-primary);
  border-bottom-left-radius: calc(var(--rc-border-radius-input) - 0.1rem);
  border-bottom-right-radius: calc(var(--rc-border-radius-input) - 0.1rem);
}
