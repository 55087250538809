// copied from core, needs refactor

.img-uploader {
  position: relative;
}

.img-uploader__thumbs {
  @include clearfix;

  margin: 0 -1.5%;
  user-select: none;
}

.img-uploader__thumb {
  box-sizing: border-box;
  position: relative;
  float: left;
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: 0.4rem;
  margin: 0 1.5% 3% 1.5%;
  background-color: var(--rc-color-palette-gray-200);
  cursor: move;
  overflow: hidden;
  width: 17%;

  @include mobile {
    width: 30.33%;
  }

  &.img-uploader__thumb--with-warning {
    &::after {
      @include fa-icon(exclamation-triangle);

      position: absolute;
      color: var(--rc-color-palette-vu-orange-500);
      padding: 0.5rem;
      background: white;
      top: 0;
      right: 0;
    }
  }

  &.img-uploader__thumb--add-photo {
    background: none;
    border: none;

    input[type='file'] {
      display: none;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  &.img-uploader__thumb--add-photo--active {
    input[type='file'] {
      display: block;
    }
  }
}

.img-uploader__thumb__message {
  box-sizing: border-box;
  border: 0.4rem dashed var(--rc-color-palette-blue-600);
  width: 100%;
  height: 0;
  color: var(--rc-color-palette-blue-600);
  position: relative;
  transition: border-color .2s;

  // square image plus height of controls plus border
  padding-bottom: calc(100% + #{2.4rem} - #{0.8rem});

  label {
    color: var(--rc-color-palette-blue-600);
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    transition: color .2s;
    line-height: 1.1;

    &::before {
      @include fa-icon(spinner);
      font-size: 2.5rem;
      display: block;
      animation: fa-spin 1.5s infinite linear;
    }
  }

  .img-uploader__thumb--add-photo--active & {
    label {
      &::before {
        content: fa-icon-content(plus);
        animation: none;
      }
    }
  }

  .img-uploader__thumb--add-photo--active:hover & {
    border-color: var(--rc-color-palette-vu-orange-500);

    label {
      color: var(--rc-color-palette-vu-orange-500);
    }
  }
}

.img-uploader__thumb__image {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.img-uploader__thumb__image__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.img-uploader__tip {
  text-align: center;
  margin-bottom: 1em;
  opacity: 0.6;
}


.img-uploader__drop-file-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  opacity: 0.9;
  font-size: 2rem;
}

.img-uploader__actions {
  @include clearfix;

  border-top: 0.1rem solid var(--rc-color-border-primary);
  background-color: var(--rc-color-palette-bw-white);
}

.img-uploader__actions__action {
  display: block;
  font-weight: bold;
  padding: 0.3rem 0.6rem;
  font-size: 1.3rem;

  &.img-uploader__actions__action--edit {
    float: left;
  }

  &.img-uploader__actions__action--remove {
    float: right;
    border-left: 0.1rem solid var(--rc-color-border-primary);

    &::before {
      @include fa-icon(times);
    }
  }
}

.img-uploader__message__close {
  @include button-reset;
  float: right;
  margin-left: 0.5rem;
  color: inherit;

  &::before {
    @include fa-icon(times);
  }
}
