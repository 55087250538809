.bar-graph-parent, .line-graph-parent {
  position: relative;

  .bar-graph, .line-graph {
    flex: 1;
    min-height: 14rem;
    padding: 1rem;
    box-sizing: border-box;

    svg.ct-chart-line {
      overflow: visible;
    }

    .ct-series-a {
      .ct-area {
        fill: var(--rc-color-sentiment-positive-dark);
      }

      .ct-line {
        stroke: var(--rc-color-sentiment-positive-dark);
        stroke-width: 0.3rem;
      }

      .ct-point {
        stroke: var(--rc-color-sentiment-positive-dark);
        stroke-width: 1.2rem;
      }

      .ct-bar {
        stroke: var(--rc-color-sentiment-positive-dark);
      }
    }

    // label rotations for spacing
    &.line-graph__label--30 {
      .line-graph__label {
        &.line-graph__horizontal {
          transform: rotate(30deg);

          @include mobile {
            transform: rotate(45deg);
          }
        }
      }
    }

    &.line-graph__label--45 {
      .line-graph__label {
        &.line-graph__horizontal {
          transform: rotate(45deg);

          @include mobile {
            transform: rotate(90deg);
          }
        }
      }
    }

    &.line-graph__label--90 {
      .line-graph__label {
        &.line-graph__horizontal {
          transform: rotate(90deg);

          @include mobile {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .bar-graph--tooltip, .line-graph--tooltip {
    border: 0.1rem solid var(--rc-color-border-primary);

    display: flex;
    flex-direction: column;
    justify-content: 'space-between';
    position: absolute;
    padding: 1rem;
    opacity: 1;
    z-index: 1;
    background: var(--rc-color-palette-bw-white);
    border-radius: $input-border-radius;
  }

  .bar-graph--tooltip__hidden, .line-graph--tooltip__hidden {
    display: none;
  }

  .bar-graph__label, .line-graph__label {
    color: var(--rc-color-text-secondary);
    display: flex;
    width: auto;
    font-size: 1rem;
    line-height: 1;
    text-align: right;
    align-items: center;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    transform-origin: left;

    &.line-graph__vertical {
      transform: translateY(50%);
      justify-content: flex-end;
    }

    &.bar-graph__vertical {
      transform: translateY(50%);
      justify-content: flex-end;
    }
  }

  .bar-graph__label {
    justify-content: center;
  }
}
