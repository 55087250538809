.sell-preview {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.sell-preview__label {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.sell-preview__image {
  width: 14rem;
  margin-right: 2rem;
  flex-shrink: 0;

  @include mobile {
    width: 6rem;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.sell-preview__make-model {
  margin-bottom: auto;
  flex: 1;
}

.sell-preview__attributes {
  padding-top: 1rem;
}

.sell-preview__info {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  h1 {
    font-size: 2.6rem;
    color: var(--rc-color-text-secondary);
    line-height: 1.2;

    @include mobile {
      font-size: 1.6rem;
    }
  }

  h2 {
    font-size: 2.6rem;
    line-height: 1.2;
    font-weight: 700;

    @include mobile {
      font-size: 1.8rem;
    }
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--rc-color-text-secondary);

    @include mobile {
      font-size: 1.25rem;
    }
  }
}

.sell-preview__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include mobile {
    display: block;
  }
}

.sell-preview__edit {
  @include button-as-link;
  @include fa-icon-before(pencil);
  margin-left: 1rem;

  @include mobile {
    margin-left: 0;
    margin-top: 1rem;
  }
}
