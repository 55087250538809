// Temporary stylesheet for handling non-RC implementations of .alert-box.
// Will be deprecated in a followup PR.

.alert-box {
  border-radius: var(--rc-border-radius-sm);
  border-width: 0.1rem;
  border-style: solid;
  position: relative;

  :last-child {
    margin-bottom: 0;
  }

  .rc-close-button {
    position: absolute;
    top: var(--rc-space-1);
    right: var(--rc-space-1);
  }

  // these default, blue, red, green, orange, and gray modifiers are deprecated but
  // left in place to support instances in core where alert-box class names are hard coded
  &,
  &.alert-box--warning {
    background-color: var(--rc-color-background-alert-warning);
    border-color: var(--rc-color-border-alert-warning);
    color: var(--rc-color-palette-gray-900);
  }

  &.alert-box--info,
  &.alert-box--blue {
    background-color: var(--rc-color-background-alert-info);
    border-color: var(--rc-color-border-alert-info);
    color: var(--rc-color-text-primary);
  }

  &.alert-box--error,
  &.alert-box--red {
    background-color: var(--rc-color-background-alert-error);
    border-color: var(--rc-color-border-alert-error);
    color: var(--rc-color-text-primary);
  }

  &.alert-box--success,
  &.alert-box--green {
    background-color: var(--rc-color-background-alert-success);
    border-color: var(--rc-color-border-alert-success);
    color: var(--rc-color-text-primary);
  }

  &.alert-box--highlight,
  &.alert-box--orange {
    background-color: var(--rc-color-background-alert-highlight);
    border-color: var(--rc-color-border-alert-highlight);
    color: var(--rc-color-text-primary);
  }

  &.alert-box--plain,
  &.alert-box--gray {
    background-color: var(--rc-color-background-alert-plain);
    border-color: var(--rc-color-border-primary);
    color: var(--rc-color-text-primary);
  }

  &.alert-box--inline {
    display: inline-block;
  }
}

.alert-box__header {
  background-color: var(--rc-color-background-page);
  padding: var(--rc-space-5);
  border-top-left-radius: calc(var(--rc-border-radius-sm) - 0.1rem);
  border-top-right-radius: calc(var(--rc-border-radius-sm) - 0.1rem);

  // the order of these modifier selectors matters here, edit with caution
  .alert-box--small & {
    padding: var(--rc-space-2);
    font-size: 0.875em; // sets the default rendered size from 16px to 14px
  }

  .alert-box--dismissible & {
    padding-right: var(--rc-space-10);
  }

  .alert-box--unpadded & {
    padding: var(--rc-space-0);
  }
}

.alert-box__header-text {
  font-size: 2.2rem;

  .alert-box--small & {
    font-size: 1.8rem;
  }
}

.alert-box__content {
  padding: var(--rc-space-5);

  .alert-box--dismissible:not(.alert-box--with-header) & {
    padding-right: var(--rc-space-10);
  }

  .alert-box--small & {
    padding: var(--rc-space-2);
    font-size: 0.875em; // sets the default rendered size from 16px to 14px
  }

  .alert-box--unpadded & {
    padding: var(--rc-space-0);
  }
}
