@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.app-install-callout {
  @include box-shadow;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: var(--rc-space-gutter-page);
  // uses the default padding value or the browser safe area, whichever is larger
  padding-bottom: max(var(--rc-space-gutter-page), var(--rc-space-inset-browser-safe-area-bottom));
  background: var(--rc-color-background-module);
  z-index: 1000;
}

.app-install-callout--open {
  @include tablet {
    display: block;
  }

  .in-mobile-app & {
    display: none;
  }
}

.app-install-callout__close {
  @include button-reset;
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg.icon-bg(close, scss-tokens.$rc-color-palette-gray-600, scss-tokens.$rc-color-palette-gray-900);

  position: absolute;
  right: 1rem;
  top: 1rem;
  height: 2rem;
  width: 2rem;
}
