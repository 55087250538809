@use '../tools/mixins/truncate-text';

.rc-nudge {
  display: flex;
  align-items: flex-start;
  gap: var(--rc-space-2);
  color: var(--rc-color-text-primary);
}

.rc-nudge__icon {
  width: 2rem;
  flex-shrink: 0;

  img {
    width: 100%;
  }
}

.rc-nudge__icon__label {
  @include truncate-text.truncate-text(2);
  font-size: 1.4rem;
  font-weight: var(--rc-font-weight-medium);
  word-break: break-word;
  flex-grow: 1;

  .rc-nudge--hidden-label & {
    display: none;
  }
}
