
.marketplace-filter {

  &.marketplace-filter--mobile-hidden {
    @include mobile {
      display: none;
    }
  }

  &.marketplace-filter--last-on-mobile {
    @include mobile {
      order: 3;
    }
  }
}

.marketplace-filter__list-view-toggle {
  order: 2;
  display: none;

  @include mobile {
    display: inline;
  }
}
