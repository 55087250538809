.collections-promo-banner {
  box-sizing: border-box;
  padding: 2rem;
  background-color: var(--rc-color-background-alert-success);
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: $input-border-radius;
  display: flex;
  margin-bottom: 2rem;

  @include mobile {
    display: block;
  }
}

.collections-promo-banner__img_container {
  flex: 0 0 15rem;

  @include mobile {
    width: 100%;
  }
}

.collections-promo-banner__img {
  width: 100%;
  height: auto;
  display: block;
}

.collections-promo-banner__text_container {
  padding-left: 3rem;
  flex: 1;

  @include mobile {
    padding-left: 0;
    margin-top: 2rem;
  }
}

.collections-promo-banner__header {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 1em;
}

.collections-promo-banner__body {
  font-size: 1.2em;
  margin-top: 1em;
  max-width: 35em;
  width: 100%;
}

.collections-promo-banner__link {
  color: black;
  text-decoration: underline;
}
