.skip-to-main {
  a {
    position: fixed;
    top: -10rem; // hidden off screen
    left: 2rem;
    z-index: 1001; // site-header + 1
    transition: top 0.25s ease;

    // We want to rely on the core "focus" state for this one
    // stylelint-disable-next-line selector-pseudo-class-disallowed-list
    &:focus {
      top: 2rem;
    }
  }
}
