// fotorama thumbnails overlay on top of Bump slideout and header, this fixes it
.fotorama__nav.fotorama__nav--thumbs                      { z-index: 0; }

// 0
#infinite-pagination-container                            { z-index: 1; }
.btn-follow                                               { z-index: 1; }
.progress-bar                                             { z-index: 0; }
.progress-bar .step                                       { z-index: 1; }
.disabled-overlay:before, .disabled-overlay::before       { z-index: 1; }
.hero:before                                              { z-index: 1; }
.hero .content-frame                                      { z-index: 1; }
.hero + div                                               { z-index: 1; }
.blog-index .content-frame                                { z-index: 1; }
.blog-navigation .fa-search                               { z-index: 1; }
.blog-navigation .search-cancel                           { z-index: 1; }
.activate-search ul                                       { z-index: 1; }
.mobile-dropdown-menu                                     { z-index: 1; }
.ribbon                                                   { z-index: 1; }
.products.show .ribbon                                    { z-index: 1; }
.bump-hint                                                { z-index: 1; }
.digital-listing-page #gallery                            { z-index: 1; }
.products.show .fotorama__stage__frame.fotorama__active   { z-index: 1; } // to prevent playing youtube video from overlapping header wrapper
.bundle-product-search-dropdown                           { z-index: 1; }

#bump-container                                           { z-index: 2; }
.blog-header.active                                       { z-index: 2; }
.mobile-dropdown-menu                                     { z-index: 2; }
.processing .product:before, .processing .product::before { z-index: 2; }
.btn-gear-alert                                           { z-index: 2; }
.panel-gear-alert                                         { z-index: 2; }
.follow                                                   { z-index: 2; }
.categories-bar-fade                                      { z-index: 2; }
.admin-static-edit                                        { z-index: 2; }
.panel-popout                                             { z-index: 2; }
%overlay                                                  { z-index: 2; }
.dropdown-module .dropdown-panel                          { z-index: 2; }
.loading-component-container                              { z-index: 3; }
.blog-navigation                                          { z-index: 3; }
.site-header                                              { z-index: 1000; }

// 1000
.fancybox-overlay                                         { z-index: 1003; }
.fancybox-opened                                          { z-index: 1004; }
#powerTip                                                 { z-index: 1005; }
.dropdown .dropdown-menu                                  { z-index: 1004; }
.site-header__menu                                        { z-index: 1004; }
.header-admin                                             { z-index: 1005; }
.loading-bar                                              { z-index: 2000; }

// 99900
.ac_results                                               { z-index: 99998; }
.flash-messages                                           { z-index: 99999; }

// nuclear - to go on top of zopim
.bottom-alert                                             { z-index: 99000000; }
