@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.price-with-shipping {
  .featured-listing-card & {
    font-size: var(--rc-font-size-700);
  }
}

.price-with-shipping__price__amount {
  font-size: var(--rc-font-size-700);
  font-weight: bold;
  line-height: 1.1;

  &.price-with-shipping__price__amount--sale {
    color: var(--rc-color-text-price-nudge);
  }

  .grid-card & {
    font-size: 1.1em;
  }
}

.price-with-shipping__price__muted {
  color: var(--rc-color-text-secondary);
}

.price-with-shipping__price__original {
  display: inline-block;
  text-decoration: line-through;
  font-size: var(--rc-font-size-400);
  color: var(--rc-color-text-secondary);

  .jumplinks & {
    display: none;
  }

  .grid-card & {
    font-size: 0.9em;
  }

  &.price-with-shipping__price__original--redesign {
    font-size: 1em;
  }
}

.price-with-shipping__price__original-description {
  margin-left: 0.8rem;
  display: inline-block;
  font-size: 1em;
  color: var(--rc-color-text-secondary);
}

.price-with-shipping__included-tax {
  font-size: 1.3rem;
  opacity: 0.7;

  .item2 & {
    font-weight: bold;
    font-size: 1.4rem;
  }
}

.price-with-shipping__accepts-offers {
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg.icon-before(check, scss-tokens.$rc-color-sentiment-positive-dark);

  font-size: 1.3rem;
  color: var(--rc-color-sentiment-positive-dark);
  white-space: nowrap;
}

.price-with-shipping__discount {
  &:empty {
    display: none;
  }

  .grid-card & {
    display: none;
  }

  .item2-pricing & {
    display: inline-block;
    margin-left: 0.3em;

    &:empty {
      display: none;
    }
  }
}
