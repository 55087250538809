@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.csp2-header {
  position: relative;
  background-color: var(--rc-color-background-page);
}

.csp2-header__breadcrumbs {
  @include site-wrapper;

  padding-top: 1.6rem;
}

.csp2-header__inner {
  @include site-wrapper;

  padding-bottom: 3rem;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  z-index: 2;

  @include mobile {
    display: block;
    padding-top: var(--rc-space-gutter-page);
    padding-bottom: var(--rc-space-gutter-page);
  }
}

.csp2-header__listing-sold {
  @include scaling((padding-top padding-bottom), 2rem);

  background-color: var(--rc-color-palette-gray-900);
}

.csp2-header__listing-sold__inner {
  @include site-wrapper;

  display: flex;
  align-items: center;
}

.csp2-header__listing-sold__image {
  flex: 1;
  max-width: 6rem;

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 0.3rem;
  }

  @include mobile {
    order: 2;
    margin-left: 0;
    margin-right: 2rem;
    max-width: 4rem;
  }
}

.csp2-header__listing-sold__title {
  @include responsive(font-size, 1.6em, 1.2em, 1em);

  flex: 11;
  font-weight: 700;
  color: var(--rc-color-palette-bw-white);
  padding-left: 1rem;

  .csp2-header[style] {
    color: var(--rc-color-palette-bw-white);
    text-shadow: 0 0 0.4rem var(--rc-color-shadow-400);
  }
}

.csp2-header__image {
  width: 10rem;
  border-radius: var(--rc-border-radius-md);

  img {
    display: block;
    width: 100%;
  }

  @include mobile {
    order: 2;
    width: 7.5rem;
  }
}

.csp2-header__content {
  flex: 1;
  display: flex;
  align-items: center;
  gap: var(--rc-space-6);

  @include mobile {
    gap: var(--rc-space-4);
    flex-direction: row-reverse;
  }
}

.csp2-header__content__inner {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.csp2-header__title {
  @include rc-text.title('900');

  .csp2-header[style] & {
    color: white;
    text-shadow: 0 0 0.3em var(--rc-color-shadow-600);
  }
}
