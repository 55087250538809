@use '@reverbdotcom/cadence/styles/tools/mixins/body-min-height';
@use '@reverbdotcom/cadence/styles/tools/mixins/links';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.form-cards__page-wrapper {
  @include body-min-height.body-min-height;
  background-color: var(--rc-color-background-page-secondary);

  h3 {
    @include rc-text.title('900');
  }
}

.form-cards__page-wrapper--mobile-native {
  min-height: 100vh;
}

.form-cards__form {
  width: 100%;
  max-width: 86rem;
}

.form-cards__form__content-wrapper {
  @include mobile {
    // allow space for the sticky footer without overlaying on top of any form content
    margin-bottom: 8rem;
  }

  &.form-cards__form__content-wrapper--tall-footer {
    @include mobile {
      // allows additional space when sticky footer contains stacked buttons
      margin-bottom: 15rem;
    }
  }
}

.form-cards__form__footer {
  display: flex;
  padding: 3rem 0;
  justify-content: flex-end;
  align-items: center;

  @include mobile {
    @include box-shadow;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    padding: 0;
    flex-direction: column-reverse;
    background: var(--rc-color-background-page);
    justify-items: center;
    transform: translate(calc(-1 * var(--rc-space-gutter-page)));
    z-index: 3; // so it goes on top of rc-select::after

    &.form-cards__form__footer--native-mobile {
      // on mobile web, the footer needs to stop being sticky at the site footer,
      // but on native mobile, we always want the footer to be fixed at the bottom of the viewport
      position: fixed;
      transform: none;
    }
  }
}

.form-cards__form__footer__additional-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    display: none;
  }

  a {
    @include links.text-link;
  }
}

.form-cards__form__footer__mobile-additional-content {
  display: none;

  @include mobile {
    display: block;
    margin: 2rem 1rem;
  }

  div + div {
    margin-top: 2rem;
  }
}

.form-cards__form__footer__actions {
  display: flex;
  align-items: center;

  & button, & a {
    margin-left: 2rem;
  }

  @include mobile {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 2rem;
    width: calc(100% - 4rem);
    padding: 2rem;
    // uses the default padding value or the browser safe area, whichever is larger
    padding-bottom: max(2rem, var(--rc-space-inset-browser-safe-area-bottom));

    & button, & a {
      margin: 0;
      width: 100%;
    }
  }
}

.form-cards__card {
  border-radius: var(--rc-border-radius-primary);
  background: var(--rc-color-background-module);

  @include mobile {
    margin-bottom: 2rem;
  }

  + .form-cards__card {
    margin-top: 3rem;

    @include mobile {
      margin-top: 0;
    }
  }

  & .rc-checkable-label {
    width: 100%;
  }
}

.form-cards__form-section {
  padding: 3rem;
  max-width: 100rem;

  + .form-cards__form-section {
    padding-top: 0;
  }

  &.form-cards__form-section--condensed-form {
    .rc-alert-box,
    .rc-form-group,
    .form-group,
    .form-cards__form-section__header__subtitle,
    .rc-form-group__error,
    .input-with-suggestions {
      max-width: 50rem;
    }
  }

  &.form-cards__form-section--toggled-section {
    padding: 0 3rem;
    border-top:  0.1rem solid var(--rc-color-border-primary);

    @include mobile {
      padding: 0 2rem;
    }
  }

  &.form-cards__form-section--bottom-border {
    border-bottom: 0.1rem solid var(--rc-color-border-primary);
    margin-bottom: 3rem;

    @include mobile {
      margin-bottom: 0;
    }
  }

  &.form-cards__form-section--highlight {
    background: var(--rc-color-background-alert-info);
    padding-bottom: 2rem;
    margin-bottom: 3rem;

    @include mobile {
      margin-bottom: 1rem;
    }
  }

  @include mobile {
    padding: 1.5rem;

    + .form-cards__form-section {
      padding-top: 1.5rem;
    }

    &.form-cards__form-section--toggled-section {
      padding-top: 0;
    }
  }
}

.form-cards__form-section__header {
  margin-bottom: 2rem;
  align-items: center;

  .form-cards__form-section__header__title {
    @include rc-text.title('500');

    .sell-form-review-section__section-content & {
      @include rc-text.title('500');
    }
  }

  .form-cards__form-section__header__subtitle {
    color: var(--rc-color-text-secondary);
  }
}

.form-cards__form-section__header__tag {
  display: inline;
  margin-left: 1rem;

  @include mobile {
    display: block;
    margin-left: auto;
    margin-top: .4rem;
  }
}
