.price-guide-tool-transaction-graph-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  min-width: 0;

  @include mobile {
    min-height: 0;
    padding: 1.1rem;
  }
}

.price-guide-tool-transaction-graph__tooltip {
  display: flex;
  flex-direction: column;
  justify-content: 'space-between';

  .price-guide-tool-transaction-graph__tooltip__title {
    font-size: 1.3rem;
    font-weight: bold;
  }

  .price-guide-tool-transaction-graph__tooltip__price-value {
    font-size: 1.2rem;
    padding: 0.5rem 0;
  }

  .price-guide-tool-transaction-graph__tooltip__condition {
    font-size: 1.2rem;
  }
}
