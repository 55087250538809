@use 'sass:math';
@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

.sell-form-video-input__video-list {
  @include clearfix;
}

.sell-form-video-input__video-list__item {
  @include grid-column(6, $cycle: true);
  position: relative;
  box-sizing: border-box;
  margin-bottom: .3rem;
  margin-left: 0;
  margin-right: 1.2rem;
  padding: 0.5rem;
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: 0.4rem;
  background-color: var(--rc-color-background-module);
  cursor: pointer;

  @include mobile {
    @include grid-column(12, $cycle: false);
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  &.sell-form-video-input__video-list__item--selected {
    color: inherit;
    border-color: var(--rc-color-border-primary);
    cursor: default;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.sell-form-video-input__video-list__item__title {
  @include truncate-text.truncate-text(
    $font-size: 1.4rem,
    $line-height: 1.2,
    $fixed-height: true
  );

  padding-top: 0.5rem;
  font-weight: bold;
  margin: 0;
}

.sell-form-video-input__video-list__item__publisher {
  @include truncate-text.truncate-text(
    $font-size: 1.4rem,
    $line-height: 1.2,
    $fixed-height: true
  );

  padding-top: 0.25rem;
  margin: 0;
}

.sell-form-video-input__video-list__item__details {
  display: flex;
  margin-top: .5rem;

  .sell-form-video-input__video-list__item__details-col {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1 35rem;
  }
}


.sell-form-video-input__video-list__item__remove {
  cursor: pointer;
  display: none;
  color: var(--rc-color-text-primary);
  transform: scale(1);
  align-self: center;
  border-color: var(--rc-color-border-primary);
  margin-top: .5rem;
  margin-left: 1rem;

  .sell-form-video-input__video-list__item--selected & {
    display: block;
    &:hover,
    &:focus-visible {
      color: var(--rc-color-text-accent);
    }
  }
}

.sell-form-video-input__video-list__item__link {
  @include truncate-text.truncate-text(
    $font-size: 1.2rem,
    $line-height: 1.2,
    $fixed-height: true
  );
}

.sell-form-video-input__video-list__item__video-frame {
  position: relative;
  padding: math.percentage((9/16) / 2); /* 16:9 */
  height: 0;
  transform: translateZ(0);
  animation: loadingPlaceholderGradient 1.3s ease 7;
  background: linear-gradient(270deg, var(--rc-color-palette-gray-200), var(--rc-color-palette-gray-100));
  background-size: 400% 400%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

.sell-form-video-input {
  margin-bottom: 0.5rem;
  width: 50rem;

  @include mobile {
    display: block;
    width: 100%;
  }

  .sell-form-video-input__input {
    margin-bottom: 2rem;
  }
}
