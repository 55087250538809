@use '../tools/mixins/button-reset';

.rc-token-card {
  @include button-reset.button-reset;
  display: flex;
  align-items: center;
  padding: var(--rc-space-2);
  gap: var(--rc-space-4);
  text-align: left;
  background-color: var(--rc-color-background-module);
  border-left: var(--rc-space-1) solid transparent;
  border-radius: 0;
  transition: border-color 0.2s ease-in, background-color 0.2s ease-in;
  width: 100%;
  min-height: var(--rc-space-12);
  box-shadow: 0 0.1rem 0 0 var(--rc-color-border-primary);

  @include responsive-hover {
    background-color: var(--rc-color-background-module-hovered);
  }

  &[aria-pressed='true'] {
    border-color: var(--rc-color-text-accent);
    background-color: var(--rc-color-background-module-hovered);
  }

  &:first-of-type {
    border-top-left-radius: var(--rc-border-radius-lg);
    border-top-right-radius: var(--rc-border-radius-lg);

    &[aria-pressed='true'] {
      border-top-left-radius: 0;
    }
  }

  &:last-of-type {
    border-bottom-left-radius: var(--rc-border-radius-lg);
    border-bottom-right-radius: var(--rc-border-radius-lg);
    box-shadow: none;

    &[aria-pressed='true'] {
      border-bottom-left-radius: 0;
    }
  }
}

.rc-token-card__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-1);
}

.rc-token-card__name {
  font-size: 1.6rem;
  color: var(--rc-color-text-primary);
  font-weight: var(--rc-font-weight-semibold);
}

.rc-token-browser__swatch {
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-full);
  position: relative;
  overflow: hidden;

  &.rc-token-browser__swatch--has-alpha,
  &.rc-token-browser__swatch--transparent {
    // checkerboard layer
    background: repeating-conic-gradient(var(--rc-color-palette-gray-500) 0% 25%, transparent 0% 50%) 50% / 10px 10px;
    transform: rotate(45deg);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &::before {
    background-color: var(--swatch-color-no-alpha);
  }

  &::after {
    background-color: var(--swatch-color);
  }

  &.rc-token-browser__swatch--has-alpha {
    &::before,
    &::after {
      width: 50%;
    }

    &::after {
      left: auto;
      right: 0;
    }
  }

  .rc-token-card & {
    width: 3rem;
    height: 3rem;
  }

  .rc-token-details & {
    width: 2rem;
    height: 2rem;
  }
}

.rc-token-card__swatch {
  height: 3rem;

  &.rc-token-card__swatch--border-radius {
    width: 3rem;
    border: 0.2rem solid var(--rc-color-border-primary);
  }

  &.rc-token-card__swatch--spacing {
    display: flex;
    border: 0.2rem solid var(--rc-color-border-primary);
    border-width: 0 0.2rem;
    background-color: var(--rc-color-sentiment-negative-lighter);
  }

  &.rc-token-card__swatch--font {
    font-family: var(--rc-font-family-monospace);
    font-size: 2.4rem;
    font-weight: bold;
    color: var(--rc-color-text-primary-disabled);
    text-transform: lowercase;
  }
}
