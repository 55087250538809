@use '../tools/mixins/internal/build';

// Note: Classes and values need to stay in sync with `RCFlex.tsx`

@include build-responsive-classes((
  valueList: (0, 1, 2, 3, 4, 8, half),
  tokenBase: '--rc-space-',
  attributes: (
    gap: gap,
    row-gap: row-gap,
    column-gap: column-gap,
  )
));

.gap-gutter {
  gap: var(--rc-space-gap-grid),
}

@include build-responsive-classes((
  valuePairs: (
    auto: auto,
    start: flex-start,
    end: flex-end,
    center: center,
    stretch: stretch
  ),
  attributes: (
    fx-align: align-items,
    fx-align-self: align-self,
  )
));

@include build-responsive-classes((
  valuePairs: (
    row: row,
    reverse: row-reverse,
    col: column,
    col-reverse: column-reverse
  ),
  attributes: (
    fx-dir: flex-direction,
  )
));

@include build-responsive-classes((
  valuePairs: (
    start: flex-start,
    end: flex-end,
    center: center,
    between: space-between,
    around: space-around,
    evenly: space-evenly
  ),
  attributes: (
    fx-justify: justify-content,
  )
));

@include build-responsive-classes((
  valueList: (1, 2, 3, 4, 5),
  attributes: (
    fx-order: order,
  )
));

@include build-responsive-classes((
  multiplier: 1%,
  valueList: (0, 10, 20, 25, 30, 40, 50, 60, 70, 75, 80, 90, 100, auto),
  attributes: (
    fx-width: flex,
  ),
  valuePrefix: '0 0 ',
));

.fx-grow {
  flex-grow: 1;
}

.fx-nogrow {
  flex-grow: 0;
}

.fx-shrink {
  flex-shrink: 1;
}

.fx-noshrink {
  flex-shrink: 0;
}

.fx-wrap {
  flex-wrap: wrap;
}

.fx-nowrap {
  flex-wrap: nowrap;
}

@include tablet {
  .tablet-fx-grow {
    flex-grow: 1;
  }

  .tablet-fx-nogrow {
    flex-grow: 0;
  }

  .tablet-fx-shrink {
    flex-shrink: 1;
  }

  .tablet-fx-noshrink {
    flex-shrink: 0;
  }

  .tablet-fx-wrap {
    flex-wrap: wrap;
  }

  .tablet-fx-nowrap {
    flex-wrap: nowrap;
  }
}

@include mobile {
  .mobile-fx-grow {
    flex-grow: 1;
  }

  .mobile-fx-nogrow {
    flex-grow: 0;
  }

  .mobile-fx-shrink {
    flex-shrink: 1;
  }

  .mobile-fx-noshrink {
    flex-shrink: 0;
  }

  .mobile-fx-wrap {
    flex-wrap: wrap;
  }

  .mobile-fx-nowrap {
    flex-wrap: nowrap;
  }
}

