.price-guide-module {
  width: 100%;
  display: flex;

  .dynamic-page__two-col__main &,
  &.price-guide-module--table-only {
    display: block;
  }

  @include mobile {
    display: block;
  }
}

.price-guide-module__chart {
  @include scaling(padding, 2rem);

  background: white;
  margin-right: $grid-gutter;
  flex: 1;
  border: 0.1rem solid var(--rc-color-border-primary);

  .dynamic-page__two-col__main & {
    @include scaling(margin-bottom, 2rem);

    margin-right: 0;
  }

  @include mobile {
    margin-right: 0;
    margin-bottom: $grid-gutter;
  }

  &:empty {
    display: none;
  }
}

.price-guide-module__table {
  box-shadow: none;
  border: 0.1rem solid var(--rc-color-border-primary);
  background: white;
  box-sizing: border-box;
  flex: 0 0 40rem;

  @include tablet {
    flex: 0 0 30rem;
  }
}
