.item2-image__status-indicator-image-banner {
  font-weight: bold;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.1;
  padding: 1rem;
  background-color: var(--rc-color-background-alert-highlight);
  border-bottom: 0.1rem solid var(--rc-color-border-alert-highlight);

  @include mobile {
    font-size: 1.4rem;
  }
}
