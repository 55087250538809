.feature-nudge {
  position: relative;
  padding: 1rem;
  margin-top: 1.5rem;
  border: 0.1rem solid;
  border-color: var(--rc-color-palette-blue-500);
  background: var(--rc-color-palette-blue-100);
  color: var(--rc-color-text-primary);
  font-size: 90%;
  text-align: center;

  &::before {
    content: '';
    border: 1.1rem solid transparent;
    border-bottom-color: var(--rc-color-palette-blue-500);
    position: absolute;
    bottom: 100%;
    right: 50%;
    transform: translateX(50%);
  }

  &::after {
    content: '';
    border: 1rem solid transparent;
    border-bottom-color: var(--rc-color-palette-blue-100);
    position: absolute;
    bottom: 100%;
    right: 50%;
    transform: translateX(50%);
  }

  &.feature-nudge--orange {
    background: var(--rc-color-palette-vu-orange-100);
    border-color: var(--rc-color-palette-vu-orange-400);
    color: var(--rc-color-palette-vu-orange-700);

    &::before {
      border-bottom-color: var(--rc-color-palette-vu-orange-400);
    }

    &::after {
      border-bottom-color: var(--rc-color-palette-vu-orange-100);
    }
  }
}
