@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.card-nudge {
  display: flex;
  gap: var(--rc-space-2);
}

.card-nudge__icon {
  width: var(--rc-space-5);
}

.card-nudge__text {
  @include rc-text.utility('350');

  .card-nudge__text--underline {
    border-bottom: dashed 0.1rem currentColor;
    line-height: 1.5;
  }
}
