@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.dynamic-page--csp {
  .sell-yours-v4 {
    @include mobile {
      display: none;
    }
  }

  .sell-yours-v4__copy {
    @include tablet {
      display: none;
    }

    @include mobile {
      font-size: 1.4rem;
      display: block;
    }
  }

  .sell-yours-v4__title {
    @include tablet {
      margin-bottom: 0;
    }

    @include mobile {
      font-size: 1.6rem;
      margin-bottom: 0.4rem;
    }
  }

  .sell-yours-v4--below-listing {
    display: none;

    @include mobile {
      display: block;
      margin-bottom: 3rem;
      margin-left: 0;
    }
  }

  @include max-width(1200px) {
    .sell-yours-v4__container {
      flex-direction: column;
    }

    .sell-yours-v4__content {
      margin-right: 0;
      margin-bottom: 1.8rem;
    }

    .sell-yours-v4__button {
      margin: 0;
    }
  }
}

.sell-yours-v4__container {
  @include scaling(padding, 2rem);
  display: flex;
  justify-content: space-between;
  border-radius: var(--rc-border-radius-primary);

  @include max-width(780px) {
    flex-direction: column;
  }
}

.sell-yours-v4__content {
  display: flex;
  align-items: center;
  margin-right: 2rem;

  @include max-width(780px) {
    margin-right: 0;
    margin-bottom: 1.8rem;
  }
}

.sell-yours-v4__icon {
  width: 5rem;
  margin-right: 2rem;

  @include tablet {
    width: 4rem;
    margin-right: 1.2rem;
  }
}

.sell-yours-v4__title {
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;

  @include max-width(780px) {
    font-size: 1.6rem;
    margin-bottom: 0.4rem;
  }
}

.sell-yours-v4__copy {
  @include max-width(780px) {
    font-size: 1.4rem;
    display: block;
  }
}

.sell-yours-v4__button {
  @include rc-button.filled($size: 'small');
  margin: auto 1rem;

  @include max-width(780px) {
    margin: 0;
  }
}
