.auto-offer-form__notices {
  @include scaling(margin-bottom, 3rem);

  color: var(--rc-color-text-secondary);

  p {
    margin-bottom: 1rem;
  }

  div.d-flex {
    margin-bottom: 1rem;
  }
}

.auto-offer-form__notices__icon {
  margin-top: 0.25rem;
  margin-right: 0.75rem;

  .rc-icon {
    height: 1em;
  }
}

.auto-offer-form__offer-timing {
  margin-top: 1rem;
  margin-bottom: 3rem;

  p {
    margin-bottom: 1rem;
  }
}
