.notification-dropdown__item {
  position: relative;
  display: block;
  color: var(--rc-color-text-primary);
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  white-space: nowrap;

  @include responsive-hover {
    background: var(--rc-color-palette-gray-100);
  }

  &::after {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}
