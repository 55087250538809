@use 'sass:map';

@each $name, $color in $sass-core-palette {
  .color-#{$name} {
    color: map.get($color, 'base');
    fill: map.get($color, 'base');
  }

  .hoverable-color-#{$name} {
    color: map.get($color, 'base');
    fill: map.get($color, 'base');

    @include responsive-hover {
      color: map.get($color, 'darker');
      fill: map.get($color, 'darker');
    }
  }

  .bg-#{$name} {
    background-color: map.get($color, 'base');

    &.bg--lighten {
      background-color: map.get($color, 'lighter');
    }

    &.bg--darken {
      background-color: map.get($color, 'darker');
    }
  }
}

@include mobile {
  @each $name, $color in $sass-core-palette {
    .mobile-bg-#{$name} {
      background-color: map.get($color, 'base');
    }
  }
}
