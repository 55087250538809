@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.csp2-product-module {
  @include responsive(padding-top, 2rem, 1rem, 0.5rem);

  &.csp2-sidebar--loading {
    @include loading-pulse;
  }
}

.csp2-product-module__sections {
  @include gap-row;
  display: flex;
  flex-wrap: wrap;
  gap: calc(2 * var(--rc-space-gap-grid)) var(--rc-space-gap-grid);
}

.csp2-product-module__section {
  &.csp2-product-module__section--gallery {
    width: 34rem;

    @include mobile {
      width: 100%
    }
  }

  &.csp2-product-module__section--specs {
    flex: 1 0 25rem;

    @include mobile {
      max-width: unset;
      flex: 0 0 100%;
    }
  }

  &.csp2-product-module__section--overview {
    flex: 1 0 30rem;
  }

  &.csp2-product-module__section--video {
    flex-basis: 100%;
    background-color: var(--rc-color-palette-gray-900);
  }

  &.csp2-product-module__section--specs,
  &.csp2-product-module__section--overview {
    padding-top: 2rem;
    border-top: 0.1rem solid var(--rc-color-border-primary);
  }

  &.csp2-product-module__section--admin {
    background-color: var(--rc-color-background-module-muted);
    margin: 2rem 0;
    padding: 2rem;
    flex-basis: 100%;
    text-align: center;
  }
}

.csp2-product-module__specs-list {
  font-size: 1.6rem;
  max-width: 50rem;

  li.collapsing-list__item {
    color: var(--rc-color-text-secondary);
  }
}

.csp2-product-module__header {
  @include rc-text.title('900');
  @include responsive((margin-top, margin-bottom), var(--rc-space-8), var(--rc-space-6));
}

.csp2-product-module__section--header {
  display: none;

  @include mobile {
    display: block;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}

.csp2-product-module__section--overview-header {
  color: var(--rc-color-text-primary);
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1.5rem;

  @include mobile {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
}

.csp2-product-module__description {
  color: var(--rc-color-text-secondary);
}

.csp2-product-module__video-wrapper {
  margin: var(--rc-space-6) auto;
  max-width: 80rem;

  @include tablet {
    margin: 0;
    max-width: unset;
  }

  .video-embed {
    margin-bottom: 1rem;
  }
}

.csp2-product-module__overview-read-more {
  @include button-as-link;

  display: block;
  font-size: 0.9em;
  color: var(--rc-color-text-secondary);
  margin-top: 0.2em;

  .collapsing-list--mobile-only & {
    display: none;

    @include mobile {
      display: block;
    }
  }

  .rc-popover & {
    font-size: 1em;
  }
}

