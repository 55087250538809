@use '@reverbdotcom/cadence/styles/tools/functions/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.site-subfooter {
  background: var(--rc-color-background-subfooter);
  padding: 2em 0;
  padding-bottom: 6rem; // extra padding so the chat widget doesn't cover end-of-page content
  color: var(--rc-color-text-footer);
  font-size: 0.8em;

  a {
    color: var(--rc-color-text-link-footer);

    @include responsive-hover {
      color: var(--rc-color-text-footer);
    }
  }
}

.site-subfooter__app-icons {
  text-align: right;
  max-width: 30rem;
  margin: 0 auto;
}

.site-subfooter__app-callout {
  max-width: 30rem;
  margin: 0 auto;

  @include mobile {
    margin-bottom: 1rem;
  }

  a {
    @include rc-button.outlined($size: 'small', $inverted: true);
  }
}

.site-subfooter__fineprint {
  margin-top: 1rem;
  font-size: 0.9em;
  opacity: 0.7;
  text-align: left;
}

.site-subfooter__app-icon {
  display: inline-block;

  + .site-subfooter__app-icon {
    margin-left: 2rem;
  }

  a {
    opacity: 1;
  }
}

.site-subfooter__icon-messages {
  display: flex;
  flex: 1;
  line-height: 1.2;
  align-items: center;
  padding: 0 1rem;

  @include tablet {
    padding: 2rem 1rem;
  }

  @include mobile {
    display: block;
  }
}

.site-subfooter__icon-message {
  display: flex;
  align-items: center;
  padding: 0 1rem;

  &::before {
    flex: 0 0 4rem;
    height: 4rem;
    content: '';
    display: block;
    margin-right: 1rem;
    background: no-repeat center transparent;
    background-size: contain;
  }

  h4 {
    font-weight: bold;
    margin-bottom: 0.5;
  }

  p {
    margin-bottom: 0;
  }

  &.site-subfooter__icon-message--carbon::before {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    background-image: svg.svg-url(carbon-offset, scss-tokens.$rc-color-text-footer);
  }

  &.site-subfooter__icon-message--gives::before {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    background-image: svg.svg-url(reverb-gives, scss-tokens.$rc-color-text-footer);
  }

  + .site-subfooter__icon-message {
    @include mobile {
      margin-top: 2rem;
    }
  }
}

.site-subfooter__social-icons {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;

  @include tablet {
    text-align: center;
    margin-bottom: 2rem;
  }
}

.site-subfooter__social-icon {
  display: inline-block;
  font-size: 2em;

  + .site-subfooter__social-icon {
    margin-left: 0.5em;
  }

  &::before {
    @extend %fa-icon-base;
    content: '';
  }

  @include mobile {
    font-size: 2.5em;
  }
}

.site-subfooter__social-icons-list-item {
  padding: 0 0.5em;
}
