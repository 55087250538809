.affirm-estimate-cta {
  font-size: 1.3rem;
  line-height: 1.3;
  margin-bottom: 2rem;
}

// Classes provided by Affirm JS SDK
// https://docs.affirm.com/payments/docs/customize-your-promotional-messaging
.affirm-as-low-as {
  font-size: 1.3rem;
  line-height: 1.3;
}
