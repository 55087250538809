@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

// Styles mostly copied from RCSegmentedControl

.button-group {
  display: inline-flex;
  box-sizing: border-box;
  background: var(--rc-color-background-segmented-control);
  min-height: var(--rc-min-height-button-medium);
  border-radius: var(--rc-border-radius-md);
  padding: var(--rc-border-width-button);
  gap: var(--rc-border-width-button);

  &.button-group--full-width {
    display: flex;
  }

  .price-history-graph-container & {
    min-height: var(--rc-min-height-button-small);
  }
}

.button-group__button {
  @include rc-button.transparent($size: 'medium');
  // rc-button variable overrides
  --button-height: calc(var(--min-height) - var(--rc-border-width-button) * 2);

  min-height: var(--button-height);
  line-height: 1;
  position: relative;
  z-index: 1;
  border-radius: calc(var(--rc-border-radius-md) - 0.2rem);
  padding-left: var(--rc-space-4);
  padding-right: var(--rc-space-4);

  .button-group--full-width & {
    flex: 1;
  }

  &.button-group__button--selected {
    @include rc-button.colors($theme: 'main', $variant: 'filled', $inverted: true);
    // override rc-button background-color when selected
    --background-color: var(--background-color-hovered);
  }
}
