@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

.download-button_menu {
  display: flex;

  .download-button_button {
    width: 100%;
    padding-right: 0.1rem;
    display: inline-grid;

    .rc-button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .download-button_dropdown-content {
    .rc-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.download-button_text {
  @include truncate-text.truncate-text();
}
