@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.grid-card {
  @include responsive(font-size, 1em, 0.95em, 0.9em);

  position: relative;
  width: 100%;
  // reset this because OverflowingRow sets it differently
  white-space: normal;
  box-shadow: none;
  background: none;
  transition: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include responsive-hover {
    box-shadow: none;

    .grid-card__image {
      @include box-shadow;
    }
  }

  &:hover,
  &:focus-within {
    .grid-card__watch {
      opacity: 1;
    }
  }
  &.grid-card--with-cart-action {
    .add-to-cart-button {
      margin-top: 1rem;
    }
  }

  &.grid-card--placeholder {
    @include loading-pulse;
  }
}

.grid-card__inner {
  display: flex;
  text-decoration: none;
  color: var(--rc-color-text-primary);
  flex-wrap: wrap;
  height: 100%;
  display: block;

  @include responsive-hover {
    color: var(--rc-color-text-primary);
  }
}

.grid-card__image {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
  background-color: var(--rc-color-palette-gray-100);
  border: 0.1rem solid var(--rc-color-border-primary);
  transition: box-shadow 0.15s;
  padding-bottom: calc(100% - 0.2rem);
}

.grid-card__main {
  flex: 0 0 100%;
  max-width: 100%;
}

.grid-card__nudge {
  padding: 0 0.7rem 0.5rem 0;
  font-weight: bold;
  font-size: 1.1rem;

  &.grid-card__nudge--in-other-carts {
    color: var(--rc-color-text-price-nudge);
  }
}

.grid-card__footer {
  flex: 0 0 100%;
  align-self: flex-end;

  .grid-card--placeholder & {
    .minimal-listing-row & {
      padding-top: 0.2rem;
    }
  }
}

.grid-card__main__text {
  padding: 0;
  margin: 1rem 0;

  .grid-card--condensed &,
  .minimal-listing-row & {
    display: none;
  }
}

.grid-card__bump-tag {
  position: absolute;
  margin: 0;
  bottom: 0;
  right: 0;
  background-color: var(--rc-color-highlight-600);
  padding: 0.2rem 0.4rem;
}

.grid-card__sold {
  position: absolute;
  margin: 0;
  bottom: 0;
  right: 0;
  background-color: var(--rc-color-highlight-600);
  padding: 0.2rem 0.4rem;
}

.grid-card__countdown-timer {
  color: var(--rc-color-sentiment-positive-dark);
  font-size: 1.2rem;
  width: 100%;
  font-weight: bold;
}

.grid-card__title {
  @include hyphenate;
  hyphens: auto;

  font-size: 0.9em;
  margin: 0;
  max-width: 100%;
  line-height: 1.2;
  max-height: 3.6em;
  overflow: hidden;
  font-weight: normal;

  .grid-card--placeholder & {
    height: 1em;
    width: 70%;
    background: var(--rc-color-palette-gray-100);
  }

  .tiles--large & {
    @include min-width($site-width-mid) {
      font-size: 1.6rem;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:only-child {
    margin: 0;
  }
}

.grid-card__label,
.grid-card__format,
.grid-card__artist {
  @include hyphenate;
  hyphens: auto;

  font-size: 0.8em;
  line-height: 1.2;
  max-height: 2.4em;
  overflow: hidden;

  .grid-card--placeholder & {
    width: 40%;
    background: var(--rc-color-palette-gray-100);
    height: 1em;
  }
}

.grid-card__label {
  font-size: 0.75em;
  max-height: 3.6em;
  opacity: 0.6;
}

.grid-card__format {
  font-size: 0.75em;
  opacity: 0.6;

  .grid-card--placeholder & {
    width: 20%;
  }
}

.grid-card__footer__actions {
  padding: 0 0.7rem 0.5rem 0;
}

.grid-card__footer__pricing {
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: block;
  margin: 0 0 1rem 0;

  .grid-card--software & {
    background: svg-url('download-icon') calc(100% - 1rem) top no-repeat;
    background-size: auto 1.2em;
  }

  .grid-card--condensed &,
  .minimal-listing-row & {
    padding-top: 0.5rem;
  }
}

.grid-card__price__amount {
  .grid-card--discounted & {
    color: var(--rc-color-text-price-nudge);
  }
}

.grid-card__price {
  font-weight: bold;
  line-height: 1;
  font-size: 1.3em;

  .grid-card--placeholder & {
    height: 1em;
    width: 3em;
    background: var(--rc-color-palette-gray-100);
  }

  .tiles--large & {
    @include min-width($site-width-mid) {
      font-size: 1.15em;
    }
  }

  .grid-card--no-inventory & {
    font-size: 0.75em;
    opacity: 0.7;

    .tiles--large & {
      @include min-width($site-width-mid) {
        font-size: 0.85em;
      }
    }
  }
}

.grid-card__original-price {
  padding: 0;
  font-size: 1.3rem;
  color: var(--rc-color-text-secondary);
  opacity: 1;

  .minimal-listing-row & {
    display: none;
  }

  .grid-card__discount {
    color: var(--rc-color-text-discount);
    font-weight: bold;
  }
}

.grid-card__condition {
  flex: 1;
  margin-top: 0.5rem;
  text-align: left;

  .grid-card--placeholder & {
    height: 1em;
    width: 3em;
    background: var(--rc-color-palette-gray-100);
  }
}

.grid-card__affirm-callout {
  font-weight: bold;
  font-size: 1.1rem;
  padding: 0.7rem 0 0.2rem;
  color: var(--rc-color-text-secondary);
}

.grid-card__free-shipping,
.grid-card__accepts-offers {
  font-weight: bold;
  font-size: 1.1rem;
  padding: 0 0.7rem;
  color: var(--rc-color-sentiment-positive-dark);
}

.grid-card__local-pickup {
  font-weight: bold;
  font-size: 1.1rem;
  padding: 0 0.7rem;
  color: var(--rc-color-text-secondary);
}

.grid-card__free-2-day {
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg.icon-before(fast-shipping-truck, scss-tokens.$rc-color-palette-vu-orange-500);

  display: block;
  color: var(--rc-color-palette-vu-orange-500);
  font-weight: bold;
  font-size: 1.1rem;
  padding: 0 0.7rem;

  .minimal-listing-row & {
    padding-top: 0.5rem;
  }
}

.grid-card__action {
  position: absolute;
  margin-top: 50%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--rc-color-palette-bw-white);
  width: 70%;

  @include tablet {
    width: 80%;
  }

  @include mobile {
    display: none; // disabled for now
    position: relative;
    margin: 0;
    top: auto;
    left: auto;
    transform: none;
    width: 100%;
  }
}

.grid-card__edit-actions {
  padding: 0.5em;
  border-top: 0.1rem solid var(--rc-color-border-primary);
  justify-content: space-between;
  display: flex;
  align-items: center;
  background: var(--rc-color-palette-gray-100);
}

.grid-card__edit-button {
  @include rc-button.muted;
  @include fa-icon-before(pencil);

  color: var(--rc-color-text-primary);
  border-color: var(--rc-color-border-primary);
  // matches button--mini
  font-size: 1.28rem;
  padding: 0.3em 0.7em;

  @include tablet {
    &::before {
      margin-right: 0;
    }
  }
}

.grid-card__watch {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;
  opacity: 0.8;
  transition: opacity 0.2s;

  @include tablet {
    opacity: 1;
  }
}

.grid-card__viewed-tag {
  position: absolute;
  top: 0.25rem;
  left: 0.5rem;
  z-index: 2;
}

.grid-card__feed-source {
  @include hyphenate;
  hyphens: auto;
  line-height: 1.2;
  font-size: 0.75em;
  color: var(--rc-color-text-secondary);
}

.grid-card__hide {
  width: auto;
  margin: 0.5rem 0 0 1rem;
}

.grid-card__article-category {
  font-weight: bold;
  color: var(--rc-color-text-secondary);
  margin-bottom: 0;
  font-size: 0.8em;
}
