@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.reverb-header {
  background: var(--rc-color-background-page);
  position: relative;

  // legacy value, could probably be smaller
  // but needs to be >1 to help the dropdown render over the main page content
  z-index: 1000;
}

.reverb-header__main {
  padding: 0.9rem 0;

  @include mobile {
    padding: 0.1rem 0;
  }

  .site-search {
    box-sizing: border-box;
    margin-top: 0;
  }
}

.reverb-header__links {
  border-top: solid 0.1rem var(--rc-color-border-primary);
  border-bottom: solid 0.1rem var(--rc-color-border-primary);

  @include mobile {
    border-top: none;
  }
}

.reverb-header__inner {
  @include site-wrapper;
  position: relative;

  nav {
    @include responsive(display, flex, $mobile: block);
    display: flex;
    align-items: center;
  }
}

.reverb-header__logo {
  margin: 1rem 1rem 1rem 0;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  color: var(--rc-color-text-primary);

  &:hover {
    color: var(--rc-color-text-secondary);
  }

  svg {
    display: block;
    height: 3.4rem;
    width: 11.5rem;
    fill: currentColor;

    @include mobile {
      width: 10rem;
      height: 3rem;
    }
  }
}

.reverb-header__nav {
  box-sizing: border-box;

  &.reverb-header__nav--main {
    flex: 1;
    position: relative;
    padding: 0 2rem;

    @include tablet {
      padding: 0 0 0 2rem;

      &::before {
        display: none;
      }
    }

    @include mobile {
      padding: 0;

      &::before {
        display: none;
      }
    }
  }

  &.reverb-header__nav--account {
    flex: 0 0 auto;
    padding: 0 0 0 1rem;

    @include mobile {
      position: absolute;
      right: var(--rc-space-gutter-page);
      top: 0.7rem;
      padding: 0;
    }
  }
}

.reverb-header__nav__search {
  width: 100%;

  @include tablet {
    padding: 0;
  }
}

.reverb-header__skip-link {
  @include site-wrapper;

  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  display: grid;
  transition: top 0.1s;
  z-index: var(--z-index-modal);

  &:focus-within {
    top: 0;
  }
}

.reverb-header__skip-link__link {
  @include text-link;
  place-self: center;
  width: 100%;
  text-align: center;
  padding: var(--rc-space-5);
  border-radius: 0 0 var(--rc-border-radius-md) var(--rc-border-radius-md);
  color: var(--rc-color-text-primary-inverted);
  background-color: var(--rc-color-text-link);

  &:hover {
    color: var(--rc-color-text-primary-inverted);
  }
}

.reverb-header__nav__links {
  .reverb-header__links & {
    display: flex;

    @include tablet {
      justify-content: space-between;
      margin: 0 -1rem;
    }
  }

  > li {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5rem;

    .reverb-header__links & {
      display: block;
      margin-left: 0;
    }

    + li {
      margin-left: 1.5rem;

      .reverb-header__links & {
        margin-left: 0;
      }
    }

    @include tablet {
      display: none;

      .reverb-header__links & {
        display: none; // needs this for specificty reasons
      }

      &.reverb-header__show-on-mobile {
        display: inline-block;
        margin-left: 0.5rem;

        .reverb-header__links & {
          margin-left: 0;
          display: block;
        }
      }

      &.reverb-header__badges-show-on-mobile {
        display: inline-block;
        margin-left: 1.5rem;

        .reverb-header__links & {
          margin-left: 0;
          display: block;
        }
      }
    }

    &.reverb-header__show-only-on-mobile {
      display: none;

      @include tablet {
        display: inline-block;
      }
    }
  }
}

.reverb-header__favorites-heart {
  @include tablet {
    margin-bottom: 0.4rem;
  }
}

.reverb-header__nav__link {
  display: block;
  position: relative;
  color: var(--rc-color-text-primary);
  transition: color .1s, background .1s;
  font-size: 1.3rem;
  text-align: center;

  .reverb-header__nav__links li:last-child & {
    padding-right: 0;
  }

  .reverb-header__links & {
    padding: 1rem;
  }

  &.reverb-header__nav__link--sell-button {
    @include min-width(641px) {
      @include rc-button.outlined;
    }

    @include mobile {
      @include button-as-link;
      display: block;
      text-transform: uppercase;
      color: var(--rc-color-text-primary);
      font-weight: bold;
      line-height: 2;
    }

    @include tablet {
      border: none;
      padding: 0;
      background: none;
      text-transform: uppercase;
      line-height: 2;

      &:hover {
        color: var(--rc-color-text-textlink-hovered);
        box-shadow: none;
        background-color: transparent;
      }
    }
  }
}

.reverb-header__nav__link__label {
  font-size: 1.1rem;
  line-height: 1;
  margin-top: 0.3em;

  // the user-menu avatar circle is an image, not an svg icon, so we can't change its color on hover and it looks weird
  // if only the text color changes, so this line specifically excludes it
  .reverb-header__nav__link:not(.reverb-header__nav__link--user-menu):hover & {
    color: var(--rc-color-text-accent);
  }

  @include tablet {
    display: none;
  }
}

.reverb-header__nav__link__icon {
  min-width: 1.6em; // to load in smoothly
  position: relative;
  height: 2.8rem;
  width: 2.8rem;
  margin: 0 auto;
  color: var(--rc-color-text-primary);

  .reverb-header__nav__link:hover & {
    color: var(--rc-color-text-accent);
  }

  &.reverb-header__nav__link--avatar {
    img {
      width: 100%;
      height: auto;
      border-radius: 50%;
    }
  }

  &[data-notification-count]::after {
    right: -0.6rem;
    top: -0.6rem;
    font-size: 1.1rem;
    border: 0.1rem solid var(--rc-color-background-page);

    @include tablet {
      top: -0.6rem;
    }
  }
}

.reverb-header__count-badge {
  @include visually-hidden;
}

.reverb-header__avatar {
  width: 4.6rem;

  img {
    border-radius: 50%;
    display: block;
    width: 100%;
    height: auto;
  }

  @include tablet {
    float: left;
    width: auto;
    padding-bottom: 2rem;
    margin-left: 0;

    img {
      width: 8rem;
    }
  }
}

.reverb-header__menu__notifications {
  max-width: 42rem;
  min-width: 20rem;
  white-space: nowrap;

  @include mobile {
    max-width: calc(100vw - 11rem);
  }
}

.reverb-header__menu__cart {
  width: 30rem;
  padding: 0;

  @include tablet {
    display: none;
  }
}

.reverb-header__menu__user-menu {
  max-width: 40rem;
  min-width: 20rem;
  white-space: nowrap;

  .reverb-header__menu__user-menu__header {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    opacity: 0.5;
    padding: 1rem 1rem 0.3rem 1rem;
  }

  ul {
    padding: 0 0 0.8rem 0;
    max-height: calc(100vh - 33rem);
    box-sizing: border-box;
    border-top: none;
    border-bottom: 0.1rem solid var(--rc-color-border-primary);
    overflow-y: auto;

    &:last-child {
      margin-bottom: 0;
      border: none;
    }

    a {
      display: block;
      color: var(--rc-color-text-primary);
      padding: 0.3rem 1rem;
      transition: color .1s;
      position: relative;

      @include responsive-hover {
        color: var(--rc-color-text-accent);
      }
    }

    span {
      padding: 0.3rem 1rem;
    }

    form {
      display: inline;
    }
  }
}

.reverb-header__name {
  padding: 0 0.4em;
  color: var(--rc-color-text-primary);
  font-weight: bold;
  margin: 1rem 0;

  b {
    display: block;
    font-size: 140%;
  }

  @include tablet {
    float: left;
    margin-left: 1rem;

    b {
      font-size: 180%;
    }
  }

  &.reverb-header__name--mobile {
    padding-bottom: 2rem;
    font-size: 120%;
  }
}

.reverb-header__menu__link {
  position: relative;
  display: block;
  color: var(--rc-color-text-primary);
  padding: 0.3em 3em 0.3em 0.4em;
  transition: color .1s, background .1s;

  &:hover {
    color: var(--rc-color-text-accent);
    background: var(--rc-color-background-module-hovered);
  }

  .reverb-header__menu__article-categories & {
    padding: 0.4em 0.6em;
  }

  .reverb-header__menu__footer & {
    opacity: 0.7;
    white-space: nowrap;

    &:hover {
      background: none;
    }
  }

  .reverb-header__menu__item & {
    border-bottom: 0.1rem solid var(--rc-color-border-primary);
    padding: 0.5em;
  }

  &[data-notification-count]::after {
    right: 0.4em;
    top: 0.4em;
    font-size: 1.4rem;
    padding: 0.2em;
  }

  .reverb-header__menu__cart & {
    display: flex;
  }

  &.reverb-header__menu__link--alert {
    background-color: var(--rc-color-background-neutral-ultralight);
    border: solid 0.1rem transparent;
    border-radius: $input-border-radius;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    margin-bottom: 0.5rem;
    font-weight: bold;
    transition: border-color 0.15s ease;

    &:hover {
      border-color: var(--rc-color-accent-neutral);
      color: var(--rc-color-text-on-neutral-ultralight);
    }
  }

  &.reverb-header__menu__link--new-feature {
    background-color: var(--rc-color-background-info-ultralight);

    &:hover {
      border-color: var(--rc-color-accent-info);
      color: var(--rc-color-text-on-info-ultralight);
    }

    &::after {
      @include fa-icon(gift);
      position: absolute;
      top: 0.6em;
      right: 0.6em;

      @include mobile {
        display: none;
      }
    }
  }

  &.reverb-header__menu__link--action-needed {
    background-color: var(--rc-color-background-notice-ultralight);
    padding-right: 3rem;

    &:hover {
      border-color: var(--rc-color-accent-notice);
      color: var(--rc-color-text-on-notice-ultralight);
    }

    &::after {
      @include fa-icon(exclamation-triangle);
      position: absolute;
      top: 0.6em;
      right: 0.6em;

      @include mobile {
        display: none;
      }
    }
  }
}

.reverb-header__menu__link-subtitle {
  display: block;
  font-size: 1.3rem;
  font-weight: normal;
  margin-top: 0.25rem;
  white-space: normal;
}

.reverb-header__menu__footer {
  .reverb-header__menu__cart & {
    padding: 0.5em;
  }

  ul {
    display: flex;
    justify-content: space-between;
  }

  li {
    width: 45%;
  }

  .button {
    @include rc-button.size('small');
  }
}

.reverb-header__menu__item {
  display: block;

  img {
    box-sizing: border-box;
    border-radius: 0.4rem;
    width: 6rem;
    height: 6rem;
    border: 0.1rem solid var(--rc-color-border-primary);
  }
}

.reverb-header__menu__item__description {
  box-sizing: border-box;
  flex: 1;
  padding-left: 1rem;
  line-height: 1.2;

  .reverb-header__menu__item__description__title {
    font-size: 85%;
    color: var(--rc-color-text-primary);
  }

  .reverb-header__menu__item__description__sale-reminder {
    margin: 0.25em 0 0 0;
    padding-left: 1em;
    text-indent: -1em;
    font-size: 80%;
    line-height: 1.2;
    color: var(--rc-color-text-price-nudge);
    font-weight: bold;

    &::before {
      @include fa-icon(tag);
      width: 1em;
      text-indent: 0;
      margin-right: 0.1em;
      font-size: 0.9em;
    }
  }

  small {
    margin-top: 0.25em;
    font-size: 85%;
    display: block;
    color: var(--rc-color-text-primary);
  }
}

.reverb-header__menu__item__description__discount-price {
  color: var(--rc-color-text-price-nudge);
}

.reverb-header__mobile-menu-toggle {
  @include button-as-link;
  display: none;
  width: 3rem;

  @include tablet {
    display: block;
    text-transform: uppercase;
    color: var(--rc-color-text-primary);
    font-weight: bold;
    line-height: 2;
  }
}

.reverb-header__mobile-menu {
  display: none;
  padding: 1rem;

  .reverb-header__menu__user-menu__header {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    opacity: 0.5;
    padding: 1rem 1rem 0.3rem 1rem;
  }

  ul {
    padding: 0 0 0.8rem 0;
    border: none;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      display: block;
      color: var(--rc-color-text-primary);
      padding: 0.3rem 1rem;
      transition: color .1s;
      position: relative;

      @include responsive-hover {
        color: var(--rc-color-text-accent);
      }
    }
  }

  @include tablet {
    display: block;
    position: fixed;
    z-index: 10001;
    top: 0;
    left: 0;
    right: 0;
    bottom: 100%;
    overflow: hidden;
    padding-left: 2rem;
    padding-right: 2rem;
    opacity: 0;
    transition: opacity .1s, transform .2s;
    transform: scale(0.95);
    pointer-events: none;
    background: var(--rc-color-background-module);

    &.reverb-header__mobile-menu--active {
      opacity: 1;
      bottom: 0;
      transform: scale(1);
      overflow-y: auto;
      pointer-events: auto;
    }

    > ul {
      width: 50%;
      float: left;
      margin: 1rem 0;
    }
  }
}

.reverb-header__mobile-menu__header {
  @include clearfix;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
  margin-top: 4rem;

  &.reverb-header__mobile-menu__header--centered {
    display: flex;
    align-items: center;
  }
}

.reverb-header__mobile-menu__close {
  @include button-as-link;
  position: absolute;
  top: 1.2rem;
  right: 2rem;
  display: block;
  text-transform: uppercase;
  color: var(--rc-color-text-primary);
  font-weight: bold;
}

.reverb-header__cart__credits-balance {
  margin: 0.5em;
  margin-top: 0;
  padding: 1rem;
  background-color: var(--rc-color-background-success-ultralight);
  border: 0.1rem solid var(--rc-color-accent-success);
  font-size: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reverb-header__menu__credits-balance {
  padding: 0.3rem 1rem;
  font-size: 90%;
  font-weight: bold;
  color: var(--rc-color-text-success);
}

.reverb-header__menu__user-menu__mobile_secondary_user_container {
  align-items: center;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
  display: flex;
  margin-left: 1%;
  margin-right: 1%;
  padding: 1rem 0;

  span {
    margin: 0 1rem 0 0.5rem;
    max-width: 75%;
  }

  form {
    display: inline;
  }
}
