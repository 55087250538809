@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.sidebar-promos {
  opacity: 1;
  transition: opacity .2s;

  .facet-sidebar--placeholder & {
    opacity: 0;
  }

  @include mobile {
    display: none;
  }
}

.sidebar-promo {
  position: relative;
  margin-bottom: 1rem;
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;
  display: block;
  // stylelint-disable color-no-hex -- specific hex needed to match image bg from marketing
  background-color: #fffaed;
  // stylelint-enable
}

.sidebar-promo__heading {
  font-size: 1.4rem;
  color: var(--rc-color-text-primary);
  margin: 0;
  padding: 2rem 2rem 0.5rem;
  text-align: center;
  font-weight: bold;
}

.sidebar-promo__subheading {
  color: var(--rc-color-text-primary);
  font-size: 0.9rem;
  margin: 0;
  padding: 0 2rem 1rem;
  text-align: center;
  font-weight: normal;

  .sidebar-promo--sell-gear & {
    padding-left: 4rem;
    padding-right: 4rem;

    &::after {
      // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
      @include svg.icon-bg(marketing-heart, scss-tokens.$rc-color-palette-vu-orange-500);
      content: '';
      display: block;
      width: 1.2rem;
      height: 1.1rem;
      margin: 0.5rem auto 0;
    }
  }
}

.sidebar-promo__image {
  display: block;
  width: 100%;
  height: auto;
}

.sidebar-promo__footer {
  display: block;
  background-color: var(--rc-color-palette-vu-orange-500);
  color: var(--rc-color-palette-bw-white);
  padding: 0.5rem;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.2;
}
