.buy-it-now-express-pay-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 27rem;
  margin: auto;
  justify-content: center;

  @include mobile {
    max-width: 100%;
  }
}

.listing-financing-callout {
  width: 30rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}
