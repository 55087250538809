.express-pay-separator {
  display: flex;
  justify-content: center;
  align-items: center;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 50%;
    height: 0.1rem;
    margin: 0.3rem 1rem 0 1rem;
    background-color: var(--rc-color-palette-gray-300);
  }

  &.express-pay-separator--condensed {
    &::before,
    &::after {
      width: 20%;
    }
  }
}
