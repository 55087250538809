@use 'sass:math';
@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

.csp-card-row {
  white-space: nowrap;
  display: flex;
  margin-left: -#{math.div($grid-gutter, 2)};
  margin-right: -#{math.div($grid-gutter, 2)};
  -ms-flex-negative: 1;

  @include mobile {
    display: block;
  }
}

.csp-embed-card {
  @include scaling((margin-top margin-bottom), 3rem);

  .g-container & {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.csp-embed-card--floated {
    float: right;
    margin: 1rem 0 1rem 4rem;
    margin-left: 4rem;
    width: 30rem;

    @include mobile {
      float: none;
      margin: 2rem auto;
      width: 100%;
    }
  }
}

.csp-card {
  position: relative;
  white-space: normal;

  a {
    @include shadow-on-hover;
    display: block;
    position: relative;
    background: var(--rc-color-background-module);
    transition: box-shadow .2s;
    color: var(--rc-color-text-primary);
  }

  .csp-card-row & {
    // weird webkit bug: don't use auto here, they won't size evenly
    flex: 1;
    box-sizing: border-box;

    padding-left: math.div($grid-gutter, 2);
    padding-right: math.div($grid-gutter, 2);

    @include mobile {
      width: 80%;
      display: inline-block;
    }

    &:last-child {
      @include mobile {
        padding-right: 0;
        margin-right: var(--rc-space-gutter-page);
      }
    }
  }

  &.csp-card--placeholder {
    @include loading-pulse;
  }

  .csp-card-row--single & {
    @include mobile {
      width: 100%;
    }
  }

  .csp-embed-card & {
    border: 0.1rem solid var(--rc-color-border-primary);
    font-size: 1.3rem;
  }

  // don't set fixed widths if they are being defined by the grid system
  .product-sidebar .csp-embed-card &,
  .g-container .csp-embed-card & {
    width: auto;

    @include mobile {
      width: auto;
    }
  }

  .product-sidebar .csp-embed-card & {
    box-shadow: none;
  }
}

.csp-card__image {
  position: relative;
  background: no-repeat center;
  background-size: cover;
  height: 12rem;

  @include mobile {
    height: 10rem;
  }

  .csp-card--placeholder & {
    background: var(--rc-color-background-loading-placeholder);
  }
}

.csp-card__text {
  border-top: 0.1rem solid var(--rc-color-border-primary);
  padding: 0.8rem;
}

.csp-card__title {
  @include truncate-text.truncate-text(
    $font-size: 1.6rem,
    $line-height: 1.2,
  );

  font-weight: bold;
  margin-bottom: 0.2em;

  // specificity hack because of blog styles
  .blog-post__content & {
    margin-bottom: 0.2em;
  }

  // specificity hack to make sure it works in CMS pages
  .pages.show &[class] {
    font-size: 1.6rem;

    @include mobile {
      font-size: 1.5rem;
    }
  }

  @include mobile {
    @include truncate-text.truncate-text(
      $font-size: 1.4rem,
      $line-height: 1.2,
    );

    // for some reason this fixes the ellipsis
    // with single line truncation in Webkit
    display: block;
  }

  @include min-width($site-width-narrow) {
    .csp-card-row--single & {
      font-size: 2.6rem;
      height: auto;
      max-height: none;
      -webkit-line-clamp: none;
    }
  }
}

.csp-card__subtitle {
  font-weight: bold;
  line-height: 1;
  min-height: 1em;
  font-size: 1.3rem;
  opacity: 0.7;

  @include mobile {
    font-size: 1.2rem;
  }
}
