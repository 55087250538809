@use '../tools/mixins/responsive';
@use '../tools/mixins/box-shadow';
@use '../tools/mixins/dialogs';
@use '../tools/mixins/loading-pulse';

.rc-listing-grid {
  --listing-columns: 5;

  display: grid;
  flex: 1;
  grid-template-columns: repeat(var(--listing-columns), 1fr);
  gap: var(--rc-space-gap-grid);
  grid-auto-flow: dense;

  &.rc-listing-grid--loading {
    @include loading-pulse.loading-pulse;
  }

  &:not(.rc-listing-grid--with-sidebar, .rc-listing-grid--single-row) {
    @include rc-listing-grid-columns(4) {
      --listing-columns: 4;
    }

    @include rc-listing-grid-columns(3) {
      --listing-columns: 3;
    }

    @include rc-listing-grid-columns(2) {
      --listing-columns: 2;
    }
  }

  // optimized for 170px+ size
  &.rc-listing-grid--with-sidebar {
    @include max-width(1306px) {
      --listing-columns: 4;
    }

    @include max-width(1112px) {
      --listing-columns: 3;
    }

    // sidebar begins floating at this breakpoint, giving more space for the grid
    @include tablet {
      --listing-columns: 4;
    }

    @include max-width(788px) {
      --listing-columns: 3;
    }

    @include mobile {
      --listing-columns: 2;
    }
  }

  &.rc-listing-grid--single-row,
  &.rc-listing-grid--single-row-t-and-d {
    --max-listings: 6;
    --ideal-width: calc((100% - ((var(--max-listings) - 1) * var(--rc-space-gap-grid))) / var(--max-listings));
    --min-width: 20rem;
    --listing-width: max(var(--ideal-width), var(--min-width));
    grid-template-columns: repeat(auto-fill, var(--listing-width));
    grid-auto-flow: column;
    grid-auto-columns: var(--listing-width);
    width: 100%;
  }

  &.rc-listing-grid--single-row-t-and-d {
    @include mobile {
      --listing-columns: 2;
      grid-template-columns: repeat(var(--listing-columns), 1fr);
      grid-auto-flow: dense;
    }
  }
}

.rc-listing-grid__item {
  &.rc-listing-grid__item--span-all {
    grid-column: 1 / -1;

    // extra spacing because our gutter by default doesn't provide quite enough visual separation on mobile
    @include rc-listing-grid-columns(2) {
      padding-bottom: var(--rc-space-gap-grid);
    }
  }

  &.rc-listing-grid__item--hide-d,
  &.rc-listing-grid__item--hide-t-and-d {
    display: none;
  }

  @include tablet {
    &.rc-listing-grid__item--hide-t,
    &.rc-listing-grid__item--hide-t-and-d,
    &.rc-listing-grid__item--hide-t-and-m {
      display: none;
    }
  }

  @include mobile {
    &.rc-listing-grid__item--hide-m,
    &.rc-listing-grid__item--hide-m-and-t {
      display: none;
    }
  }
}
