@include build-responsive-classes((
  valueList: (block, inline, inline-block, flex, grid, none),
  attributes: (
    d: display,
  )
));

// hide an element when it is empty. Helpful for wrapper elements when their React children
// conditionally render null
.hide-when-empty {
  &:empty {
    display: none;
  }
}
