.carrier-option {
  @include radio;

  > label {
    border: 0.2rem solid var(--rc-color-border-primary);
    border-radius: var(--rc-border-radius-input);
    padding: 2rem;
    background: var(--rc-color-background-page);
    cursor: pointer;
    box-sizing: border-box;
    justify-content: space-between;

    @include mobile {
      flex-wrap: wrap;
    }

    &:hover {
      border-color: var(--rc-color-background-input-checked);
    }
  }

  > input:checked + label {
    border-color: var(--rc-color-background-input-checked);

    .rate-table-body {
      display: table-row-group;
    }

    .rate-billing {
      display: block;
    }
  }
}

.carrier-option__titleblock {
  flex: 1;
  margin-right: 2rem;

  @include mobile {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.carrier-option__title {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.carrier-option__description {
  line-height: 1.2;
  margin-bottom: 0;
}

.carrier-option__details {
  width: 50%;

  @include mobile {
    width: 100%;
  }
}

.carrier-option__price-table {
  width: 100%;

  td {
    padding: 0.5rem;
    vertical-align: top;

    &:last-child {
      text-align: right;
    }
  }

  tbody tr:last-child td {
    padding-bottom: 1rem;
    border-bottom: solid 0.1rem var(--rc-color-border-primary);
  }

  tfoot td {
    padding-top: 1.5rem;
  }
}

.carrier-option__price-details {
  display: none;

  .carrier-option > input:checked + label & {
    display: table-row-group;
  }
}

.carrier-option__total {
  font-size: 2.5rem;
  font-weight: 700;
}
