@use 'sass:math';

// Aspect Ratio
//
// This mixin uses the css aspect-ratio property where supported with a fallback.
// With no args passed, this will deliver a 1 / 1 aspect ratio.
//
// Note: this should be used on an element that wraps an img. Example below:
//
// <div class="my-image-wrapper">  <-- Use the mixin on this element
//   <img src="foo">
// </div>
//
// Note: Do not use padding-bottom on elements where this mixin is used.

@mixin aspect-ratio(
  $width: 1,
  $height: 1,
  $object-fit: cover,
) {
  aspect-ratio: #{$width} / #{$height};

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: $object-fit;
  }

  @supports not (aspect-ratio: 1 / 1) {
    height: 0;
    padding-bottom: math.div($height, $width) * 100%;
    position: relative;

    img {
      position: absolute;
    }
  }
}
