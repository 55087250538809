@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow';
@use '@reverbdotcom/cadence/styles/tools/mixins/aspect-ratio';

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: center 1rem no-repeat;
  background-color: var(--rc-color-background-page-secondary);
  background-size: 8rem 8rem;
  border-radius: var(--rc-border-radius-primary);
  overflow: hidden;
  transition: background-color 0.15s ease, box-shadow 0.15s ease;
  min-width: 20rem;

  @include mobile {
    min-width: unset;
  }

  &:hover {
    @include box-shadow.box-shadow;
    background-color: var(--rc-color-background-page);
  }
}

.category-item__image {
  @include aspect-ratio.aspect-ratio(5, 6);
  width: 100%;
  background-size: cover;
  background-position: center;
}

.category-item__footer {
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
}

.category-item__text {
  padding: var(--rc-space-6);
  font-weight: bold;
  line-height: 1.25;
  color: var(--rc-color-text-primary);
  text-align: center;

  @include mobile {
    padding: var(--rc-space-4);
  }
}

.category-item-redesign {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  background: center 1rem no-repeat;
  background-color: var(--rc-color-background-card);
  background-size: 8rem 8rem;
  border-radius: var(--rc-border-radius-primary);
  overflow: hidden;
  transition: background-color 0.15s ease, box-shadow 0.15s ease;

  @include mobile {
    min-width: unset;
  }

  &:hover {
    @include box-shadow.box-shadow;
    background-color: var(--rc-color-background-card-hovered);
  }

  .category-item__text {
    @include tablet {
      font-size: var(--rc-font-size-350);
      padding: var(--rc-space-3);
    }

    @include mobile {
      font-size: var(--rc-font-size-400);
      padding: var(--rc-space-4);
      text-align: left;
    }
  }
}

.category-item-main-row {
  flex-direction: column;
  height: 100%;

  @include mobile {
    flex-direction: row-reverse;
    max-height: 10rem;

    .category-item__footer {
      display: flex;
      justify-content: left;
      flex: 0 0 66%;
      width: 100%;
    }
  }
}

.category-item-overflow {
  flex-direction: row-reverse;
  max-height: 10rem;

  .category-item__footer {
    display: flex;
    justify-content: left;
    flex: 0 0 66%;
    width: 100%;
  }

  @include tablet {
    font-size: var(--rc-font-size-350);
    padding: var(--rc-space-3);
    text-align: left;
  }

  .category-item__text {
    @include tablet {
      text-align: left;
    }
  }
}
