@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.dashboard-facet-sidebar {
  box-sizing: border-box;
  margin-bottom: var(--rc-space-3);

  .facet {
    margin-bottom: var(--rc-space-8);
  }

  .facet__heading {
    @include rc-text.title('500');
    margin-bottom: var(--rc-space-2);
  }
}
