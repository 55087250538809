@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.promo-banner-top {
  a {
    display: block;
    color: var(--rc-color-palette-bw-white);
    background: var(--rc-color-palette-vu-orange-500);
    transition: background-color 0.2s;

    &:hover {
      background-color: var(--rc-color-palette-vu-orange-600);
      color: var(--rc-color-palette-bw-white);
    }

    @include tablet {
      font-size: 85%;
    }
  }

  &.promo-banner-top--downtime {
    color: var(--rc-color-palette-bw-white);
    background-color: var(--rc-color-palette-vu-orange-500);
    text-align: center;
  }
}

.promo-banner-top__inner {
  @include site-wrapper;
  display: flex;
  align-items: center;

  @include tablet {
    min-width: 0;
  }
}

.promo-banner-top__info {
  flex: 1;
  padding: 0.8em 0;
  display: flex;
  align-items: center;

  b {
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    border-right: 1px solid var(--rc-color-palette-bw-white);
    margin-right: 1em;
    padding-right: 1em;

    @include tablet {
      display: block;
      border: none;
      margin: 0;
      padding: 0;
    }
  }

  @include tablet {
    padding: 0.5em 0 0.5em 0;
  }
}

.promo-banner-top__info__subheading {
  @include tablet {
    display: none;
  }
}

.promo-banner-top__info__ad {
  background: var(--rc-color-highlight-600);
  margin-left: 1em;
  padding: 0.1em 0.5em;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.6em;
  color: var(--rc-color-text-primary);
}

.language-banner-top__info {
  flex: 1;
  padding: 0.8em 0;

  b {
    display: inline-block;
    font-weight: bold;
    margin-right: 1em;
    padding-right: 1em;

    @include tablet {
      display: block;
      margin: 0;
      padding: 0;
    }
  }

  @include tablet {
    padding-bottom: 0;
  }
}

.promo-banner-top__action {
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg.icon-after(arrow-right, scss-tokens.$rc-color-palette-bw-white);

  flex: 0 0 auto;
  border-left: 1px solid var(--rc-color-palette-bw-white);
  white-space: nowrap;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.8em 0 0.8em 2em;

  @include tablet {
    border: 0;
    padding: 0.3em 0 0.5em 2em;
  }

  @include tablet {
    border: 0;
    padding: 0.5em 0 0.5em 2em;
  }
}

