@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.outlet-page__header {
  background-color: var(--rc-color-palette-tweed-orange-500);
  color: var(--rc-color-text-primary);
}

.outlet-page__header__image {
  @include full-bleed;

  background-image: url('#{$cdn-path}/image/upload/outlet/png/outlet.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  flex: 0 0 40%;
  border-top-left-radius: 20rem;
  margin-left: 0;

  @include min-width($site-width-max) {
    margin-right: 0;
    margin-left: 0;
  }

  @include mobile {
    @include full-bleed;
    flex: 1;
    min-height: 15rem;
    max-height: 15rem;
    min-width: 102%;
    max-width: 102%;
    border-top-left-radius: 0;
    border-top-right-radius: 10rem;
    overflow: hidden;
    --fullbleed-offset-right: calc(-0.2 * var(--rc-space-gutter-page));
  }
}

.outlet-page__header__container {
  display: flex;
  margin-right: calc(-0.5 * var(--rc-space-gutter-page));

  @include min-width($site-width-max) {
    margin-right: 0;
  }

  @include mobile {
    flex-direction: column;
  }
}

.outlet-page__header__title__container {
  display: flex;
  flex-direction: column;
  padding: 0 2rem 0 0;

  @include mobile {
    padding: var(--rc-space-2);
  }
}

.outlet-page__header_breadcrumbs {
  padding-top: var(--rc-space-10);

  @include mobile {
    padding-top:  var(--rc-space-2);
  }
}

.outlet-page__header__title {
  @include rc-text.display;
  padding-top:  var(--rc-space-15);

  @include tablet {
    @include rc-text.title('900');
    padding-top:  var(--rc-space-10);
  }

  @include mobile {
    @include rc-text.display;
    padding-top:  var(--rc-space-5);
  }
}

.outlet-page__header_subtitle {
  @include rc-text.display;
  color: var(--rc-color-palette-vu-orange-900);

  @include tablet {
    @include rc-text.title('900');
  }

  @include mobile {
    @include rc-text.display;
    padding-bottom:  var(--rc-space-5);
  }
}

.outlet-page__header_search {
  max-width: 48rem;
  padding-top: var(--rc-space-4);
  padding-bottom: var(--rc-space-10);

  @include mobile {
    padding-top: var(--rc-space-2);
    padding-bottom: var(--rc-space-6);
  }
}

.outlet-page__nudges__container {
  background-color: var(--rc-color-palette-yellow-100);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
}

.outlet-page__nudges__subcontainer {
  max-width: var(--rc-size-max-width-content);
  background-color: pink;
}

.outlet-page__nudges {
  @include site-wrapper;

  display: flex;
  justify-content: space-around;
  font-weight: bold;
  color: var(--rc-color-text-primary);
  padding-top: var(--rc-space-10);
  padding-bottom: var(--rc-space-10);
  padding-left: var(--rc-space-gutter-page);
  padding-right: var(--rc-space-gutter-page);
  max-width: var(--rc-size-max-width-content);
  width: var(--rc-size-max-width-content);

  .rc-icon svg {
    color: var(--rc-color-palette-tweed-orange-600);
    font-weight: bold;
    width: 3.5rem;
    height: auto;

    @include mobile {
      width: 2.8rem;
      height: 2.8rem;
    }
  }

  div {
    font-weight: bold;
    font-size: medium;
    margin-right: 1.8rem;
    display: flex;
    align-items: center;
    overflow: visible;
  }

  @include tablet {
    padding: var(--rc-space-10) var(--rc-space-3) var(--rc-space-10) var(--rc-space-2);
    margin-left: var(--rc-space-5);

    div {
      font-size: small;
      margin-right: 1.2rem;
      max-height: 10rem;
      overflow: visible;
    }
  }

  @include mobile {
    display: flex;
    flex-direction: column;
    padding: var(--rc-space-5) var(--rc-space-5) var(--rc-space-5) var(--rc-space-0);

    div {
      padding-top: var(--rc-space-1);
      padding-bottom: var(--rc-space-1);
      max-height: 10rem;
    }
  }
}

.outlet-page__footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.outlet-page__footer_title {
  @include rc-text.title('900');
}

.outlet-page__footer_title_container {
  text-align: center;
}

.outlet-page__footer_items_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5rem 0 5rem 0;

  @include tablet {
    display: flex;
    flex-direction: column;
    padding: var(--rc-space-10) 0;
  }
}

.outlet-page__footer_item {
  justify-content: space-around;
  padding:var(--rc-space-2);
  text-align: center;
  max-width: 40rem;

  @include tablet {
    padding:var(--rc-space-4) var(--rc-space-5);
    max-width: 100%;
  }

  @include mobile {
    padding:var(--rc-space-5) var(--rc-space-1);
    max-width: 100%;
  }
}

.outlet-page__footer_item_icon_title {
  font-weight: var(--rc-font-weight-bold);
  justify-content: center;
  align-items: center;
  justify-items: center;
  display: flex;
  flex-direction: column;

  .rc-icon {
    width: 5rem;
    justify-content: center;
    height: auto;
    color: var(--rc-color-palette-tweed-orange-600);
  }

  .outlet-page__footer_item_title {
    @include rc-text.title('700');
    padding: var(--rc-space-3);
  }
}

.outlet-page__footer_item_text {
  font-size: var(--rc-font-size-500);
}

.outlet-page__footer__text {
  font-size: var(--rc-font-size-500);
}

.outlet-page__footer__subtitle {
  margin: 0 auto;
  max-width: 80rem;
}

.outlet-page__loading-text {
  @include loading-pulse;
  background-color: var(--rc-color-background-loading-placeholder);
  width: 8em;
  height: 1em;
  border-radius: var(--rc-border-radius-sm);
}

.outlet-page__empty-state {
  border: 0.1rem solid var(--rc-color-border-primary);

  border-radius: 0.8rem;
  margin: 3rem 0;
  padding: 3rem;
  background-color: var(--rc-color-palette-bw-white);
  text-align: center;
}
