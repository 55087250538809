@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.react-seller-listing-cards {
  .pagination {
    margin-top: var(--rc-space-6);
  }
}

.listings-manager {
  padding: var(--rc-space-8) 0;
}

.listings-manager__content {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-6);
}

.seller-recommendations-summary__title {
  display: flex;
  align-items: center;
  gap: var(--rc-space-6);
  font-size: var(--rc-font-size-550);

  .rc-icon {
    color: var(--rc-color-text-accent);
    font-size: var(--rc-font-size-1000);
  }

  .rc-text-with-icon__icon--left {
    margin-right: 0;
  }
}

.seller-recommendations-summary__content {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-2);
  margin-bottom: var(--rc-space-3);
  margin-left: var(--rc-space-20);
  padding-left: var(--rc-space-2);
  list-style-type: disc;

  @include tablet {
    margin-left: var(--rc-space-16);
    padding-left: var(--rc-space-4);
  }
}

.react-seller-listing-cards, .listings-manager {
  .listing-row {
    background-color: var(--rc-color-background-page);
  }

  .pagination {
    li {
      a {
        @include rc-button.muted($extend: false);
      }

      &.active a,
      &.active a:hover {
        @include rc-button.outlined($extend: false);
      }
    }
  }

  .sidebar-min-width {
    .rc-site-wrapper__sidebar {
      min-width: 23%;
    }
  }

  .rc-site-wrapper__sidebar {
    min-width: 24%;
  }

  .rc-site-wrapper__sidebar__content {
    overscroll-behavior: contain;
  }

  .rc-accordion__heading__button {
    padding-left: 0;
  }

  .listing-results {
    .results-count {
      margin: 0;
    }
  }

  .panel-popout {
    z-index: 6;

    @include mobile {
      z-index: 99000000;
    }
  }
}

.seller-listing-card {
  border-color: var(--rc-color-border-primary);
  background-color: var(--rc-color-background-page);
  border-radius: var(--rc-border-radius-lg);
  border-width: 0.1rem;
  border-style: solid;
}

.seller-listing-card__content {
  display: flex;
  gap: var(--rc-space-4);
  padding: var(--rc-space-4) var(--rc-space-4) 0 var(--rc-space-4);

  @include tablet {
    padding-bottom: var(--rc-space-4);
  }
}

.seller-listing-card__content__image {
  width: 9rem;

  @include tablet {
    flex-shrink: 0;
  }

  img {
    border-radius: var(--rc-border-radius-md);
  }
}

.seller-listing-card__content__details-header {
  display: flex;
  justify-content: space-between;

  @include tablet {
    flex-direction: column;
    margin-left: var(--rc-space-4);
    margin-bottom: var(--rc-space-4);
    min-height: 9rem;
  }
}

.seller-listing-card__errors {
  color: var(--rc-color-sentiment-negative-dark);

  a {
    @include text-link;
  }

  ul {
    list-style: disc;
  }
}

.seller-listing-card-footer {
  padding: var(--rc-space-4);

  .rc-button {
    @include rc-button.size('mini');

    @include tablet {
      @include rc-button.size('small');
    }
  }
}

.seller-listing-card-footer__state {
  min-width: 9rem;
  font-weight: bold;
  font-size: 90%;
  margin-bottom: var(--rc-space-2);

  @include tablet {
    order: 1;
  }
}

.seller-listing-card-footer__state--default {
  color: var(--rc-color-palette-gray-700)
}

.seller-listing-card-footer__state--live {
  color: var(--rc-color-sentiment-positive-dark)
}

.seller-listing-card-footer__state--suspended {
  color: var(--rc-color-sentiment-negative-dark)
}

.seller-listing-card-footer__tablet-button-container {
  display: none;

  @include tablet {
    display: flex;
  }
}

.sales-memberships-tabs {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);

  button {
    @include button-as-link;

    font-weight: var(--rc-font-weight-bold);
    color: var(--rc-color-text-primary);
    padding-bottom: var(--rc-space-2);

    &:hover {
      background: none;
      border-width: 0 0 0.2rem;
      border-style: solid;
      border-color: var(--rc-color-palette-vu-orange-500);
    }
  }
}

.sales-memberships-tabs--selected {
  button {
    color: var(--rc-color-palette-vu-orange-500);
    border-width: 0 0 0.2rem;
    border-style: solid;
    border-color: var(--rc-color-palette-vu-orange-500);
  }
}

.listings-management-export-buttons {
  @include button-as-link;
  @include text-link;

  text-decoration: none;
  font-weight: var(--rc-font-weight-bold);
}

.listings-management-insights-facet__pricing-filter {
  .rc-checkable-label__sublabel {
    .rc-icon {
      color: var(--rc-color-text-accent);
    }
  }
}

.quick-price-edit-button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--rc-space-1);
}


.quick-price-edit-button__priced-above-average {
  font-size: var(--rc-font-size-350);

  .rc-icon {
    color: var(--rc-color-text-accent);
  }
}

.bulk-publish-errors__list-style {
  list-style: disc;
}

.seller-listing-cards-toolbar {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-6);
  width: 100%;
}

.seller-listing-cards-toolbar__alerts {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-6);
}

.seller-listing-cards-toolbar__actions {
  display: flex;
  align-items: center;
  gap: var(--rc-space-6);

  @include tablet {
    flex-direction: column;
    align-items: normal;
    gap: var(--rc-space-3);

    .rc-chip {
      width: 100%;
    }
  }
}

.seller-listing-cards-collection__listings {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-4);
}

.seller-listing-cards-collection__empty__create-listing-link {
  font-weight: bold;
  color:  var(--rc-color-palette-gray-900);
  text-decoration: underline;
  text-decoration-thickness: 0.2rem;
  margin-top: var(--rc-space-2);
}

.seller-listing-cards-toolbar__navbar__label {
  font-weight: var(--rc-font-weight-semibold);
  font-size: var(--rc-font-size-350);
}

.nav-tabs {
  padding-left: 0;
  padding-right: 0;
}
