@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.form-section-wrapper {
  + .form-section-wrapper {
    margin-top: 1.6rem;
  }
}

.form-section-wrapper__header {
  background-color: var(--rc-color-palette-gray-100);
  padding: 1.6rem 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.1rem var(--rc-color-border-primary);

  @include mobile {
    padding: 1.6rem;
  }
}

.form-section-wrapper__title {
  font-size: 1.8rem;
  font-weight: 700;

  &.form-section-wrapper__title--complete::before {
    @include fa-icon(check-circle);
    color: var(--rc-color-sentiment-positive-dark);
    margin-right: 0.5em;
  }

  &.form-section-wrapper__title--error {
    color: var(--rc-color-text-danger);

    &::before {
      @include fa-icon(exclamation-triangle);
      color: currentColor;
      margin-right: 0.5em;
    }
  }
}

.form-section-wrapper__edit {
  @include button-reset;
  @include fa-icon-before(pencil, $margin-right: 0.25em);
  color: var(--rc-color-palette-blue-600);
  font-size: 1.4rem;
  font-weight: 700;

  &:hover {
    color: var(--rc-color-palette-vu-orange-500);
  }
}

.form-section-wrapper__expand {
  @include rc-button.muted;
  font-size: 1.3rem;
  margin-left: 2rem;
}
