@use '@reverbdotcom/cadence/styles/tools/mixins/responsive-hover' as hover;
@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow' as shadow;
@use '@reverbdotcom/cadence/styles/tools/mixins/links';

.category-collection__item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: var(--rc-border-radius-lg);
  color: var(--rc-color-text-primary);
  background-color: var(--rc-color-background-card);
  transition: box-shadow 0.15s ease, background-color 0.15s ease;
  overflow: hidden;

  @include hover.responsive-hover {
    @include shadow.box-shadow;

    color: var(--rc-color-text-primary);
    background-color: var(--rc-color-background-card-hovered);
  }

  &.category-collection__item--placeholder {
    @include loading-pulse;

    @include hover.responsive-hover {
      box-shadow: none;
    }
  }

  &.category-collection__item--rounded-square {
    @include links.group-link;

    border-radius: 0;
    align-items: center;
    flex: unset;
    overflow: unset;
    background-color: unset;

    @include hover.responsive-hover {
      box-shadow: none;

      .category-collection__item__image {
        @include shadow.box-shadow;
      }
    }
  }
}

.category-collection__item__image {
  @include commons-svg-background('instruments-darken.svg');
  @include responsive(height, 12rem, 10rem, 8rem);

  background-color: var(--rc-color-palette-gray-300);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 12rem;

  .category-collection__item--rounded-square & {
    height: 0;
    padding-bottom: 100%;
    border-radius: var(--rc-border-radius-lg);
  }
}

.category-collection__item__inner {
  flex: 1;
  width: 100%;

  .category-collection__item--rounded-square & {
    text-align: center;
  }
}

.category-collection__item__text {
  font-size: 1.6rem;
  line-height: 1.25; // 2rem
  padding: var(--rc-space-4);
  font-weight: bold;
  box-sizing: border-box;

  @include mobile {
    font-size: 1em;
    font-weight: bold;
  }
}
