.intl-settings-nag {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 3rem 3rem 7rem 3rem; // Offset bottom padding to avoid overlapping with Ada chatbot button
  background: var(--rc-color-palette-gray-900);
  color: var(--rc-color-palette-bw-white);
  box-sizing: border-box;
  z-index: 100;

  @include mobile {
    padding: 1rem 3rem;
  }
}

.intl-settings-nag__inner {
  @include site-wrapper;
  display: flex;
  justify-content: space-between;

  @include tablet {
    display: block;
    text-align: center;
  }
}

.intl-settings-nag__close {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 0.5em;
  color: var(--rc-color-palette-bw-white);
}

