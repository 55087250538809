@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.amp-feature {
  @include gap-row;

  .dynamic-page__one-col & {
    @include site-wrapper;
    border: none;
  }
}

.amp-feature__inner {
  display: flex;
  min-height: 20rem;

  @include mobile {
    display: block;
  }

  .dynamic-page__two-col & {
    flex-direction: row-reverse;
    background-color: var(--rc-color-background-card);
    border-radius: var(--rc-border-radius-lg);
    overflow: hidden;
    min-height: 30rem;

    @include tablet {
      display: block;
    }
  }

  .dynamic-page__one-col & {
    border: 0.1rem solid var(--rc-color-border-primary);
  }
}

.amp-feature__text {
  @include scaling(padding, 4rem);

  flex: 0 0 45%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-basis: 55%;
  }

  .dynamic-page__two-col & {
    flex-basis: 60%;
    justify-content: center;
  }
}

.amp-feature__image {
  display: block;
  flex: 0 0 55%;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  @include tablet {
    height: 0;
    padding-bottom: 56.25%; // ratio same as image-feature.scss
    flex-basis: 45%;
    background-size: contain;
  }

  .dynamic-page__one-col & {
    @include tablet {
      padding: 0;
      height: auto;
    }

    @include mobile {
      height: 0;
      padding-bottom: 56.25%; // ratio same as image-feature.scss
    }
  }

  .dynamic-page__two-col & {
    flex: 1;
  }
}

.amp-feature__heading {
  @include rc-text.title('900');
  margin-bottom: var(--rc-space-4);
}

.amp-feature__subtitle {
  font-size: 90%;
  font-style: italic;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 1rem;
  white-space: normal;
}

.amp-feature__body {
  font-size: 0.9em;
  white-space: normal;
}

.amp-feature__tag-callout {
  position: absolute;
  bottom: 0;
  left: 0;
}
