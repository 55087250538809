.sale-information {
  @extend %component-row;
}

.sale-information__inner {
  display: flex;

  @include tablet {
    display: block;
    font-size: 90%;
    text-align: center;
    padding: 1rem;

    &::before {
      display: none;
    }
  }

  &::before {
    @include fa-icon(tag);
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    color: var(--rc-color-text-price-nudge);
    text-align: center;
    font-size: 4rem;
  }
}

.sale-information__landing {
  @include box-shadow;
  background: var(--rc-color-palette-bw-white);
  padding: 0 1.5rem 1.5rem;
}

.sale-information__details {
  flex: 1 1 auto;
  max-width: 70%;
  padding: 0 2rem;
  box-sizing: border-box;

  @include tablet {
    max-width: initial;
    padding: 0;
  }
}

.sale-information__discount {
  font-size: 120%;

  strong {
    font-size: 130%;
    // stylelint-disable-next-line declaration-property-unit-allowed-list -- Obscure exception
    line-height: 2rem;

    @include tablet {
      font-size: 100%;
      line-height: inherit;
    }
  }
}

.sale-information__description {
  position: relative;
  font-size: 90%;
  margin-top: 0.4em;
}

.sale-information__fineprint {
  flex: 1 1 auto;
  text-align: right;
  max-width: 30%;
  box-sizing: border-box;

  @include tablet {
    text-align: inherit;
    margin-top: 0.5rem;
    max-width: none;
  }
}

.sale-information__fineprint__shop-info {
  clear: both;
  font-size: 90%;
  opacity: 0.6;
  margin-top: 0.2em;
}

.sale-information__fineprint__date-info {
  font-size: 90%;
  font-weight: bold;
  margin-top: 0.2em;
  color: var(--rc-color-text-price-nudge);
}

.sale-information__fineprint__social {
  font-size: 1.4rem;
  line-height: 1.2;
  text-align: right;

  @include tablet {
    display: none;
  }

  a {
    display: inline-block;
    float: none;
    margin-left: 1em;
  }

  span {
    &.no-icon-bg {
      min-width: 0;
      text-align: right;
    }

    span {
      margin-right: 0;
    }
  }
}

.sale-information__details__description {
  @include scaling(margin-top, 0.5rem);
  margin-bottom: 0;

  @include tablet {
    font-size: 80%;
  }
}

.sale-information__search {
  margin-bottom: 1rem;

  @include tablet {
    padding: 1rem;
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
}
