.buy-it-now-standard-option {
  @include button-reset;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  min-height: 7rem;
  border: 0.2rem solid var(--rc-color-border-selectable);
  border-radius: var(--rc-border-radius-md);
  width: 100%;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--rc-color-button-main-secondary);
    border-color: var(--rc-color-button-main-primary);
  }

  &.buy-it-now-standard-option--dc {
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      justify-content: unset;
    }
  }
}

.buy-it-now-standard-option__title {
  font-weight: bold;
  margin-right: 1rem;
  text-align: left;
  flex: 1;

  .buy-it-now-standard-option--dc & {
    @include mobile {
      margin-bottom: var(--rc-space-1);
    }
  }
}

.buy-it-now-standard-option__icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: -0.25rem;
}
