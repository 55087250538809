.sell-form-pricing-section__active-scheduled-price-drop-container {
  margin-top: 1rem;
  margin-left: 4.5rem;
  font-size: 1.4rem;

  p {
    color: var(--rc-color-text-secondary);
    margin-bottom: 1rem;
  }

  .rc-form-group {
    margin-bottom: 1rem;
  }
}
