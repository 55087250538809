@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

$modal-sidebar-width: 26rem;
$modal-top-offset: 10vh;
$modal-bottom-offset: 5vh;

.ReactModal__Body--open {
  overflow: hidden;

  &.ReactModal__Body--allow-scroll {
    overflow: auto;
  }
}

.ReactModalPortal {
  position: relative;
  z-index: var(--z-index-modal);
  opacity: 0;
  transition: opacity .3s;

  .ReactModal__Body--open & {
    opacity: 1;
  }
}

.reverb-modal__container {
  @include smooth-scroll;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  overflow-y: scroll;

  &.reverb-modal__container--hidden {
    background: none;
    pointer-events: none;
  }
}

.reverb-modal {
  @include box-shadow;
  position: absolute;
  left: 50%;
  max-width: 90vw;
  transform: translateX(-50%);
  margin: $modal-top-offset 0;
  background: var(--rc-color-background-module);
  border-radius: var(--rc-border-radius-primary);
  border: 0.1rem solid var(--rc-color-border-primary);
  max-height: calc(100vh - $modal-top-offset - $modal-bottom-offset);
  display: flex;
  flex-direction: column;

  @include mobile {
    width: auto;
    max-width: none;
    left: 1rem;
    right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    transform: none;
    max-height: calc(100dvh - 2rem);
  }

  &.reverb-modal--with-sidebar {
    padding-left: $modal-sidebar-width;
    max-width: calc(90vw - #{$modal-sidebar-width});
    min-height: 50rem;

    @include tablet {
      padding-left: 0;
      max-width: 90vw;
      min-height: auto;
    }

    @include mobile {
      max-width: none;
    }
  }

  &.reverb-modal--position-bottom {
    bottom: 0;
    margin-bottom: min(var(--rc-space-gutter-page), var(--rc-space-gutter-page));
  }

  &.reverb-modal--mobile-top-margin {
    @include mobile {
      margin-top: 10rem;
    }
  }
}

.reverb-modal__content {
  width: 55rem; // default width of basic modal
  max-width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  pointer-events: auto;

  @include mobile {
    width: auto;
  }

  .reverb-modal--fluid & {
    width: auto;
  }

  .reverb-modal--wide & {
    width: calc(#{$site-width-max} - #{$modal-sidebar-width} - (var(--rc-space-gutter-page) * 2));
  }

  .reverb-modal--narrow & {
    width: 35rem;

    @include mobile {
      width: auto;
    }
  }

  .reverb-modal--newsletter-popover & {
    width: 40rem;

    @include mobile {
      width: auto;
    }
  }

  .reverb-modal--component-preview & {
    width: 90vw;
    max-width: none;

    @include mobile {
      width: auto;
    }
  }
}

.reverb-modal__header {
  padding: 1.5rem 5rem;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
  flex-grow: 0;
  flex-shrink: 0;
  border-top-left-radius: var(--rc-border-radius-primary);
  border-top-right-radius: var(--rc-border-radius-primary);

  .reverb-modal--padded-body & {
    margin-bottom: 0;
  }

  .reverb-modal--signup-signin & {
    background-color: var(--rc-color-background-module-secondary);
    padding-top: 0;
    padding-bottom: 0;
  }

  &.reverb-modal__header--overlap-close-x {
    padding: 1.5rem;
    margin-bottom: 0;
  }
}

.reverb-modal__header__title {
  @include rc-text.title('700');
  text-align: center;
}

.reverb-modal__sidebar {
  @include scaling(padding, 3rem);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  width: $modal-sidebar-width;
  background-color: var(--rc-color-palette-gray-900);
  color: var(--rc-color-palette-gray-200);
  border-radius: var(--rc-border-radius-primary) 0 0 var(--rc-border-radius-primary);
  overflow-y: auto;

  @include tablet {
    position: static;
    background-color: transparent;
    width: auto;
    color: var(--rc-color-text-primary);
    border-bottom: solid 0.1rem var(--rc-color-border-primary);
    border-radius: 0;
  }
}

.reverb-modal__body {
  flex-grow: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.reverb-modal__body-inner {
  overflow-y: auto;

  .reverb-modal--padded-body & {
    @include scaling(padding, 3rem);
  }

  .reverb-modal--modal-dialog & {
    flex-grow: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }
}

.reverb-modal__form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.reverb-modal__dialog-body {
  @include scaling(padding, 3rem);
  overflow-y: auto;
}

.reverb-modal__actions {
  @include scaling(padding, 3rem);
  border-top: 0.1rem solid var(--rc-color-border-primary);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex: 0;
  gap: var(--rc-space-4);

  @include mobile {
    flex-wrap: wrap;
    gap: var(--rc-space-3);
  }
}

.reverb-modal__actions__save {
  @include rc-button.filled;

  @include mobile {
    flex-grow: 1;
  }
}

.reverb-modal__actions__back {
  @include rc-button.muted;

  @include mobile {
    display: none;
  }
}

.reverb-modal__actions__close-or-back {
  @include rc-button.muted;

  @include mobile {
    flex-grow: 1;
    width: 100%;
  }
}

.reverb-modal__actions__delete {
  @include rc-button.transparent($theme: 'danger');
  margin-right: auto;

  @include mobile {
    flex-grow: 1;
    margin-right: 0;
  }
}

.reverb-modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
