@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.pagination,
.pagination-container {
  @include scaling((margin-top margin-bottom), 3rem);
  text-align: center;
}

.pagination__buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;

  li:empty {
    display: none;
  }
}

.pagination__button {
  @include rc-button.muted;

  &.pagination__button--gap {
    pointer-events: none;
  }

  &.pagination__button--gap,
  &.pagination__button--number {
    padding-left: var(--rc-space-1);
    padding-right: var(--rc-space-1);

    @include tablet {
      display: none;
    }
  }

  &.pagination__button--active {
    @include rc-button.outlined($extend: false);
  }
}
