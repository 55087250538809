@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.product-reviews-layout {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-4);

  .dynamic-page--csp & {
    margin-top: var(--rc-space-8);
  }

  .item2 & {
    padding: 0 var(--rc-space-x-accordion); // used to match heading alignment with surrounding content
  }
}

.product-reviews-layout__header {
  @include rc-text.title('700');
  padding: var(--rc-space-6) 0;

  @include mobile {
    padding: var(--rc-space-4) 0;
  }

  .dynamic-page--csp & {
    @include rc-text.title('900');
    padding: var(--rc-space-8) 0;

    @include mobile {
      padding: var(--rc-space-6) 0;
    }
  }
}
