.feed-article-card {
  @include clearfix;
  margin: $grid-gutter 0;

  a {
    @include shadow-on-hover;
    display: block;
    background: var(--rc-color-palette-bw-white);
    position: relative;
    color: var(--rc-color-text-primary);
    padding: 2rem 2rem 2rem 33%;

    @include mobile {
      padding: 10rem 1rem 1rem 1rem;
    }
  }
}

.feed-article-card__category {
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.feed-article-card__image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  float: left;
  width: 30%;
  background: center no-repeat;
  background-size: cover;

  @include mobile {
    width: 100%;
    height: 9rem;
  }
}

.feed-article-card__title {
  font-weight: bold;
  font-size: 1.6em;

  @include mobile {
    font-size: 1.2em;
  }
}

.feed-article-card__summary {
  margin: 1em 0;

  @include mobile {
    display: none;
  }
}

.feed-article-card__source {
  @include fa-icon-before(search);
  font-size: 1.2rem;
  color: var(--rc-color-text-secondary);

  @include mobile {
    display: none;
  }
}
