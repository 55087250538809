.ribbon-view {
  position: absolute;
  left: 0.5em;
  top: 0.5em;
  box-sizing: border-box;
  max-width: calc(85% - 1em);
  line-height: 1;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0.2em 0.4em 0.25em;
  color: white;
  background-color: var(--rc-color-sentiment-negative-dark);
  border: 0.1rem solid var(--rc-color-sentiment-negative-dark);
  z-index: 1;
  margin: 0;

  @include mobile {
    font-size: 1rem;
    line-height: 1.1;
  }

  &.ribbon-view--auction {
    background-color: var(--rc-color-sentiment-positive-dark);
    border-color: var(--rc-color-sentiment-positive-dark);
  }

  &.ribbon-view--pricedrop {
    right: auto;
  }

  &.ribbon-view--ended {
    background-color: var(--rc-color-palette-gray-800);
    border-color: var(--rc-color-border-primary);
  }

  // product show page
  .g-col-8 & {
    font-size: 1.4rem;
  }

  .item2-pricing & {
    font-size: var(--rc-font-size-400);
  }

  // this should be the default state, needs comprehensive refactor
  &.ribbon-view--unstyled {
    position: static;
    top: 0;
    left: 0;
    max-width: none;
    color: var(--rc-color-text-price-nudge);
    padding: 0;
    border: none;
    background: none;

    &.ribbon-view--auction {
      color: var(--rc-color-sentiment-positive-dark);
    }

    &.ribbon-view--ended {
      color: var(--rc-color-text-primary-disabled);
    }
  }

  &.ribbon-view--redesign {
    font-size: 1em;
    font-weight: 400;
  }
}
