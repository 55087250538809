@use '../tools/mixins/box-shadow' as shadows;
@use '../tools/mixins/truncate-text';
@use '../tools/mixins/responsive';

.rc-listing-card {
  --card-padding: calc(0.5 * var(--rc-space-gap-grid));
  --card-title-font-size: 1.6rem;
  --card-title-line-height: 1.25;
  --card-title-lines-to-show: 2;
  --card-subtitle-font-size: 1.4rem;
  --card-subtitle-line-height: 1.14;
  --card-image-edge-spacing: var(--rc-space-4);
  --card-eyebrow-height: 1.6rem;

  position: relative;
  width: 100%;

  @include tablet {
    --card-image-edge-spacing: var(--rc-space-3);
  }

  @include mobile {
    --card-title-font-size: 1.5rem;
    --card-title-lines-to-show: 3;
    --card-image-edge-spacing: var(--rc-space-2);
  }

  &.rc-listing-card--full-height {
    height: 100%;
  }

  &::before {
    @include shadows.box-shadow;
    content: '';
    display: block;
    border-radius: calc(var(--rc-border-radius-lg) + var(--card-padding));
    position: absolute;
    top: calc(-1 * var(--card-padding));
    right: calc(-1 * var(--card-padding));
    bottom: calc(-1 * var(--card-padding));
    left: calc(-1 * var(--card-padding));
    background-color: var(--rc-color-background-elevated);
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s ease;
  }

  @include responsive-hover {
    &::before {
      opacity: 1;

      @include mobile {
        opacity: 0;
      }
    }
  }

  // any tooltip should be hoverable
  .rc-tooltip__trigger,
  [data-rc-tooltip-trigger] {
    position: relative;
    z-index: 2;
  }
}

.rc-listing-card__meta {
  display: none;
}

.rc-listing-card__inner {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  height: 100%;
  gap: var(--rc-space-2);
}

.rc-listing-card__thumbnail {
  display: block;
  position: relative;
  width: 100%;
  border-radius: var(--rc-border-radius-lg);
  overflow: hidden;
}

.rc-listing-card__violator {
  position: absolute;
  bottom: var(--rc-space-2);
  left: var(--rc-space-2);
  max-width: calc(100% - 2 * var(--rc-space-2));
}

.rc-listing-card__favorite-button {
  position: absolute;
  top: var(--card-image-edge-spacing);
  right: var(--card-image-edge-spacing);
  z-index: 2;
}

.rc-listing-card__eyebrow {
  margin-bottom: var(--rc-space-half);

  &:empty {
    margin-bottom: 0;
  }

  .rc-listing-card--whitespace & {
    &:empty {
      height: var(--card-eyebrow-height);
      margin-bottom: var(--rc-space-half);

      @include mobile {
        margin-bottom: 0;
        height: auto;
      }
    }
  }
}

.rc-listing-card__header {
  display: flex;
  gap: var(--rc-space-1)
}

.rc-listing-card__titleblock {
  flex-grow: 1;

  .rc-listing-card--whitespace & {
    height: calc(
      (var(--card-title-font-size) * var(--card-title-line-height) * var(--card-title-lines-to-show))
      +
      var(--rc-space-1)
      +
      (var(--card-subtitle-font-size) * var(--card-subtitle-line-height))
      +
      (var(--card-eyebrow-height) + var(--rc-space-half))
    );

    @include mobile {
      height: auto;
    }
  }
}

.rc-listing-card__title {
  @include truncate-text.truncate-text($lines-to-show: var(--card-title-lines-to-show), $line-height: var(--card-title-line-height));
  line-height: var(--card-title-line-height);
  font-size: var(--card-title-font-size);
  font-weight: var(--rc-font-weight-semibold);
  word-break: break-word;
}

.rc-listing-card__title-link {
  display: block;
  color: var(--rc-color-text-primary);

  @include responsive-hover {
    color: var(--rc-color-text-primary);
  }

  // This is what makes the whole card clickable
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; // it's on "the bottom" of the card's inner clickable elements
  }
}

// Fallback link element when there is no title
.rc-listing-card__label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--rc-border-radius-lg);
  z-index: 1; // it's on "the bottom" of the card's inner clickable elements
}

.rc-listing-card__title__remove-button {
  --button-size: 4.4rem;
  --icon-size: 2.4rem;
  --offset: calc(-1 * (((var(--button-size) - var(--icon-size)) / 2) - var(--rc-space-1))); // math out the white-space dimension then add a touch more to compensate for line-height

  position: relative;
  z-index: 2;

  .rc-button.rc-button--main.rc-button--transparent {
    --background-color-hovered: var(--background-color);
    --text-color: var(--rc-color-palette-gray-500);
    --text-color-hovered: var(--rc-color-text-primary);
    --min-height: var(--button-size);
  }

  // reposition the icon so the visual icon is locked in the corner of the card correctly
  .rc-close-button {
    display: block;
    position: relative;
    top: var(--offset);
    right: var(--offset);

    .rc-button {
      padding: 0;
    }

    .rc-icon {
      display: block;
      height: var(--icon-size);
    }
  }
}

.rc-listing-card__condition {
  margin-top: var(--rc-space-1);
  font-size: var(--card-subtitle-font-size);
  line-height: var(--card-subtitle-line-height);
  font-weight: var(--rc-font-weight-medium);
  color: var(--rc-color-text-primary);
}

.rc-listing-card__shop-location {
  @include truncate-text.truncate-text(
    $lines-to-show: 1,
    $line-height: var(--card-subtitle-line-height),
    $font-size: var(--card-subtitle-font-size),
    $fixed-height: true,
  );

  font-size: var(--card-subtitle-font-size);
  line-height: var(--card-subtitle-line-height);
  font-weight: var(--rc-font-weight-medium);
}

.rc-listing-card__actions {
  padding: var(--rc-space-1) 0;
  display: flex;
  flex-wrap: wrap;
  gap: var(--rc-space-2);

  .rc-listing-card--single-action & {
    display: block;
  }

  .rc-listing-card--hide-actions-on-mobile & {
    @include mobile {
      display: none;
    }
  }
}

.rc-listing-card__action {
  position: relative;
  z-index: 2;
  display: flex;
  flex: 1 0 auto;

  &:empty {
    display: none;
  }
}

.rc-listing-card__nudge-block {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-1);
  // compensate for space-2 gap between major divs of listing grid
  // because 'free shipping' nudge is technically in the previous sibling div
  // but should space-1 apart from other nudges
  margin-top: calc(-1 * var(--rc-space-1));

  &:empty {
    display: none;
  }
}

.rc-listing-card__admin-controls {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  z-index: 2;

  .rc-listing-card:hover & {
    display: flex;
  }
}
