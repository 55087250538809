@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/functions/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

// This is a new search UI that, after an audit, could be renamed and become canonical.
// Currently it's only used in the new CSP searching flow.
.csp-search-input {
  // keeping top-level BEM namespace here for clarity
}

.csp-search-input__form {
  display: flex;
  margin: 1.2rem 0;
  position: relative;

  @include mobile {
    display: block;
  }
}

.csp-search-input__input-wrapper {
  position: relative;
  flex-grow: 1;
}

.csp-search-input__label {
  margin-bottom: 0.4rem;
}

.csp-search-input__input {
  // inputs on Reverb need a little extra specificity
  &[type='text'] {
    font-size: 2rem;
    padding: 1.8rem;
    padding-right: 6rem; // to make room for clear button
  }
}

.csp-search-input__clear {
  @include button-reset;
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 6rem;
  height: 6rem;
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  background-image: svg.svg-url(close, scss-tokens.$rc-color-palette-gray-900);
  background-size: 2rem auto;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.6;
  transition: opacity 0.15s ease;

  &:hover {
    opacity: 1;
  }
}

// need this specificity to override some deeper core styles
.csp-search-input__submit {
  @include rc-button.filled;
  font-size: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-left: 2rem;

  @include mobile {
    margin-left: 0;
    margin-top: 1rem;
  }
}

.csp-search-input__help {
  margin: 1rem 0 0;
}

.csp-search-input__scratch-link {
  margin-top: 1rem;
  margin-bottom: 0;
}

.csp-search-input__box-icon {
  color: var(--rc-color-text-accent);
}

.csp-search-input__manually-add-link {
  color: var(--rc-color-text-primary);
  margin-top: var(--rc-space-3);
}
