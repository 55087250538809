.product-preview {
  display: flex;
}

.product-preview__thumbnail {
  padding-right: 1rem;

  img {
    display: block;
    width: 6.5rem;
    height: auto;
  }
}

.product-preview__content {
  font-size: 1.4rem;
  flex: 1;
  padding-right: 1rem;
}

.product-preview__title {
  font-weight: 700;
}

.product-preview__price {
  display: block;
  margin-left: auto; // to r-align via flex
  font-weight: 700;
}
