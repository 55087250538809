@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.site-footer {
  background-color: var(--rc-color-background-footer);
  color: var(--rc-color-palette-gray-500);
  font-size: 1.4rem;

  a {
    color: var(--rc-color-palette-gray-500);
  }
}

.site-footer__wrapper {
  @include site-wrapper;
  padding-top: 4rem;
  padding-bottom: 4rem;

  // redeclare background so <96rem viewports can scroll right and still render bg
  background-color: var(--rc-color-background-footer);

  @include mobile {
    text-align: center;
  }

  .site-footer__subfooter & {
    background-color: var(--rc-color-background-subfooter);
    padding-bottom: 4rem;
  }
}

.site-footer__columns {
  display: flex;
  justify-content: space-between;

  @include tablet {
    flex-wrap: wrap;
  }

  @include mobile {
    display: block;
  }
}

.site-footer__column {
  flex: 1 1 auto;

  &:first-child {
    @include tablet {
      flex: 0 0 100%;
      text-align: center;
    }
  }
}

.site-footer__logo {
  @include scaling(margin-bottom, 4rem);

  display: block;
  width: 60%;
  max-width: 18rem;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @include tablet {
    margin-left: auto;
    margin-right: auto;
  }
}

.site-footer__region-button {
  @include rc-button.filled($inverted: true);
  position: relative;
}

.site-footer__region-button__option {
  display: inline-block;
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: solid 0.1rem var(--rc-color-palette-gray-800);

  @include mobile {
    margin-right: 0;
    padding-right: 0;
    margin-bottom: 0.65em;
    padding-bottom: 0.65em;
    display: block;
    border-right: none;
    border-bottom: solid 0.1rem var(--rc-color-palette-gray-800);
    text-align: center;
  }

  &:last-child {
    margin: 0;
    padding: 0;
    border: none;
  }

  &.site-footer__region-button__option--region {
    @include fa-icon-before(globe);
  }

  &.site-footer__region-button__option--locale {
    @include fa-icon-before(language);
  }

  &.site-footer__region-button__option--currency {
    &::before {
      display: none;
    }

    .fa {
      margin-right: 0.5em;
    }
  }
}

.site-footer__contact {
  margin: 2em 0;

  p {
    font-weight: bold;
    margin-bottom: 1em;
  }
}

.site-footer__heading {
  text-transform: uppercase;
  font-weight: bold;
  margin: 2em 2rem 1em 2rem;

  @include mobile {
    margin-bottom: 0.5em;
  }
}

.site-footer__links {
  a {
    display: block;
    transition: color .1s;
    padding: 0.2em 2rem;
    white-space: nowrap;

    &:hover {
      color: var(--rc-color-palette-bw-white);
    }
  }
}

.site-footer__subfooter {
  background-color: var(--rc-color-background-subfooter);

  @include tablet {
    text-align: center;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.site-footer__apps {
  @include responsive(float, right, none);
  @include responsive(text-align, right, inherit);
  margin-bottom: 2rem;

  li {
    display: inline-block;

    + li {
      margin-left: 2rem;
    }
  }

  a {
    @include responsive(width, 14rem, 12rem);

    display: block;
  }
}

.site-footer__social {
  margin-bottom: 1em;

  li {
    display: inline-block;
  }

  a {
    font-size: 1.6em;
    padding: 0.2em 0.5em;
  }
}

.site-footer__policy {
  @include responsive(font-size, 90%, 80%, 70%);
  color: var(--rc-color-text-secondary);
  font-weight: bold;

  li {
    @include responsive(display, inline-block, block);

    + li {
      @include responsive(margin-left, 1em, 0);
    }
  }
}

// FB embed doesn't work with our viewport scaling
.site-footer__fb-embed {
  @include mobile {
    display: none;
  }
}

.site-footer__reverb-gives {
  @include scaling(padding-top, 3rem);
  @include scaling(padding-bottom, 3rem);

  @include mobile {
    text-align: center;
  }
}

.site-footer__reverb-gives__logo {
  display: block;
  width: 18rem;

  img {
    width: 100%;
    display: block;
  }

  @include tablet {
    margin: 0 auto;
  }
}

.site-footer__reverb-gives__description {
  max-width: 50rem;
  margin: 1em 0;
  padding-right: 2em;

  @include tablet {
    padding-right: 0;
    margin: 1em auto;
  }
}

.site-footer__carbon-offset {
  display: flex;
  align-items: center;
  margin: 1em 0 1em 0.4em;

  @include tablet {
    flex-direction: column;
    margin: 2em 0;
  }
}

.site-footer__carbon-offset__icon {
  width: 3rem;
  height: 3rem;
  fill: var(--rc-color-palette-gray-500);
}

.site-footer__carbon-offset__description {
  margin-left: 1rem;
  max-width: 60rem;

  @include tablet {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}
