@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.radio-button-group {
  display: flex;

  @include tablet {
    overflow-y: hidden;
  }
}

.radio-button-group__button {
  position: relative;

  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  > label {
    @include rc-button.muted;
    border-radius: 0;
    position: relative;
  }

  &:first-child > label {
    border-top-left-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;
  }

  &:last-child > label {
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
  }

  > input:checked + label {
    background-color: var(--rc-color-palette-bw-white);
    border-color: var(--rc-color-border-primary);
    color: var(--rc-color-text-primary);
    font-weight: 700;
  }

  > input:disabled + label {
    color: var(--rc-color-text-primary-disabled);
    cursor: default;
    opacity: $button-disabled-opacity;

    &:hover {
      background: var(--rc-color-palette-gray-200);
    }
  }
}
