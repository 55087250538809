.feed-container__tabs {
  @include scaling(padding-top, 3rem);
  @include scroll-x-on-mobile;
  text-align: center;
  background: var(--rc-color-palette-bw-white);
  border-bottom: 0.1rem solid var(--rc-color-border-primary);

  ul {
    display: inline-block;
    white-space: nowrap;
  }
}

.feed-container__tab {
  display: inline-block;

  a {
    display: inline-block;
    font-weight: bold;
    padding: 1em 2em;
    font-size: 1.6rem;
    color: var(--rc-color-text-primary);
    border-bottom: 0.4rem solid transparent;
    transition: border-color .2s, color .2s;

    &:hover {
      color: var(--rc-color-palette-vu-orange-500);
      border-bottom-color: var(--rc-color-palette-vu-orange-500);
    }

    &.feed-container__tab--active {
      color: var(--rc-color-palette-vu-orange-500);
      border-bottom-color: var(--rc-color-palette-vu-orange-500);
    }

    @include mobile {
      padding: 0.5em 1em;
      font-size: 1.3rem;
    }
  }
}

.feed-container__header {
  @include scaling(padding, 2em);
  @include scaling(margin-bottom, 3em);

  background: var(--rc-color-palette-gray-300);
  text-align: center;

  h1 {
    @include scaling(margin-bottom, 0.25em);
    font-weight: bold;
    font-size: 1.6em;
  }
}

.feed-container__login {
  @include scaling(margin-top, 1.5em);
}
