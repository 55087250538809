@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
// TODO copied from core - lint this
.img-editor {
  margin-top: 2rem;
  width: 60rem;
  max-width: 100%;
}

.img-editor__editor-frame {
  padding: 1rem;
  background: var(--rc-color-palette-bw-white);
}

.img-editor__edit-actions {
  min-height: 3.1rem;
  text-align: center;
}

.img-editor__edit-action {
  display: inline-block;

  &.img-editor__edit-action--cancel {
    padding-right: 1rem;
    color: var(--rc-color-text-danger);
  }

  &.img-editor__edit-action--done {
    font-weight: bold;
  }

  &.img-editor__edit-action--rotate {
    @include rc-button.muted;

    &::before {
      @include fa-icon(rotate-right);
    }
  }

  &.img-editor__edit-action--crop {
    @include rc-button.muted;
    margin-left: 0.5em;

    &::before {
      @include fa-icon(crop);
      margin-right: 0.5em;
    }
  }
}

.img-editor__actions {
  text-align: right;
  padding: 1rem;
  background: var(--rc-color-palette-gray-100);
}

.img-editor__action {

  &.img-editor__action--close {
    @include rc-button.muted;
  }

  &.img-editor__action--save {
    @include rc-button.filled;
    margin-left: 0.5em;
  }
}

.img-editor__img-background {
  position: relative;
  border: 0.1rem solid var(--rc-color-border-primary);
  background: var(--rc-color-background-module-secondary) url('#{$discovery-ui-path}/images/products/blank_grid.jpg') center no-repeat;
  height: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  &::before {
    @include fa-icon(spinner);
    opacity: 0;
    color: var(--rc-color-text-primary);
    position: absolute;
    font-size: 4rem;
    display: block;
    animation: fa-spin 1.5s infinite linear;
    height: 4rem;
    width: 4rem;
    top: calc(50% - #{20px});
    left: calc(50% - #{20px});
    transition: opacity .2s;
    line-height: 1;
  }

  @include mobile {
    height: 25rem;
  }

  &.img-editor__img-background--loading {
    .img-editor__preview-image {
      opacity: 0.7;
    }

    &::before {
      opacity: 1;
    }
  }
}

.img-editor__preview-image,
.ReactCrop__image {
  max-width: 100%;
  max-height: 400px;
  transition: opacity .2s;

  @include mobile {
    max-height: 250px;
  }
}

// override handle styles
.img-editor {
  .ReactCrop__drag-handle {
    background: white;
    border: 0.1rem solid var(--rc-color-border-primary);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
  }

  .ReactCrop .ord-nw {
    margin-top: -1rem;
    margin-left: -1rem;
    cursor: grab;
  }

  .ReactCrop .ord-ne {
    margin-top: -1rem;
    margin-right: -1rem;
    cursor: grab;
  }

  .ReactCrop .ord-se {
    margin-bottom: -1rem;
    margin-right: -1rem;
    cursor: grab;
  }

  .ReactCrop .ord-sw {
    margin-bottom: -1rem;
    margin-left: -1rem;
    cursor: grab;
  }

  .ReactCrop__crop-selection {
    background-image: none;
    border: 0.2rem solid var(--rc-color-palette-bw-white);
    box-shadow: 0 0 0 1px var(--rc-color-palette-gray-300), 0 0 0 1px var(--rc-color-palette-gray-300) inset;
  }

  .ReactCrop {
    overflow: initial;
  }
}
