.csp2-sidebar {
  font-size: 1.6rem;

  &.csp2-sidebar--loading {
    @include loading-pulse;
  }

  @include mobile {
    margin-top: 2rem;
  }
}

.csp2-sidebar__section {
  @include responsive(margin-top, 4rem, 3rem, 2rem);

  &:first-child {
    margin-top: 0;
  }
}

.csp2-sidebar__header {
  font-size: 1.3em;
  margin-bottom: 0.5em;
}

.csp2-sidebar__description {
  background: white;
  font-size: 0.9em;
  padding: 0;
  margin-top: 1rem;

  ul {
    padding-left: 2rem;
  }

  li {
    list-style-type: disc;
    line-height: 1.3;
  }

  li + li {
    margin-top: 0.3em;
  }
}
