@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.safe-shipping-modal__link {
  a {
    text-decoration: underline;
  }

  a:link {
    color: var(--primary-text-color);
  }

  a:visited {
    color: var(--primary-text-color);
  }

  a:hover {
    color: var(--rc-color-text-secondary);
  }

  a:active {
    color: var(--primary-text-color);
  }
}
