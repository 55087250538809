@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.facet-sidebar {
  transition: opacity .3s;

  @include mobile {
    @include box-shadow;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: calc(100% + 3em);
    width: auto;
    z-index: 10001;
    background: var(--rc-color-background-module-secondary);
    margin: 0;
    padding: 1rem;
    transition: top .3s ease-in-out;

    // body class toggle
    .facet-sidebar-open & {
      top: 15vh;
    }

    .rc-drawer & {
      position: inherit;
      padding: 0;
      box-shadow: none;
      background: none;
    }
  }

  &.facet-sidebar--new {
    @include box-shadow;
    box-sizing: border-box;
    position: fixed;
    left: -30rem;
    bottom: 0;
    top: 0;
    width: 30rem;
    z-index: 10001;
    background: var(--rc-color-background-module-secondary);
    margin: 0;
    padding: 1rem;
    transition: left 0.3s ease-in-out;

    // body class toggle
    &.facet-sidebar--open {
      left: 0;
    }
  }

  &.facet-sidebar--loading {
    opacity: 0.6;
  }

  &.facet-sidebar--placeholder {
    @include loading-pulse;
  }
}

.facet-sidebar__contents {
  @include mobile {
    overflow-y: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 3vw;

    .rc-drawer & {
      position: inherit;
      padding: 0;
    }
  }
}

.facet__select {
  width: 100%;
}

// this entire set of classes are not deleted since srp_exposed_filters was removed
.facet-container {
  padding: 1.3rem;
  margin-bottom: 1.5rem;

  &.facet-container--no-margin {
    margin: 0;
  }

  @include mobile {
    border: none;
    padding: 0;
    background: none;
    box-shadow: none;
  }

  .rc-listing-grid & {
    padding: 0;
    border: none;
  }

  .rc-drawer & {
    padding: 0;
    border: none;
  }

  .facet-sidebar--placeholder & {
    position: relative;
    height: 12rem;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 1.3rem;
      top: 4rem;
      right: 1.3rem;
      height: 1em;
      border-radius: $input-border-radius;
      background: var(--rc-color-background-page-secondary);
    }

    &::after {
      margin-top: 2em;
      right: 2.6rem;
    }

    @include mobile {
      height: auto;

      &::before,
      &::after {
        display: none;
      }
    }
  }
}

.facet {
  margin-bottom: 2rem;
  font-size: var(--rc-font-size-400);

  &:last-of-type {
    margin-bottom: 0;
  }

  &.facet--collapsible {
    margin: 0;
    padding: 1rem 0;
    border-bottom: solid 0.1rem var(--rc-color-border-primary);
  }

  .rc-listing-grid & {
    border-top: solid 0.1rem var(--rc-color-border-primary);
    border-bottom: none;

    &:first-child {
      @include tablet {
        border-top: none;
      }
    }

    &:last-child {
      border-bottom: solid 0.1rem var(--rc-color-border-primary);
    }
  }
}

.facet__heading {
  font-weight: bold;
  font-size: 90%;
  margin-bottom: 0.5em;

  .rc-drawer & {
    font-size: 1.8rem;
    font-weight: normal;
    padding: 1rem 0 0;
  }

  .facet--collapsible & {
    font-weight: bold;
  }
}

.facet__options {
  .facet__options {
    margin-left: 1rem;
  }
}

.facet__option {
  margin-top: var(--rc-space-1);

  input[type='checkbox'] {
    margin-right: 0.5em;
    margin-bottom: 0;
  }

  // Don't display this facet in the filterable
  // feed grid b/c it does nothing
  &.facet__option--show_only_sold {
    .filterable-feed-wrapper & {
      display: none;
    }
  }

  &.facet__option--disabled {
    opacity: 0.5;
  }

  &.facet__option--checkbox {
    padding-left: 1.5em;

    input[type='checkbox'] {
      margin-left: -1.5em;
    }
  }

  &:nth-child(n+7) {
    transition: opacity .2s;
    opacity: 1;
  }

  .collapsing-facet--collapsed > .facet__options > & {
    &:nth-child(n+7) {
      &:not(.facet__option--active) {
        max-height: 0;
        overflow: hidden;
        margin: 0;
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  @include mobile {
    margin-top: 0.5em;
  }
}

.facet__option__count {
  display: inline-block;
  color: var(--rc-color-text-primary);
  font-size: 90%;
  opacity: 0.5;

  .facet__option--active & {
    font-weight: normal;
  }
}

.collapsing-facet__toggle,
.facet__toggle {
  @include button-as-link;
  @include fa-icon-after(angle-up);
  display: block;
  margin-top: 0.5em;
  font-size: 1.2rem;
  color: var(--rc-color-text-primary);

  .collapsing-facet--collapsed > & {
    &::after {
      content: fa-icon-content(angle-down);
    }
  }

  .rc-popover &,
  .rc-drawer & {
    font-size: 1.4rem;
  }

  .facet & {
    .facet & {
      padding-left: 1rem;
      margin-top: 0.3em;
      margin-bottom: 0.5em;
    }
  }
}

.facet__link {
  &[data-disabled] {
    pointer-events: none;
    cursor: default;
  }
}

.facet__label,
.facet__link {
  display: inline-block;
  color: var(--rc-color-text-primary);
  font-weight: normal;
  transition: color .2s;
  cursor: pointer;
  margin: 0;

  .facet__option--active > & {
    font-weight: bold;
  }

  .facet__option--disabled > &:hover {
    color: var(--rc-color-text-primary);
  }

  .facet__option--child-active > & {
    font-weight: bold;

    @include mobile {
      font-weight: normal;
    }
  }
}

.facet__link__text,
.facet__label__text {
  @include hyphenate;

  hyphens: auto;
  margin-right: 0.2em;
  font-size: 1.4rem;
}

.facet__link__text__new-tag {
  padding: 0.2rem 0.4rem;
  border-radius: 0.3rem;
  margin-left: 0.5rem;
  background-color: var(--rc-color-sentiment-negative-dark);
  font-weight: bold;
  text-transform: uppercase;
  color: var(--rc-color-palette-bw-white);
  font-size: 70%;
  letter-spacing: 0.05em;
}

.facet__label--active,
.facet__link--active {
  color: var(--rc-color-text-accent);
  font-weight: bold;
}

.facet__link--back {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 0.2em;
}

// TODO phase these out for .input-group
.facet__single-input {
  display: flex;
  width: 100%;
}

.facet__single-input__field {
  flex: 1 1 auto;
  box-sizing: border-box;
  padding-right: 0.3rem;

  input {
    width: 100%;
    margin: 0;
  }
}

.facet__single-input__submit {
  flex: 0 1 auto;

  button {
    @include rc-button.muted;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    height: 100%;

    &::after {
      @include fa-icon(chevron-right);
    }
  }
}

.facet__dual-input {
  display: flex;
  width: 100%;
}

.facet__dual-input__field {
  position: relative;
  flex: 1;
  width: 4rem;
  margin-right: 0.25em;

  &:first-of-type {
    margin-right: 1em;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: calc(100% + 0.25em);
      top: 50%;
      margin-top: -0.1rem;
      width: .5em;
      height: 0.2rem;
      background: var(--rc-color-palette-gray-300);
    }
  }

  input {
    width: 100%;
    margin: 0;
  }
}

.facet__dual-input__submit {
  flex: 1;

  button {
    @include rc-button.muted;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-width: 0.1rem;
    height: 100%;

    &::after {
      @include fa-icon(chevron-right);
    }
  }
}

.facet__placeholder__value {
  padding-right: 0.5em;

  &.facet__placeholder__value--loading {
    @include loading-pulse;
  }

  .rc-popover &,
  .rc-drawer & {
    font-size: 110%;
  }
}

.facet__placeholder__button {
  @include button-reset;
  color: var(--rc-color-palette-blue-600);
  transition: color .2s;
  font-size: 80%;
}
