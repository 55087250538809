.sell-form-inputs__header__button__row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 2rem;

  @include mobile {
    display: none;
  }
}

.sell-form-inputs__header__buttons__main {
  & button, & a {
    margin-left: 2rem;
  }
}
