@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.page-hero {
  @include commons-svg-background('instruments-darken.svg');
  @include scaling((padding-top padding-bottom), 8rem);

  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
  background-color: var(--rc-color-palette-blue-600);
  width: 100%;
  color: var(--rc-color-palette-bw-white);
  text-align: center;
  box-sizing: border-box;
  text-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.3);
  font-weight: 700;
  background-repeat: repeat;

  &.page-hero--photo-fill {
    background-color: var(--rc-color-palette-gray-900);
    background-image: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.page-hero--with-search {
    @include scaling((padding-top padding-bottom), 5rem);
  }

  &.page-hero--orange {
    background-color: var(--rc-color-palette-vu-orange-500);
  }

  &.page-hero--no-bg {
    @include site-wrapper;
    @include scaling(padding-top, 5rem);
    @include scaling(padding-bottom, 2.5rem);

    color: var(--rc-color-text-primary);
    background: none;
    text-shadow: none;
    text-align: left;
  }
}

.page-hero__title {
  font-size: 4rem;
  border-bottom: solid 0.2rem var(--rc-color-palette-bw-white);
  padding-top: 1rem;
  padding-bottom: 0.5em;
  display: inline-block;

  @include mobile {
    font-size: 2.8rem;
  }

  .page-hero--no-bg & {
    @include rc-text.display;

    border-bottom: none;
    padding-bottom: 0;
  }

  .page-hero--with-search & {
    border-bottom: none;
    padding-bottom: 0;
    font-weight: bold;
  }
}

.page-hero__subtitle {
  font-size: 2.4rem;
  margin: 1rem auto 0;
  max-width: 60rem;

  @include mobile {
    font-size: 1.8rem;
  }
}

.page-hero__search {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}

.page-hero__search-action {
  text-align: right;

  @include mobile {
    text-align: center;
  }

  a {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    @include svg.icon-after(arrow-right, scss-tokens.$rc-color-palette-bw-white);

    font-weight: bold;
    color: white;
  }

  .page-hero--no-bg & {
    a {
      @include svg.icon-after(arrow-right, $as-link: true);

      font-weight: bold;
      color: var(--rc-color-palette-blue-600);

      @include responsive-hover {
        color: var(--rc-color-palette-vu-orange-500);
      }
    }
  }
}
