@use '../tools/mixins/rc-button';
@use '../tools/mixins/rc-text';

.rc-chip {
  // we're inheriting outlined here because it contains a border
  // and this ensures a consistent height
  @include rc-button.muted($size: small);
  @include rc-text.utility('350', 'semibold');

  border-width: var(--rc-border-width-chip);
  border-radius: var(--rc-border-radius-chip);

  gap: var(--rc-space-2);
  padding-left: var(--rc-padding-lr-chip);
  padding-right: var(--rc-padding-lr-chip);

  &[aria-pressed='true'] {
    border-color: var(--rc-color-button-main-primary);
  }

  &[aria-haspopup='dialog'],
  &[aria-haspopup='menu'],
  &[aria-haspopup='true'] {
    padding-right: var(--rc-padding-r-chip-mini);
  }

  // small buttons render a remove icon that needs less RH padding
  &.rc-chip--small {
    &[aria-pressed='true'] {
      padding-right: var(--rc-padding-r-chip-mini);
    }
  }

  &.rc-chip--icon-only {
    padding-left: var(--rc-padding-tb-button-small);
    padding-right: var(--rc-padding-tb-button-small);
  }

  &.rc-chip--mini {
    @include rc-button.size('mini');

    &.rc-chip--icon-only {
      padding-left: var(--rc-padding-tb-button-mini);
      padding-right: var(--rc-padding-tb-button-mini);
    }
  }

  &.rc-chip--loading {
    opacity: 0.6;
  }
}

.rc-chip__remove,
.rc-chip__caret {
  display: inline-flex;
  fill: var(--rc-color-text-primary);

  svg {
    // fill: var(--rc-color-text-chip);
    width: var(--rc-space-6);
    height: var(--rc-space-6);
  }
}

.rc-chip__remove {
  .rc-chip--mini & {
    display: none;
  }
}

.rc-chip__caret {
  [aria-expanded='true'] & {
    transform: rotate(180deg);
  }
}
