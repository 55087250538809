.shipping-cost-estimator {
  @include grid-container;
  display: flex;
  flex-wrap: wrap-reverse;
}

.shipping-cost-estimator__form {
  @include grid-column(7);
  padding: 2rem;

  @include mobile {
    @include grid-column(12);
  }
}

.shipping-cost-estimator__tabs {
  margin-bottom: 2rem;
}

.shipping-cost-estimator__package__row {
  @include grid-container;
}

.shipping-cost-estimator__package__input {
  @include grid-column(4);
  margin-bottom: 1rem;

  @include mobile {
    @include grid-column(12);
  }
}

.shipping-cost-estimator__example__container {
  @include grid-column(5);
  padding: 2rem;

  @include mobile {
    @include grid-column(12);
  }
}

.shipping-cost-estimator__example__box {
  border: 0.1rem solid var(--rc-color-border-primary);
  padding: 1.5rem 1rem;

  @include mobile {
    text-align: center;
  }

  &.shipping-cost-estimator__example__box--centered {
    text-align: center;
  }

  & + .shipping-cost-estimator__example__box {
    border-top: none;
  }

  button {
    margin-top: 1rem;
  }
}

.shipping-cost-estimator__results {
  @include grid-column(12);
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.4rem;
}

.shipping-cost-estimator__rsl__text {
  margin-top: 2rem;
  opacity: 0.6;
}
