@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow';

// Use same value from item2.scss
$breakpoint-single-column: 750px;

.item2-sticky-sidebar {
  position: relative;
  flex: 1;
  z-index: 2;
}

.item2-sticky-sidebar__content {
  padding: var(--rc-space-gap-grid);
  margin-top: calc(-1 * var(--rc-space-gap-grid));
  border-radius: var(--rc-border-radius-primary);
  box-shadow: none;
  background-color: transparent;
  transition: box-shadow 0.2s ease-in-out, background-color 0.2s;

  @include max-width($breakpoint-single-column) {
    padding: 0;
  }

  .item2-sticky-sidebar--sticky & {
    position: sticky;
    top: 2rem;

    @include max-width($breakpoint-single-column) {
      position: unset;
    }
  }

  .item2-sticky-sidebar--stuck & {
    @include box-shadow.box-shadow;
    background-color: var(--rc-color-background-elevated);

    margin-top: 0;

    @include max-width($breakpoint-single-column) {
      box-shadow: none;
    }
  }
}
