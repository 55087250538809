@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.cart-item-protection-plan {
  display: flex;
  padding: var(--rc-space-4);
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;
  align-self: stretch;

  border-radius: var(--rc-border-radius-md);
  background: var(--rc-color-background-alert-plain);

  .cart-item-protection-plan__checkbox-wrapper {
    display: flex;
    align-items: flex-start;
    gap: var(--rc-space-3);
    align-self: stretch;
  }

  .cart-item-protection-plan__checkbox {
    display: flex;
    align-items: flex-start;
    gap: var(--rc-space-2);
    flex: 1 0 0;
    border-radius: 0.8rem;
  }

  .cart-item-protection-plan__checkbox--loading {
    @include loading-pulse;
  }

  .cart-item-protection-plan__price {
    display: flex;
    align-items: baseline;
    gap: 0.4rem;
    color: var(--rc-color-text-primary);
  }

  .cart-item-protection-plan__highlights {
    display: flex;
    padding-left: 2.7rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
    align-self: stretch;
  }

  .cart-item-protection-plan__summary {
    @include rc-text.fine('300', 'bold');
    color: var(--rc-color-text-secondary);

    // overrides the display: block from
    // https://github.com/reverbdotcom/frontend/blob/f0c12bfa758303ae6fa73b627703f5c6e05600f5/packages/cadence/styles/classes-global/reset.scss#L75 that hides the triangle
    display: list-item;
  }

  .cart-item-protection-plan__points {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
    align-self: stretch;
    padding-top: 0.8rem;

    div {
      display: flex;
      align-items: flex-start;
      gap: 0.8rem;
      align-self: stretch;
    }
  }

  .cart-item-protection-plan__learn-more {
    @include rc-text.fine('300');
    width: 24rem;
    color: var(--rc-color-text-secondary);

    text-decoration-line: underline;
  }

  .cart-item-protection-plan__label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--rc-space-1);
    flex: 1 0 0;
  }

  .cart-item-protection-plan__change-plan {
    @include button-as-link;
    @include rc-text.fine('300', 'bold');

    flex: 1 0 0;
    color: var(--rc-color-text-link);
    display: flex;
    align-items: flex-start;
    align-self: stretch;
  }
}

.protection-plan__provider-logo {
  display: flex;
  align-items: flex-end;
  gap: var(--rc-border-width-button);

  img {
    display: flex;
    padding: 0.1rem .15rem 0.15rem;
    height: 100%;
    max-width: 5.5rem;

    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 0.8rem;
  }
}
