@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.dynamic-page {
  // redeclaring these variables so a data-theme attribute on this element sets them properly
  // (see the darkModeReady boolean in DynamicPage.tsx)
  color: var(--rc-color-text-primary);
  background: var(--rc-color-background-page);

  // for admin preview modal
  .reverb-modal & {
    font-size: 1.6rem;
  }

  &.dynamic-page--csp,
  &.dynamic-page--article,
  &.dynamic-page--crate {
    background: var(--rc-color-background-page);
  }
}

.dynamic-page__content {
  @include scaling(padding-top, $dynamic-page-padding-top);
  @include scaling(padding-bottom, $dynamic-page-padding-bottom);

  .dynamic-page--flush-with-header & {
    padding-top: 0;
  }

  .dynamic-page--top-products & {
    padding-top: 3rem;

    @include mobile {
      padding-top: 0;
    }
  }

  .dynamic-page--csp & {
    padding-bottom: 0;
  }

  .dynamic-page--listing & {
    padding-bottom: 0;
    padding-top: var(--rc-space-gap-grid);
  }
}

.dynamic-page__two-col {
  @include site-wrapper;

  display: flex;
  background: none;
  align-items: flex-start;
  justify-content: space-between;

  @include mobile {
    display: block;
  }
}

.dynamic-page__two-col__main {
  --column-gap: calc(2 * var(--rc-space-gap-grid));

  width: calc(100% - var(--column-gap) - 25rem);

  @include tablet {
    width: calc(100% - var(--column-gap) - 20rem);
  }

  @include mobile {
    width: auto;
  }

  .dynamic-page--csp & {
    width: calc(100% - #{$grid-gutter} - 36rem);

    @include tablet {
      width: calc(100% - #{$grid-gutter} - 25rem);
    }

    @include mobile {
      width: auto;
    }
  }

  .dynamic-page--top-products & {
    width: calc(100% - #{$grid-gutter} - 22rem);

    @include tablet {
      width: calc(100% - #{$grid-gutter} - 18rem);
    }

    @include mobile {
      width: auto;
      padding-top: 2rem;
    }
  }

  .dynamic-page--listing & {
    width: 62%;
    order: 1;

    @include tablet {
      width: calc(100% - #{$grid-gutter} - 30rem);
    }

    @include mobile {
      width: auto;
    }
  }
}

.dynamic-page__one-col {
  margin-top: var(--space-gap-row-responsive);

  &:first-child {
    margin-top: 0;
  }
}

.dynamic-page__sidebar {
  flex: 0 0 25rem;
  box-sizing: border-box;
  font-size: 1.6rem;

  @include tablet {
    flex: 0 0 20rem;
  }

  @include mobile {
    display: block;
  }

  .dynamic-page--top-products & {
    flex: 0 0 22rem;

    @include tablet {
      flex: 0 0 18rem;
    }
  }

  .dynamic-page--csp & {
    flex: 0 0 34rem;

    @include tablet {
      flex: 0 0 25rem;
    }
  }

  .dynamic-page--listing & {
    order: 2;
    flex: 0 0 34%;
    font-size: 1.6rem;
    max-width: 34%;
    margin-left: auto;

    @include tablet {
      flex: 0 0 30rem;
      max-width: none;
    }

    @include mobile {
      margin-bottom: 2rem;
    }
  }
}

.dynamic-page__sidebar__bottom,
.dynamic-page__sidebar__top,
.dynamic-page__sidebar-mobile-overflow {
  margin-bottom: 2rem;
}

.dynamic-page__sidebar-mobile-overflow {
  @include gap-row;
  display: none;

  @include mobile {
    display: block;
  }
}

.dynamic-page__sidebar__bottom {
  @include mobile {
    display: none;
  }
}

.page-edit-link {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  opacity: 0.7;
  transition: opacity .2s;

  &:hover {
    opacity: 1;
  }

  a {
    @include rc-button.filled($theme: 'danger', $size: 'small');
  }
}
