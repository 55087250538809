@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.item2-shop {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.item2-shop__primary {
  flex: 1 0 auto;
  padding-right: 1rem;
  max-width: 100%;
  box-sizing: border-box;
}

.item2-shop__secondary {
  flex: 1;
  flex-wrap: wrap;
  text-align: right;
  display: flex;
  margin: 0.5rem -0.5rem;

  &.item2-shop__secondary--wide {
    flex: 1 0 100%;
  }
}

.item2-shop__header {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  opacity: 0.7;
}

.item2-shop__button-container {
  margin: 0.5rem;
  flex: 1;

  & .item2-shop__button--featured-listing {
    @include rc-button.muted($size: 'small');
    width: 100%;
  }
}
