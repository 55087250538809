$card-width: 3rem;

.cc-input-with-icon {
  position: relative;
}

.cc-input-with-icon__input {
  input,
  .adyen-field {
    padding-left: calc(#{$card-width} + #{$input-padding-horizontal}); // card width + input padding
  }
}

.cc-input-with-icon__icon {
  position: absolute;
  left: $input-padding-horizontal;
  top: 50%;
  transform: translateY(-50%);
  width: $card-width;
}
