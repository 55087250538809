.rc-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  border-radius: 50%;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  &.rc-avatar--initials {
    background-color: var( --rc-color-palette-gray-600);
  }
}

.rc-avatar__thumbnail {
  display: block;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 50%;
}

.rc-avatar__initial {
  font-weight: bold;
  color: var( --rc-color-palette-bw-white);
  line-height: 1;
  font-size: 4rem;
}
