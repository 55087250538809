$product-bump-views-scale-rotation: -5.427deg;

.product-bump__admin-bump-header {
  @include scaling(margin-bottom, 2rem);

  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: var(--rc-color-palette-vu-orange-500);
}

.product-bump__wrapper {
  @include grid-container;
}

.product-bump__overview-col {
  @include grid-columns(6, 12, 12);

  @include mobile {
    margin-bottom: 2rem;
  }
}

// For experiment BUMP_ALT_MARKETING_CONTENT
.product-bump__overview-title {
  display: flex;
  align-items: center;

  img {
    display: block;
    width: 2.4rem;
    height: auto;
  }

  p {
    font-weight: 700;
    color: var(--rc-color-palette-vu-orange-500);
    margin-bottom: 0;
    margin-left: 1rem;
    size: 2rem;
  }
}

// For experiment BUMP_ALT_MARKETING_CONTENT
.product-bump__overview-subtitle {
  margin: 1rem 0 0;
  font-size: 1.8rem;
}

// For experiment BUMP_ALT_MARKETING_CONTENT
.product-bump__overview-description {
  margin: 0;
}

.product-bump__overview-col__grid-wrap {
  @include grid-container;
  display: flex;
}

.product-bump__overview-col__grid-wrap__icon {
  @include grid-columns(3, 3, 3);

  @include mobile {
    display: none;
  }
}

.product-bump__overview-col__marketing-wrap {
  @include grid-columns(8, 8, 12);
  @include responsive(padding-left, 1rem, 1rem, 0);
}

.product-bump__overview-col__heading {
  font-size: 2rem;
  font-weight: bold;
  color: var(--rc-color-palette-vu-orange-500);
  margin-bottom: 0.4rem;

  @include mobile {
    text-align: center;
  }
}

.product-bump__overview-col__subheading {
  @include scaling(margin-bottom, 15px);
  font-size: 1.4rem;

  @include mobile {
    text-align: center;
  }
}

.product-bump__overview-col__benefits-list {
  @include scaling(margin-bottom, 1rem);
  font-size: 1.4rem;
  margin-left: 1.2em;
}

.product-bump__overview-col__benefits-list__item {
  position: relative;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    position: absolute;
    left: -1.2em;
  }
}

.product-bump__slider-col {
  @include grid-columns(6, 12, 12);
}

.product-bump__slider-col__label {
  font-weight: bold;
  line-height: 1;
  font-size: 1.4rem;
  padding: 0.5rem 0;

  @include mobile {
    margin-bottom: 1rem;
    max-width: 80%;
  }
}

.product-bump__overview-col__stat-heading {
  font-weight: bold;
  text-align: center;
  font-size: 120%;
  margin-bottom: 2rem;
}

.product-bump__overview-col__stats {
  text-align: center;
  font-weight: bold;

  @include mobile {
    font-size: 80%;
  }

  li {
    @include scaling(margin-left, 2rem);
    @include scaling(margin-right, 2rem);
    display: inline-block;
  }
}

.product-bump__overview-col__stats__stat {
  font-size: 190%;
}

.product-bump__overview-col__stats__label {
  font-size: 90%;
  color: var(--rc-color-text-secondary);
}

.product-bump__views-scale {
  position: relative;
  margin-bottom: 1rem;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.product-bump__scale-img {
  padding: 0 1rem;
}

.product-bump__scale-img__inner {
  height: 0;
  padding-bottom: 10%;
  position: relative;

  svg {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;

    .bump-bar-graphic__highlight {
      fill: var(--rc-color-palette-vu-orange-500);
    }

    .bump-bar-graphic__background {
      fill: var(--rc-color-palette-gray-200);
    }
  }
}

.product-bump__views-scale__more {
  @include responsive(font-size, 70%, 65%, 55%);
  position: absolute;
  bottom:  24%;
  left: 10%;
  font-weight: bold;
  transform: rotate(#{$product-bump-views-scale-rotation});
}

.product-bump__views-experiment__less {
  @include responsive(font-size, 70%, 65%, 55%);
  position: absolute;
  bottom:  24%;
  left: 10%;
  font-weight: bold;
}

.product-bump__views-experiment__much-more {
  @include responsive(font-size, 70%, 65%, 55%);
  position: absolute;
  right: 2%;
  bottom: 100%;
  font-weight: bold;

  @include mobile {
    max-width: 7.2rem;
    text-align: right;
  }
}

.product-bump__views-scale__most-popular {
  font-size: 60%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 20%;
  font-weight: bold;
  line-height: 1.2;
  color: var(--rc-color-palette-bw-white);
  max-width: 140px;
  text-align: center;

  @include mobile {
    transform: rotate(#{$product-bump-views-scale-rotation}) translateX(-50%);
    color: var(--rc-color-palette-vu-orange-500);
    max-width: 130px;
  }

  &.product-bump__views-scale__most-popular--new,
  &.product-bump__views-scale__most-popular--used {
    left: 64.5%;

    @include mobile {
      bottom: 86%;
      font-size: 50%;
    }
  }
}

.product-bump__views-scale__much-more {
  @include responsive(font-size, 70%, 65%, 55%);
  position: absolute;
  right: 2%;
  bottom: 100%;
  font-weight: bold;
  transform: rotate(#{$product-bump-views-scale-rotation});

  @include mobile {
    max-width: 7.2rem;
    text-align: right;
  }
}

.bump-charges {
  margin-top: 1rem;
}

.bump-charges__heading {
  font-size: 1.2rem;
  font-weight: bold;
}

.bump-charges__description {
  font-size: 1.2rem;
  line-height: 1.4;
  margin-bottom: 0;
}

.mini-bump-stats {
  margin-top: 0.5rem;
}

.mini-bump-stats .tooltip {
  display: block;
}

.mini-bump-meter {
  text-align: right;
  height: 1.6rem;
  cursor: default;

  &.mini-bump-meter--new-bump {
    height: initial;
  }

  .bump-title {
    text-align: left;
    color: var(--rc-color-text-primary);
    font-size: 1rem;
    text-transform: uppercase;
    line-height: 0.9;
    font-weight: bold;
  }

  .progress-meter {
    cursor: default;
    border: none;
    height: 0.5rem;
    background-color: var(--rc-color-background-progressbar-track);
    border-radius: 0;
    padding: 0;
  }
}
