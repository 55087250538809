.newsletter-signup-module {
  @include scaling(padding, 4rem);
  @include gap-row;

  text-align: center;
  background: var(--rc-color-background-page);
  box-sizing: border-box;

  @include mobile {
    @include viewport-bleed;
  }

  &.newsletter-signup-module--offwhite {
    border: 0.1rem solid var(--rc-color-border-primary);
    background: var(--rc-color-background-page-secondary);
  }

  &.newsletter-signup-module--blue {
    @include commons-svg-background('instruments-darken.svg');

    background-color: var(--rc-color-palette-blue-600);
    border: none;
    background-position: center;
    color: var(--rc-color-palette-bw-white);
  }

  &.newsletter-signup-module--orange {
    @include commons-svg-background('instruments-darken.svg');

    background-color: var(--rc-color-palette-vu-orange-500);
    border: none;
    background-position: center;
    color: var(--rc-color-palette-bw-white);
  }

  .dynamic-page__one-col & {
    @include viewport-bleed;

    border-left: none;
    border-right: none;
  }

  // safety measure
  .dynamic-page__sidebar & {
    display: none;
  }
}

.newsletter-signup-module__inner {
  .dynamic-page__one-col & {
    @include site-wrapper;
  }
}
