@mixin placeholder-text($height: 80%, $width: 100%) {
  display: inline-block;
  background: var(--rc-color-palette-gray-100);
  font-size: $height;
  width: $width;
  border-radius: 0.5em;

  &::after {
    content: '\00a0';
  }
}

