.rc-info-box__icon {
  flex: 0 1 auto;
  display: flex;
  max-height: var(--rc-min-height-button-medium); // allow for centering on a cta

  @include mobile {
    display: block;
    max-height: unset;
  }
}
