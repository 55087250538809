%image-box-base {
  height: 0;
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    // hacky but avoids browsers rounding down
    max-width: calc(100% + 0.2rem);
    max-height: calc(100% + 0.2rem);
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
  }
}
