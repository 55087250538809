.bulk-sale-form__rows {
  &.bulk-sale-form__rows--featured {
    margin: 0 -3rem;
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: var(--rc-color-palette-vu-orange-100);
  }
}

.bulk-sale-form__count-alert {
  @include scaling(margin-bottom, 2rem);
}

.bulk-sale-form__filters {
  border-bottom: solid 0.1rem var(--rc-color-border-primary);
}

.bulk-sale-form__empty {
  text-align: center;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bulk-sale-form__empty__title {
  font-size: 1.8rem;
}

.bulk-sale-form__empty__link {
  margin-top: 2rem;
}
