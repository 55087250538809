@use '../tools/mixins/rc-button';
@use '../config/configuration' as config;

// These class names are here to support legacy button UIs that are created that we can't update (ie inside the CMS).
// Everything here should be powered by our modern buttons but mapped to legacy class names for backwards and forwards support.

.button {
  @include rc-button.muted;

  // This is our new RCButton token-based button.
  // It will eventually include the other variants (like "secondary", "tertiary", etc.),
  // but for now it's just "primary" that's available.
  //
  // This also chains blue, green, orange and gray to make CMS pages comply without needing to
  // alter the markup manually for every page.
  &.button--primary,
  &.button--blue,
  &.button--green,
  &.button--orange,
  &.button--gray,
  &.button--branding {
    @include rc-button.colors($theme: 'main', $variant: 'filled', $inverted: false);
  }

  &.button--red {
    @include rc-button.colors($theme: 'danger', $variant: 'filled', $inverted: false);

    &.button--outline {
      @include rc-button.colors($theme: 'danger', $variant: 'outlined', $inverted: false);
    }
  }

  &.button--offwhite {
    @include rc-button.colors($theme: 'main', $variant: 'muted', $inverted: false);
  }

  &.button--glass {
    @include rc-button.colors($theme: 'main', $variant: 'outlined', $inverted: true);

    --background-color: var(--rc-color-highlight-100);
  }

  &.button--large {
    @include rc-button.size('large');
  }

  &.button--small {
    @include rc-button.size('small');
  }

  &.button--mini {
    @include rc-button.size('mini');
  }
}
