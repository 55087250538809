@use '../tools/mixins/rc-button';

.input-group {
  display: flex;

  > :first-child,
  > [type]:first-child,
  > :first-child select {
    border-top-left-radius: var(--rc-border-radius-input);
    border-bottom-left-radius: var(--rc-border-radius-input);
  }

  > :last-child,
  > [type]:last-child,
  > :last-child select,
  > :last-child::before {
    border-top-right-radius: var(--rc-border-radius-input);
    border-bottom-right-radius: var(--rc-border-radius-input);
  }

  > :focus-visible {
    z-index: 3; // so focus outline sits on top of prepend/append
  }
}

.input-group__text {
  display: flex;
  align-items: center;
  background-color: var(--rc-color-background-tag);
  padding: var(--rc-space-1) var(--rc-space-2);
  border: solid 0.1rem var(--rc-color-border-input-primary);
  font-size: 1.4rem;
  line-height: 1.2;
}

.input-group__input {
  flex: 1;
  min-width: 0; // prevents wide-rendering bug in Firefox

  &:focus-visible {
    z-index: 3; // so focus outline sits on top of prepend/append
  }

  &[type] {
    border-radius: 0;
  }
}

.input-group__button {
  @include rc-button.filled($inverted: true);
  border-color: var(--rc-color-border-input-primary);
  border-width: 0.1rem;
  min-height: var(--rc-size-height-input);
  min-width: var(--rc-size-height-input);

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--rc-border-radius-input);
    border-bottom-right-radius: var(--rc-border-radius-input);
  }
}

.input-group__select {
  @include styled-dropdown;
  flex: 1;

  select {
    // prevents weird corner clipping
    height: 100%;
    border-radius: 0;
  }
}

.input-group__text,
.input-group__input,
.input-group__select,
.input-group__button {
  border-radius: 0;
  margin: 0;
  margin-right: calc(var(--rc-space-px) * -1);
}
