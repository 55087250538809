@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

.feed-onboarding {
  text-align: center;
}

.feed-onboarding__intro {
  font-size: 1.2em;
  margin-bottom: 4rem;
}

.feed-onboarding__sets {
  @include site-wrapper;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;

  @include mobile {
    display: block;
  }
}

.feed-onboarding__set {
  flex: 0 0 25%;
  padding: 0 3rem;
  box-sizing: border-box;
  margin-bottom: 4rem;
}

.feed-onboarding__set__image {
  padding-bottom: 100%;
  height: 0;
  position: relative;
  margin-bottom: 1em;
  border-radius: 50%;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }

  @include mobile {
    width: 60%;
    padding-bottom: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}

.feed-onboarding__set__title {
  @include truncate-text.truncate-text(
    $font-size: 1.4rem,
    $line-height: 1.4,
    $lines-to-show: 2,
    $fixed-height: true
  );

  font-weight: bold;
  color: var(--rc-color-text-primary);
  margin-bottom: 0.5em;

  @include mobile {
    height: auto;
    -webkit-line-clamp: none;
  }
}

.feed-onboarding__visit {
  margin-bottom: 4rem;

  a {
    @include rc-button.filled;
  }
}

.feed-container__onbarding-guide-wrapper {
  @include site-wrapper;
  @include scaling(margin-top, 2rem);
  @include scaling(margin-bottom, 3rem);
}

.feed-container__login {
  @include scaling(margin-top, 1.5em);

  .feed-onboarding--experiment & {
    @include scaling(margin-top, 1.25em);
    @include scaling(margin-bottom, 1.25em);
    font-size: 1.6rem;
  }
}

.feed-container__onboarding-guide {
  @include scaling((padding-left padding-right), 3em);
  @include scaling(border-radius, 0.3rem);
  background: var(--rc-color-palette-bw-white);
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1.5em;
}

.feed-container__onboarding-guide__title {
  font-size: 1.4em;
  margin-bottom: 0.25em;
  font-weight: bold;
}

.feed-container__onboarding-guide__subtitle {
  font-size: 1.1em;
  margin-bottom: 1em;
}

.feed-container__onboarding-guide__details {
  display: flex;

  @include mobile {
    display: block;
  }
}

.feed-container__onboarding-guide__detail {
  margin-right: 8%;

  &:last-of-type {
    margin-right: 0;
  }

  @include mobile {
    margin-right: 0;

    &:first-of-type {
      margin-bottom: 2rem;
    }

    &:last-of-type {
      display: none;
    }
  }
}

.feed-container__onboarding-guide__hint {
  font-size: 0.9em;
}

.feed-container__onboarding-guide__icon {
  margin: 0 auto 1em;
  max-width: 8rem;
  max-height: 8rem;
}
