$verification-table-borders: solid 0.1rem var(--rc-color-border-primary);

.verification-table {
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin: 0.8rem 0;
    font-size: 1.4rem;
  }

  td {
    vertical-align: top;
    padding: 1.2rem;
    border-right: $verification-table-borders;
    border-bottom: $verification-table-borders;
    background-color: var(--rc-color-palette-gray-100);
  }

  // first column cells
  td:first-child {
    font-weight: bold;
    width: 30%;
    padding-right: 1.6rem;
    border-left: $verification-table-borders;
    white-space: nowrap;
  }

  // first row cells
  tr:first-child td {
    border-top: $verification-table-borders;
  }

  // top left cell
  tr:first-child td:first-child {
    border-top-left-radius: 0.4rem;
  }

  // top right cell
  tr:first-child td:last-child {
    border-top-right-radius: 0.4rem;
  }

  // bottom right cell
  tr:last-child td:last-child {
    border-bottom-right-radius: 0.4rem;
  }

  // bottom left cell
  tr:last-child td:first-child {
    border-bottom-left-radius: 0.4rem;
  }
}
