.routed-tabs-wrapper {
  @include site-wrapper;
  @include scaling(margin-bottom, 2rem);

  @include mobile {
    padding-left: 0; // so the tabs can scroll on mobile
    padding-right: 0;
  }
}

.routed-tabs {
  display: flex;
  border-bottom: 0.2rem solid var(--rc-color-border-primary);

  @include mobile {
    display: block;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.routed-tabs__tab {
  @include mobile {
    display: inline-block;
    max-width: initial;
  }
}

.routed-tabs__tab__link {
  box-sizing: border-box;
  display: block;
  padding: 1em 2em;
  text-align: center;
  border-bottom: 0.2rem solid transparent;
  color: var(--rc-color-text-primary);
  transition: border-color .1s;
  margin-bottom: -0.2rem;
  font-size: 1.8rem;
  font-weight: bold;

  &.routed-tabs__tab__link--active,
  &:hover {
    border-bottom-color: var(--rc-color-border-accent);
  }

  @include mobile {
    padding: 0.5em 0.75em;
    margin-bottom: 0;
    font-size: 1.4rem;
  }
}

.routed-tabs__tab__link__count {
  color: var(--rc-color-text-secondary);
  font-weight: normal;
}
