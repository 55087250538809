@keyframes popin {
  0% {
    transform: scale(0); opacity: 0;
  }
  80% {
    transform: scale(1.1); opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bar-fill {
  0% {
    width: 0;
    transform: translate3d(0, 0, 0);
  }
  100% {
    width: 100%;
    transform: translate3d(0, 0, 0);
  }
}

.step-progress-bar {
  text-align: center;
  font-weight: bold;
  padding-top: 2rem;
  padding-bottom: 5rem;

  @include mobile {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  .in-mobile-app & {
    margin-bottom: 2rem;
  }
}

.step-progress-bar__step {
  position: relative;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  margin: 0.3rem;
  background: var(--rc-color-background-progressbar-track);
  transition: all 0.2s ease-in-out;

  &.step-progress-bar__step--current,
  &.step-progress-bar__step--finished {
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 1.4rem;
      height: 1.4rem;
      border-radius: 50%;
    }
  }

  &.step-progress-bar__step--current {
    &::before {
      background: var(--rc-color-text-primary);
      animation: popin 0.5s 0.5s ease-in-out 1 forwards;
      transform: scale(0);
    }
  }

  &.step-progress-bar__step--finished {
    &::before {
      background: var(--rc-color-background-progressbar-indicator-success);
    }
  }
}

.step-progress-bar__step__label {
  position: absolute;
  top: 150%;
  display: table-cell;
  width: 15rem;
  margin-left: -6.7rem;
  text-align: center;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
  color: var(--rc-color-text-primary);

  @include mobile {
    display: none;
  }

  .step-progress-bar__step--finished & {
    color: var(--rc-color-background-progressbar-indicator-success);
  }

  @at-root {
    a#{&} {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.step-progress-bar__path {
  position: relative;
  width: 25%;
  height: 0.6rem;
  background: var(--rc-color-background-progressbar-track);
  display: inline-block;
  vertical-align: middle;
  border-radius: $input-border-radius;
  transition: all 0.2s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    background: var(--rc-color-background-progressbar-indicator-success);
    border-radius: $input-border-radius;
  }

  &.step-progress-bar__path--finished {
    &::before {
      background: var(--rc-color-background-progressbar-indicator-success);
      width: 100%;
    }
  }

  &.step-progress-bar__path--active.step-progress-bar__path--finished {
    &::before {
      width: 0;
      animation: bar-fill 0.5s 0.3s ease-in-out 1 forwards;
    }
  }
}
