@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.dismissible-banner {
  background-color: var(--rc-color-background-loud-ultralight);
  border-radius: var(--rc-border-radius-primary);
  margin: 1rem 0 2rem 0;
  border-left: 0.8rem solid var(--rc-color-accent-loud);

  @include mobile {
    border-left: 0;
  }
}

.dismissible-banner__action {
  float: right;
}

.dismissible-banner__content {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 2rem;

  @include mobile {
    display: block;
    padding: 1rem 1rem 2rem;
    text-align: center;
  }
}

.dismissible-banner__title {
  @include rc-text.title('500', 'regular');
}

.dismissible-banner__image {
  padding-top: 0.5rem;

  @include mobile {
    margin-left: var(--rc-min-height-button-mini);
  }
}
