.lp-session-header {
  padding: 8.5rem 0 10.5rem 0;
  background: url('../images/lp/reverb-lp-tile.svg') center var(--rc-color-palette-gray-100);
  border-bottom: var(--rc-color-border-primary) .1rem solid;

  img {
    display: block;
    width: 40rem;
    height: auto;
    margin: 0 auto;
  }

  @include mobile {
    padding: 4rem 0;

    img {
      width: 70%;
    }
  }
}

.session-header {
  position: relative;
  height: 10rem;
}

.session-header__image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 30rem;
  background: var(--rc-color-palette-vu-orange-500) url('//res.cloudinary.com/reverb-lp/image/upload/q_auto:good/SignupPage-Header_l13lhu.jpg');
  background-size: cover;

  @include mobile {
    position: relative;
    height: 15rem;
  }
}

.session-form {
  @include scaling(padding, 4rem);
  @include box-shadow;
  position: relative;
  background: var(--rc-color-background-module);
  border-radius: var(--rc-border-radius-primary);
  border: 0.1rem solid var(--rc-color-border-primary);
  max-width: 36rem;
  margin: 4rem auto;

  .lp-session-header + & {
    margin-top: -4rem;

    @include mobile {
      margin-top: -1rem;
    }
  }

  .referral-signup & {
    margin-top: 0;
  }

  .dialog & {
    background: none;
    margin: 0;
    box-shadow: none;
    padding: 0;
    width: 36rem;
    margin-left: auto;
    margin-right: auto;

    @include mobile {
      width: 80vw;
    }
  }

  @include mobile {
    box-sizing: border-box;
    max-width: 90vw;
  }
}

.session-form__lp-help {
  margin-top: -1em;
  margin-bottom: 1em;
  color: var(--rc-color-palette-blue-600);
  text-align: center;
}

.session-form__facebook-link {
  color: var(--rc-color-vendor-facebook);
  font-weight: bold;
}

.session-form__header {
  @include responsive(font-size, 2.8rem, 2.4rem, 2rem);

  text-align: center;
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 1em;
  color: var(--rc-color-text-primary);

  .dialog & {
    font-size: 1.8rem;
  }
}

.signup-errors {
  color: var(--rc-color-text-danger);
}

.checkbox-terms-label {
  position: relative;
  padding-left: 1.9rem;
  margin-bottom: 1.9rem;
}

.checkbox-terms-box {
  position: absolute;
  top: .3rem;
  left: 0;
}

.gdpr-data-usage-hint__title {
  font-weight: bold;
  margin-bottom: .5rem;
  font-size: 1.3rem;
  text-align: center;
}

.gdpr-data-usage-hint__subtext {
  font-size: 1.2rem;
  line-height: 1.2;
  color: var(--rc-color-text-secondary);
}
