@use 'sass:list';
@use 'sass:string';
@use '../../design-tokens/build/rc-tokens-global-scss' as globalTokens;
@use '../../design-tokens/build/rc-tokens-web-semantic-colors' as semanticColors;
@use '../tools/functions/strings';
@use '../tools/mixins/responsive';

// Generates utility classes for our semantic colors that have been output into the above scss map file.
// e.g.
// .text-color-primary {
//    color: var(--rc-color-text-primary);
// }
//
// .bg-color-module {
//    color: var(--rc-color-background-module);
// }

// Disabling no-invalid-css-vars since this area concatenates vars as an exception to the norm
// stylelint-disable cadence/no-invalid-css-vars

// Add classes to this map to output them; we are only outputting a limited number of classes as needed.
// The source maps here contain all semantic tokens and we want to minimize risk of future drift.

$text-classes: (
  color-link,
  color-primary,
  color-primary-inverted,
  color-secondary,
  color-primary-disabled,
  color-accent,
);

$background-classes: (
  color-page,
  color-page-secondary,
  color-module,
  color-module-muted,
);

$border-classes: (
  color-primary,
  color-selectable,
  color-selected,
);

@each $token, $classname in semanticColors.$text-tokens {
  @if list.index($text-classes, $classname) {
    .text-#{$classname} {
      color: var(--rc-#{$token});
    }
  }
}

@each $token, $classname in semanticColors.$background-tokens {
  @if list.index($background-classes, $classname) {
    .bg-#{$classname} {
      background-color: var(--rc-#{$token});
    }
  }
}

@each $token, $classname in semanticColors.$border-tokens {
  @if list.index($border-classes, $classname) {
    .bd-#{$classname} {
      border-color: var(--rc-#{$token});
    }
  }
}

// This mixin takes in all the global tokens generated inside `design-tokens/build/rc-tokens-global-scss`
// and iterates over them to produce utility classes with similar names. For example, it takes in a single
// token of `rc-color-palette-blue-500`, and converts it to 3 utility classes (for text, background, and
// border colors), like so:
//
// .text-color-blue-500 {
//   color: var(--rc-color-palette-blue-500);
// }
//
// .bg-color-blue-500 {
//   background-color: var(--rc-color-palette-blue-500);
// }
//
// .bd-color-blue-500 {
//   background-color: var(--rc-color-palette-blue-500);
// }
//

@mixin _build-color-classes($breakpoint: '') {
  $properties: (
    'text': 'color',
    'bg': 'background-color',
    'bd': 'border-color',
  );

  @each $name, $value in globalTokens.$tokens {
    $class-name: '';

    @if (string.index($name, 'rc-color')) {
      $no-rc: strings.str-replace($name, 'rc-', '');

      @if (string.index($name, 'color-palette')) {
        $class-name: strings.str-replace($no-rc, 'color-palette', 'color');
      } @else {
        $class-name: $no-rc;
      }

      @each $classPrefix, $property in $properties {
        .#{$breakpoint}#{$classPrefix}-#{$class-name} {
          #{$property}: var(--#{$name});
        }
      }
    }
  }
}

@include _build-color-classes;

@include responsive.tablet {
  @include _build-color-classes($breakpoint: 'tablet-');
}

@include responsive.mobile {
  @include _build-color-classes($breakpoint: 'mobile-');
}

// New token-based color class generator
// This generates 100-900 values for all new color palette groupings
// this list will eventually be folded into the main list
// and the above generators will be deprecated

$new-palette: (
  kick-blue,
  teal,
  tweed-orange,
  vu-orange,
);

@function buildValueList() {
  $values: ();
  @for $i from 1 to 9 {
    @each $p in $palette {
      $values: list.append($values, $p + '-' + $i * 100);
    }
  }
  @return $values;
};

// new class generation
@include build-responsive-classes((
  tokenBase: '--rc-color-palette',
  valueList: (blue-100, blue-500),
  attributes: (
    text: color,
    bg: background-color,
    bd: border-color,
  )
));

// these are legacy classes we're only keeping to support CMS pages with custom HTML
// please do not delete unless we're either sure no CMS pages use them anymore
// or are okay with leaving them behind

.color-primary {
  color: var(--rc-color-text-primary);
}

.color-primary-invert {
  color: var(--rc-color-text-primary-inverted);
}

.color-secondary {
  color: var(--rc-color-text-secondary);
}

.color-disabled {
  color: var(--rc-color-text-primary-disabled);
}

.color-accent {
  color: var(--rc-color-text-accent);
}

.bg-site {
  background-color: var(--rc-color-background-page);
}

.bg-site-secondary {
  background-color: var(--rc-color-background-page-secondary);
}

.bg-module {
  background-color: var(--rc-color-background-module);
}

.bg-module-muted {
  background-color: var(--rc-color-background-module-muted);
}
