.rc-form-group {
  @include scaling(margin-bottom, 2rem);

  &.rc-form-group--large {
    @include scaling(margin-bottom, 6rem);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.rc-form-group--with-error {
    .rc-select,
    input {
      border-color: var(--rc-color-border-input-error);
      // box-shadow makes the border 2px without impacting the layout or box model size
      box-shadow: 0 0 0 0.1rem var(--rc-color-border-input-error);
    }
  }
}

.rc-form-group__header {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--rc-space-1);

  .rc-form-group--large & {
    margin-bottom: var(--rc-space-2);
  }
}

.rc-form-group__tag {
  margin-left: var(--rc-space-1);
  transform: translateY(var(--rc-space-px)); // slight offset to get vertical centering but a flex-start alignment

  .rc-tag {
    display: block;
    flex-shrink: 0; // this tag should never change size
    flex-grow: 0;
  }
}

.rc-form-group__label {
  font-size: 1.4rem;
  font-weight: bold;
  display: block;
  max-width: 100%;
  word-wrap: break-word;
}

.rc-form-group__fields {
  input,
  select,
  textarea {
    .rc-form-group--large & {
      font-size: 2rem;
      padding: var(--rc-space-4);
    }
  }
}

.rc-form-group__help-text {
  font-size: 1.4rem;
  font-weight: normal;
  margin: var(--rc-space-1) 0;
  color: var(--rc-color-text-secondary);
  line-height: 1.2;

  .rc-form-group--large & {
    font-size: 1.6rem;
    color: var(--rc-color-text-secondary);
    margin-top: var(--rc-space-2);
    margin-bottom: var(--rc-space-4);
  }
}

.rc-form-group__error {
  color: var(--rc-color-text-danger);
  font-size: 1.4rem;
}
