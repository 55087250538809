.newsletter-callout {
  background: center var(--rc-color-palette-gray-300) repeat url('#{$discovery-ui-path}/images/instruments-offwhite.svg');
  background-size: 40rem 40rem;
  overflow: hidden;
  font-size: 1.2em;
}

.newsletter-callout__inner {
  @include site-wrapper;
  padding: 8rem 0;
  position: relative;
  box-sizing: border-box;
  padding-left: 50rem;

  @include mobile {
    text-align: center;
    padding: 1rem;
  }
}

.newsletter-callout__image {
  @include box-shadow;
  position: absolute;
  left: 20rem;
  bottom: -5%;
  transform: rotate(6deg);

  &:last-of-type {
    left: 5rem;
    bottom: -8%;
    transform: rotate(-7deg);
  }

  @include mobile {
    display: none;
  }
}

.newsletter-callout__title {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.newsletter-callout__form {
  @include scaling(margin-top, 2rem);

  input {
    width: 25rem;
    margin-right: 1rem;

    @include mobile {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
}
