.sell-page-alert {
  width: 100%;
  max-width: 86rem;
  margin: 2rem 0;

  ul {
    @extend %cms-ul;
    margin-bottom: 0;

    li {
      @extend %cms-ul-li;
    }
  }
}
