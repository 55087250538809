@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
$bg-color: var(--rc-color-palette-gray-800);

.multiple-photo-uploader-editor {
  margin-top: 2rem;
  width: 60rem;
  max-width: 100%;
}

.multiple-photo-uploader-editor__editor-container {
  padding: 1rem;
}

.multiple-photo-uploader-editor__action-bar {
  display: flex;
  margin-bottom: -0.1rem; // mysterious mobile border fix
  flex-direction: row;
  padding: 2rem;
  background: $bg-color;

  .rc-button {
    padding: 0.5rem;
  }

  .rc-icon {
    width: 2.5rem;
  }
}

.multiple-photo-uploader-editor__action-bar__action {
  margin-left: 2rem;

  &:first-child {
    flex-grow: 1;
    margin-left: 0;
  }
}

.multiple-photo-uploader-editor__img-background {
  position: relative;
  background: $bg-color;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &.multiple-photo-uploader-editor__img-background--loading {
    height: 30rem;

    @include mobile {
      height: 25rem;
    }
  }
}

.multiple-photo-uploader-editor__img-background__loading-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 30rem;
  width: 100%;
  background: $bg-color;
  justify-content: center;
  align-items: center;

  @include mobile {
    height: 25rem;
  }
}

.multiple-photo-uploader-editor_img-gutter {
  width: 100%;
  height: 3rem;
  margin-top: -0.1rem; // mysterious mobile border fix
  background: $bg-color;
}


.multiple-photo-uploader-editor__edit-actions {
  min-height: 3.1rem;
  text-align: center;

  button {
    @include rc-button.muted;
    display: inline-block;

    &:last-child {
      margin-left: 2rem;
    }
  }
}

.multiple-photo-uploader-editor__actions {
  text-align: right;
  padding: 1rem;
  border-top: 0.1rem solid var(--rc-color-border-primary);

  > button {
    margin-left: 2rem;
  }

  @include mobile {
    display: flex;
    flex-direction: column-reverse;

    > button {
      margin: 1rem 0;
    }
  }
}

// override ReactCrop styles
.multiple-photo-uploader-editor__preview-image,
.ReactCrop__child-wrapper > img {
  max-width: 100%;
  max-height: 40rem;
  transition: opacity .2s;

  @include mobile {
    max-height: 250px;
  }
}

.multiple-photo-uploader-editor {
  .ReactCrop {
    overflow: initial;
  }
}
