.affirm-estimate-small {
  font-size: 1.3rem;
  line-height: 1.3;

  .affirm-learn-more-modal__trigger {
    text-decoration: none;
    border-bottom: dashed 0.1rem var(--rc-color-text-primary);
  }
}

.affirm-estimate-small__copy {
  color: var(--rc-color-text-secondary);

  // when cta__copy is inside a button-as-link, make sure the
  // hover state matches our regular button-as-link styles
  .affirm-learn-more-modal__trigger:hover & {
    color: var(--rc-color-palette-vu-orange-500);
  }

  strong {
    color: var(--rc-color-text-secondary);

    .affirm-learn-more-modal__trigger:hover & {
      color: var(--rc-color-palette-vu-orange-500);
    }
  }
}
