@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/links';

.listing-row {
  border: solid 0.1rem var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-lg);

  .listing-rows & {
    margin-top: var(--rc-space-8);
  }
}

.listing-row__checkbox {
  @include checkbox;
  padding-right: 1.5rem;
  margin-top: 3.5rem; // vertically center on thumbnail

  @include mobile {
    margin-top: 0.5rem; // vertically center on thumbnail
  }

  label::before {
    margin-right: 0;
  }
}

.listing-row__header {
  padding: 1rem 1.5rem;
  background-color: var(--rc-color-palette-gray-100);
  border-top-left-radius: var(--rc-border-radius-lg);
  border-top-right-radius: var(--rc-border-radius-lg);

  @include mobile {
    padding: 1.5rem;
  }
}

.listing-row__seller-site {
  @include fa-icon-before(desktop);
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
}

.listing-row__body {
  display: flex;
  align-items: flex-start;
  padding: var(--rc-space-4);

  @include mobile {
    padding: var(--rc-space-3);
  }
}

.listing-row__thumbnail {
  background-color: var(--rc-color-palette-gray-100);
  border-radius: var(--rc-border-radius-md);
  overflow: hidden;

  a {
    display: block;
  }

  img {
    display: block;
    width: 9rem;
    height: auto;

    @include mobile {
      width: 3rem;
    }
  }
}

.listing-row__inner {
  flex: 1;
  margin-left: 1.5rem;
}

.listing-row__titleblock {
  display: flex;
  justify-content: space-between;
}

.liting-row__title {
  font-size: 1.8rem;
  font-weight: 700;

  a {
    @include links.text-link;
  }
}

.listing-row__price {
  font-size: 2.4rem;
  font-weight: 700;
  margin-left: var(--rc-space-3);
}

.listing-row__content {
  display: flex;

  @include mobile {
    display: block;
  }

  a {
    @include links.text-link;
  }
}

.listing-row__details {
  font-size: 1.6rem;
  font-weight: 600;

  ul {
    display: flex;
    flex-direction: column;
    gap: var(--rc-space-1);
  }
}

.listing-row__errors {
  color: var(--rc-color-text-danger);

  p {
    font-weight: 700;
    margin: 1rem 0 0;
  }

  ul {
    list-style: disc;
    margin: -0.2rem 0;
    padding-left: 2rem;
  }

  li {
    list-style: disc;
    margin: 0.2rem 0;
  }
}

.listing-row__stats {
  text-align: right;
  flex: 1;
  margin-left: 1rem;
  font-size: 1.6rem;
  font-weight: 600;

  @include mobile {
    text-align: left;
    margin-left: 0;
    margin-top: 0.2rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: var(--rc-space-1);
  }
}

.listing-row__state {
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 0.5rem;

  &.listing-row__state--live {
    color: var(--rc-color-text-success);
  }

  &.listing-row__state--completed {
    color: var(--rc-color-palette-blue-700);
  }

  &.listing-row__state--pending {
    color: var(--rc-color-text-accent);
  }

  &.listing-row__state--suspended {
    color: var(--rc-color-text-danger);
  }
}

.listing-row__actions {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  @include mobile {
    @include scaling(margin-top, 1rem);
    display: block;
  }

  &.listing-row__actions--draft {
    flex-direction: row-reverse
  }
}

.listing-row__action {
  margin-left: 1.5rem;

  @include mobile {
    margin-left: 0;
  }

  // overrides for LISTING_ROW_REDESIGN experiment
  .mini-bump-stats {
    display: none;
  }

  .panel-container {
    width: 100%;
  }
}

.listing-row__action__trigger {
  @include rc-button.muted($size: 'mini');

  &.listing-row__action__trigger--dropdown {
    @include fa-icon-after(caret-down, 0.25em);

    &::after {
      @include mobile {
        content: '';
      }
    }
  }

  &.listing-row__action__trigger--publish {
    @include fa-icon-before(check-circle);
    @include rc-button.colors($theme: 'main', $variant: 'filled', $inverted: false);
  }

  &.listing-row__action__trigger--delete {
    @include fa-icon-before(trash);
  }

  &.listing-row__action__trigger--active,
  &.listing-row__action__trigger--not-sending {
    &::after {
      content: '';
      display: inline-block;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background-color: var(--rc-color-palette-gray-200);
      margin-left: 0.5rem;
    }
  }

  &.listing-row__action__trigger--active::after {
    background-color: var(--rc-color-sentiment-positive-dark);
  }

  &.listing-row__action__trigger--not-sending::after {
    background-color: var(--rc-color-sentiment-negative-dark);
  }

  @include mobile {
    width: 100%;
    margin-top: 0.5rem;
  }
}

.listing-row__bundle {
  border-top: solid 0.1rem var(--rc-color-border-primary);
  padding: 1rem 1.5rem;

  @include mobile {
    padding: 1.5rem;
  }
}

.listing-row__bundle__title {
  font-weight: 700;
  font-size: 1.4rem;
  margin: 0 0 0.5rem;
}

.listing-row__bundle__items {
  margin-bottom: -0.5rem; // offset for uncontrolled partial
}

.listing-row__bundle__product {
  display: flex;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.listing-row__bundle__product__thumb {
  display: block;
  width: 4rem;
  margin-right: 1rem;
}

.listing-row__bundle__product__details {
  flex: 1;
  font-size: 1.4rem;
}

.listing-row__bundle__product__title {
  display: block;
  font-weight: 700;
}

.listing-row__bundle__product__price {
  display: inline-block;
}

.listing-row__bundle__product__message {
  display: inline-block;
  margin-left: 1rem;
}

.listing-row__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--rc-space-4) var(--rc-space-4);

  @include mobile {
    display: block;
    padding: 0 var(--rc-space-3) var(--rc-space-3);
  }
}

// Overrides that should be handled better after the LISTING_ROW_REDESIGN experiment
.mini-bump-meter {
  .listing-row__action & {
    font-size: 1.4rem;
    color: var(--rc-color-sentiment-positive-dark);
  }

  .listing-row & {
    text-align: inherit;
    margin-top: 0;

    .bump-title {
      display: inline;
      text-align: inherit;
      color: inherit;
      font-size: inherit;
      line-height: inherit;
      text-transform: inherit;
      font-weight: normal;
    }

    .align-left {
      display: inline;
      text-align: inherit;
      font-weight: normal;
      margin-left: 0.3rem;
      line-height: inherit;
    }
  }
}
