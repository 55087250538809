@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow';
@use '@reverbdotcom/cadence/styles/tools/mixins/gutter';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.toggled-site-banner {
  background: var(--rc-color-palette-bw-white);
  background-color: var(--rc-color-palette-gray-200);
  background-size: cover;
  background-position: 75%; // prioritizes right side image content.
  background-repeat: no-repeat;
  transition: opacity 0.2s ease-in-out, box-shadow 0.15s ease;

  .site-search__underlay:not(:has(> .site-search--active)) {
    z-index: 0;
  }
}

.toggled-site-banner__inner {
  @include site-wrapper;
  @include responsive(min-height, 35rem, 30rem, 20rem);
  display: flex;
  align-items: center;
  gap: var(--rc-space-gap-grid);

  @include mobile {
    padding-top: var(--rc-space-gap-grid);
    align-items: normal;
    flex-direction: column;
  }
}

.toggled-site-banner__content {
  box-sizing: border-box;
  padding-top: var(--rc-space-gap-grid);
  width: 75%;

  @include tablet {
    width: 66%;
  }

  @include mobile {
    width: 100%;
    padding-top: 0;
  }
}

.toggled-site-banner__subheading {
  @include rc-text.title('900');
  margin: var(--rc-space-3) 0;

  @include tablet {
    font-size: var(--rc-font-size-500);
    margin: var(--rc-space-2) 0;
  }
}

.toggled-site-banner__heading {
  @include rc-text.display;
  margin-bottom: var(--rc-space-8);

  @include tablet {
    @include rc-text.title('900');
    margin-bottom: var(--rc-space-4);
  }
}

.toggled-site-banner__image-container {
  display: flex;
  margin: 4rem 0;
  gap: var(--rc-space-gap-grid);
  flex: 0 0 33%;

  @include tablet {
    margin: 2rem 0;
  }

  @include mobile {
    display: none;
  }
}

.toggled-site-banner__mobile-image {
  display: none;

  @include mobile {
    max-height: 15rem;
    display: flex;
    overflow: hidden;
  }

  .image-box {
    @include mobile {
      align-self: center;
    }
  }

  img {
    @include mobile {
      width: 100%;
    }
  }
}

.toggled-site-banner__pills {
  margin: 2.2rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.toggled-site-banner__pill-dynamic {
  @include rc-button.muted($size: 'mini');
  background-color: var(--rc-color-highlight-400);

  &:hover {
    background-color: var(--rc-color-highlight-500);
  }

  & > .toggled-site-banner__pill-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.toggled-site-banner__pill-text {
  @include rc-text.default($weight: 'bold');
  padding: 0.2rem 1rem;

  @include tablet {
    padding: 0 1rem;
  }
}
