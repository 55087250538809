@use '@reverbdotcom/cadence/styles/tools/mixins/wrapper';
@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use 'sass:math';

.cms-page-head__image {
  @include responsive(height, 25vh, 20vh, 12rem);
  @include responsive(min-height, 15rem, $mobile: 12rem);
  @include commons-svg-background('instruments-lighten.svg');

  background-color: var(--rc-color-palette-blue-600);
  max-width: none;
  max-height: 20rem;

  //with image
  &[style] {
    // removes the pattern but is overridden by the inline image
    background-image: none;
    background-color: var(--rc-color-palette-gray-900);
    background-size: cover;
    background-position: center;
  }
}

.cms-page-head__container {
  position: relative;
  z-index: 1;
}

.cms-page-head__offset {
  @include wrapper.site-wrapper;
  @include wrapper.site-wrapper--cms;

  // the page-head centers with an offset by drawing a box with a max-width
  // of $site-width-cms, centering that, and then using some false negative
  // margin to draw the BOX flush with the right-hand side of the window,
  // and the TEXT flush with the max-width of the rest of the website.
  // Here's the true max-width of the centered element:
  padding: 0;
  margin-top: -3em;
}

.cms-page-head__text {
  @include max-vw(margin-left, 1);

  border: 0.1rem solid var(--rc-color-border-primary);
  position: relative;
  margin-left: 1vw;
  padding-right: var(--rc-space-gutter-page);
  background: white;

  // here's the negative margin to render the shadowed right box
  // flush with the right viewport edge
  @include min-width($site-width-cms) {
    margin-right: calc(-50vw + math.div($site-width-cms, 2));
  }

  @include mobile {
    display: block;
  }
}

.cms-page-head__text__inner {
  @include max-vw(padding-left, 2);
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  // here's the max-width of the text container itself
  max-width: $site-width-max - math.div($site-width-cms, 2);

  &::after {
    @include scaling(height, 1rem);

    content: '';
    position: absolute;
    background: var(--rc-color-palette-vu-orange-500);
    bottom: 100%;
    width: 80%;
  }
}

.cms-page-head__title {
  @include responsive(font-size, 2.6em, 2.2em, 1.4em);
  @include hyphenate;

  font-weight: 400;
  line-height: 1.1;
}

.cms-page-head__subtitle {
  @include responsive(font-size, 1.2em, 1.1em, 0.9em);

  line-height: 1.3;
  margin: 0.3em 0 0 0;
  flex: 1;

  p {
    line-height: inherit;
    margin: 0;
  }
}

.cms-page-head__action {
  @include svg.icon-after(arrow-right, $as-link: true);

  display: block;
  font-weight: bold;
  white-space: nowrap;

  .cms-page-head__subtitle + & {
    @include responsive(margin-left, 2rem, 0);
  }

  @include tablet {
    margin-top: 0.5rem;
  }

  @include mobile {
    white-space: normal;
    font-size: 0.9em;
  }
}

.cms-page-head__byline {
  font-size: 1.3rem;
  margin-top: 1em;

  li {
    display: inline-block;
  }

  li + li {
    &::before {
      content: '•';
      opacity: 0.3;
      margin: 0 0.5em;
    }
  }
}
