@use 'sass:math';
@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';
//
// do not delete; these styles are used by legacy core components
//

.article-condensed-card-row {
  @include site-wrapper;
}

.article-condensed-card-row__inner {
  display: flex;
}

.article-condensed-card {
  width: 100%;

  a {
    @include shadow-on-hover;
    box-sizing: border-box;
    position: relative;
    display: block;
    height: 13rem;
    background-size: cover;
    transition: opacity .2s, box-shadow .2s;
    white-space: normal;

    &::after {
      z-index: 1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transition: opacity .4s ease-in-out;
      background: linear-gradient(to bottom, var(--rc-color-highlight-100) 0%, var(--rc-color-palette-gray-900) 100%);
    }

    &:hover {
      &::after {
        opacity: 0.6;
      }
    }
  }

  .article-condensed-card-grid & {
    @include grid-columns(4, $mobile: 12, $cycle: true);
  }

  .article-condensed-card-row & {
    box-sizing: border-box;
    flex: 1 0 25%;
    max-width: 50%;
    margin: 0 math.div($grid-gutter, 2);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @include mobile {
      max-width: none;
      width: 80%;
      display: inline-block;
      vertical-align: top;

      a {
        height: 10rem;
      }
    }
  }
}

.article-condensed-card__title {
  @include truncate-text.truncate-text(
    $font-size: 1.8rem,
    $line-height: 1.1,
    $lines-to-show: 3
  );

  position: absolute;
  z-index: 2;
  bottom: 1.1rem;
  left: 1.5rem;
  right: 1.5rem;
  color: var(--rc-color-palette-bw-white);
  font-weight: bold;

  @include mobile {
    @include truncate-text.truncate-text(
      $font-size: 1.4rem,
      $line-height: 1.1,
      $lines-to-show: 3
    );

    bottom: 0.8rem;
    left: 1rem;
    right: 1rem;
  }
}

.article-condensed-card__category {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: var(--rc-color-palette-vu-orange-400);
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.8;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: var(--rc-color-palette-bw-white);
  padding: 0 .7em;
  z-index: 2;
}
