.sell-form-key-value-list {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1.5rem;
  font-size: 1.6rem;

  &.sell-form-key-value-list--margin-top {
    margin-top: 2rem;
  }

  @include mobile {
    grid-column-gap: 1rem;
  }
}

.sell-form-key-value-list__term {
  font-weight: bold;
  min-width: 15rem;

  &.sell-form-key-value-list__term--single-column {
    grid-column: span 2;
  }
}

.sell-form-key-value-list__definition {
  a {
    word-break: break-all;
  }

  &.sell-form-key-value-list__definition--single-column {
    grid-column: span 2;
  }
}
