.curated-set-card {
  width: 100%;

  .curated-set-card__link {
    @include shadow-on-hover;
    text-align: center;
    color: var(--rc-color-text-primary);
    display: flex;
    flex-direction: column;
    height: 100%;
    background: white;
  }

  &.curated-set-card--feature {
    .curated-set-card__link {
      flex-direction: row;

      @include tablet {
        display: block;
      }
    }
  }

  &.curated-set-card--placeholder {
    @include loading-pulse;
  }
}

.curated-set-card__image {
  transition: opacity 0.2s;

  .curated-set-card:hover & {
    opacity: 0.9;
  }

  .curated-set-card--feature & {
    flex: 0 0 50%;
  }
}

.curated-set-card__image__inner {
  background: var(--rc-color-palette-gray-200) center no-repeat;
  background-size: cover;

  .curated-set-card--feature & {
    padding-bottom: 100%;

    @include tablet {
      padding-bottom: 60%;
    }
  }

  .curated-set-card--basic & {
    @include scaling(height, 15rem);
  }

  .curated-set-card--two-image & {
    @include responsive(height, 10rem, 9rem, 8rem);
  }
}

.curated-set-card__text {
  @include scaling(padding, 1em);
  @include hyphenate;
  hyphens: auto;

  position: relative;
  box-sizing: border-box;
  flex: 1 0 auto;
  display: flex;
  align-items: center;

  .curated-set-card--basic &,
  .curated-set-card--two-image & {
    justify-content: center;
  }

  .curated-set-card--two-image & {
    padding-top: 25%;

    @include mobile {
      padding: 0.5em;
      padding-top: 25%;
    }
  }

  .curated-set-card--feature & {
    flex: 0 0 50%;
    text-align: left;
  }

  .curated-set-card--basic & {
    border-top: 0.1rem solid var(--rc-color-border-primary);
  }
}

.curated-set-card__text__inner {
  width: 100%;
  height: 100%;

  .curated-set-card--basic &,
  .curated-set-card--feature & {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.curated-set-card__square-image {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 40%;
  border: 0.2rem solid white;
}

.curated-set-card__square-image__inner {
  background: center no-repeat white;
  background-size: cover;
  padding-bottom: 100%;
}


.curated-set-card__title {
  font-size: 1.1em;
  line-height: 1.2;
  font-weight: bold;

  small {
    display: block;
    font-weight: normal;
    font-size: 1.3rem;
    opacity: 0.7;
    margin-top: 0.5em;
  }

  @include mobile {
    font-size: 1em;
  }

  .curated-set-card--feature & {
    font-size: 1.5em;

    &::after {
      content: '';
      height: 0.4rem;
      width: 80%;
      margin-top: 1em;
      display: block;
      background: var(--rc-color-palette-vu-orange-500);
    }

    @include tablet {
      font-size: 1.2em;
      text-align: center;

      &::after {
        display: none;
      }
    }
  }

  .curated-set-card--two-image & {
    @include responsive(font-size, 1em, 0.9em, 0.8em);
    line-height: 1.3;
  }

  .curated-set-card--loading & {
    height: 1em;
  }
}
