@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.empty-watchlist-row {
  .tiles {
    margin: 0.5em;
    min-width: 55%;
    .tiles__tile {
      @include responsive(min-width, 18rem, 15rem, 13rem);
      @include responsive(max-height, 25rem, 20rem, 15rem);
    }
    @include mobile {
      display: none;
    }
  }
}

.empty-watchlist-row__inner {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
}

.empty-watchlist-row__heading {
  @include rc-text.title('900');
  margin-bottom: 0.8rem;
  flex: 1;
  align-self: center;

  a {
    color: inherit;
  }
}

.empty-watchlist-row__action {
  margin-bottom: 0.8rem;
  flex: 0 0 auto;
  text-align: right;
  font-weight: bold;
  align-self: center;
}

.empty-watchlist-row__items {
  margin-top: 0.8rem;
  background-color: var(--rc-color-background-card);
  border-radius: var(--rc-border-radius-lg);
  overflow: hidden;

  @include mobile {
    flex-basis: 100%;
  }
}

.empty-watchlist-row__items__inner {
  display: inline-flex;
  padding: 0.8em;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    width: -webkit-fill-available;
  }
}

.row-main-content {
  @include responsive(max-width, 30%, 40%, none);
  margin-top: 0.8rem;
  padding: 0 0.5em;
  @include mobile {
    flex-basis: 100%;
  }
}
.row-main-content__container {
  @include mobile {
    display: inline-flex;
    width: 100%;
  }
}
.mobile-icon {
  display: none;
  @include mobile {
    width: 30%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: unset;
  }
}

.inner-subtitle {
  @include responsive(padding, 1rem, 0.8rem, 0.2rem);
  .rc-icon {
    color: var(--rc-color-text-accent);
    padding-right: 0.2em;
  }
  @include mobile {
    .rc-icon {
      display: none;
    }
  }
}

.inner-subtitle__text {
  @include responsive(font-size, large, medium, medium);
  @include mobile {
    font-weight: bold;
  }
}

.inner-title {
  @include responsive(padding, 0.8rem, 0.4rem, 0.2rem);
  @include responsive(font-size, x-large, x-large, small);
}

.inner-call-to-action {
  @include responsive(padding, 0.8rem, 0.4rem, 0.2rem);
  @include mobile {
    a {
      width: 100%;
      border-radius: 1.5em;
    }
  }
}

.inner-call-to-action__mobile {
  @include responsive(display, none, unset);
}

.inner-call-to-action__non-mobile {
  @include mobile {
    display: none;
  }
  @include tablet {
    display: none;
  }
}

.placeholder-card {
  position: relative;
  width: 100%;
}

.placeholder-card__inner {
  display: block;
}

.placeholder-card__image {
  border: 0.1rem solid var(--rc-color-border-primary);
  background-color: var(--rc-color-palette-bw-white);
  border-radius: var(--rc-border-radius-primary);
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: calc(100% - 0.2rem);
}

.placeholder-watch-icon {
  position: absolute;
  top: var(--rc-space-3);
  right: var(--rc-space-3);
  color: var(--rc-color-text-accent);
}

.placeholder-card__main__text {
  margin: 1rem 0;
}

.placeholder-card__title {
  border-radius: 1em;
  height: 0.5em;
  width: 80%;
  background: var(--rc-color-text-primary);
  opacity: 0.4;
}

.placeholder-card__pricing {
  margin: 0 0 1rem 0;
}

.placeholder-card__price {
  border-radius: 1em;
  height: 0.5em;
  width: 50%;
  background: var(--rc-color-text-primary);
  opacity: 0.4;
}
