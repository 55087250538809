.actionable-row {
  border-bottom: solid 0.1rem var(--rc-color-border-primary);
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include mobile {
    padding: 1.5rem 0;
    display: block;
  }

  .actionable-rows &:first-child {
    padding-top: 0;
  }

  &.actionable-row--placeholder {
    @include loading-pulse;
  }
}

.actionable-row__content {
  flex: 1;

  .actionable-row--placeholder & {
    &::before,
    &::after {
      content: '';
      display: block;
      height: 2rem;
      background-color: var(--rc-color-background-module-muted);
      border-radius: var(--rc-border-radius-md);
    }

    &::before {
      width: 22%;
    }

    &::after {
      width: 24%;
      margin-top: 0.5rem;
    }
  }
}

.actionable-row__actions {
  display: flex;
  align-items: center;

  @include mobile {
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0.5rem -1rem -0.5rem; // mobile offsets for __action margins
  }
}

.actionable-row__action {
  margin-left: 2rem;

  @include mobile {
    margin: 0 1rem 0.5rem;

    &:only-child {
      margin-left: auto; // to get single-item lists (ie "edit"-only) right-aligned
    }
  }

  .actionable-row--placeholder & {
    &::before {
      content: '';
      display: block;
      width: 6rem;
      height: 2rem;
      background-color: var(--rc-color-background-module-muted);
      border-radius: var(--rc-border-radius-md);
    }
  }
}
