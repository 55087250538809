.listing-shop-country-nudge {
  display: inline-flex;
  align-items: center;
  font-size: 1.3rem;
  height: 1.9rem;

  .flag-icon {
    border-radius: 0.2rem;
    margin: 0 0.5rem 0 0.2rem;
  }

  // overrides for when this is inside rc-listing-card
  .rc-listing-card__nudge & {
    width: 2rem;
    height: 1.9rem; // really 2rem, but this helps visually center the UI due to uneven px values
    display: flex;
    align-items: center;

    .flag-icon {
      width: 100%;
      height: auto;
      margin: 0;
    }
  }
}
