@use '../tools/mixins/aspect-ratio';

.rc-shop-info {
  display: flex;
  align-items: flex-start;
  column-gap: var(--rc-space-3);
}

.rc-shop-info__avatar {
  @include aspect-ratio.aspect-ratio(1, 1);

  width: 6rem;

  img {
    border-radius: var(--rc-border-radius-full);
  }
}

.rc-shop-info__content {
  display: flex;
  flex-direction: column;
}

.rc-shop-info__name {
  font-size: 1.4rem;
  font-weight: var(--rc-font-weight-semibold);
  color: var(--rc-color-text-primary)
}

.rc-shop-info__location {
  font-size: 1.4rem;
}

.rc-shop-info__rating {
  margin-top: var(--rc-space-1);
}

// .rc-shop-info__actions {
//   margin-top: var(--rc-space-1);
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   column-gap: var(--rc-space-6);
//   row-gap: var(--rc-space-1);
// }

// .rc-shop-info__action {
//   font-size: 1.4rem;
// }
