.price-display {
  white-space: nowrap;

  &.price-display--message {
    white-space: normal;
    font-size: 0.8em;
    line-height: 1.1;
    display: block;
  }
}

.price-display--message {
  .listing-shipping-display__tooltip & {
    display: inline-block;
    font-size: inherit;
    text-transform: uppercase;
  }
}
