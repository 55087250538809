.phone-number-input {
  display: flex;
}

.phone-number-input__select {
  @include styled-dropdown;
  flex-basis: 40%;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
  border-right: none;
}

.phone-number-input__input {
  flex-basis: 60%;

  input[type='tel'] { // attr selector needed for specificity
    border-top-left-radius: 0%;
    border-bottom-left-radius: 0%;
  }
}
