.price-guide-tool-bail-card {
  @include shadow-on-hover;
  @include responsive(font-size, 1em, 0.95em, 0.9em);
  @include scaling(padding, 2rem);
  background-color: var(--rc-color-background-card);
  position: relative;
  transition: box-shadow 0.2s, background-color 0.2s;
  border-radius: var(--rc-border-radius-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 23.5rem;

  @include responsive-hover {
    background-color: var(--rc-color-background-card-hovered);
  }
}

.price-guide-tool-bail-card__text {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--rc-color-text-primary);

  &:hover {
    text-decoration: none;
  }
}

.price-guide-tool-bail-card__link {
  display: block;
  text-align: center;
  font-weight: bold;

  &:hover {
    color: var(--rc-color-text-link-hovered);
  }
}
