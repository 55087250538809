$spinner-speed: 0.7s;

.loading-component {
  transform: translateZ(0);

  width: 100%;
  margin: 1.5rem 0;
  text-align: center;

  .rect {
    background-color: var(--rc-color-palette-gray-900);
    display: inline-block;
    height: 2.5rem;
    margin: 0 0.1rem;
    width: 0.6rem;

    animation: stretchdelay $spinner-speed infinite ease-in-out;
  }

  &.small {
    .rect {
      height: 1.2rem;
      width: 0.2rem;
    }
  }

  &.large {
    .rect {
      height: 4rem;
      width: 0.8rem;
    }
  }

  @for $i from 2 through 5 {
    .rect#{$i} {
      animation-delay: -($spinner-speed - $i * .1 + .1)
    }
  }
}

.loading-overlay-container {
  position: relative;

  .loading-overlay-content {
    transition: opacity 0.3s ease-in;
  }

  &.loading-overlay-container--active {

    .loading-overlay-content {
      opacity: 0.5;
    }

    .loading-component {
      position: absolute;
      top: 5%;
      z-index: 3;
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
  }
}

.loading-component-container {
  bottom: 0;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;

  &.loading-overlay {
    background-color: rgba(white, 0.5);
  }

  .loading-component {
    margin: 0;
  }
}
