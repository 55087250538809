.rc-text-input__group {
  display: flex;
  align-items: center;
  border: solid 0.1rem var(--rc-color-border-input-primary);
  border-radius: var(--rc-border-radius-input);
  background-color: var(--rc-color-background-input);

  &:focus-within {
    @include focus-outline;
    outline-offset: -0.1rem; // thickness of the input border
  }

  &:has(input:disabled) {
    background-color: var(--rc-color-background-input-disabled);
  }

  > :first-child,
  > [type]:first-child,
  > :first-child select {
    border-top-left-radius: var(--rc-border-radius-input);
    border-bottom-left-radius: var(--rc-border-radius-input);
  }

  > :last-child,
  > [type]:last-child,
  > :last-child select,
  > :last-child::before {
    border-top-right-radius: var(--rc-border-radius-input);
    border-bottom-right-radius: var(--rc-border-radius-input);
  }

  > :focus-visible {
    z-index: 3; // so focus outline sits on top of prepend/append
  }
}

.rc-text-input__text {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  font-size: 1.4rem;
  line-height: 1.2;
  color: var(--rc-color-text-primary);
  font-weight: bold;

  &:first-child {
    padding-left: 1rem;
  }

  &:last-child {
    padding-right: 1rem;
  }
}

.rc-text-input__input {
  flex: 1;
  min-width: 0; // prevents wide-rendering bug in Firefox
  border: none;

  &:focus-visible {
    z-index: 3; // so focus outline sits on top of prepend/append
    outline: none;
  }

  &[type] {
    border-radius: 0;
    border: none;
  }
}

.rc-text-input__text,
.rc-text-input__input {
  border-radius: 0;
  margin: 0;
  margin-right: 0;
}
