@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.csp-price-guide-module {
  @include site-wrapper;
  margin-top: 6rem;

  .site-module__header {
    position: relative;
    background-image: url('#{$discovery-ui-path}/images/price-guide-tool/dark-background.jpg');
    background-size: cover;
    border-top-left-radius: calc(var(--rc-border-radius-primary) - 0.1rem);
    border-top-right-radius: calc(var(--rc-border-radius-primary) - 0.1rem);
  }

  .site-module__title {
    @include rc-text.title('900');
    display: flex;
    height: 6rem;
    justify-content: space-between;
    align-items: center;
    color: var(--rc-color-palette-bw-white);

    @include mobile {
      height: 5rem;
    }
  }

  .site-module__header img {
    position: absolute;
    right: 0.5rem;
    height: 14rem;

    @include mobile {
      height: 8rem;
    }
  }

  .site-module__content {
    padding: 0;
  }

  @include tablet {
    .site-module {
      border: none;
    }

    .site-module__header {
      border-width: 0.1rem;
      border-style: solid;
      border-color: var(--rc-color-border-primary);
      border-top-left-radius: var(--rc-border-radius-primary);
      border-top-right-radius: var(--rc-border-radius-primary);
    }
  }
}

.csp-price-guide-module__content {
  display: flex;
  flex-wrap: wrap;
}

.v1-csp-price-guide-module-layout__null-state__container {
  padding: 4rem 0;
  width: 100%;
  background-color: var(--rc-color-palette-gray-100);
  display: flex;
  justify-content: center;
  align-items: center;

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
    border-width: 0.1rem;
    border-style: solid;
    border-color: var(--rc-color-border-primary);
    border-bottom-left-radius: var(--rc-border-radius-primary);;
    border-bottom-right-radius: var(--rc-border-radius-primary);;
    border-top: 0;
  }

  img {
    width: 11rem;
    margin-right: 2.5rem;

    @include tablet {
      width: 10rem;
      margin-right: 0;
    }
  }
}

.v1-csp-price-guide-module-layout__null-state__content {
  max-width: 60rem;
}

.v1-csp-price-guide-module-layout__null-state__header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;

  @include tablet {
    margin-top: 1rem;
  }
}

.v1-csp-price-guide-module-layout__estimates-container {
  width: 60%;
  margin-right: 0;
  border-right: 0.1rem solid var(--rc-color-border-primary);

  @include tablet {
    width: 100%;
    margin-bottom: 2rem;
    border-width: 0 0.1rem 0.1rem;
    border-style: solid;
    border-color: var(--rc-color-border-primary);
    border-bottom-left-radius: var(--rc-border-radius-primary);
    border-bottom-right-radius: var(--rc-border-radius-primary);
  }
}

.v1-csp-price-guide-module-layout__estimates-container__header {
  @include scaling(padding, 2rem);

  border-bottom: 0.1rem solid var(--rc-color-border-primary);

  span {
    color: var(--rc-color-palette-gray-700);
  }
}

.v1-csp-price-guide-module-layout__estimates-container__header__title {
  @include scaling(margin-bottom, 0.5rem);

  font-size: 120%;
  font-weight: bold;
}

.v1-csp-price-guide-module-layout__estimates-container__content {
  @include scaling(padding, 2rem);
}

.v1-csp-price-guide-module-layout__table-section {
  flex: 1;

  @include tablet {
    width: 100%;
    border: 0.1rem solid var(--rc-color-border-primary);
    border-radius: var(--rc-border-radius-primary);;
  }
}

.price-history-graph-container {
  @include scaling(margin-top, 2rem);
  @include scaling(padding-top, 2rem);

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.price-history-graph-container__title {
  font-size: 120%;
  font-weight: bold;
}

.csp-price-estimates {
  @include scaling(padding, 2rem);
  @include scaling(margin, -2rem);

  border-bottom: 0.1rem solid var(--rc-color-border-primary);
}

.csp-price-estimates__values {
  display: flex;
  align-items: flex-start;

  @include mobile {
    flex-direction: column;
  }

  .great-value-price-container {
    margin-top: 1rem;
  }

  .price-change-display__price-description {
    margin-bottom: 0;
  }
}

.csp-price-estimates__values__display-range {
  font-size: 4rem;
  font-weight: bold;
  margin-right: 5rem;

  @include mobile {
    font-size: 3rem;
  }
}

.csp-price-estimates__regional-caveat {
  margin-top: 2rem;
  color: var(--rc-color-text-secondary);
  font-style: italic;
}

.csp-transaction-table-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.csp-transaction-table-container__header {
  min-height: 56rem;

  @include tablet {
    min-height: 50rem;
  }

  @include mobile {
    min-height: 45rem;
  }

  h3 {
    @include scaling(padding, 2rem);
    @include scaling(margin-bottom, 0.25rem);

    font-size: 120%;
    font-weight: bold;
  }
}

.csp-transaction-table-container__header__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.csp-transaction-table-container__footer {
  @include scaling(padding, 2rem);
  @include scaling(margin-top, 4.4rem);

  display: flex;
  justify-content: space-between;
  align-items: center;

  @include tablet {
    margin-top: 0;
  }
}

.csp-transaction-table-container__footer__disclaimer {
  width: 70%;
  font-size: 80%;
  color: var(--rc-color-palette-gray-700);
  margin-bottom: 0;
}

.csp-transaction-table-container__footer__pagination-buttons {
  display: flex;
  justify-content: flex-end;
}

.csp-transaction-table-container__footer__pagination-buttons > div:last-child {
  @include scaling(margin-left, 1rem);
}

.csp-price-guide-module__disclaimer {
  @include scaling(padding-top, 2rem);

  max-width: 50rem;
  font-size: 1.3rem;
  color: var(--rc-color-text-secondary);
  margin-bottom: 0;
  margin-left: auto;
  text-align: right;
}
