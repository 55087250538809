@use '../tools/mixins/rc-image' as rc-image;

.rc-image {
  height: 0;
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden;

  img {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.rc-image--auto-height {
  height: auto;

  img {
    position: relative;
    transform: none;
    left: auto;
    top: auto;
    width: 100%;
    height: auto;
  }
}

.rc-image--full-width {
  img {
    width: 100%;
  }
}

.rc-image--with-overlay,
.rc-image--with-overlay-hover {
  @include rc-image.overlay;
}

.rc-image--with-overlay-hover {
  @include rc-image.overlay-hover;
}

// short iterator, it's safe I promise
// stylelint-disable cadence/no-invalid-css-vars
@each $size in (sm, md, lg, xl, full) {
  .rc-image-border-radius-#{$size} {
    border-radius: var(--rc-border-radius-#{$size});
  }
}
