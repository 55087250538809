.mosaic-tiles {
  @include site-wrapper;
  @include scaling(padding-top, 2rem);
  @include scaling(padding-bottom, 2rem);
  background-color: var(--rc-color-palette-bw-white);

  @include mobile {
    margin-top: 0;
    margin-bottom: 0;
    background-color: transparent;
  }
}

.mosaic-tiles__inner {
  width: 100%;
  height: auto;
  display: flex;

  @include mobile {
    flex-direction: column;
  }
}

.mosaic-tile {
  flex: 1;

  @include mobile {
    margin: 0.5rem 0;
    padding: 0;
  }

  & + .mosaic-tile {
    margin-left: 1.8rem;

    @include mobile {
      margin-left: 0;
    }
  }
}

.mosaic-tile__link {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--rc-color-text-primary);
  box-shadow: none;
  transition: opacity 0.2s ease-in-out;

  @include responsive-hover {
    opacity: 0.95;
    color: var(--rc-color-text-primary);
  }

  @include mobile {
    flex-direction: row;
  }
}

.mosaic-tile__image {
  width: 100%;
  height: 25rem;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-color: var(--rc-color-palette-gray-200);
  position: relative;
  border-radius: $input-border-radius;

  @include tablet {
    height: 15rem;
  }

  @include mobile {
    min-height: 8rem;
    height: auto;
    width: 40%;
  }

  .mosaic-tile--loading & {
    @include loading-pulse;
  }

  .mosaic-tile__ad {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.mosaic-tile__text {
  background: var(--rc-color-palette-bw-white);
  width: 100%;
  padding: 1.5rem 0 0.5rem;


  @include mobile {
    padding: 1rem 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
}

.mosaic-tile__subtitle {
  @include responsive(font-size, 0.9em, 0.8em, 0.8em);
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 0.25rem;

  .mosaic-tile--loading & {
    @include loading-pulse;
    background-color: var(--rc-color-background-loading-placeholder);
    height: 1.5rem;
    width: 30%;
  }
}

.mosaic-tile__title {
  @include responsive(font-size, 1.3em, 1.1em, 1em);
  line-height: 1.1;

  .mosaic-tile--loading & {
    @include loading-pulse;
    background-color: var(--rc-color-background-loading-placeholder);
    height: 2.5rem;
    width: 70%;
  }
}

.mosaic-tile__partner-disclaimer {
  @include responsive(font-size, 0.9em, 0.8em, 0.8em);
  margin: 0.2rem 0 0;
}
