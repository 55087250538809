@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.search-header {
  position: relative;
  padding: 9rem 0;
  margin: 0 auto;

  @include tablet {
    padding: 6rem 0;
    background-size: 130rem;
  }

  @include mobile {
    padding: 4rem 0 18rem 0;
  }
}

.search-header__bg-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.search-header__bg-image--desktop {
    display: block;

    @include mobile {
      display: none;
    }
  }

  &.search-header__bg-image--mobile {
    display: none;
    background-size: cover;
    background-position: center;

    @include mobile {
      display: block;
    }
  }
}

.search-header__inner {
  @include site-wrapper;
}

.search-header__text {
  width: 60%;
  position: relative;

  h1 {
    @include rc-text.display;
    margin-bottom: 0.5em;

    @include mobile {
      font-size: 3.2rem;
    }
  }

  h2 {
    @include rc-text.default;
    margin-bottom: 0.5em;

    @include mobile {
      font-size: var(--rc-font-size-350);
      margin-bottom: var(--rc-space-3);
    }
  }

  @include tablet {
    max-width: 80%;

    h2 {
      max-width: 55rem;
    }
  }

  @include mobile {
    max-width: none;
    text-align: center;
    width: auto;
  }
}

.search-header__input {
  input {
    color: var(--rc-color-text-primary);
    font-weight: 350;
  }

  @include mobile {
    max-width: 45rem;
    margin: 0 auto;
  }
}

.search-header__suggestions {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  opacity: 1;
  transition: 1s opacity;
  margin-top: var(--rc-space-8);

  @include mobile {
    margin-top: var(--rc-space-4);
    justify-content: center;
  }

  &.search-header__suggestions--hidden {
    opacity: 0;
  }
}

.search-header__suggestions-title {
  flex: 1 0 100%;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 80%;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}

.search-header__suggestion {
  @include rc-button.muted($size: 'mini');
  background-color: var(--rc-color-palette-vu-orange-100);
}
