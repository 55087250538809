@use '../../design-tokens/build/rc-tokens-web-css' as web;
@use '../../design-tokens/build/rc-tokens-web-dark-css' as web-dark;

// Global styles get attached to a :root selector and available anywhere
@forward '../../design-tokens/build/rc-tokens-global-css';

// Responsive token declarations on html tag
@forward '../../design-tokens/build/rc-tokens-web-responsive-css';

html,
[data-theme='light'] {
  @include web.theme;
  color-scheme: light;
}

// Dark theme overrides
[data-theme='dark'] {
  @include web-dark.theme;
  color-scheme: dark;
}

// Dark theme overrides for mobile app views
html.in-mobile-app {
  @media (prefers-color-scheme: 'dark') {
    @include web-dark.theme;
    color-scheme: dark;

    [data-theme='light'] {
      @include web.theme;
      color-scheme: light;
    }
  }
}
