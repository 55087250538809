.shipping-rate-card {
  border-top: solid 0.1rem var(--rc-color-border-primary);
  border-radius: $input-border-radius;
  padding: 2rem;
}

.shipping-rate-card-shipping-profile {
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.shipping-rate-card-shipping-profile__help__text {
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.2;
  color: var(--rc-color-text-secondary);
}

.shipping-rate-card__header {
  display: flex;
  margin-top: 1rem;

  .shipping-rate-card__header__subheading {
    margin-top: .5rem;
    margin-bottom: 1.5rem;
  }
}

.shipping-rate-card__titleblock {
  flex-grow: 1;
}

.shipping-rate-card__title {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;

  &.shipping-rate-card__regional-title {
    margin-top: 2.5rem;
  }

  .shipping-rate-card__title__tag {
    margin-left: 1.4rem;
  }
}

.shipping-rate-card__subtitle {
  font-size: 1.4rem;
  margin: 0.5rem 0 0;
  color: var(--rc-color-text-secondary);
}

.shipping-rate-card__remove {
  margin-left: 2rem;
}

.shipping-rate-card__main {
  margin-top: 2rem;
}

.shipping-rate-card__help__title {
  font-size: 120%;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}

.shipping-rate-card__help__subtitle {
  margin-left: 0.5rem;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  font-weight: normal;
  color: var(--rc-color-text-secondary);
  line-height: 1.2;
}

.shipping-rate-card__shipping__mode__title {
  margin-right: 1rem;
  font-size: 120%;
  font-weight: normal;
}

.shipping-rate-card__carrier__calculated__help__text {
  font-size: 1.4rem;
  font-weight: normal;
  margin: 0.4rem 0;
  margin-left: 2.7rem;
  color: var(--rc-color-text-secondary);
  line-height: 1.2;

  &:last-child {
    margin-top: 1rem;
  }
}

.shipping-rate-collapsible__radio__content {
  padding-left: 2.7rem;
}

.shipping-rate-card__carrier__calculated__list__group {
  margin-left: 2rem;
  font-size: 1.4rem;
}

.shipping-rate-card__carrier__calculated__list__item {
  &:first-child {
    margin-top: 0;
  }

  list-style: disc;
  margin-top: 0.8rem;
}

.shipping-rate-card__manual__prices {
  padding: 1rem 0;
}

.shipping-rate-card__manual__prices .form-group__help-text {
  margin-left: 3rem;
}

.shipping-rate-card__manual__price__entry {
  display: flex;
  flex-wrap: wrap;

  @include max-width(374px) {
    display: block;
    max-width: none;
  }

  + .shipping-rate-card__manual__price__entry {
    margin-top: 2rem;
  }

  &.shipping-rate-card__manual__price__entry--regional {
    margin-top: 2rem;
    min-height: 9rem;
  }
}

.shipping-rate-card__enable__checkbox {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.shipping-rate-card__manual__price__entry--indented {
  margin-left: 2.7rem;
}

.shipping-rate-card__manual__price__entry__input {
  flex-grow: 1;
  max-width: 50rem;
}

.shipping-rate-card__estimator__button {
  @include button-as-link;
  font-size: 1.4rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.shipping-rate-card__regional__alert__info {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
}

.shipping-rate-card__option-container {
  padding: 2rem;
  margin-top: .8rem;
  background: var(--rc-color-background-module-muted);
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-primary);

  &.shipping-rate-card__option-container--inline {
    border-bottom-left-radius: .4rem;
    border-bottom-right-radius: .4rem;
    margin-bottom: 1.7rem;
  }

  p {
    font-weight: bold;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
  }

  .shipping-rate-card__option-container__help-text {
    font-size: 1.4rem;
    color: var(--rc-color-text-primary);
    margin-left: 3.1rem;
  }
}


.shipping-rate-card__regional__rate-group {
  display: flex;
  padding: 2rem;
  margin-top: 1.5rem;
  background: var(--rc-color-background-page);
  border-radius: var(--rc-border-radius-md);
  flex-direction: column;

  button {
    margin-left: auto;

    @include tablet {
      margin-top: 1rem;
    }

    @include tablet {
      width: 100%;
    }
  }

  &.shipping-rate-card__regional__rate-group--new {
    flex-direction: row;
    align-items: center;

    div:first-child {
      margin-right: 1rem;
    }

    button {
      min-width: 26rem;
    }

    @include mobile {
      flex-direction: column;
      button {
        font-size: 1.4rem;;
        min-width: 100%;
      }
    }
  }

  .shipping-rate-card__regional__rate-group__footer > button {
    margin-left: auto;
    display: block;
    border: none;

    @include tablet {
      display: flex;
      justify-content: center;
      padding: 0;
      font-size: 1.4rem;
    }
  }

  &.shipping-rate-card__regional__rate-group--default-free {
    div:first-child {
      margin-right: 0;
      width: 100%;
    }

    button {
      margin-right: 0;
    }
  }

  .shipping-rate-card__regional__rate-group--default-free-help-text {
    font-size: 1.4rem;
    text-align: center;
    margin-right: 1.5rem;

    @include tablet {
      width: 100%;
    }
  }
}

.shipping-rate-card__regional__rate-group__button-group {
  margin-left: auto;
  display: block;

  button {
    margin-right: 1.5rem;
  }

  @include tablet {
    display: flex;
    margin-left: 0;

    button {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  @include mobile {
    flex-direction: column;

    button {
      margin-right: 0;
      margin-left: 0;
      min-width: 100%;
    }
  }
}

.shipping-rate-card__regional__rate-group__body {
  @include grid-container;

  display: flex;

  @include tablet {
    flex-direction: column;
  }
}

.shipping-rate-card__regional__rate-group__label {
  @include grid-columns(6, 12, 12);

  :first-child {
    font-weight: bold;
  }

  @include mobile {
    :last-child {
      font-size: 1.4rem;
    }
  }
}

.shipping-rate-card__regional__separator {
  margin: 2rem 0;
}

.shipping-rate-card__regional__modal__separator {
  width: 100%;
}

.shipping-rate-card__regional__modal__body {
  margin-bottom: 0;
}

.shipping-rate-card__regional__modal {
  @include grid-container;

  .alert-box {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.shipping-rate-card__regional__modal__area-checkbox {
  @include grid-columns(6, 12, 12);
  padding-bottom: 1rem;

  &.shipping-rate-card__regional__modal__area-checkbox--error {
    padding-bottom: 0;
  }

  &:last-of-type {
    margin-bottom: 1rem;
  }
}

.shipping-rate-card__regional__info {
  background-color: var(--rc-color-background-alert-info);
  border-radius: var(--rc-border-radius-md);
  padding: 2rem;

  .shipping-rate-card__regional__info__header {
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  &.shipping-rate-card__regional__info--self-contained {
    background-color: var(--rc-color-background-alert-plain);
    border: 0.1rem solid var(--rc-color-border-primary);
    margin-top: .8rem;
    margin-bottom: 1.5rem;
    padding: 2rem;
  }

  &:not(.shipping-rate-card__regional__info--self-contained) {
    a {
      color: var(--rc-color-text-primary);
      text-decoration: underline;
    }
  }
}

.shipping-rate-card__inline-link {
  margin-left: 0.4rem;
  text-decoration: underline;
}

.shipping-rate-card__regional__modal__error-list {
  list-style: disc;
  margin-left: 2.5rem;
}

.shipping-rate-card__regional__modal__area-checkboxes__container {
  margin-top: 1rem;
}
