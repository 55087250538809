@use '../tools/mixins/focus-outline' as focus;
@use '../config/configuration' as config;

.rc-search-input__inner {
  display: flex;
  background-color: var(--rc-color-background-input);
  border: solid 0.1rem var(--rc-color-border-input-primary);
  border-radius: var(--rc-border-radius-input);

  &:focus-within {
    @include focus.focus-outline;
    outline-offset: -0.1rem; // thickness of the input border
  }

  &:has(.rc-search-input__input[type=text]:disabled) {
    background-color: var(--rc-color-background-input-disabled);
  }

  .rc-search-input--br-full & {
    border-radius: var(--rc-border-radius-full);
    overflow: hidden;
    padding-left: var(--rc-space-2);
    padding-right: var(--rc-space-1);
  }

  .rc-search-input--borderless & {
    border: none;
  }
}

.rc-search-input__input[type=text] {
  border: none;
  outline: none;
}

.rc-search-input__actions {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: var(--rc-space-px);
}

.rc-search-input__action {
  &.rc-search-input__action--clear {
    .rc-icon {
      transform: scale(0.9);
    }
  }

  &.rc-search-input__action--search {
    .rc-icon {
      transform: scale(1.1);
    }
  }
}
