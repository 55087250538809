
.rc-star-rating {
  display: flex;
  align-items: center;
}

.rc-star-rating__stars {
  display: inline-flex;
  margin-right: var(--rc-space-1);
  color: var(--rc-color-text-star);
  justify-content: center;

  .rc-icon {
    width: 1.6rem;
  }

  .rc-star-rating--large & {
    .rc-icon {
      width: 2.6rem;
    }
  }
}

.rc-star-rating__count {
  display: inline-block;
  font-size: 1.4rem;
  color: var(--rc-color-text-primary);

  .rc-star-rating--large & {
    font-size: 2rem;
  }
}
