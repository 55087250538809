.shop-campaign-summary-table {
  width: 100%;

  th {
    background-color: var(--rc-color-palette-gray-300);
  }

  th, td {
    min-width: 10rem;
    max-width: 10rem;
    padding: 0.4em;
    word-wrap: break-word;
  }

  tr + tr td {
    border-bottom: 0.1rem solid var(--rc-color-palette-gray-300);
  }
}
