.date-picker {
  position: relative;
  width: auto;
}

.date-picker__dropdown {
  border: 0.1rem solid var(--rc-color-border-primary);
  background: var(--rc-color-palette-bw-white);
  position: absolute;
  top: calc(100% + 1rem);
  left: 0;
  padding: 2rem;
  z-index: 10;
  width: calc(100% - 4rem);

  @include mobile {
    min-width: 25rem;
  }
}

.date-picker__day {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 80%;
  transition: background-color 0.25s, color 0.25s;
  cursor: pointer;

  &--today {
    font-weight: 700;
  }

  &--selected {
    background: var(--rc-color-palette-vu-orange-500);
    color: var(--rc-color-palette-bw-white);
  }

  &--active {
    background: var(--rc-color-palette-vu-orange-100);
    color: var(--rc-color-palette-vu-orange-500);
  }

  &--disabled {
    background: var(--rc-color-palette-gray-100);
    color: var(--rc-color-text-primary-disabled);
    cursor: default;
  }

  &--hidden {
    cursor: default;
  }
}

.date-picker__calendar__container {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  .date-picker__calendar {
    flex-basis: 49%;

    @include mobile {
      display: none;
      flex-grow: 1;

      &:last-of-type {
        display: block;
      }
    }
  }
}

.date-picker__calendar__toggle {
  display: flex;
  height: 1rem;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.date-picker__calendar {
  width: 100%;

  table {
    width: 100%;
  }

  th {
    text-align: center;
  }

  td {
    border-top: 0.2rem solid transparent;
    border-bottom: 0.2rem solid transparent;
    width: calc(100% / 7);
    position: relative;
  }

  td::after {
    content: '';
    display: block;
    margin-top: 100%;
  }
}

.date-picker__calendar__label {
  text-align: center;
  font-weight: 700;
  margin-bottom: 1rem;
}
