.search-signup-headline {
  @include scaling(margin-bottom, 1.5rem);
  margin-top: -1.5rem;
  display: flex;
  align-items: center;

  @include mobile {
    display: block;
  }
}

.search-signup-headline__icon {
  flex: 4;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @include mobile {
    width: 35%;
    margin: 0 auto;
  }
}

.search-signup-headline__details {
  flex: 8;

  @include mobile {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.search-signup-headline__details__heading {
  margin-bottom: 0.5rem;
  padding-top: 1rem;
  font-size: 1.6rem;
  font-weight: bold;
}
