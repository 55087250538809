.feedback-summary-table {
  padding: 2rem;
  background: var(--rc-color-background-module-muted);
  border-radius: var(--rc-border-radius-primary);

  .item2-shop-module__reviews & {
    padding: 0 0 2rem;
    border-bottom: 0.1rem solid var(--rc-color-border-primary);
    background: none;
    border-radius: unset;
  }

  table {
    width: 100%;
  }

  th,
  td {
    padding: 0.8rem;
    text-align: left;
  }

  th {
    font-size: 0.8em;
  }

  td {
    border-top: 0.1rem solid var(--rc-color-border-primary);
  }
}
