@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

// this BEM exists to handle the strange display issues due to Apple Pay button webkit styles
.checkout-cart-footer {
  // keeping top-level BEM namespace here for clarity
}

.checkout-cart-footer__financing-estimate {
  display: flex;
  justify-content: flex-end;
}

.checkout-cart-footer__paypal-financing-estimate {
  display: flex;
  justify-content: flex-end;

  iframe {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}

.checkout-cart-footer__buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--rc-space-4);

  @include mobile {
    display: block;
  }
}

.checkout-cart-footer__button {
  &.checkout-cart-footer__button--apple-pay {
    // needed due to how Apple/safari doesn't calculate button width the same way as normal buttons.
    // This rem width allows us to render the longer "checkout with Apple Pay" button variant.
    margin-left: 1rem;
    margin-right: 0.5rem;
    margin-top: 0.32rem;

    @include mobile {
      margin-right: 0;
      margin-left: 0;
      margin-top: 0.6rem;
      margin-bottom: 1rem;
      width: 100%;
    }

    &:empty {
      display: none;
      margin: 0;
    }
  }

  &.checkout-cart-footer__button--paypal {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    gap: var(--rc-space-2);

    @include mobile {
      flex-direction: column;
      width: 100%;
      margin-bottom: var(--rc-space-2);
    }
  }
}

.checkout-cart-footer__express-pay-separator {
  display: flex;
  align-items: center;
  height: var(--rc-min-height-button-medium);

  @include tablet {
    display: none;
  }
}
