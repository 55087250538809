.curated-set-button__action {
  .curated-set-button--icon-only & {
    @include box-shadow;
    box-sizing: border-box;
    display: block;
    background-color: var(--rc-color-palette-bw-white);
    color: inherit;
    border-radius: 0.3rem;
    font-size: 1.8rem;
    width: 2.4rem;
    height: 2.4rem;
    position: relative;
    text-align: center;
    line-height: 1.4;
    transition: color .1s;
  }


  &::before {
    @include fa-icon(plus-square-o);
    margin-right: 0.5em;

    .curated-set-button--icon-only & {
      margin-right: 0;
    }
  }

  &.curated-set-button__action--remove {
    .curated-set-button--icon-only & {
      color: var(--rc-color-palette-vu-orange-500);
      line-height: 1.3;
    }

    &::before {
      content: fa-icon-content(plus-square);
    }
  }

  &.curated-set-button__action--add {
    &:hover {
      cursor: pointer;
      color: var(--rc-color-palette-vu-orange-500);
    }
  }
}
