@use '../../config/configuration' as config;

@mixin body-min-height {
  --body-wrapper-admin-toolbar-height: 3.2rem;
  --body-wrapper-footer-peek-space: 2rem; // amount of space we want the footer to "peek" up
  --body-wrapper-header-height: #{config.$header-height};

  width: 100%;
  box-sizing: border-box;
  min-height: calc(100vh - var(--body-wrapper-header-height) - var(--body-wrapper-footer-peek-space));

  body[data-is-admin='true'] & {
    min-height: calc(100vh - var(--body-wrapper-header-height) - var(--body-wrapper-footer-peek-space) - var(--body-wrapper-admin-toolbar-height));
  }
}
