.csp-feature-list {
  @include site-wrapper;
  @include gap-row;

  .dynamic-page__one-col & {
    @include site-wrapper;
    @include site-wrapper--cms;
  }

  .dynamic-page__two-col & {
    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.csp-feature-list__heading {
  @include responsive(font-size, 2.2rem, 1.9rem, 1.7rem);
  @include scaling(margin-bottom, 1.5rem);

  a {
    color: inherit;
  }

  &:empty {
    display: none;
  }
}

.csp-feature-list__item {
  @include shadow-on-hover;

  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  padding: 2rem;
  background: var(--rc-color-palette-bw-white);
  transition: box-shadow .2s ease-in-out;
  color: var(--rc-color-text-primary);

  &:hover {
    color: var(--rc-color-text-primary);
  }

  &.csp-feature-list__item--placeholder {
    @include loading-pulse;
  }
}

.csp-feature-list__item__title {
  font-size: 1.2em;
  line-he

  .csp-feature-list__item--placeholder & {
    width: 80%;
    height: 1em;
    background: var(--rc-color-palette-gray-200);
  }

  @include mobile {
    font-size: 1em;
  }
}

.csp-feature-list__item__rating {
  margin-top: 1rem;
}

.csp-feature-list__item__number-group {
  position: absolute;
  left: 1rem;
  top: 1rem;
  text-align: center;
  font-weight: bold;
  color: var(--rc-color-palette-bw-white);
  background-color: var(--rc-color-palette-blue-600);
  font-size: 1.3em;
  padding: 0.8em;
  line-height: 1;
  border: 0.1rem solid white;

  @include mobile {
    font-size: 1.1em;
  }
}

.csp-feature-list__item__number-group__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.csp-feature-list__item__description {
  @include responsive(font-size, 1.4rem, 1.2rem);

  margin-top: 0.5rem;

  p {
    margin-bottom: 0;
    line-height: 1.4;
  }
}

.csp-feature-list__item__details {
  display: flex;
  flex-basis: 30%;
  justify-content: flex-end;
  box-sizing: border-box;
  align-items: center;
  text-align: right;
}

.csp-feature-list__item__image {
  box-sizing: border-box;
  border: 0.1rem solid var(--rc-color-border-primary);
  flex: 0 0 16rem;
  margin-right: 2rem;

  @include tablet {
    flex-basis: 12rem;
  }

  @include mobile {
    flex-basis: 8rem;
    margin-right: 1rem;
  }
}

.csp-feature-list__item__image__inner {
  background: no-repeat center var(--rc-color-palette-gray-200);
  background-size: cover;
  position: relative;
  height: 0;
  padding-top: 100%;
}

.csp-feature-list__item__price {
  font-size: 1.4rem;
  color: var(--rc-color-text-primary);
  display: inline-block;
  font-weight: 700;
  margin-right: 1em;
  line-height: 1;
  order: 1;
  margin-top: 1rem;

  b {
    font-size: 1.2em;
    color: var(--rc-color-palette-vu-orange-500);
  }

  @include responsive-hover {
    color: var(--rc-color-palette-vu-orange-500);
  }

  @include mobile {
    font-size: 1.3rem;
  }

  .csp-feature-list__item--placeholder & {
    height: 1em;
    width: 40%;
    background: var(--rc-color-palette-gray-200);
  }
}
