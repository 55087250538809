@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.csp-curation-controls {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;
  opacity: .7;
  transition: opacity .2s;

  &:hover {
    opacity: 1;
  }
}

.csp-curation-controls__curate {
  @include rc-button.muted;
  font-size: 1.1rem;
}
