//
// Focus State weirdness:
//
// Safari doesn't yet support :focus-visible, so we need to hack around it a bit here.
// We set :focus styles for Safari, then we unset them for everything else via :not()
// then we add the same styles back for :focus-visible.
//
// When Safari adds support for :focus-visible, we can remove this hack and use just :focus-visible here.
// Check https://caniuse.com/css-focus-visible for status
//
// stylelint-disable selector-pseudo-class-disallowed-list
:focus {
  @include focus-outline;
}

:focus:not(:focus-visible) {
  outline: none;
}

:focus-visible {
  @include focus-outline;
}
