.logo-medium {
  display: block;
  width: 108px;
  height: 30px;
  text-indent: -9999px;
  color: transparent;
  background: url('../images/top-level/reverb-logo.png') 0 0 no-repeat;
  background: url('../images/top-level/reverb-logo.svg') 0 0 no-repeat;
  background-size: 108px 30px;

  @include mobile {
    width: 130px;
    height: 36px;
    background-size: 130px 36px;
  }
}

.site-header__mobile-menu,
div.site-header__menu.site-header__menu--subnav {
  padding: 1rem;

  h3 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    opacity: 0.5;
    padding: 1rem 1rem 0.3rem 1rem;
  }

  ul {
    padding: 0 0 0.8rem 0;
    border-top: none;
    border-bottom: 0.1rem solid var(--rc-color-palette-gray-200);

    &:last-child {
      margin-bottom: 0;
      border: none;
    }

    a {
      display: block;
      color: var(--rc-color-text-primary);
      padding: 0.3rem 1rem;
      transition: color .1s;
      position: relative;

      @include responsive-hover {
        color: var(--rc-color-palette-vu-orange-500);
      }
    }
  }
}

.site-header__mobile-menu {
  ul {
    border: none;
    font-size: 1.2em;
  }
}

.site-header__menu__credit-amount {
  padding: 0.3rem 1rem;
  font-size: 90%;
  font-weight: bold;
  color: var(--rc-color-sentiment-positive-dark);
}

.site-header__cart__credits-container {
  margin: 0.5em;
  padding: 1rem;
  background-color: var(--rc-color-sentiment-positive-lighter);
  border: 0.1rem solid var(--rc-color-sentiment-positive-light);
  font-size: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
