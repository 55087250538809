@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.item2-product-module {
  display: flex;
  flex-wrap: wrap;
}

.item2-product-module__header {
  display: flex;
  flex: 2 1 auto;
}

.item2-product-module__image-link {
  margin-right: 2rem;

  @include tablet {
    margin-right: 1.5rem;
  }
}

.item2-product-module__image {
  width: 10rem;
  height: 10rem;
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: $input-border-radius;
  overflow: hidden;

  @include tablet {
    width: 7.5rem;
    height: 7.5rem;
  }

  img {
    width: 100%;
  }
}

.item2-product-module__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-right: 2rem;
}

.item2-product-module__title {
  @include group-link;
  @include rc-text.utility($size: '600', $weight: 'medium');

  @include mobile {
    font-size: var(--rc-font-size-500);
    font-weight: var(--rc-font-weight-semibold);
  }
}

.item2-product-module__actions {
  display: flex;
  margin: 1rem -0.5rem 0;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;

  @include mobile {
    margin-bottom: 0;
  }
}

.item2-product-module__action {
  margin: 0.5rem;
  flex: 1;

  @include mobile {
    flex-basis: 100%;
  }

  &:empty {
    display: none;
  }
}

.item2-product-module__b-stock-listings {
  width: 100%;
}

.item2-product-module__action__button {
  @include rc-button.muted;
  @include rc-button.size('small');
  width: 100%;
}

.item2-product-module__reviews,
.item2-product-module__mismatch {
  flex: 0 0 100%;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 0.1rem solid var(--rc-color-border-primary);
  font-size: 1.6rem;

  &:empty {
    display: none;
  }
}
