@use 'sass:color';

$thumb-width: 11.2rem;
$thumb-gap: 2.6rem;
$mobile-thumb-gap: 2rem;

.multiple-photo-uploader {
  min-width: 20rem;
}

.multiple-photo-uploader__file-uploader {
  width: 100%;
  margin-bottom: 2rem;
  background-color: var(--rc-color-background-input);
  border: 0.1rem dashed var(--rc-color-border-input-primary);
  border-radius: var(--rc-border-radius-input);
  cursor: pointer;
  font-size: 1.6rem;
  transition: background-color 0.2s, border-color 0.2s;

  &:hover,
  &:focus-visible,
  &.multiple-photo-uploader__file-uploader--drag-active {
    border-color: var(--rc-color-outline-focused);
    background-color: var(--rc-color-background-page-secondary);
  }

  &.multiple-photo-uploader__file-uploader--disabled {
    cursor: not-allowed;

    .rc-button {
      cursor: not-allowed;
      opacity: $button-disabled-opacity;

      &:hover {
        box-shadow: none;
      }
    }

    &:hover {
      border: 0.1rem dashed var(--rc-color-border-primary);
      background-color: var(--rc-color-background-page);
    }
  }
}

.multiple-photo-uploader__file-uploader__image-icon {
  width: 3rem;
  margin-top: 1rem;
}

.multiple-photo-uploader__file-uploader__drag-text {
  margin-top: 1rem;
}

.multiple-photo-uploader__file-uploader__desktop-container {
  display: flex;
  padding: 5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--rc-color-text-primary);

  @include mobile {
    display: none;
  }
}

.multiple-photo-uploader__file-uploader__mobile-container {
  display: none;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: var(--rc-color-background-page-secondary);
  color: var(--rc-color-text-primary);

  @include mobile {
    display: flex;
  }
}

.multiple-photo-uploader__thumbnails-section__header {
  display: grid;
  width: 100%;
  padding-bottom: 1rem;
  grid-template-columns: 1fr auto;
  align-items: center;
  font-size: 1.6rem;
  font-weight: bold;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
}

.multiple-photo-uploader__thumbnails-section__header__mobile-reorder-toggle {
  display: none;

  @include mobile {
    display: block;
  }
}

.multiple-photo-uploader__thumb-list {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, $thumb-width);
  grid-gap: $thumb-gap;

  @include mobile {
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
    grid-gap: $mobile-thumb-gap;
  }
}

.multiple-photo-uploader__thumb {
  position: relative;
  width: $thumb-width;
  float: left;
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-md);
  background-color: var(--rc-color-background-page-secondary);
  touch-action: none;

  &.multiple-photo-uploader__thumb--loading {
    display: flex;
    height: $thumb-width;

    justify-content: center;
    align-items: center;
  }

  &.multiple-photo-uploader__thumb--dragging {
    @include focus-outline;
    @include box-shadow;
    cursor: grabbing;

    border-radius: var(--rc-border-radius-md);
  }

  @include mobile {
    width: 100%;
  }
}

.multiple-photo-uploader__thumb__image {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  cursor: grab;
  overflow: hidden;
  touch-action: none;

  .multiple-photo-uploader__thumb--dragging & {
    cursor: grabbing;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
    object-fit: contain;
    touch-action: none;
  }

  @include mobile {
    display: none;
  }

  // show the draggable container on mobile when sorting is enabled
  &.multiple-photo-uploader__thumb__image--draggable {
    .multiple-photo-uploader__thumb--show-mobile-move & {
      @include mobile {
        display: block;
      }
    }
  }

  // show the edit container on mobile when sorting is disabled
  &.multiple-photo-uploader__thumb__image--editable {
    @include button-reset;
    padding-bottom: 100%;
    display: none;

    .multiple-photo-uploader__thumb--show-mobile-edit & {
      @include mobile {
        display: block;
      }
    }
  }
}

.multiple-photo-uploader__primary-label {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.4rem;
}

.multiple-photo-uploader__thumb__warning {
  position: absolute;
  padding: 1rem;
  border-radius: 50%;
  bottom: 0.25rem;
  left: 0.25rem;
  color: var(--rc-color-text-primary);
  background: var(--rc-color-background-alert-warning);
  border: 0.1rem solid var(--rc-color-border-alert-warning);

  .rc-icon {
    width: 1.5rem;
    color: var(--rc-color-palette-gray-900);
  }
}

.multiple-photo-uploader__thumb__image__loading-overlay {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.multiple-photo-uploader__actions {
  position: absolute;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  top: 0;
  padding: 0.5rem 0;
  justify-items: center;
  align-items: center;
  background: linear-gradient(to bottom, var(--rc-color-palette-bw-soft-black) 0%, var(--rc-color-shadow-400) 100%);
  border-radius: $input-border-radius $input-border-radius 0 0;

  @include mobile {
    grid-template-columns: 1fr;
  }

  .multiple-photo-uploader__thumb--show-mobile-edit & {
    @include mobile {
      display: none;
    }
  }
}

.multiple-photo-uploader__actions__action {
  @include button-reset;

  width: 1.6rem;
  color: var(--rc-color-text-primary-inverted);

  @include mobile {
    display: none;
    width: 100%;
    padding-right: 0.5rem;
    justify-content: flex-end;

    .rc-icon {
      width: 2rem;
    }
  }

  &.multiple-photo-uploader__actions__action--move {
    cursor: grab;
  }

  .multiple-photo-uploader__thumb--dragging & {
    cursor: grabbing;
  }

  .multiple-photo-uploader__thumb--show-mobile-move & {
    &.multiple-photo-uploader__actions__action--move {
      @include mobile {
        display: flex;
      }
    }
  }
}

.multiple-photo-uploader__alert {
  margin-bottom: 2rem;
}

.multiple-photo-uploader__alert__content {
  display: flex;
  align-items: center;

  .rc-icon {
    width: 1.6rem;
    margin-right: 1rem;
    flex-shrink: 0;
  }
}

