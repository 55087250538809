@use 'sass:math';

// Fluid background image - use http://petercollingridge.appspot.com/svg-editor to compress svgs
@mixin fluid-bg-image($image-width, $image-height) {
  display: block;
  width: 100%;
  background-size: contain;
  padding-bottom: math.percentage(math.div($image-height, $image-width)); // height divided by width to get correct ratio
}

.payment-setup__label {
  @include radio;
}

.payment-setup__label--non-radio {
  label::before {
    border: none;
  }
}

.payment-setup__provider-logo {
  display: block;
  width: 100%;
  background: transparent left center no-repeat;
  background-size: contain;

  &.payment-setup__provider-logo--rp-us {
    background-image: url('#{$discovery-ui-path}/images/icons/payment-options/reverb-payments-integrated-paypal-apple-google-stacked.svg');
    height: 5rem;
  }

  &.payment-setup__provider-logo--rp-ca {
    background-image: url('#{$discovery-ui-path}/images/icons/payment-options/canadian-reverb-payments-integrated-paypal-apple-google-stacked.svg');
    height: 5rem;
  }

  &.payment-setup__provider-logo--rp-eu {
    background-image: url('#{$discovery-ui-path}/images/icons/payment-options/eu-reverb-payments-payment-options.svg');
    height: 5rem;
  }

  &.payment-setup__provider-logo--rp-eu-eur {
    background-image: url('#{$discovery-ui-path}/images/icons/payment-options/eur-eu-reverb-payments-payment-options.svg');
    height: 5rem;
  }

  &.payment-setup__provider-logo--paypal {
    background-image: url('#{$discovery-ui-path}/images/icons/payment-options/paypal-only-icon.svg');
    height: 2.3rem;
  }
}

.payment-setup__info {
  margin: 0.7rem 0 1.4rem;
  padding-left: 3.4rem;
}

.payment-setup-radio-col {
  @include grid-column(1);
  box-sizing: border-box;

  .shop-settings-payment-setup & {
    @include grid-column(1);
  }
}

.payment-setup-method-col {
  @include grid-column(11);
  box-sizing: border-box;

  .shop-settings-payment-setup & {
    @include grid-column(11);
  }
}

.payment-setup-method-icon-wrapper {
  width: 53%;

  @include mobile {
    width: 70%;
  }

  .shop-settings-payment-setup & {
    width: 70%;
  }

  &.payment-setup-method-icon-wrapper--mobile-pay {
    width: 59%;

    @include mobile {
      width: 100%;
    }

    .shop-settings-payment-setup & {
      width: 80%;

      @include mobile {
        width: 100%;
      }
    }
  }
}


.direct-checkout-integrated-paypal {
  @include fluid-bg-image(292, 46);
  background: url('#{$discovery-ui-path}/images/icons/payment-options/reverb-payments-integrated-paypal-apple-google-stacked.svg') no-repeat;
}

.international-direct-checkout-integrated-paypal {
  @include fluid-bg-image(284, 46);
  background: url('#{$discovery-ui-path}/images/icons/payment-options/canadian-reverb-payments-integrated-paypal-apple-google-stacked.svg') no-repeat;
}

.eu-reverb-payments-payment-methods {
  @include fluid-bg-image(212, 46);
  background: url('#{$discovery-ui-path}/images/icons/payment-options/eu-reverb-payments-payment-options.svg') no-repeat;
}

.paypal-only-icon {
  @include fluid-bg-image(96, 18);
  background: url('#{$discovery-ui-path}/images/icons/payment-options/paypal-only-icon.svg') no-repeat;
}

.example-check-img {
  width: 70%;
  display: block;

  @include mobile {
    width: 100%;
  }
}

.shop-settings-payment-setup {
  .example-check-img {
    width: 100%;
    display: block;
  }

  .hint {
    display: block;
  }
}

.dc-upgrade {
  padding: 1.8rem;
  margin-left: -1.8rem;
  border-radius: 0.6rem;
  border: 0.1rem solid var(--rc-color-border-primary);
}

.dc-upgrade__ad {
  text-align: center;
  margin-bottom: 2em;
}

.dc-upgrade__heading {
  size: 120%;
  font-weight: bold;
}

.dc-upgrade__sub-heading {
  font-weight: bold;
  color: var(--rc-color-text-accent);
}

.dc-upgrade__paragraph {
  font-size: 1.3rem;
  text-align: center;
  margin: 0.5em auto;
  width: 75%;
}
