@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.listing-shipping-display {
  font-size: 1.3rem;
  color: var(--rc-color-text-secondary);

  &.listing-shipping-display--free-expedited {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    @include svg.icon-before(fast-shipping-truck, scss-tokens.$rc-color-palette-vu-orange-500);
    display: block;

    color: var(--rc-color-palette-vu-orange-500);

    .item2-pricing & {
      font-weight: bold;
    }

    &::after {
      @include fa-icon('question-circle');

      color: var(--rc-color-palette-vu-orange-500);
      margin-left: 0.25em;
      opacity: 0.8;
    }

    .item2-pricing--signal-system & {
      font-weight: normal;
      color: var(--rc-color-text-secondary);

      &::before {
        display: none;
      }

      &::after {
        color: var(--rc-color-text-secondary);
      }
    }
  }

  &.listing-shipping-display--free {
    @include icon-before(fast-truck-nudge-tiny);
    font-weight: bold;
    color: var(--rc-color-text-primary);
    display: flex;

    &::before {
      margin-right: 0.5em;
      width: 2rem;
      height: 2rem;
    }

    .item2-pricing--signal-system & {
      font-weight: normal;
      color: var(--rc-color-text-secondary);

      &::before {
        display: none;
      }
    }
  }

  &.listing-shipping-display--fsox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  + .listing-shipping-display {
    margin-top: 0.3em;
  }

  .grid-card & {
    font-weight: normal;
    margin-top: 0.2em;
    font-size: 1.2rem;
  }

  .item2-pricing & {
    font-size: 1.4rem;
  }

  .featured-listing-header & {
    font-size: 1.5rem;
  }
}

.listing-shipping-display__original {
  .listing-shipping-display--combined & {
    text-decoration: line-through;
  }
}

.listing-shipping-display__to-postal {
  &::after {
    content: '\00a0';
  }
}

.listing-shipping-display__combined {
  font-size: 1.3rem;

  display: block;
  margin-left: 1rem;

  &:empty {
    display: none;
  }

  .grid-card & {
    margin-left: 0;
    margin-top: 0.2em;
    margin-bottom: 0.4em;
  }
}

.listing-shipping-display__tooltip {
  color: var(--rc-color-text-success);
  font-weight: bold;
  text-decoration: none;
  border-bottom: dashed 0.1rem currentColor;
  font-size: 1.4rem;

  &:empty {
    display: none;
  }
}
