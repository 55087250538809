@use '../tools/mixins/rc-button';

.rc-segmented-control {
  display: inline-flex;
  box-sizing: border-box;
  background-color: var(--rc-color-background-segmented-control);
  min-height: var(--rc-min-height-button-medium);
  border-radius: var(--rc-border-radius-md);
  padding: var(--rc-border-width-button);
  gap: var(--rc-border-width-button);

  &.rc-segmented-control--small {
    min-height: var(--rc-min-height-button-small);
  }

  &.rc-segmented-control--full-width {
    display: flex;
  }

  &.rc-segmented-control--loading {
    opacity: 0.6;
  }

  &.rc-segmented-control--high-contrast {
    background-color: var(--rc-color-background-segmented-control-high-contrast);
  }
}

.rc-segmented-control__button {
  @include rc-button.transparent($size: 'medium');
  // rc-button variable overrides
  --button-height: calc(var(--min-height) - var(--rc-border-width-button) * 2);

  min-height: var(--button-height);
  line-height: 1;
  position: relative;
  z-index: 1;
  border-radius: calc(var(--rc-border-radius-md) - 0.2rem);
  gap: 0;
  padding-left: var(--rc-space-4);
  padding-right: var(--rc-space-4);

  .rc-segmented-control--high-contrast & {
    --background-color-hovered: var(--rc-color-background-segmented-control-high-contrast);
  }

  // button has equal width / height
  &.rc-segmented-control__button--icon-only {
    min-width: var(--button-height);
    padding-left: unset;
    padding-right: unset;
  }

  &.rc-segmented-control__button--selected {
    @include rc-button.colors($theme: 'main', $variant: 'filled', $inverted: true);
    // override rc-button hover state when selected
    --background-color: var(--background-color-hovered);

    .rc-segmented-control--high-contrast & {
      @include rc-button.colors($theme: 'main', $variant: 'filled', $inverted: false);
      // override rc-button hover state when selected
      --background-color-hovered: var(--background-color);
    }
  }

  .rc-segmented-control--small & {
    @include rc-button.size('small');
  }

  .rc-segmented-control--full-width & {
    flex: 1;
  }
}
