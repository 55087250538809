.quick-price-edit-modal {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-3);

  .rc-form-group {
    margin-bottom: 0;
  }

  .price-breakdown__vat-id-alert {
    margin-top: 0;
  }
}

.quick-price-edit-percent-off-filter-chips__label {
  font-size: var(--rc-font-size-350);
  font-weight: var(--rc-font-weight-bold);
  margin-bottom: var(--rc-space-1);
}

.quick-price-edit-percent-off-filter-chips__buttons {
  display: flex;
  align-items: center;
  gap: var(--rc-space-2);
}

.quick-price-edit-price-recommendation {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-3);
  padding: var(--rc-space-4);
  background: var(--rc-color-background-module-price-guide-secondary);
  border-radius: var(--rc-border-radius-md);

  .sell-form-price-signal-callout {
    margin: 0;
  }
}

.quick-price-edit-price-recommendation__price-guide {
  display: flex;
  gap: var(--rc-space-2);
  font-weight: var(--rc-font-weight-bold);
  width: 100%;
}

.quick-price-edit-price-recommendation__price-guide__competitive-price {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-half);

  .price-display {
    font-size: 2.4rem;
  }
}

.quick-price-edit-price-recommendation__estimated-range {
  display: flex;
  gap: var(--rc-space-1);
}

.quick-price-edit-price-recommendation__estimated-range--semibold {
  font-weight: var(--rc-font-weight-semibold);
}

.quick-price-edit-modal-v2__content {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-4);
}

.quick-price-edit-modal-v2__content-header {
  size: var(--rc-font-size-550);
  font-weight: var(--rc-font-weight-semibold);
}

.quick-price-edit-modal-v2__content-body {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-3);

  .rc-form-group {
    margin-bottom: 0;
  }

  .sell-form-price-signal-callout {
    margin-top: 0;
  }

  .price-breakdown__vat-id-alert {
    margin-top: 0;
  }
}
