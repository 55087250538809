@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.add-to-cart-button {
  @include rc-button.filled;

  .item2 &,
  .featured-listing-card & {
    font-size: 1.1em;
    width: 100%;
    white-space: normal;
  }

  .item2-pricing--buy-it-now & {
    @include rc-button.colors($theme: 'main', $variant: 'muted', $inverted: false);
    font-size: 1em;
  }

  .item2-pricing--loud-preorder & {
    @include rc-button.colors($theme: 'loud', $variant: 'filled', $inverted: false);
  }

  .featured-listing-header,
  .featured-listing-module & {
    font-size: 1em;
    width: 100%;
    white-space: normal;
  }

  .rc-listing-row-card & {
    width: 100%;
    white-space: normal;
  }

  .list-view-row-card & {
    width: 100%;
    white-space: normal;

    @include tablet {
      @include rc-button.size('small');
    }
  }

  .grid-card & {
    width: 100%;
    white-space: normal;
    font-size: 0.9em;
  }

  .grid-card & {
    .similar-listings-row & {
      @include rc-button.muted;

      width: auto;
      white-space: normal;
    }
  }

  .jumplinks & {
    white-space: nowrap;
  }

  .rc-listing-card & {
    @include rc-button.size('small');
  }
}

.add-to-cart-button__preorder-date {
  margin-top: 1rem;
  font-size: 0.8em;
  opacity: 0.8;
  text-align: center;

  .listing-row-card & {
    display: none;
  }

  .grid-card & {
    display: none;
  }
}
