.adyen-field {
  height: var(--rc-size-height-input);
  border-radius: var(--rc-border-radius-input);
  border: 0.1rem solid var(--rc-color-border-input-primary);
  background: var(--rc-color-background-input);
  transition: 0.2s;
  overflow: hidden;

  &.adyen-field--focused {
    @include focus-outline();
    outline-offset: -0.1rem;
  }

  &.adyen-field--error {
    border-color: var(--rc-color-border-input-error);
  }
}
