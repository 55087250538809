.listing-nudge {
  display: flex;
  align-items: center;
  font-size: 0.9em;

  + .listing-nudge {
    margin-top: 1.2rem;
  }

  + .listing-nudge--inline {
    margin-top: 1rem;
  }

  &:nth-child(n+4) {
    display: none;
  }

  &.listing-nudge--inline {
    align-items: flex-start;
  }

  .cart-signals & {
    margin-top: 1rem;
    justify-content: flex-end;
    align-items: center;

    + .listing-nudge {
      margin-top: 0.5rem;
    }

    @include mobile {
      display: none;
    }
  }

  .cart-signals--mobile-only & {
    display: none;

    @include mobile {
      display: flex;
      justify-content: initial;
    }
  }

  .item2__content__section--why-reverb & {
    margin: 0;
    flex: 1 1 25rem;
  }
}

.listing-nudge__icon {
  display: block;
  width: 3.4rem;
  height: 3.4rem;
  margin-right: var(--rc-space-4);
  background-repeat: no-repeat;
  background-position: center center;
  flex-shrink: 0;
  text-align: center;

  svg {
    fill: none;
  }

  .listing-nudge--sale & {
    background-image: svg-url(sale-tag-nudge);
    background-size: contain;
  }

  .listing-nudge--shipping & {
    background-image: svg-url(fast-truck-nudge);
    background-size: contain;
  }

  .listing-nudge--local-pickup & {
    background-image: svg-url(location-pin-nudge);
    background-size: contain;
  }

  .listing-nudge--trust & {
    background-image: svg-url(trust-shield-nudge);
    background-size: contain;
  }

  .listing-nudge--affirm & {
    background-image: url('#{$discovery-ui-path}/images/icons/affirm-blue-circle.svg');
  }

  .listing-nudge--returns & {
    background-image: url('#{$discovery-ui-path}/images/icons/box.svg');
  }

  .listing-nudge--secure & {
    background-image: svg-url(secure-nudge);
    background-size: contain;
  }

  .listing-nudge--inline & {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
}

.listing-nudge__inner {
  flex: 1;

  .cart-signals & {
    flex: initial;
  }

  .listing-nudge--inline & {
    line-height: 1;
  }
}

.listing-nudge__title {
  font-weight: 700;
  line-height: 1.2;
  margin: 0;

  .listing-nudge--inline & {
    display: inline;
  }
}

.listing-nudge__subtitle {
  font-size: 1.3rem;
  line-height: 1.2;
  margin: 0.2em 0 0 0;
  color: var(--rc-color-text-secondary);

  .listing-nudge--inline & {
    display: inline;
    margin-left: 0.5rem;
    font-size: 1.4rem;
  }
}
