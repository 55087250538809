.feedback-seller-shop-info {
  &.feedback-seller-shop-info--placeholder {
    @include loading-pulse;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 2rem;
      background-color: var(--rc-color-palette-gray-200);
      border-radius: $input-border-radius;
    }

    &::before {
      width: 80%;
      margin-bottom: 0.5rem;
      margin-top: 2rem;
    }

    &::after {
      width: 40%;
    }
  }
}
