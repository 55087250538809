@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.sell-form-great-value-callout {
  height: 0;
  opacity: 0;
  width: 100%;
  transition: height 0.2s, opacity 0.3s ease-in-out;

  @include tablet {
    margin: 0;
    transition: margin 0.2s, height 0.2s, opacity 0.3s ease-in-out;
  }
}

.sell-form-great-value-callout--visible {
  height: 100%;
  opacity: 1;
  color: var(--rc-color-text-primary);

  @include tablet {
    margin: var(--rc-space-1) 0;
  }
}

.sell-form-great-value-callout__content {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-2);
  background-color: var(--rc-color-background-alert-info);
  border-radius: var(--rc-border-radius-md);
  padding: var(--rc-space-4);
  max-width: 22.8rem;

  @include tablet {
    max-width: 100%;
    padding: var(--rc-space-3);
  }
}

.sell-form-great-value-callout__title {
  @include rc-text.default($weight: 'bold');
}

.sell-form-great-value-callout__body-content {
  flex: 1;
}

.sell-form-great-value-callout__body {
  display: flex;
  gap: var(--rc-space-2);
}

.sell-form-great-value-callout__body__icon {
  align-self: center;
}

.sell-form-great-value-callout__body__title {
  font-size: var(--rc-font-size-350);
  font-weight: var(--rc-font-weight-semibold);
}

.sell-form-great-value-callout__body__text {
  font-size: var(--rc-font-size-350);

  @include tablet {
    width: 90%;
  }
}

.rc-form-group__help-text:has(.tablet-d-block):has(.sell-form-great-value-callout) {
  margin: 0;
}
