@use '../tools/mixins/box-shadow' as shadow;
@use '../tools/mixins/responsive-hover' as hover;
@use '../tools/mixins/aspect-ratio' as aspect;
@use '../tools/mixins/responsive';
@use '../tools/mixins/truncate-text';
@use '../tools/mixins/rc-button';
@use '../tools/mixins/rc-text';
@use '../tools/mixins//button-reset';

.rc-product-card {
  --card-background-color: var(--rc-color-background-card);

  position: relative;
  background-color: var(--card-background-color);
  border-radius: var(--rc-border-radius-primary);
  transition: box-shadow 0.15s ease, background-color 0.15s ease;
  width: 100%;

  @include hover.responsive-hover {
    @include shadow.box-shadow;

    --card-background-color: var(--rc-color-background-card-hovered);
  }

  &:focus-within {
    @include shadow.box-shadow;

    --card-background-color: var(--rc-color-background-card-hovered);
  }

  .rc-grid & {
    min-width: 25rem;
  }
}

.rc-product-card__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.rc-product-card__wrapper--link {
    color: var(--rc-color-text-primary);
    border-radius: inherit;

    @include hover.responsive-hover {
      color: var(--rc-color-text-primary);
    }
  }

  &.rc-product-card__wrapper--button {
    @include button-reset.button-reset;
    text-align: left;
    letter-spacing: var(--rc-font-letter-spacing-default);
    border-radius: inherit;
    width: 100%;
  }

  .sell__product-grid & {
    @include mobile {
      flex-direction: row;
    }
  }
}

.rc-product-card__image-wrapper {
  aspect-ratio: 1/1;
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--rc-space-3) var(--rc-space-3) 0;

  .sell__product-grid & {
    @include mobile {
      width: 30%;
      padding: var(--rc-space-2);
    }
  }
}

.rc-product-card__content {
  padding: var(--rc-space-5) var(--rc-space-6) var(--rc-space-6);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--rc-space-4);

  .sell__product-grid & {
    @include mobile {
      padding: var(--rc-space-3) var(--rc-space-3) var(--rc-space-4);
    }
  }
}

.rc-product-card__title {
  --font-size: 1.6rem;
  --line-height: 1.25; // 2rem
  --max-lines: 2;

  @include truncate-text.truncate-text(
    $lines-to-show: var(--max-lines),
    $font-size: var(--font-size),
    $line-height: var(--line-height),
  );
  font-weight: bold;
  word-break: break-word;

  .sell__product-grid & {
    --max-lines: 3;

    @include mobile {
      height: auto;
      --max-lines: 4;
    }
  }
}

.rc-product-card__eyebrow {
  display: flex; // needed to prevent center alignment in Safari
  margin-bottom: var(--rc-space-1);
}

.rc-product-card__footer {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-3);

  .sell__product-grid & {
    @include mobile {
      gap: var(--rc-space-2);
    }
  }

  &:empty {
    display: none;
  }
}
