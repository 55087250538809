@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.marketing-callout-banner {
  @include site-wrapper;

  margin-bottom: 4rem;
  padding-top: 3rem;

  @include mobile {
    margin-top: 0;
    padding: 0;
  }
}

.marketing-callout-banner__inner {
  padding: 5rem 2rem 5rem 3rem;
  border-radius: var(--rc-border-radius-primary);

  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-around;

  @include tablet {
    padding: 4rem 3rem;
  }

  @include mobile {
    padding: 1rem 1rem 2rem 1rem;
    border-radius: unset;
    display: block;
    padding: 0 0 1rem 0;
  }
}

.marketing-callout-banner__copy {
  color: var(--rc-color-text-primary);

  @include mobile {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 3%;
    padding-right: 3%;
  }
}

.marketing-callout-banner__callouts {
  display: flex;
  gap: var(--rc-space-gap-grid);
  flex: 0 0 50%;

  @include mobile {
    overflow: auto;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 2.4rem;
  }
}

.marketing-callout-banner__callouts--two-wide {
  flex: 0 0 33%;
  justify-content: center;

  @include tablet {
    flex: 0 0 40%;
  }
}

.marketing-callout-banner__callout-card {
  flex: 0 1 33%;

  @include mobile {
    flex: 0 1 50%;
  }
}

.marketing-callout-banner__callout-card--two-wide {
  flex: 0 1 50%;
}

.marketing-callout-banner__callout-card--three-wide {
  @include mobile {
    flex: 1 0 40%;
  }
}

.marketing-callout-banner__main-heading {
  @include rc-text.display;
  margin-bottom: 1rem;

  @include tablet {
    @include rc-text.title('900');
  }
}

.marketing-callout-banner__secondary-heading {
  @include rc-text.title('700');
  margin-bottom: 2rem;

  @include tablet {
    font-size: var(--rc-font-size-500);
    margin-bottom: 2.5rem;
  }

  @include mobile {
    margin-bottom: 2rem;
  }
}

.marketing-callout-banner__callout-text {
  padding: 2rem 1.6rem;
  font-size: 1.75rem;
  font-weight: 700;

  @include tablet {
    padding: 1.25rem 1rem;
    font-size: 1em;
  }
}
