@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow';
@use '@reverbdotcom/cadence/styles/tools/mixins/aspect-ratio';
@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

.csp-square-card {
  --card-outer-padding: var(--rc-space-4);
  --card-inner-spacing: var(--rc-space-2);

  flex: 0 0 100%;
  align-items: stretch;
  position: relative;

  @include tablet {
    --card-outer-padding: var(--rc-space-3);
  }

  &.csp-square-card--condensed {
    .csp-square-card__image {
      border: 0.1rem solid transparent;
      transition: box-shadow .1s ease-in-out;
      box-sizing: border-box;
    }

    @include responsive-hover {
      .csp-square-card__image {
        @include box-shadow;
        border-color: var(--rc-color-border-primary);
      }
    }
  }

  .csp-tiles & {
    min-height: 28rem;
  }
}

.csp-square-card__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background: var(--rc-color-background-card);
  white-space: normal;
  color: var(--rc-color-text-primary);
  transition: box-shadow 0.15s ease, background-color 0.15s ease;
  min-height: 14rem;
  border-radius: var(--rc-border-radius-primary);
  overflow: hidden;

  &:hover {
    @include box-shadow.box-shadow;
    color: var(--rc-color-text-primary);
    background: var(--rc-color-background-card-hovered);
  }

  @include tablet {
    .csp-square-card--horizontal & {
      flex-wrap: nowrap;
      min-height: 0;
      align-items: center;
    }
  }

  .csp-square-card--horizontal & {
    flex-direction: row;
    align-items: center;
  }

  .csp-square-card--condensed & {
    box-shadow: none;
    border: none;
    display: block;
    height: auto;
    min-height: none;
  }
}

.csp-square-card__header {
  align-self: flex-start;
  width: 100%;
  padding: 0.8rem;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);

  .csp-square-card--placeholder & {
    height: 1em;
  }

  .csp-square-card--condensed & {
    border-bottom: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.csp-square-card__main {
  flex: 1 0 auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--card-outer-padding) var(--card-outer-padding) 0;

  .csp-square-card--horizontal & {
    flex: 0 0 30%;
    min-width: 15rem;
    padding: var(--card-outer-padding) 0 var(--card-outer-padding) var(--card-outer-padding);

    @include tablet {
      min-width: 12rem;
    }

    @include mobile {
      min-width: 10rem;
    }
  }
}

.csp-square-card__details {
  padding: 0 var(--card-outer-padding) var(--card-outer-padding);

  .csp-square-card--horizontal & {
    align-self: center;
    padding: var(--card-outer-padding);
  }

  .csp-square-card--placeholder & {
    height: 1em;
  }

  .csp-square-card--condensed & {
    border-top: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.csp-square-card__details__price {
  position: relative;
  line-height: 1;
  font-size: 1.3rem;
  padding-top: 0;

  b {
    margin-left: 0.1em;
    white-space: nowrap;
  }

  .csp-square-card--condensed & {
    b {
      color: inherit;
    }
  }
}

.csp-square-card__finish-indicator {
  font-size: 1.3rem;
  font-weight: var(--rc-font-weight-semibold);
  margin-bottom: var(--rc-space-2);
  order: 3;

  @include mobile {
    font-size: 1.2rem;
  }

  .csp-square-card--condensed & {
    margin: 0.1rem;
    border: 0.1rem solid var(--rc-color-border-primary);
  }

  .csp-square-card--horizontal & {
    text-align: center;
  }
}

.csp-square-card__titleblock {
  @include responsive(font-size, 1.4rem, 1.3rem);
  @include hyphenate;
  hyphens: auto;
  margin: var(--card-outer-padding) 0 var(--card-inner-spacing);
  line-height: 1.2;
  font-weight: bold;
  order: 2;
  flex: 1 0 auto;

  .csp-square-card--condensed & {
    border-top: none;
    padding-left: 0;
    padding-right: 0;
  }

  .tiles--large & {
    @include tablet {
      font-size: 1.6rem;
    }
  }

  .csp-square-card--horizontal & {
    border-top: none;

    @include tablet {
      padding: 0;
      font-size: 1.3rem;

      @include max-tiles(2) {
        font-size: 1.6rem;
      }
    }
  }

  .csp-square-card--placeholder & {
    height: 2em;
  }

  .csp-square-card__details & {
    padding-left: 0;
  }
}

.csp-square-card__title {
  @include responsive(font-size, 1.5rem, 1.3rem);
  @include truncate-text.truncate-text(3);
  margin: 0.1em 0 0.2em 0;
  font-weight: bold;
}

.csp-square-card__eyebrow {
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}

.csp-square-card__category {
  line-height: 1.1;

  span {
    font-size: 1.2rem;
    color: var(--rc-color-text-secondary);
    font-weight: normal;
  }

  span:first-child {
    @include fa-icon-after(angle-right);
    margin-right: $icon-inline-spacing;
  }

  span:only-child::after {
    display: none;
  }

  .csp-square-card--condensed & {
    display: none;
  }
}
