@use 'sass:color';
@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';
@use '@reverbdotcom/cadence/styles/tools/mixins/wrapper';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

// Use same value in item2-sticky-sidebar.scss
$breakpoint-single-column: 750px;

.item2 {
  // prevent watch animation overflow
  @include mobile {
    overflow: clip;
  }
}

.item2_order-banner {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--rc-color-palette-blue-600);
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 90%;

  @include max-width($breakpoint-single-column) {
    font-size: 80%;
    line-height: 1.6;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 1rem;
  }
}

.item2_order-banner__text {
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: 0.1rem solid var(--rc-color-palette-bw-white);
  color: var(--rc-color-palette-bw-white);

  @include max-width($breakpoint-single-column) {
    margin: 0;
    padding: 0;
    border: none;
  }
}

.item2_order-banner__link {
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg.icon-after(arrow-right, scss-tokens.$rc-color-palette-bw-white, $hover-fill: scss-tokens.$rc-color-palette-vu-orange-500);
  color: var(--rc-color-palette-bw-white);
  font-weight: bold;
}

.item2__image {
  border-radius: 0;
  display: block;
  position: relative;

  @include max-width($breakpoint-single-column) {
    padding: 0;
    box-shadow: none;
    border-width: 0 0 0.1rem;
    border-radius: initial;
  }

  .dynamic-page__sidebar & {
    display: none;

    @include max-width($breakpoint-single-column) {
      display: block;
    }
  }

  .lightbox-image__primary {
    .lightbox-image-item {
      background: var(--rc-color-background-module-muted);
    }
  }
}

.item2__watch-badge {
  position: absolute;
  top: var(--rc-space-4);
  right: var(--rc-space-4);
  z-index: 2;

  .item2-image__status-indicator-image-banner ~ & {
    top: 5rem;
  }
}

.item2__outlet-badge {
  position: absolute;
  top: var(--rc-space-4);
  left: var(--rc-space-4);
  z-index: 2;
  border-radius: var(--rc-border-radius-md);
  padding: var(--rc-space-1) var(--rc-space-2);
  font-size: var(--rc-font-size-350);
  background-color: var(--rc-color-palette-tweed-orange-500);
  color: var(--rc-color-text-primary-invert-dark);
  font-weight: var(--rc-font-weight-semibold);
}

.item2__watch-badge__inner {
  position: relative;
}

.item2__sidebar-module {
  &:empty {
    display: none;
  }

  & + .item2__sidebar-module {
    border-top: 0.1rem solid var(--rc-color-border-primary);
    margin-top: 2rem;
    padding-top: 2rem;
  }

  &.item2__sidebar-module--shaded {
    border: 0.1rem solid var(--rc-color-border-primary);
    background-color: var(--rc-color-background-alert-plain);
    padding: 2rem;
    border-radius: var(--rc-border-radius-md);
    border: none;

    & + .item2__sidebar-module {
      border-top: none;
      margin-top: 0;
    }

    .item2__content__section--why-reverb & {
      display: flex;
      flex-wrap: wrap;
      gap: var(--rc-space-5);
      background-color: var(--rc-color-background-alert-info);
      padding: var(--rc-space-5);
      margin: var(--rc-space-2) 0;

      @include max-width($breakpoint-single-column) {
        margin: 0 calc(-1 * var(--rc-space-gutter-page));
        border-width: 0.1rem 0;
        padding: 2rem var(--rc-space-gutter-page);
        border-radius: initial;
      }

      &:empty {
        display: none;
      }
    }

    &.item2__content__section--cpo-outlet {
      gap: var(--rc-space-2);

      &:empty {
        display: none;
      }
    }
  }

  .item2-sticky-sidebar & {
    padding-top: var(--rc-space-4);
    border-top: 0.1rem solid var(--rc-color-border-primary);
    margin-top: var(--rc-space-4)
  }
}

.item2__sidebar-module__header {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1em;
  opacity: 0.7;

  .item2__sidebar-module--shaded & {
    margin-bottom: 0.5rem;
  }
}

.item2__listing-state {
  text-align: center;
  font-weight: bold;
  margin-bottom: -0.5rem;
}

.item2__listing-state__state {
  color: var(--rc-color-text-price-nudge);
}

.item2__listing-state__prompt {
  font-size: 0.8em;
  opacity: 0.7;
  line-height: 1.2;
}

.item2__price-nudge {
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
}

.item2__anyone-can-sell-cta {
  @include site-wrapper;
  margin-bottom: 3rem;

  @include max-width($breakpoint-single-column) {
    display: none;
  }
}

.item2__footer {
  padding-bottom: 2rem;
}

.item2__star-rating {
  margin-right: 2rem;
}

.item2__alt-text {
  margin-right: 2rem;
  font-size: 1.6rem;
  color: var(--rc-color-text-secondary);
}

.item2__star-rating {
  margin-right: 2rem;
}

.item2__alt-text {
  margin-right: 2rem;
  font-size: 1.6rem;
  color: var(--rc-color-text-secondary);
}

.item2__about-this-listing-inner {
  display: flex;
  flex-direction: row;
  gap: var(--rc-space-gap-grid);

  @include tablet {
    flex-direction: column;
  }
}

.item2__about-this-listing-heading {
  @include rc-text.title('900');
  margin-bottom: var(--rc-space-8);
}

.item2__about-this-listing-subheading {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: var(--rc-space-6);
}

.item2__about-specs {
  flex-basis: 50%;

  @include tablet {
    padding-right: 0;
  }
}

.item2__about-description {
  flex-basis: 50%;
  padding-right: 5.6rem;

  @include tablet {
    padding-right: 0;
  }
}

.item2__about-this-listing-report {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-top: 3rem;
}

.item2__content {
  @include site-wrapper;
  @include site-wrapper--desktop;

  display: grid;
  grid-template-columns: auto minmax(45rem, 40%);
  column-gap: var(--rc-space-gap-grid);

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }

  @include max-width($breakpoint-single-column) {
    display: block;
  }

  &:last-of-type {
    margin-bottom: 3rem;
    border-bottom: 0;
  }

  &.item2__content--hidden {
    display: none;
  }
}

.item2__content__section {
  grid-column: 1;
  min-width: 0;

  &:empty {
    display: none;
  }

  &:last-of-type {
    border-bottom: 0.1rem solid var(--rc-color-border-primary);

    .item2__content:last-of-type & {
      border-bottom: none;
    }
  }

  + .item2__content__section {
    border-top: 0.1rem solid var(--rc-color-border-primary);

    &.item2__content__section--buying-guide {
      border-top: none;
    }

    &.item2__content__section--why-reverb {
      border: none;

      @include max-width($breakpoint-single-column) {
        border-top: none;
        margin-top: 0;
      }
    }
  }

  &.item2__content__section--why-reverb {
    + .item2__content__section {
      border-top: none;

      @include max-width($breakpoint-single-column) {
        margin-top: 0;
        border-top: none;
      }
    }
  }

  &.item2__content__section--image {
    @include max-width($breakpoint-single-column) {
      margin: 0 calc(-1 * var(--rc-space-gutter-page));
    }
  }

  &.item2__content__section--buying-guide {
    + .item2__content__section {
      border-top: none;
    }
  }
}

.item2__content__sidebar {
  display: flex;
  flex-direction: column;
  grid-column: 2;
  grid-row: 1 / span 10;
  min-width: 45rem;
  gap: var(--rc-space-gap-grid);
  padding-bottom: 3rem;

  .item2:has(.item2__content--product.item2__content--hidden) & {
    padding-bottom: 0;
  }

  @include tablet {
    min-width: 0;
  }

  @include max-width($breakpoint-single-column) {
    margin-top: 2rem;
  }

  @include max-width($breakpoint-single-column) {
    border-bottom: 0.1rem solid var(--rc-color-border-primary);
    padding-bottom: var(--rc-space-4);
  }

  + .item2__content__section {
    @include max-width($breakpoint-single-column) {
      margin-top: 0;
    }
  }

  .item2__content--product & {
    grid-row: 1 / span 3;

    @include max-width($breakpoint-single-column) {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.item2-breadcrumbs {
  @include site-wrapper;
  @include site-wrapper--desktop;
}

.item2-shop-policies-button__icon {
  width: 3rem;
  height: 3rem;
}

.item2-product-reviews__stars-text {
  @include text-link;

  display: flex;
  color: var(--rc-color-text-primary);
  min-width: 15%;
  font-size: var(--rc-font-size-350);

  @include tablet {
    min-width: 20%;
  }
}

.item2-product-reviews__rating-count-text {
  display: flex;
  justify-content: flex-end;
  min-width: 20%;
  font-size: var(--rc-font-size-350);

  @include tablet {
    min-width: 25%;
  }
}

.item2-product-reviews__rating-count-text {
  display: flex;
  justify-content: flex-end;
  min-width: 20%;
  font-size: var(--rc-font-size-350);

  @include tablet {
    min-width: 25%;
  }
}

.item2-product-reviews__content-text {
  font-size: var(--rc-font-size-350);
}

.item2-product-reviews__content-bold-text {
  @include rc-text.utility('350', 'semibold');
}
