@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.article-search-prompt {
  @include rc-button.muted;
  vertical-align: bottom;
  margin-right: 1em;
  padding: 0.5em 1em;

  @include mobile {
    display: none;
  }

  .holiday-sale-grid & {
    display: none;
  }
}
