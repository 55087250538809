.sell-search-input {
  .rc-form-group__fields {
    position: relative;
    margin-bottom: .3rem;
    z-index: 2;
  }

  &.sell-search-input--active {
    .rc-form-group__fields {
      @include box-shadow;
      transition: box-shadow .2s;
    }
  }
}

.sell-search-input__underlay {
  position: relative;
  z-index: 1001;

  &::before {
    content: '';
    height: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--rc-color-shadow-600);
    transform: translateZ(0); // transition performance hack
    transition: opacity .25s;
  }

  &.sell-search-input__underlay--active {
    &::before {
      z-index: 1;
      display: block;
      height: auto;
      opacity: 1;
    }
  }

  .sell-search-input--active {
    .sell-search-input__dropdown {
      box-shadow: none;
    }
  }
}

.sell-search-input__lowlight-title {
  font-weight: 700;

  b {
    font-weight: 400;
  }
}

