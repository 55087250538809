.fancybox-opened .fancybox-skin {
  background: var(--rc-color-palette-bw-white);
  color: var(--rc-color-text-primary);
  border-radius: 0;
  .lightbox-only {
    display: block;
  }
}

// Ensure that chosen dropdowns or dynamic content
// remains visible and doesn't get cut off by the fancybox frame.
.fancybox-inner {
  overflow: auto !important;
  .heading {
    border-radius: 0 !important;
    padding: 10px 18px;
  }
}

.lb_overlay {
  opacity: 0.45;
}

.fancybox-skin {
  -moz-border-radius:    0px !important;
  -webkit-border-radius: 0px !important;
  border-radius:         0px !important;
}

a.fancybox-close {
  transition: all 0.25s;
  background: none;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  background: var(--rc-color-palette-gray-900);
  border: 3px solid var(--rc-color-palette-gray-100);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    top: -15px;
    right: -9px;
    width: 24px;
    height: 24px;
  }
}

a.fancybox-close:before {
  color: var(--rc-color-palette-gray-100);
  font-size: 24px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: '\f00d';

  @include mobile {
    font-size: 18px;
    top: 3px;
    left: 5px;
  }
}

a.fancybox-close:active {
  background: var(--rc-color-palette-gray-300);
}

a.dismiss {
  top: 0;
  right: 4px;
}

.fancybox-overlay {
  background: var(--rc-color-shadow-600);
}

.fancybox-wrap {
  animation: fade-in-up 0.2s 1 ease-out forwards;
}

.fancybox-outer {
  @include mobile {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
