// set up the wrapper container to make the stars show up in a row
.rc-star-rating-selector {
  display: flex;
  color: var(--rc-color-text-star);
}

// Hide the actual native <input /> element and the alternate label
.rc-star-rating-selector__input,
.rc-star-rating-selector__alt-label {
  @include visually-hidden;
}

// Set the base size of the star
// Position relative so the absolute positioning of the child icons works
.rc-star-rating-selector__star {
  height: 2.4rem;
  width: 2.4rem;
  position: relative;
  cursor: pointer;
}

// set position of empty and filled stars to both be absolute
.rc-star-rating-selector__icon {
  position: absolute;
  transition: opacity 0.2s ease;
}

// by default, the filled icons should be hidden
// but sit on top of the visible empty stars
.rc-star-rating-selector__icon--filled {
  z-index: 2;
  opacity: 0;
}

.rc-star-rating-selector__icon--empty {
  z-index: 1;
  opacity: 1;
}

.rc-star-rating-selector__input:focus-visible + .rc-star-rating-selector__star {
  @include focus-outline;
}

// set all stars to be on when the container is hovered
.rc-star-rating-selector:hover .rc-star-rating-selector__icon--filled {
  opacity: 0.5;
}

// ... then, unset the siblings
.rc-star-rating-selector:hover .rc-star-rating-selector__star:hover ~ .rc-star-rating-selector__star > .rc-star-rating-selector__icon--filled {
  opacity: 0;
}

// invert the opacity of the filled star for the checked stars
.rc-star-rating-selector__input--checked + .rc-star-rating-selector__star > .rc-star-rating-selector__icon--filled {
  opacity: 1;
}
