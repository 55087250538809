@use '@reverbdotcom/cadence/styles/config/configuration' as config;
@use '@reverbdotcom/cadence/styles/tools/mixins/responsive-hover' as hover;
@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow' as shadow;
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-image' as rc-image;
@use '@reverbdotcom/cadence/styles/tools/mixins/theme';
@use '@reverbdotcom/cadence/styles/tools/mixins/aspect-ratio';
@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.marketing-callout-sidebar-container {
  @include gap-row;

  .dynamic-page__sidebar-mobile-overflow & {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 2rem;
  }
}

.marketing-callout {
  width: 100%;
  background: var(--rc-color-background-card);
  border-radius: var(--rc-border-radius-lg);
  transition: box-shadow 0.15s ease, background-color 0.15s ease;
  overflow: hidden;

  @include hover.responsive-hover {
    @include shadow.box-shadow;

    background-color: var(--rc-color-background-card-hovered);
  }

  + .marketing-callout {
    .marketing-callout-sidebar-container & {
      margin-top: 2rem;
    }
  }

  &.marketing-callout--placeholder {
    @include loading-pulse;
  }

  &.homepage-search-recommendations {
    position: relative;
  }
}

.marketing-callout__inner {
  display: flex;
  color: var(--rc-color-text-primary);
  flex-wrap: wrap;
  height: 100%;
  flex-direction: column;

  .marketing-callout--wide & {
    @include responsive(min-height, 16rem, 14rem, 12rem);
    align-items: stretch;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .item2__content__section--buying-guide & {
    flex-direction: row;
  }
}

.marketing-callout__text-area {
  padding: var(--rc-space-5);
  color: var(--rc-color-text-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  .marketing-callout--wide & {
    flex: 1;
    box-sizing: border-box;
    padding: 2% 4%;
    margin: 0;
    max-width: none;
  }

  .marketing-callout--small & {
    justify-content: flex-start;
  }

  .marketing-callout--square & {
    background: var(--rc-color-palette-tweed-orange-400);
    justify-content: flex-start;
    padding: var(--rc-space-6);
  }

  .csp-tiles & {
    flex: initial;
  }
}

.marketing-callout__image-area {
  width: 100%;

  .marketing-callout--wide & {
    @include responsive((width flex-basis), 16rem, 14rem, 12rem);
  }

  .item2__content__section--buying-guide & {
    width: 25%;
  }

  .csp-tiles & {
    flex: 1;
  }

  .homepage-search-recommendations & {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.1rem;
  }

  .homepage-trending-searches & {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.1rem;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.marketing-callout__image {
  @include commons-svg-background('instruments-darken.svg');
  @include responsive(height, 16rem, 14rem, 12rem);
  @include rc-image.overlay;

  background-repeat: repeat;
  background-position: center;
  position: relative;
  border-radius: var(--rc-border-radius-lg) var(--rc-border-radius-lg) 0 0;

  // with background image
  &[style] {
    background-size: cover;
  }

  .dynamic-page__sidebar & {
    height: 12rem;
  }

  .marketing-callout--wide &,
  .marketing-callout--square & {
    height: 0;
    padding-bottom: 100%;
  }

  .item2__content__section--buying-guide & {
    height: 10rem;
    border-radius: var(--rc-border-radius-lg) 0 0 var(--rc-border-radius-lg);

    @include mobile {
      min-height: 7.5rem;
    }
  }

  .marketing-callout__ad {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .csp-tiles & {
    height: 100%;
    min-height: 20rem;
  }

  .homepage-trending-searches & {
    @include aspect-ratio.aspect-ratio(1, 1);
    border-radius: 0;
    height: auto;
  }

  .homepage-search-recommendations & {
    border-radius: 0;
  }
}

.marketing-callout__title {
  font-size: 1.6rem;
  line-height: 1.25; // 2rem
  font-weight: bold;

  .tiles__tile:only-child & {
    .dynamic-page__two-col__main &,
    .dynamic-page__one-col & {
      @include responsive(font-size, 1.4em, 1.2em, 1em);
    }
  }

  .marketing-callout--placeholder & {
    height: 1em;
    width: 30%;
  }

  .marketing-callout--square & {
    @include rc-text.title('700');
  }
}

.marketing-callout__tag,
.marketing-callout__subtitle {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1.333; // 1.6rem
  text-transform: uppercase;
  color: var(--rc-color-text-primary);

  .marketing-callout--square & {
    font-size: 2.0rem;
    font-weight: var(--rc-font-weight-medium);
    color: var(--rc-color-text-primary);
    padding-bottom: var(--rc-space-2);
    text-transform: none;
  }

  .marketing-callout--small & {
    display: none;
  }
}

.marketing-callout__primary {
  display: flex;
  align-items: flex-start;
  gap: var(--card-padding);
  flex-grow: 1;
}

.marketing-callout__cta {
  display: flex;
  align-items: center;
  gap: var(--action-gap);
  position: absolute;
  top: var(--rc-space-4);
  right: var(--rc-space-4);
  z-index: 2;
}

// used in homepage_trending_searches.tsx

.marketing-callout__text__query {
  @include truncate-text.truncate-text;
  @include rc-text.utility('400', 'bold');

  margin-bottom: 0.5rem;
  color: var(--rc-color-text-primary);
}

.marketing-callout__text__category {
  @include rc-text.utility('350', 'semibold');
  color: var(--rc-color-text-primary);
}
