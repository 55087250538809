@use 'sass:map';
@use '@reverbdotcom/cadence/styles/tools/modules/condition';

.condition-display {
  // due to how Webkit won't render inline-block heights smaller than the font-size
  font-size: 0;
  line-height: 0;

  .item2-title & {
    display: inline-block;
  }
}

.condition-display__label {
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1;
  white-space: nowrap;

  .dynamic-page__sidebar &,
  .featured-listing-header & {
    font-size: 1.3rem;
  }

  .condition-display--text-only & {
    font-size: 1.3rem;
    font-weight: normal;
  }

  .condition-display--tag & {
    display: inline-block;
    padding: 0.2rem 0.5rem 0.5rem;
    border-radius: $input-border-radius;
    background-color: var(--rc-color-background-tag);
    font-size: 1.4rem;
  }
}

.condition-display__bars {
  margin-top: 0.3rem;
  height: 0.3rem;
  // stylelint-disable-next-line declaration-property-unit-allowed-list -- sets specific box height
  line-height: 0.3rem;
  font-size: 0.3rem;
  position: relative;
  width: 5.3rem;
  display: inline-block;

  span {
    display: inline-block;
    margin-left: 0.2rem;
    width: 0.4rem;
    height: 0.3rem;
    background: var(--rc-color-palette-gray-200);

    + span {
      margin-left: 0.2rem;
    }
  }

  .condition-display--text-only & {
    display: none;
  }
}

@each $tier in condition.$tiers {
  .condition-display--#{map.get($tier, 'name')} {
    color: map.get($tier, 'color');

    .condition-display__label {
      color: currentColor;
    }

    .condition-display__bars {
      span:nth-of-type(-n+#{map.get($tier, 'bar-count')}) {
        background-color: currentColor;
      }
    }
  }
}

.condition-display--text-only {
  .condition-display__label {
    color: var(--rc-color-text-primary);
  }
}
