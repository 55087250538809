// fixed width for contents of address modal on desktop
// modal dialog max-width area is 60rem - padding

.shipping-address-selector {
  width: 50rem;

  @include mobile {
    width: auto;
  }
}
