.intl-callout {
  @include scaling((padding-top padding-bottom), 6rem);
  background: var(--rc-color-palette-bw-white) url('#{$discovery-ui-path}/images/icons/tiled-pattern-gray.svg') repeat-x;
  background-size: auto 100%;
  text-align: center;
}

.intl-callout__heading {
  @include responsive(max-width, 60%, 70%, 80%);
  @include scaling(font-size, 1.8em);
  display: inline-block;
  margin: 0 auto;
  background: var(--rc-color-palette-bw-white);
  color: var(--rc-color-palette-vu-orange-500);
  padding: 0.7em;
  font-weight: bold;
  line-height: 1;

  small {
    @include scaling(font-size, 2.2rem);
    display: inline-block;
    padding-top: 0.5em;
    font-weight: 300;
    color: var(--rc-color-text-primary);
  }
}
