.sell-instrument-callout {
  @include site-wrapper;

  display: flex;
  text-align: center;
  align-items: center;

  @include mobile {
    text-align: left;
    margin-top: 2rem;
  }
}

.sell-instrument-callout__img {
  flex: 0 0 30%;
  align-self: flex-end;
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  @include mobile {
    flex: 0 0 15rem;
    min-height: 15rem;

    img {
      position: absolute;
      width: 20rem;
      right: 0;
      bottom: 0;
    }

    &:last-child {
      display: none;
    }
  }
}

.sell-instrument-callout__text {
  flex: 1;
  margin: 0;
  padding: 1.5rem 0;

  h2 {
    @include responsive(font-size, 2.2em, 1.8em, 1.4em);

    font-weight: 300;
    margin-bottom: 0.5em;
  }

  p {
    @include responsive(font-size, 1.3em, 1.2em, 1.1em);
    line-height: 1.3;

    @include tablet {
      margin-bottom: 1rem;
    }
  }
}
