@use '@reverbdotcom/cadence/styles/tools/mixins/responsive' as breakpoint;
@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow' as shadow;

.favorites-recommended {
  --thumbnail-width: 5.5rem;
  --card-padding: var(--rc-space-4);
  --action-width: 3.2rem;
  --action-gap: 1rem;

  background-color: var(--rc-color-background-module);
  border: solid 0.1rem var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-md);
  box-sizing: border-box;
  position: relative;
  min-height: calc(var(--thumbnail-width) + (var(--card-padding) * 2));
  padding: var(--card-padding);
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  box-shadow: none;
  transition: box-shadow 0.1s ease;

  &:hover {
    @include shadow.box-shadow;
  }

  // extra left padding for absolute-positioned thumbnail
  padding-left: calc(var(--thumbnail-width) + (var(--card-padding) * 2));
  padding-right: calc(var(--action-width) + (var(--card-padding) * 2));

  &.favorites-recommended__placeholder {
    @include loading-pulse;
  }

  @include breakpoint.tablet {
    --action-width: 3.2rem;

    // stylelint-disable-next-line declaration-no-important
    padding: 0 !important;
    min-height: auto;
    gap: var(--rc-space-0);
  }
}

.favorites-recommended__primary-content {
  display: flex;
  align-items: flex-start;
  gap: var(--card-padding);
  flex-grow: 1;

  @include breakpoint.tablet {
    padding: var(--card-padding);
  }
}

.favorites-recommended__thumbnail {
  position: absolute;
  width: var(--thumbnail-width);
  top: var(--card-padding);
  left: var(--card-padding);

  @include breakpoint.tablet {
    --thumbnail-width: 4.0rem;

    flex: 0 0 var(--thumbnail-width);
    position: static;
  }
}

.favorites-recommended__thumbnail-link {
  display: block;
  width: 100%;
}

.favorites-recommended__thumbnail-image {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: var(--rc-border-radius-sm)
}

.favorites-recommended__titleblock {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-half);
  min-width: 1%; // to ensure word-break in __title works properly
}

.favorites-recommended__title {
  color: var(--rc-color-text-primary);
  font-size: 1.6rem;
  font-weight: bold;
  display: block;
  white-space: normal;
  word-wrap: break-word;

  @include breakpoint.tablet {
    overflow-y: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  &:hover {
    color: var(--rc-color-text-primary);
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.favorites-recommended__subtitle {
  font-size: 1.2rem;
  color: var(--rc-color-text-secondary);
  display: block;
}

.favorites-recommended__secondary-content {
  @include breakpoint.tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--rc-space-2) var(--card-padding);
  }
}

.favorites-recommended__cta {
  display: flex;
  align-items: center;
  gap: var(--action-gap);
  position: absolute;
  top: var(--card-padding);
  right: var(--card-padding);
  z-index: 2;
}

.favorites-recommended__placeholder-title {
  width: 18rem;
  height: 1.2em;
  background: var(--rc-color-palette-gray-300);
  margin: 0.5rem;
}

.favorites-recommended__placeholder-subtitle {
  width: 12rem;
  height: 1.2em;
  background: var(--rc-color-palette-gray-300);
  margin: 0.5rem;
}
