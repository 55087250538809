@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.search-pills {
  @include clearfix;
  clear: both;

  @include mobile {
    overflow-x: scroll;
    margin-left: calc(-1 * var(--rc-space-gutter-page));
    margin-right: calc(-1 * var(--rc-space-gutter-page));
    padding-left: var(--rc-space-gutter-page);
  }
}

.search-pills__label {
  display: inline-block;
  margin-right: 0.5rem;
  font-weight: bold;

  @include mobile {
    display: none;
  }
}

.search-pill {
  display: inline-block;
  white-space: nowrap;
  font-size: 1.4rem;

  .not-found__remove-shipping & {
    margin: 1em 0;
  }
}

.search-pill__term {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  line-height: 1;
  font-size: 1.4rem;
  border: 0.1rem solid var(--rc-color-border-primary);
  background: var(--rc-color-palette-bw-white);
  padding: 0.5em 2em 0.5em 0.8em;
  border-radius: $input-border-radius;

  + .search-pill__term {
    margin-left: -0.7rem;
    padding-left: 1em;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      border: 1em solid transparent;
      top: -0.1rem;
      bottom: -0.1rem;
    }

    &::before {
      left: 0;
      border-right: none;
      border-left-width: 0.5em;
      border-left-color: var(--rc-color-border-primary);
    }

    &::after {
      left: -0.1rem;
      border-left-width: 0.5em;
      border-left-color: var(--rc-color-palette-bw-white);
    }
  }
}

.search-pill__remove {
  @include button-reset;
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg.icon-bg(close, scss-tokens.$rc-color-palette-gray-900);

  position: absolute;
  background-size: 65%;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1.8em;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity .1s;

  @include responsive-hover {
    opacity: 1;
  }
}
