@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

.product-feature-card {
  @include shadow-on-hover;
  position: relative;
  background: var(--rc-color-palette-bw-white);
  border-radius: 0.4rem;
  padding: 3%;
  margin-bottom: $grid-gutter;
  transition: box-shadow .2s;

  a {
    display: flex;
    width: 100%;
    color: var(--rc-color-text-primary);
    align-items: center;
  }

  &.product-feature-card--placeholder {
    @include loading-pulse;
  }

  .bump-row & {
    margin-bottom: 0;
  }

  &.product-feature-card--remove-padding {
    padding: 0;
  }
}

.product-feature-card__image {
  box-sizing: border-box;
  flex: 0 1 40%;
  max-width: 20rem;
  margin-right: 3%;
  background: var(--rc-color-palette-gray-100);
  position: relative;
  border: 0.1rem solid var(--rc-color-border-primary);

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .product-feature-card--placeholder & {
    padding-bottom: 40%;
  }
}

.product-feature-card__content {
  flex: 1;
}

.product-feature-card__bump-tag {
  padding: 0.2em 0;
  margin: 0 0 0.25em;
}

.product-feature-card__content__title {
  @include truncate-text.truncate-text(
    $font-size: 2.6rem,
    $line-height: 1.2,
    $lines-to-show: 3
  );

  font-weight: bold;
  margin-bottom: 0.3em;

  @include mobile {
    @include truncate-text.truncate-text(
      $font-size: 1.6rem,
      $line-height: 1.3,
      $lines-to-show: 3
    );
  }

  .product-feature-card--placeholder & {
    span {
      @include placeholder-text($height: 100%, $width: 80%);
    }
  }
}

.product-feature-card__content__byline {
  font-size: 85%;
  opacity: 0.7;
  margin-bottom: 0.5em;

  .product-feature-card--placeholder & {
    @include placeholder-text($width: 60%);
    color: transparent;
  }
}

.product-feature-card__content__description {
  @include truncate-text.truncate-text(
    $font-size: 1.6rem,
    $line-height: 1.5,
    $lines-to-show: 4,
    $fixed-height: true
  );

  margin-bottom: 1em;

  @include mobile {
    display: none;
  }

  .product-feature-card--placeholder & {
    span {
      @include placeholder-text($width: 65%);
    }

    span + span {
      width: 40%;
    }
  }
}

.product-feature-card__content__price {
  font-weight: bold;
  font-size: 2.6rem;
  color: var(--rc-color-text-primary);
  display: inline-block;
  margin-right: 2em;
  line-height: 1;
  vertical-align: middle;

  @include mobile {
    display: block;
    margin-bottom: 0.3em;
    font-size: 2.2rem;
  }

  .product-feature-card--placeholder & {
    @include placeholder-text($height: 100%, $width: 20%);
    font-size: 2.6rem;
  }
}

.product-feature-card__content__price__current {
  .product-feature-card__content__price__original + & {
    white-space: nowrap;
    color: var(--rc-color-text-price-nudge);
  }
}

.product-feature-card__content__price__original {
  display: inline-block;
  opacity: 0.5;
  margin-right: 0.3em;
  text-decoration: line-through;
  white-space: nowrap;
}

.product-feature-card__content__condition {
  display: inline-block;
  vertical-align: middle;

  @include mobile {
    display: block;
  }

  .product-feature-card--placeholder & {
    opacity: 0;
  }
}
