@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.item-page-protection-plan {
  display: flex;
  padding: var(--rc-space-4);
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;
  align-self: stretch;

  border-radius: var(--rc-border-radius-md);
  background: var(--rc-color-background-alert-plain);

  .item-page-protection-plan__checkbox-wrapper {
    display: flex;
    align-items: flex-start;
    gap: var(--rc-space-3);
    align-self: stretch;
  }

  .item-page-protection-plan__checkbox {
    display: flex;
    align-items: flex-start;
    gap: var(--rc-space-2);
    flex: 1 0 0;
    border-radius: 0.8rem;
  }

  .item-page-protection-plan__change-plan {
    @include button-as-link;
    @include rc-text.fine('300');

    flex: 1 0 0;
    color: var(--rc-color-text-mulberry);
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    text-decoration: underline;
  }

  .item-page-protection-plan__body--bold {
    color: var(--rc-color-text-mulberry);
    font-weight: bold;
  }
}


