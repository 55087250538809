@use 'sass:math';
@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

.product-card {
  @include shadow-on-hover;
  background: var(--rc-color-palette-bw-white);
  position: relative;
  transition: box-shadow 0.2s, background .2s;
  width: 100%;

  > a {
    display: block;
  }

  &:hover {
    background: var(--rc-color-palette-bw-white);
  }

  &.product-card--bordered {
    border: 0.1rem solid var(--rc-color-border-primary);
  }

  &.product-card--placeholder {
    @include loading-pulse;
  }
}

.product-card__actions {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;

  .product-card:hover & {
    opacity: 1;
  }

  @include tablet {
    display: none;
  }
}

.product-card-img-container {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 100%;
  background: var(--rc-color-palette-gray-100) no-repeat;
  transition: opacity .4s ease-in-out;

  &:hover {
    .product-card-img-container__actions {
      opacity: 1;
    }
  }

  img {
    display: block;
    width: 100.5%;
    height: auto;
    margin-left: -0.25%;
  }

  .product-card-img-container__actions {
    position: absolute;
    top: 50%;
    text-align: center;
    left: 0;
    right: 0;
    opacity: 0;
    transform: translateY(-50%);
    transition: opacity .4s ease-in-out;
  }

  &.product-card-img-container--gift-card-img {
    img {
      box-sizing: border-box;
      padding: 2rem;
    }
  }

  .product-card--brand & {
    padding-bottom: 81%;
  }
}

.product-card-img-container__prefetch {
  display: none;
}

.product-card-img-container__slides {
  position: absolute;
  width: 300%;
  left: -100%;
  height: 100%;
  transition: none;
  backface-visibility: hidden;

  .product-card-img-container__slide {
    position: absolute;
    width: math.percentage(math.div(1, 3));
    left: 0;
    height: 100%;
    background: var(--rc-color-palette-gray-200) center no-repeat;
    background-size: 70% auto;

    + .product-card-img-container__slide {
      left: math.percentage(math.div(1, 3));
    }

    + .product-card-img-container__slide + .product-card-img-container__slide {
      left: math.percentage(math.div(2, 3));
    }
  }

  .product-card-img-container--click-left & {
    left: 0;
    transition: left .6s ease-in-out;
  }

  .product-card-img-container--click-right & {
    left: -200%;
    transition: left .6s ease-in-out;
  }
}

.product-card-img-container__slide {
  background: var(--rc-color-background-module-secondary);
}

.product-card__img-control {
  position: absolute;
  top: 50%;
  margin-top: - 2rem;
  height: 4rem;
  width: 4rem;
  text-align: center;
  opacity: 0.6;
  background: transparent center no-repeat;
  background-size: 50% auto;
  transition: left .2s, right .2s, opacity .2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0;
  border: none;
  cursor: pointer;

  &.product-card__img-control--left {
    left: 0;
    background-image: url('#{$discovery-ui-path}/images/angle-left.svg');
  }

  &.product-card__img-control--right {
    right: 0;
    background-image: url('#{$discovery-ui-path}/images/angle-right.svg');
  }

  &:hover {
    opacity: 1;
  }

  .listing-grid__item--condensed &,
  .card-grid--6up & {
    width: 3.5rem;
  }

  @include mobile {
    height: 3rem;
    width: 3rem;
    margin-top: - 1.5rem;
  }
}

.product-card-description {
  @include clearfix;
  padding: 0.8rem;
  border-top: 0.1rem solid var(--rc-color-border-primary);

  .price-footer {
    @include clearfix;
    position: relative;
    overflow: hidden;
    height: 3rem;

    .product-card--software & {
      background: url('#{$discovery-ui-path}/images/download-icon.svg') bottom right no-repeat;
      background-size: auto 2.2rem;

      @include mobile {
        background-size: auto 1.6rem;
      }
    }

    > a {
      @include clearfix;
      display: block;
    }

    @include mobile {
      height: 2.4rem;
    }
  }
}

.product-card-body-sized {
  @include truncate-text.truncate-text(
    $font-size: 1.4rem,
    $line-height: 1.3,
    $lines-to-show: 2,
    $fixed-height: true
  );
  color: var(--rc-color-text-primary);
  font-weight: 700;
  hyphens: auto;

  @include mobile {
    @include truncate-text.truncate-text(
      $font-size: 1.2rem,
      $line-height: 1.3,
      $lines-to-show: 2,
      $fixed-height: true
    );
  }

  .product-card--placeholder & {
    span {
      @include placeholder-text($width: 80%);
    }

    span + span {
      width: 60%;
    }
  }
}

.product-card__price {
  padding-top: 0.8rem;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1;
  color: var(--rc-color-text-primary);
  margin-right: 0.5em;

  @include mobile {
    font-size: 1.3rem;
  }

  .price-footer & {
    float: left;
  }

  .product-card--pricedrop & {
    color: var(--rc-color-text-price-nudge);
  }

  .product-card--placeholder & {
    span {
      @include placeholder-text($width: 4rem);
    }
  }
}

.product-card__condition {
  float: right;
  padding-top: 0.9rem;

  .listing-grid--condensed &,
  .card-grid--6up & {
    display: none;
  }
}

.product-card__search-path {
  text-overflow: ellipsis;
  font-size: 1.2rem;
  padding-top: 0.8rem;

  a {
    color: var(--rc-color-text-secondary);
  }

  @include mobile {
    display: none;
  }
}

.product-card__action {
  margin-top: 0.8rem;
}

.product-card__curation {
  padding: 0.5rem;
  border-top: 0.1rem solid var(--rc-color-border-primary);
  font-weight: bold;
  font-size: 1.1rem;
}

.product-card__notes__wrapper {
  position: relative;
  z-index: 2;

  // 1 line multiline-truncation + 0.8rem vertical padding + 0.1rem border
  margin-bottom: 2.46rem;

  @include mobile {
    margin-bottom: 0;
  }
}

.product-card__notes {
  position: absolute;
  left: 2.5%;
  width: 95%;
  padding: 0.4rem 0.5rem;
  background-color: var(--rc-color-palette-gray-100);
  box-sizing: border-box;
  border-radius: 0 0 0.4rem 0.4rem;
  box-shadow:
    0 0.1rem 0.3rem 0 var(--rc-color-shadow-700),
    inset 0 0.6rem 0.3rem -0.6rem var(--rc-color-shadow-700);

  @include mobile {
    position: relative;
    left: 0;
    width: auto;
    right: 0;
    box-shadow: none;
    border-top: 0.1rem solid var(--rc-color-border-primary);
  }
}

.product-card__notes__text {
  @include truncate-text.truncate-text(
    $font-size: 1.2rem,
    $line-height: 1.3,
    $fixed-height: false
  );
  transition: max-height .25s ease-in-out;

  .product-card__notes__wrapper:hover & {
    cursor: default;
    max-height: 100rem;
    text-overflow: initial;
    -webkit-line-clamp: initial;
  }

  @include mobile {
    @include truncate-text.truncate-text(
      $font-size: 1.1rem,
      $line-height: 1.3,
      $lines-to-show: 10,
      $fixed-height: false
    );

    max-height: none;
    text-overflow: initial;
    -webkit-line-clamp: initial;
  }
}

.product-card__listing-prompt {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  font-weight: bold;
  font-size: 1.3em;
  background: var(--rc-color-palette-gray-100);
  border-radius: 0.4rem;
  transition: background .2s, color .2s;

  div {
    margin-top: 50%;
    transform: translateY(-50%);

    &::before {
      @include fa-icon(plus);
      display: block;
      margin-bottom: 0.2em;
      font-size: 2em;
    }
  }

  &:hover {
    background: transparent;
  }
}
