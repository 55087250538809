.rc-checkable-label {
  flex-grow: 1;
  padding-left: var(--rc-space-gap-checkbox-label);
  align-content: center;

  .rc-switch & {
    min-height: var(--rc-size-height-switch);
  }
}

.rc-checkable-label__label {
  display: block;
  text-align: left;
  max-width: 100%;
  word-wrap: break-word;
  font-size: var(--rc-font-size-350);
  font-weight: var(--rc-font-weight-semibold);
  cursor: pointer;

  .rc-radio-card--disabled &,
  .rc-radio--disabled &,
  .rc-checkbox--disabled & {
    cursor: not-allowed;
  }

  // allows the padded area to be clickable on a radio card
  &::before {
    .rc-radio-card & {
      content: '';
      position: absolute;
      inset: calc(-1 * var(--radio-card-padding));
    }
  }
}

.rc-checkable-label__sublabel {
  display: block;
  font-size: var(--rc-font-size-350);
  font-weight: normal;
  color: var(--rc-color-text-secondary);

  .rc-checkable-label + & {
    margin-top: var(--rc-space-half)
  }
}
