@use 'sass:map';
@use '../tools/mixins/rc-text';
@use '../tools//mixins/responsive';

$breakpoints: 'desktop', 'tablet', 'mobile';

@mixin _getBreakpoint($breakpoint) {
  @if ($breakpoint == 'tablet') {
    @include responsive.tablet {
      @content;
    }
  } @else if ($breakpoint == 'mobile') {
    @include responsive.mobile {
      @content;
    }
  } @else {
    @content;
  }
}

// Text style classes

@each $breakpoint in $breakpoints {
  $breakpointPrefix: if($breakpoint == 'mobile', 'mobile-', if($breakpoint == 'tablet', 'tablet-', ''));

  @include _getBreakpoint($breakpoint) {
    @each $variant in rc-text.$valid-variants {
      @each $size in map.get(rc-text.$valid-sizes, $variant) {
        // e.g. `.text-display-1600`, `.text-title-900`, `.text-utility-400`
        .#{$breakpointPrefix}text-#{$variant}-#{$size} {
          @if ($variant == 'default') {
            @include rc-text.default($size: $size);
          }

          @if ($variant == 'display') {
            @include rc-text.display($size: $size);
          }

          @if ($variant == 'eyebrow') {
            @include rc-text.eyebrow($size: $size);
          }

          @if ($variant == 'fine') {
            @include rc-text.fine($size: $size);
          }

          @if ($variant == 'title') {
            @include rc-text.title($size: $size);
          }

          @if ($variant == 'utility') {
            @include rc-text.utility($size: $size);
          }
        }
      }

      // This produces slightly more lines of CSS, but it gives us some measure of style safety
      @each $weight in map.get(rc-text.$valid-weights, $variant) {
        // e.g. `.text-display--regular`, `.text-utility--semibold`
        [class*='text-#{$variant}'] {
          &.#{$breakpointPrefix}text-#{$variant}--#{$weight} {
            font-weight: #{map.get(rc-text.$weight-variables, $weight)};
          }
        }
      }
    }
  }
}

// Text truncation

[class*='text-truncate'] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@include build-responsive-classes((
  valueList: (1, 2, 3, 4, 5),
  attributes: (
    text-truncate: -webkit-line-clamp,
  )
));
