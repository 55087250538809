@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.hp-sale-banner-new__wrapper {
  background: var(--rc-color-palette-bw-white);
}

.hp-sale-banner-new {
  @include site-wrapper;
  @include scaling(padding-top, 2rem);
}

.hp-sale-banner-new__inner {
  @include responsive(min-height, 35rem, 25rem, 15rem);
  margin: 0 auto;
  display: flex;
  align-items: center;
  max-width: $site-width-max;
  background-color: var(--rc-color-palette-gray-200);
  background-size: cover;
  background-position: 75%; // prioritizes right side image content.
  background-repeat: no-repeat;
  color: var(--rc-color-text-primary);
  transition: opacity 0.2s ease-in-out;
  position: relative;

  @include responsive-hover {
    color: var(--rc-color-text-primary);
    opacity: 0.95;
  }

  @include mobile {
    @include box-shadow;

    @include responsive-hover {
      @include box-shadow;
    }
  }
}

.hp-sale-banner-new__content {
  @include scaling(padding-top, 4rem);
  @include scaling(padding-bottom, 4rem);
  box-sizing: border-box;
  padding-left: 3vw;
  width: 60%;

  &.hp-sale-banner-new__content--light {
    color: var(--rc-color-palette-bw-white);
  }
}

.hp-sale-banner-new__subtext {
  @include responsive(font-size, 1.4em, 1.1em, 0.9em);
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.1;

}

.hp-sale-banner-new__headline {
  @include scaling(margin-bottom, 2rem);
  @include responsive(font-size, 2.5em, 1.8em, 1.2em);
  line-height: 1.1;
}

.hp-sale-banner-new__cta {
  @include rc-button.muted;

  @include mobile {
    font-size: 1.2rem;
  }
}

.hp-sale-banner-new__ad {
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--rc-color-highlight-700);
  padding: 0.4rem 1.2rem;
  font-size: 1em;
  font-style: italic;
}
