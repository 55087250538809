@use '../tools/mixins/dialogs';
@use '../tools/mixins/focus-outline';
@use '../tools/mixins/button-reset';
@use '../tools/mixins/rc-text';

.rc-tooltip {
  @include dialogs.panel;
  z-index: var(--z-index-tooltip);
  opacity: 1;
  transform: translate(0, 0);
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(.4, 0.0, 0.2, 1); // Material's FastOutSlowIn

  // Animations
  &[data-status='open'] {
    transition-duration: 0.2s;
  }

  &[data-status='close'] {
    transition-duration: 0.1s;
  }

  &[data-status='initial'],
  &[data-status='close'] {
    opacity: 0;
  }

  &[data-status='initial'] {
    &[data-placement^='top'] {
      transform: translate(0, var(--rc-space-2));
    }

    &[data-placement^='bottom'] {
      transform: translate(0, calc(-1 * var(--rc-space-2)));
    }

    &[data-placement^='left'] {
      transform: translate(var(--rc-space-2), 0);
    }

    &[data-placement^='right'] {
      transform: translate(calc(-1 * var(--rc-space-2)), 0);
    }

    // don't transition position for labels
    &.rc-tooltip--label {
      transform: translate(0, 0);
    }

    // don't transition opacity or position in instant transition phase
    &[data-instant='true'] {
      opacity: 1;
    }
  }

  &.rc-tooltip--popover {
    @include rc-text.utility('400');
    max-width: 35rem;
    box-sizing: border-box;
    padding: var(--rc-space-4);

    @include mobile {
      max-width: calc(100vw - 2rem);
    }
  }

  &.rc-tooltip--label {
    @include rc-text.utility('350', 'semibold');
    text-align: center;
    padding: var(--rc-space-2);
    max-width: 15rem;
    border-radius: var(--rc-border-radius-sm);
    color: var( --rc-color-palette-bw-white);
    background-color: var(--rc-color-palette-gray-800);
    border-color: transparent;
    box-shadow: none;
  }
}

.rc-tooltip__arrow {
  display: none;

  .rc-tooltip--label & {
    @include dialogs.arrow($border-width: 0, $fill-color: var(--rc-color-palette-gray-800));
    display: block;
  }
}

.rc-tooltip__trigger {
  @include button-reset.button-reset;
  cursor: default;
  font-weight: inherit;
  color: inherit;
  text-align: inherit;
}

.rc-tooltip__trigger__inner {
  .rc-tooltip__trigger--underline & {
    border-bottom: dashed 0.1rem currentColor;
    position: relative;
  }

  .rc-tooltip__trigger--icon & {
    margin-right: 0.25rem;
  }
}
