@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow';

.brand-grid__section {
  .collection-header + & {
    padding-top: 0;
  }

  &.brand-grid__section--csps {
    background: var(--rc-color-palette-gray-100);
    border-top: 0.1rem solid var(--rc-color-border-primary);
    border-bottom: 0.1rem solid var(--rc-color-border-primary);
  }

  &.brand-grid__section--footer {
    border-top: 0.1rem solid var(--rc-color-border-primary);
    background: var(--rc-color-palette-gray-300);
    padding: 0.1rem 0;
  }
}

.best-selling-brand-card {
  &:hover {
    @include box-shadow.box-shadow;
  }
}
