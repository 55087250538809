.homepage-mkt__wrapper {
  @include responsive(padding-top, 3%, 5%, 7%);
  overflow: hidden;
}

.homepage-mkt {
  background-color: var(--rc-color-palette-bw-white);
  min-height: 13vw;
  position: relative;
  text-align: center;
}

.homepage-mkt__illustration__image {
  display: block;
  position: absolute;
  bottom: 0;
  height: 110%;
  width: auto;

  &.homepage-mkt__illustration__image--left {
    right: 70%;

    @include mobile {
      display: none;
    }
  }

  &.homepage-mkt__illustration__image--right {
    left: 70%;

    @include mobile {
      left: 50%;
    }
  }
}

.homepage-mkt__main {
  box-sizing: border-box;
  width: 40%;
  max-width: 60rem;
  padding: 5% 4%;
  text-align: center;
  margin: 0 auto;

  @include mobile {
    width: 50%;
    margin-left: 0;
    text-align: left;
  }
}

.homepage-mkt__main__title {
  @include responsive(font-size, 2.6rem, 2rem, 1.8rem);
  @include scaling((margin-bottom padding-bottom), 1.5rem);
  display: inline-block;
  position: relative;

  a {
    color: var(--rc-color-text-primary);
  }

  &::after {
    content: '';
    height: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: -0.2rem;
    width: 50%;
    background-color: var(--rc-color-palette-gray-900);

    @include mobile {
      left: 0;
      transform: translateX(0);
    }
  }
}

.homepage-mkt__main__subtext {
  @include responsive(font-size, 1.6rem, 1.4rem, 1.4rem);

  a {
    @include scaling(margin-top, 1rem);
    display: inline-block;
  }
}

