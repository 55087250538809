.rc-skeleton {
  background-color: var(--rc-color-background-loading-placeholder);
  border-radius: var(--rc-border-radius-sm);

  &.rc-skeleton--square,
  &.rc-skeleton--circle {
    aspect-ratio: 1 / 1;
  }

  &.rc-skeleton--circle {
    border-radius: 50%;
  }
}
