@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

// Styles here are referenced in raw HTML/Markdown CMS components.
// Even if there are no references to these classes in the codebase they are probably still being used.
// Modify/delete at your peril!

.sell-cta {
  display: flex;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: var(--rc-color-text-primary-invert-dark);
  border-radius: var(--rc-border-radius-primary);
}

.sell-cta__contents {
  text-align: center;
  padding: 6rem 3rem;

  @include mobile {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.sell-cta__title {
  font-size: 3.2rem;
  font-weight: bold;
  margin: 0 0 1rem;
}

.sell-cta__subtitle {
  font-size: 1.6rem;
  margin: 0 0 2rem;

  &.sell-cta__subtitle--with-heart {
    &::after {
      // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
      @include svg.icon-bg(marketing-heart, scss-tokens.$rc-color-text-accent);
      content: '';
      display: block;
      width: 1.2rem;
      height: 1.1rem;
      margin: 1rem auto 0;
    }
  }
}

.sell-cta__button {
  @include rc-button.filled;
}
