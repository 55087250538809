// Global options
$font-display: 'swap';
$font-path: '~@reverbdotcom/cadence/styles/fonts';

//
// LATO
//

@font-face {
  font-display: $font-display;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src:
    local('Lato Light'),
    local('Lato-Light'),
    url('#{$font-path}/lato-v14-latin-300.woff2') format('woff2'),
    url('#{$font-path}/lato-v14-latin-300.woff') format('woff');
}

@font-face {
  font-display: $font-display;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src:
    local('Lato Regular'),
    local('Lato-Regular'),
    url('#{$font-path}/lato-v14-latin-regular.woff2') format('woff2'),
    url('#{$font-path}/lato-v14-latin-regular.woff') format('woff');
}

@font-face {
  font-display: $font-display;
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src:
    local('Lato Italic'),
    local('Lato-Italic'),
    url('#{$font-path}/lato-v14-latin-italic.woff2') format('woff2'),
    url('#{$font-path}/lato-v14-latin-italic.woff') format('woff');
}

@font-face {
  font-display: $font-display;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src:
    local('Lato Bold'),
    local('Lato-Bold'),
    url('#{$font-path}/lato-v14-latin-700.woff2') format('woff2'),
    url('#{$font-path}/lato-v14-latin-700.woff') format('woff');
}

@font-face {
  font-display: $font-display;
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src:
    local('Lato Bold Italic'),
    local('Lato-BoldItalic'),
    url('#{$font-path}/lato-v14-latin-700italic.woff2') format('woff2'),
    url('#{$font-path}/lato-v14-latin-700italic.woff') format('woff');
}

//
// INTER
//

@font-face {
  font-display: $font-display;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src:
    url('#{$font-path}/Inter-Regular.woff2') format('woff2'),
    url('#{$font-path}/Inter-Regular.woff') format('woff');
}

@font-face {
  font-display: $font-display;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  src:
    url('#{$font-path}/Inter-Italic.woff2') format('woff2'),
    url('#{$font-path}/Inter-Italic.woff') format('woff');
}

@font-face {
  font-display: $font-display;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src:
    url('#{$font-path}/Inter-Medium.woff2') format('woff2'),
    url('#{$font-path}/Inter-Medium.woff') format('woff');
}

@font-face {
  font-display: $font-display;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  src:
    url('#{$font-path}/Inter-MediumItalic.woff2') format('woff2'),
    url('#{$font-path}/Inter-MediumItalic.woff') format('woff');
}

@font-face {
  font-display: $font-display;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src:
    url('#{$font-path}/Inter-SemiBold.woff2') format('woff2'),
    url('#{$font-path}/Inter-SemiBold.woff') format('woff');
}

@font-face {
  font-display: $font-display;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  src:
    url('#{$font-path}/Inter-SemiBoldItalic.woff2') format('woff2'),
    url('#{$font-path}/Inter-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-display: $font-display;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src:
    url('#{$font-path}/Inter-Bold.woff2') format('woff2'),
    url('#{$font-path}/Inter-Bold.woff') format('woff');
}

@font-face {
  font-display: $font-display;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  src:
    url('#{$font-path}/Inter-BoldItalic.woff2') format('woff2'),
    url('#{$font-path}/Inter-BoldItalic.woff') format('woff');
}

//
// FONTAWESOME
//

@font-face {
  font-display: $font-display;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  src:
    url('#{$font-path}/fontawesome-webfont-4.7.0.woff2') format('woff2'),
    url('#{$font-path}/fontawesome-webfont-4.7.0.woff') format('woff');
}
