.article-embed {
  @extend %blog-wrapper;

  @include mobile {
    font-size: 0.9em;
  }

  a {
    @include clearfix;
    @include responsive(display, flex, $mobile: block);
    border-bottom: 0.1rem solid var(--rc-color-border-primary);
    padding: 1rem;
    color: var(--rc-color-text-primary);
    align-items: center;
    transition: color .1s;

    &:hover {
      color: var(--rc-color-palette-vu-orange-500);
    }
  }

  &::before {
    content: attr(data-heading);
    text-transform: uppercase;
    font-size: 0.7em;
    border-bottom: 0.1rem solid var(--rc-color-border-primary);
    display: block;
    padding-bottom: 0.5em;
  }
}

.article-embed__image {
  flex: 0 0 25rem;

  @include mobile {
    width: 100%;
    margin-bottom: 1rem;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.article-embed__details {
  @include responsive(padding-left, 2rem, $mobile: 0);
  flex: 1 1 auto;
  box-sizing: border-box;
}

.article-embed__title {
  font-size: 1.2em;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.article-embed__summary {
  font-size: 0.85em;
  margin-bottom: 1rem;
}

.article-embed__summary__readmore {
  @include fa-icon-after(angle-right);
  margin-top: 1rem;
  font-size: 1.2em;
}
