@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.collection-header {
  position: relative;
}

.collection-header__title {
  @include commons-svg-background('instruments-lighten.svg');

  background-position: center;
  position: relative;
  background-color: var(--rc-color-palette-gray-900);
  text-align: center;
  text-shadow: 0 0 0.4rem var(--rc-color-shadow-500);
  color: var(--rc-color-palette-bw-white);
  margin-bottom: 2rem;
  transition: background-color .4s;

  @include mobile {
    background-size: cover;
    margin-bottom: 1rem;
  }

  .collection-header--with-description &,
  .brand-grid & {
    margin-bottom: 0;
  }

  &::after {
    display: none;
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.5;
    background: linear-gradient(to bottom, transparent, var(--rc-color-palette-gray-900) 100%);
    transition: opacity .4s;
  }

  // if the collection header has a background image
  .collection-header--with-image & {
    background: center transparent no-repeat;
    background-size: cover;

    &::after {
      display: block;
    }
  }
}

.collection-header__title__banner {
  @include site-wrapper;
  @include site-wrapper--narrow;
  position: relative;
  z-index: 2;
  padding-top: 1rem;

  img {
    @include box-shadow;
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    border: 0.2rem solid var(--rc-color-palette-bw-white);
  }
}

.collection-header__title__text {
  position: relative;
  font-size: 5.5rem;
  padding: 1.5em 3vw .2em 3vw;
  line-height: 1;
  font-weight: bold;
  z-index: 2;
  transition: opacity .4s;

  @include mobile {
    font-size: 2.8rem;
    padding-left: .5em;
    padding-right: .5em;
  }

  .collection-header__title__banner + & {
    padding-top: .2em;
  }

  .collection-header--with-subtitle & {
    font-size: 2.8rem;
    padding-bottom: 0;

    @include mobile {
      font-size: 1.8rem;
    }
  }
}

.collection-header__subtitle {
  position: relative;
  font-size: 5.5rem;
  font-weight: bold;
  color: var(--rc-color-palette-bw-white);
  z-index: 2;
  line-height: 1;
  padding-bottom: .2em;

  @include mobile {
    font-size: 2.8rem;
  }
}

.collection-header__title__link {
  color: var(--rc-color-palette-bw-white);

  &:hover {
    color: var(--rc-color-palette-bw-white);
  }
}

.collection-header__fineprint {
  @include site-wrapper;
  @include scaling(margin-bottom, 2rem);

  border: 0.1rem solid var(--rc-color-border-primary);

  display: flex;
  align-items: center;
  background: var(--rc-color-palette-bw-white);
  padding: 2rem;

  &::before {
    @include fa-icon(tag);
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: var(--rc-color-text-price-nudge);
    text-align: center;
    font-size: 4rem;
  }

  @include mobile {
    font-size: 80%;
  }
}

.collection-header__buttons {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;

  a {
    @include rc-button.filled($theme: 'danger');
  }
}
