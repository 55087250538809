
.item2-pricing__actions {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem -0.5rem 0 -0.5rem;

  > div {
    flex: 1 1 auto;
    margin: 0.5rem;
    min-width: calc(50% - 1rem);

    &:first-child {
      flex: 0 0 calc(100% - 1rem);
    }
  }
}

.item2-pricing__amount {
  @include responsive(font-size, 1.2em, 1.1em, 1em);

  margin: 1.5rem 0;

  .featured-listing-module & {
    margin-top: 0;
  }
}

.item2-pricing__callouts {
  margin-bottom: 2rem;

  &:empty {
    display: none;
  }
}

.item2-pricing__callout {
  font-size: 1.3rem;
  color: var(--rc-color-text-secondary);
  margin: 0.3rem 0;
  line-height: 1.3;

  b {
    color: var(--rc-color-text-primary);
  }
}

.item2-pricing__affirm {
  position: relative;

  // tweak styles for the MORE_PROMINENT_AFFIRM_PRICING experiment
  .item2-pricing & {
    margin: 1.5rem 0 2rem;
  }
}

.item2-sold-text {
  font-size: 1.6rem;
  color: var(--rc-color-text-secondary);

  p {
    margin: 0;
  }
}

.item2-pricing__status-indicator-container {
  background-color: var(--rc-color-background-alert-highlight);
  padding: var(--rc-space-gap-grid);
  text-align: center;
  border-radius: var(--rc-border-radius-primary);

  .item2-pricing & {
    margin: 0 calc(-1 * var(--rc-space-gap-grid));
  }

  .item2-sticky-sidebar--stuck & {
    border-radius: 0;
  }

  .item2-sold-text {
    margin-bottom: 1rem;
    color: var(--rc-color-text-primary);
  }

  .watch-badge__button, .rc-button {
    border-radius: 2rem;
  }

  .item2-pricing__watch, .item2__ended-search-button {
    margin-bottom: 1rem;
  }

  @include mobile {
    padding: 2rem 1.5rem;
    margin: -3vw;
    text-align: center;

    .item2-stats {
      justify-content: center;
    }
  }
}
