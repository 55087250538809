.secure-checkout-message {
  margin-right: auto; // auto keeps things centered without allowing the button to be super wide

  @include mobile {
    order: 3;
    flex-basis: 100%;
    margin-top: 1rem;
  }
}

.secure-checkout-message__trigger {
  @include fa-icon-before(lock);
  @include button-reset;
  color: var(--rc-color-sentiment-positive-dark);
  font-weight: bold;
  margin-top: 1rem; // small offset to more visually center against logo

  .item2 &,
  .dynamic-page--csp & {
    color: var(--rc-color-text-primary);
    float: right;
    margin: 0 0 0.8rem 0;
    font-size: 0.8em;
  }
}
