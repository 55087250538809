
$example-item-width: 10rem;
$example-item-width-mobile: 9.5rem;

.sell-form-photo-examples {
  margin-bottom: 2rem;
}

.sell-form-photo-examples__header {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto;
  align-items: center;
  font-size: 1.6rem;
  font-weight: bold;
}

.sell-form-photo-examples__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($example-item-width, 1fr));
  grid-gap: 2rem;
  margin-top: 1rem;

  @include mobile {
    grid-template-columns: repeat(auto-fill, minmax(9.5rem, 1fr));
    grid-gap: 1.5rem;
    font-size: 1.4rem;
  }
}

.sell-form-photo-examples__list__item {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-md);
  color: var(--rc-color-text-secondary);
  text-align: center;

  img {
    width: auto;
    height: 8rem;
    object-fit: contain;

    @include mobile {
      height: 7rem;
    }
  }
}

.sell-form-photo-examples_label {
  margin: 0.5rem 0;
}

.sell-form-photo-examples__button-text {
  font-size: 1.4rem;
}
