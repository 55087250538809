.auction-listing-card {
  border: 0.1rem solid var(--rc-color-border-primary);

  background: white;
  padding: 1rem;
  display: flex;
  width: 100%;
  align-items: center;

  @include tablet {
    display: block;
  }

  &.auction-listing-card--placeholder {
    @include loading-pulse;
  }
}

.auction-listing-card__image {
  flex: 0 0 12rem;

  a {
    @include image-box;

    background: var(--rc-color-palette-gray-200);
  }

  @include tablet {
    flex-basis: 10rem;
  }
}

.auction-listing-card__primary {
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;

  @include tablet {
    height: auto;
  }
}

.auction-listing-card__secondary {
  flex: 0 0 24rem;
  line-height: 1.2;
  font-size: 0.9em;

  @include tablet {
    margin: 2rem 0 0 12rem;
  }
}

.auction-listing-card__main {
  box-sizing: border-box;
  padding: 0 2rem;
}

.auction-listing-card__subhead {
  font-size: 0.9em;
  text-transform: uppercase;
  opacity: 0.7;
  margin-top: 1em;

  :first-child > & {
    margin-top: 0;
  }
}

.auction-listing-card__seller {
  font-size: 0.9em;
}

.auction-listing-card__status {
  font-weight: bold;

  .auction-listing-card--winning & {
    color: var(--rc-color-sentiment-positive-dark);
  }

  .auction-listing-card--losing & {
    color: var(--rc-color-text-danger);
  }
}
