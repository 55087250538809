// probably a sass-core candidate

.basic-table {
  width: 100%;

  thead td {
    font-weight: bold;
    border-bottom: 0.2rem solid var(--rc-color-border-primary);
  }

  td {
    padding: 0.4em 0.8em;

    @include mobile {
      padding: 0.4em;
    }
  }

  tr + tr td {
    border-top: 0.1rem solid var(--rc-color-border-primary);
  }
}
