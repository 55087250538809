@use '@reverbdotcom/cadence/styles/tools/functions/svg';
@use '@reverbdotcom/cadence/styles/tools/mixins/svg' as svg-icon;
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.react-tagsinput-tag {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  line-height: 1;
  font-size: 0.8em;
  border: 0.1rem solid var(--rc-color-border-primary);
  background: var(--rc-color-palette-bw-white);
  padding: 0.6em 2em 0.7em 0.8em;
  font-weight: bold;
}

.react-tagsinput-remove {
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg-icon.icon-bg(close, scss-tokens.$rc-color-palette-gray-500);
  position: absolute;
  background-size: 65%;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1.8em;
  cursor: pointer;

  &:hover {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    @include svg.svg-background-image(close, scss-tokens.$rc-color-palette-gray-900);
  }
}
