
.shared-tabs {
  @include smooth-scroll;
  @include scaling(gap, 2rem);
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  max-width: 100%;
  align-items: flex-end;

  &.shared-tabs--grow {
    width: 100%;
  }

  &.shared-tabs--centered {
    justify-content: center;
  }

  &.shared-tabs--with-wrapper {
    @include site-wrapper;
  }
}

.shared-tabs__tab {
  display: block;
  border-bottom: 0.4rem solid var(--rc-color-border-selectable);
  color: var(--rc-color-text-secondary);
  padding: var(--rc-space-4);
  line-height: 1;
  transition: color .2s;
  text-align: center;

  @include mobile {
    padding-left: var(--rc-space-2);
    padding-right: var(--rc-space-2);
  }

  &:hover {
    color: var(--rc-color-text-primary);
  }

  &.shared-tabs__tab--active {
    border-bottom-color: var(--rc-color-border-selected);
    color: var(--rc-color-text-primary);
    background: none;
  }

  .shared-tabs--placeholder & {
    color: transparent;
    height: 1em;
    width: 4em;
  }

  .shared-tabs--grow & {
    flex: 1;
  }

  .shared-tabs--high-contrast & {
    color: var(--rc-color-text-primary);
    font-weight: bold;

    &:hover {
      color: var(--rc-color-text-secondary);
    }
  }
}

.shared-tabs__text {
  display: inline-block;
  font-size: 1.3em;

  .shared-tabs--high-contrast & {
    font-size: 1.1em;
  }

  .shared-tabs--small & {
    font-size: 1em;
  }

  @include mobile {
    font-size: 1em;
  }
}

.shared-tabs__rating {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 0.3em;
}

.shared-tabs__count {
  display: inline-block;
  opacity: 0.7;
  margin-left: 0.3em;

  .shared-tabs--high-contrast & {
    font-weight: normal;
  }

  &::before {
    content: '(';
  }

  &::after {
    content: ')';
  }
}
