// legacy; remainder of styles are in commons

.video-embed {
  .blog-post__frame & {
    @extend %blog-wrapper;

    &.video-embed--narrow {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
