.rc-notice {
  @include box-shadow;

  position: relative;
  box-sizing: border-box;
  width: 100rem;
  max-width: 100%;
  max-height: 100%;
  margin: min(var(--rc-space-10), var(--rc-space-gutter-page));
  border-radius: var(--rc-border-radius-xl);
  background-color: var(--rc-color-background-module);
  border: 0.1rem solid var(--rc-color-border-primary);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--rc-space-6);
  padding: var(--rc-space-6);

  &.rc-notice--row {
    flex-direction: row;

    @include mobile {
      flex-direction: column;
    }
  }

  @include mobile {
    padding: var(--rc-space-4);
  }
}

.rc-notice__main {
  display: flex;
  flex: 1;
  gap: var(--rc-space-4);
}

.rc-notice__icon {
  width: var(--rc-space-14);

  @include mobile {
    width: var(--rc-space-10);
  }
}

.rc-notice__text {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--rc-space-2);
}

.rc-notice__actions {
  display: flex;
  align-items: center;

  @include mobile {
    justify-content: flex-end;
  }
}
