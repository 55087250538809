
.sell-form-callout {
  max-width: max-content;
}

.sell-form-callout__content {
  background-color: var(--rc-color-background-alert-info);
  margin-bottom: 2rem;
  padding: 2.4rem;
  border-radius: var(--rc-border-radius-md);
  display: flex;
  align-items: center;

  .cms-ul {
    margin: 0 0 0 var(--rc-space-4);
  }
}

.sell-form-callout__info__icon__container {
  text-align: center;
  margin-right: 2rem;
}

.sell-form-callout__info__icon {
  height: 5rem;
  width: 5rem;
}
