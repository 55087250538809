@use 'sass:math';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';
@use '@reverbdotcom/cadence/styles/tools/mixins/aspect-ratio';

.homepage__subheading {
  @include site-wrapper;
  @include scaling(padding-top, 2rem);
  @include rc-text.title('900');
}

.reverb-overview__signup__button {
  @include rc-button.filled;
  font-size: 1.4rem;
}

.homepage-top-brands {
  @include scaling((margin-top margin-bottom), 7rem, 0.7);
}

.homepage-explore {
  @include scaling(margin-top, 7rem, 0.7);
  @include scaling((padding-top padding-bottom), 4rem);
  background: var(--rc-color-palette-bw-white);
}

.homepage-explore__inner {
  @include site-wrapper;
}

.homepage-explore__heading {
  font-size: 1.6em;
  margin-bottom: 2rem;
  text-align: center;
}

.homepage-explore__links {
  @include grid-container;
}

.homepage-explore__links__link {
  @include grid-columns(3, $mobile: 6, $cycle: true);
  @include scaling((padding-top padding-bottom), 2rem);
  @include scaling((padding-left padding-right), 4rem);

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.homepage-explore__links__link__title {
  font-weight: bold;
  text-align: center;
  margin-top: 1rem;
}

.homepage-learn {
  @include site-wrapper;
  @include scaling((margin-top margin-bottom), 7rem, 0.7);
}

.homepage-learn__articles {
  display: flex;

  @include tablet {
    display: block;
  }
}

.homepage-learn__articles__article {
  flex: 0 0 math.percentage(math.div(1, 3));
}

.homepage-learn__banner {
  display: block;
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.homepage-learn__banner__title {
  @include responsive(max-width, 70%, 80%, 80%);
  padding: 1rem;
  position: absolute;
  bottom: 0;
  background: var(--rc-color-palette-bw-white);

  p {
    @include responsive(font-size, 0.9em, 0.8em, 0.8em);
    color: var(--rc-color-text-primary);
    font-weight: bold;
    margin-bottom: 0;
  }

  h4 {
    @include responsive(font-size, 1.1em, 1em, 1em);
    color: var(--rc-color-text-primary);
  }

  &::after {
    content: '';
    position: absolute;
    height: 1rem;
    width: 80%;
    left: 1rem;
    bottom: 100%;
    background: var(--rc-color-palette-vu-orange-500);
  }
}

.homepage-footer {
  display: flex;
  box-sizing: border-box;
  overflow: hidden;

  @include mobile {
    display: block;
  }
}

.homepage-footer__col {
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &:only-child {
    flex: 0 0 100%;
  }

  &.homepage-footer__col--app {
    @include scaling((padding-top padding-left padding-right), 4rem);
    background: var(--rc-color-palette-vu-orange-500);
  }

  &.homepage-footer__col--signup {
    @include scaling(padding, 4rem);
    background-image: url('#{$discovery-ui-path}/images/homepage/footer-join-illustration.svg');
    background-position: left;
    background-size: cover;
    overflow: hidden;

    @include mobile {
      background: var(--rc-color-palette-blue-600);
    }
  }
}

.homepage-footer__col__image {
  @include responsive(width, 20rem, 16rem, 15rem);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.homepage-footer__col__get-app {
  flex: 1;
  padding-left: 2rem;
  max-width: 20rem;
  padding-bottom: 2rem;
}

.homepage-footer__col__get-app__heading {
  @include scaling(font-size, 2.4rem);
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--rc-color-palette-bw-white);
}

.homepage-footer__col__get-app__subheading {
  @include scaling(font-size, 1.8rem);
  font-weight: bold;
  margin-bottom: 2rem;
  color: var(--rc-color-text-primary);
}

.homepage-footer__get-app-image {
  max-width: 18rem;

  a {
    display: block;
    padding-bottom: 30%;
    background: center no-repeat;
    background-size: contain;
    font-size: 0;
    color: transparent;
  }
}

.homepage-marketing-callout__container {
  @include site-wrapper;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: calc(3 * var(--rc-space-gap-grid));

  @include mobile {
    background-color: var(--rc-color-palette-gray-200);

    .homepage-sell & {
      background-color: unset;
    }
  }
}

.homepage-marketing-callout__image-container {
  flex: 1 1 40%;
  min-height: 35rem;

  @include tablet {
    min-height: 25rem;
  }

  @include mobile {
    display: none;
  }
}

.homepage-marketing-callout__image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0 var(--rc-border-radius-primary) var(--rc-border-radius-primary) 0;
}

.homepage-marketing-callout__content {
  display: flex;
  flex: 1 1 60%;
  align-items: center;
  background-color: var(--rc-color-palette-gray-200);
  background-blend-mode: saturation; // removes image texture in 2.0
  padding: var(--rc-space-10);
  border-radius: var(--rc-border-radius-primary) 0 0 var(--rc-border-radius-primary);
  max-width: max-content;

  @include tablet {
    padding: var(--rc-space-8);
    width: 60%;
    font-size: 100%;
  }

  @include mobile {
    padding: var(--rc-space-8) 0;
    border-radius: 0;
  }

  .homepage-sell & {
    @include mobile {
      padding: var(--rc-space-6);
      flex: 1;
      border-radius: var(--rc-border-radius-primary);
      min-width: unset;
      max-width: unset;
    }
  }

  .input-group__button {
    @include rc-button.filled($extend: false);
  }
}

.homepage-just-listed-for-you__filters-component {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  margin-bottom: 1.5rem;
  max-width: 75%;

  @include mobile {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.homepage-just-listed-for-you__component-wrapper-variant-2 {
  .overflowing-row:first-child {
    margin-bottom: 0;
    padding-right: 0;

    .overflowing-row__action {
      @extend .overflowing-row__action;

      position: absolute;
      bottom: 0.75rem;
      right: 4rem;
      z-index: 2;
      padding-right: 0;

      @include mobile {
        display: none;
      }
    }
  }

  .overflowing-row:last-child {
    margin-top: 0;

    .overflowing-row__action {
      @extend .overflowing-row__action;
      display: none;

      @include mobile {
        display: block;
      }
    }
  }
}

.homepage-sell {
  li {
    @include rc-text.utility('500');
    margin-bottom: var(--rc-space-2);

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.homepage-sell__title {
  @include rc-text.title('900');
  margin-bottom: var(--rc-space-3);
}
