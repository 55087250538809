@use '../tools/mixins/internal/class-prefix';

$_fa-icon-subset: (
  angle-down
  angle-left
  angle-right
  angle-up
  apple
  arrow-circle-down
  arrow-circle-left
  arrow-circle-right
  arrow-circle-up
  arrow-down
  arrow-left
  arrow-right
  arrow-up
  ban
  bank
  bars
  bell
  bell-o
  bolt
  bookmark
  calendar
  camera
  caret-down
  caret-left
  caret-right
  caret-up
  check
  check-circle
  check-square
  check-square-o
  chevron-down
  chevron-left
  chevron-right
  chevron-up
  circle
  circle-o
  circle-thin
  clipboard
  clock-o
  close
  cloud-upload
  cogs
  comment
  comment-o
  comments
  crop
  desktop
  download
  envelope
  eur
  exchange
  exclamation-circle
  exclamation-triangle
  external-link
  eye
  facebook
  file-text
  file-text-o
  flag
  gbp
  gear
  gift
  globe
  google-plus
  home
  info-circle
  instagram
  jpy
  jpy
  language
  linux
  list
  lock
  magic
  map-marker
  minus
  pencil
  phone
  picture-o
  pinterest
  play-circle
  plus
  plus-circle
  print
  question-circle
  refresh
  remove
  reply
  rotate-right
  search
  share-alt
  shield
  shopping-cart
  snapchat-ghost
  soundcloud
  spinner
  square
  square-o
  star
  star-o
  suitcase
  tachometer
  tag
  tags
  th
  thumbs-down
  thumbs-up
  ticket
  times
  times-circle
  trash
  trash-o
  truck
  twitter
  unsorted
  usd
  user-circle-o
  user-secret
  users
  window-maximize
  windows
  youtube
  youtube
  youtube-play
);

@include class-prefix('icon-r-') {
  &::after {
    @extend %fa-icon-base;
  }
}

@include class-prefix('icon-l-') {
  &::before {
    @extend %fa-icon-base;
  }
}

@include class-prefix('icon-l') {
  &::before {
    margin-right: $icon-inline-spacing;
  }
}

@include class-prefix('icon-r') {
  &::after {
    margin-left: $icon-inline-spacing;
  }
}

@each $icon in $_fa-icon-subset {
  .icon-l-#{$icon}:before {
    content: fa-icon-content($icon);
  }

  .icon-r-#{$icon}:after {
    content: fa-icon-content($icon);
  }
}
