.rsl-selector-module__help-text {
  font-size: 1.4rem;
  font-weight: normal;
  margin: 0.4rem 0;
  margin-left: 2.7rem;
  color: var(--rc-color-text-secondary);
  line-height: 1.2;
}

.rsl-selector-module__list-group {
  margin-left: 2rem;
}

.rsl-selector-module__list-item {
  &:first-child {
    margin-top: 0;
  }

  list-style: disc;
  margin-top: 0.8rem;
}

