@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.list-view-row-card {
  display: flex;
  width: 100%;
  padding-bottom: 1.5rem;
  border-bottom: solid 0.1rem var(--rc-color-border-primary);
  margin-bottom: 1.5rem;

  @include tablet {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }
}

.list-view-row-card--loading {
  @include loading-pulse;
  background-color: var(--rc-color-background-loading-placeholder);
  width: 100%;
  height: 15rem;
  border-radius: 1em;
  margin: 0.6rem;
}

.list-view-row-card__thumbnail-inner,
.list-view-row-card__title-inner,
.list-view-row-card__seller-inner {
  color: var(--rc-color-text-primary);
  font-weight: bold;

  @include responsive-hover {
    color: var(--rc-color-text-primary);
    text-decoration: underline;
  }

  @include tablet {
    font-size: 1.4rem;
  }
}

.list-view-row-card__thumbnail-container {
  position: relative;
}

.list-view-row-card__thumbnail {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 12.5rem;
  overflow: hidden;

  @include tablet {
    flex-basis: 11rem;
  }

  img {
    display: block;
    border-radius: $input-border-radius * 2;
    border: 0.1rem solid var(--rc-color-border-primary);
    box-sizing: border-box;
  }
}

.list-view-row-card__sold {
  &.list-view-row-card__sold--thumb {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    background: var(--rc-color-highlight-600);
  }
}

.list-view-row-card__bump {
  &.list-view-row-card__bump--thumb {
    display: none;

    @include mobile {
      display: block;
      margin-top: 0.6rem;
    }
  }

  &.list-view-row-card__bump--titleblock {
    display: block;

    @include mobile {
      display: none;
    }
  }

  .bump-tag {
    color: var(--rc-color-text-secondary);
    margin-bottom: 0.5rem;
    font-size: 1.4rem;

    @include tablet {
      margin-bottom: 0.2rem;
    }

    span {
      font-weight: normal;
    }
  }
}

.list-view-row-card__info {
  display: flex;
  flex: 1;
  margin-left: 1.5rem;

  @include tablet {
    margin-left: 1rem;
  }

  @include mobile {
    border-bottom: none;
    display: block;
    margin-left: 1rem;
    padding-bottom: 1rem;
  }
}

.list-view-row-card__titleblock {
  flex: 0 0 25%;
  width: 25%;

  @include mobile {
    width: 100%;
  }
}

.list-view-row-card__titleblock-inner {
  border-right: solid 0.1rem var(--rc-color-border-primary);
  padding-right: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @include tablet {
    padding-right: 1rem;
  }

  @include mobile {
    padding: 0 0 1rem;
    border: none;
    height: auto;
    display: block;
  }
}

.list-view-row-card__titleblock-watch {
  display: none;

  @include mobile {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.rc_listing-row-card__title-container {
  flex-grow: 1;
}

.list-view-row-card__title {
  @include responsive(font-size, 1.8rem, 1.6rem, 1.6rem);
  margin-bottom: 0.4rem;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  max-height: 5rem;

  @include mobile {
    margin-bottom: 0.3rem;
    margin-right: 2.5em;
  }

  @include tablet {
    max-height: 4rem;
  }
}

.list-view-row-card__nudges {
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;

  .listing-card-nudge {
    margin-right: 0.8rem;
    margin-bottom: 0;
  }

  @include mobile {
    display: none;
  }
}

.list-view-row-card__mobile-nudges {
  display: none;

  @include mobile {
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
    padding-bottom: 0.5rem;

    &:empty {
      display: none;
    }

    .listing-card-nudge {
      margin-right: 0.8rem;
    }
  }
}

.list-view-row-card__nudge {
  margin-right: 2.4rem;
  margin-bottom: 0.8rem;

  .listing-card-nudge {
    margin: 0;
  }
}

.list-view-row-card__priceblock {
  flex: 0 0 25%;
  width: 25%;

  @include mobile {
    width: 100%;
  }
}

.list-view-row-card__priceblock-inner {
  border-right: solid 0.1rem var(--rc-color-border-primary);
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  @include tablet {
    padding: 0 1rem;
  }

  @include mobile {
    padding: 0 0 1rem;
    border: none;
    height: auto;
  }

  .list-view-row-card__footer__pricing {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
  }

  .list-view-row-card__price {
    > .price-display {
      font-size: 2.4rem;
      font-weight: bold;

      @include tablet {
        font-size: 2rem;
      }
    }

    .listing-shipping-display {
      font-size: 1.4rem;
    }
  }

  .list-view-row-card__original-price {
    font-size: 1.4rem;
    color: var(--rc-color-text-primary);
    line-height: 1.4;

    .list-view-row-card__discount {
      color: var(--rc-color-text-discount);
      font-weight: bold;
    }
  }

  .list-view-row-card__original-price-display {
    text-decoration: line-through;
    margin-right: 0.25em;
    color: var(--rc-color-text-primary-disabled);
    display: inline-block;
  }
}

.list-view-row-card__shop-info {
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  width: 25%;

  @include mobile {
    width: 100%;
    display: none;
    padding-top: 0.5rem;

    .list-view-row-card--expanded & {
      display: block;
    }
  }
}

.list-view-row-card__shop-info-inner {
  padding-left: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include tablet {
    padding-left: 1rem;
  }

  @include mobile {
    padding-left: 0;
    height: auto;
  }
}

.list-view-row-card__seller-location {
  color: var(--rc-color-text-secondary);
  font-size: 1.4rem;
  line-height: 1.7;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  max-height: 2rem;

  @include tablet {
    line-height: 1.4;
  }

  @include mobile {
    padding-bottom: 0.6rem;
    font-size: 1.4rem;
    line-height: 1.6;
  }
}

.list-view-row-card__condition {
  margin-top: auto;

  .condition-display__label {
    font-size: 1.4rem;
    padding-bottom: 0.4rem;

    @include mobile {
      font-size: 1.4rem;
      line-height: 1.6;
      padding-bottom: 0;
    }
  }
}

.list-view-row-card__seller-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  max-height: 2rem;
}

.list-view-row-card__seller-rating {
  margin-top: auto;

  @include mobile {
    margin-top: 0;
    margin-bottom: 0.8em;
  }

  .rc-star-rating {
    padding: 0.1rem 0;
  }
}

.list-view-row-card__seller-rating-text {
  margin-top: 2em;
  font-size: 1.4rem;
  line-height: 1.9;

  @include mobile {
    margin-top: 0;
    font-size: 1.4rem;
    line-height: 1.6;
  }
}

.list-view-row-card__actions {
  flex: 0 0 25%;

  @include mobile {
    display: none;
    max-width: 24rem;

    .watchlist-container--mweb-purchase-actions-exp & {
      max-width: 100%;
      display: block;

      .watch-badge {
        display: none;
      }
    }
  }
}

.list-view-row-card__actions-inner {
  padding-left: 1.5rem;
  height: 100%;

  @include tablet {
    padding-left: 1rem;
  }

  @include mobile {
    padding: 0.5rem 0 0;
    height: auto;

    .watchlist-container--mweb-purchase-actions-exp & {
      padding: 1rem 0 0;
    }
  }

  .list-view-row-card__make-offer {
    margin-top: 0.5rem;
  }

  .watch-badge {
    margin-top: 0.5rem;
  }
}
