@use './responsive-hover' as hover;

// there are places in our codebase where we can't use `RCImage` without
// heavily refactoring. This allows us to inject JUST the overlay itself
// onto those divs.

// PLEASE DO NOT USE this for new implementations, use RCImage.

@mixin overlay-hover {
  [data-rc-image-overlay-trigger]:hover & {
    &::after {
      opacity: 0;
    }
  }

  @include hover.responsive-hover {
    &::after {
      opacity: 0;
    }
  }
}

@mixin overlay($hover: false) {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.15s ease;
    background-color: var(--rc-color-shadow-100);
    pointer-events: none;
  }

  @if $hover {
    @include overlay-hover;
  }
}
