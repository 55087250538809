.favorite-shops__header {
  font-weight: 700;
  margin-bottom: 1.8rem;
}

.favorite-shops {
  flex-direction: column;

  .rc-button {
    margin: auto;
  }

  .favorite-shops__shop-heart {
    .rc-button {
      border-color: var(--rc-color-border-primary);
    }
    .rc-icon {
      color: var(--rc-color-palette-vu-orange-600);
    }
  }
}

.favorite-shops__loading {
  margin: 0 65rem;
}

.favorite-shops__pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}
