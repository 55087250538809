@use '@reverbdotcom/cadence/styles/tools/mixins/card-styles';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.bail-card {
  @include card-styles.card-base;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--rc-space-5);
  gap: var(--rc-space-4);
  text-align: center;
  min-height: 12rem;

  .sell__product-grid & {
    flex: 1;
  }
}

.bail-card__text {
  @include rc-text.default;
  color: var(--rc-color-text-primary);
}

.bail-card__link {
  @include text-link;
  @include rc-text.default($weight: 'bold');
  color: var(--rc-color-text-primary);
}
