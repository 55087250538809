.checkout-loading-indicator {
  box-sizing: border-box;
  background-color: var(--rc-color-palette-bw-white);
  border: solid 0.1rem var(--rc-color-border-primary);
  border-radius: 0.4rem;
  padding: 4.2rem;
  text-align: center;
  margin: 6rem auto;
  max-width: 40rem;

  @include mobile {
    margin: 2rem;
    padding: 2rem;
    max-width: 100%;
  }
}

.checkout-loading-indicator__title,
.checkout-loading-indicator__description {
  font-size: 2rem;
  margin: 0;

  @include mobile {
    font-size: 1.6rem;
  }
}

.checkout-loading-indicator__icon {
  @include scaling(margin-top, 2rem);
  @include scaling(margin-bottom, 2rem);
  display: flex;
  justify-content: center;
}

.checkout-loading-indicator__wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
