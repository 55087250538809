.sell-form-section-buttons {
  display: flex;

  @include mobile {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 2rem;
    width: 100%;

    &.sell-form-section-buttons--mobile-vertical-align {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: 1fr;
      grid-row-gap: 2rem;

      > :first-child {
        order: 1;
      }
    }
  }
}

.sell-form-section-buttons--submit-disabled {
  button[type='submit'] {
    opacity: $button-disabled-opacity;
  }
}
