.social-links {
  margin: 0 -1rem;
}

.social-links__link {
  display: inline-block;

  + .social-links__link {
    margin-left: 1.5rem;
  }

  &:first-child {
    margin-left: 0;
  }

  a {
    color: var(--rc-color-text-secondary);
    transition: color .2s;

    @include responsive-hover {
      color: var(--rc-color-text-primary);
    }
  }
}
