@use '@reverbdotcom/cadence/styles/tools/mixins/responsive-hover' as hover;
@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow' as shadow;
@use '@reverbdotcom/cadence/styles/tools/mixins/aspect-ratio';

.article-collection-card {
  width: 100%;
  background: var(--rc-color-background-card);
  color: var(--rc-color-text-primary);
  border-radius: var(--rc-border-radius-lg);
  overflow: hidden;
  transition: box-shadow .2s ease-in-out;

  @include hover.responsive-hover {
    @include shadow.box-shadow;

    color: var(--rc-color-text-primary);
    background-color: var(--rc-color-background-card-hovered);
  }

  &.article-collection-card--sidebar {
    min-height: 5rem;
    height: auto;
  }

  @include min-width($site-width-mid) {
    @include max-tiles(2) {
      position: relative;
      padding-left: 40%;
    }

    @include max-tiles(1) {
      padding-left: 32%;
    }
  }
}

.article-collection-card__inner {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.article-collection-card__sponsored_content {
  font-size: 1.2rem;
  font-style: italic;
  font-weight: bold;
  color: var(--rc-color-text-secondary);
}

.article-collection-card__image {
  @include aspect-ratio.aspect-ratio(2, 1);
  position: relative;
  background-color: var(--rc-color-palette-gray-200);
  background-size: cover;
  background-position: center;

  @include min-width($site-width-mid) {
    @include max-tiles(2) {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 40%;
      height: auto;
    }

    @include max-tiles(1) {
      width: 32%;
    }
  }

  .article-collection-card--large & {
    @include responsive(height, 30rem, 24rem, 16rem);
  }

  .article-collection-card--sidebar & {
    height: 100%;
    padding-bottom: 0;
  }
}

.article-collection-card__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--rc-space-5);

  @include tablet {
    padding: var(--rc-space-4);
  }

  @include mobile {
    padding: var(--rc-space-3);
  }

  .article-collection-card--sidebar & {
    height: auto;
    padding: var(--rc-space-4);

    @include tablet {
      padding: var(--rc-space-3);
    }
  }

  @include min-width($site-width-mid) {
    @include max-tiles(2) {
      padding: 2rem;
      font-size: 1.3em;
    }
    @include max-tiles(1) {
      font-size: 1.5em;
    }
  }
}

.article-collection-card__primary {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-2);
  flex: 1;
}


.article-collection-card__title {
  font-size: 1.6rem;
  line-height: 1.25; // 2rem
  font-weight: bold;

  .article-collection-card--placeholder & {
    height: 2em;
  }

  @include min-width($site-width-mid) {
    @include max-tiles(2) {
      font-size: 1.3em;
    }
    @include max-tiles(1) {
      font-size: 1.5em;
    }
  }
}

.article-collection-card__meta {
  .article-collection-card--sidebar & {
    display: none;
  }
}

.article-collection-card__category {
  color: var(--rc-color-text-primary);
  text-align: right;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.06em;
  line-height: 1;
  margin-top: var(--rc-space-3);

  &:empty {
    display: none;
  }
}
