@use '@reverbdotcom/cadence/styles/tools/mixins/gutter';

.watchlist-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.watchlist-container__content {
  @include site-wrapper;
}

.watchlist-container__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 7rem;
}

.watchlist-container__empty-header {
  font-size: 2rem;
  margin-top: 4rem;
  font-weight: 600;
}

.watchlist-container__instructions {
  margin-top: 1rem;
}

.watchlist-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  flex-wrap: wrap;
  gap: var(--rc-space-3);

  @include mobile {
    align-items: center;
    flex-direction: column;
  }

  &.watchlist-header--favorites {
    margin-bottom: 0;

    @include mobile {
      @include gutter.full-bleed;
      align-items: stretch;
      overflow-x: hidden;
    }
  }
}

.watchlist-header__right-header--loading {
  @include loading-pulse;
  background-color: var(--rc-color-background-loading-placeholder);
  width: 35rem;
  height: 5rem;
  border-radius: 1em;
  margin: 0.6rem;

  @include mobile {
    margin-top: 3rem;
  }
}

.watchlist-header__right-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }

  .watchlist-header--favorites & {
    flex: 1;

    @include mobile {
      @include gutter.full-bleed;
      overflow-x: auto;
      width: 100%;
      flex-direction: row;
    }
  }
}

.watchlist-header__marketplace-filters {
  .search-overview__filters {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}
