.shop-card {
  margin: calc(-0.5 * var(--rc-space-gap-grid));
  padding: calc(.5* var(--rc-space-gap-grid));
  height: 100%;
}

.shop-card__content {
  padding:  calc(0.5 * var(--rc-space-gap-grid));
}

.shop-card__title {
  @include responsive('font-size', 1.6rem, 1.6rem, 1.4rem);
  font-weight: bold;
  color: var(--rc-color-text-primary);
  margin-top: var(--rc-space-1);
}

.shop-card__location {
  @include responsive('font-size', 1.4rem, 1.4rem, 1.2rem);
  color: var(--rc-color-text-secondary);
  line-height: 1.2;
}
