@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.shop-onboarding__header {
  @include site-wrapper;
  @include site-wrapper--narrow;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }
}

.shop-onboarding__header-image-container {
  padding: 4rem 4rem 0;
  width: 50%;
  display: flex;

  @include mobile {
    width: 100%;
    max-width: 20rem;
    padding: 2rem 2rem 0;
  }
}

.shop-onboarding__header-image {
  width: 100%;
  height: auto;
}

.shop-onboarding__header-description {
  text-align: left;
  width: 50%;
  padding-right: 2rem;

  @include mobile {
    width: 100%;
    padding-right: 0;
    padding-top: 1rem;
  }
}

.shop-onboarding__header-title {
  font-weight: 700;
  font-size: 2.4rem;
  margin-top: .5rem;
}

.shop-onboarding__header-subtitle {
  font-size: 1.4rem;
  margin-top: 0.6rem;
}

.shop-onboarding__body {
  @include site-wrapper;
  @include site-wrapper--narrow;
  padding-bottom: 4rem;
}

.shop-onboarding__tabs {
  margin-bottom: 3.2rem;

  @include mobile {
    margin-bottom: 1rem;
  }
}

.shop-onboarding__back-link {
  @include fa-icon-before(caret-left);
}

.shop-onboarding__form-cols {
  @include grid-container($gutter: 3%);
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

.shop-onboarding__form-col {
  @include grid-columns(6, 12, $gutter: 3%);
  margin-bottom: 1.6rem;

  &.shop-onboarding__form-col--full {
    @include grid-columns(12, $gutter: 3%);
  }

  &:last-child {
    margin-bottom: 0;
  }

  input {
    margin-bottom: 0;

    + input {
      margin-top: 0.8rem;
    }
  }

  .shop-onboarding__select {
    margin-bottom: 0;
  }
}

.shop-onboarding__select {
  @include styled-dropdown;

  &.shop-onboarding__select--disabled {
    color: var(--rc-color-palette-gray-500);
    box-shadow: none;

    &::before {
      opacity: 0.35;
    }
  }
}

.shop-onboarding__explanation {
  margin-bottom: 2.4rem;
}

.shop-onboarding__country-warning {
  color: var(--rc-color-text-danger);
  margin-bottom: 1em;
}

.shop-onboarding__checkbox-with-label {
  @include checkbox;
  margin-bottom: 1.2rem;
}

.shop-onboarding__radio-with-label {
  @include radio;
  margin-bottom: 1.2rem;

  label {
    font-weight: normal;
  }
}

.shop-onboarding__payment-option {
  margin-bottom: 2.4rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.shop-onboarding__payment-option-content {
  display: none;
  padding-left: calc(1.6rem + 1em); // equal spacing on radio padding

  &.shop-onboarding__payment-option-content--active {
    display: block;
  }
}

.shop-onboarding__payment-label {
  display: block;

  &.shop-onboarding__payment-label--dc {
    height: 5rem;
  }

  &.shop-onboarding__payment-label--paypal {
    height: 2.4rem;
  }
}

.shop-onboarding__checklist-item {
  position: relative;
  padding-left: 2rem;

  &::before {
    @include fa-icon(check);
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1.35;
    color: var(--rc-color-sentiment-positive-dark);
  }
}

.shop-onboarding__input-with-button {
  display: flex;
}

.shop-onboarding__input-with-button__input {
  margin: 0 1.2rem 0 0;
}

.shop-onboarding__input-with-button__action {
  @include rc-button.filled;
  padding-left: 2.8rem;
  padding-right: 2.8rem;
}

.shop-onboarding__input-with-button__verification {
  color: var(--rc-color-sentiment-positive-dark);
  font-weight: 700;
  padding: 0.65em 1em 0.6175em 1em;
}

.shop-onboarding__currency-change-callout {
  background-color: var(--rc-color-palette-blue-100);
  border-radius: 0.429rem;
  margin-bottom: 0.5rem;
  padding: 1rem;
}

.shop-onboarding__currency-change-callout__first {
  margin-bottom: 1rem;
}

.shop-onboarding__file-upload__inner {
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: 0.4rem;
  background: var(--rc-color-palette-bw-white);
  margin-bottom: 0.8rem;
  font-size: 1.4rem;

  &.shop-onboarding__file-upload__inner--error {
    border-color: var(--rc-color-sentiment-negative-dark);
  }
}

.shop-onboarding__file-upload__input {
  display: block;
  box-sizing: border-box;
  margin-bottom: 0;
  padding: 0.8rem;
}

.shop-onboarding__file-upload__progress {
  position: relative;
  background-color: var(--rc-color-palette-bw-white);
  border: solid 0.1rem var(--rc-color-border-primary);
  height: 0.8rem;
  margin-top: 0.8rem;
  border-radius: 0.4rem;
  overflow: hidden;
}

.shop-onboarding__file-upload__progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: var(--rc-color-palette-gray-300);
  border-radius: 0.4rem;
  transition: width 0.2s ease;
  text-indent: -9999.9rem;
}

.shop-onboarding__file-upload__error {
  color: var(--rc-color-text-danger);
  background-color: var(--rc-color-sentiment-negative-lighter);
  padding: 1.4rem;
  border-radius: 0.4rem;

  .shop-onboarding__file-upload__inner & {
    padding: 0.8rem;
    border-radius: 0 0 0.3rem 0.3rem;
  }
}

.shop-onboarding__photo-guidelines {
  border: solid 0.1rem var(--rc-color-border-primary);
  background-color: var(--rc-color-palette-gray-100);
  border-radius: 0.4rem;
  padding: 1.4rem 1.4rem 0;
  margin-top: 1.4rem;
}

.shop-onboarding__photo-guidelines__title {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0 0 1.4rem;
}

.shop-onboarding__photo-guidelines__inner {
  display: flex;
  flex-wrap: wrap;
}

.shop-onboarding__photo-guideline {
  box-sizing: border-box;
  flex: 0 0 50%;
  padding: 0 1.8rem 1.8rem 0;
  hyphens: auto;
  overflow: hidden;

  &:nth-child(2n) {
    margin-right: 0;
  }
}

.shop-onboarding__photo-guideline__title {
  font-weight: 700;
  font-size: 1.4rem;
}

.shop-onboarding__photo-guideline__description {
  font-size: 1.4rem;
}

.shop-onboarding__photo-guideline__highlight {
  color: var(--rc-color-palette-vu-orange-500);
  font-weight: 700;
}

.shop-onboarding__kyc-id-image {
  display: block;
  float: right;
  max-height: 12rem;
  max-width: 18rem;
  width: auto;
  height: auto;
}

.shop-onboarding__address-preview {
  font-size: 1.4rem;
  line-height: 1.25;
  color: var(--rc-color-text-secondary);
  border-left: solid 0.4rem var(--rc-color-border-primary);
  padding-left: 1rem;
}
