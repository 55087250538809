.checkout-layout__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: var(--rc-space-10);

  @include tablet {
    column-gap: var(--rc-space-5);
  }

  @include mobile {
    display: block;
  }

  &>div {
    grid-column: 2;
  }

  .checkout-layout__error-message-banner {
    grid-column: 1 / span 2;
    grid-row: 1;
    margin-bottom: 2rem;
  }

  .checkout-layout__shipping-billing-forms {
    grid-column: 1;
    // The left-hand column must span greater than or equal to the number of rows in the
    // right-hand column to ensure that the right-hand column rows occupy
    // the minimum content space instead of expanding their height
    // to fill the leftover space.
    grid-row: 2 / span 10;

    @include mobile {
      margin-bottom: 2rem;
    }
  }
}
