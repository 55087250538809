@use '../tools/mixins/wrapper';
@use '../config/configuration' as config;

.rc-banner {
  border-radius: var(--rc-border-radius-primary);

  &.rc-banner--full-bleed {
    border-radius: 0;
  }

  &.rc-banner--with-texture {
    background-blend-mode: overlay;
    background-position: left top;
    background-repeat: repeat;
    background-size: auto;
  }

  &.rc-banner--error {
    background-color: var(--rc-color-background-alert-error);

    &.rc-banner--with-texture {
      background-color: var(--rc-color-background-banner-error);
    }
  }

  &.rc-banner--highlight {
    background-color: var(--rc-color-background-alert-highlight);

    &.rc-banner--with-texture {
      background-color: var(--rc-color-background-banner-highlight);
    }
  }

  &.rc-banner--info {
    background-color: var(--rc-color-background-alert-info);

    &.rc-banner--with-texture {
      background-color: var(--rc-color-background-banner-info);
    }
  }

  &.rc-banner--plain {
    background-color: var(--rc-color-background-alert-plain);

    &.rc-banner--with-texture {
      background-color: var(--rc-color-background-banner-plain);
    }
  }

  &.rc-banner--success {
    background-color: var(--rc-color-background-alert-success);

    &.rc-banner--with-texture {
      background-color: var(--rc-color-background-banner-success);
    }
  }

  &.rc-banner--warning {
    background-color: var(--rc-color-background-alert-warning);

    &.rc-banner--with-texture {
      background-color: var(--rc-color-background-banner-warning);;
    }
  }
}

.rc-banner__wrapper {
  display: flex;
  gap: var(--rc-space-4);
  padding: var(--rc-space-4);
  color: var(--rc-color-text-primary);

  .rc-banner--full-bleed & {
    @include wrapper.site-wrapper;
  }

  .rc-banner--mobile-layout-card & {
    @include mobile {
      flex-direction: column;
      align-items: center;
      gap: var(--rc-space-3);
      text-align: center;
    }
  }
}

.rc-banner__image {
  flex: 0 0 5rem;
  width: 5rem;
  height: auto;

  @include mobile {
    flex: 0 0 4rem;
    width: 4rem;

    .rc-banner--mobile-layout-card & {
      flex: 0 0 5rem;
      width: 5rem;
    }
  }

  img {
    display: block;
    width: 100%;
  }
}

.rc-banner__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--rc-space-4);
  flex: 1;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--rc-space-2);

    .rc-banner--mobile-layout-card & {
      align-items: center;
      gap: var(--rc-space-4);
      width: 100%;
    }
  }
}

.rc-banner__text {
  flex: 1;
}

.rc-banner__cta {
  .rc-banner--mobile-layout-card & {
    @include mobile {
      width: 100%;
    }
  }
}
