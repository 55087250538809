.klarna-credit-placement {
  // https://docs.klarna.com/on-site-messaging/in-depth-knowledge/styling-on-site-messaging-with-css/#examples-credit-promotion-badge
  & ::part(osm-container) {
    background-color: transparent;
    border: none;
    padding: 0 0 1rem 0;
  }

  & ::part(osm-legal) {
    text-align: left;
  }

  &.klarna-credit-placement--text-only {
    & ::part(osm-message) {
      text-align: start;
    }
  }
}
