.quicklinks {
  @include scaling((padding-top padding-bottom), 1rem);
  @include scaling((margin-top margin-bottom), 3rem);
  background-color: var(--rc-color-background-module-secondary);
  border-top: 0.1rem solid var(--rc-color-border-primary);
  border-bottom: 0.1rem solid var(--rc-color-border-primary);

  @include tablet {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
}

.quicklinks__inner {
  @include site-wrapper;
  text-align: center;
}

.quicklinks__link {
  background-color: var(--rc-color-background-page);
  display: inline-block;
  padding: 1.1rem 1.5em;
  font-size: 1.4rem;
  border: 0.1rem solid var(--rc-color-border-primary);
  line-height: 1;
  border-radius: 1.8rem;
  color: var(--rc-color-text-primary);
  margin: 1rem;

  // TODO: Rethink active styles
  &.quicklinks__link--active {
    border: 0.2rem solid var(--rc-color-text-primary);
  }

  @include tablet {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    &:first-child {
      margin-left: 0;
    }
  }
}
