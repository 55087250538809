@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.recommended-price-range {
  display: flex;
  flex-direction: column;
  background: var(--rc-color-background-alert-info);
  padding: 3rem;
  margin-bottom: 2rem;

  &.recommended-price-range--rounded-border {
    border-radius: var(--rc-border-radius-primary);
  }

  .form-cards__card & {
    border-radius: var(--rc-border-radius-primary) var(--rc-border-radius-primary) 0 0;
  }

  @include mobile {
    margin-bottom: 1rem;
    padding: 1.5rem;
  }
}

.recommended-price-range__title {
  font-weight: bold;
  margin-bottom: 1rem;

  @include mobile {
    font-size: 1.6rem;
    margin: 0.5rem;
  }
}

h3.recommended-price-range__title {
  @include rc-text.default($weight: 'bold');
}

.recommended-price-range__list {
  width: 100%;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  @include mobile {
    width: 100%;
    font-size: 1.2rem;
  }
}

.recommended-price-range__range {
  display: flex;
  flex: 1;
  align-content: center;
  font-weight: bold;
  padding: 1rem;
  text-align: center;
  font-size: 2rem;
  width: calc(100% / 3);
  border-radius: var(--rc-border-radius-md);

  @include mobile {
    padding: 0.8rem 0.5rem;
  }

  @include tablet {
    font-size: 1.4rem;
  }
}

.recommended-price-range__range_contents {
  flex: 1;
  margin: auto;
}

.recommended-price-range__range--below,
.recommended-price-range__range--above {
  background-color: var(--rc-color-palette-gray-300);
}

.recommended-price-range__range--fair {
  background: var(--rc-color-sentiment-positive-dark);
  color: var(--rc-color-text-primary-inverted);
  margin: 0 2rem;

  @include mobile {
    margin: 0 .75rem;
  }
}

.recommended-price-range__label {
  font-size: 1.25rem;
  width: 100%;
  display: block;
  font-weight: 400;
}
