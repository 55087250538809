.gdpr-alert {
  @include responsive(width, 40%, 60%, 70%);
  @include box-shadow;
  position: fixed;
  left: 10px;
  bottom: 10px;
  background: var(--rc-color-palette-bw-white);
  padding: 15px;
  box-sizing: border-box;
  z-index: 1;
  max-width: 600px;
}

.gdpr-alert__overview {
  font-size: 12px;
  line-height: 1.2;
  @include scaling(padding-right, 20px);

  @include mobile {
    font-size: 11px;
  }
}

.gdpr-alert__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 20px;
  line-height: 1;
  color: var(--rc-color-text-primary);
}
