@use '../tools/mixins/dialogs';

.rc-icon-button {
  display: inline-block;
  // Padding overrides to give each size variant visually equivalent width / height
  .rc-button {
    display: flex;
    padding: var(--rc-space-1);
    font-size: 2.4rem;
  }
  .rc-button--mini {
    font-size: 1.8rem;
  }
  .rc-button--small {
    font-size: 2rem;
  }
  .rc-button--large {
    font-size: 2.8rem;
  }
}

.rc-icon-button__label {
  @include dialogs.panel;
  padding: var(--rc-space-1) var(--rc-space-2);
  font-size: 1.4rem;
  z-index: var(--z-index-tooltip);
  max-width: 15rem;
  text-align: center;
  border-radius: var(--rc-border-radius-sm);

  &.rc-icon-button__label--dark {
    color: var( --rc-color-palette-bw-white);
    background-color: var(--rc-color-palette-gray-700);
    border-color: transparent;
  }
}
