.notification-settings__table {
  width: 100%;

  @include mobile {
    display: block;
  }

  tr {
    @include mobile {
      display: block;
    }
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;

    @include mobile {
      display: block;
      text-align: left;
    }

    &:first-child {
      text-align: left;
    }
  }

  th {
    font-size: 1.4rem;
    font-weight: 700;
    border-bottom: solid 0.1rem var(--rc-color-border-primary);
    white-space: nowrap;
    padding: 4rem 2em 1rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &:not(:first-child) {
      @include mobile {
        display: none;
      }
    }
  }

  td {
    padding: 1.5rem 0;

    @include mobile {
      padding: 1rem 0;
    }
  }
}

.notification-settings__row-title {
  font-weight: 700;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.notification-settings__row-description {
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
  margin-bottom: 0;
  line-height: 1.2;
}

.notification-settings__checkbox {
  @include checkbox;
  display: inline-block;

  @include mobile {
    display: block;
  }

  span {
    display: none;

    @include mobile {
      display: inline-block;
    }
  }

  &.notification-settings__checkbox--na span {
    display: inline-block;
  }
}
