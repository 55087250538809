.seo-blurb {
  @include gap-row;
  padding: var(--rc-space-gap-row) 0;
  font-size: 0.9em;
  border-top: 0.1rem solid var(--rc-color-border-primary);
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
  background: var(--rc-color-background-page);

  .dynamic-page__two-col__main & {
    @include scaling(padding, 5rem);
  }

  &.seo-blurb--with-wrapper {
    @include scaling((padding-top padding-bottom), 4rem);

    font-size: 1em;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .cms-text {
    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
}

.seo-blurb__inner {
  > :first-child {
    margin-top: 0;
  }

  .dynamic-page__one-col & {
    @include site-wrapper;
    @include site-wrapper--cms;
  }

  .seo-blurb--with-wrapper & {
    @include site-wrapper;
  }
}

.seo-blurb__title {
  @extend %cms-h1;
}
