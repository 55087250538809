@use 'sass:map';
@use '../tools/mixins/internal/class-prefix';

$_directions: (
  t: top,
  r: right,
  b: bottom,
  l: left
);

$_borders: (
  0: none,
  1: 1px,
  2: 2px,
  3: 3px,
  4: solid transparent 4px
);

// optimization: can't extend from within a mixin
// so we're going to go with the safe assumption
// that only these classes will ever start with 'bd'

@include class-prefix('bd') {
  border: solid transparent 0;
}

.bd-primary {
  border-color: var(--rc-color-border-primary);
}

@include build-responsive-classes((
  valueList: (0 1 2 3 4),
  multiplier: 0.1rem,
  attributes: (
    bd: border-width,
    bdl: border-left-width,
    bdr: border-right-width,
    bdt: border-top-width,
    bdb: border-bottom-width,
  )
));


@include build-responsive-classes((
  valueList: (0 1 2 3 4),
  multiplier: 0.1rem,
  attributes: (
    bd: border-width,
    bdl: border-left-width,
    bdr: border-right-width,
    bdt: border-top-width,
    bdb: border-bottom-width,
  )
));

@each $name, $color in $sass-core-palette {
  .bd--#{$name} {
    border-color: map.get($color, 'base');

    &.bd--lighten {
      border-color: map.get($color, 'lighter');
    }

    &.bd--darken {
      border-color: map.get($color, 'darker');
    }
  }
}

@include tablet {
  @each $name, $color in $sass-core-palette {
    .tablet-bd--#{$name} {
      border-color: map.get($color, 'base');

      &.tablet-bd--lighten {
        border-color: map.get($color, 'lighter');
      }

      &.tablet-bd--darken {
        border-color: map.get($color, 'darker');
      }
    }
  }
}

@include mobile {
  @each $name, $color in $sass-core-palette {
    .mobile-bd--#{$name} {
      border-color: map.get($color, 'base');

      &.mobile-bd--lighten {
        border-color: map.get($color, 'lighter');
      }

      &.mobile-bd--darken {
        border-color: map.get($color, 'darker');
      }
    }
  }
}

@include build-responsive-classes((
  valueList: (0, sm, md, lg, xl, full, primary),
  tokenBase: '--rc-border-radius-',
  attributes: (
    bd-radius: border-radius,
    bd-tl-radius: border-top-left-radius,
    bd-tr-radius: border-top-right-radius,
    bd-bl-radius: border-bottom-left-radius,
    bd-br-radius: border-bottom-right-radius,
  ),
));

// deprecated legacy class, use `.bd-radius-primary` instead

.bd-rounded {
  border-radius: var(--rc-border-radius-primary);
}
