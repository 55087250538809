@use '../tools/mixins/rc-text';

.rc-accordion {
  width: 100%;
  box-sizing: border-box;
}

.rc-accordion__content-wrapper {
  .rc-accordion--collapsed & {
    overflow: hidden;
    content-visibility: hidden;
  }
}

.rc-accordion__content {
  padding: var(--rc-space-y-accordion) var(--rc-space-x-accordion);

  .rc-accordion--zero-padding &,
  &:empty {
    padding: 0;
  }
}

.rc-accordion__heading__button {
  @include button-reset;

  display: flex;
  width: 100%;
  padding: var(--rc-space-y-accordion) var(--rc-space-x-accordion);
  cursor: pointer;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  gap: var(--rc-space-gap-grid);
  border-radius: var(--rc-border-radius-sm);
  font-weight: var(--rc-font-weight-bold);
  transition: background-color 0.2s;

  @include responsive-hover {
    background: var(--rc-color-background-module-hovered);
  }

  .rc-accordion__heading--locked & {
    @include responsive-hover {
      background: inherit;
    }
  }

  .rc-accordion__heading--small & {
    font-weight: var(--rc-font-weight-normal);
  }

  .rc-accordion__heading--large & {
    padding-top: var(--rc-space-y-accordion-large);
    padding-bottom: var(--rc-space-y-accordion-large);

    @include mobile {
      padding-top: var(--rc-space-y-accordion-large-mobile);
      padding-bottom: var(--rc-space-y-accordion-large-mobile);
    }
  }
}

.rc-accordion__heading__content {
  flex: 1 1 auto;
}

// exposed as RCAccordion.Heading
.rc-accordion__heading__title {
  @include rc-text.title('500');
  color: var(--rc-color-text-primary);

  .rc-accordion__heading--large & {
    @include rc-text.title('700');
  }

  .rc-accordion__heading--small & {
    font-size: 1.6rem;
    font-weight: normal;
  }
}

.rc-accordion__heading__icon {
  width: 2rem;
  margin-left: auto;
  flex-shrink: 0;

  .rc-accordion__heading--locked & {
    display: none;
  }
}
