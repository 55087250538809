@use '../tools/mixins/internal/build';

$spacing-attributes: (
  padding: padding,
  margin: margin,
  ml: margin-left,
  mr: margin-right,
  mt: margin-top,
  mb: margin-bottom,
  pl: padding-left,
  pr: padding-right,
  pt: padding-top,
  pb: padding-bottom,
  mtb: (margin-top margin-bottom),
  mlr: (margin-left margin-right),
  ptb: (padding-top padding-bottom),
  plr: (padding-left padding-right),
  pos-left: left,
  pos-top: top,
  pos-right: right,
  pos-bottom: bottom,
);

$spacing-config-for-scaling: (
  tokenBase: '--rc-space-',
  valueList: (2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 20),
  attributes: $spacing-attributes,
);

$spacing-config: (
  tokenBase: '--rc-space-',
  valueList: (0, 1, px, half),
  attributes: $spacing-attributes,
  valuePairs: (
    auto: auto,
    gutter: var(--rc-space-gutter-page),
  )
);

@include build-responsive-classes($spacing-config-for-scaling);
@include build-responsive-classes($spacing-config);

@include build-classes($spacing-config-for-scaling, $overrides: (classPrefix: 'scaling-'));
@include tablet {
  @include build-classes($spacing-config-for-scaling, $overrides: (classPrefix: 'scaling-', scaleBy: 0.8));
}
@include mobile {
  @include build-classes($spacing-config-for-scaling, $overrides: (classPrefix: 'scaling-', scaleBy: 0.6));
}

@include build-classes((
  valuePairs: (
    space: 0.25em,
  ),
  attributes: $spacing-attributes,
));

// special class just for our responsive gap value
@include build-classes((
  attributes: (
    mt: margin-top,
    mb: margin-bottom,
    pt: padding-top,
    pb: padding-bottom,
  ),
  valuePairs: (
    gap-row: var(--rc-space-gap-row),
  )
));

// Special classes just for checkboxes, radios, and switches
.pl-checkbox {
  padding-left: calc(var(--rc-size-checkbox) + var(--rc-space-gap-checkbox-label));
}

.pl-switch {
  padding-left: calc(var(--rc-size-width-switch) + var(--rc-space-gap-checkbox-label));
}

.ml-checkbox {
  padding-left: calc(var(--rc-size-checkbox) + var(--rc-space-gap-checkbox-label));
}

.ml-switch {
  padding-left: calc(var(--rc-size-width-switch) + var(--rc-space-gap-checkbox-label));
}
