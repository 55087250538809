.input-with-suggestions {
  position: relative;
}

.input-with-suggestions__suggestions {
  border: 0.1rem solid var(--rc-color-border-primary);
  box-sizing: border-box;
  background-color: var(--rc-color-background-module);
  padding: 0.5rem 0;
  border-radius: $input-border-radius;
  position: absolute;
  z-index: 10;
  left: 0;
  width: 100%;
  top: 100%;
}

.input-with-suggestions__option {
  @include button-reset;
  display: block;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  line-height: 1.4;
  font-weight: 700;
  width: 100%;
  text-align: left;
  color: var(--rc-color-text-primary);

  &.input-with-suggestions__option--active {
    background-color: var(--rc-color-background-page-inverted);
    color: var(--rc-color-text-primary-inverted);
  }
}
