@use 'responsive';

@mixin scroll-x-on-mobile {
  @include responsive.mobile {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
}

@mixin scroll-x-on-tablet {
  @include responsive.tablet {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
}
