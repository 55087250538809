.help-card {
  --icon-size: 5rem;
  --icon-padding: 1rem;
  --icon-border-width: 0.4rem;
  --icon-offset: calc((0.5 * var(--icon-size)) + var(--icon-padding) + var(--icon-border-width) + var(--card-padding));
  --card-padding: var(--rc-space-5);

  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: calc(var(--icon-offset) - var(--card-padding));
  padding: var(--card-padding);
  border-radius: var(--rc-border-radius-primary);
  background-color: var(--rc-color-background-alert-info);
}

.help-card--secondary {
  margin-top: 0;
  background-color: var(--rc-color-background-module-secondary);
}

.help-card__icon-container {
  display: flex;
  justify-content: center;
  margin-top: calc(-1 * var(--icon-offset));
}

.help-card__icon-frame {
  border-radius: var(--rc-border-radius-full);
  padding: var(--icon-padding);
  background-color: var(--rc-color-background-page);
  border-width: var(--icon-border-width);
  border-style: solid;
  border-color: var(--rc-color-background-alert-info);
}

.help-card__icon {
  width: var(--icon-size);
  display: block;
}
