@use '@reverbdotcom/cadence/styles/config/scss-tokens';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.sell-form-pricing-guidance__no-estimate {
  @include scaling(padding-left, 3rem);
  @include scaling(padding-right, 3rem);
  @include scaling(padding-top, 3rem);

  p {
    margin-bottom: 0;
  }
}

.sell-form-pricing-guidance__header {
  @include scaling(padding-left, 3rem);
  @include scaling(padding-right, 3rem);

  @include scaling(padding-top, 2rem);
  @include scaling(padding-bottom, 2rem);

  background: var(--rc-color-background-module-price-guide);
  border-top-right-radius: var(--rc-border-radius-primary);
  border-top-left-radius: var(--rc-border-radius-primary);

  h2 {
    @include rc-text.title('500');
    color: var(--rc-color-text-primary-inverted);
  }
}


.sell-form-pricing-guidance__body {
  @include scaling(padding-left, 3rem);
  @include scaling(padding-right, 3rem);

  @include scaling(padding-top, 2rem);
  @include scaling(padding-bottom, 2rem);

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: var(--rc-color-background-module-price-guide-secondary);;
}

.sell-form-pricing-guidance__body__competitive-pricing {
  width: 50rem;

  @include tablet {
    width: 100%;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: var(--rc-font-weight-medium);
  }

  .price-display {
    font-size: 3.8rem;
    font-weight: var(--rc-font-weight-bold);
  }
}

.sell-form-pricing-guidance__body__competitive-pricing__price {
  display: flex;
  margin-bottom: 2rem;

  img {
    margin-right: 2rem;
  }
}

.sell-form-pricing-guidance__body__competitive-pricing__description {
  @include tablet {
    margin-bottom: 2rem;
  }
}

.sell-form-pricing-guidance__body__estimated-price-range-section {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-4);
  padding: var(--rc-space-4);
  width: 22.8rem;

  @include tablet {
    width: 100%;
    padding: 0
  }

  h4 {
    font-size: 1.6rem;
  }

  .price-display-range {
    font-size: 2.2rem;
    font-weight: var(--rc-font-weight-bold);
  }
}

.sell-form-pricing-guidance-modal__nav {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);

  button {
    @include button-as-link;

    font-weight: var(--rc-font-weight-bold);
    color: var(--rc-color-text-primary);
    padding-bottom: var(--rc-space-2);

    &:hover {
      background: none;
      border-width: 0 0 0.2rem;
      border-style: solid;
      border-color: var(--rc-color-palette-vu-orange-500);
    }
  }
}

.sell-form-pricing-guidance-modal__nav--selected {
  button {
    color: var(--rc-color-palette-vu-orange-500);
    border-width: 0 0 0.2rem;
    border-style: solid;
    border-color: var(--rc-color-palette-vu-orange-500);
  }
}
