@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.text-edit-form {
  @include clearfix;
}

.text-edit-form__input {
  @include scaling(margin-bottom, 0.4rem);
  width: 100%;
}

.text-edit-form__save {
  @include rc-button.filled;
  font-size: 0.8em; // button--mini
  padding: 0.3em 0.7em; // button--mini
}

.text-edit-form__char-count {
  display: inline-block;
  font-size: 80%;
  color: var(--rc-color-text-secondary);
  margin-left: 0.5em;
}

.text-edit-form__cancel {
  float: right;
}
