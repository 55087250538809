.card-field {
  width: 100%;
  padding: 0.75rem;
  border: solid 0.1rem var(--rc-color-border-input-primary);
  border-radius: var(--rc-border-radius-input);
  box-sizing: border-box;
  margin-top: 0.375rem;
  margin-bottom: 1rem;
  resize: vertical;
  height: 5rem;
  font-size: 1rem;
}
