.shop-refund-stats {
  margin-top: 1rem;
}

.shop-refund-stats__row {
  @include grid-container;
  align-items: stretch;
  display: flex;
  margin-top: 1rem;

  @include tablet {
    flex-direction: column;
  }
}

.shop-refund-stats__section {
  @include grid-columns(4, 12, 12);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;

  &:last-child {
    border-left: 0.1rem solid var(--rc-color-border-primary);
    text-align: center;
    align-items: center;
    margin-left: 0;
    padding-right: 0;
    justify-content: center;
  }

  &:first-child {
    text-align: left;
    padding-left: 0;
  }

  @include tablet {
    margin-bottom: 2rem;
    text-align: center;
    padding: 0;

    &:last-child {
      padding: 1rem 0 0;
      margin-bottom: 0;
      border-left: 0;
      border-top: 0.1rem solid var(--rc-color-border-primary);
    }
  }
}

.shop-refund-stats__no-refunds {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: bold;

  .shop-refund-stats__icon {
    margin-top: 1rem;
  }

  @include tablet {
    .shop-refund-stats__icon {
      order: -1;
    }
  }
}

.shop-refund-stats__icon {
  width: 4rem;
  margin: 1rem;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.shop-refund-stats__icon-label {
  max-width: 22rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.shop-refund-stats__results {
  min-height: 8.3rem;
}

.shop-refund-stats__results__loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shop-refund-stats__results__count-label {
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0;
}

.shop-refund-stats__results__count {
  font-weight: bold;
  font-size: 200%;
  margin-right: 1rem;
}

.shop-refund-stats__results__subtext {
  color: var(--rc-color-text-primary);
}

.shop-refund-stats__alert {
  margin-bottom: 2rem;

  .shop-refund-stats__alert__content {
    font-size: 1.4rem;
  }

  li {
    list-style-type: disc;
    margin-left: 2rem;
  }
}
