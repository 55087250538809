@use '../tools/mixins/wrapper';

.rc-token-browser {
  background-color: var(--rc-color-background-page-secondary);
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // override to account for default story body classes
  position: absolute;
  inset: 0;

  .rc-site-wrapper__sidebar {
    --gradient-color: var(--rc-color-background-page-secondary);
  }
}

.rc-token-browser__header {
  @include full-bleed;

  background-color: var(--rc-color-background-module);
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
  z-index: 10;
}

.rc-token-browser__header__content {
  @include wrapper.site-wrapper;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--rc-space-12);
}

.rc-token-browser__logo {
  display: flex;
  align-items: center;
  gap: var(--rc-space-2);
  flex: 1 0 25rem;
  font-size: 1.8rem;
  font-weight: bold;

  img {
    width: 3rem;
    height: 3rem;
  }
}

.rc-token-browser__body {
  @include wrapper.site-wrapper;
  width: 100%;
  padding-block: var(--rc-space-6);
  overflow-y: auto;
}

.rc-token-browser__header__tabs {
  display: flex;
  justify-content: center;
}

.rc-token-browser__sidebar-content {
  position: sticky;
  top: var(--rc-space-8);
  // background-color: var(--rc-color-background-module);
}

.rc-token-browser__filters {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-4);
  padding-top: var(--rc-space-4);
}

.rc-token-browser__filter-group {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-2);
}

.rc-token-browser__filter-group__label {
  font-weight: bold;
  font-size: 1.4rem;
}

.rc-token-browser__tokens {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--rc-space-gap-grid);

  @include mobile {
    display: flex;
    flex-direction: column-reverse;
  }
}

.rc-token-browser__tokens__list {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  border-radius: var(--rc-border-radius-lg);
}

.rc-token-browser__footer {
  margin-top: var(--rc-space-12);
  color: var(--rc-color-text-secondary);
  font-size: 1.4rem;
}

.rc-token-browser__empty {
  background-color: var(--rc-color-palette-bw-white);
  border-radius: var(--rc-border-radius-lg);
  padding: var(--rc-space-6);
  text-align: center;
}
