.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  margin: 0;
  border: none;
  display: none;

  a {
    margin-left: 0;
    display: block;
    width: 100%;
    padding: 7px;
    transition: all 0.2s linear;
    box-sizing: border-box;

    &.dropdown-link {
      font-size: 1.6rem;
      font-weight: bold;
      display: block;
      min-width: 200px;
      padding: 6px 14px;
      color: var(--rc-color-text-primary);
      transition: none;
      @include mobile { line-height: 1.4rem; }

      .badge {
        margin: -2px 0 0;
        border-color: var(--rc-color-palette-bw-white);
      }

      &:hover {
        background: var(--rc-color-palette-gray-200);
        .badge { border-color: var(--rc-color-palette-gray-200); }
      }
    }

    .notification { float: right; }
  }

  &.speech-bubble { padding: 0; }
}
