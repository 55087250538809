.csp-price-guide-module-layout {
  width: 100%;

  .csp-price-estimates {
    border: none;
  }

  .csp-price-estimates__values {

    @include tablet {
      flex-direction: column;
    }
  }

  .price-history-graph-container {
    padding-top: 0;

    p:last-child {
      margin-top: 3.35rem;
    }
  }

  .csp-transaction-table-container__header {
    min-height: 42.5rem;
  }

  .csp-transaction-table-container__footer {
    margin-top: 0;
  }
}

.csp-price-guide-module-layout__filters-container {
  @include scaling(padding, 2rem);

  border-bottom: 0.1rem solid var(--rc-color-border-primary);

  @include tablet {
    border-left: 0.1rem solid var(--rc-color-border-primary);
    border-right: 0.1rem solid var(--rc-color-border-primary);
  }
}

.csp-price-guide-module-layout__filters-container__title {
  @include scaling(margin-bottom, 0.5rem);

  font-size: 120%;
  font-weight: bold;
}

.csp-price-guide-module-layout__filters-container__filters {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.csp-price-guide-module-layout_filters_container__filters__model-filter {
  @include scaling(margin-right, 2rem);

  width: 40%;

  .rc-combobox-base__action {
    .rc-icon {
      transform: scale(0.6);
    }
  }

  @include tablet {
    margin-right: 0;
    width: 100%;
  }
}

.csp-price-guide-module-layout_filters_container__filters__condition-filter {
  width: 40%;

  @include tablet {
    @include scaling(margin-bottom, 2rem);

    width: 100%;
  }
}

.csp-price-guide-module-content__estimates-container {
  @include scaling(padding, 2rem);

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);

  @include tablet {
    flex-direction: column;
    border-left: 0.1rem solid var(--rc-color-border-primary);
    border-right: 0.1rem solid var(--rc-color-border-primary);

    .csp-price-guide-module-content__estimates-container__section:nth-child(2) {
      margin-top: 2rem;
    }
  }
}

.csp-price-guide-module-content__estimates-container__header {
  width: 40%;

  @include tablet {
    width: 100%;
  }
}

.csp-price-guide-module-content__estimates-container__header__title {
  @include scaling(margin-bottom, 0.5rem);

  font-size: 120%;
  font-weight: bold;
}


.csp-price-guide-module-content__estimates-container__estimate {
  width: 50%;

  @include tablet {
    margin-top: 1rem;
    width: 100%;
  }
}

.csp-price-guide-module-graph-and-table-container {
  @include scaling(padding-left, 2rem);

  display: flex;

  @include tablet {
    flex-wrap: wrap;
    padding-left: 0;
  }
}

.csp-price-guide-module-graph-and-table-container__price-graph {
  @include scaling(padding-right, 2rem);

  width: 60%;
  border-right: 0.1rem solid var(--rc-color-border-primary);

  @include tablet {
    @include scaling(padding-left, 2rem);
    @include scaling(padding-bottom, 2rem);
    @include scaling(margin-bottom, 4rem);

    width: 100%;
    border-left: 0.1rem solid var(--rc-color-border-primary);
    border-bottom: 0.1rem solid var(--rc-color-border-primary);
    border-bottom-left-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
  }

  .price-history-graph-container__title {
    margin-bottom: 2.4rem;
  }
}

.csp-price-guide-module-graph-and-table-container__transaction-table {
  width: 40%;

  @include tablet {
    width: 100%;
    border: 0.1rem solid var(--rc-color-border-primary);
    border-radius: $input-border-radius;
  }
}

.csp-price-guide-module-table-container {
  @include scaling(padding, 2rem);

  background-color: var(--rc-color-palette-gray-100);

  @include tablet {
    border: 0.1rem solid var(--rc-color-border-primary);
  }
}

.csp-price-guide-module-table-container__header {
  display: flex;
  justify-content: space-between;

  @include tablet {
    flex-direction: column;
  }
}

.csp-price-guide-module-table-container__header__image-and-description {
  display: flex;
  align-items: center;
  width: 50%;

  img {
    margin-right: 2rem;
  }

  @include tablet {
    width: 100%;
  }
}

.csp-price-guide-module-table-container__header__button-container {
  align-self: center;
}

.csp-price-guide-module-table-container__transactions {
  background-color: var(--rc-color-palette-bw-white);
  border: 0.1rem solid var(--rc-color-border-primary);

  .csp-transaction-table-container__header {
    @include scaling(padding-left, 2rem);
    @include scaling(padding-right, 2rem);

    h3 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include tablet {
    margin-top: 2rem;
  }
}

.csp-price-guide-module-orders-empty-state {
  @include scaling(padding-top, 2rem);
  @include scaling(padding-bottom, 2rem);

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--rc-color-palette-gray-100);
  padding-right: 8rem;
  padding-left: 8rem;

  @include tablet {
    @include scaling(padding-left, 2rem);
    @include scaling(padding-right, 2rem);

    justify-content: flex-start;
    border-left: 0.1rem solid var(--rc-color-border-primary);
    border-right: 0.1rem solid var(--rc-color-border-primary);
    border-bottom: 0.1rem solid var(--rc-color-border-primary);
    border-bottom-left-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
  }
}

.csp-price-guide-module-orders-empty-state__description {
  width: 40%;
  margin-left: 2rem;

  @include tablet {
    width: 100%;
    margin-left: 2rem;
  }
}
