.image-gallery {
  @include gap-row;
}

.image-gallery__inner {
  border: 0.1rem solid var(--rc-color-border-primary);

  background: white;
  margin: 0 auto;
  padding: 1rem;
  max-width: 50rem;
}

.image-gallery__title {
  @include responsive(font-size, 2.2rem, 1.9rem, 1.7rem);

  margin-bottom: 0.8rem;
  text-align: center;

  @include mobile {
    font-weight: bold;
  }
}
