$loading-bar-stretch-speed: 0.7s;

.rc-loading-bars {
  transform: translateZ(0);
  width: 4rem;
  height: 2.5rem;

  &.rc-loading-bars--small {
    width: 1.2rem;
    height: 1.2rem;
  }

  &.rc-loading-bars--large {
    width: 5rem;
    height: 4rem;
  }

  &.rc-loading-bars--fluid {
    width: 100%;
    height: 100%;
  }
}

.rc-loading-bars__inner {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.rc-loading-bars__bar {
  width: 15%;
  min-width: 0.1rem;
  height: 100%;
  background-color: var(--rc-color-background-loadingbar);
  animation: loadingBarStretch $loading-bar-stretch-speed infinite ease-in-out;

  .rc-loading-bars--invert-color & {
    background-color: var(--rc-color-background-loadingbar-inverted);
  }
}

@for $i from 2 through 5 {
  .rc-loading-bars__bar:nth-child(#{$i}) {
    animation-delay: -($loading-bar-stretch-speed - $i * 0.1 + 0.1);
  }
}

@keyframes loadingBarStretch {
  0%,
  100% {
    transform: scaleY(0.4);
  }

  15% {
    transform: scaleY(1);
  }
}
