// wacky selector for applying styles to items inside of a Tiles count of $num or fewer.
// e.g. `max-tiles(2)` will only apply styles to something inside of Tiles when there are
// 1, or 2 tiles in the set.

@mixin max-tiles($num) {
  .tiles__tile:nth-last-child(-n + #{$num}):first-child &,
  .tiles__tile:nth-last-child(-n + #{$num}):first-child ~ .tiles__tile & {
    .tiles--grow & {
      @content;
    }
  }
}
