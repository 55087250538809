@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.protection-plan-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  .protection-plan-modal__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.4rem;
  }

  .protection-plan-modal__plan-length {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.4rem;

    align-self: stretch;

    .protection-plan-modal__plan-length-title {
      color: var(--rc-color-text-mulberry);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .protection-plan-modal__plan-length-subtitle {
      color: var(--rc-color-text-secondary);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .protection-plan-modal__checkbox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.6rem;
      align-self: stretch;
      font-weight: normal;
    }

    .protection-plan-modal__selector {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-self: stretch;
      margin-top: -2rem;
      gap: 1rem;

      button {
        flex: auto;
        padding: 0.8rem;
        background-color: var(--rc-color-background-module);
        border: 0.35rem solid var(--rc-color-border-selectable);
        border-radius: var(--rc-border-radius-md);

        &[aria-pressed='true'] {
          border-color: var(--rc-color-text-mulberry);
        }
      }
    }
  }

  .protection-plan-modal__plan-highlights {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.4rem;
    align-self: stretch;

    .protection-plan-modal__plan-copy {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.8rem;
      align-self: stretch;

      .protection-plan-modal__highlight-points {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.8rem;
        align-self: stretch;

        .protection-plan-modal__highlight-point {
          display: flex;
          align-items: flex-start;
          gap: 0.8rem;
          justify-content: flex-start;

          .rc-icon {
            color: var(--rc-color-text-mulberry);
            display: flex;
            padding: var(--rc-padding-tb-button-mini);
            align-items: flex-start;
            gap: 0.8rem;
            max-width: 1.7rem;
          }
        }
      }
    }

    .protection-plan-modal__learn-more {
      @include rc-text.default('350');
      width: 24rem;
      color: var(--rc-color-text-secondary);

      text-decoration-line: underline;
    }
  }

  .protection-plan-modal__logo {
    margin-right: 1rem;

    img {
      height: 5rem;
    }
  }
}
