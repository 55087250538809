.klarna-callout {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
}

.klarna-callout__icon {
  padding-right: 1rem;

  svg {
    height: 3rem;
    width: auto;
  }
}
