@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.sell-form-price-signal-callout {
  height: 0;
  opacity: 0;
  width: 100%;
  transition: height 0.2s, opacity 0.3s ease-in-out;
  margin-top: 2.3rem;

  @include mobile {
    margin: 1.1rem 0;
  }
}

.sell-form-price-signal-callout--visible {
  height: 100%;
  opacity: 1;
}
