.collection-social-links {
  flex: 0 0 auto;
  font-size: 1.4rem;
  line-height: 1.3;
  text-align: right;

  @include mobile {
    text-align: inherit;
  }

  a {
    display: inline-block;
    float: none;
    margin-left: 1em;
  }

  span {
    &.no-icon-bg {
      min-width: 0;
      text-align: right;
    }

    span {
      margin-right: 0;
    }
  }
}
