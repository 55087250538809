.full-bleed-heading {
  @include gap-row;

  position: relative;
  background: center no-repeat var(--rc-color-palette-gray-200);
  background-size: cover;
  display: flex;
  align-items: flex-end;
  clear: both;

  &::before {
    content: '';
    position: absolute;
    left: 5%;
    width: 60%;
    top: -0.4rem;
    height: 0.8rem;
    background: var(--rc-color-palette-vu-orange-500);
  }

  .dynamic-page__one-col & {
    @include viewport-bleed;

    display: flex;
  }
}

.full-bleed-heading__inner {
  margin-top: 20rem;
  position: relative;
  width: 100%;

  .dynamic-page__one-col & {
    @include site-wrapper;
    @include site-wrapper--cms;
  }

  .full-bleed-heading--with-album & {
    margin-top: 22rem;
  }
}

.full-bleed-heading__album {
  @include scaling(bottom, -2em);
  position: absolute;
  right: 0;
}

.full-bleed-heading__text {
  @include responsive(font-size, 2.8em, 2.2em, 1.6em);


  background: var(--rc-color-palette-bw-white);
  padding: 0.3em 0.5em;
  display: inline-block;
  margin-left: -0.5em;

  .dynamic-page__two-col & {
    margin-left: 0;
  }

  .full-bleed-heading__album + & {
    @include responsive(padding-right, 20rem, 18rem, 16rem);
    @include responsive(font-size, 2.8em, 1.8em, 1.3em);

    box-sizing: border-box;
    width: 100%;
  }
}

.full-bleed-heading__title {
  @include hyphenate;
  @include responsive(font-weight, 300, 400);
  hyphens: auto;
}

.full-bleed-heading__subtitle {
  @include responsive(font-size, 2rem, 1.8rem, 1.6rem);

  margin: 0;
}
