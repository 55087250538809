.csp2-summary {
  @include site-wrapper;
}

.csp2-summary__header {
  @include responsive(font-size, 2.2rem, 1.9rem, 1.7rem);
  margin-bottom: 0.8rem;

  @include mobile {
    font-weight: bold;
  }
}

.csp2-summary__wrapper {
  @include scaling(padding, 2rem);
  display: flex;
  border: 0.1rem solid var(--rc-color-border-primary);

  @include mobile {
    flex-direction: column-reverse;
  }
}

.csp2-summary__image {
  flex: 0 0 25%;
  max-width: 27rem;
  margin-right: 2rem;

  @include tablet {
    flex: 0 0 21rem;
  }

  @include mobile {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 2rem;
    margin-right: 0;
  }
}

.csp2-summary__content {
  flex: 1;

  @include tablet {
    margin-bottom: 2rem;
  }
}

.csp2-summary__title {
  font-size: 2.2rem;
  margin-bottom: 2rem;
}

.csp2-summary__description {
  margin: 2rem 0;
}

.csp2-summary__specs {
  & td:first-of-type {
    padding-right: 1rem;
  }
}
