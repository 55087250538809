
.tab-pane {
  display: none;

  &.tab-pane--extrapadded {
    padding: 24px;
  }

  &.active {
    display: block;
  }
}

.tabbable {
  position: relative;

  .tab-content {
    background-color: var(--rc-color-background-page);

    .tab-pane {
      padding: 16px;

      &.tab-pane--unpadded {
        padding: 0;
      }
    }
  }

  &.tabbable--no-bg {
    .nav-tabs {
      background: transparent;
    }

    .tab-content {
      background: transparent;
    }
  }

  .nav-tabs, .mkt-nav-tabs {
    @include clearfix;
    @include scroll-x-on-mobile;
    box-sizing: border-box;
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: 1px solid var(--rc-color-palette-gray-200);
    background: none;
    font-size: 1.4rem;

    > li {
      display: inline-block;
    }

    &.nav-tabs--unpadded {
      padding: 0;
    }

    a {
      @include scaling(padding-left, 1.6rem);
      @include scaling(padding-right, 1.6rem);
      color: var(--rc-color-text-secondary);
      display: block;
      text-align: center;
      line-height: 4;
      position: relative;
      border-bottom: 0.4rem solid transparent;

      &:hover {
        color: var(--rc-color-text-primary);
        border-bottom-color: var(--rc-color-palette-gray-300);
      }

      &.active {
        font-weight: bold;
        color: var(--rc-color-text-primary);
        border-bottom-color: var(--rc-color-text-primary);
      }

      &[data-notification-count]:after {
        position: relative;
        display: inline-block;
        margin-left: 8px;
        margin-top: 0.2em;
        font-size: 0.85em;
      }
    }

    .notification {
      font-size: 11px;
      top: -1px;
      margin-left: 4px;
    }

    &.light-tabs {
      background-color: #DDD;

      li a {
        color: var(--rc-color-text-primary);
        border-width: 0;

        &.active {
          background: var(--rc-color-palette-gray-100);
          border-color: transparent;
        }
      }
    }
  }

  .nav-tabs.nav-tabs--lp a {
    &:hover,
    &.active {
      color: var(--rc-color-palette-blue-600);
    }

    &.active:before {
      border-bottom-color: var(--rc-color-palette-blue-600);
    }

    &[data-notification-count]:after {
      background-color: var(--rc-color-palette-blue-600);
    }
  }
}

// Tab styles for marketing team to use on static pages.
// This is necessary b/c the default tab classes have
// javascript tied to specifc classes that prevents
// hrefs linking out to a different page
.tabbable {
  .mkt-nav-tabs {
    font-size: 1.6rem;
    line-height: 4.5rem;

    a {
      line-height: 4.5rem;
    }
  }
}
