@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.message-box {
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: $input-border-radius;
  background-color: var(--rc-color-palette-bw-white);

  img {
    max-width: 100%;
  }
}

.message-box__text {
  textarea {
    border: none;
    margin-bottom: 0;
    min-height: 10rem;
    height: 10vh;
    padding: 1.2rem;
    resize: vertical;
  }
}

.message-box__content {
  &:empty {
    border-top: none;
  }
}

.message-box__attachments {
  padding: 0 1.2rem;
}

.message-box__suggest-form {
  padding: 0 1.2rem 1.2rem;
}

.message-form__listing-preview {
  .product-preview {
    padding: 0.5rem;
    border: solid 0.1rem var(--rc-color-border-primary);
    border-radius: $input-border-radius;
    margin-bottom: 0.5rem;
  }

  &:last-child .product-preview {
    margin-bottom: 0;
  }
}

.message-box__forms {
  padding: $input-padding-vertical $input-padding-horizontal;

  &:empty {
    padding: 0;
  }
}

.message-box__actions {
  padding: 1rem 1.2rem;
  border-top: 0.1rem solid var(--rc-color-border-primary);
}

.message-box__action {
  @include button-as-link;
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }

  &.message-box__action--active {
    font-weight: 700;
  }

  &.message-box__action--listing {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    @include svg.icon-before(listing-icon, scss-tokens.$rc-color-palette-blue-600);

    &:hover {
      // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
      @include svg.icon-before(listing-icon, scss-tokens.$rc-color-palette-vu-orange-500);
    }
  }

  &.message-box__action--photos {
    @include fa-icon-before(camera);
  }
}
