// width-outset wrapper for use within the
// blog container, which is narrower than the
// regular site container

%blog-wrapper {
  @include site-wrapper;
  @include scaling((margin-top margin-bottom), 4rem);
  margin-left: -10%;
  margin-right: -10%;
  padding: 0;

  @include tablet {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
