@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.item2-about-this-seller {
  background-color: var(--rc-color-background-card);
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-8);

  padding-top: var(--rc-space-8);
  padding-bottom: var(--rc-space-8);

  border-radius: var(--rc-border-radius-lg);
  margin: 0 auto var(--rc-space-20) auto;

  @include mobile {
    @include full-bleed;

    border-radius: 0;
    margin-bottom: var(--rc-space-8);

    padding-top: var(--rc-space-8);
    padding-bottom: var(--rc-space-8);
  }
}

.item2-about-this-seller__heading {
  @include rc-text.title('900');
  padding-left: var(--rc-space-8);
  padding-right: var(--rc-space-8);

  @include mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

.item2-about-this-seller__title {
  @include rc-text.title('700');
  color: var(--rc-color-text-primary);

  @include mobile {
    font-size: var(--rc-font-size-500);
  }
}

.item2-about-this-seller__location-and-rating {
  @include rc-text.utility('350', 'semibold');
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-2);

  span {
    @include rc-text.default($weight: 'bold');

    @include mobile {
      @include rc-text.utility('350', 'semibold');
    }
  }

  .rc-star-rating__count {
    @include rc-text.utility('350', 'semibold');
  }
}

.item2-about-this-seller__actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--rc-space-3);
  padding-right: var(--rc-space-2);

  @include mobile {
    gap: var(--rc-space-4);
    margin-top: var(--rc-space-3);
  }

  .rc-accordion__heading__title {
    font: unset;
  }
}

.item2-about-this-seller__avatar {
  padding-left: var(--rc-space-8);
  padding-right: var(--rc-space-2);
  width: 100%;
  max-width: 19rem;

  @include tablet {
    padding-right: 0;
  }

  @include mobile {
    padding-left: 0;
  }
}

.item2-about-this-seller__stats {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-2);
  font-size: var(--rc-font-size-350);
}

.item2-about-this-seller__quantity {
  @include rc-text.utility('350', 'semibold');
}
