@use '@reverbdotcom/cadence/styles/tools/functions/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.cart-nudge {
  display: flex;
  align-items: center;

  .item2 & {
    margin: 0.5rem 0 1.5rem 0;
    font-size: 0.9em;
  }
}

.cart-nudge__icon {
  display: block;
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  background-image: svg.svg-url(wristwatch, scss-tokens.$rc-color-sentiment-negative-dark);
  background-repeat: no-repeat;
  background-position: center center;
  flex-shrink: 0;

  .cart-nudge--wristwatch & {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    background-image: svg.svg-url(wristwatch, scss-tokens.$rc-color-sentiment-negative-dark);
  }

  .cart-nudge--trending-up & {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    background-image: svg.svg-url(trending-up-arrow, scss-tokens.$rc-color-sentiment-positive-dark);
  }

  .item2 & {
    width: 4rem;
    height: 2.7rem;
    margin-right: 1rem;
  }
}

.cart-nudge__inner {
  flex: 1;
}

.cart-nudge__title {
  font-weight: 700;
  line-height: 1.2;
  margin: 0;

  .cart-nudge--wristwatch & {
    color: var(--rc-color-text-price-nudge);
  }
}

.cart-nudge__subtitle {
  font-size: 1.4rem;
  line-height: 1.2;
  margin: 0;
  color: var(--rc-color-text-secondary);
}
