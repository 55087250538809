@use '../tools/mixins/responsive';
@use '../tools/mixins/truncate-text';

.rc-price-block {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-1);
}

.rc-price-block__discount-block {
  --font-size: 1.4rem;
  --line-height: calc(16 / 14);

  font-size: var(--font-size);
  line-height: calc(var(--line-height) * var(--font-size));
  margin-bottom: var(--rc-space-1);

  .rc-price-block--fixed-height & {
    min-height: calc(var(--font-size) * var(--line-height));
  }

  &:empty {
    display: none;
  }
}

.rc-price-block__discount-price {
  display: inline;
  color: var(--rc-color-text-secondary);

  .rc-price-block--original-price & {
    display: block;
    margin-bottom: var(--rc-space-half);
  }
}

.rc-price-block__currency {
  font-size: 0.6em;
  margin-left: var(--rc-space-sp);
}

.rc-price-block__discount-description {
  display: inline;
  color: var(--rc-color-text-price-nudge);
  font-weight: var(--rc-font-weight-medium);
}

.rc-price-block__discount-block-inline {
  .rc-price-block__price {
    padding-right: 1rem;
  }
}

.rc-price-block__price {
  color: var(--rc-color-text-primary);
  font-weight: var(--rc-font-weight-semibold);
  font-size: 2.2rem;
  line-height: calc(26 / 22);

  @include responsive.mobile {
    font-size: 1.8rem;
  }

  .rc-price-block--highlighted-price & {
    color: var(--rc-color-sentiment-negative-dark);
  }

  .rc-price-block--small-text & {
    font-size: 2rem;
    line-height: 1;

    @include tablet {
      font-size: 1.6rem;
    }
  }
}

.rc-price-block__shipping {
  font-size: 1.4rem;
  line-height: calc(16 / 14);
  font-weight: var(--rc-font-weight-medium);

  .rc-price-block--shipping-strike & {
    color: var(--rc-color-text-secondary);
    text-decoration: line-through;
  }
}

.rc-price-block__shipping-promo {
  &.rc-price-block__shipping-promo--highlight {
    color: var(--rc-color-text-success);
    font-weight: var(--rc-font-weight-medium);
    font-size: 1.4rem;
  }
}
