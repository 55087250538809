@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/svg';

.search-input-group__inner {
  display: flex;
  width: 100%;
  background-color: var(--rc-color-background-input);
  border-radius: var(--rc-border-radius-input);

  .search-input-group--rounded & {
    border-radius: var(--rc-border-radius-full);
  }
}

// specificity due to input[type]
.search-input-group__field {
  flex: 1;
  position: relative;

  input.search-input-group__input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 1;
    position: relative;

    &:focus-visible {
      z-index: 3;
    }
  }

  .search-input-group--rounded & {
    input.search-input-group__input {
      border-top-left-radius: var(--rc-border-radius-full);
      border-bottom-left-radius: var(--rc-border-radius-full);
      border: none;
    }
  }

  .search-input-group--large & {
    @include min-width($site-width-mid) {
      input {
        font-size: 2.4rem;
      }
    }
  }

  + .search-input-group__field {
    input.search-input-group__input {
      border-radius: 0;
      margin-left: -0.1rem;
      width: calc(100% + 0.1rem);
    }
  }
}

.search-input-group__button {
  @include rc-button.transparent;

  border-width: 0.1rem;
  border-color: var(--rc-color-border-input-primary);
  position: relative;
  width: $input-line-height * $input-font-size + $input-padding-vertical * 2;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--rc-border-radius-input);
  border-bottom-right-radius: var(--rc-border-radius-input);
  padding: 0;
  z-index: 2;
  min-height: var(--rc-size-height-input);
  min-width: var(--rc-size-height-input);

  &::after {
    @include svg.icon-mask-image(search, var(--rc-color-text-primary), 2.4rem);

    content: '';
    position: absolute;
    inset: 0;
  }

  &:focus-visible {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--rc-border-radius-input);
    border-bottom-right-radius: var(--rc-border-radius-input);
  }

  .search-input-group--rounded & {
    border: none;
    border-top-right-radius: var(--rc-border-radius-full);
    border-bottom-right-radius: var(--rc-border-radius-full);
  }

  .search-input-group--large & {
    @include min-width($site-width-mid) {
      width: 5rem;

      &::after {
        mask-size: 2.9rem;
      }
    }
  }
}

.search-input-group__clear {
  display: none;
  align-items: center;
  position: absolute;
  right: 0.4em;
  top: 0;
  bottom: 0;
  z-index: 4;

  @include mobile {
    display: flex;
  }
}
