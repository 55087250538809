@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';
@use 'sass:math';

.price-guide-tool-page-wrapper {
  margin-bottom: 4rem;

  .price-guide-tool-page-wrapper__img-header {
    height: 50rem;
    background-image: url('#{$discovery-ui-path}/images/price-guide-tool/header-background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include tablet {
      background-image: url('#{$discovery-ui-path}/images/price-guide-tool/dark-background.jpg');
      background-size: 100% 80rem;
    }

    @include mobile {
      background-size: 100% 65rem;
    }
  }

  .price-guide-tool-page-wrapper__content {
    @include site-wrapper;

    margin-top: -50rem;

    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }

    .price-guide-tool-page-wrapper__content__overview {
      width: 55%;
      padding-top: 12rem;
      margin-bottom: 10rem;
      color: var(--rc-color-palette-bw-white);

      @include mobile {
        padding-left: 3vw;
        padding-right: 3vw;
        box-sizing: border-box;
      }

      @include tablet {
        width: 100%;
      }

      @media screen and (max-width: 1400px) {
        padding-top: 8rem;
        margin-bottom: 6rem;
      }

      @media screen and (max-width: 1050px) {
        padding-top: 2rem;
        margin-bottom: 2rem;
      }

      .price-guide-tool-page-wrapper__mobile-header-image-container {
        display: none;

        @include tablet {
          display: block;
          margin-top: -6rem;
          margin-bottom: -8rem;

          img {
            width: 100%;
          }
        }

        @include mobile {
          margin-top: -2rem;
          margin-bottom: -4rem;
        }
      }

      h1 {
        @include rc-text.display('1600');
        margin-bottom: var(--rc-space-4);

        @media screen and (max-width: 1050px) {
          @include rc-text.title('1000');
          margin-bottom: var(--rc-space-2);
        }
      }

      p {
        font-size: 2rem;
        margin-bottom: 0;

        @include tablet {
          font-size: 1.5rem;
        }
      }
    }

    .price-guide-tool-page-wrapper__content__module-title {
      @include rc-text.title('700');
    }

    .price-guide-tool-usage {
      @include mobile {
        margin-top: -4rem;
      }
    }

    .faceted-grid__inner {
      padding: 0;
    }

    .csp-square-card__title {
      h3 {
        font-size: 1.6rem;
        font-weight: normal;
      }
    }

    .csp-square-card__details {
      .price-guide-tool-card__tooltip {
        font-size: 1.3rem;
        margin-bottom: 1rem;
      }
    }

    & > .site-module {
      @include box-shadow;
      border: none;

      @include mobile {
        box-shadow: none;
        border-radius: 0;
      }
    }
  }

  .price-guide-tool-page-wrapper__estimated-value-info {
    margin: 8rem 0;
    background-image: url('#{$discovery-ui-path}/images/price-guide-tool/dark-background.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 6rem 0;

    @include tablet {
      padding: 2rem 0;
      margin: 6rem 0;
    }

    .price-guide-tool-page-wrapper__estimated-value-info__content {
      @include site-wrapper;

      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 6rem;

      @include tablet {
        display: block;
        padding: 0 3vw;
        width: 100%;
      }

      .price-guide-tool-page-wrapper__estimated-value-info__content__image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;

        @include tablet {
          margin: 2rem auto 4rem;
          width: 100%;
        }

        img {
          height: auto;
          width: 100%;

          @include tablet {
            height: auto;
            width: 70%;
          }

          @include mobile {
            height: auto;
            width: 100%;
          }
        }
      }

      .price-guide-tool-page-wrapper__estimated-value-info__content__text-container {
        color: var(--rc-color-palette-bw-white);
        width: 70%;

        @include tablet {
          width: 100%;
        }

        .price-guide-tool-page-wrapper__estimated-value-info__title {
          @include rc-text.title('700');
          margin-bottom: 2rem;
        }

        p {
          font-size: 2rem;

          &:last-child {
            margin-bottom: 0;
          }

          @include tablet {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  .price-guide-tool-page-wrapper__articles {
    @include site-wrapper;
    align-items: center;
    padding: 0;

    .price-guide-tool-page-wrapper__articles__learn-more {
      @include rc-text.title('700');
      text-align: center;
      margin-bottom: 2rem;
    }

    .tiles__tile {
      @include tablet {
        min-width: 35rem;
      }
    }

    .price-guide-tool-page-wrapper__articles__article {
      border-radius: var(--rc-border-radius-primary);
      color: var(--rc-color-text-primary);
      background-color: var(--rc-color-background-card);
      transition: background-color 0.2s, box-shadow 0.2s;

      &:hover {
        @include box-shadow;
        background-color: var(--rc-color-background-card-hovered);
      }

      .price-guide-tool-page-wrapper__articles__article__image-container {
        height: 21rem;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        background-size: cover;
        background-repeat: no-repeat;
      }

      .price-guide-tool-page-wrapper__articles__article__image-container--date-gear {
        background-image: url('#{$discovery-ui-path}/images/price-guide-tool/article-for-dating-gear.jpg');
      }

      .price-guide-tool-page-wrapper__articles__article__image-container--gear-change {
        background-image: url('#{$discovery-ui-path}/images/price-guide-tool/article-for-gear-over-time.jpg');
      }

      .price-guide-tool-page-wrapper__articles__article__image-container--vintage-faq {
        background-image: url('#{$discovery-ui-path}/images/price-guide-tool/article-for-vintage-guitar-faq.jpg');
      }

      .price-guide-tool-page-wrapper__articles__article__text-container {
        padding: 2rem;

        h3 {
          @include rc-text.utility('450', 'bold');
          margin-bottom: var(--rc-space-2);
        }

        p {
          @include rc-text.default('400');
          margin-bottom: 0;
        }
      }
    }
  }
}
