@use '../tools/mixins/box-shadow' as shadows;

.rc-listing-row-card {
  --card-padding: calc(0.5 * var(--rc-space-gap-grid));
  position: relative;

  // same as rc-listing-card, refactorable into mixin later likely
  &::before {
    @include shadows.box-shadow;
    content: '';
    display: block;
    border-radius: calc(var(--rc-border-radius-lg) + var(--card-padding));
    position: absolute;
    top: calc(-1 * var(--card-padding));
    right: calc(-1 * var(--card-padding));
    bottom: calc(-1 * var(--card-padding));
    left: calc(-1 * var(--card-padding));
    background-color: var(--rc-color-background-module);
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s ease;
  }

  @include responsive-hover {
    &::before {
      opacity: 1;

      @include rc-listing-grid-columns(2) {
        opacity: 0;
      }
    }
  }
}

.rc-listing-row__meta {
  display: none;
}

.rc-listing-row-card__inner {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: var(--rc-space-gap-grid);
}

.rc-listing-row-card__thumbnail {
  display: block;
  flex: 0 0 20rem;
  position: relative;

  @include rc-listing-grid-columns(3) {
    flex-basis: 16rem;
  }
}

.rc-listing-row-card__violator {
  position: absolute;
  bottom: var(--rc-space-2);
  left: var(--rc-space-2);
}

.rc-listing-row-card__favorite-button {
  position: absolute;
  top: var(--rc-space-2);
  right: var(--rc-space-2);
  z-index: 2;
}

.rc-listing-row-card__subcontent {
  display: flex;
  flex-grow: 1;
  gap: var(--rc-space-gap-grid);

  @include rc-listing-grid-columns(2) {
    display: block;
  }
}

.rc_listing-row-card__title-container {
  padding-top: var(--rc-space-3);
  flex-grow: 1;

  @include rc-listing-grid-columns(2) {
    padding-top: 0;
  }
}

.rc-listing-row-card__title {
  font-size: 2.2rem;
  font-weight: bold;
  line-height: calc(26 / 22);
  margin-bottom: var(--rc-space-1);

  @include rc-listing-grid-columns(3) {
    font-size: 1.8rem;
  }

  @include rc-listing-grid-columns(2) {
    font-size: 1.6rem;
  }
}

.rc-listing-row-card__title-link {
  display: block;
  color: var(--rc-color-text-primary);

  @include responsive-hover {
    color: var(--rc-color-text-primary);
  }

  // This is what makes the whole card clickable
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; // it's on "the bottom" of the card's inner clickable elements
  }
}

.rc-listing-row-card__condition {
  font-size: 1.4rem;
  font-weight: var(--rc-font-weight-semibold);
  margin-bottom: var(--rc-space-4);
  line-height: 1.1;

  @include rc-listing-grid-columns(2) {
    margin-bottom: var(--rc-space-3);
  }
}

.rc-listing-row-card__shop-info {
  @include rc-listing-grid-columns(2) {
    display: none;
  }
}

.rc-listing-row-card__price-area {
  flex: 0 0 22rem;
  padding-top: var(--rc-space-3);

  @include rc-listing-grid-columns(2) {
    padding-top: 0;
  }
}

.rc-listing-row-card__nudge {
  margin-top: var(--rc-space-1);
}

.rc-listing-row-card__actions-area {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-2);
  flex: 0 0 20rem;
  padding-top: var(--rc-space-3);
  z-index: 2;

  @include rc-listing-grid-columns(4) {
    display: none;
  }
}

