@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow';
@use '@reverbdotcom/cadence/styles/tools/mixins/gutter';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.site-banner-hero {
  @include site-wrapper;
  @include gap-row;
  padding-top: var(--rc-space-gap-grid);
}

.site-banner-hero__inner {
  @include responsive(min-height, 35rem, 30rem, 20rem);
  display: flex;
  align-items: center;
  background-color: var(--rc-color-background-module-muted);
  background-size: cover;
  background-position: 75%; // prioritizes right side image content.
  background-repeat: no-repeat;
  color: var(--rc-color-text-primary);
  transition: opacity 0.2s ease-in-out, box-shadow 0.15s ease;
  position: relative;
  border-radius: var(--rc-border-radius-lg);

  &:hover {
    @include box-shadow.box-shadow;
    color: var(--rc-color-text-primary);
  }

  @include responsive-hover {
    color: var(--rc-color-text-primary);
    opacity: 0.95;
  }
}

.site-banner-hero__content {
  @include scaling(padding-top, 4rem);
  @include scaling(padding-bottom, 4rem);
  box-sizing: border-box;
  padding-left: var(--rc-space-gutter-page);
  width: 60%;

  &.site-banner-hero__content--light {
    color: var(--rc-color-palette-bw-white);
  }
}

.site-banner-hero__subtext {
  @include rc-text.title('700');
  margin-bottom: var(--rc-space-2);

  @include mobile {
    @include rc-text.default($weight: 'bold');
    margin-bottom: var(--rc-space-1);
  }
}

.site-banner-hero__headline {
  @include rc-text.display;
  margin-bottom: var(--rc-space-8);

  @include mobile {
    @include rc-text.title('900');
    margin-bottom: var(--rc-space-4);
  }
}

.site-banner-hero__cta {
  @include rc-button.outlined;

  @include mobile {
    @include rc-button.size('small');
  }
}

.site-banner-hero__ad {
  position: absolute;
  right: 0;
  bottom: 0;
}
