.bump-tag {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: var(--rc-color-text-secondary);

  svg {
    display: inline-block;
    height: 0.8em;
    width: auto;
    margin-right: 0.5em;

    path {
      fill: currentColor;
    }
  }

  span {
    font-weight: 700;
    line-height: 1.2;
  }

  .grid-card__image & {
    font-size: 1.2rem;
  }
}
