@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';
@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

.related-searches-row {
  align-items: baseline;

  .overflowing-row {
    margin: 0;
  }

  .rc-grid-item {
    width: 100%;
  }
}

.related-searches-image-cards {
  .tiles__tile {
    &:hover {
      .rc-image-border-radius-full {
        @include box-shadow.box-shadow;
      }
    }
  }
}

.related-search-image-card {
  align-items: center;
  color: var(--rc-color-text-primary);
  display: block;
  margin: 0 auto;
}

.related-search-image-card__title {
  @include rc-text.title('500');
}

.related-search-image-card__image-container {
  width: 13rem;
  margin: auto;
}

.related-search-image-card__text {
  @include truncate-text.truncate-text(3);
  @include rc-text.utility('350');

  max-width: 18rem;
  margin: 1rem 0;
  text-align: center;
  max-height: 100%;
  overflow-y: hidden;
}

.related-searches-pills {
  .rc-button {
    white-space: normal;
  }
}

.related-searches-pills-row {
  display: flex;
  gap: var(--rc-space-gap-grid);
}

.related-searches-pills-row-stack {
  flex-wrap: wrap;
  gap: var(--rc-space-3);

  .related-search-pill {
    flex: none;
  }
}

.related-search-pill {
  display: flex;
  flex: 1 0 auto;
}

.related-search-pill__title {
  @include rc-text.title('500');
  align-content: center;
}

.related-search-pill__text {
  @include rc-text.utility('350');
  text-align: left;
}

.related-search-pill__placeholder {
  width: 12rem;
  .related-search-pill__text {
    height: 1.4em;
    width: 80%;
    background: var(--rc-color-palette-gray-300);
    margin-left: 0.5rem;
  }
}

.related-search-pill__icon {
  padding-right: 0.8rem;
  margin: auto;
}
