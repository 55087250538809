.product-badge {
  margin: 0.5rem 0;

  &:empty {
    display: none;
  }

  .dynamic-page--csp & {
    margin: 0;
  }

  .item2-sticky-sidebar & {
    display: inline-block;
    margin: 0 0 0 1rem;
  }
}

.product-badge__badge {
  display: inline;
  padding: 0.2rem 0.5rem 0.5rem;
  border-radius: $input-border-radius;
  background-color: var(--rc-color-background-tag-notification);
  color: var(--rc-color-text-primary);
  font-size: 1.4rem;
  margin: 0.5rem 1rem 0.5rem 0;
}

.product-badge__text {
  [data-rc-tooltip-trigger='true'] & {
    border-bottom: dashed 0.1rem currentColor;
    position: relative;
  }
}

.product-badge__link {
  @include text-link;
  display: inline-block;
  margin: 0.5rem 1rem 0.5rem 0;
  font-size: 1.3rem;

  .item2-sticky-sidebar & {
    display: none;
  }
}
