$mosaic-grid-cols: 12;

.mosaic-listings-collection__grid {
  display: grid;
  column-gap: var(--rc-space-gap-grid);
  row-gap: var(--rc-space-gap-grid);
  --edge-spacing: var(--rc-space-4);
  --min-column-width: 15rem;

  @include tablet {
    --edge-spacing: var(--rc-space-3);
  }

  @include mobile {
    --edge-spacing: var(--rc-space-2);
  }

  &.mosaic-listings-collection__adjust-grid {
    @include mobile {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &.mosaic-listings-collection__adjust-grid-mobile-mosaic {
    @include mobile {
      grid-template-columns: none;
    }
  }

  // loop over 12 columns
  @for $i from 1 through $mosaic-grid-cols {
    &.mosaic-listings-collection__grid--col-d-#{$i} {
      grid-template-columns: repeat($i, minmax(var(--min-column-width), 1fr));

      @include mobile {
        --min-column-width: auto;
      }
    }
  }

  @include tablet {
    @for $i from 1 through $mosaic-grid-cols {
      &.mosaic-listings-collection__grid--col-t-#{$i} {
        grid-template-columns: repeat($i, 1fr);
      }
    }
  }

  @include mobile {
    @for $i from 1 through $mosaic-grid-cols {
      &.mosaic-listings-collection__grid--col-m-#{$i} {
        grid-template-columns: repeat($i, 1fr);
      }
    }
  }
}

.mosaic-listings-collection__item,
.mosaic-listings-collection__item-mobile-mosaic {
  .rc-price-block__price {
    // Added bigger breaking point for smaller font size
    // to prevent overflow on smaller desktop viewport sizes.
    @include max-width(1200px) {
      font-size: var(--rc-font-size-400);
      line-height: 1;
    }
  }
}

.mosaic-listings-collection__item {
  @include mobile {
    min-width: 20rem;
  }
}
