.payment-method__icons {
  .payment-method__icon {
    width: 4.5rem;
    margin: 0.25rem;

    @include mobile {
      width: 4rem;
    }
  }

  &.payment-method__icons--small {
    .payment-method__icon {
      width: 3rem;
    }
  }
}
