@use '@reverbdotcom/cadence/styles/tools/mixins/body-min-height';
@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow' as shadow;
@use '@reverbdotcom/cadence/styles/tools/mixins/responsive-hover' as hover;

.trade-in-layout {
  @include body-min-height.body-min-height;
  width: 100%;
  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }
}

.trade-in-layout__details {
  @include body-min-height.body-min-height;
  display: flex;
  min-width: 50%;
  background-color: var(--rc-color-background-card);
  padding: var(--rc-space-6);
  flex-direction: column;

  &.trade-in-layout__details--eligible {
    background-color: var(--rc-color-palette-tweed-orange-100)
  }

  @include mobile {
    width: 100%;
    // stylelint-disable-next-line declaration-no-important
    min-height: fit-content !important;
    padding: var(--rc-space-4);
  }
}

.trade-in-layout__inner {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--rc-space-4);
}

.trade-in-layout__title {
  font-family: var(--rc-font-family-inter);
  font-size: var(--rc-font-size-700);
  font-weight: var(--rc-font-weight-bold);
  line-height: var(--rc-font-line-height-title);
  letter-spacing: -0.0816rem;
  align-self: center;
  padding: 1rem;

  &.trade-in-layout__title--eligible {
    text-align: center;

    h1 {
      font-size: var(--rc-font-size-900);
      font-weight: var(--rc-font-weight-bold);
      color: var(--rc-color-palette-vu-orange-600);
      padding-bottom: 1rem;
      line-height: var(--rc-font-line-height-title);
    }

    h2 {
      font-size: var(--rc-font-size-900);
      font-weight: var(--rc-font-weight-bold);
      letter-spacing: -0.0625rem;
    }
  }
}

.trade-in-layout__image {
  img {
    width: 25rem;
    height: 25rem;

    @include mobile {
      width: 15rem;
      height: 15rem;
    }
  }
}

.trade-in-layout__make {
  font-family: var(--rc-font-family-inter);
  font-size: var(--rc-font-size-400);
  font-weight: var(--rc-font-weight-bold);
  line-height: var(--rc-font-line-height-utility);
  letter-spacing: 0.01rem;
}

.trade-in-layout__model {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--rc-font-family-inter);
  font-size: var(--rc-font-size-600);
  line-height: var(--rc-font-line-height-utility);
  font-weight: var(--rc-font-weight-medium);
  letter-spacing: -0.024rem;

  @include mobile {
    font-size: var(--rc-font-size-400);
  }
}

.trade-in-layout__creation-date {
  display: flex;
  flex-shrink: 1;
  font-size: var(--rc-font-size-350);
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;

  @include mobile {
    font-size: var(--rc-font-size-300);
  }
}

.trade-in-layout__pricing {
  font-size: var(--rc-font-size-500);
  font-weight: var(--rc-font-weight-bold);
  letter-spacing: -0.024rem;
}

.trade-in-layout__form-section {
  @include body-min-height.body-min-height;
  display: flex;
  min-width: 50%;
  padding: var(--rc-space-6);
  justify-content: center;
  flex-shrink: 1;

  @include mobile {
    width: 100%;
    // stylelint-disable-next-line declaration-no-important
    min-height: fit-content !important;
    padding: var(--rc-space-6) var(--rc-space-4);
  }
}
