@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.listing-quantity-selector {
  display: flex;
  align-items: center;
}

.listing-quantity-selector__amount {
  font-size: 1.3em;
  margin: 0 0.8em;
}

.listing-quantity-selector__add,
.listing-quantity-selector__subtract {
  @include rc-button.muted;

  font-size: 1.6rem;
  line-height: 1;
  padding: 0.2em 0.5em;
}

.listing-quantity-selector__stock {
  opacity: 0.7;
  margin-left: 1em;
  font-size: 0.9em;

  &:first-child {
    margin-left: 0;
  }
}
