.shipping-label-form {
  &.shipping-label-form--placeholder {
    @include loading-pulse;
  }
}

.shipping-label-form__titleblock {
  @include scaling(margin-bottom, 3rem);

  .shipping-label-form--placeholder & {
    &::before,
    &::after {
      content: '';
      display: block;
      background-color: var(--rc-color-palette-gray-300);
      border-radius: $input-border-radius;
    }

    &::before {
      height: 3rem;
      width: 30%;
    }

    &::after {
      height: 2.2rem;
      width: 40%;
      margin-top: 0.5rem;
    }
  }
}

.shipping-label-form__row {
  @include grid-container;
  display: flex;

  @include mobile {
    display: block;
  }
}

.shipping-label-form__module {
  @include scaling(padding, 2rem);
  background-color: var(--rc-color-background-module);
  border-radius: var(--rc-border-radius-primary);
  border: 0.1rem solid var(--rc-color-border-primary);
  margin-bottom: 2%;

  &.shipping-label-form__module--half {
    @include grid-columns(6, $mobile: 12);
  }

  .shipping-label-form--placeholder & {
    background-color: var(--rc-color-palette-gray-300);
    height: 20rem;

    &.shipping-label-form__module--tall {
      height: 30rem;
    }
  }
}

.shipping-label-form__module-footer {
  @include scaling(margin-top, 2rem);
  @include scaling(margin-right, -2rem);
  @include scaling(margin-bottom, -2rem);
  @include scaling(margin-left, -2rem);
  @include scaling(padding, 2rem);
  border-top: solid 0.1rem var(--rc-color-border-primary);
  background-color: var(--rc-color-background-module-secondary);
  border-radius: 0 0 var(--rc-border-radius-primary) var(--rc-border-radius-primary);
}
