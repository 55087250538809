@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.price-guide-tool-instructions__title {
  @include rc-text.title('700');
  margin-top: var(--rc-space-12);
  margin-bottom: var(--rc-space-3);
}

.price-guide-tool-instructions__steps {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--rc-space-gap-grid);

  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
}

.price-guide-tool-instructions__steps__step {
  display: flex;
  gap: var(--rc-space-4);
  flex: 1;
  align-items: flex-start;
  background-color: var(--rc-color-background-module-price-guide-secondary);
  padding: 2rem;
  border-radius: var(--rc-border-radius-md);
}

.price-guide-tool-instructions__steps__step__number {
  --container-size: 4.8rem;
  display: inline-flex;
  min-width: var(--container-size);
  height: var(--container-size);
  border-radius: var(--rc-border-radius-full);
  align-items: center;
  justify-content: center;
  background-color: var(--rc-color-background-module-price-guide);
  color: var(--rc-color-background-module-price-guide-secondary);

  @include mobile {
    --container-size: 4rem;
  }
}
