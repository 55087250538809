@use 'sass:math';

// Assigning a temporary gutter spacing since the _tiles.scss stuff is so hard to parse
$temp-gutter: 1rem;

.sortable-tiles {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  opacity: 1;
  transition: opacity 0.3s;

  // TODO build these values based on existing methods
  margin-bottom: -#{$temp-gutter * 2};
  margin-left: -#{$temp-gutter};
  margin-right: -#{$temp-gutter};

  &.sortable-tiles--reloading {
    opacity: 0.6;
  }
}

.sortable-bump-promo {
  order: 0;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  min-width: 0;
  padding: 0 1rem;

  .product-feature-card {
    padding: 0;
    flex: 1;
  }
}

.sortable-tile {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 math.percentage(math.div(1, 4));
  max-width: math.percentage(math.div(1, 4));
  min-width: 0;

  // TODO build these values based on existing methods
  margin-bottom: $temp-gutter * 2;
  padding-left: $temp-gutter;
  padding-right: $temp-gutter;

  $grid-4up: 1081px; // only 1px higher since it flips to be min-width
  $grid-3up: 1080px;
  $grid-2up: 890px;

  @media screen and (max-width: $grid-3up) {
    flex-basis: math.percentage(math.div(1, 3));
    max-width: math.percentage(math.div(1, 3));
  }

  @media screen and (max-width: $grid-2up) {
    flex-basis: math.percentage(math.div(1, 2));
    max-width: math.percentage(math.div(1, 2));
  }

  // hide things on a 4-up grid (~desktop)...
  // needs a min-width query VS max-width to combat our standard query direction
  &.sortable-tile--hide-4up {
    @media screen and (min-width: $grid-4up) {
      display: none;

      .faceted-grid--wide & {
        display: flex;
      }
    }
  }

  // For ~tablet sizes
  &.sortable-tile--hide-3up {
    @media screen and (max-width: $grid-3up) and (min-width: $grid-2up) {
      display: none;

      .faceted-grid--wide & {
        display: flex;
      }
    }
  }

  // For ~mobile sizes
  &.sortable-tile--hide-2up {
    @media screen and (max-width: $grid-2up) {
      display: none;

      .faceted-grid--wide & {
        display: flex;
      }
    }
  }

  @for $order from 1 through 25 {
    &.sortable-tile--4up-order-#{$order} {
      order: $order;
    }

    @media screen and (max-width: $grid-3up) {
      &.sortable-tile--3up-order-#{$order} {
        order: $order;
      }
    }

    @media screen and (max-width: $grid-2up) {
      &.sortable-tile--2up-order-#{$order} {
        order: $order;
      }
    }
  }

  .faceted-grid--wide & {
    $wide-grid-5up: 1081px; // only 1px higher since it flips to be min-width
    $wide-grid-4up: 1080px;
    $wide-grid-3up: 890px;
    $wide-grid-2up: 640px;

    @for $order from 1 through 37 {
      &.sortable-tile--5up-order-#{$order} {
        order: $order;
      }

      @media screen and (max-width: $wide-grid-4up) {
        &.sortable-tile--4up-order-#{$order} {
          order: $order;
        }
      }

      @media screen and (max-width: $wide-grid-3up) {
        &.sortable-tile--3up-order-#{$order} {
          order: $order;
        }
      }

      @media screen and (max-width: $wide-grid-2up) {
        &.sortable-tile--2up-order-#{$order} {
          order: $order;
        }
      }
    }

    @media screen and (min-width: $wide-grid-5up) {
      flex-basis: math.percentage(math.div(1, 5));
      max-width: math.percentage(math.div(1, 5));
    }

    @media screen and (max-width: $wide-grid-4up) {
      flex-basis: math.percentage(math.div(1, 4));
      max-width: math.percentage(math.div(1, 4));
    }

    @media screen and (max-width: $wide-grid-3up) {
      flex-basis: math.percentage(math.div(1, 3));
      max-width: math.percentage(math.div(1, 3));
    }

    @media screen and (max-width: $wide-grid-2up) {
      flex-basis: math.percentage(math.div(1, 2));
      max-width: math.percentage(math.div(1, 2));
    }
  }
}
