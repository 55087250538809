@use '@reverbdotcom/cadence/styles/tools/functions/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-image';

.lightbox-image__primary {
  position: relative;
  border: 0.1rem solid var(--rc-color-border-primary);

  + .lightbox-image__thumbs {
    margin-top: 1rem;
  }

  .item2 &,
  .featured-listing-module & {
    border: none;
  }

  .csp2-product-module & {
    border: none;
  }

  .featured-listing-module &,
  .csp2-product-module & {
    border-radius: var(--rc-border-radius-lg);
    overflow: hidden;
  }
}

.lightbox-image__thumbs {
  margin: 0 -1rem;
  position: relative;

  .lightbox-image--larger-thumbs & {
    margin-left: 0;
    margin-right: 0;
  }

  &::before,
  &::after {
    width: 1rem;
    position: absolute;
    background: linear-gradient(to right, var(--rc-color-highlight-0) 0%, var(--rc-color-background-page) 100%);
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    content: '';
    pointer-events: none;

    .lightbox-image--larger-thumbs & {
      display: none;
    }
  }

  &::before {
    background: linear-gradient(to left, var(--rc-color-highlight-0) 0%, var(--rc-color-background-page) 100%);
    left: 0;
    right: auto;
  }

  .lightbox-modal & {
    margin: 0;
    position: absolute;
    // accounts for browser safe area when present
    bottom: calc(1rem + var(--rc-space-inset-browser-safe-area-bottom));
    right: 0;
    left: 0;

    &::after,
    &::before {
      display: none;
    }
  }

  .item2 &,
  .featured-listing-module & {
    padding: 1rem 0;
    margin: 0;

    @include mobile {
      border-top: 0.1rem solid var(--rc-color-border-primary);
    }

    &::after,
    &::before {
      display: none;
    }
  }

  .featured-listing-module & {
    @include mobile {
      border-top: 0;
    }
  }
}

.lightbox-image__thumbs__inner {
  padding: 0 0 1rem 0;
  display: flex;
  scroll-behavior: smooth;
  overflow: hidden;
  overflow-x: auto;
  z-index: 1;
  margin-bottom: -1rem;

  .lightbox-image--larger-thumbs & {
    flex-wrap: wrap;
    overflow: visible;
    padding: 0;
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lightbox-modal & {
    display: block;
    white-space: nowrap;
    padding: 0;
    text-align: center;
  }
}

.lightbox-image__thumb {
  @include responsive(flex-basis, 6rem, 5rem, 4rem);
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 0.5rem;
  transition: opacity .1s;

  &.lightbox-image__thumb--border {
    & .lightbox-image-item {
      border-color: var(--rc-color-border-accent);
      border-width: 0.2rem;
      border-style: solid;
    }
  }

  &.lightbox-image__thumb--large {
    @include mobile {
      flex-basis: 40%;
    }
  }

  .lightbox-modal & {
    @include responsive(width, 6rem, 5rem, 4rem);
    display: inline-block;

    &.lightbox-image__thumb--inactive {
      opacity: 0.6;

      & .lightbox-image-item {
        border-color: transparent;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }


  .lightbox-image--larger-thumbs & {
    box-sizing: border-box;
    flex-basis: 20%;
    padding: 0 1rem 1rem;

    @include tablet {
      flex-basis: 25%;
    }

    @include mobile {
      flex-basis: calc(100% / 3);
    }
  }

  .lightbox-image__primary + .lightbox-image__thumbs & {
    &.lightbox-image__thumb--inactive {
      opacity: 0.6;

      & .lightbox-image-item {
        border-color: transparent;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.lightbox-image__control {
  @include button-reset;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent center no-repeat;
  background-size: auto 80%;
  opacity: 0.5;
  transition: opacity .2s, right .2s, left .2s;

  .lightbox-image--hide-mobile-controls & {
    @include mobile {
      display: none;
    }
  }

  .lightbox-modal & {
    @include responsive((width height), 5rem, 4rem);
  }

  .lightbox-image__primary & {
    width: 4rem;
    height: 4rem;
    background-color: var(--rc-color-highlight-700);
    border-radius: 50%;
  }

  &.lightbox-image__control--next {
    .lightbox-modal & {
      // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
      @include svg.svg-background-image(angle-right, scss-tokens.$rc-color-palette-bw-white);

      right: 0;

      @include mobile {
        display: none;
      }
    }

    .lightbox-image__primary & {
      // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
      @include svg.svg-background-image(angle-right, scss-tokens.$rc-color-palette-gray-900);

      background-position: 55% 50%;
      right: -5rem;
    }

    .lightbox-image__primary:hover & {
      right: 2%;
    }

    &:focus-visible {
      right: 2%;
      opacity: 1;
      transition: right 0s, opacity 0.2s;
    }
  }

  &.lightbox-image__control--prev {
    .lightbox-modal & {
      // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
      @include svg.svg-background-image(angle-left, scss-tokens.$rc-color-palette-bw-white);

      left: 0;

      @include mobile {
        display: none;
      }
    }

    .lightbox-image__primary & {
      // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
      @include svg.svg-background-image(angle-left, scss-tokens.$rc-color-palette-gray-900);

      background-position: 45% 50%;
      left: -5rem;
    }

    .lightbox-image__primary:hover & {
      left: 2%;
    }

    &:focus-visible {
      left: 2%;
      opacity: 1;
      transition: left 0s, opacity 0.2s;
    }
  }

  &:hover {
    opacity: 1;
  }
}

.lightbox-image__primary__caption {
  font-size: 1.3rem;
  padding: 0.5em 1em 1em 1em;
  text-align: center;

  .lightbox-modal & {
    position: absolute;
    bottom: calc(100% + 2rem);
    left: 2rem;
    right: 2rem;
    padding: 0;
    color: var(--rc-color-palette-bw-white);
    font-weight: bold;
  }
}
