@use '@reverbdotcom/cadence/styles/tools/mixins/gutter';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.search-overview {
  @include clearfix;
  margin-bottom: 2rem;

  position: relative;
  z-index: 2;
  opacity: 1;
  transition: opacity .3s;

  @include mobile {
    @include scaling(margin-bottom, 2rem);
    border: none;
  }

  &.search-overview--new {
    position: unset;

    &.search-overview--loading {
      opacity: 1;
    }
  }

  &.search-overview--loading {
    opacity: 0.6;
  }
}

.search-overview__count {
  font-size: 1.6rem;
  flex: 1 1 auto;

  @include mobile {
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    font-size: 1.4rem;
  }

  > h1,
  > h2,
  > h3 {
    @include rc-text.title('700');
    font-weight: normal;
  }

  > h2.search-overview__count-title {
    @include rc-text.title('900');
  }
}

.search-overview__feed {
  .search-overview--new &,
  .csp2-marketplace-filters & {
    display: flex;
    align-items: center;
  }
}

.search-overview__feed {
  @media screen and (max-width: 751px) {
    margin-left: auto;
  }
}

.search-overview__primary,
.search-overview__secondary {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.search-overview__primary {
  gap: 1rem;
}

.search-overview__secondary {
  margin-top: 1rem;

  .search-overview--new &,
  .csp2-marketplace-filters & {
    flex-direction: column;
  }

  &:empty {
    margin-top: 0;
  }

  @include mobile {
    @include gutter.full-bleed;
    display: block;
    overflow-x: hidden;
  }
}

.search-overview__filters {
  flex: 1;
  margin-right: 1rem;

  @include mobile {
    margin: 0.5rem 0;
  }

  &:empty {
    display: none;
  }

  .search-overview--new &,
  .csp2-marketplace-filters & {
    margin-right: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    @include mobile {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    &.search-overview__filters--mobile {
      @include mobile {
        @include gutter.full-bleed;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
      }
    }
  }
}

.search-overview__mobile-overflow {
  @include mobile {
    overflow: hidden;
    overflow-x: scroll;
    margin-left: calc(-1 * var(--rc-space-gutter-page));
    width: 100vw;
  }
}

.search-overview__pinned-query-coupon-switch-container {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: space-between;

  &:empty {
    display: none;
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}

.search-overview__coupon-switch-container {
  display: flex;
}

.search-overview__pinned-query-container {
  min-width: 45rem;
  display: flex;
  flex-direction: row-reverse;
  margin-left: auto;

  @include tablet {
    min-width: unset;
    width: 100%;
  }
}

.search-overview__pinned-query {
  width: 100%;
}

.search-overview__count {
  .search-overview--new.search-overview--loading & {
    opacity: 0.6;
  }
}

.search-overview__controls {
  .search-overview__filters:empty + & {
    width: 100%;
  }
}

.search-overview__loading-text {
  @include loading-pulse;
  background-color: var(--rc-color-background-loading-placeholder);
  width: 8em;
  height: 1em;
  border-radius: $input-border-radius;
}

.search-overview__list-view-toggle {
  display: flex;
  align-items: center;

  @include mobile {
    display: none;
  }
}

.search-overview__list-view-toggle-loading-placeholder {
  @include loading-pulse;
  background-color: var(--rc-color-background-loading-placeholder);
  width: 8rem; // arbirary value to estimate normal button width
  height: 1rem;
  border-radius: 1em;
  margin: 0.6rem;
}

.search-overview__info-container-inverted {
  display: flex;
  flex-direction: column-reverse;
}
