@use 'sass:math';
@use '../tools/mixins/internal/build';
@use '../config/configuration' as config;

@include build-responsive-classes((
  valuePairs: $font-weights,
  attributes: (
    weight: font-weight,
  )
));

@include build-responsive-classes((
  valueList: (normal, italic),
  attributes: (
    style: font-style,
  )
));

@include build-responsive-classes((
  valueList: (capitalize uppercase lowercase none),
  attributes: (
    tt: text-transform,
  )
));

@include build-responsive-classes((
  valueList: (underline line-through),
  attributes: (
    td: text-decoration,
  )
));

@include build-responsive-classes((
  valueList: (normal nowrap),
  attributes: (
    ws: white-space,
  )
));

@include build-responsive-classes((
  valueList: (50 60 70 80 90 100 110 120 130 140 150 160 170 180 190 200 250 300 350 400 450 500),
  multiplier: 0.01rem,
  // fixed-size classes approximate percentages but through fixed rem's
  // e.g. size-fixed-100 should be 1.6rem (equiv to 100%)
  scaleBy: 1.6,
  attributes: (
    size-fixed: font-size,
  ),
));

@include build-responsive-classes((
  valueList: (50 60 70 80 90 100 110 120 130 140 150 160 170 180 190 200 250 300 350 400 450 500),
  multiplier: 1%,
  attributes: (
    size: font-size,
  ),
));

@include build-responsive-classes((
  valueList: (80 90 100 110 120 130 140 150 160 170 180 190 200),
  multiplier: 0.01,
  attributes: (
    lh: line-height,
  ),
));

.ff-normal {
  font-family: var(--rc-font-family-page), config.$body-font-family-fallback;
}

.ff-monospace {
  font-family: $mono-font-family;
}
