.dialog {
  @include clearfix;
  @include box-shadow;
  border-radius: $input-border-radius;
  background: var(--rc-color-palette-bw-white);

  clear: both;
  padding: 0;

  .heading {
    padding: 10px 18px;
    color: var(--rc-color-palette-bw-white);
    background: var(--rc-color-palette-gray-900);
    border-radius: 3px 3px 0 0;

    @include mobile {
      padding: 10px;
    }

    h3 {
      font-weight: normal;
      color: var(--rc-color-palette-bw-white);
    }

    a {
      color: var(--rc-color-palette-blue-300);
    }

    &.heading--light {
      background-color: var(--rc-color-palette-gray-100);
      border-bottom: 1px solid var(--rc-color-palette-gray-200);
    }
  }

  &.unpadded {
    .body {
      padding: 0;
    }
  }

  .body {
    @include clearfix;
    padding: 1em;
    background: var(--rc-color-palette-bw-white);
    max-height: 75vh;
    overflow-y: auto;

    &.paywall-body {
      padding: 1rem 2rem;

      li label {
        display: block;
      }
    }
    &.scrollable {
      overflow-y: auto;
    }
  }

  &.refund-dialog .body {
    max-height: none;
  }

  .actions {
    @include clearfix;
    border-radius: 0 0 3px 3px;

    display: block;
    clear: both;
    padding: 9px;
    padding-right: 18px;
    border-top: 1px solid var(--rc-color-palette-gray-200);
    background: var(--rc-color-palette-gray-100);

    .buttons {
      @include clearfix;
      float: right;
      a,
      input {
        margin-left: 9px;
      }
    }
  }

  input[type="text"],
  label,
  select {
    display: block;
    &.inline {
      display: inline-block;
    }
  }

  li label {
    display: inline;
  }

  // small-dialog, medium-dialog, etc

  $dialog-sizes: (
    small: 380px,
    medium: 480px,
    large: 680px,
    extra-large: 780px,
    video: 600px,
    new-password: 350px
  );

  @each $name, $size in $dialog-sizes {
    &.#{$name}-dialog {
      width: $size;
      max-width: $size;
      @include mobile {
        max-width: 100%;
      }
    }
  }

  div.attachinary_container {
    ul {
      max-height: 250px;
      overflow-y: scroll;
    }
  }
}
