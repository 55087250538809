@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.csp-thumbs {
  @extend %component-row;
}

.csp-thumbs__heading {
  @extend %component-row-heading;
  text-align: center;
}

.csp-thumbs__action {
  margin-top: $grid-gutter;
  text-align: center;

  a {
    @include rc-button.filled;
    @include fa-icon-after(arrow-right);
  }

  @include mobile {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
