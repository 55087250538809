.combined-inputs {
  display: flex;
  position: relative;

  input[type] { // needs attr selector for specificity override
    border-radius: 0;
    margin-right: -0.1rem;

    &:first-child {
      border-radius: 0.4rem 0 0 0.4rem;
    }

    &:last-child {
      border-radius: 0 0.4rem 0.4rem 0;
      margin-right: 0;
    }

    &:focus-visible {
      z-index: 1; // to prevent focused borders from overlapping
    }
  }
}
