.label-carrier__header {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.label-carrier__logo {
  flex-shrink: 0;
  flex-grow: 0;
}

.label-carrier__callout {
  margin-left: 2rem;
}

.label-carrier__disclaimer {
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-size: 1.4rem;
  opacity: 0.7;
  margin-left: 0.5rem;
}

.label-carrier__disclaimer-description {
  margin-right: 0.5rem;
}
