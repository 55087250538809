// This mixin should be used for rendering text that should only be seen be screen readers
// stylelint-disable declaration-property-unit-allowed-list
@mixin visually-hidden {
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
