@use '../tools/mixins/dialogs';
@use '../tools/mixins/focus-outline';

.rc-guide-popover {
  min-width: 24rem;
  max-width: 35rem;
  box-sizing: border-box;
  padding: var(--rc-space-4);

  &.rc-guide-popover--narrow {
    max-width: 24rem;
  }

  @include mobile {
    min-width: unset;
    max-width: calc(100vw - 2rem);
    padding: var(--rc-space-2);
  }
}

.rc-guide-popover__a11y-description {
  @include visually-hidden;
}

.rc-guide-popover__title {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0 0 var(--rc-space-1);

  @include mobile {
    font-size: 1.6rem;
    margin-bottom: 0;
  }
}

.rc-guide-popover__footer {
  display: flex;
  padding-top: var(--rc-space-4);
  justify-content: flex-end;

  .rc-button--inverted {
    &:focus-visible {
      @include focus-outline.focus-outline($offset: true);
      outline-color: var( --rc-color-palette-bw-white);
    }
  }
}
