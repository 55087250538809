.bump-split-button {
  margin-top: 3rem;
}

.bump-split-button__label {
  margin-bottom: 1rem;
}

.bump-split-button__options {
  display: flex;
  width: 100%;
  font-weight: bold;
  box-sizing: border-box;
  align-items: flex-start;
}

.bump-split-button__option {
  flex: 1;
  border: 0.1rem solid var(--rc-color-border-primary);
  padding: 0.1rem;

  button {
    @include button-reset;

    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--rc-color-text-primary);
    padding: 1rem 0.2em;
    background: white;
  }

  &.bump-split-button__option--selected {
    button {
      background: var(--rc-color-sentiment-positive-dark);
      color: var(--rc-color-palette-bw-white);
      box-shadow: inset 0 0 0.4rem var(--rc-color-sentiment-positive-dark);
    }

    &:first-child {
      border-bottom-right-radius: 0.6rem;
    }
  }

  + .bump-split-button__option {
    border-left: none;
  }

  &:first-child {
    border-top-left-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;

    button {
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;
    }
  }

  &:last-child {
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;

    button {
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    }
  }
}

.bump-split-button__custom-field {
  z-index: -1;
  padding: 0.2rem 0.1rem 0.1rem 0.1rem;
  border-top: 0.1rem solid var(--rc-color-border-primary);
  margin: 0.1rem -0.1rem 0 -0.1rem;
}

.bump-split-button__highlights {
  display: flex;
  margin-top: 2rem;
}

.bump-split-button__highlight {
  flex: 1;
  height: 0;
  width: 100%;
  position: relative;

  &.bump-split-button__highlight--active {
    &::before,
    &::after {
      position: absolute;
      display: block;
      bottom: calc(100%);
      left: 50%;
      margin-left: -1rem;
      content: '';
      width: 0;
      height: 0;
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
      border-bottom: 1rem solid var(--rc-color-palette-blue-100);
    }

    &::after {
      bottom: calc(100% - 0.2rem);
      border-bottom: 1rem solid var(--rc-color-palette-gray-100);
    }
  }
}
