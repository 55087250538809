@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/functions/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.csp-search-input-v2 {
  // We are adding this top level BEM class for clarity. There is no styling applied for this class
}

.csp-search-input-v2__form {
  margin: 1rem 0;
  position: relative;
}

.csp-search-input-v2__form__input {
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: none;

  &:hover {
    @include box-shadow;
  }
}

.csp-search-input-v2__form__input__search {
  box-sizing: border-box;
  margin: 0;
  background: var(--rc-color-background-input);
  border-radius: $input-border-radius 0 0 $input-border-radius;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  border: solid 0.1rem var(--rc-color-border-input-primary);

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
    -webkit-appearance: none;
  }

  input[type='search'] {
    @include responsive(font-size, 2rem, 1.6rem);
    @include responsive(line-height, 2, 1.6);
    @include responsive(padding, 1.25rem 1.5rem, 1rem 1.25rem);
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    border: none;
    margin: 0;
    resize: none;
    box-shadow: none;
    width: 100%;

    &::placeholder {
      color: var(--rc-color-text-secondary);
      opacity: 1; // Fix for browsers that don't default to 1
    }
  }
}

.csp-search-input-v2__form__input__search__clear {
  cursor: pointer;
  height: calc(100% - 0.2rem);
  position: absolute;
  right: 0;
  top: 0.1rem;
  width: 4.1rem;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4; // 1+ of the focused state for the adjacent input

  @include mobile {
    width: 3.1rem;
  }

  &:hover::after {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    background-image: svg.svg-url(close, scss-tokens.$rc-color-palette-gray-700);
  }
}

.csp-search-input-v2__form__input__search__button {
  @include rc-button.filled;

  font-size: 120%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: $input-border-radius;
  border-bottom-right-radius: $input-border-radius;

  @include tablet {
    font-size: 100%;
  }
}
