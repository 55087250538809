@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.cms-centered-page-head__image {
  @include responsive(height, 25vh, 20vh, 12rem);
  @include responsive(min-height, 15rem, $mobile: 12rem);
  @include commons-svg-background('instruments-lighten.svg');

  background-color: var(--rc-color-palette-blue-600);
  max-width: none;
  max-height: 20rem;

  //with image
  &[style] {
    // removes the pattern but is overridden by the inline image
    background-image: none;
    background-color: var(--rc-color-palette-gray-900);
    background-size: cover;
    background-position: center;
  }
}

.cms-centered-page-head__wrapper {
  @include site-wrapper;

  .cms-centered-page-head--no-actions & {
    text-align: center;
  }

  .cms-centered-page-head--no-actions.cms-centered-page-head--long-description & {
    text-align: left;
  }
}

.cms-centered-page-head__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--rc-color-background-page);
  position: relative;
  padding-top: var(--rc-space-6);
  padding-bottom: var(--rc-space-12);

  @include mobile {
    display: block;
  }

  .cms-centered-page-head--no-actions & {
    display: inline-flex;
    justify-content: center;

    &::after {
      display: none;
    }
  }
}

.cms-centered-page-head__text {
  @include responsive(padding-right, 2em, $mobile: 0);

  position: relative;

  .cms-centered-page-head--no-actions & {
    padding-right: 0;
  }
}

.cms-centered-page-head__title {
  @include rc-text.display;
}

.cms-centered-page-head__subtitle {
  @include responsive(font-size, 1.2em, 1em, 0.9em);

  font-weight: 400;
  line-height: 1.4;
  margin: 0.4em 0 0 0;

  .cms-centered-page-head--long-description & {
    font-size: 0.9em;

    @include mobile {
      font-size: 0.8em;
    }
  }
}

.cms-centered-page-head__fineprint {
  font-size: 1.2rem;
  color: var(--rc-color-text-secondary);
  margin-top: 1em;
}

.cms-centered-page-head__actions {
  text-align: right;
  flex: 0 0 25rem;

  @include tablet {
    flex-basis: 20rem;
  }

  @include mobile {
    text-align: center;
    margin-top: 1rem;
  }

  // Overrides default View All Listings link on the holiday sale page
  a {
    @include text-link;

    .holiday-sale-grid & {
      display: none;
    }
  }
}
