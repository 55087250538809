.listing-card-nudge {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  margin: 0.2rem 0;

  img {
    height: 2rem;
    width: 2rem;
    margin-right: 0.5rem;
  }
}
