.price-guide-tool-form {
  .form-section {
    border: none;

    .form-section__wrapper {
      .form-section__info {
        flex-basis: 22%;
        margin-right: 4rem;

        @include tablet {
          flex-basis: 32%;
        }

        @include mobile {
          margin-right: 0;
        }

        .form-section__title {
          .rc-button {
            white-space: normal;
          }
        }
      }

      .form-section__content {
        .site-module {
          .site-module__footer {
            @extend .bg-white;

            button {
              @include mobile {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .price-guide-tool-estimates-container {
    .site-module__content {
      padding: 0;
    }
  }

  .site-module__title {
    font-size: 1.8rem;
  }
}
