.cms-reviews {
  @include scaling((margin-top margin-bottom), 5rem);
  @include scaling((padding-top padding-bottom), 3rem);

  border: 0.1rem solid var(--rc-color-border-primary);
  background: white;

  .dynamic-page__two-col__main & {
    @include scaling(padding, 3rem);
  }
}

.cms-reviews__inner {
  .dynamic-page__one-col & {
    @include site-wrapper;
    @include site-wrapper--cms;
  }
}

.cms-reviews__title {
  @include responsive(font-size, 2.4rem, 1.9rem, 1.7rem);
  text-align: center;
  margin-bottom: 0.5em;
}
