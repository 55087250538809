@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.grid-filter {
  white-space: nowrap;
  display: flex;
  align-items: flex-start;

  @include mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .search-overview__filters:empty + .search-overview__controls & {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

// also used in the SuggestedScopes component
.grid-filter__action {
  @include rc-button.muted;
  padding: 0.5em 1em;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1;

  &.grid-filter__action--active {
    border-color: var(--rc-color-palette-vu-orange-500);
    font-weight: bold;
  }

  + .grid-filter__action {
    margin-left: 0.3em;
  }
}

.grid-filter__conditions {
  display: inline-block;

  @include tablet {
    display: none;
  }
}

.grid-filter__mobile-toggle {
  display: none;

  @include mobile {
    display: block;
  }
}

.grid-filter__sort {
  display: inline-block;
  margin-left: 1rem;

  select {
    line-height: 1;
    font-size: 1.4rem;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}

.grid-filter__location {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
  font-size: 1.4rem;

  &:empty {
    display: none;
  }
}
