@use '../tools/mixins/button-reset';
@use '../tools/mixins/box-shadow';
@use '../tools/mixins/button-as-link';
@use '../tools/mixins/links';
@use '../tools/mixins/responsive-hover';
@use '../tools/mixins/rc-text';

.rc-token-details {
  @include box-shadow.box-shadow;
  position: sticky;
  top: 0;
  background-color: var(--rc-color-background-module);
  border-radius: var(--rc-border-radius-lg);
  transition: background-color 0.2s ease-in-out;
  box-sizing: border-box;

  &.rc-token-details--placeholder {
    padding: var(--rc-space-6);
    text-align: center;
    box-shadow: none;
    border: 0.3rem dashed var(--rc-color-border-primary);
    background-color: transparent;
  }
}

.rc-token-details__section {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-2);
  padding: var(--rc-space-4);

  & + .rc-token-details__section {
    border-top: 0.1rem solid var(--rc-color-border-primary);
  }
}

.rc-token-details__section__heading {
  font-size: 1.4rem;
  font-weight: bold;
}

.rc-token-details__section__value {
  font-size: 1.6rem;
  font-family: var(--rc-font-family-monospace);
}

.rc-token-details__main {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-4);
  color: var(--rc-color-text-primary);
}

.rc-token-details__group {
  display: flex;
  justify-content: space-between;
  gap: var(--rc-space-2);
}

.rc-token-details__value {
  font-family: var(--rc-font-family-monospace);
  font-size: 1.6rem;
  padding: var(--rc-space-1) 0;

  &.rc-token-details__value--heading {
    font-family: var(--rc-font-family-heading);
    font-size: 2.4rem;
    font-weight: bold;
    padding: 0;
    margin-top: calc(var(--rc-space-half) * -1);
  }

  &.rc-token-details__value--color {
    display: flex;
    align-items: center;
    gap: var(--rc-space-2);
  }

  &.rc-token-details__value--description {
    font-family: var(--rc-font-family-page);
  }
}

.rc-token-details__label {
  font-size: 1.3rem;
  color: var(--rc-color-text-secondary);
}

.rc-token-details__link-group {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-2);
  align-items: flex-start;
}

.rc-token-details__link {
  @include button-as-link.button-as-link;
  @include links.text-link;

  text-align: left;
}

.rc-token-details__link-details {
  color: var(--rc-color-text-secondary);
}

.rc-token-details__deprecated-tag {
  @include rc-text.eyebrow;

  background-color: var(--rc-color-background-notice-bold);
  color: var(--rc-color-text-on-notice-bold);
  padding: var(--rc-space-2) var(--rc-space-4);
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
