.boxed-list__item {
  position: relative;
  margin-bottom: 1rem;
  padding: 1em;
  border: 0.2rem solid var(--rc-color-border-primary);
  border-radius: 0.5rem;
  font-weight: normal;
  transition: border-color 0.2s ease;

  &.boxed-list__item--small {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &.boxed-list__item--no-margin {
    margin: 0;
  }

  &.boxed-list__item--selectable {
    cursor: pointer;
    color: var(--rc-color-text-secondary);
    border: 0.2rem solid var(--rc-color-border-selectable);

    &:hover {
      color: inherit;
      background: var(--rc-color-background-module-hovered);
    }

    :checked + &,
    &.boxed-list__item--active {
      color: inherit;
      border-color: var(--rc-color-border-selected);
      cursor: default;
    }
  }

  // For use when each item is toggleable
  // Items always need cursor b/c they can be toggled off
  &.boxed-list__item--toggleable,
  &.boxed-list__item--checkbox-toggleable {
    cursor: pointer;
    color: var(--rc-color-text-secondary);

    &:hover {
      color: inherit;
      background: var(--rc-color-background-module-hovered);
    }

    :checked + &,
    &.boxed-list__item--active {
      color: inherit;
      border-color: var(--rc-color-border-selected);
    }

    // special case where selected items do not need the green border
    &.boxed-list__item--selected {
      color: inherit;
      border-color: var(--rc-color-border-selected);
    }
  }

  &.boxed-list__item--checkbox-toggleable {
    cursor: default;
    padding: 0;

    .boxed-list__toggle {
      display: block;
      cursor: pointer;
      padding: 1em;
    }

    .boxed-list__body {
      padding: 0 1em 1em 1em;
    }
  }

  // this accounts for `.dialog li label` in `dialog.scss` overriding its display attr
  .dialog {
    li & {
      display: block;
    }
  }
}

.boxed-list__item__shipping-method {
  font-size: 95%;
  color: var(--rc-color-text-primary);
}

.boxed-list__item__shipping-price {
  font-size: 95%;
  color: var(--rc-color-text-primary);
  text-align: right;
}

.boxed-list__item__hint {
  font-size: 85%;
}

// we appear to need the specificity here to override core styles
input[type='radio'].boxed-list__toggle,
input[type='checkbox'].boxed-list__toggle {
  display: none;

  &.boxed-list__toggle--show-radio,
  &.boxed-list__toggle--show-checkbox {
    display: block;
    position: absolute;
    margin-top: 0.6rem;
    margin-left: 0.6rem;
    z-index: 2;

    & + .boxed-list__item--selectable {
      padding-left: 1.7em;

      @include mobile {
        padding-left: 2rem;
      }
    }
  }

  &.boxed-list__toggle--show-checkbox--multi-order {
    margin-top: 1.8rem;
    margin-left: 1.2rem;

    & + .boxed-list__item--toggleable {
      padding-left: 2.4em;

      @include mobile {
        padding-left: 2em;
      }
    }

    @include mobile {
      margin-top: 1.7em;
    }
  }

  @include mobile {
    margin-top: 2rem;
  }
}

.boxed-list__action {
  position: absolute;
  bottom: 1em;
  right: 1em;
  font-weight: bold;
}
