@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.rich-text-editor__editor-container {
  border: solid 0.1rem var(--rc-color-border-input-primary);
  border-radius: var(--rc-border-radius-input);
  position: relative;
  text-align: left;
  cursor: text;
}

.rich-text-editor__editor-inner {
  background: var(--rc-color-background-page);
  position: relative;
  border-radius: var(--rc-border-radius-input);
}

.rich-text-editor__editor-input {
  position: relative;
  min-height: 20rem;
  padding: 1rem;
  border-radius: var(--rc-border-radius-input);
  background: var(--rc-color-background-input);
  resize: none;
  position: relative;
  outline: 0;

  p {
    margin-bottom: 1rem;
  }
}

.rich-text-editor__italic {
  font-style: italic;
}

.rich-text-editor__placeholder {
  color: var(--rc-color-text-secondary);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 1.25rem;
  left: 1rem;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.rich-text-editor__tag {
  margin-left: 0.5rem;
  transform: translateY(0.1rem); // slight offset to get vertical centering but a flex-start alignment

  .rc-tag {
    display: block;
    flex-shrink: 0; // this tag should never change size
    flex-grow: 0;
  }
}

.rich-text-editor__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.rich-text-editor__label {
  font-size: 1.4rem;
  font-weight: bold;
  display: block;
  max-width: 100%;
  word-wrap: break-word;
}

.rich-text-editor__admin-button {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;

  @include mobile {
    justify-content: flex-start;
    margin-top: 1rem;
  }
}

.rich-text-editor__toolbar {
  display: flex;
  border-bottom: solid 0.1rem var(--rc-color-border-input-primary);
  border-radius: var(--rc-border-radius-input) var(--rc-border-radius-input) 0 0;
}

.rich-text-editor__toolbar__button {
  @include rc-button.transparent($size: 'medium');

  min-width: var(--rc-min-height-button-medium);
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  border-width: 0;
  border-right: solid 0.1rem var(--rc-color-border-input-primary);

  &:focus-visible {
    z-index: 1;
  }

  &:first-child {
    border-top-left-radius: var(--rc-border-radius-input);
  }

  &.rich-text-editor__toolbar__button--selected {
    @include rc-button.colors($theme: 'main', $variant: 'filled', $inverted: false);
    border-color: var(--rc-color-border-primary);
  }

  &.rich-text-editor__toolbar__button--disabled {
    --text-color: var(--rc-color-text-primary-disabled);
    --background-color-hovered: var(--background-color);
  }

  .rc-icon {
    width: 2rem;
  }
}
