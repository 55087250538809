@use 'sass:math';
@use '../../config/configuration' as config;
@use './box-shadow';

@mixin panel() {
  @include box-shadow.box-shadow;
  box-sizing: border-box;
  display: block;
  background-color: var(--rc-color-background-elevated);
  border-radius: var(--rc-border-radius-lg);
  border: solid 0.1rem var(--rc-color-border-primary);
}

// These arrows are wrapper divs with a set size (20x10 by default) with a ::before pseudo element inside.
// Those ::before elements are squares are sized using trig (SCSS' math.hypot) to ensure the diagonal
// from corner to corner is equal to the wrapper div's _width_
// More on SCSS math: https://sass-lang.com/documentation/modules/math#hypot
//
// The inner element is then shifted and rotated accordingly to align based on the "direction" it needs to face.
// The overflow is then `hidden`.
//
// This approach means we draw one single inner shape, and the remaining complexity is just the transform positioning.
// This approach plays well with our floating-ui React library.
@mixin arrow(
  $arrow-height: 8px,
  $border-width: 1px,
  $border-color: var(--rc-color-border-primary),
  $fill-color: var(--rc-color-background-elevated),
  $extend: true,
) {
  $arrow-width: $arrow-height * 2;
  $inner-box-size: math.hypot($arrow-height, $arrow-height);
  $offset-width: ($arrow-width - $inner-box-size) * 0.5;
  $offset-height: math.ceil(($inner-box-size - $arrow-height) * 0.5);
  $offset-alt: math.ceil($inner-box-size * 0.5);

  @if($extend == true) {
    position: absolute;
    overflow: hidden;
    box-sizing: border-box;
  }

  &::before {
    @if($extend == true) {
      content: '';
      display: block;
      border-style: solid;
      transform-origin: center;
      box-sizing: border-box;
    }

    border-width: $border-width;
    border-color: $border-color;
    width: $inner-box-size;
    height: $inner-box-size;
    background-color: $fill-color;
  }

  &--top,
  &--bottom {
    width: $arrow-width;
    height: $arrow-height;
  }

  &--top {
    &::before {
      transform: translate($offset-width, $offset-height) rotate(45deg);
    }
  }

  &--bottom {
    &::before {
      transform: translate($offset-width, -#{$offset-alt}) rotate(45deg);
    }
  }

  &--left,
  &--right {
    width: $arrow-height;
    height: $arrow-width;
  }

  &--left {
    &::before {
      transform: translate($offset-height, $offset-width) rotate(45deg);
    }
  }

  &--right {
    &::before {
      transform: translate(-#{$offset-alt}, $offset-width) rotate(45deg);
    }
  }
}

