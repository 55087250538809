[data-truncate-with-fade='true'] {
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    background: linear-gradient(to top, transparent, var(--rc-color-palette-bw-white));
    height: 3em;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    // the fade should be the same as the bg color it's on top of
    .site-module__footer & {
      background: linear-gradient(to top, transparent, var(--rc-color-palette-gray-100));
    }
  }
}

[data-truncate-with-fade='false'] {
  height: auto;

  &::after {
    display: none;
  }
}

[data-truncate-without-fade='true'] {
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    height: 3em;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
