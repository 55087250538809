@use './box-shadow';
@use './responsive-hover' as hover;

// This mixin can be used to apply base 2.0 styles to a container element

@mixin card-base {
  border-radius: var(--rc-border-radius-primary);
  background-color: var(--rc-color-background-card);
  transition: background-color 0.15s ease, box-shadow 0.15s ease;

  @include hover.responsive-hover {
    @include box-shadow.box-shadow;
    background-color: var(--rc-color-background-card-hovered);
  }
}
