@use '@reverbdotcom/cadence/styles/config/configuration' as config;
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.blog-nav {
  position: relative;
  margin-bottom: 4rem;
  z-index: 3;

  @include tablet {
    border-bottom: solid 0.1rem var(--rc-color-border-primary);
  }

  &.blog-nav--with-subpage {
    margin-bottom: 0;
  }

  .in-mobile-app & {
    display: none;
  }
}

.blog-nav__title {
  margin: 0;
  position: relative;

  @include min-width(config.$site-width-mid) {
    @include visually-hidden;
  }

  a {
    @include group-link;
    @include rc-text.title('700');
    display: block;
    padding: 1.5rem 3vw 0; // l/r padding mirrors that of site-wrapper below
  }
}

.blog-nav__menu {
  @include site-wrapper;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 6rem;
  position: relative;

  @include tablet {
    display: block;
    max-height: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    column-count: 2;
    gap: 2rem;
  }
}

.blog-nav__item {
  flex: 1;
  order: 1;
  text-align: center;

  @include tablet {
    text-align: left;
  }

  &.blog-nav__item--logo {
    flex: 0;

    a {
      display: block;
      width: 8rem;
      transition: transform 0.15s ease;
    }

    a:hover {
      transform: scale(1.1);
    }

    @include tablet {
      display: none;
    }
  }
}

.blog-nav__link {
  @include group-link;
  display: block;
  padding: 1.5rem 1rem;

  @include tablet {
    padding: 0.5rem 0;
  }
}

.blog-nav__logo {
  display: block;
  width: 100%;
  height: auto;

  .blog-nav--condensed &,
  .blog-nav--overlaid & {
    width: 4rem;
    padding: 0 1rem;
  }
}
