.rc-checkbox {
  &:is(button) {
    // tri-state checkboxes are button elements
    @include button-reset;
  }

  display: flex;
  align-items: flex-start;
  position: relative;

  // colors needed for when this renders as a link
  color: var(--rc-color-text-primary);

  @include responsive-hover {
    color: var(--rc-color-text-primary);
  }

  &:is(a) {
    cursor: pointer;

    &.rc-checkbox--disabled {
      cursor: not-allowed;
    }
  }

  // disabled state for label
  &.rc-checkbox--disabled {
    color: var(--rc-color-text-secondary);
    cursor: not-allowed;
  }
}

.rc-checkbox--padded-hover {
  padding: var(--rc-space-x-accordion);
  background-color: transparent;
  transition: background-color ease 0.15s;
  border-radius: var(--rc-border-radius-sm);

  @include responsive-hover {
    background-color: var(--rc-color-background-module-hovered);
  }
}

.rc-checkbox__input {
  opacity: 0;
  height: var(--rc-size-checkbox);
  width: var(--rc-size-checkbox);
  position: absolute;
  z-index: 2; // on top, but invisible to accept clicks
  cursor: pointer;
  margin: 0;
  padding: 0;

  &:disabled {
    cursor: not-allowed;
  }
}

.rc-checkbox__box {
  box-sizing: border-box;
  display: block;
  position: relative;
  width: var(--rc-size-checkbox);
  height: var(--rc-size-checkbox);
  background-color: var(--rc-color-background-input);
  border-width: 0.2rem;
  border-style: solid;
  border-color: var(--rc-color-border-input-primary);
  border-radius: var(--rc-border-radius-checkbox);
  z-index: 1;
  flex-shrink: 0;
  flex-grow: 0;

  .rc-icon {
    display: block;
    width: calc(var(--rc-size-checkbox) * 0.7); // 1.4rem
    height: calc(var(--rc-size-checkbox) * 0.7); // 1.4rem
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  // Checked state styling
  .rc-checkbox--checked &,
  .rc-checkbox__input:checked + & {
    background-color: var(--rc-color-background-input-checked);
    color: var(--rc-color-background-page);
    border-color: var(--rc-color-background-input-checked);

    .rc-icon {
      opacity: 1;
    }
  }

  // Hover state styling
  .rc-checkbox:hover:not(.rc-checkbox--disabled) & {
    color: var(--rc-color-text-input-secondary);

    .rc-icon {
      opacity: 1;
    }
  }

  // Disabled state styling
  .rc-checkbox--disabled {
    opacity: 0.5;
  }
}

// Focus-visible state styling
.rc-checkbox__input:focus-visible + .rc-checkbox__box {
  @include focus-outline;
  outline-offset: -0.1rem; // border thickness
}
