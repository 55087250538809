@use '../tools/mixins/dialogs';
@use '../tools/mixins/focus-outline' as focus;
@use '../config/configuration' as config;

.rc-combobox-base {
  display: flex;
  border: solid 0.1rem var(--rc-color-border-input-primary);
  border-radius: var(--rc-border-radius-input);
  background-color: var(--rc-color-background-input);

  &:focus-within {
    @include focus.focus-outline;
    outline-offset: -0.1rem; // thickness of the input border
  }

  &:has(input:disabled) {
    background-color: var(--rc-color-background-input-disabled);
  }
}

.rc-combobox-base__input[type=text] {
  border: none;
  outline: none;
}

.rc-combobox-base__actions {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: var(--rc-space-px);
}

.rc-combobox-base__action {
  .rc-icon {
    pointer-events: none;
  }

  &.rc-combobox-base__action--select {
    .rc-icon {
      transform: scale(1.2);
    }
  }

  &.rc-combobox-base__action--clear {
    .rc-icon {
      transform: scale(0.8);
    }
  }

  &.rc-combobox-base__action--search {
    .rc-icon {
      transform: scale(1.1);
    }
  }

  &.rc-combobox-base__action--loading {
    margin: 0 var(--rc-space-1);
    transform: scale(1.5);
  }
}

.rc-combobox-base__input__icon {
  display: flex;
  align-items: center;
  right: config.$input-padding-horizontal;
  top: 0;
  bottom: 0;
  pointer-events: none;
  width: 2rem;

  // resizes & positions loading indicator to match other icons
  &.rc-combobox-base__input__icon--loading {
    right: var(--rc-space-half);
    transform: scale(1.5);
    color: var(--rc-color-text-primary);
  }

  // scales down select icons to visually match select tag styling
  &.rc-combobox-base__input__icon--select {
    width: 1.4rem;
  }
}

.rc-combobox-base__panel {
  @include dialogs.panel;
  border-radius: var(--rc-border-radius-input);
  z-index: var(--z-index-combobox);
  margin-right: var(--rc-space-2);
  max-height: 40rem;
  overflow-y: auto;
}

.rc-combobox-base__options {
  padding: 0.5rem 0;
}

.rc-combobox-option,
.rc-autocomplete-item {
  display: flex;
  justify-content: space-between;
  padding: config.$input-padding-vertical config.$input-padding-horizontal;
  // background-color: var(--rc-color-background-page);
  line-height: 1.5;
  transition: background-color 0.2s;

  &[data-active=true] {
    background-color: var(--rc-color-background-module-hovered);
    cursor: pointer;
  }

  &[aria-disabled=true] {
    background-color: var(--rc-color-transparent);
    color: var(--rc-color-text-primary-disabled);
    cursor: default;
  }

  &[aria-selected=true] {
    background-color: var(--rc-color-background-module-hovered);
    font-weight: bold;
  }
}

.rc-autocomplete-item {
  &.rc-autocomplete-item--link {
    text-decoration: underline;
  }
}

.rc-combobox-option__icon {
  margin-left: 0.5rem;
  margin-top: var(--rc-space-half);
  width: 2rem;
}

.rc-autocomplete-item__icon {
  margin: 0.2rem -0.2rem 0 0.5rem;
  width: 2rem;
  transform: scale(0.8);
}
