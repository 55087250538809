@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.featured-listing-module {
  @include site-wrapper;
  @include scaling(margin-bottom, 3rem);

  transition: opacity 0.3s ease-in-out;

  // hides primary listing content when featured listing module is present
  &.featured-listing-module--hide-other-content ~ .dynamic-page__two-col {
    display: none;
  }

  &.featured-listing-module--reloading {
    opacity: 0.5;
  }

  &.featured-listing-module--hidden {
    display: none;

    // displays primary listing content when featured listing module is hidden
    &.featured-listing-module--hide-other-content ~ .dynamic-page__two-col {
      display: flex;

      @include mobile {
        display: block;
      }
    }
  }

  .dynamic-page--csp & {
    @include responsive(padding, 2rem, 1rem, 0);
    max-width: unset;
    margin-bottom: 3rem;
    border: 0.1rem solid var(--rc-color-border-primary);
    border-radius: var(--rc-border-radius-primary);

    &.featured-listing-module--bottom-border-only {
      padding-left: 0;
      padding-right: 0;
      border: none;
      border-bottom: 0.1rem solid var(--rc-color-border-primary);
      border-radius: 0;
    }
  }

  @include mobile {
    padding: 0;
  }
}

.featured-listing-module__heading {
  display: none;

  @include mobile {
    display: block;
    margin-bottom: 1rem;
    font-size: 2rem;
    color:  var(--rc-color-text-primary);
  }
}

.featured-listing-module__wrapper {
  display: flex;
  width: 100%;
  padding-bottom: 3rem;

  @include mobile {
    flex-wrap: wrap;
  }

  .dynamic-page--csp & {
    padding-bottom: 0;
  }
}

.featured-listing-module__image-wrapper {
  @include scaling(margin-right, 2rem);

  flex: 0 0 25%;

  @include tablet {
    flex: 0 0 40%;
  }

  @include mobile {
    display: inline;
    margin-bottom: 0.5rem;
    flex: 0 0 100%;
  }
}

.featured-listing-module__image {
  .featured-listing-module--loading & {
    @include loading-pulse;
    padding-bottom: 100%;
    background-color: var(--rc-color-background-loading-placeholder);
  }

  @include mobile {
    border: none;
    display: none;
  }

  &.featured-listing-module__image--mobile {
    display: none;

    @include mobile {
      display: block;

      .featured-listing-module--loading & {
        display: flex;
        padding-bottom: 0;
        background-color: transparent;

        li {
          @include loading-pulse;
          width: 35%;
          height: 30vw;
          margin: 1rem 0.5rem;
          background-color: var(--rc-color-background-loading-placeholder);
        }

        li:first-of-type {
          margin-left: 1rem;
        }

        li:last-of-type {
          margin-right: 1rem;
        }
      }
    }
  }
}

.featured-listing-module__shop {
  max-width: 40rem;
  margin-top: 1.6rem;

  @include tablet {
    margin-top: 0;
    max-width: 100%;
  }
}

.featured-listing-module__section-header {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  opacity: 0.7;
  margin-bottom: 0.5rem;
}

.featured-listing-module__main {
  flex: 1;

  @include mobile {
    padding: 0 var(--rc-space-gutter-page);
  }

  .dynamic-page--csp & {
    @include mobile {
      padding: 0 1rem 1rem;
    }
  }
}

.featured-listing-module__bumptag {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-size: 1.2rem;
  color: var(--rc-color-text-tag-bump);
  font-weight: bold;
  line-height: 1.2;
}

.featured-listing-module__title {
  @include responsive(font-size, 2.8rem, 2rem, 1.8rem);
  line-height: 1.2;
  margin-bottom: 0.6rem;
  display: block;
  color: var(--rc-color-text-primary);

  .dynamic-page--csp & {
    @include responsive(font-size, 1.4em, 1.2em, 1.1em);
    font-weight: bold;
  }
}

.featured-listing-module__status {
  @include scaling(margin-bottom, 2rem);

  font-size: 1.6rem;
  text-align: center;
}

.featured-listing-module__return-policy {
  display: flex;
  flex: 1 0 auto;

  p {
    margin-bottom: 0;
  }
}

.featured-listing-module__details {
  @include scaling(margin-top, 2rem);
  display: flex;
  flex: 1 0 auto;
  margin-top: 0;

  @include tablet {
    flex-direction: column-reverse;
  }

  @include mobile {
    flex-direction: column-reverse;
  }
}

.featured-listing-module__primary {
  margin-right: 3rem;
  flex: 1;

  @include mobile {
    margin-right: 0;
  }
}

.featured-listing-module__description {
  margin-top: 2rem;
  font-size: 90%;
  word-break: break-word; // prevents overflow on csp

  @include tablet {
    order: 2;
  }
}

.featured-listing-module__secondary {
  flex: 0 0 30rem;

  @include tablet {
    flex: 1 0 20rem;
    margin-bottom: 2rem;
  }

  @include mobile {
    margin-top: 1rem;
    margin-left: 0;
    flex-basis: 100%;
    order: 1;
  }
}

.featured-listing-module__listing-row {
  @include scaling((margin-top, margin-bottom), 4rem);

  &:empty {
    display: none;
  }

  &.featured-listing-module__listing-row--truncated {
    position: relative;
    max-height: 70rem;
    overflow: hidden;
    margin-bottom: 0;

    @include tablet {
      max-height: 60rem;
    }

    @include mobile {
      max-height: 80rem;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 10rem;
      background: linear-gradient(transparent, var(--rc-color-palette-bw-white));
      z-index: 1;

      @include tablet {
        height: 5rem;
      }
    }
  }
}

.featured-listing-module__toggle-wrapper {
  @include site-wrapper;
  margin: 0 auto;
  display: none;
  width: 100%;
  position: relative;
  justify-content: center;
  background: var(--rc-color-palette-bw-white);
  margin-bottom: 4rem;
  border-top: 0.1rem solid var(--rc-color-border-primary);

  .featured-listing-module__wrapper + &,
  .featured-listing-module__listing-row--truncated + & {
    display: flex;
    z-index: 10;
  }

  .featured-listing-module__toggle-action {
    @include rc-button.muted;
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    @include svg.icon-after('angle-down', scss-tokens.$rc-color-palette-gray-900);
    display: block;
    margin-top: -2rem;
  }
}

.featured-listing-module__secondary__link {
  @include text-link;
  @include svg.icon-after('arrow-right');
  @include scaling('margin-top', 2rem);
  display: block;
  text-align: center;
  font-weight: bold;

  @include mobile {
    display: none;
  }
}

.featured-listing-module__secondary__link--mobile-only {
  @include scaling('margin-top', 2rem);
  display: none;
  text-align: left;
  font-weight: bold;

  @include mobile {
    display: block;
  }
}

.featured-listing-module__jumplink {
  @include svg.icon-after('angle-down', $as-link: true);
  display: block;
  font-weight: bold;
  text-align: center;
  padding: 1rem 0;
}
