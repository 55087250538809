@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.app-callout {
  background-color: var(--rc-color-palette-yellow-500);
  border-radius: var(--rc-border-radius-lg);
  overflow: hidden;

  @include mobile {
    border-top-right-radius: 7.2rem;
  }
}

.app-callout__inner {
  @include responsive(max-height, 25rem, 25rem, 40rem);
  @include responsive(margin, 2rem, 2rem, 0);
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--rc-color-text-primary);

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.app-callout__inner-text {
  margin: 2rem var(--rc-space-gap-grid);

  @include mobile {
    margin: 2rem;
  }
}

.app-callout__qr-code-container {
  @include responsive(max-height, 25rem, 25rem, 40rem);
  display: flex;
  align-items: center;
  margin: 1.5rem var(--rc-space-gap-grid);
  padding: 1rem;
}

.app-callout__qr-code {
  @include responsive(width, 12rem, 10rem);
  background-color: var(--rc-color-background-page);
  border-radius: var(--rc-border-radius-md);
  padding: 1rem;
}

.app-callout__qr-code-text {
  @include rc-text.utility('350', 'semibold');
  padding-bottom: 1rem;
}

.app-callout__title {
  @include rc-text.title('900');
  padding-bottom: 1rem;
}

.app-callout__subtitle {
  @include rc-text.title('700', 'regular');

  @include tablet {
    @include rc-text.default;
  }

  @include mobile {
    display: none;
  }
}

.app-callout__mobile-image {
  display: none;

  @include mobile {
    display: flex;
    width: 100%;
  }
}

.app-callout__image {
  @include responsive(max-height, 30rem, 30rem, 40rem);
  background-size: cover;
  display: flex;
  // height: 100%;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
}

.app-callout__app-store-badges {
  display: none;

  @include mobile {
    display: flex;
    margin: 2rem;
    margin-top: 0;
    a {
      margin-right: 1rem;
    }
  }
}
