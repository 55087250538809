.rc-drawer {
  @include box-shadow;

  --modal-padding: var(--rc-space-6);

  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 40rem;
  max-width: 100%;
  max-height: 100%;
  background-color: var(--rc-color-background-module);
  border: 0.1rem solid var(--rc-color-border-primary);
  display: flex;
  flex-direction: column;

  @include mobile {
    --modal-padding: var(--rc-space-4);
  }
}

.rc-drawer__header {
  position: relative;
  padding: var(--modal-padding);
}

.rc-drawer__body {
  position: relative;
  flex: 1;
  overflow: hidden;

  &::before,
  &::after {
    @include box-shadow($spread-radius: var(--rc-space-1));
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 0.1rem;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &::before {
    top: -0.1rem;
    border-bottom: 0.1rem solid var(--rc-color-border-primary);
  }

  &::after {
    bottom: -0.1rem;
    border-top: 0.1rem solid var(--rc-color-border-primary);
  }

  &.rc-drawer__body--shadow-top {
    &::before {
      opacity: 1;
    }
  }

  &.rc-drawer__body--shadow-bottom {
    &::after {
      opacity: 1;
    }
  }
}

.rc-drawer__body__content {
  height: 100%;
  padding: var(--rc-space-1) var(--modal-padding);
  box-sizing: border-box;
  overflow: auto;

  & > [data-observer] {
    height: 0.1rem;
  }
}

.rc-drawer__footer {
  padding: var(--modal-padding);
  padding-bottom: max(var(--modal-padding, var(--rc-space-inset-browser-safe-area-bottom)));
}
