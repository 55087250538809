.item2-return-policy {
  font-size: 0.9em;

  li {
    display: inline-block;
  }

  li + li {
    &::before {
      display: inline-block;
      content: '•';
      font-size: 0.8em;
      margin: 0 0.6em;
      font-weight: bold;
    }
  }
}
