.price-change-display__price-description {
  font-size: initial;
  font-weight: normal;
  margin-bottom: 2rem;
}

.great-value-price-container {
  display: flex;
  margin-top: 2rem;
}

.price-guide-tool-estimates {
  @include scaling(padding, 2rem);
}
