.rc-modal-action-group {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--rc-space-2);

  .rc-modal-confirmation & {
    justify-content: center;
  }
}

.rc-modal-action-group__action {
  display: flex;

  button, a {
    width: 100%;
  }

  &:only-of-type {
    button, a {
      width: auto;
    }
  }

  &:not(:only-of-type) {
    .rc-modal-confirmation & {
      min-width: 33%;
    }
    @include tablet {
      flex: 1;

      .rc-notice & {
        flex: unset;
      }

      .rc-modal-confirmation & {
        min-width: unset;
        flex: 1 0 33%;
      }
    }

    @include mobile {
      .rc-notice & {
        flex: 1;
      }
    }
  }

  @include mobile {
    flex: 1;

    .rc-notice & {
      flex: unset;
    }
  }

  &.rc-modal-action-group__action--full-width {
    flex: 1;

    button, a {
      width: 100%;
    }
  }
}
