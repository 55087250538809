@use '@reverbdotcom/cadence/styles/tools/mixins/loading-pulse' as loading;

.seller-hub {
  display: flex;
  flex-direction: column;
  padding-top: var(--rc-space-15);
  padding-bottom: var(--rc-space-15);

  @include mobile {
    padding-top: var(--rc-space-6);
    padding-bottom: var(--rc-space-6);
  }

  .rc-grid {
    gap: var(--rc-space-20) var(--rc-space-12);

    @include mobile {
      gap: var(--rc-space-20) var(--rc-space-0);
    }
  }

  .seller-hub__shop-stats {
    .rc-grid {
      gap: var(--rc-space-6);
    }

    @include mobile {
      margin-top: -5rem;
    }
  }

  .rc-skeleton, .rc-chip--loading,
  .seller-hub__shop-stat--loading {
    @include loading.loading-pulse;
    pointer-events: none;
  }

  .seller-hub__recent-module--loading,
  .seller-hub__recommendation-module--loading {
    .rc-button.rc-button--muted {
      @include loading.loading-pulse;
    }
  }

  .shop-refund-stats {
    width: 100%;
  }
}

.seller-hub__avatar {
  width: 12.4rem;
  min-width: 12.4rem;
  margin-right: var(--rc-space-6);

  @include mobile {
    width: 6rem;
    min-width: 6rem;
    margin-right: var(--rc-space-3);
  }
}

.seller-hub__greeting {
  font-weight: bold;
  font-size: var(--rc-font-size-1200);
  line-height: var(--rc-font-line-height-title);
  letter-spacing: var(--rc-font-letter-spacing-heading);

  @include mobile {
    font-size: var(--rc-font-size-900);
    margin-top: 0;
    width: 21rem;
    display: flex;
    flex-wrap: wrap;
  }
}

.seller-hub__profile-links {
  display: flex;
  align-items: flex-start;
  margin-top: var(--rc-space-6);

  @include mobile {
    margin-left: 0;
    margin-top: 0;
  }
}

.seller-hub__earnings {
  font-size: var(--rc-font-size-600);
  margin-right: var(--rc-space-4);

  @include mobile {
    margin-bottom: var(--rc-space-3);
  }
}

.seller-hub__earnings-amount {
  color: var(--rc-color-text-accent);
  font-weight: var(--rc-font-weight-bold);
  border-bottom: dashed 0.1rem currentColor;
}

.seller-hub__earnings--accommodations {
  font-size: var(--rc-font-size-400);
  margin-right: var(--rc-space-1);
}

.seller-hub__shop-stats--title {
  font-size: var(--rc-font-size-600);
  font-weight: var(--rc-font-weight-bold);
  margin-bottom: var(--rc-space-1);
}

.seller-hub__shop-stats {
  width: 100%;
  margin-top: -3.2rem;

  .ct-chart-bar {
    min-height: 26rem;

    @include tablet {
      min-height: 25rem;
    }
  }
}

.seller-hub__shop-stats__time-period__select-option {
  cursor: pointer;
  padding: var(--rc-space-2);

  &:hover {
    background: var(--rc-color-background-card);
  }
}

.seller-hub__shop-stats__time-period__select-option--selected {
  background: var(--rc-color-background-card);
}

.seller-hub__shop-stats__stats-and-chart {
  display: flex;
  width: 100%;
  gap: var(--rc-space-6);

  @include tablet {
    flex-wrap: wrap;
  }

  @include mobile {
    gap: var(--rc-space-2)
  }
}

.seller-hub__shop-stats__stats-container {
  @include tablet {
    width: 100%;
  }
}

.seller-hub__shop-stats__sales-chart {
  width: 100%;

  .rc-skeleton {
    max-height: 39rem;
  }
}

.seller-hub__shop-stats__sales-chart--loaded {
  padding: 1.6rem;
  border-radius: var(--rc-border-radius-md);
  background: var(--rc-color-background-card);
}

.seller-hub__shop-stats__sections {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--rc-space-0);

  &.seller-hub__shop-stats__sections--full-length {
    grid-template-columns: repeat(2, 1fr);

    @include tablet {
      grid-template-columns: repeat(1, 1fr);
    }

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.seller-hub__shop-stats__comparisons {
  margin-right: 0;
  margin-bottom: var(--rc-space-6);

  &.seller-hub__shop-stats__comparisons--full-length {
    margin-right: var(--rc-space-6);

    @include tablet {
      margin-right: 0;
    }

    @include mobile {
      margin-right: 0;
    }
  }
}

.seller-hub__shop_stats__stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--rc-space-6);

  @include tablet {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  @include mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--rc-space-4);
  }

  &.seller-hub__shop_stats__stats-grid--full-length {
    grid-template-columns: repeat(4, 1fr);

    @include mobile {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.seller-hub__shop-stat {
  height: 8.4rem;
  width: 9.2rem;
  padding: 1.6rem 1.2rem;
  border-radius: var(--rc-border-radius-md);
  background: var(--rc-color-background-card);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include tablet {
    flex: 1;
  }

  @include mobile {
    width: auto;
    height: 4.8rem;
    padding: 1.2rem;
  }
}

.seller-hub__shop-stat--col-two {
  grid-column: span 2;
  width: auto;

  @include tablet {
    width: 23rem;
  }

  @include mobile {
    width: auto;
  }

  .rc-star-rating {
    .rc-icon {
      width: 3.2rem;

      @include mobile {
        width: 1.6rem;
      }
    }
  }
}

.seller-hub__shop-stats__feedback-and-badges {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--rc-space-6);

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile {
    grid-template-columns: repeat(1, 1fr);
  }
}

.seller-hub__shop-stat--col-two-optimized {
  grid-column: span 1;
  width: auto;

  .rc-star-rating {
    .rc-icon {
      width: 3.2rem;

      @include mobile {
        width: 1.6rem;
      }
    }
  }
}

.seller-hub__shop-stat__current-stat {
  font-size: var(--rc-font-size-600);
  font-weight: var(--rc-font-weight-bold);

  @include mobile {
    font-size: var(--rc-font-size-550);
  }
}

.seller-hub__shop-stat__change-stat {
  font-size: var(--rc-font-size-350);
  font-weight: var(--rc-font-weight-semibold);
  color: var(--rc-color-text-secondary);
  align-self: flex-end;
}

.seller-hub__shop-stat__change-stat--positive {
  color: var(--rc-color-sentiment-positive-dark);
}

.seller-hub__shop-stat__change-stat--feedback {
  align-self: flex-start;
}

.seller-hub__shop-badges {
  .shared-shop-badge {
    margin-right: var(--rc-space-5);
    margin-left: 0;
  }
}

.seller-hub__shop-badges__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--rc-space-3);
  height: inherit;

  @include tablet {
    gap: var(--rc-space-6);
  }
}

.seller-hub__shop-badge {
  align-self: center;
  box-sizing: border-box;
  max-width: 7rem;
  padding: var(--rc-space-2) var(--rc-space-3) 0;

  .rc-icon {
    width: 3.8rem;
  }

  .percentage-bar {
    margin: var(--rc-space-2) 0;
    opacity: 0;
  }

  &.seller-hub__shop-badge--incomplete {
    color: var(--rc-color-palette-gray-600);

    .percentage-bar {
      opacity: 1;
    }
  }
}

.seller-hub__shop-badge__title {
  font-size: 1.8rem;
  font-weight: bold;
}

.seller-hub__shop-badge__requirement {
  padding: 0.5rem 0;
  list-style-type: none;
  font-size: 1.4rem;
}

.seller-hub__shop-badge__requirement--complete {
  color: var(--rc-color-text-success);
  font-weight: bold;
}

.seller-hub__messages__unread {
  font-size: var(--rc-font-size-300);
  font-weight: var(--rc-font-weight-bold);
  color: var(--rc-color-text-accent);
  text-transform: uppercase;
}

.seller-hub__row-title {
  font-size: var(--rc-font-size-900);
  font-weight: var(--rc-font-weight-bold);
  margin-bottom: -10rem;
}

.seller-hub__recent-module, .seller-hub__recommendation-module {
  width: 100%;
}

.seller-hub__recent-module {
  .seller-hub__recent-module__recent-items {
    display: flex;
    flex-direction: column;
    gap: var(--rc-space-12);

    @include mobile {
      gap: var(--rc-space-8);
    }
  }

  .seller-hub__recent-module__title {
    font-size: var(--rc-font-size-800);
    font-weight: var(--rc-font-weight-bold);
    margin: var(--rc-space-4) 0;

    @include mobile {
      align-self: start;
    }

    &.seller-hub__recent-module__title--small {
      font-size: var(--rc-font-size-500);
    }
  }
}

.seller-hub__recent-offers__expires {
  font-size: var(--rc-font-size-300);
  font-weight: var(--rc-font-weight-bold);
  color: var(--rc-color-text-secondary);
}

.seller-hub__recent-offers__expires--warning {
  color: var(--rc-color-text-accent);
}

.seller-hub__recommendation-module {
  .seller-hub__recommendation-module__header-icon {
    margin-right: var(--rc-space-4);
    margin-top: 0.2rem;
    width: 4rem;

    @include mobile {
      min-width: 3.2rem;
    }

    .rc-icon {
      color: var(--rc-color-text-accent);
    }
  }

  .seller-hub__recommendation-module__subtitle {
    font-size: var(--rc-font-size-500);
    margin-bottom: var(--rc-space-3);

    @include mobile {
      font-size: var(--rc-font-size-400);
    }
  }

  .seller-hub__recommendation-module__recommendation-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--rc-space-8);

    @include tablet {
      grid-template-columns: 1fr;
    }
  }
}

.seller-hub__recommendation-item__item-price {
  font-size: var(--rc-font-size-500);
  font-weight: var(--rc-font-weight-bold);
  margin-right: var(--rc-space-5);
}

.seller-hub__recommendation-item__price-drop-recommendation {
  display: flex;
  font-weight: var(--rc-font-weight-bold);
  align-items: center;
}

.seller-hub__recommendation-item__price-drop-recommendation--success {
  color: var(--rc-color-text-alert-success);

  .rc-icon {
    margin-right: var(--rc-space-1);
  }
}

.seller-hub__draft-listings {
  .site-wrapper {
    padding: 0;
  }

  .overflowing-row {
    margin-bottom: 0;

    h2.overflowing-row__heading {
      small.d-block {
        font-size: var(--rc-font-size-550);
        font-weight: var(--rc-font-weight-normal);
        margin-top: var(--rc-space-1);
      }
    }
  }

  @include mobile {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem;
  }
}

.seller-hub__new-seller-prompt {
  width: 100%;

  .seller-hub__new-seller-prompt__title {
    font-size: var(--rc-font-size-900);
    font-weight: var(--rc-font-weight-bold);
    margin-bottom: var(--rc-space-8);

    @include mobile {
      font-size: var(--rc-font-size-600);
      margin-bottom: var(--rc-space-6);
    }
  }

  .seller-hub__new-seller-prompt__tiles {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--rc-space-10);

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .seller-hub__new-seller-prompt__tile__number {
    width: 4.2rem;
    height: 4.2rem;
    color: var(--rc-color-palette-bw-white);
    background-color: var(--rc-color-palette-vu-orange-600);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.2rem;
    font-size: var(--rc-font-size-600);
    font-weight: var(--rc-font-weight-bold);
    border-radius: var(--rc-border-radius-full);
  }

  .seller-hub__new-seller-prompt__tile--loading {
    .seller-hub__new-seller-prompt__tile__number {
      background-color: unset;
    }
  }

  .seller-hub__new-seller-prompt__tile__title {
    font-size: var(--rc-font-size-700);
    font-weight: var(--rc-font-weight-bold);
  }

  .seller-hub__new-seller-prompt__tile__description {
    font-size: var(--rc-font-size-450);
    font-weight: var(--rc-font-weight-medium);

    a {
      color: var(--rc-color-text-primary);
      text-decoration: underline;

      &:hover {
        color: var(--rc-color-text-link-hovered);
      }
    }
  }
}
