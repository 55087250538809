@use 'sass:math';
@use '@reverbdotcom/cadence/styles/tools/functions/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';
@use '@reverbdotcom/cadence/styles/tools/mixins/links';
@use '@reverbdotcom/cadence/styles/tools/mixins/pointer';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

$adjusted-gutter-amount: math.div($grid-gutter, 100%) * 80;

@mixin overflowing-row-gutter($attr, $multiplier) {
  @include max-vw($attr, $adjusted-gutter-amount * $multiplier);
  #{$attr}: $adjusted-gutter-amount * 1vw * $multiplier;
}

.overflowing-row {
  @include site-wrapper;
  @include gap-row;

  position: relative;
  z-index: 1;

  // needs to be deprecated, may not be currently used
  &.overflowing-row--condensed {
    @include scaling((margin-top margin-bottom), 2rem);
  }

  &.overflowing-row--narrow {
    box-sizing: content-box;
    max-width: $site-width-cms;
  }

  &.overflowing-row--landing {
    @include mobile {
      margin-bottom: var(--rc-space-10);
    }
  }

  .faceted-grid--wide & {
    width: 100%;
    padding: 0;
  }

  @include with-sidebar {
    width: 100%;
    padding: 0;
  }

  .rc-site-wrapper &,
  .site-wrapper &,
  .content-frame & {
    width: 100%;
    padding: 0;
  }

  .indented-row-collection & {
    margin-bottom: 0;
  }

  .site-search & {
    margin: 0 0 1.5rem;

    .overflowing-row__items {
      padding: 0;
      margin: 0;

      .overflowing-row__items__inner {
        box-sizing: border-box;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-left: 0;
      }
    }
  }

  // this should just be bypassed in the sidebar;
  // zeroing out the spacing allows components to render
  // OverflowingRows in the sidebar without it having weird side padding.
  // there's no expectation for OR's in the sidebar to scroll.
  .dynamic-page__sidebar & {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .item2__content__section--buying-guide & {
    margin: 0;
    padding: 0;
  }

  .blog-post__frame & {
    @include min-width($site-width-mid) {
      margin-left: -10%;
      margin-right: -10%;

      &.overflowing-row--narrow {
        margin-left: 0;
        margin-right: 0;
      }
    }

    @include tablet {
      width: 100%;
      padding: 0;
    }
  }

  .item2-product-module__b-stock-listings &, .item2__content__section__listings-row & {
    max-width: 100%;
    margin: 0;
    padding: 2rem 0;
  }
}

.overflowing-row__inner {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  .overflowing-row--landing & {
    @include mobile {
      flex-direction: column;
    }
  }
}

.overflowing-row__lede {
  flex: 0 0 100%;
  font-size: 0.9em;
  z-index: 2; // sits above __items

  .overflowing-row--landing & {
    @include mobile {
      order: 2;
      margin-bottom: -1.5rem;
      text-align: center;
    }
  }
}

.overflowing-row__heading {
  @include rc-text.title('700');
  margin-bottom: 1.6rem;
  flex: 1;
  align-self: center;
  z-index: 2; // sits above __items

  a {
    color: inherit;
  }

  .overflowing-row--condensed & {
    @include responsive(font-size, 1.8rem, 1.7rem, 1.6rem);
  }

  .overflowing-row--centered & {
    margin-bottom: 1rem;
    text-align: center;
    flex-basis: 100%;
  }

  .overflowing-row--landing & {
    @include mobile {
      order: 1;
      text-align: center;
    }
  }

  // override blog post h2 defaults
  .blog-post__frame & {
    margin: 0 0 1rem 0;
    font-weight: normal;
    font-style: normal;
    font-family: inherit;
  }

  &:empty {
    margin-bottom: 0;
  }
}

.overflowing-row__action {
  margin-bottom: 0.8rem;
  flex: 0 0 auto;
  text-align: right;
  font-weight: bold;
  align-self: center;
  z-index: 2; // sits above __items

  @include tablet {
    padding-left: 0.5rem
  }

  .overflowing-row--landing & {
    @include mobile {
      order: 5;
    }
  }

  .favorites-content & {
    align-self: unset;
  }

  a {
    .overflowing-row--landing & {
      @include mobile {
        @include rc-button.outlined;
        width: 100%;
      }
    }
  }

  // append-action passes in a button
  // this isn't a great override but removing this
  // would require adding some subclass under the control
  // of OverflowingRow rather than it blindly rendering
  // an element from a prop
  .overflowing-row--append-action &,
  .overflowing-row--hide-action-arrow & {
    a {
      &::after {
        display: none;
      }
    }
  }

  .overflowing-row--append-action &,
  .overflowing-row--centered & {
    @include scaling(margin-top, 1.5rem);
    flex-basis: 100%;
    order: 5;
    text-align: center;
    margin-bottom: 0;
  }

  .overflowing-row--landing & {
    @include mobile {
      align-self: center;
      width: 100%;
    }
  }
}

.overflowing-row__items {
  @include full-bleed;
  position: relative;
  display: flex;
  width: 100%;
  z-index: 1;

  .item2-product-module__b-stock-listings &, .item2__content__section__listings-row & {
    --fullbleed-offset-left: 0;
    --fullbleed-offset-right: 0;
  }

  @include min-width($site-width-narrow) {
    @include with-sidebar {
      --fullbleed-offset-left: var(--rc-space-gap-grid);
    }
  }

  .overflowing-row--landing & {
    @include mobile {
      order: 4;
    }
  }

  .item2__content__section--buying-guide & {
    padding-top: calc(2 * var(--rc-space-gap-grid));
  }

  .csp-layout__buying-guide & {
    padding-top: 0;
    padding-bottom: 0;
  }

  // Overflow Gradients
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 1;
    transition: opacity 0.2s ease;
    pointer-events: none;

    @include max-width(1800px) { // $rc-size-max-width-content
      opacity: 0;
    }
  }

  &::before {
    left: 0;
    z-index: 2;
    width: var(--fullbleed-offset-left);
    background-image: linear-gradient(to left, var(--rc-color-transparent), var(--rc-color-background-page));

    .overflowing-row--disable-left-control & {
      opacity: 0;
    }
  }

  &::after {
    right: 0;
    z-index: 1;
    width: var(--fullbleed-offset-right);
    background-image: linear-gradient(to right, var(--rc-color-transparent), var(--rc-color-background-page));

    .overflowing-row--disable-right-control & {
      opacity: 0;
    }
  }
}

.overflowing-row__items__inner {
  @include full-bleed;
  @include smooth-scroll;
  overflow-x: auto;
  overflow-y: hidden;
  flex-basis: 100%;
  width: auto;
  margin-top: calc(-1.5 * var(--rc-space-gap-grid));
  padding-top: calc(1.5 * var(--rc-space-gap-grid));
  margin-bottom: calc(-1.5 * var(--rc-space-gap-grid));
  padding-bottom: calc(1.5 * var(--rc-space-gap-grid));
  position: relative;
  z-index: 1;

  // This is how we're hiding scroll bars on overflowing rows.
  // NOTE: This should not be copied as an approved pattern as it has negative UX implications
  // https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width
  scrollbar-width: none; // Standard way
  -ms-overflow-style: none; // IE and Edge override
  &::-webkit-scrollbar { display: none } // Safari and Chrome

  .item2-product-module__b-stock-listings &, .item2__content__section__listings-row & {
    margin-right: 0;
    padding-right: 0;
    margin-left: 0;
    padding-left: 0;
  }

  @include min-width($site-width-narrow) {
    @include with-sidebar {
      --fullbleed-offset-left: var(--rc-space-gap-grid);
    }
  }
}

.overflowing-row__control {
  @include box-shadow;

  position: absolute;
  top: 50%;
  z-index: 2;
  cursor: pointer;
  width: 4.8rem;
  height: 4.8rem;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--rc-color-background-semitransparent-inverted);
  border-radius: var(--rc-border-radius-button);
  transition: box-shadow 0.2s ease, background-color 0.2s ease, transform 0.2s ease, opacity 0.2s ease;

  &:hover {
    background-color: var(--rc-color-text-primary);
  }

  svg {
    width: 3.6rem;
    height: 3.6rem;
    fill: var(--rc-color-text-primary-inverted);
  }

  @include pointer.touch-input {
    display: none;
  }

  &.overflowing-row__control--left {
    left: calc(var(--rc-space-gutter-page) / 2);
    transform: translateY(-50%) scale(1);

    &:hover {
      transform: translateY(-50%) scale(1.167);
    }

    .overflowing-row--disable-left-control & {
      opacity: 0;
      cursor: default;
    }

    .overflowing-row--hide-controls & {
      opacity: 0;
      pointer-events: none;
      cursor: default;
    }
  }

  &.overflowing-row__control--right {
    right: calc(var(--rc-space-gutter-page) / 2);
    transform: translateY(-50%) scale(1);

    &:hover {
      transform: translateY(-50%) scale(1.167);
    }

    svg {
      transform: translateX(var(--rc-space-half));
    }

    .overflowing-row--disable-right-control & {
      opacity: 0;
      cursor: default;
    }

    .overflowing-row--hide-controls & {
      opacity: 0;
      pointer-events: none;
      cursor: default;
    }
  }

  .overflowing-row--condensed & {
    width: 3.2rem;
    height: 3.2rem;
  }

  .overflowing-row--landing & {
    @include mobile {
      display: none;
    }
  }
}
