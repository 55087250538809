.product-family-header {
  padding: 3rem 0;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
}

.product-family-header__title {
  @include responsive(font-size, 2em, 1.6em, 1.2em);

  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 0.3em;
}

.product-family-header__breadcrumbs {
  font-size: 0.9em;
  margin-bottom: 0.5em;

  a {
    color: var(--rc-color-text-primary);

    @include responsive-hover {
      color: var(--rc-color-palette-blue-600);
    }
  }

  @include mobile {
    font-size: 0.8em;
  }
}

.product-family-header__breadcrumb {
  display: inline-block;

  + .product-family-header__breadcrumb {
    &::before {
      @extend %fa-icon-base;

      content: fa-icon-content(angle-right);
      opacity: 0.5;
      margin: 0 0.6em;
    }
  }
}
