@use 'sass:math';

.subway-navigation {
  position: sticky;
  top: 4rem;
  padding-bottom: 25rem;
}

.subway-navigation--menu {
  padding-bottom: 0;
}

$circle-border-thickness: 0.3rem;
$disabled-circle-buffer: 0.5rem;

// "standard" is for every circle except for the current step's circle
$standard-radius: 0.8rem;
$current-step-radius: 1.2rem;

// used when the "condensed" prop is set to true (primarily in the Dropdown Menu version)
$condensed-standard-radius: 0.7rem;
$condensed-current-step-radius: 1rem;

// color variables
$primary-color: var(--rc-color-text-primary);
$disabled-line-color: var(--rc-color-palette-gray-500);

@mixin subway-navigation-lines($height, $small-radius, $large-radius) {
  margin-bottom: $height;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  // basic strategy: draw a line and below each circle that is between 50% and 100% of the height necessary
  // to connect each step. The lines will overlap and look like a single solid line

  // filled in line styles
  &::before,
  &::after {
    content: '';
    height: calc(25% + (0.55 * $height)); // this is a completely arbitrary calculation to make the lines overlap without going too far in the disabled state
    width: 0.3rem;
    position: absolute;
    z-index: 0;
    background-color: $primary-color;
    left: $large-radius + math.div($circle-border-thickness, 2);
  }

  // "disabled" line styles
  &.subway-navigation__item--next-step-disabled::before,
  &.subway-navigation__item---disabled::after {
    height: 0.6 * $height;
    border-radius: 0.2rem;
    background-color: $disabled-line-color;
  }

  // --- line below ---
  &::before {
    top: calc(50% + $small-radius + $circle-border-thickness);
  }

  &.subway-navigation__item--next-step-disabled::before {
    // We want a 0.5rem gutter between the edge of the border circle and a "disabled" line
    top: calc(50% + $small-radius + $circle-border-thickness + $disabled-circle-buffer);
  }

  &.subway-navigation__item--current.subway-navigation__item--next-step-disabled::before {
    // The start of the absolutely positioned line needs to be farther down for the larger circle to keep the same size gutter
    top: calc(50% + $large-radius + $circle-border-thickness + $disabled-circle-buffer);
  }

  // don't draw a line below the last step
  &:last-child::before {
    content: none;
  }

  // --- line above ---
  &::after {
    bottom: calc(50% + $small-radius + $circle-border-thickness);
  }

  &.subway-navigation__item---disabled::after {
    // We want a 0.5rem gutter between the edge of the border circle and a "disabled" line
    bottom: calc(50% + $small-radius + $circle-border-thickness + $disabled-circle-buffer);
  }

  // don't draw a line above the first step
  &:first-child::after {
    content: none;
  }
}

// Each li in the ol.subway-navigation
.subway-navigation__item {
  @include subway-navigation-lines(7rem, $standard-radius, $current-step-radius);

  .subway-navigation--condensed & {
    @include subway-navigation-lines(3rem, $condensed-standard-radius, $condensed-current-step-radius);
  }

  // for the menu variant, each li wraps the entire "step" in a link
  .subway-navigation--menu & {
    a {
      color: $primary-color;
      line-height: 1;
      display: block;

      &:hover {
        .subway-navigation__step__label__title {
          text-decoration: underline;
        }
      }
    }
  }
}

// styling for the circle and label text, see the `SubwayNavigationStep` story for the standalone styling
.subway-navigation__step {
  display: flex;
  align-items: center;
}

.subway-navigation__step__label {
  display: flex;
  margin-left: 1rem;
  flex-direction: column;
  font-size: 1.6rem;
}

.subway-navigation__step__label__title {
  line-height: 1;

  button {
    @include button-as-link;
    text-align: left;
    color: $primary-color;

    @include responsive-hover {
      color: $primary-color;
      fill: $primary-color;
      text-decoration: underline;
    }
  }

  a {
    color: $primary-color;

    &:hover {
      text-decoration: underline;
    }
  }

  .subway-navigation__step--current & {
    font-weight: bold;
  }

  .subway-navigation__step--disabled & {
    color: var(--rc-color-text-primary-disabled);
  }
}

.subway-navigation__step__label__errors {
  color: var(--rc-color-text-danger);
}

.subway-navigation__step__circle__label {
  @include visually-hidden;
}

.subway-navigation__step__circle {
  display: inline-flex;
  width: $standard-radius * 2;
  height: $standard-radius * 2;
  margin: 0 $current-step-radius - $standard-radius;
  color: $primary-color;
  background: var(--rc-color-background-page);
  border: $circle-border-thickness solid $primary-color;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex-shrink: 0;

  .rc-icon {
    width: 1.2rem;
  }

  .subway-navigation__step--current & {
    width: $current-step-radius * 2;
    height: $current-step-radius * 2;
    margin: 0;

    .rc-icon {
      width: 1.4rem;
    }
  }

  .subway-navigation__step--condensed & {
    width: $condensed-standard-radius * 2;
    height: $condensed-standard-radius * 2;
    margin: 0 $condensed-current-step-radius - $condensed-standard-radius;

    .rc-icon {
      width: 1rem;
    }
  }

  .subway-navigation__step--condensed.subway-navigation__step--current & {
    width: $condensed-current-step-radius * 2;
    height: $condensed-current-step-radius * 2;
    margin: 0;

    .rc-icon {
      width: 1.2rem;
    }
  }

  .subway-navigation__step--disabled & {
    color: $disabled-line-color;
    border-color: $disabled-line-color;
  }

  .subway-navigation__step--completed & {
    background: $primary-color;
    color: var(--rc-color-text-primary-inverted);
  }

  .subway-navigation__step--disabled.subway-navigation__step--completed & {
    background: $disabled-line-color;
  }

  .subway-navigation__step--completed.subway-navigation__step--current & {
    color: $primary-color;
    background: var(--rc-color-background-page);
  }

  .subway-navigation__step--error & {
    color: var(--rc-color-text-danger);
    border-color: var(--rc-color-border-input-error);
  }
}

// SubwayNavigationDropdown classes
.subway-navigation-dropdown {
  position: relative;
}

.subway-navigation-dropdown__trigger {
  @include button-reset;

  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  font-weight: bold;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: $input-border-radius;

  &:hover {
    background-color: var(--rc-color-button-main-secondary);
  }
}

.subway-navigation-dropdown__trigger__step-count {
  margin-bottom: 1rem;
  font-size: 1.6rem;
  color: var(--rc-color-text-secondary);
}

.subway-navigation-dropdown__trigger__step-title {
  @include responsive(font-size, 2.5rem, 2.2rem, 2rem);
  color: var(--rc-color-text-primary);
}

.subway-navigation-dropdown__dropdown-container {
  position: absolute;
  top: 3rem;
  left: 0;
  right: 0;
  width: auto;
  max-width: 30rem;
  padding: 2rem 1.5rem;
  z-index: 1000;
  border-radius: $input-border-radius;
  background-color: var(--rc-color-background-page);
  color: var(--rc-color-text-primary);
  border: solid 0.1rem var(--rc-color-border-primary);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
  font-weight: 400;

  // safari styling override for weird behavior
  // stylelint-disable-next-line selector-pseudo-class-disallowed-list
  &:focus {
    outline: none;
  }
}
