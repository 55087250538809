.sell-form-canonical-product-card {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.sell-form-canonical-product-card__label {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.sell-form-canonical-product-card__image {
  display: block;
  width: 14rem;
  margin-right: 2rem;
  flex-shrink: 0;
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: $input-border-radius;

  @include mobile {
    width: 6rem;
  }
}

.sell-form-canonical-product-card__make-model {
  @include responsive(font-size, 2rem, 2rem, 1.8rem);
  margin-bottom: auto;
  flex: 1;
}

.sell-form-canonical-product-card__model {
  font-weight: bold;
}

.sell-form-canonical-product-card__attributes {
  display: grid;
  margin-top: 2rem;
  grid-template-columns: auto 1fr;
  grid-column-gap: 2rem;
  font-size: 1.4rem;
}

.sell-form-canonical-product-card__attribute-label {
  font-weight: bold;
}

.sell-form-canonical-product-card__attribute-label__select-model {
  font-style: italic;
  color: var(--rc-color-text-secondary);
}

.sell-form-canonical-product-card__info {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  flex-grow: 1;
}

.sell-form-canonical-product-card__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
