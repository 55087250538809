@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.dashboard-page-header {
  border-bottom: solid 0.1rem var(--rc-color-border-primary);
  padding-top: 2rem;
  padding-bottom: 4rem;
  margin-bottom: 4rem;

  &.dashboard-page-header--no-top-padding {
    padding-top: 0;
  }

  &.dashboard-page-header--no-border {
    border-bottom: none;
    margin-bottom: 0;
  }
}

.dashboard-page-header__breadcrumbs {
  margin-bottom: 1.5rem;

  ol {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.dashboard-page-header__breadcrumb {
  display: flex;
  align-items: center;
  color: var(--rc-color-text-secondary);
  margin-bottom: 0.5rem;
  margin-right: 1rem;

  &::after {
    content: '/';
    display: block;
    margin-left: 1rem;
  }

  &:last-child::after {
    display: none;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: var(--rc-color-text-primary);
    }
  }
}

.dashboard-page-header__main {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    display: block;
  }
}


.dashboard-page-header__titleblock {
  width: 100%;
  margin-right: 2rem;

  @include mobile {
    margin-right: 0;
  }
}

.dashboard-page-header__title {
  @include rc-text.title('700');

  .dashboard-page-header--title-large & {
    @include rc-text.title('900');

    @include mobile {
      font-size: var(--rc-font-size-700);
    }
  }
}

.dashboard-page-header__subtitle {
  color: var(--rc-color-text-secondary);
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.dashboard-page-header__actions {
  display: flex;
  align-items: center;

  @include mobile {
    display: block;
    margin-top: 1rem;
  }
}

.dashboard-page-header__action-wrapper {
  margin-left: 2rem;

  @include mobile {
    margin-left: 0;
    margin-top: 2rem;
    width: 100%;
  }
}
