.rc-switch {
  display: flex;
  align-items: flex-start;
  position: relative;

  // disabled state for label
  &.rc-switch--disabled {
    color: var(--rc-color-text-primary-disabled);
    cursor: not-allowed;
  }
}

.rc-switch__input {
  opacity: 0;
  height: var(--rc-size-height-switch);
  width: var(--rc-size-width-switch);
  position: absolute;
  z-index: 2; // on top, but invisible to accept clicks
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.rc-switch__box {
  --handle-size: calc(var(--rc-size-height-switch) - 2 * var(--rc-border-width-switch));
  box-sizing: border-box;
  display: block;
  position: relative;
  width: var(--rc-size-width-switch);
  height: var(--rc-size-height-switch);
  border-radius: var(--rc-border-radius-full);
  background-color: var(--rc-color-background-switch-track);
  border-width: var(--rc-border-width-switch);
  border-style: solid;
  border-color: var(--rc-color-border-input-primary);
  z-index: 1;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 0;
  transition-property: background-color, border-color;
  transition-timing-function: cubic-bezier(.4, 0.0, 0.2, 1); // Material's FastOutSlowIn
  transition-duration: 0.15s;

  // the switch "handle"
  &::before {
    content: '';
    box-sizing: border-box;
    display: block;
    background-color: var(--rc-color-border-input-primary);
    width: var(--handle-size);
    height: var(--handle-size);
    border-radius: var(--rc-border-radius-full);
    position: absolute;
    left: 0;
    margin-left: 0;
    transform: scale(0.667);
    transition-property: transform, background-color, margin-left;
    transition-timing-function: cubic-bezier(.4, 0.0, 0.2, 1); // Material's FastOutSlowIn
    transition-duration: 0.15s;
  }

  // the "check" icon
  .rc-icon {
    --icon-size: calc(var(--handle-size) / 2);
    position: absolute;
    display: block;
    width: calc(var(--handle-size) / 2);
    height: calc(var(--handle-size) / 2);
    color: var(--rc-color-background-switch-handle);
    top: 50%;
    opacity: 0;
    transform: translate(calc(var(--icon-size) / 2), -50%);
    transition: opacity 0.15s ease-in-out;
  }
}

//Checked state styling
.rc-switch__input:checked + .rc-switch__box {
  background-color: var(--rc-color-background-switch-track-selected);
  border-color: var(--rc-color-background-switch-track-selected);
  transition-delay: 0s;

  // move the "handle" UI
  &::before {
    background-color: var(--rc-color-background-switch-handle);
    transform: scale(1);
    margin-left: calc(100% - var(--handle-size));
  }

  // move the "check" icon
  .rc-icon {
    opacity: 1;
  }
}

// Focus-visible state styling
.rc-switch__input:focus-visible + .rc-switch__box {
  @include focus-outline;
  outline-offset: -0.1rem; // border thickness
}

// Disabled state styling
.rc-switch__input:disabled {
  cursor: not-allowed;

  + .rc-switch__box {
    opacity: 0.5;
  }
}
