@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.item2-shop-module {
  display: flex;
  flex-wrap: wrap;
}

.item2-shop-module__header {
  display: flex;
  flex: 2 1 auto;
}

.item2-shop-module__image-link {
  margin-right: 2rem;

  @include tablet {
    margin-right: 1.5rem;
  }
}

.item2-shop-module__image {
  width: 10rem;
  height: 10rem;
  background-color: var(--rc-color-background-module-secondary);
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: 50%;
  overflow: hidden;

  @include tablet {
    width: 7.5rem;
    height: 7.5rem;
  }

  img {
    width: 100%;
  }

  &.item2-shop-module__image--placeholder {
    img {
      padding: 1.5rem;
      box-sizing: border-box;
    }
  }
}

.item2-shop-module__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-right: 2rem;

  @include mobile {
    margin-right: 0;
  }
}

.item2-shop-module__title {
  @include group-link;
  @include rc-text.utility($size: '600', $weight: 'medium');

  @include mobile {
    font-size: var(--rc-font-size-500);
    font-weight: var(--rc-font-weight-semibold);
  }
}

.item2-shop-module__location {
  color: var(--rc-color-text-secondary);
  line-height: 1.2;
}

.item2-shop-module__stats {
  font-size: 1.4rem;
  margin: 1rem 2rem 1rem 0;
}

.item2-shop-module__actions {
  display: flex;
  margin: 1rem -0.5rem 0;
  flex: 1;
  flex-wrap: wrap;

  @include mobile {
    margin-bottom: 0;
  }
}

.item2-shop-module__action {
  margin: 0.5rem;
  flex: 1;

  @include mobile {
    flex-basis: 100%;
  }
}

.item2-shop-module__action__button {
  @include rc-button.muted;
  @include rc-button.size('small');
  width: 100%;
}

.item2-shop-module__policies {
  flex: 0 0 calc(100% + 2 * var(--rc-space-x-accordion));
  margin: var(--rc-space-3) calc(-1 * var(--rc-space-x-accordion)) 0;
  padding-top: var(--rc-space-3);
  border-top: 0.1rem solid var(--rc-color-border-primary);
}

.item2-shop-module__policies__button {
  @include button-reset;
  @include group-link;

  border: none;
  width: 100%;
  font-size: 1.7rem;
  font-weight: bold;
  text-align: left;
  padding: 1rem 0;
  margin: -1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item2-shop-module__reviews {
  flex: 0 0 calc(100% + 2 * var(--rc-space-x-accordion));
  margin: var(--rc-space-3) calc(-1 * var(--rc-space-x-accordion)) 0;
  padding-top: var(--rc-space-3);
  border-top: 0.1rem solid var(--rc-color-border-primary);
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 1.6rem;

  &:empty {
    display: none;
  }
}
