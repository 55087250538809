.feed-discover {
  .rc-card {
    width: 100%;
  }

  .feed-discover__header {
    margin: 1rem 0;
  }

  .feed-discover__article-thumbnail {
    background-color: var(--rc-color-background-box-teal-subtle);
  }

  .feed-discover__entries {
    margin-top: 1rem;
  }

  .amp-card {
    border: none;
  }
}
