.sell-form-localized-content {
  width: 100%;
}

.sell-form-localized-content__checkbox {
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: $input-border-radius;
  background: var(--rc-color-background-page);
  padding: 2rem;
  margin-bottom: 1.5rem;

  @include mobile {
    margin-bottom: 2rem;
  }
}

.sell-form-localized-content__form {
  background: var(--rc-color-background-module-secondary);
  padding: 2rem;
  margin: -1rem 0 1.5rem;
}
