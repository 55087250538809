@use '../tools/mixins/truncate-text';

.rc-eyebrow {
  --font-size: 1.2rem;
  --line-height: calc(1.6 / 1.2);

  font-size: var(--font-size);
  line-height: var(--line-height);
  text-transform: uppercase;
  font-weight: var(--rc-font-weight-bold);
  letter-spacing: 0.06em;
  display: flex;
  align-items: center;
  color: var(--rc-color-text-primary);

  &.rc-eyebrow--inverted {
    color: var(--rc-color-text-primary-inverted);
  }

  &.rc-eyebrow--highlight {
    color: var(--rc-color-palette-vu-orange-500);
  }

  &.rc-eyebrow--alert {
    color: var(--rc-color-text-discount);
  }

  &.rc-eyebrow--truncate {
    @include truncate-text.truncate-text(
      $lines-to-show: 1,
      $font-size: var(--font-size),
      $line-height: var(--line-height),
      $fixed-height: true,
    );
  }
}
