// this is a mixin to encompass any containers we have that render next to a sidebar.
// the future here is to unify this naming, which would eliminate the need
// for this mixin.

@mixin with-sidebar {
  .release-show__secondary &,
  .dynamic-page__two-col__main &,
  .faceted-grid:not(.faceted-grid--wide) .faceted-grid__main &,
  .site-search__dropdown__section & {
    @content;
  }
}
