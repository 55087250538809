.video-player {
  flex: 1;
  width: auto;
}

.video-player__wrapper {
  display: flex;

  @include tablet {
    flex-direction: column;
  }
}

.video-player-sidebar {
  @include responsive(width, 40%, 100%);
  @include responsive(margin-left, 2rem, 1rem, 0);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;

  @include tablet {
    margin-left: 0;
    margin-top: 1rem;
  }
}

.video-player-sidebar__video-wrapper {
  flex: 0 1 33.3%;
  padding: 0.5rem 0;
}

.video-player-sidebar__video {
  @include button-reset;
  display: flex;
  align-items: center;
  border: none;
  font-size: 1.5rem;
  text-align: left;
  height: 100%;
  width: 100%;
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: $input-border-radius;
  transition: border-color 0.2s ease-in-out;

  @include responsive-hover {
    border-color: var(--rc-color-text-primary);
  }
}

.video-player-sidebar__image-wrapper {
  @include responsive(height, 100%, 10rem, 7.5rem);
  @include responsive(width, 33%, 15rem, 10rem);
  margin-right: 1rem;
}

.video-player-sidebar__image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.video-player-sidebar__video-info {
  flex: 1;
  padding: 1rem;
}

.video-player-sidebar__video-title {
  @include responsive(-webkit-line-clamp, 3, 3, 2);
  font-size: 1.6rem;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @include mobile {
    font-size: 1.4rem;
  }
}

.video-player-sidebar__video-views {
  font-size: 1.4rem;
  padding-top: 0.5rem;
}
