.formatted-address {
  font-size: 1.6rem;
  line-height: 1.2;
  margin-bottom: 0;

  &.formatted-address--placeholder {
    @include loading-pulse;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 2rem;
      background-color: var(--rc-color-palette-gray-200);
      border-radius: $input-border-radius;
    }

    &::before {
      width: 22%;
      margin-bottom: 0.5rem;
    }

    &::after {
      width: 24%;
    }
  }
}

.formatted-address__highlight {
  font-weight: bold;

  .formatted-address--placeholder & {
    content: '';
    display: block;
    width: 26%;
    height: 2rem;
    background-color: var(--rc-color-palette-gray-200);
    border-radius: $input-border-radius;
    margin-bottom: 0.5rem;
  }
}

.formatted-address__info {
  margin: 0;
  padding: 0;
  line-height: inherit;
}

.formatted-address__incomplete {
  @include fa-icon-before(exclamation-triangle);
  color: var(--rc-color-text-secondary);
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-size: 1.3rem;
}
