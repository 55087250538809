@use 'sass:math';
@use '@reverbdotcom/cadence/styles/tools/functions/svg';
@use '@reverbdotcom/cadence/styles/tools/mixins/svg' as svg-icon;
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.lightbox-image-item {
  @include image-box(var(--rc-color-background-module));

  background: var(--rc-color-background-module);
  border-radius: var(--rc-border-radius-sm);

  &.lightbox-image-item--landscape {
    padding-bottom: math.percentage(math.div(3, 4));
  }

  .lightbox-image--transitioning .lightbox-image__primary & {
    img {
      opacity: 0;
      transition: none;
    }
  }

  img {
    max-width: none;
    opacity: 0.99;
    transition: opacity .5s;
  }

  &.lightbox-image-item--video {
    &::before {
      // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
      background: svg.svg-url(play, scss-tokens.$rc-color-palette-bw-white) center no-repeat;
      background-size: cover;
      width: 9rem;
      height: 9rem;
      transform: translate(-50%, -50%);
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 2;
      opacity: 0.8;
      transition: opacity .2s;

      .lightbox-image__thumbs & {
        opacity: 0.9;
        width: 70%;
        height: 70%;
      }
    }

    &:hover::before {
      opacity: 1;
    }
  }

  &::after {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    @include svg-icon.icon-bg(images, scss-tokens.$rc-color-palette-bw-white);
    background-color: var(--rc-color-shadow-500);
    background-size: 85% auto;
    position: absolute;
    right: 0.2rem;
    bottom: 0.2rem;
    width: 2rem;
    height: 2rem;
    content: '';
    transition: background-color .2s, opacity .2s;
    opacity: 0.9;
    border: 0.1rem solid var(--rc-color-highlight-400);
  }

  &:hover {
    &::after {
      background-color: var(--rc-color-shadow-600);
      opacity: 1;
    }
  }

  &:empty {
    cursor: default;

    &::after {
      display: none;
    }
  }

  .lightbox-image--with-thumbnails .lightbox-image__primary & {
    cursor: zoom-in;

    &.lightbox-image-item--video {
      cursor: pointer;
    }
  }

  .lightbox-image__thumbs &,
  .lightbox-image--single &,
  .lightbox-image--with-thumbnails .lightbox-image__primary & {
    &::after {
      display: none;
    }
  }

  .lightbox-image__primary &,
  .lightbox-image__thumbs & {
    cursor: pointer;
  }

  .dynamic-page--listing .lightbox-image__primary & {
    background: var(--rc-color-background-page);
  }

  .lightbox-modal__primary & {
    position: static;
    background: none;
    padding: 0;
    height: auto;
    width: auto;

    &::after {
      display: none;
    }

    img {
      position: absolute;
      display: block;
      max-width: 100%;
      max-height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .lightbox-image__thumb--large & {
    border: 0.1rem solid var(--rc-color-border-primary);
  }

  .lightbox-image--larger-thumbs & {
    border-radius: $input-border-radius;
    box-sizing: border-box;

    img {
      border-radius: $input-border-radius;
    }
  }
}

.lightbox-image-item__video-frame {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  padding-bottom: 56.25%;
  width: 100%;
  z-index: 3;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

