.product-search-form {
  position: relative;
}

.product-search-form__input {
  &.product-search-form__input--with-results {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.product-search-form__rows {
  @include smooth-scroll;

  border: 0.1rem solid var(--rc-color-border-primary);
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: -0.1rem;
  max-height: 35rem;
  background-color: var(--rc-color-palette-bw-white);
  border: 0.1rem solid var(--rc-color-border-primary);
  overflow-y: scroll;
  border-radius: 0 0 $input-border-radius $input-border-radius;
  z-index: 1;
}

.product-search-form__row {
  display: block;
  border-bottom: solid 0.1rem var(--rc-color-border-primary);
  padding: 0.6rem 1.2rem;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  @include responsive-hover {
    background-color: var(--rc-color-palette-gray-100);
  }
}
