@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.csp-feature-card {
  width: 100%;
  margin-bottom: 1rem;

  &.csp-feature-card--placeholder {
    @include loading-pulse;
  }
}

.csp-feature-card__image-area {
  height: 16rem;
  display: block;
  background-color: var(--rc-color-background-page-secondary);
  background-position: center;
  background-size: cover;
  position: relative;
  transition: box-shadow 0.15s ease, background-color 0.15s ease;
  border-radius: var(--rc-border-radius-primary);

  &:hover {
    @include box-shadow.box-shadow;
    background-color: var(--rc-color-background-page);

    .csp-feature-card__price {
      background-color: var(--rc-color-background-page-secondary);
    }
  }

  .csp-feature-card--square-image & {
    height: 0;
    padding-bottom: 100%;
  }
}

.csp-feature-card__title {
  @include rc-text.utility('400', 'bold');
  margin: 1em 0 0.5em 0;

  a {
    @include group-link;
    color: var(--rc-color-text-primary);
  }
}

.csp-feature-card__price {
  max-width: 90%;
  color: var(--rc-color-text-primary);
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  position: absolute;
  left: var(--rc-space-2);
  bottom: var(--rc-space-2);
  background: var(--rc-color-highlight-600);
  backdrop-filter: blur(var(--rc-space-1));
  border-radius: var(--rc-border-radius-md);
  line-height: 1.1;

  .csp-feature-card--placeholder & {
    width: 40%;
  }
}

.csp-feature-card__text {
  .csp-feature-card--placeholder & {
    margin-top: 1rem;
    background: var(--rc-color-palette-gray-300);
    height: 1.2em;
    width: 70%;
  }

  .csp-feature-card--square-image & {
    font-size: 0.9em;
  }
}

.csp-feature-card__price__inner {
  @include rc-text.fine;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--rc-space-1) var(--rc-space-2);

  b {
    @include rc-text.utility('400', 'bold');
  }

  .csp-feature-card--placeholder & {
    height: 1em;
  }
}

.csp-feature-card__split-price {
  font-size: 0.8em;
  margin-bottom: 0.2em;

  &:only-child,
  &:last-child {
    margin-bottom: 0;
    font-size: 1em;
  }
}

.csp-feature-card__description {
  font-size: 0.9em;
  line-height: 1.3;
  margin-bottom: 0.5em;

  // doing this here for legacy support since
  // some older cards may not be markdown
  p {
    margin-bottom: 0.5em;
  }
}

.csp-feature-card__shop-link {
  @include group-link;
  @include svg.icon-after(arrow-right);
  font-weight: bold;
}
