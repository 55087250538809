.listing-shop-module {
  @include scaling((padding), 2rem);
  background: white;
  border-radius: 0.4rem;
  text-align: center;
}

.listing-shop-module__shop-name {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.listing-shop-module__avatar {
  margin-bottom: 0.5rem;

  img {
    width: 5rem;
    height: auto;
    border-radius: 50%;

    &:hover {
      opacity: 0.85;
    }
  }
}

.listing-shop-module__location,
.listing-shop-module__joined {
  margin-bottom: 0;
  font-size: 1.3rem;
}

.listing-shop-module__location {
  @include fa-icon-before(map-marker);
  margin-top: 0.5rem;
}

.listing-shop-module__achievements {
  margin: 0.5rem 0;
  opacity: 0.5;
  font-size: 1.2rem;
  text-transform: uppercase;
}
