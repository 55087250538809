@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
// Styles correspond to AdBanner component
// This component is a mobile-first design. All sizes you see are for mobile,
// and each min-width block overrides that for a specific size
.amp-banner {
  position: relative;
  min-height: 10rem; // no smaller than 100px
  display: flex;
  align-items: stretch;
  color: var(--rc-color-text-primary);
  overflow: hidden;

  &:hover {
    color: currentColor;
    text-decoration: none;
  }

  @include min-width(800px) {
    min-height: 12rem;
  }
}

.amp-banner__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  background-size: cover;

  &.amp-banner__background--desktop {
    display: none;

    @include min-width(800px) {
      display: block;
    }
  }

  &.amp-banner__background--mobile {
    display: block;

    @include min-width(800px) {
      display: none;
    }
  }
}

.amp-banner__content {
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 1.5rem;
  padding-right: calc(1.5rem + 13rem); // offset for background image

  @include min-width(800px) {
    padding-right: calc(2rem + 32rem);
  }
}

.amp-banner__titleblock {
  text-align: center;
}

.amp-banner__title {
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.2;

  @include min-width(550px) {
    font-size: 2.2rem;
  }

  @include min-width(1440px) {
    font-size: 3rem;
  }
}

.amp-banner__subtitle {
  display: none;
  font-size: 1.6rem;
  margin: 0.5rem 0 0;
  line-height: 1.2;
  font-weight: bold;

  @include min-width(370px) {
    display: block;
  }
}

.amp-banner__button {
  @include rc-button.filled;
  display: none;
  transition: opacity 0.1s ease-in-out;

  @include min-width(800px) {
    display: inline-flex;
    margin: 0 2rem;
  }

  &:hover {
    // This is an intentional break away from RC styles because the button colors are being injected
    // viz inline styles and the RC hover states are getting overridden
    opacity: 0.75;
  }
}

.amp-banner__tag {
  position: absolute;
  bottom: 0;
  right: 0;
}
