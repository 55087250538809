@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.alert-banner {
  padding: 22px 0;

  &.alert-banner--error {
    color: var(--rc-color-text-danger);
    background-color: var(--rc-color-background-alert-error);
  }

  &.alert-banner--warn {
    color: var(--rc-color-text-primary);
    background-color: var(--rc-color-background-alert-warning);
  }

  &.alert-banner--notice {
    color: var(--rc-color-text-primary);
    background-color: var(--rc-color-background-alert-info);
  }

  &.alert-banner--success {
    color: var(--rc-color-text-alert-success);
    background-color: var(--rc-color-background-alert-success);
  }

  @include mobile {
    padding: 1rem;
  }
}

.alert-banner__inner {
  @include site-wrapper;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &.alert-banner__inner--activation-header {
    display: block;
  }

  @include mobile {
    display: block;
  }
}

.alert-banner__info {
  flex: 1;
  position: relative;

  .alert-banner--with-icon & {
    padding-left: 46px;

    &:before {
      @include fa-icon(exclamation-triangle);
      font-size: 30px;
      margin-right: 16px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

.alert-banner__title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 2px;
}

.alert-banner__text {
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}

.alert-banner__list {
  line-height: 1.5;
}

.alert-banner__list__item {
  @include fa-icon-before(circle-o, $margin-right: 0.35em);

  position: relative;
  margin-left: 2rem;

  &:before {
    position: absolute;
    left: -2rem;
    top: 0.5rem;
  }
}

.alert-banner__text-link {
  text-decoration: underline;
  color: currentColor;
  transition: opacity 0.2s ease;

  &.alert-banner__text-link--bold {
    font-weight: bold
  }

  &:hover {
    color: currentColor;
    opacity: 0.8;
  }
}

.alert-banner__paypal-verification-wrapper {
  margin-top: 1em;
}

.alert-banner__paypal-verification {

  &.alert-banner__paypal-verification--pending {
    @include fa-icon-before(spinner, $margin-right: 0.35em, $spin: true);
  }

  &.alert-banner__paypal-verification--success {
    @include fa-icon-before(check-circle, $margin-right: 0.35em);
    display: none; // hidden by default
  }

  &.alert-banner__paypal-verification--failure {
    @include fa-icon-before(exclamation-circle, $margin-right: 0.35em);
    display: none; // hidden by default
  }
}

.alert-banner__action {
  @include rc-button.muted;
  margin-left: 2.2rem;

  &.alert-banner__action--blue {
    @include rc-button.filled;
  }

  @include mobile {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }
}

