.listing-card-tag {
  background-color: var(--rc-color-background-module);
  border-radius: var(--rc-border-radius-md);
  padding: var(--rc-space-1) var(--rc-space-2);
  color: var(--rc-color-text-primary);

  &.listing-card-tag--outlet {
    background-color: var(--rc-color-palette-tweed-orange-500);
  }
}
