.spec-list {
  line-height: 1.3;
  width: 100%;

  td {
    padding: 0.4em 0;

    &:first-child {
      vertical-align: top;
      font-weight: bold;
      padding-right: 2em;
      white-space: nowrap;

      @include mobile {
        white-space: normal;
      }
    }

    &.spec-list__styled-td-label {
      vertical-align: top;
      font-weight: bold;
      padding-right: 2.4rem;
      padding-left: 0;
      white-space: nowrap;
      width: 32%;

      @include tablet {
        width: 40%
      }

      @include mobile {
        white-space: normal;
      }
    }
  }

  tr:first-child td {
    padding-top: 0;
  }

  tr:last-child td {
    padding-bottom: 0;
  }

  a {
    @include text-link;
  }

  .csp2-sidebar__specs & {
    font-size: 0.9em;
  }
}

.spec-list--redesign {
  td {
    padding: 1.2rem;
    border-bottom: solid 0.1rem var(--rc-color-border-primary);
    transition: background-color 0.1s ease;
    padding: var(--rc-space-4) var(--rc-space-2);
    padding-left: 0;
  }

  tbody {
    width: 100%;
    display: table;
  }

  tr:first-child td {
    padding-top: 1.2rem;
  }

  tr:last-child td {
    padding-bottom: 1.2rem;
  }
}

.spec-list__condition-text {
  @include button-as-link;
  border-bottom: dashed 0.1rem var(--rc-color-text-secondary);
  position: relative;
}
