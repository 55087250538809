.markdown-section {
  @extend %cms-base;

  // for legacy equivalent to scaling down dynamic-page-spacing
  margin: calc(var(--rc-space-gap-row) / 2) 0;

  // this is primarily for CMS pages
  .dynamic-page__one-col & {
    @include site-wrapper;
    @include site-wrapper--cms;

    &.markdown-section--site-width {
      max-width: var(--rc-size-max-width-content);
    }

    &.markdown-section--fullbleed {
      padding-left: 0;
      padding-right: 0;
      max-width: none;
    }
  }

  .mosaic + & {
    margin-top: 0;
  }
}
