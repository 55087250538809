.rc-popover {
  min-width: 24rem;
  max-width: 35rem;
  overflow: clip;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  &.rc-popover--loading {
    opacity: 0.6;
  }

  @include mobile {
    min-width: unset;
    max-width: calc(100vw - 2rem);
  }

  .rc-popover__content {
    max-height: 50dvh;
    overflow-y: auto;
    padding: var(--rc-space-4);
  }
}
