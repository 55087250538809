@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.express-sale {
  .sell__form {
    margin: 0 50% 0 0;
    position: relative;

    @include mobile {
      margin: 1rem 0;
    }
  }

  .sell__form__input__search {
    border: solid 0.1rem var(--rc-color-border-primary);
  }

  .csp-square-card__title {
    h3 {
      font-size: 1.6rem;
      font-weight: normal;
    }
  }

  .csp-square-card__details {
    width: 100%;
    font-size: 1.5rem;

    b {
      font-size: 2rem;
      color: var(--rc-color-sentiment-positive-dark);
    }
  }

  .express-sale__listing-price {
    color: var(--rc-color-text-primary-disabled);
    display: flex;
    flex-wrap: wrap;

    .express-sale__listing-price__amount {
      font-size: 1.5rem;
      font-weight: bold;
      margin-right: 0.5rem;
      color: var(--rc-color-text-primary);
    }
  }

  .express-sale__shop-name {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .express-sale__gauranteed-buyer {
    font-size: 1.4rem;
    color: var(--rc-color-text-primary-disabled);
  }
}

.express-sale__header {
  padding: 10vh 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url('#{$discovery-ui-path}/images/sell-flow/trade-in-for-cash-header.svg');
  background-size: cover;
  background-position: center;

  @include mobile {
    background: var(--rc-color-palette-vu-orange-100);
    padding: 3rem 0;
    align-items: flex-start;
  }

  ul, li {
    list-style: inherit;
    line-height: 1.5;
  }

  ul {
    margin-bottom: 2rem;
  }
}

.express-sale__header__image-mobile {
  display: none;

  @include mobile {
    display: block;
    width: 100%;
    background: var(--rc-color-palette-vu-orange-100);
  }
}

.express-sale__header__container {
  @include site-wrapper;
  z-index: 2;
  width: 100%;

  @include mobile {
    max-width: 45rem;
    margin: 0 auto;
  }
}

.express-sale__header__content {
  @include responsive(width, 58%, 58%, 100%);
  text-align: left;
}

.express-sale__header__title {
  @include responsive(font-size, 4rem, 3rem, 2.5rem);
  font-weight: bold;
}

.express-sale__header__subtitle {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.express-sale__section {
  @include site-wrapper;
  @include responsive(padding, 3rem, 2rem);
  margin: auto;
  border-bottom: solid 0.1rem var(--rc-color-border-primary);
}

.express-sale__section__title {
  @include responsive(font-size, 3rem, 3rem, 2.5rem);
  font-weight: bold;
  padding-bottom: 2rem;
}

.express-sale__explanation__content {
  display: flex;

  @include mobile {
    flex-direction: column;
  }
}

.express-sale__explanation-step {
  display: flex;
  padding-right: 2rem;
  align-items: center;

  @include mobile {
    padding-bottom: 2rem;
  }
}

.express-sale__explanation-step__icon {
  height: 8rem;
  width: 8rem;
  padding-right: 2rem;
}

.express-sale__explanation-step__content {
  display: flex;
  flex-direction: column;
}

.express-sale__explanation-step__title {
  font-size: 2rem;
  padding-bottom: 0.5rem;
}

.express-sale__explanation-step__description {
  margin-bottom: 0;
}

.express-sale__search__subtitle {
  font-weight: bold;
}

.express-sale__sell-card__button {
  @include rc-button.filled;
  width: 100%;
  margin: 1rem 0;
}
