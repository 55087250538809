@use '../functions/svg';
@use '../../config/scss-tokens';

@use 'sass:math';

// stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
@mixin image-box($background: null, $aspect: 1, $svg-background: null, $svg-fill: scss-tokens.$rc-color-text-primary) {
  @extend %image-box-base;

  padding-bottom: math.div(100%, $aspect);

  @if $background != null {
    &:empty {
      background: $background center no-repeat;
      background-size: cover;
    }
  }

  @if $svg-background != null {
    &:empty {
      @include svg.svg-background-image($svg-background, $svg-fill);
      background-size: cover;
    }
  }
}
