// this doesn't follow our BEM naming convention but it's pulled straight from Apple docs
// https://developer.apple.com/documentation/apple_pay_on_the_web/displaying_apple_pay_buttons
// We might be able to rename things without breaking stuff in the future
.apple-pay-button {
  display: block;
  border-radius: $button-border-radius;
  height: var(--rc-min-height-button-medium);
  width: 100%;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-style: black;

  &.apple-pay-button--black {
    -apple-pay-button-style: black;
    -apple-pay-button-type: check-out;
  }

  &.apple-pay-button--black-express {
    -apple-pay-button-style: black;
    -apple-pay-button-type: pay;
  }

  &.apple-pay-button--white-outlined {
    -apple-pay-button-style: white-outline;
    -apple-pay-button-type: check-out;
  }

  &.apple-pay-button--white-outlined-express {
    -apple-pay-button-style: white-outline;
    -apple-pay-button-type: pay;
  }

  &.apple-pay-button--small {
    height: var(--rc-min-height-button-small);
  }
}
