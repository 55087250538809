
.item2-shop-overview {
  margin-top: var(--rc-space-4);
  padding-top: var(--rc-space-4);
  border-top: 0.1rem solid var(--rc-color-border-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item2-shop-overview__avatar {
  width: 3.4rem;
  height: auto;
  margin-right: var(--rc-space-4);
}

.item2-shop-overview__title {
  display: flex;
  flex-wrap: wrap;
}

.item2-shop-overview__location {
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
}
