@use 'sass:math';

.rsl-value-props {
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-primary);
  background-color: var(--rc-color-background-module-muted);
}

.rsl-value-props__header {
  @include scaling(padding, 2rem);
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
}

.rsl-value-props__title-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rsl-value-props__title {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;
}

.rsl-value-props__content {
  @include scaling(padding, 2rem);
  display: flex;
  margin-left: -#{math.div($grid-gutter, 2)};
  margin-right: -#{math.div($grid-gutter, 2)};

  .rsl-value-props--vertical & {
    flex-direction: column;
  }

  @include mobile {
    flex-direction: column;
    margin: 0;
  }

  // if this is inside a .g-col-8 element, we can assume the desktop-size width is narrower than ideal,
  // so we need to break it to the "mobile" version sooner (ie at tablet)
  .g-col-8 & {
    @include tablet {
      flex-direction: column;
      margin: 0;
    }
  }
}

.rsl-value-prop {
  display: flex;
  flex: 1;
  margin: math.div($grid-gutter, 2);
  margin-right: math.div($grid-gutter, 2);

  @include mobile {
    margin: 0 0 2rem;

    &:last-child {
      margin: 0;
    }
  }

  .g-container & {
    @include tablet {
      margin: 0 0 2rem;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.rsl-value-prop__image {
  flex: 0 0 4rem;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.rsl-value-prop__content {
  flex: 1 1 auto;
  padding-left: 2rem;
  min-width: 0; // this is a flexbox hack to prevent overflowing content

  @include mobile {
    padding-left: 1rem;
  }

  .g-container & {
    padding-left: 1rem;
  }
}

.rsl-value-prop__title {
  font-size: 1.6rem;
  font-weight: bold;
}

.rsl-value-prop__subtitle {
  font-size: 1.4rem;
  margin: 0;
}
