@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.item-row {
  display: flex;
  background-color: var(--rc-color-palette-bw-white);
  padding: 2rem;
  border-top: solid 0.1rem var(--rc-color-border-primary);

  @include mobile {
    display: block;
    padding: 1.4rem;
    padding-left: 1.4rem + 4rem + 1.4rem;
    position: relative;
  }

  &.item-row--compact {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    padding-left: 2rem + 4rem + 1.4rem; // padding + thumbnail width + thumb margin
    display: block;
    position: relative;
    min-height: 4rem;

    @include mobile {
      padding-left: 1.4rem + 4rem + 1.4rem;
    }

    &.item-row--has-notice {
      padding-right: 2rem + 21rem; // right padding + notice width

      @include mobile {
        padding-right: 1.4rem;
      }
    }
  }

  &:last-child {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
}

.item-row__thumbnail {
  display: block;
  width: 10rem;
  height: 10rem;
  border-radius: 0.4rem;
  flex: 0;
  flex-basis: 10rem;
  margin-right: 2rem;

  @mixin item-row--compact--thumbnail {
    width: 4rem;
    height: 4rem;
    margin: 0;
    position: absolute;
    top: 1.4rem;
    left: 1.4rem;
  }

  @include mobile {
    @include item-row--compact--thumbnail;
  }

  .item-row--compact & {
    @include item-row--compact--thumbnail;
    left: 2rem;
  }
}

.item-row__main {
  flex: 1;
  margin-right: 2rem;
}

.item-row__notice-pane {
  margin-bottom: 0.6rem;

  .item-row--compact & {
    margin-bottom: 0;
    position: absolute;
    right: 2rem;
    top: 1.4rem;
    text-align: right;
    max-width: 21rem;

    @include mobile {
      position: relative;
      top: 0;
      right: auto;
      max-width: 100%;
      text-align: left;
    }
  }
}

.item-row__notice {
  font-size: 1.4rem;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 0;

  &.item-row__notice--refund {
    @include fa-icon-before(exchange, $margin-right: 0.25em);
    color: var(--rc-color-text-price-nudge);
  }
}

.item-row__title {
  font-size: 1.8rem;
  font-weight: 700;

  @include mobile {
    font-size: 1.4rem;
  }

  .item-row--compact & {
    font-size: 1.4rem;
  }
}

.item-row__details {
  font-size: 1.4rem;
  margin-top: 0.4rem;

  td {
    padding: 0 0.75em 0.2em 0;

    &:last-child {
      padding-right: 0;
    }
  }

  tr:last-child td {
    padding-bottom: 0;
  }
}

.item-row__actions {
  flex: 0;
  flex-basis: 28%;
  margin-left: auto;

  @include mobile {
    display: none;
  }
}

.item-row__action {
  @include rc-button.muted;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  width: 100%;

  .item-row--compact & {
    @include button-reset;
    font-size: 1.4rem;
    font-weight: normal;
    color: var(--rc-color-palette-blue-600);
    width: auto;
    margin: 0;
    margin-right: 1em;

    &:hover {
      color: var(--rc-color-palette-vu-orange-500);
    }
  }

  &:last-child {
    margin: 0;
  }

  &.order-card__action--primary {
    @include rc-button.filled;
    font-size: 1.3rem;
  }
}
