.seller-info {
  line-height: 1.2;
}

.seller-info__name {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 0.25rem;

  a {
    color: var(--rc-color-text-primary);
  }
}

.seller-info__location {
  font-size: 1.2rem;
  color: var(--rc-color-text-secondary);
  margin-bottom: 0.25rem;
}

.seller-info__badges {
  margin-top: 0.5rem;
}
