.label-tag, // Needed for core for now
.rc-tag {
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: var(--rc-color-background-tag);
  color: var(--rc-color-text-primary);
  padding: 0.2em 0.4em;
  border-radius: var(--rc-border-radius-sm);
  min-width: 2rem;
  text-align: center;
  box-sizing: border-box;
  line-height: 1.2;
  flex-shrink: 0;

  &.label-tag--orange, // Needed for core for now
  &.rc-tag--notification {
    background-color: var(--rc-color-background-tag-notification);
  }

  &.label-tag--blue, // Needed for core for now
  &.rc-tag--highlight {
    background-color: var(--rc-color-background-tag-highlight);
    color: var(--rc-color-text-tag-accent);
  }

  &.rc-tag--success {
    background-color: var(--rc-color-background-tag-success);
    color: var(--rc-color-text-tag-accent);
  }

  &.label-tag--small, // Needed for core for now
  &.rc-tag--small {
    padding: 0.05em 0.2em;
    min-width: 1.4rem;
  }
}
