.progress-bar-header {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 1rem;
}

.progress-bar {
  text-align: center;
  font-weight: bold;

  .order-actions & {
    margin: 0.5rem 0 0;
  }

  &.checkout-progress-bar {
    padding-top: 20px;
    padding-bottom: 50px;

    @include mobile {
      padding-top: 10px;
      padding-bottom: 20px;
    }
  }

  li {
    display: inline-block;
    vertical-align: middle;
    margin-right: -5px;
    color: var(--rc-color-text-secondary);
    border: 3px solid var(--rc-color-background-module);
    background: var(--rc-color-background-progressbar-track);
    transition: all 0.2s ease-in-out;

    &:last-child { margin-right: 0; }
    &.current {
      margin-right: -8px;
      color: var(--rc-color-text-primary);
      box-sizing: content-box;
    }

    &.warning {
      $progress-bar-warning: var(--rc-color-sentiment-warning-light);
      border: none;
      background: $progress-bar-warning;

      &, a { color: var(--rc-color-palette-sentiment-warning-lighter); }

      &:hover,
      & a:hover { color: $progress-bar-warning; }
    }

    &.finished {
      border-left: none;
      border-right: none;
      background: var(--rc-color-background-progressbar-indicator-success);

      &, a { color: var(--rc-color-text-success); }

      &:hover,
      & a:hover { color: var(--rc-color-text-alert-success); }
    }

    @include mobile {
      margin-right: -10px;
      border: none;
      background: var(--rc-color-text-secondary);
    }
  }

  .step {
    position: relative;
    width: 14px;
    height: 14px;
    border-radius: 50%;

    .label,
    a.label {
      position: absolute;
      top: 150%;
      display: table-cell;
      width: 150px;
      margin-left: -64px; // 75px - 1/2 step margin width - border
      text-align: center;
      white-space: nowrap;
      transition: all 0.2s ease-in-out;

      @include mobile { display: none; }
    }

    &.current,
    &.finished {
      &:before {
        content: '';
        position: absolute;
        top: -20%;
        display: block;
        width: 14px;
        height: 14px;
      }
    }

    &.current {
      border: none;
      background: none;

      &:before {
        border: 3px solid var(--rc-color-background-module);
        background: var(--rc-color-text-primary);
        animation: popin 0.5s 0.5s ease-in-out 1 forwards;
        transform: scale(0);
        border-radius: 50%;
      }
    }

    &.finished {
      background: var(--rc-color-background-progressbar-track);

      &:before,
      &::before {
        background: var(--rc-color-background-progressbar-indicator-success);
        border: 3px solid var(--rc-color-background-module);
        border-radius: 50%;
      }

      &.active:before,
      &.active::before {
        animation: popin 0.5s 0 ease-in-out 1 forwards;
      }

      @include mobile { background: var(--rc-color-text-secondary); }
    }
  }

  .path {
    position: relative;
    width: 25%;
    height: 6px;
    border-left: none;
    border-right: none;
    background: var(--rc-color-background-progressbar-track);

    &:before,
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 0;
      background: var(--rc-color-background-progressbar-indicator-success);
    }

    &.finished.active {
      background: var(--rc-color-background-progressbar-track);

      &:before,
      &::before {
        animation: bar-fill 0.5s 0.3s ease-in-out 1 forwards;
      }
    }

    @include mobile {
      &, &.finished.active { background: var(--rc-color-text-secondary); }
    }
  }

  // &.on-white-bg {
  //   li {
  //     border-color: var(--rc-color-palette-bw-white);
  //     &.step {
  //       &:before, &::before { border-color: var(--rc-color-palette-bw-white); }
  //     }
  //   }
  // }

  // &.on-gray-bg {
  //   li {
  //     border-color: var(--rc-color-palette-gray-100);
  //     &.step {
  //       &:before, &::before { border-color: var(--rc-color-palette-gray-100); }
  //     }
  //   }
  // }

  // &.on-light-gray-bg {
  //   li {
  //     border-color: var(--rc-color-palette-gray-200);
  //     &.step {
  //       &:before, &::before { border-color: var(--rc-color-palette-gray-100); }
  //     }
  //   }
  // }

  &.no-animation {
    li {
      &:before,
      &::before {
        transition: none;
        animation: none;
        animation: none !important;
        transition: none !important;
      }

      &.path.finished {
        &:before, &::before { width: 100%; }
      }
    }
  }
}

// Mini
.progress-bar {
  margin-right: -6px;
  min-width: 6em;

  .order-actions & {
    margin-right: 0;
  }

  &.mini .progress-bar-header {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.3rem;
    margin-bottom: 0;
  }

  &.mini .step {
    &.active,
    &.current,
    &.finished {
      width: 9px;
      height: 9px;
      border: none;

      &:before,
      &::before {
        width: 9px;
        height: 9px;
        border: none;
        top: 0;
      }
    }

    &,
    &.finished {
      width: 9px;
      height: 9px;
    }
  }

  &.mini .path {
    height: 3px;
    width: 20%;
  }
}

// Small
.progress-bar {
  &.small .step {
    &.current,
    &.finished {
      width: 12px;
      height: 12px;

      &:before,
      &::before {
        width: 8px;
        height: 8px;
      }
    }

    &,
    &.finished {
      width: 8px;
      height: 8px;
    }
  }

  &.small .path {
    height: 3px;
  }

}

.in-mobile-app .progress-bar {
  @include mobile { margin-bottom: 20px; }
}
