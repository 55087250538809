@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';
@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

.brand-card {
  width: 100%;
}

.brand-card__link {
  @include shadow-on-hover;

  position: relative;
  display: flex;
  gap: var(--rc-space-1);
  height: 100%;
  background-color: var(--rc-color-background-module);
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2.5rem 2rem;
  color: var(--rc-color-text-primary);
  border-radius: var(--rc-border-radius-md);

  .brand-card--placeholder & {
    @include loading-pulse;

    height: 7rem;
  }
}

.brand-card__title {
  @include truncate-text.truncate-text(2);
  @include rc-text.utility('450', 'bold');
  flex: 1;
}

.brand-card__icon {
  display: flex;

  svg {
    width: 1.8rem;
    fill: currentColor;
  }
}
