/**
 * -- README --
 * This file is automatically generated, do not edit directly.
 * Edit files in `src/tokenSets` then run `npm run build-tokens` from packages/cadence to generate updates.
 */

@mixin theme {
  --rc-space-inset-browser-safe-area-bottom: env(safe-area-inset-bottom, 0rem);
  --rc-size-checkbox: 2rem;
  --rc-size-width-scrollbar: 0;
  --rc-size-max-width-content: 180rem;
  --rc-size-height-input: 4.8rem;
  --rc-color-text-urgent-on-urgent-ultralight: #c60c04;
  --rc-color-text-urgent: #d80b03;
  --rc-color-accent-urgent: #de0902;
  --rc-color-background-urgent-ultralight: rgba(253, 43, 10, 0.1);
  --rc-color-background-urgent-medium: #fd7664;
  --rc-color-background-urgent-bold: #de0902;
  --rc-color-text-success-on-success-ultralight: #04741e;
  --rc-color-text-success: #0b7d23;
  --rc-color-accent-success: #01820e;
  --rc-color-background-success-ultralight: rgba(0, 194, 0, 0.13);
  --rc-color-background-success-medium: #5bb662;
  --rc-color-background-success-bold: #018924;
  --rc-color-text-notice-on-notice-ultralight: #7f620b;
  --rc-color-accent-notice: #fbc410;
  --rc-color-background-notice-ultralight: rgba(244, 186, 26, 0.19);
  --rc-color-background-notice-medium: #fcd363;
  --rc-color-background-notice-bold: #fbc410;
  --rc-color-text-neutral-on-neutral-ultralight: #646464;
  --rc-color-accent-neutral: #757575;
  --rc-color-background-neutral-ultralight: rgba(117, 117, 117, 0.11);
  --rc-color-background-neutral-medium: #a2a2a2;
  --rc-color-background-neutral-bold: #757575;
  --rc-color-text-loud-on-loud-ultralight: #ad4208;
  --rc-color-text-loud: #bf4802;
  --rc-color-accent-loud: #ee5b03;
  --rc-color-background-loud-ultralight: rgba(255, 98, 0, 0.1);
  --rc-color-background-loud-medium: #ffa166;
  --rc-color-background-loud-bold: #ff6200;
  --rc-color-text-info-on-info-ultralight: #075cd3;
  --rc-color-text-info: #0363e6;
  --rc-color-accent-info: #036af4;
  --rc-color-background-info-ultralight: rgba(3, 106, 244, 0.1);
  --rc-color-background-info-medium: #63a6fe;
  --rc-color-background-info-bold: #036af4;
  --rc-color-background-recessed: #f0f1f5;
  --rc-font-line-height-utility: 1.2;
  --rc-font-line-height-title: 1.1;
  --rc-font-line-height-eyebrow: 1.333;
  --rc-font-line-height-display: 1.0625;
  --rc-font-line-height-default: 1.4;
  --rc-font-letter-spacing-heading: -0.034em;
  --rc-font-letter-spacing-eyebrow: 0.06em;
  --rc-font-letter-spacing-default: -0.01em;
  --rc-color-background-switch-track-selected: #43993f;
  --rc-border-width-switch: 0.2rem;
  --rc-size-width-switch: 5.2rem;
  --rc-size-height-switch: 2.8rem;
  --rc-border-width-chip: 0.2rem;
  --rc-color-button-danger-secondary-hovered-inverted: rgba(222, 9, 2, 0.58);
  --rc-color-button-danger-secondary-inverted: rgba(222, 9, 2, 0.42);
  --rc-color-button-danger-primary-hovered-inverted: #f74435;
  --rc-color-button-danger-primary-inverted: #de0902;
  --rc-color-button-danger-secondary-hovered: rgba(253, 41, 13, 0.44);
  --rc-color-button-danger-secondary: rgba(253, 45, 13, 0.24);
  --rc-color-button-danger-primary-hovered: #bf0802;
  --rc-color-button-danger-primary: #de0902;
  --rc-color-button-loud-secondary-hovered-inverted: rgba(255, 98, 0, 0.51);
  --rc-color-button-loud-secondary-inverted: rgba(255, 98, 0, 0.35);
  --rc-color-button-loud-primary-hovered-inverted: #ff8133;
  --rc-color-button-loud-primary-inverted: #ff6200;
  --rc-color-button-loud-secondary-hovered: rgba(255, 98, 0, 0.4);
  --rc-color-button-loud-secondary: rgba(255, 98, 0, 0.2);
  --rc-color-button-loud-primary-hovered: #d95300;
  --rc-color-button-loud-primary: #ff6200;
  --rc-color-button-main-secondary-hovered-inverted: rgba(255, 255, 255, 0.26);
  --rc-color-button-main-secondary-inverted: rgba(255, 255, 255, 0.16);
  --rc-color-button-main-primary-hovered-inverted: #ffffff;
  --rc-color-button-main-primary-inverted: #f5f5f5;
  --rc-color-button-main-secondary-hovered: rgba(0, 0, 0, 0.12);
  --rc-color-button-main-secondary: rgba(0, 0, 0, 0.07);
  --rc-color-button-main-primary-hovered: #121212;
  --rc-color-button-main-primary: #212121;
  --rc-color-text-button-danger-inverted: #ff6463;
  --rc-color-text-button-danger: #d80b03;
  --rc-color-text-button-loud-inverted: #fd8332;
  --rc-color-text-button-loud: #ee5b03;
  --rc-opacity-button-disabled: 0.5;
  --rc-font-weight-button: bold;
  --rc-font-family-button: Inter;
  --rc-font-size-button-large: 1.6rem;
  --rc-font-size-button-medium: 1.6rem;
  --rc-font-size-button-small: 1.6rem;
  --rc-font-size-button-mini: 1.4rem;
  --rc-padding-lr-button-large: 3rem;
  --rc-padding-lr-button-medium: 2.6rem;
  --rc-padding-lr-button-small: 2rem;
  --rc-padding-lr-button-mini: 1.4rem;
  --rc-padding-tb-button-large: 0.4rem;
  --rc-padding-tb-button-medium: 0.4rem;
  --rc-padding-tb-button-small: 0.4rem;
  --rc-padding-tb-button-mini: 0.2rem;
  --rc-min-height-button-large: 5.6rem;
  --rc-min-height-button-medium: 4.8rem;
  --rc-min-height-button-small: 4rem;
  --rc-min-height-button-mini: 3rem;
  --rc-border-width-button: 0.3rem;
  --rc-space-y-accordion-large-mobile: var(--rc-space-4);
  --rc-space-y-accordion-large: var(--rc-space-6);
  --rc-space-x-accordion: var(--rc-space-1);
  --rc-space-y-accordion: var(--rc-space-3);
  --rc-space-gap-checkbox-label: var(--rc-space-2);
  --rc-padding-lr-input-medium: var(--rc-space-3);
  --rc-padding-tb-input-medium: var(--rc-space-2);
  --rc-color-text-on-urgent-bold: var(--rc-color-palette-bw-white);
  --rc-color-text-on-success-bold: var(--rc-color-palette-bw-white);
  --rc-color-text-on-neutral-bold: var(--rc-color-palette-bw-white);
  --rc-color-accent-on-loud-bold: var(--rc-color-palette-bw-white);
  --rc-color-text-on-info-bold: var(--rc-color-palette-bw-white);
  --rc-color-background-overlay-selected: var(--rc-color-shadow-200);
  --rc-color-background-overlay-pressed: var(--rc-color-shadow-200);
  --rc-color-background-overlay-hovered: var(--rc-color-shadow-100);
  --rc-color-background-scrim: var(--rc-color-shadow-600);
  --rc-color-border-selectable: var(--rc-color-palette-gray-400);
  --rc-color-border-primary: var(--rc-color-palette-gray-300);
  --rc-color-text-primary-inverted: var(--rc-color-palette-bw-white);
  --rc-color-text-tertiary: var(--rc-color-palette-gray-600);
  --rc-color-text-secondary: var(--rc-color-palette-gray-700);
  --rc-color-text-primary: var(--rc-color-palette-gray-900);
  --rc-color-background-elevated: var(--rc-color-palette-bw-white);
  --rc-color-background-on-primary: var(--rc-color-palette-gray-100);
  --rc-color-background-primary: var(--rc-color-palette-bw-white);
  --rc-font-family-heading: var(--rc-font-family-inter);
  --rc-font-family-page: var(--rc-font-family-inter);
  --rc-color-background-segmented-control-high-contrast: var(--rc-color-highlight-400);
  --rc-color-background-segmented-control: var(--rc-color-button-main-secondary);
  --rc-color-background-switch-track: var(--rc-color-palette-gray-200);
  --rc-color-background-switch-handle: var(--rc-color-palette-bw-white);
  --rc-padding-r-chip-mini: var(--rc-space-2);
  --rc-padding-lr-chip: var(--rc-space-4);
  --rc-border-radius-chip: var(--rc-border-radius-md);
  --rc-color-text-button-inverted: var(--rc-color-palette-bw-white);
  --rc-border-radius-button: var(--rc-border-radius-full);
  --rc-color-text-mulberry: var(--rc-color-vendor-mulberry);
  --rc-color-text-tag-bump: var(--rc-color-palette-gray-600);
  --rc-color-text-tag-accent: var(--rc-color-palette-bw-white);
  --rc-color-text-price-nudge: var(--rc-color-text-urgent);
  --rc-color-text-link: var(--rc-color-text-info);
  --rc-color-text-input-secondary: var(--rc-color-palette-gray-300);
  --rc-color-text-discount: var(--rc-color-text-urgent);
  --rc-color-text-danger: var(--rc-color-text-urgent);
  --rc-color-text-alert-success: var(--rc-color-text-success-on-success-ultralight);
  --rc-color-text-accent: var(--rc-color-accent-loud);
  --rc-color-box-shadow-primary: var(--rc-color-shadow-300);
  --rc-color-outline-focused: var(--rc-color-accent-info);
  --rc-color-border-input-primary: var(--rc-color-palette-gray-800);
  --rc-color-border-input-error: var(--rc-color-accent-urgent);
  --rc-color-border-alert-warning: var(--rc-color-accent-notice);
  --rc-color-border-alert-success: var(--rc-color-accent-success);
  --rc-color-border-alert-info: var(--rc-color-accent-info);
  --rc-color-border-alert-highlight: var(--rc-color-accent-loud);
  --rc-color-border-alert-error: var(--rc-color-accent-urgent);
  --rc-color-border-alert-plain: var(--rc-color-accent-neutral);
  --rc-color-border-accent: var(--rc-color-accent-loud);
  --rc-color-fill-box-sentiment-negative-bold: var(--rc-color-sentiment-negative-light);
  --rc-color-fill-box-sentiment-negative-muted: var(--rc-color-sentiment-negative-dark);
  --rc-color-fill-box-sentiment-negative: var(--rc-color-sentiment-negative-dark);
  --rc-color-fill-box-kick-blue-bold: var(--rc-color-palette-kick-blue-300);
  --rc-color-fill-box-kick-blue-muted: var(--rc-color-palette-kick-blue-500);
  --rc-color-fill-box-kick-blue: var(--rc-color-palette-kick-blue-400);
  --rc-color-fill-box-blue-bold: var(--rc-color-palette-blue-300);
  --rc-color-fill-box-blue-muted: var(--rc-color-palette-blue-600);
  --rc-color-fill-box-blue: var(--rc-color-palette-blue-500);
  --rc-color-fill-box-teal-bold: var(--rc-color-palette-teal-900);
  --rc-color-fill-box-teal-muted: var(--rc-color-palette-teal-900);
  --rc-color-fill-box-teal: var(--rc-color-palette-teal-800);
  --rc-color-fill-box-yellow-bold: var(--rc-color-palette-yellow-900);
  --rc-color-fill-box-yellow-muted: var(--rc-color-palette-yellow-900);
  --rc-color-fill-box-yellow: var(--rc-color-palette-yellow-800);
  --rc-color-fill-box-tweed-orange-bold: var(--rc-color-palette-tweed-orange-900);
  --rc-color-fill-box-tweed-orange-muted: var(--rc-color-palette-tweed-orange-900);
  --rc-color-fill-box-tweed-orange-subtle: var(--rc-color-palette-tweed-orange-900);
  --rc-color-fill-box-tweed-orange: var(--rc-color-palette-tweed-orange-800);
  --rc-color-fill-box-vu-orange-bold: var(--rc-color-palette-vu-orange-900);
  --rc-color-fill-box-vu-orange-muted: var(--rc-color-palette-vu-orange-900);
  --rc-color-fill-box-vu-orange-subtle: var(--rc-color-palette-vu-orange-700);
  --rc-color-fill-box-vu-orange: var(--rc-color-palette-vu-orange-600);
  --rc-color-fill-box-gray-bold: var(--rc-color-palette-gray-300);
  --rc-color-fill-box-gray-muted: var(--rc-color-palette-gray-600);
  --rc-color-fill-box-gray: var(--rc-color-palette-gray-600);
  --rc-color-border-box-sentiment-negative: var(--rc-color-sentiment-negative-dark);
  --rc-color-border-box-kick-blue: var(--rc-color-palette-kick-blue-400);
  --rc-color-border-box-blue: var(--rc-color-palette-blue-500);
  --rc-color-border-box-teal: var(--rc-color-palette-teal-800);
  --rc-color-border-box-yellow: var(--rc-color-palette-yellow-800);
  --rc-color-border-box-tweed-orange: var(--rc-color-palette-tweed-orange-800);
  --rc-color-border-box-vu-orange: var(--rc-color-palette-vu-orange-600);
  --rc-color-border-box-gray: var(--rc-color-palette-gray-600);
  --rc-color-background-box-sentiment-negative-bold: var(--rc-color-sentiment-negative-dark);
  --rc-color-background-box-sentiment-negative-muted: var(--rc-color-sentiment-negative-light);
  --rc-color-background-box-sentiment-negative-subtle: var(--rc-color-sentiment-negative-lighter);
  --rc-color-background-box-kick-blue-bold: var(--rc-color-palette-kick-blue-600);
  --rc-color-background-box-kick-blue-muted: var(--rc-color-palette-kick-blue-300);
  --rc-color-background-box-kick-blue-subtle: var(--rc-color-palette-kick-blue-100);
  --rc-color-background-box-blue-bold: var(--rc-color-palette-blue-600);
  --rc-color-background-box-blue-muted: var(--rc-color-palette-blue-300);
  --rc-color-background-box-blue-subtle: var(--rc-color-palette-blue-100);
  --rc-color-background-box-teal-bold: var(--rc-color-palette-teal-500);
  --rc-color-background-box-teal-muted: var(--rc-color-palette-teal-300);
  --rc-color-background-box-teal-subtle: var(--rc-color-palette-teal-100);
  --rc-color-background-box-yellow-bold: var(--rc-color-palette-yellow-600);
  --rc-color-background-box-yellow-muted: var(--rc-color-palette-yellow-300);
  --rc-color-background-box-yellow-subtle: var(--rc-color-palette-yellow-100);
  --rc-color-background-box-tweed-orange-bold: var(--rc-color-palette-tweed-orange-600);
  --rc-color-background-box-tweed-orange-muted: var(--rc-color-palette-tweed-orange-300);
  --rc-color-background-box-tweed-orange-subtle: var(--rc-color-palette-tweed-orange-100);
  --rc-color-background-box-vu-orange-bold: var(--rc-color-palette-vu-orange-500);
  --rc-color-background-box-vu-orange-muted: var(--rc-color-palette-vu-orange-300);
  --rc-color-background-box-vu-orange-subtle: var(--rc-color-palette-vu-orange-100);
  --rc-color-background-box-gray-bold: var(--rc-color-palette-gray-600);
  --rc-color-background-box-gray-muted: var(--rc-color-palette-gray-300);
  --rc-color-background-box-gray-subtle: var(--rc-color-palette-gray-100);
  --rc-color-background-pip: var(--rc-color-background-loud-bold);
  --rc-color-background-tag: var(--rc-color-shadow-200);
  --rc-color-background-tag-success: var(--rc-color-sentiment-positive-dark);
  --rc-color-background-tag-notification: var(--rc-color-palette-vu-orange-300);
  --rc-color-background-tag-highlight: var(--rc-color-palette-blue-600);
  --rc-color-background-subfooter: var(--rc-color-palette-bw-soft-black);
  --rc-color-background-progressbar-indicator-muted: var(--rc-color-palette-gray-600);
  --rc-color-background-progressbar-indicator-success: var(--rc-color-sentiment-positive-dark);
  --rc-color-background-progressbar-indicator-primary: var(--rc-color-palette-gray-900);
  --rc-color-background-progressbar-track: var(--rc-color-palette-gray-300);
  --rc-color-background-page-inverted: var(--rc-color-palette-bw-soft-black);
  --rc-color-background-page-secondary: var(--rc-color-background-recessed);
  --rc-color-background-module-price-guide: var(--rc-color-palette-blue-700);
  --rc-color-background-module-price-guide-secondary: var(--rc-color-palette-blue-100);
  --rc-color-background-semitransparent-inverted: var(--rc-color-shadow-500);
  --rc-color-background-semitransparent: var(--rc-color-highlight-500);
  --rc-color-background-loadingbar: var(--rc-color-palette-gray-900);
  --rc-color-background-loadingbar-inverted: var(--rc-color-palette-bw-white);
  --rc-color-background-loading-placeholder: var(--rc-color-palette-gray-200);
  --rc-color-background-input: var(--rc-color-palette-bw-white);
  --rc-color-background-input-disabled: var(--rc-color-palette-gray-200);
  --rc-color-background-input-checked: var(--rc-color-palette-bw-soft-black);
  --rc-color-background-footer: var(--rc-color-palette-gray-900);
  --rc-color-background-banner-warning: var(--rc-color-background-notice-medium);
  --rc-color-background-banner-success: var(--rc-color-background-success-medium);
  --rc-color-background-banner-plain: var(--rc-color-background-neutral-medium);
  --rc-color-background-banner-info: var(--rc-color-background-info-medium);
  --rc-color-background-banner-highlight: var(--rc-color-background-loud-medium);
  --rc-color-background-banner-error: var(--rc-color-background-urgent-medium);
  --rc-color-background-alert-warning: var(--rc-color-background-notice-ultralight);
  --rc-color-background-alert-success: var(--rc-color-background-success-ultralight);
  --rc-color-background-alert-plain: var(--rc-color-background-neutral-ultralight);
  --rc-color-background-alert-info: var(--rc-color-background-info-ultralight);
  --rc-color-background-alert-highlight: var(--rc-color-background-loud-ultralight);
  --rc-color-background-alert-error: var(--rc-color-background-urgent-ultralight);
  --rc-border-radius-primary: var(--rc-border-radius-lg);
  --rc-border-radius-checkbox: var(--rc-border-radius-sm);
  --rc-border-radius-input: var(--rc-border-radius-md);
  --rc-color-text-on-urgent-ultralight: var(--rc-color-text-primary);
  --rc-color-text-on-urgent-medium: var(--rc-color-text-primary);
  --rc-color-text-on-success-ultralight: var(--rc-color-text-primary);
  --rc-color-text-on-success-medium: var(--rc-color-text-primary);
  --rc-color-text-on-notice-ultralight: var(--rc-color-text-primary);
  --rc-color-text-on-notice-medium: var(--rc-color-text-primary);
  --rc-color-text-on-notice-bold: var(--rc-color-text-primary);
  --rc-color-text-on-neutral-ultralight: var(--rc-color-text-primary);
  --rc-color-text-on-neutral-medium: var(--rc-color-text-primary);
  --rc-color-text-on-loud-ultralight: var(--rc-color-text-primary);
  --rc-color-text-on-loud-medium: var(--rc-color-text-primary);
  --rc-color-text-on-info-ultralight: var(--rc-color-text-primary);
  --rc-color-text-on-info-medium: var(--rc-color-text-primary);
  --rc-color-border-selected: var(--rc-color-text-primary);
  --rc-color-text-primary-on-light: var(--rc-color-text-primary);
  --rc-color-text-primary-on-dark: var(--rc-color-text-primary-inverted);
  --rc-color-text-button-on-danger-primary-inverted: var(--rc-color-text-button-inverted);
  --rc-color-text-button-on-danger-primary: var(--rc-color-text-button-inverted);
  --rc-color-text-button-on-loud-primary-inverted: var(--rc-color-text-button-inverted);
  --rc-color-text-button-on-loud-primary: var(--rc-color-text-button-inverted);
  --rc-color-text-button-main-inverted: var(--rc-color-text-button-inverted);
  --rc-color-text-button-on-main-primary: var(--rc-color-text-button-inverted);
  --rc-color-text-button: var(--rc-color-text-primary);
  --rc-color-text-textlink: var(--rc-color-text-primary);
  --rc-color-text-textlink-hovered: var(--rc-color-text-accent);
  --rc-color-text-star: var(--rc-color-text-primary);
  --rc-color-text-primary-disabled: var(--rc-color-text-tertiary);
  --rc-color-text-link-hovered: var(--rc-color-text-accent);
  --rc-color-text-pip: var(--rc-color-text-primary-inverted);
  --rc-color-background-page: var(--rc-color-background-primary);
  --rc-color-background-overlay-modal: var(--rc-color-background-scrim);
  --rc-color-background-module-hovered: var(--rc-color-background-overlay-hovered);
  --rc-color-background-module: var(--rc-color-background-primary);
  --rc-color-background-module-secondary: var(--rc-color-background-on-primary);
  --rc-color-background-module-muted: var(--rc-color-background-on-primary);
  --rc-color-background-card: var(--rc-color-background-on-primary);
  --rc-color-background-card-hovered: var(--rc-color-background-elevated);
  --rc-color-text-on-loud-bold: var(--rc-color-text-primary-on-light);
  --rc-color-text-button-main: var(--rc-color-text-button);
  --rc-color-text-button-on-main-primary-inverted: var(--rc-color-text-button);
  --rc-color-text-primary-invert-light: var(--rc-color-text-primary-on-dark);
  --rc-color-text-primary-invert-dark: var(--rc-color-text-primary-on-light);
  --rc-color-text-link-footer: var(--rc-color-text-primary-on-dark);
  --rc-color-text-footer: var(--rc-color-text-primary-on-dark);
}
