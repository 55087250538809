
.header-dropdown {
  display: inline-flex;
  position: relative;

  @include tablet {
    position: static;
  }
}

.header-dropdown__trigger {
  @include button-reset;

  display: inline-block;
  color: var(--rc-color-text-primary);
  transition: color 0.2s;

  &[href='#'] {
    cursor: default;
  }

  @include responsive-hover {
    color: var(--rc-color-text-accent);
  }
}

.header-dropdown__contents {
  position: absolute;
  background: var(--rc-color-background-elevated);
  z-index: 1004;
  top: calc(100% + 1rem);
  right: 0;
  width: 35rem;
  border-radius: var(--rc-border-radius-md);
  opacity: 0;
  transform: translateZ(0) scale(0.95);
  visibility: hidden;
  transition: opacity .1s, visibility 0s .1s;
  padding: 1rem;
  border: 0.1rem solid var(--rc-color-border-primary);

  .header-dropdown--open & {
    @include box-shadow;
    display: block;
    opacity: 1;
    transform: translateZ(0) scale(1);
    visibility: visible;
    transition: opacity .1s, transform .2s;
  }

  .category-flyout-header__link-bar__secondary &,
  .reverb-header__nav__link__auto-width & {
    display: flex;
    flex-direction: column;
    width: auto;
  }

  [data-news-trigger] & {
    width: 32rem;
  }

  &.header-dropdown__contents--mobile-hide {
    @include mobile {
      display: none;
    }
  }
}
