@use '../tools/mixins/dialogs';

.rc-menu__panel {
  @include dialogs.panel;
  border-radius: var(--rc-border-radius-input);
  z-index: var(--z-index-combobox);
  margin-right: var(--rc-space-2);
  max-height: 30rem;
  overflow-y: auto;
  max-width: 30rem;
  min-width: 20rem;
  opacity: 1;
  transform: translateY(0);
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(.4, 0.0, 0.2, 1); // Material's FastOutSlowIn

  &:focus-visible {
    outline: none;
  }

  // Animations
  &[data-status='open'] {
    transition-duration: 0.25s;
  }

  &[data-status='close'] {
    transition-duration: 0.15s;
  }

  &[data-status='initial'],
  &[data-status='close'] {
    opacity: 0;
  }

  &[data-status='initial'] {
    &[data-placement^='top'] {
      transform: translateY(var(--rc-space-2));
    }

    &[data-placement^='bottom'] {
      transform: translateY(calc(-1 * var(--rc-space-2)));
    }
  }
}

.rc-menu__separator {
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
  margin: var(--rc-space-1) 0;
}

.rc-menu__item {
  position: relative;

  button {
    @include button-reset;

    width: 100%;
  }

  button,
  a {
    padding: var(--rc-space-3) var(--rc-space-4);
    text-align: left;
    background-color: var(--rc-color-transparent);
    display: block;
    transition: background-color 0.2s;
    color: var(--rc-color-text-primary);

    &[aria-checked='true'] {
      background-color: var(--rc-color-background-module-hovered);
    }

    @include responsive-hover {
      background-color: var(--rc-color-background-module-hovered);
    }

    &:focus-visible {
      background-color: var(--rc-color-background-module-hovered);
    }

    &[aria-disabled='true'] {
      color: var(--rc-color-text-primary-disabled);
      background-color: var(--rc-color-transparent);
      cursor: default;
    }

    &[role='menuitemcheckbox'],
    &[role='menuitemradio'] {
      padding-right: var(--rc-space-10);
    }
  }
}

.rc-menu__item__selected-icon {
  position: absolute;
  top: 0;
  right: var(--rc-space-3);
  bottom: 0;
  pointer-events: none;
  fill: var(--rc-color-text-primary);

  svg {
    width: var(--rc-space-4);
    height: 100%;
  }
}
