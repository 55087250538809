@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.homepage-sell-visibility-v2__content {
  display: flex;
  justify-content: space-between;
  gap: 3rem;

  @include tablet {
    flex-direction: column;
    gap: 1.2rem;
  }
}

.homepage-sell-visibility-v2__card {
  width: 100%;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  border-radius: var(--rc-border-radius-primary);
  background-color: var(--rc-color-palette-vu-orange-100);
  background-blend-mode: saturation; // removes texture
}

.homepage-sell-visibility-v2__icon {
  width: 6rem;

  @include tablet {
    width: 4rem;
  }
}

.homepage-sell-visibility-v2__icon-container {
  padding: 1.6rem 2rem 0 2rem;

  @include tablet {
    padding: 1.2rem 1.2rem 0 1.2rem;
  }
}

.homepage-sell-visibility-v2__icon {
  width: 4.8rem;
}

.homepage-sell-visibility-v2__card-content {
  padding: 2rem 2rem 2rem 0;
  font-size: 1.6rem;

  @include tablet {
    padding: 1.6rem 1.6rem 1.6rem 0;
    font-size: 1.4rem;
  }
}

.homepage-sell-visibility-v2__card-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;

  @include tablet {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }
}

.homepage-sell-visibility-v2__button {
  @include rc-button.filled;

  @include mobile {
    @include rc-button.size('small');
    width: 100%;
    margin-top: 1rem;
  }
}
