@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

// RCRadioCard overrides
.rc-radio-card:has(.domestic-shipping-radio-label) {
  .rc-radio-card__content {
    border-radius: inherit;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-width: 0;
    padding-left: var(--radio-card-padding);

    @include mobile {
      border-radius: inherit;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-width: 0.1rem;
    }
  }
}

.domestic-shipping-radio-label__sublabel {
  @include rc-text.default('350');
  color: var(--rc-color-text-secondary);

  a {
    color: var(--rc-color-text-secondary);
    text-decoration: underline;
    z-index: 1;
    position: relative;
  }
}

.domestic-shipping-radio-label__sublabel__link {
  margin-left: 0.5rem;
  white-space: nowrap;
  position: relative;
  z-index: 1;
}

.domestic-shipping-radio-label__help {
  display: flex;
  height: 100%;
}

.domestic-shipping-radio-label__help__background {
  display: flex;
  gap: var(--rc-space-4);
  width: 100%;

  hr {
    border-right: 0.1rem solid var(--rc-color-border-primary);
    margin: 0;
  }
}

.domestic-shipping-radio-label__help__box {
  flex: 1;
}

.domestic-shipping-radio-label__help__box__title {
  @include rc-text.utility('350');
}

.domestic-shipping-radio-label__example__text {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
}

.domestic-shipping-radio-label__example__icon {
  width: 1.6rem;
  margin-right: 0.5rem;
}

.domestic-shipping-radio-label__explainer__text {
  @include rc-text.utility('350');
  margin-top: 1rem;
  color: var(--rc-color-text-secondary);
}
