.rc-modal {
  --modal-padding: var(--rc-space-6);

  position: relative;
  box-sizing: border-box;
  width: 55rem;
  max-width: 100%;
  max-height: 100%;
  margin: min(var(--rc-space-10), var(--rc-space-gutter-page));
  border-radius: var(--rc-border-radius-xl);
  background-color: var(--rc-color-background-module);
  border: 0.1rem solid var(--rc-color-border-primary);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include mobile {
    --modal-padding: var(--rc-space-4);
  }
}

.rc-modal__header {
  padding: var(--rc-space-3) calc(var(--rc-min-height-button-small) + var(--rc-space-1));
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
  text-align: center;
}

.rc-modal__body {
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;

  &::before,
  &::after {
    @include box-shadow($spread-radius: var(--rc-space-1));
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 0.1rem;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &::before {
    top: -0.1rem;
  }

  &::after {
    bottom: -0.1rem;
  }

  &.rc-modal__body--shadow-top {
    &::before {
      opacity: 1;
    }
  }

  &.rc-modal__body--shadow-bottom {
    &::after {
      opacity: 1;
    }
  }
}

.rc-modal__body__content {
  flex: 1;
  padding: var(--modal-padding);
  overflow: auto;

  & > [data-observer] {
    height: 0.1rem;
  }
}

.rc-modal__footer {
  padding: var(--rc-space-4) var(--modal-padding);
  border-top: 0.1rem solid var(--rc-color-border-primary);
  display: flex;
}
