@use '../tools/mixins/box-shadow';

.rc-card {
  @include box-shadow.shadow-animation;
  background-color: var(--bg-color);
  border-radius: var(--rc-border-radius-primary);
  position: relative;

  @include responsive-hover {
    @include box-shadow.box-shadow;
    background-color: var(--bg-color-hover);
  }

  &.rc-card--full-height {
    height: 100%;
  }

  // This allows other card actions to sit "on top" of the exploded pseudo element click target
  a:not(.rc-card__link),
  button {
    position: relative;
    z-index: 2;
  }
}

.rc-card__link {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}
