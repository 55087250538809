@use '@reverbdotcom/cadence/styles/tools/mixins/wrapper';

.video-embed {
  @include clearfix;
  @include responsive(font-size, 1em, 0.9em, 0.85em);
  @include gap-row;

  .blog-post & {
    @include scaling((margin-top margin-bottom), 4rem);
  }

  &.video-embed--with-background {
    @include commons-svg-background('instruments-darken.svg');
    background-color: var(--rc-color-palette-gray-300);
    background-repeat: repeat;
    padding-top: $grid-gutter;
    padding-bottom: $grid-gutter;
  }
}

.video-embed__wrapper {
  .dynamic-page__one-col & {
    @include wrapper.site-wrapper;
    @include wrapper.site-wrapper--cms;
  }
}

.video-embed__overhang {
  .dynamic-page__one-col & {
    @include site-wrapper-overhang;
  }

  .video-embed--narrow & {
    margin-left: 0;
    margin-right: 0;
  }
}

.video-embed__columns {
  .video-embed--with-description & {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dynamic-page__two-col & {
      @include tablet {
        display: block;
      }
    }

    @include mobile {
      display: block;
    }
  }
}

.video-embed__column {
  .video-embed--with-description & {
    flex: 0 0 48%;
  }
}

.video-embed__inner {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-embed__caption {
  margin-top: 1em;
  text-align: center;
  font-size: 1.4rem;
  font-style: italic;

  .video-embed--with-description & {
    font-size: 1.4em;
    font-style: normal;
    text-align: left;
  }
}

.video-embed__description {
  margin-top: 1em;

  p {
    margin-bottom: 1em;
  }
}

.video-embed__thumbs {
  margin: 1rem -0.5rem;
  position: relative;

  @include tablet {
    margin: 1rem 1rem;
  }
}

.video-embed__thumbs__inner {
  padding: 0 0 1rem 0;
  display: flex;
  scroll-behavior: smooth;
  overflow: hidden;
  overflow-x: auto;
  z-index: 1;
  margin-bottom: -1rem;
}

.video-embed__thumb {
  @include responsive(flex-basis, 10rem, 8rem, 6rem);
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 0.5rem;
  transition: opacity .1s;
  cursor: pointer;

  .video-embed__thumbs & {
    &.video-embed__thumb--inactive {
      opacity: 0.6;

      & .video-embed-item {
        border: none;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .lightbox-image-item {
    &::before {
      @include responsive(height, 5rem, 4rem, 3rem);
      @include responsive(width, 5rem, 4rem, 3rem);
    }

    &::after {
      display: none;
    }
  }
}
