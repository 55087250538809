@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.item2-title {
  @include scaling(margin-bottom, 2rem);

  @include mobile {
    margin-top: 1.5rem;
  }
}

.item2-title__inner {
  h1 {
    @include hyphenate;
    @include rc-text.title('900');
    margin-bottom: var(--rc-space-3);
  }
}

.item2-title__primary {
  flex: 1 1 auto;
}

.item2-title__secondary {
  flex: 0 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}
