.cart-coupons__callout {
  border-color: var(--rc-color-palette-yellow-600);
  background: var(--rc-color-palette-yellow-100);
  border-width: 0.2rem;
  border-radius: var(--rc-border-radius-md);
  border-style: dashed;
  margin: 1rem 0;
  padding: var(--rc-space-4);

  @include mobile {
    display: block;
  }
}

.cart-coupons__coupons-available {
  font-weight: bold;
  margin-right: .8rem;

  @include mobile {
    margin-bottom: 1rem;
  }
}

.cart-coupons__view-coupons {
  @include button-as-link;
}


.cart-coupons__apply-coupons {
  margin-right: .8rem;

  @include mobile {
    display: none;
  }
}

.cart-coupons__callout-text {
  @include mobile {
    display: inline-block;
    margin-bottom: 1rem;
  }
}
