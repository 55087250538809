// avoiding class collision with legacy 'shop-badge' class in core

.shared-shop-badges {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -0.5rem;

  > .shared-shop-badge {
    margin: 0.5rem;
  }

  &.shared-shop-badges--stacked {
    display: block;
  }
}

// individual shop-badge
.shared-shop-badge {
  @include button-reset;
  color: var(--rc-color-text-primary);
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.shared-shop-badge__icon {
  width: 4rem;

  .shared-shop-badge--small & {
    width: 3.2rem;
  }

  .rc-icon {
    display: block;
    width: 100%;
    height: auto;
  }
}

.shared-shop-badge__label {
  font-size: 1.3rem;
  font-weight: bold;
  margin-left: 0.4em;

  .shared-shop-badge--hide-label & {
    @include visually-hidden;
    margin-left: 0;
  }
}
